import React, { useContext, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Autocomplete, Button, Checkbox, Fab, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack, styled, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import { MuiFileInput } from 'mui-file-input';
import PreviewIcon from "@mui/icons-material/Preview";
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../api/Viewimage';


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));


const fileTypes = ["pdf"];
const ProductMarketscst = () => {
    const showAddInfo = localStorage.getItem("showInfo")

    const [formData, setFormData] = useState({
        products: [{}]
    });
    const { navigator, handleBackapp, VisuallyHiddenInput, setPageLoading, icon, checkedIcon } = useContext(DataContext)
    const { handleSubmit, control, getValues, setValue, watch, clearErrors, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        fields: productsFields,
        append: productsAppend,
        remove: productsRemove,
    } = useFieldArray({
        control,
        name: "products"
    });
    const [multipleImages, setMultipleImages] = useState([{
        productImageName: ''
    }]);
    useDidMountEffect(() => {
        // eslint-disable-next-line
        (async () => await getApi())()
        dropdownValueApi()
    }, [])
    const [technologiesList, setTechnologiesList] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setTechnologiesList(val.technologyStacks)
        })
    }
    const getApi = async () => {
        const updateName = multipleImages.filter((index) => index !== index)
        setMultipleImages(updateName)
        setPageLoading(true)
        await apiService(`startup/productandmarket/get`, '', 'get').then((res) => {
            if (res) {
                setPageLoading(false)
                setFormData({})
                let response = res.data
                if (response.id !== 0) {
                    setFormData(response)
                    if (response.editForm === false && response.superEdit === false) {
                        setReadyOnlyValue(true)
                    }
                    if (response.editForm === false && response.superEdit === true) {
                        setReadyOnlyValue(false)
                    }
                    if (response.editForm === true && response.superEdit === false) {
                        setReadyOnlyValue(false)
                    }
                    if (response.productOrServiceTested) {
                        const productOrServiceTested = response.productOrServiceTested === true;
                        setValue("productOrServiceTested", productOrServiceTested);
                    }
                    if (response.testingReport) {
                        setIsUploadedTesting(true)
                        setReportValue(response.testingReport);
                        setReportName(response.testingReport);
                        setReportURL(response.testingReportName);
                        setCheckMethod("update");
                    }
                    if (response.productOverview) {
                        setIsUploadedTesting2(true)
                        setReportValue2(response.productOverview);
                        setReportName2(response.productOverview);
                        setReportURL2(response.productOverviewName);
                        setCheckMethod2("update");
                    }
                    //           
                    if (response.products !== undefined) {

                        // response.products.forEach((element, index) => {
                        //     const newInputValues = [...multipleImages];
                        //     newInputValues[index] = { ...newInputValues[index], imageName: "" };
                        //     setMultipleImages(newInputValues);
                        //     console.log(multipleImages)
                        // });
                        setMultipleImages(response.products);
                        // response.products.forEach((element, index) => {
                        //     // var req = {
                        //     //     imageName: element.productImageName
                        //     // }
                        //     // imageFile.push(req)
                        //     // setMultipleImages(imageFile)
                        //     // debugger
                        //     // const newInputValues = [...multipleImages];
                        //     // newInputValues[index] = { ...newInputValues[index], imageName: element.productImageName };
                        //     // setMultipleImages(newInputValues);
                        //     // console.log(multipleImages)
                        //     let newArr = multipleImages.map((item, i) => {
                        //         if (index == i) {
                        //           return { ...item, imageName: element.productImageName };
                        //         } else {
                        //           return item;
                        //         }
                        //       });
                        //       setMultipleImages(products);
                        //       debugger
                        // });

                    }
                    if (response.technologyStack) {
                        if (response.technologyStack.length === 1) {
                            if (response.technologyStack[0] === "") {
                                response.technologyStack = undefined
                            }
                        }
                        if (response.technologyStack !== undefined) {
                            setTechnologyStackValue(response.technologyStack)
                        }
                    }
                    console.log(multipleImages)

                } else {
                    setFormData(response)
                }
            }
        })
    }
    const addAdvisoriesDetailsBtn = () => {
        productsAppend({
            id: 0,
            name: "",
            link: "",
            productImage: "",
            productImageName: "",
            productRoadMap: "",
        })
        // const last = multipleImages.slice(-1)
        // debugger
        // setMultipleImages((prevImages) => prevImages.concat(null))
    }
    // let imageFile = []

    const handleProductUpload = (event, index) => {
        // const id = Number(getValues(`products.${index}.id`))
        // debugger
        if (event.target === undefined) {
            logoselecetdFile = event
        } else {
            logoselecetdFile = event.target.files[0]
        }
        if (logoselecetdFile) {
            var reader = new FileReader();
            var imagetype = logoselecetdFile.type
            var imagedatatype = imagetype.split("/")
            var img_crt_type = imagedatatype[1]
            if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf") {
                var fileValue = logoselecetdFile
                reader.readAsDataURL(logoselecetdFile);
                reader.onload = () => {
                    var logourl1 = reader.result;
                    var spl = logourl1.split(',')
                    var ImageValue = spl[1]
                    var img_name = fileValue.name
                    var getdata = getValues()
                    getdata.products[index].productImage = ImageValue
                    getdata.products[index].productImageName = img_name
                    setFormData(getdata)
                    // var req = {
                    //     imageName: img_name
                    // }
                    // imageFile.push(req)
                    // setMultipleImages(imageFile)                    
                    const newInputValues = [...multipleImages];
                    newInputValues[index] = { ...newInputValues[index], productImageName: logourl1 };
                    setMultipleImages(newInputValues);
                    // const imageArray = Array.from(event.target.files).map((file) =>
                    //     URL.createObjectURL(file)
                    // );
                    // if (id !== 0) {
                    //     setMultipleImages([...multipleImages, (prevImages) => prevImages.concat(imageArray)])
                    // } else {
                    //     setMultipleImages([...multipleImages, (prevImages) => prevImages.concat(imageArray)])
                    // }


                }
            }
        }
    }
    // delete products
    const deleteProducts = async (i) => {
        const id = Number(getValues(`products.${i}.id`))
        if (id !== 0) {
            apiService(`startup/products/remove?id=${id}`, '', 'get').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        productsRemove(i)
                        getApi()
                    }
                }
            })
        } else {
            productsRemove(i)
        }
        const updateName = multipleImages.filter((item, index) => index !== i)
        setMultipleImages(updateName)
        debugger
    }

    const [technologyStackValue, setTechnologyStackValue] = useState([])
    const handleSelectTechnologyStack = (value) => {
        setTechnologyStackValue(value)
        setValue('technologyStack', value)
        clearErrors('technologyStack');
    }
    // const [intellectualPropsValue,setIntellectualPropsValue]=useState('')
    const onSubmit = (data) => {
        debugger
        if (readyOnlyValue === false) {
            setPageLoading(true);
            var getform = data
            if (getform.technologyStack === '') {
                getform.technologyStack = []
            }
            if (getform.productOrServiceTested === '') {
                debugger
                getform.productOrServiceTested = false
                // setValue('productOrServiceTested','false')
            }
            debugger
            apiService('startup/productandmarket/save', getform, 'post').then((res) => {
                setPageLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        // navigator('/startup/business-strategy')
                        if (showAddInfo === "true") {
                            navigator("/startup/business-strategy/scst")
                        }
                    }
                }
            })
        }
    };
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [reportValue, setReportValue] = useState(null);
    const [reportName, setReportName] = useState(null);
    const [reportURL, setReportURL] = useState(null);
    const [reportValue2, setReportValue2] = useState(null);
    const [reportName2, setReportName2] = useState(null);
    const [reportURL2, setReportURL2] = useState(null);
    const [checkMethod, setCheckMethod] = useState("save");
    const [checkMethod2, setCheckMethod2] = useState("save");
    const inputRef = useRef(null);
    let logoselecetdFile = "";
    const handleFileUpload = (
        event,
        filekey,
        fileName,
        setdata,
        setName,
        setUrl,
        format
    ) => {
        if (event !== null) {
            let logoselectedFile;
            if (event.target === undefined) {
                logoselectedFile = event;
            } else {
                logoselectedFile = event.target.files[0];
            }
            if (logoselectedFile) {
                const fileSizeLimit = 5 * 1024 * 1024; // 5 MB limit
                if (logoselectedFile.size > fileSizeLimit) {
                    // File size exceeds limit, show error message
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return; // Exit function
                }
                var reader = new FileReader();
                var imagetype = logoselectedFile.type;
                var imagedatatype = imagetype.split("/");
                var img_crt_type = imagedatatype[1];
                if (
                    (format === "file" &&
                        (img_crt_type === "jpeg" ||
                            img_crt_type === "jpg" ||
                            img_crt_type === "png" ||
                            img_crt_type === "pdf")) ||
                    (format === "image" &&
                        (img_crt_type === "jpeg" ||
                            img_crt_type === "jpg" ||
                            img_crt_type === "png"))
                ) {
                    setValue(filekey, "");
                    setValue(fileName, "");
                    setName("");
                    setUrl("");
                    if (event.target === undefined) {
                        setdata(event);
                    } else {
                        setdata(event.target.files[0]);
                    }
                    var fileValue = logoselectedFile;
                    reader.readAsDataURL(logoselectedFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(",");
                        var ImageValue = spl[1];
                        var img_name = fileValue.name;
                        setUrl(logourl1);
                        setValue(filekey, ImageValue);
                        setValue(fileName, img_name);
                        setName(img_name);
                        clearErrors(filekey);
                        clearErrors(fileName);
                        // if (filekey === "logo") {
                        //     setLogoInvalid(false);
                        // }
                    };
                } else {
                    notifyService(
                        "danger",
                        "File Format Invalid",
                        "Please check your file format."
                    );
                }
            }
        } else {
            // Handle case when event is null (optional)
        }
    };

    const productOrServiceTested = watch("productOrServiceTested", false);
    const handleFilter = (isProductOrServiceTested) => {
        setValue("productOrServiceTested", isProductOrServiceTested);
        if (!isProductOrServiceTested) {
            setValue("productOrServiceTested", "");
            setValue("productOrServiceTestedDesc", "");
            setValue("testingReportName", "");
            setValue("testingReport", "");
        }
    };

    const [isUploadedTesting, setIsUploadedTesting] = useState(false);
    const handleFileChangeTesting = (e) => {
        debugger
        handleFileUpload(
            e,
            "testingReport",
            "testingReportName",
            setReportValue,
            setReportName,
            setReportURL,
            "file"
        )
        setIsUploadedTesting(true);
    }
    const [isUploadedTesting2, setIsUploadedTesting2] = useState(false);
    const handleFileChangeTesting2 = (e) => {
        debugger
        handleFileUpload(
            e,
            "productOverview",
            "productOverviewName",
            setReportValue2,
            setReportName2,
            setReportURL2,
            "file"
        )
        setIsUploadedTesting2(true);
    }
    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBackapp}><img src='/images/Fig-back.png' className='img-fluid' alt='' /></span> <h6 className='ml-2 mb-0'>Product and Market Fit</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="overviewOfProduct" control={control} defaultValue="" rules={{ required: ' Overview of product / Service is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Can you provide an overview of product / Service and its process? *"
                                                placeholder='Enter Overview of product / Service'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.overviewOfProduct && errors.overviewOfProduct.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="productOrServiceUnique" control={control} defaultValue="" rules={{ required: ' Product/service unique is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="What makes your product/service unique? *"
                                                placeholder='Enter your product / service Uniqueness'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.productOrServiceUnique && errors.productOrServiceUnique.message}</FormHelperText>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 mb-3 ">
                                <FormLabel>Please provide an overview of your product or service solution—include the complete architecture and specifications. Please share this as a document.</FormLabel>
                                {/* <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="productOverview" control={control} defaultValue="" rules={{ required: 'product or service is required' }}
                                            render={({ field }) =>
                                                
                                                <FileUploader
                                                {...field}
                                                handleChange={(e) => handleFileUpload(e,
                                                    "productOverview",
                                                    "productOverviewName",
                                                    "productOverviewURL",
                                                    'file')}
                                                value={getValues('productOverview')}
                                                name="file"
                                                types={fileTypes}
                                                children={
                                                    <div className={getValues('productOverview') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                        <center>
                                                            <p className={getValues('productOverview') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                <span>
                                                                    <AttachFileIcon className="file-icon mr-2" />
                                                                    {getValues('productOverview') !== '' ? 'Uploaded (Click to Change)' : 'Resigned  Document'}
                                                                </span>
                                                            </p>
                                                        </center>
                                                    </div>
                                                }
                                                disabled={readyOnlyValue}
                                            />
                                            } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf,.xlsx,.doc</FormHelperText>
                                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.resignedDirectorsDoc && errors.resignedDirectorsDoc.message}</FormHelperText>
                                </div>
                                <span className="pre-btn-dpiit" onClick={() => handleClickOpen(getValues('productOverviewURL'))}><VisibilityIcon className='icon-size mr-1' /><Link


                                    style={{ color: "#80809e" }}
                                > Download
                                </Link>
                                </span>
                            </div> */}
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        {/* <FormLabel shrink="true" className='input_Lable'>Attachments for images or testing reports.<span className='text-danger'>*</span> </FormLabel> */}
                                        <Controller name="productOverview" control={control} defaultValue="" rules={{ required: 'product or service is required' }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleFileChangeTesting2}
                                                    value={reportValue}
                                                    name="file"
                                                    types={fileTypes}
                                                    children={
                                                        <div className={getValues(`productOverview`) ? 'fileupload-product-bg-active' : 'fileupload-product-bg'}>
                                                            <center>
                                                                <p className={getValues(`productOverview`) ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues(`productOverview`) ? 'Uploaded (Click to Change) ' : 'product or service *'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                        <FormHelperText className='text-danger'>{errors.productOverview && errors.productOverview.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit" onClick={() => handleClickOpen(reportURL2)}><VisibilityIcon className='icon-size mr-1' />
                                        <Link
                                            style={{ color: "#80809e" }}
                                        > Preview
                                        </Link>
                                    </span>
                                    {/* <span className="pre-btn-dpiit"> <DeleteIcon className='icon-size' /></span> */}
                                </div>
                            </div>
                            {/* <div className='col-lg-12 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="overviewOfProduct" control={control} defaultValue="" rules={{ required: 'Product/service overview is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Please give a detailed overview of the product / service including the - procurement process. Sales process. Operations process, delivery process & post delivery process *"
                                                placeholder='Enter your overview of the product / service'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.overviewOfProduct && errors.overviewOfProduct.message}</FormHelperText>
                                </div>
                            </div> */}
                            {/* <div className='col-lg-4 col-12'></div> */}


                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    {/* <div className="d-flex">
                                        <span className="mt-1">Have your product / service tested or used? If so, where and how?</span>
                                        <span className="ml-3 d-flex justify-content-center align-items-center">
                                            No{" "}
                                           
                                            <AntSwitch className="mx-2"
                                                checked={productOrServiceTested}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter(!productOrServiceTested)}
                                                inputProps={{ 'aria-label': 'ant design' }} />
                                            {" "}
                                            Yes{" "}
                                        </span>
                                    </div> */}
                                    <span className="mt-1">Have your product / service tested or used? If so, where and how?</span>
                                    <span className="ml-3 d-flex align-items-center">
                                        <Controller
                                            name="productOrServiceTested"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field: { onChange, value } }) => (
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    No
                                                    <Switch
                                                        className="mx-2"
                                                        checked={value}
                                                        onChange={() => { onChange(!value); handleFilter(!value) }}
                                                        inputProps={{ 'aria-label': 'ant design' }}
                                                        disabled={readyOnlyValue}
                                                    />
                                                    Yes
                                                </Stack>
                                            )}
                                        />
                                    </span>
                                </div>
                            </div>
                            {
                                productOrServiceTested === true && (
                                    <>
                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group mt-2">
                                                <Controller
                                                    name="productOrServiceTestedDesc"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: "Where and How are required",
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            id="outlined-multiline-static"
                                                            defaultValue="Default Value"
                                                            variant='outlined'
                                                            label=" Where and How ? *"
                                                            placeholder='Enter Where and How.'
                                                            fullWidth
                                                            multiline
                                                            rows={3}
                                                            inputProps={
                                                                { readOnly: readyOnlyValue, }
                                                            }
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.productOrServiceTestedDesc && errors.productOrServiceTestedDesc.message}
                                                </FormHelperText>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12 mb-3 ">
                                            {
                                                getValues(`testingReportName`) && (<span>Testing Reports <span className='text-danger'>*</span></span>)
                                            }
                                            <div className="d-flex align-items-center">
                                                <div className='form-group my-1'>
                                                    {/* <FormLabel shrink="true" className='input_Lable'>Attachments for images or testing reports.<span className='text-danger'>*</span> </FormLabel> */}
                                                    <Controller name="testingReport" control={control} defaultValue="" rules={{ required: 'Testing reports is required' }}
                                                        render={({ field }) =>
                                                            <FileUploader
                                                                {...field}
                                                                handleChange={handleFileChangeTesting}
                                                                value={reportValue}
                                                                name="file"
                                                                types={fileTypes}
                                                                children={
                                                                    <div className={getValues(`testingReport`) ? 'fileupload-product-bg-active' : 'fileupload-product-bg'}>
                                                                        <center>
                                                                            <p className={getValues(`testingReport`) ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                                <span>
                                                                                    <AttachFileIcon className="file-icon mr-2" />
                                                                                    {getValues(`testingReport`) ? 'Uploaded (Click to Change) ' : 'Testing Reports *'}
                                                                                </span>
                                                                            </p>
                                                                        </center>
                                                                    </div>
                                                                }
                                                                disabled={readyOnlyValue}
                                                            />
                                                        } />
                                                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                                    <FormHelperText className='text-danger'>{errors.testingReport && errors.testingReport.message}</FormHelperText>
                                                </div>
                                                <span className="pre-btn-dpiit" onClick={() => handleClickOpen(reportURL)}><VisibilityIcon className='icon-size mr-1' />
                                                    <Link
                                                        style={{ color: "#80809e" }}
                                                    > Preview
                                                    </Link>
                                                </span>
                                                {/* <span className="pre-btn-dpiit"> <DeleteIcon className='icon-size' /></span> */}
                                            </div>
                                            {/* {checkMethod === "save" ? (
                                            <div className="form-group ">
                                                <Controller
                                                    name="testingReport"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required:
                                                            false,
                                                    }}
                                                    render={({ field }) => (
                                                        <MuiFileInput
                                                            {...field}
                                                            onChange={(e) =>
                                                                handleFileUpload(
                                                                    e,
                                                                    "testingReport",
                                                                    "testingReportName",
                                                                    setReportValue,
                                                                    setReportName,
                                                                    setReportURL,
                                                                    "file"
                                                                )
                                                            }
                                                            value={reportValue}
                                                            variant="outlined"
                                                            // inputProps={}
                                                            // onReset={(e) => handleremove(e)}
                                                            inputRef={inputRef}
                                                            // inputProps= {
                                                            //   ref=
                                                            // }

                                                            fullWidth
                                                            label="Attachments for images or testing reports."
                                                            placeholder="Choose File"
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.testingReport && errors.testingReport.message}
                                                </FormHelperText>
                                                <div>
                                                    <span className="fn-12">
                                                        Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                                                        <span className="text-danger">*</span>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <p className="mb-0">
                                                    Attachments for images or testing reports.
                                                </p>
                                                <div className="row">
                                                    <div className="col-lg-7 col-12 my-3">
                                                        <Controller
                                                            name="testingReport"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{
                                                                required:
                                                                    "Images or testing reports is required",
                                                            }}
                                                            render={({ field }) => (
                                                                <Button
                                                                    component="label"
                                                                    variant="contained"
                                                                    {...field}
                                                                    fullWidth
                                                                    onChange={(e) =>
                                                                        handleFileUpload(
                                                                            e,
                                                                            "testingReport",
                                                                            "testingReportName",
                                                                            setReportValue,
                                                                            setReportName,
                                                                            setReportURL,
                                                                            "file"
                                                                        )
                                                                    }
                                                                    startIcon={<CloudUploadIcon />}
                                                                    href="#file-upload"
                                                                    disabled={readyOnlyValue}
                                                                >
                                                                    Change
                                                                    {readyOnlyValue === false ? (
                                                                        <VisuallyHiddenInput type="file" />
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </Button>
                                                            )}
                                                        />
                                                        <div>
                                                            <span className="fn-12">
                                                                Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                                                                <span className="text-danger">*</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-12 my-3">
                                                        <Fab variant="extended">
                                                            <Link
                                                                to={`${reportURL}`}
                                                                target="_blank"
                                                                color="black"
                                                            >
                                                                <PreviewIcon sx={{ mr: 1 }} />
                                                                View Document
                                                            </Link>
                                                        </Fab>
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <div className="form-group text-right">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>

                    </form>
                </div>
            </section>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </>
    )
}

export default ProductMarketscst
import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Autocomplete, Button, FormControl, FormHelperText, Grid, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const TeamInfoscst = () => {
    const [formData, setFormData] = useState({
        startupFounders: [{}],
        startupAdvisories: [{}]
    });
    const { navigator, handleBackapp, Paper } = useContext(DataContext)
    const { handleSubmit, control, setValue, watch, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    useDidMountEffect(() => {
        // eslint-disable-next-line
        (async () => await getApi())()
    }, [])
    const getApi = async () => {
        await apiService(`startup/team/get`, '', 'get').then((res) => {
            if (res) {
                setFormData({})
                let response = res.data
                if (response.id !== 0) {

                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                } else {
                    setFormData(response)
                }
            }
        })
    }

    const onSubmit = (data) => {
        if (readyOnlyValue === false) {
            setLoading(true);
            var getform = data
            apiService('startup/team/save', getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        // navigator('/startup/incubation-accelaration')
                    }
                }
            })
        }
    };
    // delete Founders
    // const deleteFounders = async (index) => {
    //     const id = getValues(`startupFounders.${index}.id`);
    //     if (id !== undefined) {
    //         apiService(`startup/founders/remove?id=${id}`, '', 'get').then((res) => {
    //             if (res) {
    //                 if (res.data.responseStatus === 'Success') {
    //                     foundersRemove(index)
    //                     getApi()
    //                 }
    //             }
    //         })
    //     } else {
    //         foundersRemove(index)
    //     }
    // }

    // delete Advisories
    // const deleteAdvisories = async (index) => {
    //     const id = getValues(`startupAdvisories.${index}.id`);
    //     if (id !== undefined) {
    //         apiService(`startup/advisories/remove?id=${id}`, '', 'get').then((res) => {
    //             if (res) {
    //                 if (res.data.responseStatus === 'Success') {
    //                     advisoriesRemove(index)
    //                     getApi()
    //                 }
    //             }
    //         })
    //     } else {
    //         advisoriesRemove(index)
    //     }
    // }

    const currentEmployees = watch("currentEmployees", false);
    const handleFilter = (isCurrentEmployees) => {
        setValue("currentEmployees", isCurrentEmployees);
        if (!isCurrentEmployees) {
            setValue("currentEmployees", "");
        }
    };

    const yesRoles = [
        "Sales/Marketing/HR/Operations",
        "Execution/Product Development",
        "R&D/Finance",
        "Accounts/Office Staff",
        "Admin/Contract Workers"
    ]

    const noRoles = [
        "Sales/Marketing/HR/Operations",
        "Execution/Product Development",
        "R&D/Finance",
        "Accounts/Office Staff",
        "Admin/Contract Workers"
    ]

    const handleSelectCommunity = (value, index) => {
        setValue(`roles`, value);
        clearErrors(`roles`);
    }

    const [showTextarea, setShowTextarea] = useState(false);

    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBackapp}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Team Info</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        {/* <div className='row'> */}
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={3}
                        >
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className="form-group mt-2">
                                    <div className="d-flex">
                                        <span className="mt-1">Are there any current employees?</span>
                                        <span className="ml-3 d-flex justify-content-center align-items-center">
                                            No{" "}
                                            <Switch
                                                checked={currentEmployees}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter(!currentEmployees)}
                                                color="primary"
                                            />{" "}
                                            Yes{" "}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            {currentEmployees === true ? (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="form-group mt-2">
                                            <Controller
                                                name="roles"
                                                control={control}
                                                defaultValue={[]}
                                                rules={{
                                                    required: "Roles are required",
                                                }}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        multiple
                                                        disableClearable
                                                        disablePortal
                                                        {...field}
                                                        options={yesRoles}
                                                        onChange={(e, selectedOptions) => {
                                                            field.onChange(selectedOptions);
                                                            handleSelectCommunity(selectedOptions)
                                                            // setShowTextarea(selectedOptions.includes("Others"))
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="If YES, what are their roles? *" />
                                                        )}
                                                        disabled={readyOnlyValue}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.roles && errors.roles.message}
                                            </FormHelperText>
                                        </div>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="form-group mt-2">
                                            <Controller
                                                name="roles"
                                                control={control}
                                                defaultValue={[]}
                                                rules={{
                                                    required: "Roles are required",
                                                }}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        multiple
                                                        {...field}
                                                        options={noRoles}
                                                        onChange={(e, selectedOptions) => {
                                                            field.onChange(selectedOptions);
                                                            handleSelectCommunity(selectedOptions)
                                                            // setShowTextarea(selectedOptions.includes("Others"))
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="If NO, what are the roles would you like to hire for setting up your team? *" />
                                                        )}
                                                        disabled={readyOnlyValue}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.roles && errors.roles.message}
                                            </FormHelperText>
                                        </div>
                                    </Grid>
                                </>
                            )}
                            {/* {showTextarea && (
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Controller name="OtherEmployee" control={control} defaultValue="" rules={{ required: 'Other type is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            id="outlined-multiline-static"
                                            defaultValue="Default Value"
                                            variant='outlined'
                                            label="Other type ? *"
                                            placeholder='Enter Other.'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.OtherEmployee && errors.OtherEmployee.message}</FormHelperText>
                                </Grid>
                            )} */}
                        </Grid>


                        <div className="form-group text-center mt-3 ">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>

                    </form>
                </div >
            </section >
        </>
    )
}

export default TeamInfoscst
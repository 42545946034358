import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, useFormState, Controller } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';
import { TextField, FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import apiService from '../../../../api/apiService';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import KeyIcon from '@mui/icons-material/Key';
import IconButton from '@mui/material/IconButton';
import cryptoJS from '../../../../hooks/cryptoJS';
import '../signup.css'
import DataContext from '../../../../context/DataContext';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Signupalert from '../Signupalert';
import ReCAPTCHA from 'react-google-recaptcha';
import Resendmail from '../Resendmail';
import notifyService from '../../../../api/notifySerivce';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import CustomLink from '../../../common-page/CustomLink';
const Signupverify = () => {
    const { navigator, modalstyle, validatePhoneNumber } = useContext(DataContext)
    // const { register, handleSubmit, control } = useForm();
    // const { errors } = useFormState({ control });
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
        { values: formData }
    );
    // const [capVal, setCapVal] = useState(null)
    const [loading, setLoading] = useState(false)
    const [checkSamePassword, setCheckSamePassword] = useState(false)
    const [encryptedOtp, setEncryptedOtp] = useState('');

    useDidMountEffect(() => {
        document.getElementById('theme').setAttribute('href', '/css/admin.css');
    }, [])
    useDidMountEffect(() => {
        homeApi();
    }, [])

    const [homeData, setHomeData] = useState({})
    const homeApi = () => {
        setHomeData({})
        apiService(`home`, '', 'get').then((res) => {
            if (res.data) {
                setHomeData(res.data)
            }
        })
    }


    const onSubmit = async (data, event) => {
        if (data.setPassword === data.newPassword) {
            setLoading(true);
            setCheckSamePassword(false)
            var getform = data
            const encryptedOtp = cryptoJS(data.otp);
            getform.id = startupId
            getform.otp = encryptedOtp;
            getform.newPassword = cryptoJS(data.newPassword)
            getform.setPassword = cryptoJS(data.setPassword)
            apiService('verifyregister', getform, 'unauthpost').then((res) => {
                setLoading(false);
                if (res) {
                    localStorage.setItem('jwttoken', res.data.token)
                    localStorage.setItem('userId', res.data.userId)
                    localStorage.setItem('role', res.data.role)
                    localStorage.setItem('privileges', JSON.stringify(res.data.privileges))
                    localStorage.setItem('username', res.data.userName)
                    localStorage.setItem('logo', res.data.organizationLogoName)
                    localStorage.setItem('name', res.data.name)
                    navigator('/startup/info')
                }
            })
        } else {
            setCheckSamePassword(true)
        }
        // var getform = data
        // getform.roleId = 4
        // getform.oldPassword = cryptoJS(data.oldPassword)
        // getform.newPassword = cryptoJS(data.newPassword)

    };
    const validateNumberonly = (e) => {
        const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
        if (!/[0-9]/.test(e.key) && !validKeys.includes(e.key)) {
            e.preventDefault();
        }
    };
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showRePassword, setShowRePassword] = React.useState(false);
    const handleClickShowRePassword = () => setShowRePassword((show) => !show);

    const name = localStorage.getItem('StartupName')
    let startupId = Number(localStorage.getItem('StartupId'))
    const handleResendMail = () => {
        debugger
        if (startupId === '0') {
            handleOpen()
        } else {
            var getform = {
                id: startupId
            }
            apiService(`resendregisterationmail`, getform, 'post').then((res) => {
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Mail sent successfully !!')
                    }
                }
            })
        }
    }
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <marquee><h3 className='text-danger mt-5'>{homeData.maintenanceMessage}</h3></marquee>
            <section className="signup-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <div className="wrap d-md-flex">
                                {/* <div className="text-wrap-signup p-4 p-lg-5 text-center d-flex align-items-center order-md-last">
                                    <div className="text w-100">
                                        <h2>Startup Registration</h2>
                                        <p>Already have a account ?</p>
                                        <Link to="/sign-in" className="btn btn-white btn-outline-white">Sign In</Link>
                                    </div>
                                </div> */}
                                <div>
                                    <img src="/images/startup.png" className="startup-bar img-fluid" alt="" />
                                </div>
                                <div className="signup-wrap p-4 p-lg-5 bg-white">
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <h3 className="mb-2 font-weight-bold">Welcome, let's verify your registration !</h3>
                                            <p className="mb-4">Please enter the Username and One-Time-Password (OTP) received on your e-mail, create a new password to secure your account</p>
                                        </div>
                                        {/* <div className="w-100">
                                                <p className="social-media d-flex justify-content-end">
                                                    <Link className="social-icon d-flex align-items-center justify-content-center"><span
                                                    className="fab fa-facebook-f"></span></Link>
                                                    <Link className="social-icon d-flex align-items-center justify-content-center"><span
                                                    className="fab fa-twitter"></span></Link>
                                                </p>
                                            </div> */}
                                    </div>
                                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className='row'>
                                            {
                                                startupId === 0 && (
                                                    <div className='col-lg-6 col-12 mb-3'>
                                                        <div className="form-group mb-3">
                                                            <Controller name="userName" control={control} defaultValue="" rules={{ required: 'Username / Email Id is required' }}
                                                                render={({ field }) =>
                                                                    <TextField
                                                                        variant='outlined'
                                                                        label="Username / Email Id"
                                                                        placeholder='Username / Email Id'
                                                                        autoFocus
                                                                        fullWidth
                                                                        {...field}
                                                                    />} />
                                                            <FormHelperText className='text-danger'>{errors.userName && errors.userName.message}</FormHelperText>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className={startupId === 0 ? ('col-lg-6 col-12 mb-3') : ('col-lg-12 col-12 mb-3')}>
                                                <div className="form-group mb-3">
                                                    <Controller name="otp" control={control} defaultValue="" rules={{
                                                        required: 'OTP is required', minLength: {
                                                            value: 6,
                                                            message: "OTP must be in 6 digits"
                                                        }
                                                    }}
                                                        render={({ field }) =>
                                                            <TextField
                                                                variant='outlined'
                                                                label="OTP"
                                                                placeholder='OTP received on email'
                                                                autoFocus
                                                                fullWidth
                                                                {...field}
                                                                inputProps={
                                                                    { maxLength: 6 }
                                                                }
                                                                onKeyDown={(e) => validateNumberonly(e)}
                                                            />} />
                                                    <FormHelperText className='text-danger'>{errors.otp && errors.otp.message}</FormHelperText>
                                                    <FormHelperText><span onClick={() => handleResendMail()} className='c-blue c-pointer'>Resend OTP</span></FormHelperText>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-12 mb-3'>
                                                <div className="form-group mb-3">
                                                    {/* <TextField
                                                        variant='outlined'
                                                        label="Email Id"
                                                        placeholder='Enter Email Id'
                                                        fullWidth
                                                        autoComplete='email'
                                                        {...register("email", {
                                                            required: "Email Id is required",
                                                            // validate: {
                                                            //   matchPattern: (v) =>
                                                            //     /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                            //     "Email address must be a valid address",
                                                            // },
                                                        })}
                                                        error={!!errors?.email}
                                                        helperText={errors?.email ? errors.email.message : null}
                                                    />
                                                    {errors.email?.message && (
                                                        <small>{errors.email.message}</small>
                                                    )} */}
                                                    {/* <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email Id' }}
                                                        render={({ field }) =>
                                                        <TextField
                                                            variant='outlined'
                                                            label="Email Id"
                                                            placeholder='Enter Email Id'
                                                            autoFocus
                                                            fullWidth
                                                            {...field}
                                                        />} />
                                                    <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText> */}
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Controller name="setPassword" control={control} defaultValue="" rules={{ required: 'New Password is required' }}
                                                            render={({ field }) =>
                                                                <TextField
                                                                    variant='outlined'
                                                                    label="Set Password"
                                                                    placeholder='Alpha Numeric Password'
                                                                    autoFocus
                                                                    fullWidth
                                                                    {...field}
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <KeyIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                        endAdornment:
                                                                            <IconButton
                                                                                onClick={handleClickShowPassword}
                                                                            >{showPassword ? <Visibility /> : <VisibilityOff />}
                                                                            </IconButton>
                                                                    }}
                                                                />} />
                                                        <FormHelperText className='text-danger'>{errors.setPassword && errors.setPassword.message}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-12 mb-3'>
                                                <div className="form-group mb-3">
                                                    {/* <TextField
                                                        variant='outlined'
                                                        label="Phone Number"
                                                        placeholder='Enter Phone Number'
                                                        fullWidth
                                                        type='number'
                                                        autoComplete='phone'
                                                        {...register("phone", {
                                                            required: "Phone Number is required",
                                                            validate: {
                                                                minLength: (v) => v.length <= 10 || "Maximum 10 number required",
                                                                maxLength: (v) => v.length >= 10 || "Minimum 10 number required",
                                                            }
                                                        })}
                                                        error={!!errors?.phone}
                                                        helperText={errors?.phone ? errors.phone.message : null}
                                                    /> */}
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Controller name="newPassword" control={control} defaultValue="" rules={{ required: 'Confirm Password is required' }}
                                                            render={({ field }) =>
                                                                <TextField
                                                                    variant='outlined'
                                                                    label="Confirm Password"
                                                                    placeholder='Confirm Password'
                                                                    autoFocus
                                                                    fullWidth
                                                                    {...field}
                                                                    type={showRePassword ? 'text' : 'password'}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <KeyIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                        endAdornment:
                                                                            <IconButton
                                                                                onClick={handleClickShowRePassword}
                                                                            >{showRePassword ? <Visibility /> : <VisibilityOff />}
                                                                            </IconButton>
                                                                    }}
                                                                />} />
                                                        <FormHelperText className='text-danger'>{errors.newPassword && errors.newPassword.message}</FormHelperText>
                                                        <FormHelperText className='text-danger'>{(checkSamePassword === true ? ('Password Mismatch') : (<></>))}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <div className='d-flex justify-content-center mb-3'> */}
                                            {/* dev -> 6LdEc4YoAAAAAD7exCN9xw41WW20_Aa3wpxsgTvj */}
                                            {/* local -> 6LcaFYIoAAAAAP93phV9qkG0EcGDMyw_SJJAB_O9 */}
                                            {/* live -> 6Lee24YoAAAAAATfkCzwSRTv7Zu7ASyb0ogFlprH */}
                                            {/* <ReCAPTCHA sitekey='6LcaFYIoAAAAAP93phV9qkG0EcGDMyw_SJJAB_O9' onChange={val => setCapVal(val)} /> */}
                                            {/* </div> */}
                                            {/* disabled={!capVal} */}
                                            <Button type="submit" variant="contained" className="form-control btn btn-primary submit px-3">Verify & Proceed</Button>
                                        </div>
                                        <div className='text-center'>
                                            <p className='mb-0 font-lg'>Didn't received verification mail !  <span onClick={() => handleResendMail()} className='c-blue c-pointer' >Resend</span></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalstyle} className="form_tag">
                        <Resendmail handleClose={handleClose} />
                    </Box>
                </Modal>
            </section>
        </>
    )
}

export default Signupverify
import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import apiService from '../../../../../api/apiService';
import notifyService from '../../../../../api/notifySerivce';
import Questiontemplate from './Questiontemplate';
import DataContext from '../../../../../context/DataContext';

const Panelquestionadd = ({ listApi, nestIndex, control, getValues, errors, juryList, enableJuryEdit }) => {
    debugger
    const { modalstyle } = useContext(DataContext)

    const { fields, remove, append } = useFieldArray({
        control,
        name: `panelRounds.${nestIndex}.panelRoundQuestions`
    });
    const addQuestionBtn = () => {
        append({
            id: 0,
            question: "",
            questionType: "",
            panelRoundsId: 0
        })
    }
    const removeQuestion = (k) => {
        const list = getValues(`panelRounds.${nestIndex}.panelRoundQuestions`);
        // debugger
        if (list.length > 0) {
            const id = getValues(`panelRounds.${nestIndex}.panelRoundQuestions.${k}.id`);
            if (id !== 0) {
                apiService(`partner/panelround/question/remove?id=${id}`, '', 'get').then((res) => {
                    if (res) {
                        if (res.data.responseStatus === 'Success') {
                            remove(k)
                        }
                    }
                })
            } else {
                remove(k)
            }
        } else {
            notifyService('warning', 'warning', 'Minimun one question is required !!')
        }
    }
    const [openTemplate, setOpenTemplate] = useState(false);
    const handleTemplateOpen = (id) => {
        setOpenTemplate(true)
    }
    const handleTemplateClose = () => {
        setOpenTemplate(false);
        listApi(juryList)
    }
    return (
        <div className='ques_row'>
            <div className='question_lable d-flex mb-3'>
                <p>Question - </p>
                <Button disabled={enableJuryEdit} className='chooseTem' onClick={() => handleTemplateOpen(nestIndex)}>Choose From Template</Button>
                <Button disabled={enableJuryEdit} variant="outlined" className='ml-auto' onClick={() => addQuestionBtn()}>+ Add Question</Button>
            </div>
            <div>
                {
                    ((fields.length > 0) ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Question </TableCell>
                                        <TableCell>Type </TableCell>
                                        {
                                            enableJuryEdit === false && (
                                                <TableCell align='center'>Action</TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fields.map((item, k) => (
                                        <TableRow key={item.id}>
                                            <TableCell>{k + 1}</TableCell>
                                            <TableCell>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <Controller name={`panelRounds.${nestIndex}.panelRoundQuestions.${k}.question`} control={control} defaultValue="" rules={{ required: false }}
                                                        render={({ field }) =>
                                                            <TextField
                                                                id="outlined-multiline-static"
                                                                label="Question"
                                                                placeholder='Enter Question'
                                                                fullwidth
                                                                {...field}
                                                                inputProps={{
                                                                    disabled: enableJuryEdit
                                                                }}
                                                            />} />
                                                    <FormHelperText className='text-danger'>
                                                        {errors.panelRounds?.[nestIndex]?.panelRoundQuestions?.[k]?.question && errors.panelRounds?.[nestIndex]?.panelRoundQuestions?.[k]?.question.message}
                                                    </FormHelperText>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <InputLabel id="demo-simple-select-label">Question Type</InputLabel>
                                                    <Controller name={`panelRounds.${nestIndex}.panelRoundQuestions.${k}.questionType`} control={control} defaultValue="" rules={{ required: false }}
                                                        render={({ field }) =>
                                                            <Select
                                                                {...field}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Age"
                                                                disabled={enableJuryEdit}
                                                            >
                                                                <MenuItem value={'yesno'}>Yes/No</MenuItem>
                                                                <MenuItem value={'scoring'}>Scoring</MenuItem>
                                                            </Select>} />
                                                    <FormHelperText className='text-danger'>
                                                        {errors.panelRounds?.[nestIndex]?.panelRoundQuestions?.[k]?.questionType && errors.panelRounds?.[nestIndex]?.panelRoundQuestions?.[k]?.questionType.message}
                                                    </FormHelperText>
                                                </FormControl>
                                            </TableCell>
                                            {
                                                enableJuryEdit === false && (
                                                    <TableCell align='center'>
                                                        <DeleteIcon disabled={enableJuryEdit} style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { removeQuestion(k) }} />
                                                    </TableCell>
                                                )
                                            }

                                        </TableRow>
                                    ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (<p className='text-center'>Question list is empty</p>))
                }

            </div>
            <Modal
                open={openTemplate}
                onClose={handleTemplateClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...modalstyle }} className="form_tag">
                    <Questiontemplate nestIndex={nestIndex} handleTemplateClose={handleTemplateClose} getValues={getValues} />
                </Box>

            </Modal>
        </div >
    )
}

export default Panelquestionadd
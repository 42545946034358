import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button } from '@mui/material';
import CustomLink from '../../../../common-page/CustomLink';
const Teaminfoview = ({ details }) => {
  return (
    <main>
      <section>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <div className='row'>
              {
                (details.startupFounders) && (
                  <div className='col-lg-12 col-12 my-3'>
                    <div className='div_header'>
                      <h6>Founder Details</h6>
                    </div>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Founder Name</TableCell>
                            <TableCell>LinkedIn Profile Link</TableCell>
                            <TableCell>Brief</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {details.startupFounders.map((list, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {list.name}
                              </TableCell>
                              <TableCell>
                                {
                                  list.linkedIn ? (<Link className='linkin-btn' to={`${list.linkedIn}`} target='_blank'><LinkedInIcon /></Link>) : (`-`)
                                }
                              </TableCell>
                              <TableCell>
                                {list.brief}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                    </TableContainer>
                  </div>
                )
              }
              {
                (details.startupAdvisories) && (
                  <div className='col-lg-12 col-12 my-3'>
                    <div className='div_header'>
                      <h6>Advisory / Mentors Details</h6>
                    </div>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Advisory / Mentors Name</TableCell>
                            <TableCell>LinkedIn Profile Link</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {details.startupAdvisories.map((list, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {list.name}
                              </TableCell>
                              <TableCell>
                                {
                                  list.linkedIn ? (<Link className='linkin-btn' to={`${list.linkedIn}`} target='_blank'><LinkedInIcon /></Link>) : (`-`)
                                }
                                {/* <Link className='linkin-btn' to={`${list.linkedIn}`} target='_blank'><LinkedInIcon /></Link> */}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>

                    </TableContainer>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Teaminfoview
import React, { useEffect, useContext, useState } from 'react';
import { Container, FormHelperText, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DataContext from '../../../context/DataContext';
import TablePagination from '@mui/material/TablePagination';
import Investorsadd from './Investorsadd';
import Signupalert from '../Signupalert';
import { LoadingButton } from '@mui/lab';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import apiService from '../../../api/apiService';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import DeleteIcon from '@mui/icons-material/Delete';
import Bulkupload from '../Bulkupload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import downloadExcel from '../../../hooks/downloadExcel';
import Investorprofile from './Investorprofile';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import notifyService from '../../../api/notifySerivce';
import { Controller, useForm } from 'react-hook-form';

const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const Investorslist = () => {
    const { openSuccess, handleSuccessClose, open, navigator, handleClose, modalstyle, searchInputValue, deleteUser,
        handleChangePage, handleChangeRowsPerPage, partnerListData, count, page, rowsPerPage, handleSearch, editValue,
        userListItem, investorProfileType, setInvestorProfileType, active, setActive, pageLoading, setPageLoading,
        tableIndexValue
    } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, reset, control, clearErrors, setValue, formState: { errors } } = useForm(
        { values: formData }
    );
    // setActive(1)
    useDidMountEffect(() => {
        (async () => await userListItem(5))()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchInputValue, investorProfileType, active])


    const [loading, setLoading] = useState(false);
    const [downloadValue, setDownloadValue] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [open1, setOpen1] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen1(!open1);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };
    const exportExcel = (download) => {
        setDownloadValue(download)
        setLoading(true);
        setPageLoading(true);
        var req
        if (download === 'All') {
            req = {
                "download": download,
                "roleId": 5,
                "listSize": rowsPerPage,
                "pageNumber": page + 1,
                "searchString": searchInputValue,
                "active": active,
                "investorProfileType": investorProfileType,
                "email": emails
            }
        } else {
            req = {
                "download": download,
                "roleId": 5,
                "listSize": rowsPerPage,
                "pageNumber": page + 1,
                "searchString": searchInputValue,
                "active": active,
                "investorProfileType": investorProfileType
            }
        }

        apiService('user/list', req, 'downloadpost').then((res) => {
            setLoading(false);
            setPageLoading(false);
            if (res) {
                if (res.data) {
                    if (res.data.byteLength === 184) {
                        notifyService('danger', 'Investor list is empty', '')
                    } else {
                        if (download !== 'All') {
                            downloadExcel(res.data, 'investor_')
                        } else {
                            notifyService('success', 'Success', 'An Excel file has been sent to your email address.')
                            handleExportAllClose()
                        }
                        setDownloadValue('')
                    }
                }
            }
        })
    }
    const [openBulk, setOpenBulk] = useState(false);
    const handleBulkOpen = () => setOpenBulk(true);
    const handleBulkClose = () => setOpenBulk(false);
    const addInvestor = () => {
        navigator('/admin/investor/add')

    }

    const editInvestor = (id) => {
        navigator(`/admin/investor/update/${btoa(id)}`)
    }
    const handleFiliter = (value) => {
        setInvestorProfileType(value)
        // userListItem(5)
    }

    const handleFilter = (value) => {
        setActive(value)
    }
    const handleActiveApi = (path, id) => {
        apiService(`user/${path}?id=${id}`, '', 'get').then((res) => {
            if (res) {
                if (res.data) {
                    userListItem(5)
                }
            }
        })
    }
    const [openAllExport, setopenAllExport] = useState(false)
    const handleExportAllClick = () => {
        // notifyService('success', 'Success', 'An Excel file has been sent to your email address.')
        setopenAllExport(true)
    }
    const handleExportAllClose = () => {
        reset()
        setopenAllExport(false)
    }
    let emails
    const onSubmit = (data) => {
        emails = data.email.split(',');
        exportExcel('All')
    };
    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };
    return (
        <section>
            <div className='container-fluid'>
                <div className='form_div chart'>
                    <div className='header_tag'>
                        <h5>Investors List</h5>
                    </div>
                    <div className='table_tag'>
                        <div className='table_header_tag set-res-flex'>
                            <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    value={searchInputValue}
                                    type='search'
                                    placeholder="Search with Name"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </SearchinputDiv>
                            {/* <Button className='ml-auto' variant="outlined" onClick={addInvestor}>+ Add Investors</Button> */}
                            <div className='ml-auto'>
                                <div className='d-flex align-item-center'>
                                    <div className='filiterinvestor mr-4'>
                                        <span className={(active === 1) ? ('common_span act_span') : ('common_span')} onClick={() => { handleFilter(1) }}>Active</span>
                                        <span className={(active === 0) ? ('common_span act_span') : ('common_span')} onClick={() => { handleFilter(0) }}>Inactive</span>
                                    </div>
                                    <div className='filiterinvestor'>
                                        <span className={(investorProfileType === 2) ? ('common_span act_span') : ('common_span')} onClick={() => { handleFiliter(2) }} >All</span>
                                        <span className={(investorProfileType === 0) ? ('common_span act_span') : ('common_span')} onClick={() => { handleFiliter(0) }}>Pending</span>
                                        <span className={(investorProfileType === 1) ? ('common_span act_span') : ('common_span')} onClick={() => { handleFiliter(1) }}>Completed</span>
                                    </div>
                                    {/* <LoadingButton
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<CloudDownloadIcon />}
                                        variant="outlined"
                                        onClick={exportExcel}
                                    >
                                        <span>EXPORT INVESTORS</span>
                                    </LoadingButton> */}
                                    {/* <FormControl sx={{ minWidth: 180, marginLeft: '15px' }}>
                                        <InputLabel className='search_sm_lbl'><i className="fas fa-file-excel c-green-icon"></i>Export Investors</InputLabel>
                                        <Select
                                            size="small"
                                            onChange={exportExcel}
                                            value={downloadValue}
                                        >
                                            <MenuItem value="All">All</MenuItem>
                                            <MenuItem value="This Page Only">This Page Only</MenuItem>
                                        </Select>
                                    </FormControl> */}

                                    <div className='exp-btn'>
                                        <Button
                                            id="demo-customized-button"
                                            aria-controls={open ? 'demo-customized-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            variant="outlined"
                                            disableElevation
                                            onClick={handleClick}
                                            startIcon={<CloudDownloadIcon />}
                                            endIcon={<KeyboardArrowDownIcon />}
                                        >
                                            Export Investors
                                        </Button>
                                        <StyledMenu
                                            id="demo-customized-menu"
                                            anchorEl={anchorEl}
                                            open={open1}
                                            onClose={handleClose1}
                                        >
                                            <MenuItem disableRipple onClick={handleExportAllClick}>
                                                Export All
                                            </MenuItem>
                                             <MenuItem onClick={(event) => { handleClose1(); exportExcel('This Page Only') }} disableRipple>
                                                This Page Only
                                            </MenuItem>
                                        </StyledMenu>
                                    </div>
                                </div>

                            </div>

                            {/* <Button className='ml-3' startIcon={<CloudUploadIcon />} variant="outlined" onClick={handleBulkOpen}>IMPORT INVESTORS</Button> */}
                        </div>
                        <div>
                            {
                                (partnerListData.length) ? (
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: 440 }}>
                                            <Table aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Email Id</TableCell>
                                                        <TableCell>Phone Number</TableCell>
                                                        <TableCell align='center'>Account status</TableCell>
                                                        <TableCell align='center'>Status</TableCell>
                                                        <TableCell align='center'>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {partnerListData.map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {
                                                                    tableIndexValue(rowsPerPage, page, index)
                                                                }
                                                            </TableCell>
                                                            <TableCell>{row.name}</TableCell>
                                                            <TableCell>
                                                                {row.email}
                                                                {
                                                                    (row.accountVerified === true) ? (
                                                                        <span className='verify_span'>(<i className="fas fa-check-circle"></i> Verified)</span>
                                                                    ) : (<span className='inprogress_span'>(Not verified)</span>)
                                                                }
                                                            </TableCell>
                                                            <TableCell>{row.phone}</TableCell>
                                                            <TableCell align='center'>
                                                                {
                                                                    (row.active === false) && (<span className='no_span'>Disabled</span>)
                                                                }
                                                                {
                                                                    (row.active === true) && (<span className='yes_span'>Enabled</span>)
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {
                                                                    (row.acceptTermsAndConditions === false) && (<span className='no_span'>Pending</span>)
                                                                }
                                                                {
                                                                    (row.acceptTermsAndConditions === true) && (<span className='yes_span'>Completed</span>)
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {
                                                                    (row.accountVerified === true) && (<span className='edit_span c-pointer ml-2' onClick={() => editInvestor(row.id)}>Edit</span>)
                                                                }
                                                                {
                                                                    (row.active === true) && (<span className='no_span c-pointer ml-2' onClick={() => { handleActiveApi('remove', row.id) }}>Disable</span>)
                                                                }
                                                                {
                                                                    (row.active === false) && (<span className='yes_span c-pointer ml-2' onClick={() => { handleActiveApi('activate', row.id) }}>Enable</span>)
                                                                }
                                                                {/* <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => deleteUser(row.id)} /> */}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <caption>
                                                    <TablePagination
                                                        component="div"
                                                        count={count}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        style={{float:'left'}}
                                                    /></caption>
                                            </Table>

                                        </TableContainer>
                                    </Paper>
                                ) : (
                                    <div className='nodata_tag'>
                                        <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                        <h4>Investors list is empty</h4>
                                    </div>
                                )
                            }
                        </div>


                    </div>
                </div>

            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="form_tag">
                    <Investorprofile handleClose={handleClose} />
                </Box>

            </Modal>
            <Modal
                open={openSuccess}
                onClose={handleSuccessClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Signupalert handleSuccessClose={handleSuccessClose} />
                </Box>

            </Modal>
            <Modal
                open={openBulk}
                onClose={handleBulkClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Bulkupload handleBulkClose={handleBulkClose} title={'Investor'} api={'investor'} roleid={5} />
                </Box>
            </Modal>
            <Modal
                open={openAllExport}
                onClose={handleExportAllClose}
            >
                <Box sx={modalstyle} className="form_tag">
                    <div className='header_tag text-center'>
                        <h5 className='c-black'>Send Email Ids</h5>
                    </div>
                    <form
                        className="signin-form"
                        onSubmit={handleSubmit(onSubmit, onError)}
                    >
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="form-group mt-2">
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Email Id's is required" }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Email Id's *"
                                                placeholder="Enter Email Id's"
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText>
                                        For multiple emails use , (comma) inbetween Ex. test@startuptn.in,support@startuptn.in
                                    </FormHelperText>
                                    <FormHelperText className="text-danger">
                                        {errors.email && errors.email.message}
                                    </FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center mt-4 ">
                            <Button
                                variant="contained"
                                onClick={handleExportAllClose}
                                className="btn btn-cancel submit px-3"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className="btn btn-primary submit px-3 ml-3"
                            >
                                <span>Send</span>
                            </Button>
                        </div>
                    </form>
                    {/* <Bulkupload handleExportAllClose={handleExportAllClose} title={'Investor'} api={'investor'} roleid={5} /> */}
                </Box>
            </Modal>
        </section>
    )
}

export default Investorslist
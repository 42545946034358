import React, { Fragment, useContext, useState } from 'react'
import './fundingpartners.css'
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';
// import ReactReadMoreReadLess from "react-read-more-read-less";
import { Pagination, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
const style = {
    py: 0,
    width: '100%',
    maxWidth: 360,
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
};

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
const MoreLessText = ({ children }) => {
    console.log(children)
    const [more, setMore] = React.useState(false)
    return (
        <Fragment>
            <div className={"text" + (more ? '' : ' less')} >
                {children}
            </div>
            {
                <span className='c-blue c-pointer' onClick={() => setMore(!more)}> {/* toggle state*/}
                    {more ? 'Show Less' : 'Show More'}
                </span>
            }

        </Fragment>
    )
}
const Fundingpartners = () => {

    const { name } = useParams()
    const [totalPage, setTotalPage] = React.useState(1);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [searchString, setSearchString] = useState('');
    const [organizationType, setOrganizationType] = useState(name);   
    useDidMountEffect(() => {
        (async () => await listApi())()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, organizationType, searchString])
    const [partnerList, setPartnerList] = useState([])
    const handleReset = () => {
        setSearchString('')
        setSearchInputValue('')
        setPage(1)
    }
    const handleSearch = (value) => {
        if (value.length > 3) {
            setSearchString(value)
        }
        setSearchInputValue(value)
        setPage(1)
    }
    const handleFiliter=(value)=>{
        setOrganizationType(value)
        setPage(1)
    }
    const listApi = () => {
        setPartnerList([])
        var req = {
            "listSize": 8,
            "pageNumber": page,
            "searchString": searchString,
            "organizationType": organizationType
        }
        apiService('user/investor/list', req, 'unauthpost').then((res) => {
            if (res) {
                if (res.data.responseModelList) {
                    setPartnerList(res.data.responseModelList)
                    setTotalPage(res.data.totalPages)
                }
            }
        })
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (event, value) => {
        setPage(value);
    };
    return (

        <div className='bg-body'>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            ></Box>
            <section>
                <div className='funding-bg'>
                    <img src='/images/fund-partners-bg.png' className='fund-bg-img' alt='' />
                    <div className='fund-partners-heading'>
                        <h3 className='font-weight-bold'>Find and Connect to the World of Opportunities</h3>
                    </div>
                    <div className='search-box'>
                        <div>
                            <img src='/images/vector.png' className='' alt='' />
                            <input className='fund-input' value={searchInputValue} onInput={(e) => handleSearch(e.target.value)} type="text" placeholder="Search Investor" />
                        </div>
                        <div className='drop-btn'>
                            {/* <div className='all-btn'>
                                <small className='cat-tit'>Categories</small>
                                <Button
                                    id="demo-customized-button"
                                    aria-controls={open ? 'demo-customized-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleClick}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    All
                                </Button>

                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >

                                    <MenuItem onClick={handleClose} disableRipple>
                                        Fund
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} disableRipple>
                                        Partners
                                    </MenuItem>
                                </StyledMenu>
                            </div> */}

                            {
                                searchInputValue === '' ? (
                                    <button className='search-btn' onClick={() => listApi()}>Search</button>
                                ) : (
                                    <button className='search-btn' onClick={() => handleReset()}>Reset</button>
                                )
                            }
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid'>
                    <div className='funding-filter '>
                        <span className={organizationType === 'All' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('All')}>All</span>
                        <span className={organizationType === 'Venture Capitalists' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('Venture Capitalists')}>Venture Capitalists</span>
                        <span className={organizationType === 'Private Equity' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('Private Equity')}>Private Equity</span>
                        <span className={organizationType === 'Government' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('Government')}>Government Agencies</span>
                        <span className={organizationType === 'Angel Networks' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('Angel Networks')}>Angel Networks</span>
                        {/*  */}
                        <span className={organizationType === 'Revenue-based Financing' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('Revenue-based Financing')}>Financing</span>
                        <span className={organizationType === 'Incubators' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('Incubators')}>Incubators</span>
                        <span className={organizationType === 'Accelerators' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('Accelerators')}>Accelerators</span>
                        <span className={organizationType === 'Family Offices' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('Family Offices')}>Family Offices</span>
                        <span className={organizationType === 'Venture Debt' ? 'n-active active-span' : 'n-active'} onClick={() => handleFiliter('Venture Debt')}>Venture Debt</span>
                    </div>
                </div>
            </section>

            <section className='py-5'>
                <div className='container-fluid'>
                    {
                        partnerList.length > 0 ? (
                            <div className='row'>
                                {
                                    partnerList.map((list, index) => (
                                        <div className='col-lg-3 col-md-6'>
                                            <div className='img-c-bg'>
                                                <div className='img-card'>
                                                    <center><img src={list.organizationLogoName ? (list.organizationLogoName) : ('/images/product-image.png')} className="" alt="..." /></center>
                                                </div>
                                                <div className='img-card1'>
                                                    <h6 className='font-weight-bold'>{list.name}</h6>
                                                    {/* <small> */}
                                                    {list.organizationBrief && (
                                                        <>
                                                            {
                                                                list.organizationBrief.length > 100 ? (
                                                                    <MoreLessText>
                                                                        <small>
                                                                            {list.organizationBrief}
                                                                        </small>
                                                                    </MoreLessText>
                                                                ) : (
                                                                    <small>
                                                                        {list.organizationBrief}
                                                                    </small>
                                                                )
                                                            }

                                                        </>
                                                    )}

                                                    {/* <ReactReadMoreReadLess
                                                            charLimit={100}
                                                            readMoreText={"Read more"}
                                                            readLessText={"Read less"}
                                                        >
                                                            
                                                        </ReactReadMoreReadLess> */}

                                                    {/* </small> */}
                                                    <Divider variant="middle" className='my-3' />
                                                    {
                                                        list.preferredSectors && (
                                                            <>
                                                                <h6 className='font-weight-bold'>Sectors</h6>
                                                                <div className='span-tag'>
                                                                    {
                                                                        list.preferredSectors.length <= 3 ? (
                                                                            <>
                                                                                {

                                                                                    list.preferredSectors.map((res) => (
                                                                                        <span>{res}</span>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <MoreLessText>
                                                                                {

                                                                                    list.preferredSectors.map((res) => (
                                                                                        <span>{res}</span>
                                                                                    ))
                                                                                }
                                                                            </MoreLessText>
                                                                        )
                                                                    }


                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    totalPage > 1 && (
                                        <div className='col-12 page_div_investor'>
                                            <Stack spacing={2}>
                                                <Pagination count={totalPage} page={page} onChange={handleChange} />
                                            </Stack>
                                        </div>
                                    )
                                }
                            </div>

                        ) : (
                            <div className='nodata_tag'>
                                <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                <h4>Onboarding Soon...</h4>
                            </div>
                        )
                    }
                </div>
            </section>
        </div>
    )
}

export default Fundingpartners
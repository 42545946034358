import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Box, Accordion, AccordionDetails, style, Switch, AccordionSummary, Button, styled, FormControl, FormControlLabel, Paper, MenuItem, FormHelperText, FormLabel, IconButton, InputAdornment, InputLabel, Typography, Modal, OutlinedInput, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Autocomplete, Container } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
// import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
// import PreliminaryTextPart1 from './PreliminaryTextPart1';
const fileTypes = ["pdf"];
// const fileTypesExcel = ["pdf", "XLSX"];

const FddSectionH = () => {
  const [formData, setFormData] = useState({});
  const [isAccordionOpen1, setIsAccordionOpen1] = useState(true);
  const { handleBackapp, setPageLoading, AntSwitch, validateNumberonly,navigator } = useContext(DataContext)
  const { handleSubmit, setValue, watch, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const {
    fields: startupDeptorsFields,
    append: startupDeptorsAppend,
    remove: startupDeptorsRemove
  } = useFieldArray({
    control,
    name: "startupDebtors"
  });
  const addstartupDeptorsBtn = () => {
    startupDeptorsAppend({
      id: 0,
      debtor: "",
      amount: "",
    })
  }
  const deletestartupDeptorsBtn = async (index) => {
    const id = getValues(`startupDebtors.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`startup/fdd/sech/startupDebtors/remove?id=${id}`, '', 'delete').then((res) => {
        if (res) {
          if (res.data.responseStatus === 'Success') {
            indexBaseDeletestartupDeptors(index)
          }
        }
      })
    } else {
      indexBaseDeletestartupDeptors(index)
    }
  }
  const indexBaseDeletestartupDeptors = (index) => {
    var startupTeams = getValues('startupDebtors')
    if (startupTeams.length === 1 && index === 0) {
      // var val = getValues()
      // val.startupDebtors = [{
      // id: 0,
      // debtor: "",
      // amount: "", 
      // }]
      // setFormData(val)
      startupDeptorsRemove(index)
      var val = getValues()
      setFormData(val)
    } else {
      startupDeptorsRemove(index)
      var val = getValues()
      setFormData(val)
    }
  }
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [fileUploadId, setFileUploadId] = useState(0)
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/sech/get`, '', 'get').then((res) => {
      if (res && res.data) {
        var data = res.data
        if (data.editForm === false) {
          setReadyOnlyValue(true)
        }
        if (res.data.id) {
          setFileUploadId(res.data.id)
        }

        setFormData(res.data)
      }
      setPageLoading(false)
    })
  }
  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type
        var imagedatatype = imagetype.split("/")
        var img_crt_type = imagedatatype[1]
        if (
          ((format === 'file') && (img_crt_type === "pdf")) ||
          ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
        ) {
          setValue(filekey, "")
          setValue(fileName, "")

          var fileValue = logoselecetdFile
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(',')
            var ImageValue = spl[1]
            var img_name = fileValue.name
            setValue(filekey, ImageValue)
            setValue(fileName, img_name)
            setValue(fileURL, logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId
            }
            console.log(req);
            // saveData(req)
          }
        } else {
          notifyService('danger', 'File Format Invalided', 'Please check your file format')
        }
      }
    }
  }
  const collectsGstNumbers = watch("collectsGstNumbers", false);
  const confirmationSystem = watch("confirmationSystem", false)
  const relatedPartyDebtors = watch("relatedPartyDebtors", false)
  const handleFilterCheck = (value, key, file, name, url) => {
    setValue(key, value);
    if (!value) {
      setValue(key, "");
      setValue(file, "");
      setValue(name, "");
      setValue(url, "");
    }
  };
  const handleFilter = (value, key) => {
    setValue(key, value);
    if (!value) {
      setValue(key, "");
    }
  };

  const saveData = (req) => {
    console.log(req);
    apiService('startup/ldd/sectionone/save', req, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          getApi()
        }
      }
    })
  }

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    setPageLoading(true);
    // if (data.shareHoldingPattern) {
    //   data.shareHoldingPattern.forEach(element => {
    //     if (element.date) {
    //       element.date = element.date.format('YYYY-MM-DD');
    //     }
    //   });
    // }
    // var req = {
    //   id: fileUploadId,
    //   directorsInterestedCompanies: data.directorsInterestedCompanies,
    //   shareHoldingPattern: data.shareHoldingPattern,
    //   sharesSubjectedToPledge: data.sharesSubjectedToPledge
    // }
    console.log(data);
    apiService('startup/fdd/sech/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-i");
        }
      }
    })
  }
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
};
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Receivables</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit,onError)}>
            <div className=''>
              <div className="row doc-row">
                <div className='col-12'>
                  <div className='doc-title mt-3'>
                    <h6>Receivables :</h6>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>What is the general credit period you give for all the Invoices</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="creditPeriod"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Credit Period"
                          placeholder="Enter Credit Period"
                          fullWidth
                          {...field}
                          type='text'
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.creditPeriod && errors.creditPeriod.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Do you collect the GST numbers from all the parities when you raise the invoice</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={collectsGstNumbers}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilter(!collectsGstNumbers, `collectsGstNumbers`)}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 mt-2'>
                  <FormLabel>Provide the list of receivables as on DD cut off date with number of days outstanding for each customer</FormLabel>
                  <div className="d-flex align-items-center">
                    <div className='form-group my-1'>
                      <Controller name="receivablesDDCutOff" control={control} defaultValue="" rules={{ required: false }}
                        render={({ field }) =>

                          <FileUploader
                            {...field}
                            handleChange={(e) => handleFileUpload(e,
                              "receivablesDDCutOff",
                              "receivablesDDCutOffName",
                              "receivablesDDCutOffURL",
                              'file')}
                            value={getValues('receivablesDDCutOff')}
                            name="file"
                            types={fileTypes}
                            children={
                              <div className={getValues('receivablesDDCutOff') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                <center>
                                  <p className={getValues('receivablesDDCutOff') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                    <span>
                                      <AttachFileIcon className="file-icon mr-2" />
                                      {getValues('receivablesDDCutOff') !== '' ? 'Uploaded (Click to Change)' : 'Receivables DD Cut Off Document'}
                                    </span>
                                  </p>
                                </center>
                              </div>
                            }
                            disabled={readyOnlyValue}
                          />
                        } />
                      <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                      <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.receivablesDDCutOff && errors.receivablesDDCutOff.message}</FormHelperText>
                    </div>
                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                      onClick={() => handleClickOpen(getValues('receivablesDDCutOffURL'))}
                      style={{ color: "#80809e" }}
                    > Preview
                    </Link>
                    </span>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>List out receivables which are doubtful or to be written off</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="doubtfulReceivables"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Doubt ful Receivables"
                          placeholder="Enter Doubt ful Receivables"
                          fullWidth
                          {...field}
                          type='text'
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.doubtfulReceivables && errors.doubtfulReceivables.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Do you have the system of getting confirmations from debtors at the end of each year</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={confirmationSystem}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilter(!confirmationSystem, `confirmationSystem`)}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Are there any related parties in the debtors outstanding</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={relatedPartyDebtors}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilter(!relatedPartyDebtors, `relatedPartyDebtors`)}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12 mb-3 pl-3">
                  <Accordion className='Addfundacc-bor' expanded={isAccordionOpen1} onChange={() => setIsAccordionOpen1(!isAccordionOpen1)}>
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className='Addfundacc'
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0
                      }}>
                        <AddCircleIcon className='add-icon mr-3' /> List of top 10 Debtors and the amount outstanding
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className='col-12 mb-3'>
                          <div>
                            {
                              (startupDeptorsFields.length >= 0) && (
                                <TableContainer component={Paper} className='table-back'>
                                  <Table aria-label="caption table">
                                    <TableBody >
                                      {startupDeptorsFields.map((field, index) => (
                                        <TableRow key={index}>
                                          <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                          <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                            <FormControl sx={{ width: '100%' }} >
                                              <Controller name={`startupDebtors.${index}.debtor`} control={control} defaultValue="" rules={{ required: 'Date of policy Policy is required' }}
                                                render={({ field }) =>
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Debtor *"
                                                    placeholder='Enter Debtor'
                                                    fullwidth
                                                    {...field}
                                                    type='text'
                                                    inputProps={{ readOnly: readyOnlyValue }}
                                                  />
                                                } />
                                              <FormHelperText className='text-danger'>
                                                {errors.startupDebtors?.[index]?.debtor && errors.startupDebtors?.[index]?.debtor.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                            <FormControl sx={{ width: '100%', paddingTop: '8px' }} >
                                              <Controller name={`startupDebtors.${index}.amount`} control={control} defaultValue="" rules={{ required: 'Value is required' }}
                                                render={({ field }) =>
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Amount *"
                                                    placeholder='Enter Amount'
                                                    fullwidth
                                                    {...field}
                                                    type='text'
                                                    inputProps={{ readOnly: readyOnlyValue }}
                                                    onKeyDown={(e)=>validateNumberonly(e)}
                                                  />} />
                                              <FormHelperText className='text-danger'>
                                                {errors.startupDebtors?.[index]?.amount && errors.startupDebtors?.[index]?.amount.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>                                          
                                          <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                            <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deletestartupDeptorsBtn(index) : <></> }} />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <center>
                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addstartupDeptorsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                  </center>
                                </TableContainer>
                                //     ) : (
                                // <div className='nodata_tag'>
                                //     <p>Fundraising history details is empty</p>
                                // </div>
                              )
                            }
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
        <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      </section>
    </>
  )
}


export default FddSectionH
import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DataContext from '../../../context/DataContext';
import { Dialog } from '@mui/material';
const Scsthubavpremarks = ({ remarks,lblName }) => {
    const { modalstyle } = useContext(DataContext)
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            {
                remarks ? (
                    <Button onClick={() => setOpenModal(true)} className='text-cap'>{lblName}</Button>
                ) : (`-`)
            }
            <Dialog
                open={openModal}
                onClose={() => setOpenModal(true)}
                maxWidth="md"
            >
                <Box className="form_tag">
                    <div className='p-5'> 
                        <p>{remarks}</p>
                        <div className='text-center'>
                            <Button onClick={() => setOpenModal(false)} variant="outlined">Ok</Button>
                        </div>
                    </div>
                </Box>
            </Dialog>
        </>
    )
}

export default Scsthubavpremarks
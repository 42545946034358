import React, { useContext, useEffect, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Autocomplete,
  FormControl,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  NativeSelect,
  InputBase,
} from "@mui/material";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { styled } from '@mui/system';
import { styled, alpha } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import DataContext from "../../../context/DataContext";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiCalendarPicker-root": {
    "& .MuiPickersCalendarHeader-label": {
      // Additional styling for month and year selection if needed
    },
  },
}));

const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "17ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const OnBoradFilter = ({
  setPage,
  districtName,
  setDistrictName,
  scsthub,
  setScsthub,
  yearName,
  setYearName,
  toYearName,
  setToYearName,
  monthName,
  setMonthName,
  toMonthName,
  setToMonthName,
  manualName,
  setManualName,
  path,
  setSearchInputValue,
  searchInputValue,
  phaseValue,
  setPhaseValue,
  statusValue,
  setStatusValue,
  lblName,
}) => {
  // console.log(phaseValue);
  // console.log(statusValue);
  const roles = localStorage.getItem("role");
  const {
    yearListApi,
    yearListValue,
    endYearListValue,
    setSelectedYear,
    scstRemoveFiters,
  } = useContext(DataContext);
  const [cityList, setCityList] = useState([]);
  const [hubList, setHubList] = useState([]);

  // var localPath = localStorage.getItem('localPath')
  // useDidMountEffect(() => {

  //     if (localPath !== path) {
  //
  //         handleClear()
  //         localStorage.setItem('localPath', path)
  //     } else {
  //         localStorage.setItem('localPath', path)
  //     }
  // }, [path])
  useDidMountEffect(() => {
    scstFilterApi();
    localStorage.removeItem("phaseValueScst");
  }, []);
  const [phaseName, setPhaseName] = useState([]);
  const scstFilterApi = () => {
    axios
      .get("/json/scstfilter.json", "")
      .then((result) => {
        setPhaseName(result.data);
        debugger;
        if (phaseValue) {
          handlePhaseFiter(phaseValue, result.data);
        }
      })
      .catch((err) => {});
  };
  useDidMountEffect(() => {
    yearListApi();
  }, [yearName]);
  useDidMountEffect(() => {
    getScstHub();
    handleSelectYear(yearName, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue]);
  useDidMountEffect(() => {
    getCityApi();
  }, [scsthub]);
  // useDidMountEffect(() => {
  //     handleClear()

  // }, [])
  const getCityApi = async () => {
    var role = localStorage.getItem("role");
    let url;
    if (role === "SCSTHUB") {
      url = "admin/district/get";
    } else {
      // url = `admin/dropdownvalues/get?key=district`
      url = `admin/hub/district/get?hub=${scsthub}`;
    }
    await apiService(url, "", "get").then((res) => {
      if (res && res.data)
        if (role === "SCSTHUB") {
          setCityList(res.data);
        } else {
          // var val = res.data.dropDownValues;
          // setCityList(val.districts);
          if (res && res.data && res.data.districts) {
            var dis = res.data.districts;
            dis.unshift("All");
            setCityList(dis);
          }
        }
    });
  };
  const getScstHub = async () => {
    await apiService(`partner/hub/filter`, "", "get")
      .then((res) => {
        if (res.data) {
          var hub = res.data;
          hub.unshift("All");
          setHubList(hub);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleSelectCity = (value) => {
    setDistrictName(value);
    localStorage.setItem("districtNameScst", value);
    localStorage.setItem("pageScst", 0);
    setPage(0);
  };

  const handleScstHub = (value) => {
    setScsthub(value);
    localStorage.setItem("hubScst", value);
    localStorage.setItem("pageScst", 0);
    setDistrictName("All");
    localStorage.setItem("districtNameScst", "");
  };
  let month = [
    "All",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let endmonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [monthList, setMonthList] = useState(month);
  const [filteredYears, setFilteredYears] = useState([]);
  const handleSelectYear = (value, change) => {
    let year = new Date().getFullYear();
    const currentMonthIndex = new Date().getMonth();
    setYearName(value);
    setSelectedYear(value);
    localStorage.setItem("yearNameScst", value);
    localStorage.setItem("pageScst", 0);
    setPage(0);
    let yearValue;
    if (typeof value === "string") {
      setMonthName(0);
      setSelectedMonth(monthList[0]);
      yearValue = Number(value);
    } else {
      yearValue = value;
      setSelectedMonth(monthList[monthName]);
    }
    if (year === yearValue) {
      let valPush = [];
      for (let i = 0; i <= currentMonthIndex + 1; i++) {
        var element = monthList[i];
        valPush.push(element);
      }
      setMonthList(valPush);
      let vallastPush = [];
      for (let i = 0; i <= currentMonthIndex + 1; i++) {
        var element = endmonth[i];
        vallastPush.push(element);
      }
      setSelectedEndMonthList(vallastPush);
    } else {
      setMonthList(month);
      setSelectedEndMonthList(endmonth);
    }

    if (value) {
      const selectedIndex = endYearListValue.indexOf(value.toString());
      var endyear = [];
      for (let i = 0; i < selectedIndex + 1; i++) {
        endyear.push(endYearListValue[i]);
      }
      setFilteredYears(endyear);
    } else {
      setFilteredYears(endYearListValue);
    }
    // if (change !== '') {
    //     setToYearName('');
    // }
    if (value === "All") {
      setToMonthName("");
    }
    setToYearName("");
  };
  const handelEndYear = (value) => {
    setToYearName(value);
    let year = new Date().getFullYear();
    if (year === Number(value)) {
      let index = new Date().getMonth();
      var month = endmonth;
      var endmonths = [];
      for (let i = 0; i < index + 1; i++) {
        endmonths.push(month[i]);
      }
      setSelectedEndMonthList(endmonths);
      setToMonthName(index + 1);
      setSelectedToMonth(endmonth[index]);
    } else {
      setSelectedEndMonthList(endmonth);
      setToMonthName(12);
      setSelectedToMonth(endmonth[11]);
    }

    localStorage.setItem("toYearNameScst", value);
    localStorage.setItem("pageScst", 0);
  };
  const [selectedMonth, setSelectedMonth] = useState(monthList[0]);
  const [selectedToMonth, setSelectedToMonth] = useState(monthList[12]);
  const [selectedEndMonthList, setSelectedEndMonthList] = useState(endmonth);
  // console.log(selectedEndMonthList);

  const handleSelectMonth = (e, value) => {
    const index = monthList.indexOf(value);
    setSelectedMonth(value);
    setMonthName(index);
    localStorage.setItem("monthNameScst", index);
    localStorage.setItem("pageScst", 0);
    setPage(0);
    // if (value) {
    //     var month = endmonth
    //     var endyear = []
    //     for (let i = 0; i < index + 1; i++) {
    //         endyear.push(month[i])
    //     }
    //     setSelectedEndMonthList(endyear);

    // } else {
    //     // setSelectedEndMonthList(month);
    // }
    // setToMonthName(null);
    // setSelectedToMonth(null)
  };

  const handleSelectToMonth = (e, value) => {
    const index = selectedEndMonthList.indexOf(value);
    setToMonthName(index);
    setSelectedToMonth(value);
    localStorage.setItem("toMonthNameScst", index);
    localStorage.setItem("pageScst", 0);
  };

  const handleMenualFiter = (value) => {
    setManualName(value);
    localStorage.setItem("manualNameScst", value);
    localStorage.setItem("pageScst", 0);
    setPage(0);
  };

  const [stageList, setStageList] = useState([]);
  const handlePhaseFiter = (value, phaseList) => {
    localStorage.removeItem("statusValueScst");
    setStageList([]);
    setPhaseValue(value);
    localStorage.setItem("phaseValueScst", value);
    let valueFilter;
    if (phaseList) {
      valueFilter = phaseList;
    } else {
      valueFilter = phaseName;
    }
    if (value !== "All") {
      var statusData = valueFilter.filter((phase) => phase.phaseName === value);
      if (statusData && statusData[0].stageList) {
        setStageList(statusData[0].stageList);
        var statusValueScst = localStorage.getItem("statusValueScst");
        if (statusValueScst === null) {
          localStorage.setItem(
            "statusValueScst",
            statusData[0].stageList[0].stageId
          );
          setStatusValue(statusData[0].stageList[0].stageId);
        } else {
          setStatusValue(statusValueScst);
        }
      }
    } else {
      handleStatusFiter(100);
    }
  };
  const handleStatusFiter = (value) => {
    setStatusValue(value);
    localStorage.setItem("statusValueScst", value);
    localStorage.setItem("pageScst", 0);
    setPage(0);
  };

  const d = new Date();
  let year = d.getFullYear();
  const handleClear = () => {
    debugger;
    setDistrictName("All");
    setScsthub("All");
    setMonthName(0);
    setSelectedMonth("All");
    setYearName("2022");
    setToYearName("");
    setManualName(0);
    scstRemoveFiters();
    setPhaseValue("All");
    setStatusValue(100);
    setToMonthName("");
    setSelectedEndMonthList([]);
    localStorage.removeItem("statusValueScst");
    localStorage.removeItem("phaseValueScst");
    handleSelectYear("2022", "");
    // if (path === 'jury/scst') {
    //     setStatusValue(1)
    // } else if (path === 'ic') {
    //     setStatusValue(11)
    // } else if (path === 'psc') {
    //     setStatusValue(14)
    // } else {
    //     setStatusValue(0)
    // }
  };
  const handleClearJury = () => {
    debugger;
    setDistrictName("All");
    setScsthub("All");
    setMonthName(0);
    setSelectedMonth("All");
    setYearName("2022");
    setToYearName("");
    setManualName(0);
    scstRemoveFiters();
    setPhaseValue("");
    setStatusValue(52);
    setToMonthName("");
    setSelectedEndMonthList([]);
    localStorage.removeItem("statusValueScst");
    localStorage.removeItem("phaseValueScst");
    handleSelectYear("2022", "");
    debugger;
  };
  const handleSearch = (searchValue) => {
    if (searchValue.length > 3) {
      setSearchInputValue(searchValue);
      localStorage.setItem("searchInputValueScst", searchValue);
      localStorage.setItem("pageScst", 0);
      setPage(0);
    } else {
      setSearchInputValue("");
      localStorage.setItem("searchInputValueScst", "");
      localStorage.setItem("pageScst", 0);
      setPage(0);
    }
  };
  return (
    <div>
      <div className="header_tag">
        <h5 className="mb-0">{lblName}</h5>
        <div className="ml-auto d-flex align-items-center">
          <SearchinputDiv
            sx={{ display: { xs: "none", sm: "block" } }}
            onChange={(e) => handleSearch(e.target.value)}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by Name"
              type="search"
              inputProps={{ "aria-label": "Search by Name" }}
            />
          </SearchinputDiv>
          <Button
            className="ml-3"
            variant="outlined"
            type="button"
            data-toggle="collapse"
            data-target="#collapseWidthExample"
          >
            <FilterAltIcon />
            Filters
          </Button>
        </div>
      </div>
      <div className="d-flex">
        {/* {
                    (path !== 'jury/scst') && (
                        <div className=" my-2">
                            <Button
                                variant="outlined"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseWidthExample"
                            >
                                <FilterAltIcon />
                                Filters
                            </Button>
                        </div>
                    )
                } */}
        <div className="ml-auto my-2"></div>
      </div>
      {/* filter code */}
      <div className="collapse mt-3 mb-3" id="collapseWidthExample">
        <div className="row d-flex justify-content-center">
          {path !== "validation" && path !== "evaluation" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    value={scsthub}
                    options={hubList}
                    onChange={(e, selectedOptions) =>
                      handleScstHub(selectedOptions)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Hub" fullWidth />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          )}
          <div className="col-lg-2">
            <div className="form-group">
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  disablePortal
                  value={districtName}
                  options={cityList}
                  onChange={(e, selectedOptions) =>
                    handleSelectCity(selectedOptions)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Startup District" fullWidth />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="col-lg-2">
            <div className="form-group">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-filled-label">
                  Onboarded Type
                </InputLabel>
                <Select
                  onChange={(e) => handleMenualFiter(e.target.value)}
                  value={manualName}
                >
                  <MenuItem value={0}>Both</MenuItem>
                  <MenuItem value={1}>Through Manual</MenuItem>
                  <MenuItem value={2}>Through Portal</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {roles === "SCSTHUB" && (
            <>
              <div className="col-lg-2">
                <div className="form-group">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">
                      Phase
                    </InputLabel>
                    <Select
                      onChange={(e) => handlePhaseFiter(e.target.value)}
                      value={phaseValue}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"Screening"}>Screening</MenuItem>
                      <MenuItem value={"Evaluation"}>Evaluation</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </>
          )}
          {(roles === "SCSTADMIN" || roles === "SCSTHUBAVP") && (
            <>
              <div className="col-lg-2">
                <div className="form-group">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">
                      Phase
                    </InputLabel>
                    <Select
                      onChange={(e) => handlePhaseFiter(e.target.value)}
                      value={phaseValue}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {phaseName.map((phase, index) => (
                        <MenuItem value={phase.phaseName}>
                          {phase.phaseName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </>
          )}
          {phaseValue !== "All" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => handleStatusFiter(e.target.value)}
                    value={statusValue}
                  >
                    {stageList.map((stage, index) => (
                      <MenuItem value={stage.stageId}>
                        {stage.stageName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          {path !== "jury/scst" && (
            <>
              <div className="col-lg-2">
                <div className="form-group">
                  <FormControl fullWidth>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      value={yearName}
                      options={yearListValue}
                      onChange={(e, selectedOptions) =>
                        handleSelectYear(selectedOptions, "yearchange")
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="From Year" fullWidth />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="form-group">
                  <FormControl fullWidth>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      value={selectedMonth}
                      options={monthList}
                      onChange={(e, selectedOptions) =>
                        handleSelectMonth(e, selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="From Month" fullWidth />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              {yearName !== "All" && (
                <div className="col-lg-2">
                  <div className="form-group">
                    {/* Second Autocomplete */}
                    <FormControl fullWidth>
                      <Autocomplete
                        disableClearable
                        disablePortal
                        value={toYearName}
                        options={filteredYears}
                        onChange={(event, newValue) => handelEndYear(newValue)}
                        renderInput={(params) => (
                          <TextField {...params} label="To Year" fullWidth />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              )}
              {toYearName !== "" && (
                <div className="col-lg-2">
                  <div className="form-group">
                    <FormControl fullWidth>
                      <Autocomplete
                        disableClearable
                        disablePortal
                        value={selectedToMonth}
                        options={selectedEndMonthList}
                        onChange={(e, selectedOptions) =>
                          handleSelectToMonth(e, selectedOptions)
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="To Month" fullWidth />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              )}
            </>
          )}
          {roles === "JURY" ? (
            <div className="col-lg-2">
              <Button
                variant="contained"
                sx={{ marginRight: "10px" }}
                onClick={() => handleClearJury()}
                data-toggle="collapse"
                data-target="#collapseWidthExample"
              >
                Clear
              </Button>
            </div>
          ) : (
            <div className="col-lg-2">
              <Button
                variant="contained"
                sx={{ marginRight: "10px" }}
                onClick={() => handleClear()}
                data-toggle="collapse"
                data-target="#collapseWidthExample"
              >
                Clear
              </Button>
            </div>
          )}
          {/* {
                        roles === 'JURY' && (
                            <div className="col-lg-2">
                                <Button
                                    variant="contained"
                                    sx={{ marginRight: "10px" }}
                                    onClick={() => handleClear()}
                                    data-toggle="collapse"
                                    data-target="#collapseWidthExample"
                                >
                                    Clear
                                </Button>
                            </div>
                        )} */}
        </div>
        {/* <div className="mt-3 mb-3">
                    <center>
                        <Button
                            variant="contained"
                            sx={{ marginRight: "10px" }}
                            onClick={() => handleClear()}
                            data-toggle="collapse"
                            data-target="#collapseWidthExample"
                        >
                            Clear
                        </Button>
                        <Button variant="contained" onClick={handleFilter}>
                            Filter
                        </Button>
                    </center>
                </div> */}
      </div>
    </div>
  );
};

export default OnBoradFilter;

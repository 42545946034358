import React from 'react'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import CloseIcon from '@mui/icons-material/Close';
import * as pdfjsLib from 'pdfjs-dist';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { Worker, Viewer, SpecialZoomLevel, Icon, MinimalButton, Position, Tooltip } from '@react-pdf-viewer/core';
import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const CommonViewPitchDeck = ({ viewImage, open, handleClose }) => {
    const pdfUrl = `data:image/pdf;base64,${viewImage}`
    const workerSrc = ` https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
    // https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js
    const disableScrollPlugin = () => {
        const renderViewer = ({ slot }) => {
            if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
                slot.subSlot.attrs.style = {
                    ...slot.subSlot.attrs.style,
                    overflow: 'hidden',
                };
            }

            return slot;
        };

        return {
            renderViewer,
        };
    };

    const disableScrollPluginInstance = disableScrollPlugin();
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { GoToPreviousPage, GoToNextPage } = pageNavigationPluginInstance;
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#293b7b', boxShadow: '0px 0px 5px gainsboro' }}>
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        View Document
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {/* <img src={viewImage} width="100%" height="100%" className='viewImage'/> */}
            <section className='p-5'>
                {
                    viewImage && (
                        <>
                            <div className='full-pdf' >
                                <Worker workerUrl={workerSrc}>
                                    <Viewer
                                        fileUrl={pdfUrl}
                                        defaultScale={SpecialZoomLevel.PageFit}
                                        plugins={[
                                            disableScrollPluginInstance,
                                            pageNavigationPluginInstance,
                                        ]}
                                    />
                                </Worker>
                                {
                                    viewImage && (
                                        <>
                                            <div className='pdf-left-arrow'>
                                                <GoToPreviousPage>
                                                    {(props) => (
                                                        <Tooltip
                                                            position={Position.BottomCenter}
                                                            target={
                                                                <MinimalButton onClick={props.onClick}>
                                                                    <Icon size={16}>
                                                                        <path d="M18.4.5,5.825,11.626a.5.5,0,0,0,0,.748L18.4,23.5" />
                                                                    </Icon>
                                                                </MinimalButton>
                                                            }
                                                            content={() => 'Previous page'}
                                                            offset={{ left: 0, top: 8 }}
                                                        />
                                                    )}
                                                </GoToPreviousPage>
                                            </div>
                                            <div className='pdf-right-arrow' >
                                                <GoToNextPage>
                                                    {(props) => (
                                                        <Tooltip
                                                            position={Position.BottomCenter}
                                                            target={
                                                                <MinimalButton onClick={props.onClick}>
                                                                    <Icon size={16}>
                                                                        <path d="M5.6.5,18.175,11.626a.5.5,0,0,1,0,.748L5.6,23.5" />
                                                                    </Icon>
                                                                </MinimalButton>
                                                            }
                                                            content={() => 'Next page'}
                                                            offset={{ left: 0, top: 8 }}
                                                        />
                                                    )}
                                                </GoToNextPage>
                                            </div>
                                        </>
                                    )
                                }

                            </div>
                        </>
                    )
                }
            </section>

        </Dialog>
    )
}

export default CommonViewPitchDeck
import React, { useContext, useState } from "react";
import {
  Autocomplete,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller } from "react-hook-form";
// import apiService from '../../../api/apiService';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormHelperText from "@mui/material/FormHelperText";
// import { MuiFileInput } from "mui-file-input";
import dayjs from "dayjs";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import PreviewIcon from "@mui/icons-material/Preview";
// import Fab from "@mui/material/Fab";
// import { Link } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import { styled } from "@mui/material/styles";
// import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
// import Stack from "@mui/material/Stack";
// import Typography from "@mui/material/Typography";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
// import Container from "@mui/material/Container";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 28,
//   height: 16,
//   padding: 0,
//   display: "flex",
//   "&:active": {
//     "& .MuiSwitch-thumb": {
//       width: 15,
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       transform: "translateX(9px)",
//     },
//   },
//   "& .MuiSwitch-switchBase": {
//     padding: 2,
//     "&.Mui-checked": {
//       transform: "translateX(12px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//     width: 12,
//     height: 12,
//     borderRadius: 6,
//     transition: theme.transitions.create(["width"], {
//       duration: 200,
//     }),
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor:
//       theme.palette.mode === "dark"
//         ? "rgba(255,255,255,.35)"
//         : "rgba(0,0,0,.25)",
//     boxSizing: "border-box",
//   },
// }));

const Applicationtanseed = () => {
  const {
    // validateEmail,
    // validatePhoneNumber,
    handleBacktanseed,
    readyOnlyValue,
    setReadyOnlyValue,
    navigator,
    VisuallyHiddenInput,
    setStartupProfileValue,
  } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    // watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const [sectorData, setSectorData] = useState([]);
  const [briefStartupDescriptionValue, setBriefStartupDescriptionValue] =
    useState("");
  const [acceleratorProgram, setAcceleratorProgram] = useState();
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    dropdownValueApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyOnlyValue, setReadyOnlyValue]);
  const [entityTypeList, setEntityTypeList] = useState([]);
  const [startupStageData, setStartupStageData] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=startup_min`,
      "",
      "get"
    ).then((res) => {
      var val = res.data.dropDownValues;
      if (val) {
        setSectorData(val.sectors);
        setEntityTypeList(val.legalEntities);
        setStartupStageData(val.startupStages);
      }
    });
  };
  const getApi = async () => {
    await apiService(`startup/startupdetails/get`, "", "get").then((res) => {
      if (res && res.data) {
        let response = res.data;
        if (response.id !== 0) {
          setFormData({});
          setFormData(response);
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
          if (response.entityType) {
            setEntityTypeValue(response.entityType);
          }
          if (response.tanseedCohort) {
            setAcceleratorProgram(response.tanseedCohort);
          }
          if (response.briefStartupDescription) {
            setBriefStartupDescriptionValue(response.briefStartupDescription);
          }
          if (response.incorporatedDate) {
            const tomorrow = dayjs(response.incorporatedDate, "DD/MM/YYYY");
            response.incorporatedDate = tomorrow;
            setDate(tomorrow);
          }
          if (response.entityType) {
            setEntityTypeValue(response.entityType);
          }
          if (response.startupLogoName === undefined) {
            setLogoDisplayUrl("/images/user.png");
            setStartupProfileValue(false);
          } else {
            setLogoDisplayUrl(response.startupLogoName);
            setLogoDisplayValue(response.startupLogo);
            setLogoDisplayName(response.startupLogo);
            setStartupProfileValue(true);
          }
          setValue("startupLogo", response.startupLogo);
          setValue("startupLogoName", response.startupLogoName);
        } else {
          setFormData(response);
        }
      }
    });
  };
  const [logoDisplayUrl, setLogoDisplayUrl] = useState("/images/user.png");
  const [logoDisplayValue, setLogoDisplayValue] = useState(null);
  const [logoDisplayName, setLogoDisplayName] = useState(null);
  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    setdata,
    setName,
    setUrl,
    format
  ) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          (format === "file" &&
            (img_crt_type === "jpeg" ||
              img_crt_type === "jpg" ||
              img_crt_type === "png" ||
              img_crt_type === "pdf")) ||
          (format === "image" &&
            (img_crt_type === "jpeg" ||
              img_crt_type === "jpg" ||
              img_crt_type === "png"))
        ) {
          setValue(filekey, "");
          setValue(fileName, "");
          setName("");
          setUrl("");
          if (event.target === undefined) {
            setdata(event);
          } else {
            setdata(event.target.files[0]);
          }
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            setUrl(logourl1);
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setName(img_name);
            clearErrors(filekey);
            clearErrors(fileName);
            if (filekey === "startupLogo") {
              setLogoInvalid(false);
            }
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    } else {
    }
  };

  const [date, setDate] = useState(null);
  const handleDateFormat = (newValue) => {
    const formattedDate = dayjs(newValue); // Convert newValue to a Day.js object
    setDate(formattedDate);
    setValue("incorporatedDate", formattedDate);
    clearErrors("incorporatedDate");
  };

  const [entityTypeValue, setEntityTypeValue] = useState("");
  const handleSelectEntityType = (value) => {
    setEntityTypeValue(value);
    setValue("entityType", value);
    clearErrors("entityType");
  };

  const handleSelectSector = (value) => {
    setValue("sector", value);
    clearErrors("sector");
  };

  const handleSelectStartupStage = (value) => {
    setValue("startupStage", value);
    clearErrors("startupStage");
  };

  const [loading, setLoading] = useState(false);
  const [logoInvalid, setLogoInvalid] = useState(false);
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      if (logoDisplayValue !== null) {
        setLoading(true);
        var getform = data;
        getform.incorporatedDate = data.incorporatedDate.format("DD/MM/YYYY");
        apiService("startup/startupdetails/save", getform, "post").then(
          (res) => {
            setLoading(false);
            if (res) {
              if (res.data.responseStatus === "Success") {
                notifyService("success", "Success", "Saved successfully !!");
                navigator("/startup/funding-financials/tanseed");
              }
            }
          }
        );
      } else {
        setLogoInvalid(true);
        notifyService("danger", "Error", "Please upload Startup Logo");
      }
    }
  };
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };

  return (
    <section className="container-fluid">
      <div className="form_div">
        <div className="d-flex align-items-center">
          <span className="back_span" onClick={handleBacktanseed}>
            <i className="fas fa-arrow-alt-circle-left"></i>
          </span>
          <h6 className="ml-2 mb-0">Startup Details</h6>
        </div>
        <hr />
        <form
          className="signin-form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="row">
            <div className="d-none">
              <div className="form-group">
                <Controller
                  name="id"
                  control={control}
                  defaultValue="0"
                  render={({ field }) => <TextField {...field} />}
                />
              </div>
            </div>
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="startupName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Startup Name is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Startup Name *"
                          placeholder="Enter Startup Name"
                          fullWidth
                          inputProps={{ readOnly: readyOnlyValue }}
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.startupName && errors.startupName.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="entityType"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Legal Entity is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            // limitTags={2}
                            options={entityTypeList}
                            onChange={(e, selectedOptions) =>
                              handleSelectEntityType(selectedOptions)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Legal Entity *" />
                            )}
                            disabled={readyOnlyValue}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.entityType && errors.entityType.message}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                {entityTypeValue === "Other" && (
                  <div className="col-lg-6 col-12">
                    <div className="form-group mt-2">
                      <Controller
                        name="entityTypeOther"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Type of Entity is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            label="Type of Entity *"
                            placeholder="Enter Type of Entity"
                            fullWidth
                            inputProps={{ readOnly: readyOnlyValue }}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.entityTypeOther &&
                          errors.entityTypeOther.message}
                      </FormHelperText>
                    </div>
                  </div>
                )}
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="website"
                      control={control}
                      defaultValue=""
                      rules={{
                        required:
                          "Company Website/LinkedIn Profile is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Company Website/LinkedIn Profile  *"
                          placeholder="Enter Company Website/LinkedIn Profile "
                          fullWidth
                          multiline
                          inputProps={{ readOnly: readyOnlyValue }}
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.website && errors.website.message}
                    </FormHelperText>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <Controller
                      name="incorporatedDate"
                      control={control}
                      defaultValue={date}
                      rules={{
                        required: "Date of Company Incorporation is required",
                      }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]} fullWidth>
                            <DatePicker
                              label="Incorporation Date *"
                              format="DD/MM/YYYY"
                              {...field}
                              readOnly={readyOnlyValue}
                              sx={{ width: "100% " }}
                              inputFormat="DD/MM/YYYY"
                              onChange={(newValue) =>
                                handleDateFormat(newValue)
                              }
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.incorporatedDate &&
                        errors.incorporatedDate.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <FormControl sx={{ width: "100%" }}>
                      {/* <InputLabel htmlFor="grouped-native-select">Current Growth Stage of Your Startup *</InputLabel> */}
                      <Controller
                        name="startupStage"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Growth Stage is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            limitTags={2}
                            options={startupStageData}
                            onChange={(e, selectedOptions) =>
                              handleSelectStartupStage(selectedOptions)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Current Growth Stage of Your Startup *"
                              />
                            )}
                            disabled={readyOnlyValue}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.startupStage && errors.startupStage.message}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="sector"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Sector is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            limitTags={2}
                            options={sectorData}
                            onChange={(e, selectedOptions) =>
                              handleSelectSector(selectedOptions)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Sector *" />
                            )}
                            disabled={readyOnlyValue}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.sector && errors.sector.message}
                      </FormHelperText>
                    </FormControl>
                    {/* <span className='font-sm'>Please check here https://bit.ly/spstn , for eligibility under category Rural Impact, Green Tech & Women-Led Startups</span> */}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <FormLabel>TANSEED Cohort? *</FormLabel>
                    <Controller
                      name="tanseedCohort"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <RadioGroup row name="position" {...field}>
                          <FormControlLabel
                            disabled={readyOnlyValue}
                            onChange={(e) => {
                              setAcceleratorProgram(e.target.value);
                            }}
                            defaultValue="null"
                            value="true"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            disabled={readyOnlyValue}
                            onChange={(e) => {
                              setAcceleratorProgram(e.target.value);
                              setValue("tanseedCohortData", "");
                            }}
                            defaultValue="null"
                            value="false"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.tanseedCohort && errors.tanseedCohort.message}
                    </FormHelperText>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  {(acceleratorProgram === "true" ||
                    acceleratorProgram === true) && (
                    <div className="form-group mt-2">
                      <Controller
                        name="tanseedCohortData"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "TANSEED Cohort details is required",
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Enter TANSEED Cohort *"
                            placeholder="Enter TANSEED Cohort details"
                            fullWidth
                            multiline
                            rows={4}
                            inputProps={{ readOnly: readyOnlyValue }}
                            {...field}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.tanseedCohortData &&
                          errors.tanseedCohortData.message}
                      </FormHelperText>
                    </div>
                  )}
                </div>

                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <Controller
                      name="briefStartupDescription"
                      control={control}
                      defaultValue=""
                      rules={{ required: "About Company is required" }}
                      render={({ field }) => (
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          defaultValue="Default Value"
                          variant="outlined"
                          label="About Company *"
                          placeholder="Describe about your company, products, Services in 300 words"
                          fullWidth
                          inputProps={{
                            readOnly: readyOnlyValue,
                            maxLength: 300,
                          }}
                          {...field}
                          value={briefStartupDescriptionValue}
                          // onChange={handleChangeBriefStartupDescription}
                          onChange={(e) => {
                            const { value } = e.target;
                            setBriefStartupDescriptionValue(value);
                            setValue("briefStartupDescription", value);
                            if (value !== "") {
                              clearErrors("briefStartupDescription");
                            } else {
                              setError("briefStartupDescription", {
                                message: "About Company is required",
                              });
                            }

                            // setError(value.length > 150);
                          }}
                          // error={error}
                          // helperText={error ? "Input must be at least 150 characters" : ""}
                        />
                      )}
                    />
                    <FormHelperText className="text-right">
                      {briefStartupDescriptionValue.length} / 300
                    </FormHelperText>
                    <FormHelperText className="text-danger">
                      {errors.briefStartupDescription &&
                        errors.briefStartupDescription.message}
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 text-center mb-3">
              <div className="logo_div">
                <img
                  src={logoDisplayUrl}
                  className="user_logo"
                  alt={logoDisplayName}
                />
                {readyOnlyValue === false ? (
                  <div className="camera_icon">
                    <Button
                      onChange={(e) =>
                        handleFileUpload(
                          e,
                          "startupLogo",
                          "startupLogoName",
                          setLogoDisplayValue,
                          setLogoDisplayName,
                          setLogoDisplayUrl,
                          "image"
                        )
                      }
                      component="label"
                      startIcon={<CameraAltIcon />}
                    >
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <span>
                  Startup Logo <span className="text-danger">*</span>
                </span>
              </div>
              <div>
                <span className="fn-12">Recommend Size (128px * 128px)</span>
              </div>
              <div>
                <span className="fn-12">
                  Maximum 5 mb allowed doc (png, jpg, jpeg){" "}
                  <span className="text-danger">*</span>
                </span>
              </div>
              {logoInvalid === true ? (
                <span className="text-danger">Startup Logo is required</span>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="form-group text-center mt-4 ">
            <Button
              variant="contained"
              onClick={handleBacktanseed}
              className="btn btn-cancel submit px-3"
            >
              Cancel
            </Button>
            {readyOnlyValue === false ? (
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="btn btn-primary submit px-3 ml-3"
              >
                <span>Save & Proceed Next</span>
              </LoadingButton>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default Applicationtanseed;

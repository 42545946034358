import React, { useEffect, useContext, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Modal,
  TextField,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import { LoadingButton } from "@mui/lab";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import apiService from "../../../api/apiService";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import downloadExcel from "../../../hooks/downloadExcel";
import Adminstartupupdate from "./Adminstartupupdate";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import notifyService from "../../../api/notifySerivce";
import { Link } from "react-router-dom";
import CustomLink from "../../common-page/CustomLink";
import { Controller, useForm } from "react-hook-form";
import SendEmail from "./SendEmail";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "17ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Adminstartuplist = () => {
  const {
    open,
    handleClose,
    modalstyle,
    editValue,
    deleteUser,
    pageLoading,
    setPageLoading,
    active,
    setActive,
    icon,
    checkedIcon,
    tableIndexValue,
    adminStartupRemoveFilter
  } = useContext(DataContext);
  // const [formData, setFormData] = useState({});
  const {
    getValues,
    reset,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  useDidMountEffect(() => {
    dropdownValueApi();
    dropdownDistrictApi();
  }, []);
  const [sectorData, setSectorData] = useState([]);
  const [revenueStagesData, setRevenueStagesData] = useState([]);
  const [startupStageData, setStartupStageData] = useState([]);
  const [districtData, setDistrictData] = useState([]);

  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=startup_min`,
      "",
      "get"
    ).then((res) => {
      var val = res.data.dropDownValues;
      setRevenueStagesData(val.revenueStages);
      // setEntityTypeList(val.legalEntities)
      setStartupStageData(val.startupStages);
      // setStartupCategoryData(val.startupCategory)
      setSectorData(val.sectors);
    });
  };
  const dropdownDistrictApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=district`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        setDistrictData(val.districts);
      }
    );
  };

  var pageNumberPartners = Number(localStorage.getItem('pageNumberAdminStartup'))
  const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

  var listSizePartners = Number(localStorage.getItem('listSizeAdminStartup'))
  const [rowsPerPage, setRowsPerPage] = useState(listSizePartners ? listSizePartners : 10);

  var activeAdminStartup = localStorage.getItem('activeAdminStartup')
  const [activeStartup, setActiveStartup] = useState(activeAdminStartup === '0' ? 0 : 1);

  var applicationStatusAdminStartup = localStorage.getItem('applicationStatusAdminStartup')
  const [submitted, setSubmitted] = useState(applicationStatusAdminStartup === 'false' ? false : true);

  const [partnerListData, setPartnerListData] = useState([]);
  const [count, setCount] = useState(0);
  var searchInputValuePartners = localStorage.getItem('searchStringAdminStartup')
  const [searchInputValue, setSearchInputValue] = useState(searchInputValuePartners ? searchInputValuePartners : "");
  useDidMountEffect(() => {

    // var searchInputValuePartners = localStorage.getItem('searchStringAdminStartup')
    // setSearchInputValue(searchInputValuePartners ? searchInputValuePartners : '')

    // var activeAdminStartup = localStorage.getItem('activeAdminStartup')
    // setActiveStartup(activeAdminStartup === '0' ? 0 : 1);

    // var submitted = localStorage.getItem('applicationStatusAdminStartup')
    // setSubmitted(submitted === 'false' ? false : true)

    var startupStageAdminStartup = localStorage.getItem('startupStageAdminStartup')
    setValue('startupStage', startupStageAdminStartup)

    var cityAdminStartup = localStorage.getItem('cityAdminStartup')
    setValue('city', cityAdminStartup ? cityAdminStartup : '')

    var revenuStageAdminStartup = localStorage.getItem('revenuStageAdminStartup')
    setValue('revenuStage', revenuStageAdminStartup ? revenuStageAdminStartup : '')

    var sectors = JSON.parse(localStorage.getItem('sectorsAdminStartup'))
    setValue('sectors', sectors ? sectors : [])
  }, [])
  useDidMountEffect(() => {
    (async () => await userListItem())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, searchInputValue, activeStartup, submitted]);
  const handleSearch = (searchValue) => {
    setSearchInputValue(searchValue);
    localStorage.setItem('searchStringAdminStartup', searchValue)
  };
  const userListItem = async () => {
    setPartnerListData([]);
    var data = getValues();
    var reqData = {
      roleId: 4,
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
      active: activeStartup,
      applicationStatus: submitted,
      startupStage: data.startupStage,
      city: data.city,
      revenuStage: data.revenuStage,
      sectors: data.sectors ? data.sectors : [],
    };

    await apiService("user/startup/admin/list", reqData, "post").then((res) => {
      if (res) {
        setCount(res.data.count);
        if (res.data.responseModelList) {
          setPartnerListData(res.data.responseModelList);
        } else {
          setPartnerListData([]);
        }
      }
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem('pageNumberAdminStartup', newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('listSizeAdminStartup', parseInt(event.target.value, 10))
    localStorage.setItem('pageNumberAdminStartup', 0)
    setPage(0);
  };

  const [loading, setLoading] = useState(false);
  const [downloadValueStartup, setDownloadValueStartup] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open2, setOpen2] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen2(!open2);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [emailValues, setEmailValues] = useState([]);
  const exportExcelStartup = (download, emails) => {
    setDownloadValueStartup(download);
    // setLoading(true);
    setPageLoading(true);
    var req;
    if (download === "All") {
      req = {
        download: download,
        roleId: 4,
        listSize: rowsPerPage,
        pageNumber: page + 1,
        searchString: searchInputValue,
        active: activeStartup,
        email: emails,
      };
    } else {
      req = {
        download: download,
        roleId: 4,
        listSize: rowsPerPage,
        pageNumber: page + 1,
        searchString: searchInputValue,
        active: activeStartup,
      };
    }
    ;
    apiService("user/list", req, "downloadpost").then((res) => {
      // setLoading(false);
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.byteLength === 184) {
            notifyService("danger", "Startup list is empty", "");
          } else {
            if (download !== "All") {
              downloadExcel(res.data, "startup_");
            } else {
              notifyService(
                "success",
                "Success",
                "An Excel file has been sent to your email address."
              );
              handleExportAllClose();
            }
            setDownloadValueStartup("");
          }
        }
      }
    });
  };
  // const exportExcel = () => {
  //     setLoading(true);
  //     apiService('export/users?roleid=4', '', 'download').then((res) => {
  //         setLoading(false);
  //         downloadExcel(res.data, 'startup_')
  //     })
  // }

  const handleFilterStartup = (value) => {
    setActiveStartup(value);
    localStorage.setItem('activeAdminStartup', value)
    setPage(0)
  };

  const handleFiltersubmit = (value) => {
    setSubmitted(value);
    localStorage.setItem('applicationStatusAdminStartup', value)

  };

  const handleActiveApi = (path, id) => {
    apiService(`user/${path}?id=${id}`, "", "get").then((res) => {
      if (res) {
        if (res.data) {
          userListItem(4);
        }
      }
    });
  };

  const [openAllExport, setopenAllExport] = useState(false);
  const handleExportAllClick = () => {
    setopenAllExport(true);
    // notifyService('success', 'Success', 'An Excel file has been sent to your email address.')
  };
  const handleExportAllClose = () => {
    reset();
    setopenAllExport(false);
  };
  const handleFilter = () => {
    var data = getValues()
    localStorage.setItem('startupStageAdminStartup', data.startupStage)
    localStorage.setItem('cityAdminStartup', data.city)
    localStorage.setItem('revenuStageAdminStartup', data.revenuStage)
    localStorage.setItem('sectorsAdminStartup', JSON.stringify(data.sectors))
    localStorage.setItem('pageNumberAdminStartup', 0)
    setPage(0);
    userListItem();
  };
  const handleClear = () => {
    reset();
    userListItem();
    adminStartupRemoveFilter()
  };

  return (
    <section className="">
      <div className="container-fluid">
        <div className="form_div chart">
          <div className="header_tag">
            <h5>Startup List</h5>
          </div>
          <div className="table_tag">
            <div className="table_header_tag set-res-flex align-item-center">
              <div>
                <SearchinputDiv
                  sx={{ display: { xs: "none", sm: "block" } }}
                  onChange={(e) => handleSearch(e.target.value)}
                >
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    value={searchInputValue}
                    type="search"
                    placeholder="Search with Name"
                    inputProps={{ "aria-label": "search" }}
                  />
                </SearchinputDiv>
              </div>
              <div className="ml-auto">
                <div className="d-flex align-item-center">
                  <div className="filiterinvestor mr-4">
                    <span
                      className={
                        activeStartup === 1
                          ? "common_span act_span"
                          : "common_span"
                      }
                      onClick={() => {
                        handleFilterStartup(1);
                      }}
                    >
                      Active Account
                    </span>
                    <span
                      className={
                        activeStartup === 0
                          ? "common_span act_span"
                          : "common_span"
                      }
                      onClick={() => {
                        handleFilterStartup(0);
                      }}
                    >
                      InActive Account
                    </span>
                  </div>
                  <div className="ml-auto">
                    <div className="d-flex align-item-center">
                      <div className="filiterinvestor mr-4">
                        {/* <span
                          className={
                            submitted === false
                              ? "common_span act_span"
                              : "common_span"
                          }
                          onClick={() => {
                            handleFiltersubmit(false);
                          }}
                        >
                          InProgress
                          
                        </span>
                        <span
                          className={
                            submitted === true
                              ? "common_span act_span"
                              : "common_span"
                          }
                          onClick={() => {
                            handleFiltersubmit(true);
                          }}
                        >
                          Submitted
                        </span> */}
                        <span
                          className={
                            submitted === true
                              ? "common_span act_span"
                              : "common_span"
                          }
                          onClick={() => {
                            handleFiltersubmit(true);
                          }}
                        >
                          Submitted Application

                        </span>
                        <span
                          className={
                            submitted === false
                              ? "common_span act_span"
                              : "common_span"
                          }
                          onClick={() => {
                            handleFiltersubmit(false);
                          }}
                        >

                          Not Submitted Application
                        </span>
                      </div>
                    </div>
                  </div>

                  <Button
                    variant="outlined"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseWidthExample"
                    className="mr-3 my-2"
                  >
                    <FilterAltIcon />
                    Filters
                  </Button>
                  {/* <LoadingButton
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<CloudDownloadIcon />}
                                        variant="outlined"
                                        onClick={exportExcel}
                                    >
                                        <span>EXPORT STARTUP</span>
                                    </LoadingButton> */}
                  <div className='exp-btn'>
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="outlined"
                      disableElevation
                      onClick={handleClick}
                      startIcon={<CloudDownloadIcon />}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Export Startups
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      anchorEl={anchorEl}
                      open={open2}
                      onClose={handleClose2}
                    >
                      <MenuItem disableRipple onClick={handleExportAllClick}>
                        Export All
                      </MenuItem>
                      <MenuItem onClick={(event) => { handleClose2(); exportExcelStartup('This Page Only', '') }} disableRipple>
                        This Page Only
                      </MenuItem>
                    </StyledMenu>
                  </div>
                </div>
              </div>
            </div>
            {/* filter code */}
            <div className="collapse my-3" id="collapseWidthExample">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-3">
                    <div className="form-group mt-2">
                      {/* <FormControl > */}

                      <Controller
                        name="sectors"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Sector is required" }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            multiple
                            limitTags={2}
                            value={value || []}
                            options={sectorData}
                            onChange={(e, newValue) => { onChange(newValue) }}
                            renderInput={(params) => (
                              <TextField {...params} label="Sectors *" />
                            )}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="fnt-sm c-blue">
                        Select one or more
                      </FormHelperText>
                      {/* </FormControl> */}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mt-2">
                      {/* <FormControl > */}
                      <Controller
                        name="startupStage"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Startup Stage is required" }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            // {...field}
                            fullWidth
                            disableClearable
                            disablePortal
                            value={value || []}
                            options={startupStageData}
                            onChange={(event, newValue) => { onChange(newValue) }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Startup Stage"
                                fullWidth
                              />
                            )}
                          />
                        )}
                      />
                      {/* </FormControl> */}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mt-2">
                      {/* <FormControl > */}
                      <Controller
                        name="revenuStage"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Revenu Stage is required" }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            // {...field}
                            fullWidth
                            disableClearable
                            disablePortal
                            value={value || []}
                            options={revenueStagesData}
                            onChange={(event, newValue) => { onChange(newValue) }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Revenu Stage"
                                fullWidth
                              />
                            )}
                          />
                        )}
                      />
                      {/* </FormControl> */}
                    </div>
                  </div>
                  {/* <div className="col-lg-3">
                                        <div className="form-group mt-2">
                                            <Controller
                                                name="annualRevenue"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: "Annual Revenue is required" }}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <Autocomplete
                                                        fullWidth
                                                        disableClearable
                                                        disablePortal
                                                        value={value || []}
                                                        options={revenueStagesData}
                                                        onChange={(event, newValue) => onChange(newValue)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Annual Revenue" fullWidth />
                                                        )}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div> */}
                  <div className="col-lg-3">
                    <div className="form-group mt-2">
                      {/* <FormControl > */}
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        rules={{ required: "City is required" }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            // {...field}
                            fullWidth
                            disableClearable
                            disablePortal
                            value={value || []}
                            options={districtData}
                            onChange={(event, newValue) => { onChange(newValue) }}
                            renderInput={(params) => (
                              <TextField {...params} label="City" fullWidth />
                            )}
                          />
                        )}
                      />
                      {/* </FormControl> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 mb-3">
                <center>
                  <Button
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    onClick={() => handleClear()}
                    data-toggle="collapse"
                    data-target="#collapseWidthExample"
                  >
                    Clear
                  </Button>
                  <Button variant="contained" onClick={handleFilter}>
                    Filter
                  </Button>
                </center>
              </div>
            </div>
            <div>
              {partnerListData.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Organization Name</TableCell>
                        <TableCell>Email Id</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Sector</TableCell>
                        <TableCell>Stage</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {partnerListData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {tableIndexValue(rowsPerPage, page, index)}
                          </TableCell>
                          <TableCell>
                            <CustomLink
                              to={`/admin/startup/details/${btoa(row.id)}`}
                            >
                              {row.name}
                            </CustomLink>
                            {/* {row.name} */}
                          </TableCell>
                          <TableCell>
                            {row.email}
                            {row.accountVerified === true ? (
                              <span className="verify_span">
                                (<i className="fas fa-check-circle"></i>{" "}
                                Verified)
                              </span>
                            ) : (
                              <span className="inprogress_span">
                                (Not verified)
                              </span>
                            )}
                          </TableCell>
                          <TableCell>{row.phone}</TableCell>
                          <TableCell>
                            {row.city ? row.city : '-'}
                            {/* {row.active === false && (
                              <span className="no_span">Inactive</span>
                            )}
                            {row.active === true && (
                              <span className="yes_span">Active</span>
                            )} */}
                          </TableCell>
                          <TableCell>{row.sector ? row.sector : `-`}</TableCell>
                          <TableCell>{row.startupStage ? row.startupStage : `-`}</TableCell>
                          <TableCell align="center">
                            <span
                              className="edit_span c-pointer ml-2"
                              onClick={() => editValue(row.id)}
                            >
                              Edit
                            </span>
                            {/* <EditIcon style={{ fill: '#66bb6a', cursor: 'pointer' }} onClick={() => editValue(row.id)} /> */}
                            {row.active === true && (
                              <span
                                className="no_span c-pointer ml-2"
                                onClick={() => {
                                  handleActiveApi("remove", row.id);
                                }}
                              >
                                Disable
                              </span>
                            )}
                            {row.active === false && (
                              <span
                                className="yes_span c-pointer ml-2"
                                onClick={() => {
                                  handleActiveApi("activate", row.id);
                                }}
                              >
                                Enable
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <caption>
                      <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{ float: 'left' }}
                      />
                    </caption>
                  </Table>
                </TableContainer>
              ) : (
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />
                  <h4>Startup list is empty</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag">
          <Adminstartupupdate handleClose={handleClose} />
        </Box>
      </Modal>
      <Modal open={openAllExport}>
        <Box sx={modalstyle} className="form_tag">
          <SendEmail
            handleExportAllClose={handleExportAllClose}
            exportExcelStartup={exportExcelStartup}
          />
          {/* <Bulkupload handleExportAllClose={handleExportAllClose} title={'Investor'} api={'investor'} roleid={5} /> */}
        </Box>
      </Modal>
    </section>
  );
};

export default Adminstartuplist;

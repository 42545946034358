import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DataContext from '../../../context/DataContext';

import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Viewjurynamelist from './Viewjurynamelist';
import Juryassign from './Juryassign';
import ScsthubavpEvaNavbar from '../ScsthubavpEvaNavbar';

const Assignjurylist = ({ name }) => {
    const { open, navigator, handleClose, modalstyle, searchInputValue,
        handleRountClose, page, rowsPerPage, setPageLoading, viewQues
    } = useContext(DataContext)
    useDidMountEffect(() => {
        // eslint-disable-next-line
        // (async () => await roundlistItem())()
        assignJurylist()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchInputValue])

    // const addRound = () => {
    //     navigator(`/${path}/round/add`)
    // }

    // const EditRoundBtn = () => {
    //     navigator(`/${path}/round/update`)
    // }
    const [assignJuryValue, setAssignJuryValue] = useState([])
    const assignJurylist = () => {
        setPageLoading(true)
        apiService(`partner/hub/list`, '', 'get').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.hub) {
                    setAssignJuryValue(res.data.hub)
                    // roundlistItem()
                    // setOpenModal(false);
                }
            }
        })
    }
    const [viewJuryData, setViewJuryData] = useState([])
    const [openJury, setOpenJury] = React.useState(false);
    const viewJury = (value, id, status) => {
        setViewJuryData(value)
        setId(id)
        setAssignStatus(status)
        setOpenJury(true);
    }
    const handleCloseJury = () => {
        setViewJuryData([])
        setId()
        setAssignStatus('')
        setOpenJury(false);
        assignJurylist()
    }

    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(0)
    const [assignStatus, setAssignStatus] = useState('')
    const assignStatusBtn = (id, status) => {
        setId(id)
        setAssignStatus(status)
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        assignJurylist()
    };



    return (
        <>
            {/* <ScsthubavpEvaNavbar /> */}
            <section className=''>
                <div className='container-fluid'>
                    <div className='form_div chart'>
                        <div className='header_tag'>
                            <h5>Assign Evaluator</h5>
                        </div>
                        <div className='table_tag'>
                            <div className='table_header_tag d-flex'>

                                {/* <Button variant="outlined" onClick={addRound}>+ Add Round</Button> */}
                            </div>
                            {
                                (assignJuryValue.length) ? (
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Hub Name</TableCell>
                                                    <TableCell align='center'>Evaluator</TableCell>
                                                    <TableCell align='center'>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {assignJuryValue.map((list, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{list.hubName}</TableCell>

                                                        <TableCell align='center'>
                                                            {
                                                                list.jury ? (<Button onClick={() => viewJury(list.jury, list.id)}>View Evaluator</Button>) : (`-`)
                                                            }

                                                        </TableCell>
                                                        {/* <TableCell align='center'>
                                                        {
                                                            list.panelRoundQuestions ? (
                                                                <Button onClick={() => viewQues(list.panelRoundQuestions)}>View Question</Button>
                                                            ) : (`-`)
                                                        }

                                                    </TableCell> */}
                                                        <TableCell align='center'>
                                                            <span className='assign_span' onClick={() => assignStatusBtn(list.id)}>Assign</span>
                                                            {/* <span className='unassign_span' onClick={() => assignStatusBtn(list.id, false)}>Un Assign</span> */}
                                                            {/* <EditIcon style={{ fill: '#66bb6a', cursor: 'pointer' }} onClick={() => { EditRoundBtn() }} /> */}
                                                            {/* <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={handleClickOpen} /> */}
                                                            {/* onClick={() => { removeRound(list.id) }} */}
                                                            {/* <Dialog
                                                            open={openModal}
                                                            onClose={handleCloseModal}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">
                                                                <center><img src='/images/alert.png' alt='' className='alert-image' /></center>
                                                                <h4 className='text-center p-3'>Confirm</h4>
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">
                                                                    <p className='remove-api-modal'>Deleting Round will remove all the score, remarks provided by jury / investor for the startups associated.</p>
                                                                    <p className='remove-api-modal text-center'>Are you sure ?</p>
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions className='d-flex justify-content-center mb-2'>
                                                                <Button className='remove-api-modal-cancel' onClick={handleCloseModal}>Cancel</Button>
                                                                <Button className='remove-api-modal-confirm' onClick={() => { removeRount(list.id) }}>Confirm</Button>
                                                            </DialogActions>
                                                        </Dialog> */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                    </TableContainer>
                                ) : (
                                    <div className='nodata_tag'>
                                        <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                        <p>Evaluator list is empty</p>
                                    </div>
                                )
                            }

                        </div>
                        <Dialog open={openModal} maxWidth={'lg'}>
                            <Juryassign id={id} handleCloseModal={handleCloseModal} name={name} />
                        </Dialog>
                        <Dialog open={openJury} maxWidth={'lg'} onClose={handleCloseJury}>
                            <Viewjurynamelist viewJuryData={viewJuryData} id={id} handleCloseJury={handleCloseJury} />
                        </Dialog>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Assignjurylist
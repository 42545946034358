import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
const fileTypes = ["pdf"];

const FddSectionAB = () => {
  const [formData, setFormData] = useState({});
  const { handleBackapp, setPageLoading } = useContext(DataContext)
  const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [assessmentOrders, setAssessmentOrders] = useState();
  const [changesInBankers, setChangesInBankers] = useState();
  const [statutoryAuditors, setStatutoryAuditors] = useState();
  const [pendingLitigations, setPendingLitigations] = useState();
  const [employeeStatusPresent, setEmployeeStatusPresent] = useState();
  const [downloadedStatementPresent, setDownloadedStatementPresent] = useState();
  const [staffWrittenOff, setStaffWrittenOff] = useState();
  const [fileUploadId, setFileUploadId] = useState(0)
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/secab/get`, '', 'get').then((res) => {
      if (res && res.data) {
        var data = res.data
        if (res.data.id) {
          setFileUploadId(res.data.id)
        }
        if(res.data.employeeStatusPresent){
          setEmployeeStatusPresent(res.data.employeeStatusPresent)
        }
        if(res.data.downloadedStatementPresent){
          setDownloadedStatementPresent(res.data.downloadedStatementPresent)
        }
        setFormData(res.data)
      }
      setPageLoading(false)
    })
  }

  const handlemeetingDetails = (e) => {
    handleFileUpload(e, "meetingDetails", "meetingDetailsName", "meetingDetailsURL", 'file');
  }
  const handleAuditedStaement = (e) => {
    handleFileUpload(e, "auditedStaement", "auditedStaementName", "auditedStaementURL", 'file');
  }
  const handleDownloadedStatement = (e) => {
    handleFileUpload(e, "downloadedStatement", "downloadedStatementName", "downloadedStatementURL", 'file');
  }
  const handleCompanyInsurance = (e) => {
    handleFileUpload(e, "companyInsurance", "companyInsuranceName", "companyInsuranceURL", 'file');
  }



  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type
        var imagedatatype = imagetype.split("/")
        var img_crt_type = imagedatatype[1]
        if (
          ((format === 'file') && (img_crt_type === "pdf")) ||
          ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
        ) {
          setValue(filekey, "")
          setValue(fileName, "")

          var fileValue = logoselecetdFile
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(',')
            var ImageValue = spl[1]
            var img_name = fileValue.name
            setValue(filekey, ImageValue)
            setValue(fileName, img_name)
            setValue(fileURL, logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId
            }
            console.log(req);
            // saveData(req)
          }
        } else {
          notifyService('danger', 'File Format Invalided', 'Please check your file format')
        }
      }
    }
  }

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    console.log(data);
    setPageLoading(true);
    apiService('startup/fdd/secab/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          handleBackapp()
        }
      }
    })
  }
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Contingent Liabilities</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>

            <div className="row doc-row2">


            <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Form 26AS statement and reconciliation of the same with Audited financials
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="auditedStaement" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleAuditedStaement}
                          value={getValues('auditedStaement')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('auditedStaement') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('auditedStaement') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('auditedStaement') !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.auditedStaement && errors.auditedStaement.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('auditedStaementURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
              <FormLabel>List of various insurance covers taken by the company
              </FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="companyInsurance" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleCompanyInsurance}
                          value={getValues('companyInsurance')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('companyInsurance') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('companyInsurance') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('companyInsurance') !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.companyInsurance && errors.companyInsurance.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('companyInsuranceURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Any notices received, assessment orders, demand relating to any statutory authorities Legal notice received, issued and status so far</FormLabel>
                  <Controller name="assessmentOrders" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setAssessmentOrders(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setAssessmentOrders(e.target.value); setValue('assessmentOrders', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.assessmentOrders && errors.assessmentOrders.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Provide the following details in a tabular form of meetings held in the preceeding 3 FY, including meetings held during the Stub period,including meetings held during the Stub period( Dates of Board Meetings
                  , Dates of AGM
                  , Minutes of the respective meetings.)</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="meetingDetails" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handlemeetingDetails}
                          value={getValues('meetingDetails')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('meetingDetails') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('meetingDetails') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('meetingDetails') !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.meetingDetails && errors.meetingDetails.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('meetingDetailsURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Any major disputes with customers, vendors, employees and status of the same.</FormLabel>
                  <Controller name="employeeStatusPresent" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setEmployeeStatusPresent(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setEmployeeStatusPresent(e.target.value); setValue('employeeStatus', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.employeeStatusPresent && errors.employeeStatusPresent.message}</FormHelperText>
                </div>
              </div>
              {
                (employeeStatusPresent === 'true' || employeeStatusPresent === true) && (
                  <>
                    <div className='col-lg-4 col-12 mt-2'>
                      <div className="form-group mt-2">
                        <Controller
                          name="employeeStatus"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Any major disputes"
                              placeholder="Enter Details"
                              fullWidth
                              multiline
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.employeeStatus && errors.employeeStatus.message}
                        </FormHelperText>
                      </div>
                    </div>

                  </>
                )
              }

             
              
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Any demand as per Traces w.r.t. Income Tax? Provide the downloaded statement from Traces.</FormLabel>
                  <Controller name="downloadedStatementPresent" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setDownloadedStatementPresent(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setDownloadedStatementPresent(e.target.value); setValue('assessmentOrders', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.downloadedStatementPresent && errors.downloadedStatementPresent.message}</FormHelperText>
                </div>
              </div>
              {
                (downloadedStatementPresent === 'true' || downloadedStatementPresent === true) && (
                  <>
                    <div className='col-lg-4  col-12 mt-2'>

                      <div className="d-flex align-items-center">
                        <div className='form-group my-1'>
                          <Controller name="downloadedStatement" control={control} defaultValue="" rules={{ required: false }}
                            render={({ field }) =>
                              <FileUploader
                                {...field}
                                handleChange={handleDownloadedStatement}
                                value={getValues('downloadedStatement')}
                                name="file"
                                types={fileTypes}
                                children={
                                  <div className={getValues('downloadedStatement') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                    <center>
                                      <p className={getValues('downloadedStatement') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues('downloadedStatement') !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            } />
                          <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                          <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.downloadedStatement && errors.downloadedStatement.message}</FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                          onClick={() => handleClickOpen(getValues('downloadedStatementURL'))}
                          style={{ color: "#80809e" }}
                        > Preview
                        </Link>
                        </span>
                      </div>
                    </div>

                  </>
                )
              }
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Any changes in Statutory Auditors
                  </FormLabel>
                  <Controller name="statutoryAuditors" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setStatutoryAuditors(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setStatutoryAuditors(e.target.value); setValue('statutoryAuditors', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.statutoryAuditors && errors.statutoryAuditors.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Any changes in Bankers</FormLabel>
                  <Controller name="changesInBankers" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setChangesInBankers(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setChangesInBankers(e.target.value); setValue('changesInBankers', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.changesInBankers && errors.changesInBankers.message}</FormHelperText>
                </div>
              </div>
            
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Any pending litigations against the company
                  </FormLabel>
                  <Controller name="pendingLitigations" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setPendingLitigations(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setPendingLitigations(e.target.value); setValue('pendingLitigations', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.pendingLitigations && errors.pendingLitigations.message}</FormHelperText>
                </div>
              </div>
            </div>


            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
        <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      </section>
    </>
  )
}

export default FddSectionAB
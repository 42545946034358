import React, { useContext, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
// import {  Autocomplete, Button, Fab, FormControl, FormHelperText, FormLabel, styled, Switch, Table, Paper, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import {
  Box,
  Accordion,
  AccordionDetails,
  style,
  Switch,
  AccordionSummary,
  Button,
  styled,
  FormControl,
  FormControlLabel,
  Paper,
  MenuItem,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Autocomplete,
  Container,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import notifyService from "../../../api/notifySerivce";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Viewimage from "../../../api/Viewimage";
import { Label } from "@mui/icons-material";
import cryptoJS from "../../../hooks/cryptoJS";
import CommonViewPitchDeck from "../../common-page/CommonViewPitchDeck";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DocumentTextscst from "./DocumentTextscst";
import { BsFiletypeXls } from "react-icons/bs";
const fileTypes = ["pdf", "XLSX"];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Documentscst = () => {
  const showAddInfo = localStorage.getItem("showInfo");
  const [formData, setFormData] = useState({});
  const { handleBackapp, setPageLoading } = useContext(DataContext);
  const {
    setValue,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [readyOnlyBDDValue, setReadyOnlyBDDValue] = useState(false);

  useDidMountEffect(() => {
    getApi();
    entityGetApi();
  }, []);
  const [entityType, SetEntityType] = useState("");
  const entityGetApi = async () => {
    setPageLoading(true);
    await apiService(`startup/startupdetails/get`, "", "get").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.entityType) {
          SetEntityType(res.data.entityType);
        }
      }
    });
  };
  const [fileUploadId, setFileUploadId] = useState(0);
  const getApi = async () => {
    setPageLoading(true);
    await apiService(`startup/documents/get`, "", "get").then((res) => {
      if (res && res.data) {
        // if (res.data.editForm === false) {
        //   setReadyOnlyValue(true);
        // }
        if (res.data.editForm === false && res.data.superEdit === false) {
          setReadyOnlyValue(true)
      }
      if (res.data.editForm === false && res.data.superEdit === true) {
          setReadyOnlyValue(false)
      }
      if (res.data.editForm === true && res.data.superEdit === false) {
          setReadyOnlyValue(false)
      }
        var data = res.data;
        if (data.gstLogin) {
          data.gstLogin = cryptodecryptJS(data.gstLogin);
        }
        if (data.gstPassword) {
          data.gstPassword = cryptodecryptJS(data.gstPassword);
        }
        if (data.incTaxLogin) {
          data.incTaxLogin = cryptodecryptJS(data.incTaxLogin);
        }
        if (data.incTaxPassword) {
          data.incTaxPassword = cryptodecryptJS(data.incTaxPassword);
        }
        if (res.data.id) {
          setFileUploadId(res.data.id);
        }

        setFormData(res.data);
      }
      setPageLoading(false);
    });
  };
  const handleFileChange = (e) => {
    handleFileUpload(
      e,
      "auditFinancialDoc",
      "auditFinancialDocName",
      "auditFinancialDocURL",
      "file"
    );
  };
  const handleAoaDoc = (e) => {
    handleFileUpload(e, "aoaDoc", "aoaDocName", "aoaDocURL", "file");
  };
  const handleStartupIndiaDoc = (e) => {
    handleFileUpload(
      e,
      "startupIndiaDoc",
      "startupIndiaDocName",
      "startupIndiaDocURL",
      "file"
    );
  };
  const handleEsiChllansDoc = (e) => {
    handleFileUpload(
      e,
      "esiChllansDoc",
      "esiChllansDocName",
      "esiChllansDocDocURL",
      "file"
    );
  };
  const handlePFChallans = (e) => {
    handleFileUpload(
      e,
      "pfChallansDoc",
      "pfChallansDocName",
      "pfChallansDocURL",
      "file"
    );
  };
  const handleDetailsOfShop = (e) => {
    handleFileUpload(
      e,
      "detailsOfShopDoc",
      "detailsOfShopDocName",
      "detailsOfShopDocURL",
      "file"
    );
  };
  const handleDetailsOfProfDoc = (e) => {
    handleFileUpload(
      e,
      "detailsOfProfDoc",
      "detailsOfProfDocName",
      "detailsOfProfDocURL",
      "file"
    );
  };
  const handleCompanyLoanDoc = (e) => {
    handleFileUpload(
      e,
      "companyLoanDoc",
      "companyLoanDocName",
      "companyLoanDocURL",
      "file"
    );
  };
  const handleAuditorConfirmDoc = (e) => {
    handleFileUpload(
      e,
      "auditorConfirmDoc",
      "auditorConfirmDocName",
      "auditorConfirmDocURL",
      "file"
    );
  };
  const handleEmploymentAgreeDoc = (e) => {
    handleFileUpload(
      e,
      "employmentAgreeDoc",
      "employmentAgreeDocName",
      "employmentAgreeDocURL",
      "file"
    );
  };
  const handleFiledCopiesDoc = (e) => {
    handleFileUpload(
      e,
      "filedCopiesDoc",
      "filedCopiesDocName",
      "filedCopiesDocURL",
      "file"
    );
  };
  const handleDir3KycDoc = (e) => {
    handleFileUpload(
      e,
      "dir3KycDoc",
      "dir3KycDocName",
      "dir3KycDocURL",
      "file"
    );
  };
  const handleInc20aDoc = (e) => {
    handleFileUpload(e, "inc20ADoc", "inc20ADocName", "inc20ADocURL", "file");
  };
  const handleFormAocDoc = (e) => {
    handleFileUpload(
      e,
      "formAocDoc",
      "formAocDocName",
      "formAocDocURL",
      "file"
    );
  };
  const handleFormMgtDoc = (e) => {
    handleFileUpload(
      e,
      "formMgtDoc",
      "formMgtDocName",
      "formMgtDocURL",
      "file"
    );
  };
  const handleFormDptDoc = (e) => {
    handleFileUpload(
      e,
      "formDptDoc",
      "formDptDocName",
      "formDptDocURL",
      "file"
    );
  };
  const handlellpIncorporationDoc = (e) => {
    handleFileUpload(
      e,
      "llpIncorporationDoc",
      "llpIncorporationDocName",
      "llpIncorporationDocURL",
      "file"
    );
  };
  const handlellpAgreeDoc = (e) => {
    handleFileUpload(
      e,
      "llpAgreeDoc",
      "llpAgreeDocName",
      "llpAgreeDocURL",
      "file"
    );
  };
  const handleFilLipDoc = (e) => {
    handleFileUpload(e, "filLipDoc", "filLipDocName", "filLipDocURL", "file");
  };
  const handleRunLlpDoc = (e) => {
    handleFileUpload(e, "runLlpDoc", "runLlpDocName", "runLlpDocURL", "file");
  };
  const handleForm3LlpDoc = (e) => {
    handleFileUpload(
      e,
      "form3LlpDoc",
      "form3LlpDocName",
      "form3LlpDocURL",
      "file"
    );
  };
  const handleform8SasDoc = (e) => {
    handleFileUpload(
      e,
      "form8SasDoc",
      "form8SasDocName",
      "form8SasDocURL",
      "file"
    );
  };
  const handleForm11LlpDoc = (e) => {
    handleFileUpload(
      e,
      "form11LlpDoc",
      "form11LlpDocName",
      "form11LlpDocURL",
      "file"
    );
  };
  const handleQuotationsDoc = (e) => {
    handleFileUpload(
      e,
      "quotationsDoc",
      "quotationsDocName",
      "quotationsDocURL",
      "file"
    );
  };
  const handleTextSummaryDoc = (e) => {
    handleFileUpload(
      e,
      "textSummaryDoc",
      "textSummaryDocName",
      "textSummaryDocURL",
      "file"
    );
  };
  const handlelBankStatementsDoc = (e) => {
    handleFileUpload(
      e,
      "bankStaComFinYearsDoc",
      "bankStaComFinYearsDocName",
      "bankStaComFinYearsDocURL",
      "file"
    );
  };
  const handlelbankStaComFinYearsExcel = (e) => {
    handleFileUpload(
      e,
      "bankStaComFinYearsExcel",
      "bankStaComFinYearsExcelName",
      "bankStaComFinYearsExcelURL",
      "excel"
    );
  };
  const handleBusinessPlanDoc = (e) => {
    handleFileUpload(
      e,
      "businessPlanDoc",
      "businessPlanDocName",
      "businessPlanDocURL",
      "excel"
    );
  };
  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];

        if (
          (format === "file" && img_crt_type === "pdf") ||
          (format === "excel" &&
            (img_crt_type === "vnd.ms-excel" ||
              img_crt_type ===
              "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
        ) {
          setValue(filekey, "");
          setValue(fileName, "");

          // setName('')
          // setUrl('')
          // if (event.target === undefined) {
          //   setdata(event)
          // } else {
          //   setdata(event.target.files[0])
          // }
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;

            // var val = getValues()
            // val.filekey=ImageValue
            // val.fileName=img_name
            // setFormData(val)
            // setUrl(logourl1)
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setValue(fileURL, logourl1);

            // setName(img_name)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);

            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId,
            };
            saveData(req);
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    } else {
    }
    console.log(getValues());
  };
  const saveData = (req) => {
    console.log(req);
    apiService("startup/documents/save", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          entityGetApi();
        }
      }
    });
  };

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClickOpens = (url) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = "filename.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onPdfClick = () => {
    const pdfUrl =
      "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Business_Plan_Template.xlsx";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Business_Plan_Template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src="/images/Fig-back.png" className="img-fluid" alt="" />
            </span>
            <h6 className="ml-2 mb-0">Documents Upload</h6>
          </div>
          <hr />
          <div className="form_div2">
            <div className="row doc-row">
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Audited Financials for all Completed Financials including
                  Unaudited Financials if any{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    {/* required: 'Audit Financial Document is required' */}
                    <Controller
                      name="auditFinancialDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleFileChange}
                          value={getValues("auditFinancialDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("auditFinancialDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("auditFinancialDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("auditFinancialDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Audit Financial Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.auditFinancialDoc &&
                        errors.auditFinancialDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit" onClick={() =>
                    handleClickOpen(getValues("auditFinancialDocURL"))
                  }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>Startup India Certificate </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="startupIndiaDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleStartupIndiaDoc}
                          value={getValues("startupIndiaDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("startupIndiaDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("startupIndiaDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("startupIndiaDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Startup India Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.startupIndiaDoc && errors.startupIndiaDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit" onClick={() =>
                    handleClickOpen(getValues("startupIndiaDocURL"))
                  }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Copies of Loan Documents if the company has taken loans from
                  Banks/Financial Institutions{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="companyLoanDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleCompanyLoanDoc}
                          value={getValues("companyLoanDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("companyLoanDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("companyLoanDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("companyLoanDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Banks/Financial Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.companyLoanDoc && errors.companyLoanDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit" onClick={() =>
                    handleClickOpen(getValues("companyLoanDocURL"))
                  }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <FormLabel>
                  Certificate from the Auditor confirming the involvement/non
                  involvement of the promoters/directors in other entities
                  Inside & Outside India{" "}
                </FormLabel>
                {/* <div className="row doc-row">
                    
                    <div className='col-lg-6  col-12 mt-2'> */}

                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    {/* required: 'involvement/non involvement Document is required' */}
                    <Controller
                      name="auditorConfirmDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleAuditorConfirmDoc}
                          value={getValues("auditorConfirmDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("auditorConfirmDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("auditorConfirmDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("auditorConfirmDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.auditorConfirmDoc &&
                        errors.auditorConfirmDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"
                    onClick={() =>
                      handleClickOpen(getValues("auditorConfirmDocURL"))
                    }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
                {/* </div>


                  </div> */}
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  ESI Challans for all completed Financial Years{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="esiChllansDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleEsiChllansDoc}
                          value={getValues("esiChllansDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("esiChllansDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("esiChllansDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("esiChllansDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "ESI Challans Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.esiChllansDoc && errors.esiChllansDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"
                    onClick={() =>
                      handleClickOpen(getValues("esiChllansDocDocURL"))
                    }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  PF Challans for all completed Financial Years{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="pfChallansDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handlePFChallans}
                          value={getValues("pfChallansDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("pfChallansDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("pfChallansDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("pfChallansDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "PF Challans Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.pfChallansDoc && errors.pfChallansDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"
                    onClick={() =>
                      handleClickOpen(getValues("pfChallansDocURL"))
                    }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>
                  Details of Shops & Establishment Act, 1948 if the company is
                  registered{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="detailsOfShopDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleDetailsOfShop}
                          value={getValues("detailsOfShopDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("detailsOfShopDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("detailsOfShopDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("detailsOfShopDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Establishment Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.detailsOfShopDoc &&
                        errors.detailsOfShopDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"
                    onClick={() =>
                      handleClickOpen(getValues("detailsOfShopDocURL"))
                    }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-4  col-12 mt-2">
                <FormLabel>Business Plan inlcuding Unit Economics </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="businessPlanDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleBusinessPlanDoc}
                          value={getValues("businessPlanDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("businessPlanDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("businessPlanDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("businessPlanDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Business Plan Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .xlsx
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.esiChllansDoc && errors.esiChllansDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"
                    onClick={() => {
                      handleClickOpens(getValues("businessPlanDocURL"));
                    }}>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      Download
                    </Link>
                  </span>
                </div>
                <div>
                  <span className="c-blue c-pointer ml-2" onClick={onPdfClick}>
                    Business Plan Template <BsFiletypeXls className="c-green" />
                  </span>
                </div>
              </div>
              {/* <div className="col-lg-4"></div> */}
              {/* {(entityType === 'Private Limited' || entityType === 'LLP' || entityType === 'Registered Partnership' || entityType === 'Proprietorship') && (
                
              )
              } */}

              {/* <div className="col-lg-4"></div> */}

              {/* {(entityType === 'Private Limited' || entityType === 'LLP' || entityType === 'Registered Partnership' || entityType === 'Proprietorship') && (
                
              )
              } */}
              {entityType === "Private Limited" && (
                <div className="col-lg-4  col-12 mt-2">
                  <FormLabel>AOA of Company </FormLabel>
                  <div className="d-flex align-items-center">
                    <div className="form-group my-1">
                      {/* 'Aoa Document is required' */}
                      <Controller
                        name="aoaDoc"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FileUploader
                            {...field}
                            handleChange={handleAoaDoc}
                            value={getValues("aoaDoc")}
                            name="file"
                            types={fileTypes}
                            children={
                              <div
                                className={
                                  getValues("aoaDoc") !== ""
                                    ? "fileupload-pdf-dpiit-bg1-active"
                                    : "fileupload-pdf-dpiit-bg1"
                                }
                              >
                                <center>
                                  <p
                                    className={
                                      getValues("aoaDoc") !== ""
                                        ? "fileupload-pdf-active"
                                        : "fileupload-pdf"
                                    }
                                  >
                                    <span>
                                      <AttachFileIcon className="file-icon mr-2" />
                                      {getValues("aoaDoc") !== ""
                                        ? "Uploaded (Click to Change)"
                                        : "AOA Document"}
                                    </span>
                                  </p>
                                </center>
                              </div>
                            }
                            disabled={readyOnlyValue}
                          />
                        )}
                      />
                      <FormHelperText className="ml-2">
                        Maximum size: 5 MB. File format .pdf
                      </FormHelperText>
                      <FormHelperText
                        sx={{ marginLeft: "5px" }}
                        className="text-danger"
                      >
                        {errors.aoaDoc && errors.aoaDoc.message}
                      </FormHelperText>
                    </div>
                    <span className="pre-btn-dpiit"
                      onClick={() => handleClickOpen(getValues("aoaDocURL"))}>
                      <VisibilityIcon className="icon-size mr-1" />
                      <Link
                        style={{ color: "#80809e" }}
                      >
                        {" "}
                        Preview
                      </Link>
                    </span>
                  </div>
                </div>
              )}
              {/* {(entityType === 'Private Limited' || entityType === 'LLP' || entityType === 'Registered Partnership' || entityType === 'Proprietorship') && (
                
              )
              } */}
              {/* {(entityType === 'Private Limited' || entityType === 'LLP') && ( */}

              {/* )
              } */}
              {/* {(entityType === 'Private Limited' || entityType === 'LLP' || entityType === 'Registered Partnership' || entityType === 'Proprietorship') && (
                
              )
              } */}

              {/* {(entityType === 'Private Limited' || entityType === 'LLP' || entityType === 'Registered Partnership' || entityType === 'Proprietorship') && (
                
              )
              } */}
              {(entityType === "Private Limited" ||
                entityType === "LLP" ||
                entityType === "Registered Partnership" ||
                entityType === "Proprietorship") && (
                  <div className="col-lg-4  col-12 mt-2">
                    <FormLabel>
                      Details of Professionals if the company is registered{" "}
                    </FormLabel>
                    <div className="d-flex align-items-center">
                      <div className="form-group my-1">
                        <Controller
                          name="detailsOfProfDoc"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FileUploader
                              {...field}
                              handleChange={handleDetailsOfProfDoc}
                              value={getValues("detailsOfProfDoc")}
                              name="file"
                              types={fileTypes}
                              children={
                                <div
                                  className={
                                    getValues("detailsOfProfDoc") !== ""
                                      ? "fileupload-pdf-dpiit-bg1-active"
                                      : "fileupload-pdf-dpiit-bg1"
                                  }
                                >
                                  <center>
                                    <p
                                      className={
                                        getValues("detailsOfProfDoc") !== ""
                                          ? "fileupload-pdf-active"
                                          : "fileupload-pdf"
                                      }
                                    >
                                      <span>
                                        <AttachFileIcon className="file-icon mr-2" />
                                        {getValues("detailsOfProfDoc") !== ""
                                          ? "Uploaded (Click to Change)"
                                          : "Professionals Document"}
                                      </span>
                                    </p>
                                  </center>
                                </div>
                              }
                              disabled={readyOnlyValue}
                            />
                          )}
                        />
                        <FormHelperText className="ml-2">
                          Maximum size: 5 MB. File format .pdf
                        </FormHelperText>
                        <FormHelperText
                          sx={{ marginLeft: "5px" }}
                          className="text-danger"
                        >
                          {errors.detailsOfProfDoc &&
                            errors.detailsOfProfDoc.message}
                        </FormHelperText>
                      </div>
                      <span className="pre-btn-dpiit"
                        onClick={() =>
                          handleClickOpen(getValues("detailsOfProfDocURL"))
                        }>
                        <VisibilityIcon className="icon-size mr-1" />
                        <Link
                          style={{ color: "#80809e" }}
                        >
                          {" "}
                          Preview
                        </Link>
                      </span>
                    </div>
                  </div>
                )}
              {/* {(entityType === 'Private Limited' || entityType === 'Registered Partnership' || entityType === 'Proprietorship') && (
                
              )
              } */}

              {(entityType === "Private Limited" || entityType === "LLP") && (
                <div className="col-lg-4  col-12 mt-2">
                  <FormLabel>
                    Copy of the Employement Agreement of the Promoters/Directors{" "}
                  </FormLabel>
                  <div className="d-flex align-items-center">
                    <div className="form-group my-1">
                      <Controller
                        name="employmentAgreeDoc"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FileUploader
                            {...field}
                            handleChange={handleEmploymentAgreeDoc}
                            value={getValues("employmentAgreeDoc")}
                            name="file"
                            types={fileTypes}
                            children={
                              <div
                                className={
                                  getValues("employmentAgreeDoc") !== ""
                                    ? "fileupload-pdf-dpiit-bg1-active"
                                    : "fileupload-pdf-dpiit-bg1"
                                }
                              >
                                <center>
                                  <p
                                    className={
                                      getValues("employmentAgreeDoc") !== ""
                                        ? "fileupload-pdf-active"
                                        : "fileupload-pdf"
                                    }
                                  >
                                    <span>
                                      <AttachFileIcon className="file-icon mr-2" />
                                      {getValues("employmentAgreeDoc") !== ""
                                        ? "Uploaded (Click to Change)"
                                        : "Employement Document"}
                                    </span>
                                  </p>
                                </center>
                              </div>
                            }
                            disabled={readyOnlyValue}
                          />
                        )}
                      />
                      <FormHelperText className="ml-2">
                        Maximum size: 5 MB. File format .pdf
                      </FormHelperText>
                      <FormHelperText
                        sx={{ marginLeft: "5px" }}
                        className="text-danger"
                      >
                        {errors.employmentAgreeDoc &&
                          errors.employmentAgreeDoc.message}
                      </FormHelperText>
                    </div>
                    <span className="pre-btn-dpiit"
                      onClick={() =>
                        handleClickOpen(getValues("employmentAgreeDocURL"))
                      }>
                      <VisibilityIcon className="icon-size mr-1" />
                      <Link
                        style={{ color: "#80809e" }}
                      >
                        {" "}
                        Preview
                      </Link>
                    </span>
                  </div>
                </div>
              )}
            </div>
            {(entityType === "Private Limited" || entityType === "LLP") && (
              <>
                <div className="doc-title mt-3">
                  <h6>Filed Copies of the Following :</h6>
                </div>

                <div className="row doc-row">
                  <div className="col-lg-4  col-12 mt-2">
                    <FormLabel>Filed Copies of the Following </FormLabel>
                    <div className="d-flex align-items-center">
                      <div className="form-group my-1">
                        <Controller
                          name="filedCopiesDoc"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FileUploader
                              {...field}
                              handleChange={handleFiledCopiesDoc}
                              value={getValues("filedCopiesDoc")}
                              name="file"
                              types={fileTypes}
                              children={
                                <div
                                  className={
                                    getValues("filedCopiesDoc") !== ""
                                      ? "fileupload-pdf-dpiit-bg1-active"
                                      : "fileupload-pdf-dpiit-bg1"
                                  }
                                >
                                  <center>
                                    <p
                                      className={
                                        getValues("filedCopiesDoc") !== ""
                                          ? "fileupload-pdf-active"
                                          : "fileupload-pdf"
                                      }
                                    >
                                      <span>
                                        <AttachFileIcon className="file-icon mr-2" />
                                        {getValues("filedCopiesDoc") !== ""
                                          ? "Uploaded (Click to Change)"
                                          : "Filed Copies Document"}
                                      </span>
                                    </p>
                                  </center>
                                </div>
                              }
                              disabled={readyOnlyValue}
                            />
                          )}
                        />
                        <FormHelperText className="ml-2">
                          Maximum size: 5 MB. File format .pdf
                        </FormHelperText>
                        <FormHelperText
                          sx={{ marginLeft: "5px" }}
                          className="text-danger"
                        >
                          {errors.filedCopiesDoc &&
                            errors.filedCopiesDoc.message}
                        </FormHelperText>
                      </div>
                      <span className="pre-btn-dpiit"
                        onClick={() =>
                          handleClickOpen(getValues("filedCopiesDocURL"))
                        }>
                        <VisibilityIcon className="icon-size mr-1" />
                        <Link
                          style={{ color: "#80809e" }}
                        >
                          {" "}
                          Preview
                        </Link>
                      </span>
                    </div>
                  </div>
                  {entityType === "Private Limited" && (
                    <>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>
                          DIR 3 KYC for all Directors for all completed
                          Financial Years{" "}
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="dir3KycDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleDir3KycDoc}
                                  value={getValues("dir3KycDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("dir3KycDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("dir3KycDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("dir3KycDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "Financial Years Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.dir3KycDoc && errors.dir3KycDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("dir3KycDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>INC 20 A </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="inc20ADoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleInc20aDoc}
                                  value={getValues("inc20ADoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("inc20ADoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("inc20ADoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("inc20ADoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "INC 20 A Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.inc20ADoc && errors.inc20ADoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("inc20ADocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>
                          Form AOC 4 for all completed Financial Years{" "}
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="formAocDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleFormAocDoc}
                                  value={getValues("formAocDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("formAocDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("formAocDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("formAocDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "AOC 4 Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.formAocDoc && errors.formAocDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("formAocDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>
                          Form MGT-7 or MGT-7A for all completed Financial Years{" "}
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="formMgtDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleFormMgtDoc}
                                  value={getValues("formMgtDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("formMgtDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("formMgtDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("formMgtDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "MGT-7 or MGT-7A Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.formMgtDoc && errors.formMgtDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("formMgtDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>
                          Form DPT-3 for all completed Financial Years{" "}
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="formDptDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleFormDptDoc}
                                  value={getValues("formDptDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("formDptDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("formDptDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("formDptDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "DPT-3 Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.formDptDoc && errors.formDptDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("formDptDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {entityType === "LLP" && (
                    <>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>
                          FiLLip (Form for Incorporation of Company){" "}
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="filLipDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleFilLipDoc}
                                  value={getValues("filLipDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("filLipDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("filLipDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("filLipDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "FiLLip Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.filLipDoc && errors.filLipDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("filLipDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>
                          RUN LLP (Form for Reserving a Name of LLP){" "}
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="runLlpDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleRunLlpDoc}
                                  value={getValues("runLlpDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("runLlpDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("runLlpDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("runLlpDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "RUN LLP Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.runLlpDoc && errors.runLlpDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("runLlpDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>
                          Form 3 (Information about LLP Agreement){" "}
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="form3LlpDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleForm3LlpDoc}
                                  value={getValues("form3LlpDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("form3LlpDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("form3LlpDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("form3LlpDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "Form 3 Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.form3LlpDoc && errors.form3LlpDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("form3LlpDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>
                          Form 8 (Statement of Account & Solvency) for all
                          completed Financial Years{" "}
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="form8SasDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleform8SasDoc}
                                  value={getValues("form8SasDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("form8SasDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("form8SasDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("form8SasDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "Form 8 Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.form8SasDoc && errors.form8SasDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("form8SasDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>
                          Form 11 (Annual Return of LLP) for all completed
                          Financial Years{" "}
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="form11LlpDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handleForm11LlpDoc}
                                  value={getValues("form11LlpDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("form11LlpDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("form11LlpDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("form11LlpDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "Form 11 Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.form11LlpDoc &&
                                errors.form11LlpDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("form11LlpDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>LLP Incorporation Certificate </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="llpIncorporationDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handlellpIncorporationDoc}
                                  value={getValues("llpIncorporationDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("llpIncorporationDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("llpIncorporationDoc") !==
                                              ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues(
                                              "llpIncorporationDoc"
                                            ) !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "LLP Incorporation Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.llpIncorporationDoc &&
                                errors.llpIncorporationDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(
                                getValues("llpIncorporationDocURL")
                              )
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4  col-12 mt-2">
                        <FormLabel>Copy of the LLP Agreement </FormLabel>
                        <div className="d-flex align-items-center">
                          <div className="form-group my-1">
                            <Controller
                              name="llpAgreeDoc"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <FileUploader
                                  {...field}
                                  handleChange={handlellpAgreeDoc}
                                  value={getValues("llpAgreeDoc")}
                                  name="file"
                                  types={fileTypes}
                                  children={
                                    <div
                                      className={
                                        getValues("llpAgreeDoc") !== ""
                                          ? "fileupload-pdf-dpiit-bg1-active"
                                          : "fileupload-pdf-dpiit-bg1"
                                      }
                                    >
                                      <center>
                                        <p
                                          className={
                                            getValues("llpAgreeDoc") !== ""
                                              ? "fileupload-pdf-active"
                                              : "fileupload-pdf"
                                          }
                                        >
                                          <span>
                                            <AttachFileIcon className="file-icon mr-2" />
                                            {getValues("llpAgreeDoc") !== ""
                                              ? "Uploaded (Click to Change)"
                                              : "LLP Agreement Document"}
                                          </span>
                                        </p>
                                      </center>
                                    </div>
                                  }
                                  disabled={readyOnlyValue}
                                />
                              )}
                            />
                            <FormHelperText className="ml-2">
                              Maximum size: 5 MB. File format .pdf
                            </FormHelperText>
                            <FormHelperText
                              sx={{ marginLeft: "5px" }}
                              className="text-danger"
                            >
                              {errors.llpIncorporationDoc &&
                                errors.llpIncorporationDoc.message}
                            </FormHelperText>
                          </div>
                          <span className="pre-btn-dpiit"
                            onClick={() =>
                              handleClickOpen(getValues("llpAgreeDocURL"))
                            }>
                            <VisibilityIcon className="icon-size mr-1" />
                            <Link
                              style={{ color: "#80809e" }}
                            >
                              {" "}
                              Preview
                            </Link>
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            <div className="row doc-row"></div>
            <div className="row doc-row">
              <div className="col-12">
                <div className="doc-title mt-3">
                  <h6>Bank Statements :</h6>
                </div>
              </div>
              <div className="col-lg-6  col-12 mt-2">
                <FormLabel>
                  Bank Statements for all completed financial years in both PDF
                  format with revenue and expenses transactions highlighted
                  seperately in different colours{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="bankStaComFinYearsDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handlelBankStatementsDoc}
                          value={getValues("bankStaComFinYearsDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("bankStaComFinYearsDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("bankStaComFinYearsDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("bankStaComFinYearsDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Bank Statements Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.bankStaComFinYearsDoc &&
                        errors.bankStaComFinYearsDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"
                    onClick={() =>
                      handleClickOpen(getValues("bankStaComFinYearsDocURL"))
                    }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-6  col-12 mt-2">
                <FormLabel>
                  Bank Statements for all completed financial years in both
                  Excel format with revenue and expenses transactions
                  highlighted seperately in different colours{" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="bankStaComFinYearsExcel"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handlelbankStaComFinYearsExcel}
                          value={getValues("bankStaComFinYearsExcel")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("bankStaComFinYearsExcel") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("bankStaComFinYearsExcel") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("bankStaComFinYearsExcel") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Bank Statements Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .xlsx
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.bankStaComFinYearsExcel &&
                        errors.bankStaComFinYearsExcel.message}
                    </FormHelperText>
                  </div>
                  {/* <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpens(getValues('bankStaComFinYearsExcelURL'))}
                    style={{ color: "#80809e" }}
                    
                  > Download
                  </Link>
                  </span> */}
                  <span className="pre-btn-dpiit"
                    onClick={() => {
                      handleClickOpens(
                        getValues("bankStaComFinYearsExcelURL")
                      );
                    }}>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      Download
                    </Link>
                  </span>
                </div>
              </div>
            </div>

            <div className="row doc-row">
              <div className="col-12">
                <div className="doc-title mt-3">
                  <h6>Quotations of Capex Component :</h6>
                </div>
              </div>
              <div className="col-lg-6  col-12 mt-2">
                <FormLabel>
                  Quotation Document (Should be addressed in the company's name){" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="quotationsDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleQuotationsDoc}
                          value={getValues("quotationsDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("quotationsDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("quotationsDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("quotationsDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Quotations Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.quotationsDoc && errors.quotationsDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"
                    onClick={() =>
                      handleClickOpen(getValues("quotationsDocURL"))
                    }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
              </div>

              <div className="col-lg-6  col-12 mt-2">
                <FormLabel>
                  Tech Summary (Should Include Technical Features & Working
                  Principle){" "}
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="textSummaryDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleTextSummaryDoc}
                          value={getValues("textSummaryDoc")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("textSummaryDoc") !== ""
                                  ? "fileupload-pdf-dpiit-bg1-active"
                                  : "fileupload-pdf-dpiit-bg1"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("textSummaryDoc") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("textSummaryDoc") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Tech Summary Document"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.textSummaryDoc && errors.textSummaryDoc.message}
                    </FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"
                    onClick={() =>
                      handleClickOpen(getValues("textSummaryDocURL"))
                    }>
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <DocumentTextscst
            entityType={entityType}
            setFormData={setFormData}
            formData={formData}
            readyOnlyValue={readyOnlyValue}
          />
        </div>
        <Viewimage
          viewImage={viewImage}
          open={open}
          handleClose={handleClose}
        />
      </section>
    </>
  );
};

export default Documentscst;

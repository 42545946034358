import React, { useState } from 'react'
import { Button, Container, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
const Reviewupdate = ({ handleViewClose, getData }) => {
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, formState: { errors } } = useForm(
        { values: formData }
    );
    useDidMountEffect(() => {
        // eslint-disable-next-line
        setFormData(getData)
    }, [getData]);
    // const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false);
    const onSubmit = (data) => {
        var getform = data
        getform.selected = Number(data.selected)
        apiService('partner/panel/review/save', getform, 'post').then((res) => {
            setLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Updated successfully !!')
                    handleViewClose()
                }
            }
        })
    }
    return (
        <section className=''>
            <Container>
                <div className='header_tag text-center'>
                    <h5 className='c-black'>Update Status</h5>
                </div>
                <div className=''>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-12 col-12 mb-3'>
                                <div className="form-group">

                                    <Controller row name="selected" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <>
                                                <RadioGroup
                                                    row
                                                    label="Selected"
                                                    name="position"
                                                    {...field}
                                                >
                                                    <FormLabel className='mr-3'>Selected </FormLabel>
                                                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="2" control={<Radio />} label="No" />
                                                    <FormControlLabel value="0" control={<Radio />} label="Under Review" />
                                                </RadioGroup>
                                            </>

                                        } />
                                    <FormHelperText className='text-danger'>{errors.selected && errors.selected.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-12 col-12 mb-3'>
                                <div className="form-group">
                                    <Controller name="remarks" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Remark"
                                                placeholder='Enter Remark'
                                                fullWidth
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.remarks && errors.remarks.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3"
                            >
                                <span>Save</span>
                            </LoadingButton>
                            <Button variant="contained" onClick={handleViewClose} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>
                        </div>
                    </form>
                </div>
            </Container>
        </section>
    )
}

export default Reviewupdate
import React, { useContext, useState } from 'react'
import Footer from '../footer-page/Footer'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect } from 'react';
import DataContext from '../../../context/DataContext';
import { Modal } from '@mui/material';
import Signuproutemodal from '../signup-page/signupModal/Signuproutemodal';
import Aos from "aos";
import 'aos/dist/aos.css'
import $ from 'jquery';
import Grid from '@mui/material/Grid';
import Howitswork from './Howitswork';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Navbar from '../../nav-page/Navbar';
import apiService from '../../../api/apiService';
const Home = () => {
  const { navigator, modalstyle, handleClose, open, handleOpen, setPageLoading } = useContext(DataContext)

  useDidMountEffect(() => {
    document.getElementById('theme').setAttribute('href', '/css/landing1.css');
  }, [])
  const [value, setValue] = useState('0');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [screenSize, setScreenSize] = useState(5)
  // useEffect(() => {
  //   size()
  // }, [])

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(5)
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4)
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(3)
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(3)
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(2)
    } else {
      setScreenSize(1)
    }
  }
  const handelSignUp = () => {
    navigator('/startup/sign-up')
  }
  const handelInvestorSignUp = () => {
    navigator('/investor/sign-up')
  }
  const handleFundingPartners = (value) => {
    // navigator(`/fundingpartners/${value}`)
  }

  useDidMountEffect(() => {
    Aos.init();
    // scrollAbout()
    // scrollContact()
    size();
    homeApi();
  }, [])

  const [homeData, setHomeData] = useState(0)
  const homeApi = () => {
    setPageLoading(true)
    setHomeData({})
    apiService(`home`, '', 'get').then((res) => {
      setPageLoading(false)
      if (res.data) {
        setHomeData(res.data)
      }
    }).catch((err) => {
      setPageLoading(false)
    });
  }


  // const button1 = document.getElementById('button1');
  // const button2 = document.getElementById('button2');

  // if (button1 && button2) {
  //   button2.addEventListener('mouseenter', () => {
  //     button1.style.backgroundColor = '#253B80';
  //     button1.style.color = 'white';
  //   });

  //   button2.addEventListener('mouseleave', () => {
  //     button1.style.backgroundColor = '';
  //     button1.style.color = '';
  //   });
  // }
  const hoverFun = () => {
    const button1 = document.getElementById('button1');
    const button2 = document.getElementById('button2');
    button1.addEventListener('mouseenter', () => {
      button2.style.backgroundColor = 'white';
      button2.style.color = 'black';
      button1.style.backgroundColor = 'transparent';
      button1.style.color = 'white';
    });
    button1.addEventListener('mouseleave', () => {
      button2.style.backgroundColor = '';
      button2.style.color = '';
      button1.style.backgroundColor = 'white';
      button1.style.color = 'black';
    });
    button2.addEventListener('mouseenter', () => {
      button1.style.backgroundColor = '#253B80';
      button1.style.color = 'white';
    });
    button2.addEventListener('mouseleave', () => {
      button1.style.backgroundColor = '';
      button1.style.color = '';
    });
  }

  const hoverFun1 = () => {
    const button3 = document.getElementById('button3');
    const button4 = document.getElementById('button4');

    if (button3 && button4) {
      button4.addEventListener('mouseenter', () => {
        button3.style.backgroundColor = 'white';
        button3.style.color = '#253B80';
      });

      button4.addEventListener('mouseleave', () => {
        button3.style.backgroundColor = '';
        button3.style.color = '';
      });
    }
  }
  $(document).ready(function () {
    function animateElements() {
      $("#showDiv").hide();
      $("#contentDiv").show();
      $("#Num1").show();
      $("#Num2").hide();
      $("#Num3").hide();
      $("#Num4").hide();
      $("#Num5").hide();
      $("#showDiv1").show();
      $("#contentDiv1").hide();
      $("#showDiv2").show();
      $("#contentDiv2").hide();
      $("#showDiv3").show();
      $("#contentDiv3").hide();
      $("#showDiv4").show();
      $("#contentDiv4").hide();
      $('#move1').addClass('activeShow')
      $('#move2').removeClass('activeShow')
      $('#move3').removeClass('activeShow')
      $('#move4').removeClass('activeShow')
      $('#move5').removeClass('activeShow')
    }

    function animateElements1() {
      $("#showDiv1").hide();
      $("#contentDiv1").show();
      $("#iconDiv1").show();
      $("#Num2").show();
      $("#Num1").hide();
      $("#Num3").hide();
      $("#Num4").hide();
      $("#Num5").hide();
      $("#showDiv").show();
      $("#contentDiv").hide();
      $("#showDiv2").show();
      $("#contentDiv2").hide();
      $("#showDiv3").show();
      $("#contentDiv3").hide();
      $("#showDiv4").show();
      $("#contentDiv4").hide();
      $('#move1').removeClass('activeShow')
      $('#move2').addClass('activeShow')
      $('#move3').removeClass('activeShow')
      $('#move4').removeClass('activeShow')
      $('#move5').removeClass('activeShow')
    }

    function animateElements2() {
      $("#showDiv2").hide();
      $("#contentDiv2").show();
      $("#iconDiv2").show();
      $("#Num3").show();
      $("#Num1").hide();
      $("#Num2").hide();
      $("#Num4").hide();
      $("#Num5").hide();
      $("#showDiv").show();
      $("#contentDiv").hide();
      $("#showDiv1").show();
      $("#contentDiv1").hide();
      $("#showDiv3").show();
      $("#contentDiv3").hide();
      $("#showDiv4").show();
      $("#contentDiv4").hide();
      $('#move1').removeClass('activeShow')
      $('#move2').removeClass('activeShow')
      $('#move3').addClass('activeShow')
      $('#move4').removeClass('activeShow')
      $('#move5').removeClass('activeShow')
    }

    function animateElements3() {
      $("#showDiv3").hide();
      $("#contentDiv3").show();
      $("#iconDiv3").show();
      $("#Num4").show();
      $("#Num1").hide();
      $("#Num2").hide();
      $("#Num3").hide();
      $("#Num5").hide();
      $("#showDiv").show();
      $("#contentDiv").hide();
      $("#showDiv1").show();
      $("#contentDiv1").hide();
      $("#showDiv2").show();
      $("#contentDiv2").hide();
      $("#showDiv4").show();
      $("#contentDiv4").hide();
      $('#move1').removeClass('activeShow')
      $('#move2').removeClass('activeShow')
      $('#move3').removeClass('activeShow')
      $('#move4').addClass('activeShow')
      $('#move5').removeClass('activeShow')
    }

    function animateElements4() {
      $("#showDiv4").hide();
      $("#contentDiv4").show();
      $("#iconDiv4").show();
      $("#Num5").show();
      $("#Num1").hide();
      $("#Num2").hide();
      $("#Num3").hide();
      $("#Num4").hide();
      $("#showDiv").show();
      $("#contentDiv").hide();
      $("#showDiv1").show();
      $("#contentDiv1").hide();
      $("#showDiv2").show();
      $("#contentDiv2").hide();
      $("#showDiv3").show();
      $("#contentDiv3").hide();
      $('#move1').removeClass('activeShow')
      $('#move2').removeClass('activeShow')
      $('#move3').removeClass('activeShow')
      $('#move4').removeClass('activeShow')
      $('#move5').addClass('activeShow')
    }

    let animationCount = 0;

    setInterval(function () {
      switch (animationCount) {
        case 0:
          animateElements();
          break;
        case 1:
          animateElements1();
          break;
        case 2:
          animateElements2();
          break;
        case 3:
          animateElements3();
          break;
        case 4:
          animateElements4();
          animationCount = -1;
          break;
      }
      animationCount++;
    }, 3000);
  });
  const scrollAbout = () => {
    const reftp = document.getElementById("aboutus");
    reftp.scrollIntoView();
  }
  const scrollContact = () => {
    const refdp = document.getElementById("contactus");
    refdp.scrollIntoView();
  }
  return (
    <>
      <Navbar scrollContact={scrollContact} scrollAbout={scrollAbout} />
      <section className='slider_section'>
        <div className='round_ball'>
          <div className='container pset-5 mset-5'>
            <div>
              <img src='/images/landing/Tanfundlogo.png' className='img-fluid silder_logo' alt='' />
            </div>
            <div className='header_content_div'>
              <h1 className='start_top'>Empowering Innovation,</h1>
              <h1>Enabling Investment</h1>
              <p className='my-3'>Bridging Tamil Nadu Startups with Global Investors</p>
              <div className='py-4'>
                <button className="btn1" id='button1' onClick={handelSignUp} onMouseMove={hoverFun}>I'm a Founder</button>
                <button className="btn2" id='button2' onClick={handelInvestorSignUp} onMouseMove={hoverFun}>I'm an Investor</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="marquee_main">
        <div className='container'>
          <div className='row set-mar-pad'>
            <div className='col-lg-3 col-md-3 col-6'>
              <div id="carouselExampleInterval" className="" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c26.png" className="d-block  flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c2.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c3.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c1.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer active" data-interval="2500">
                    <center><img src="/images/landing/logos/c5.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c6.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c7.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c8.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c9.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-3 col-6'>
              <div id="carouselExampleInterval" className="" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c10.png" className="d-block  flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c11.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c12.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c13.png" className="d-block  flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer active" data-interval="2500">
                    <center><img src="/images/landing/logos/c14.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c15.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c16.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c17.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c18.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-3 col-6'>
              <div id="carouselExampleInterval" className="" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c19.png" className="d-block  flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c20.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c21.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c22.png" className="d-block  flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer active" data-interval="2500">
                    <center><img src="/images/landing/logos/c23.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c24.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c25.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c26.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c27.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-3 col-6'>
              <div id="carouselExampleInterval" className="" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c28.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c29.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c30.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c31.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer active" data-interval="2500">
                    <center><img src="/images/landing/logos/c32.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c33.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c34.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c35.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                  <div className="carousel-item outer" data-interval="2500">
                    <center><img src="/images/landing/logos/c3.png" className="d-block flip flip-infinite" alt="..." /></center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about_tanfund" id='aboutus'>
        <div className='container'>
          <div className="tanfund">
            <div className='row justify-content-center' >
              <div className='col-lg-6 col-12'>
                <div className='py-sys-5'>
                  <h3 className="what_is mb-3">What is <span>TANFUND ?</span></h3>
                  <p className="what_is_p"><span>TANFUND</span> is a premier fund aggregator promoted by StartupTN, the Tamil Nadu Government’s nodal agency for Startup and Innovation. This platform connects innovative and scalable Startups across a wide spectrum of sectors with diverse funding partners.</p>

                  <div className="tan-button my-5">
                    <button className="btn3" id='button3' onClick={handleOpen} onMouseMove={hoverFun1}>Join <span className="btnh">Now</span></button>
                    {/* <button className="btn4 " id='button4' onMouseMove={hoverFun1}>Learn <span className="btnh">More</span></button> */}
                  </div>
                </div>

              </div>
              <div className='col-lg-6 col-12 text-center'>
                <img src='/images/map3.png' className='map img-fluid' alt='' /><p className='map-dyn font-weight-bold'>{homeData.tamilnaduStartups}<br /><span className='map-dyn-con font-weight-bold'>Startups in <br />Tamil Nadu</span></p>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* <section>
        <div className='container'>
          <div className='dyn-num-bg'>
            <div className='icon-dyn'>
              <span><img src="/images/roc-bg.png" /><span className='font-dyn font-weight-bold ml-2'>{homeData.totalStartups}</span></span>
              <span><img src="/images/mon-bg.png" /><span className='font-dyn font-weight-bold ml-2'>{homeData.totalInvestors}</span></span>
              <span><img src="/images/hand-bg.png" /><span className='font-dyn font-weight-bold ml-2'>{homeData.associateStartups}</span></span>
            </div>
            <div className='con-dyn'>
              <p>Startups onboarded</p>
              <p>Investors  Onboarded</p>
              <p className='text-center'>Startups associated <br/>with Investors</p>
            </div>
          </div>
        </div>
      </section> */}



      {/* <Howitswork component="component-171.svg"
  ellipse="ellipse-217-5.svg"
  ellipse1="ellipse-219-6.svg"
  ellipse2="ellipse-220-6.svg"
  ellipse3="ellipse-204-6.svg"
  group="group-6.png"
  group1="group-12171-6.png"
  group2="group-12172-5.png"
  img="ellipse-218-6.svg"
  property1="component-186"/> */}
      <section className='work_section' >
        <div className='container'>
          <div className='work_title'>
            <h1 className='work_star'>How It Works?</h1>
          </div>
          {/* <div className='work_row'>
            <div className='work_col' data-aos="fade-up" data-aos-delay="300">
              <div className='work_details'>
                <div className='number_set_dev'><span>1</span></div>
                <div className='pt-5 pb-4'>
                  <img src="/images/landing/i1.png" className="img-fluid" alt="" />
                </div>
                <h6 className='pt-4 pb-5'>Submission of Application</h6>
              </div>
            </div>
            <div className='work_col' data-aos="fade-up" data-aos-delay="600">
              <div className='work_details'>
                <div className='number_set_dev'><span>2</span></div>
                <div className='pt-5 pb-4'>
                  <img src="/images/landing/i2.png" className="img-fluid" alt="" />
                </div>
                <h6 className='pt-4 pb-5'>Allotment to Funding Partners</h6>
              </div>
            </div>
            <div className='work_col' data-aos="fade-up" data-aos-delay="900">
              <div className='work_details'>
                <div className='number_set_dev'><span>3</span></div>
                <div className='pt-5 pb-4'>
                  <img src="/images/landing/i3.png" className="img-fluid" alt="" />
                </div>
                <h6 className='pt-4 pb-5'>Evaluation Phase</h6>
              </div>
            </div>
            <div className='work_col' data-aos="fade-up" data-aos-delay="1200">
              <div className='work_details'>
                <div className='number_set_dev'><span>4</span></div>
                <div className='pt-5 pb-4'>
                  <img src="/images/landing/i5.png" className="img-fluid" alt="" />
                </div>
                <h6 className='pt-4 pb-5'>Due Diligence Rounds</h6>
              </div>
            </div>
            <div className='work_col' data-aos="fade-up" data-aos-delay="1500">
              <div className='work_details'>
                <div className='number_set_dev'><span>5</span></div>
                <div className='pt-5 pb-4'>
                  <img src="/images/landing/i6.png" className="img-fluid" alt="" />
                </div>
                <h6 className='pt-4 pb-5'>Finalization of Investment Terms</h6>
              </div>
            </div>
          </div> */}
          <div className='row work_row'>
            <div className='col-xl-6 col-lg-6 col-12 circle-sm'>
              <div className='circle-ani'>
                <div className='circle-line'>
                  <center>
                    <div className='num activeShow' id='move1'>
                      <div className='col'>1</div>
                    </div>
                    <div className='num1' id='move2'>
                      <div className='col'>2</div>
                    </div>
                    <div className='num2' id='move3'>
                      <div className='col'>3</div>
                    </div>
                    <div className='num3' id='move4'>
                      <div className='col'>4</div>
                    </div>
                    <div className='num4' id='move5'>
                      <div className='col'>5</div>
                    </div>
                  </center>
                  <img src="/images/landing/i1.svg" className="icon img-fluid" id='Num1' alt="" />
                  <img src="/images/landing/i2.svg" className="icon img-fluid" id='Num2' alt="" style={{ display: 'none' }} />
                  <img src="/images/landing/i3.svg" className="icon img-fluid" id='Num3' alt="" style={{ display: 'none' }} />
                  <img src="/images/landing/i4.svg" className="icon img-fluid" id='Num4' alt="" style={{ display: 'none' }} />
                  <img src="/images/landing/i6.svg" className="icon img-fluid" id='Num5' alt="" style={{ display: 'none' }} />
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-12 content-div'>
              <div id='showDiv' style={{ display: 'none' }}>
                <div className='htw-info mt-4'>
                  <img src="/images/landing/point.svg" className="" alt="" />
                  <h4>Submission of Application</h4>
                </div>
              </div>

              <div id="contentDiv" >
                <span className='step'><h5>Step 1</h5></span>
                <div className='p-info'>
                  <h4>Submission of Application</h4>
                  <p>Startups introduce themselves by detailing their business, team, product or service, market and specific funding requirements.</p>
                </div>
              </div>

              <div className='heading2' id="showDiv1">
                <div className='htw-info mt-4'>
                  <img src="/images/landing/point.svg" className="" alt="" />
                  <h4>Allotment to Funding Partners</h4>
                </div>
              </div>

              <div id="contentDiv1" style={{ display: 'none' }}>
                <span className='step'><h5>Step 2</h5></span>
                <div className='p-info'>
                  <h4>Allotment to Funding Partners</h4>
                  <p>Based on the funding ask and other relevant details, The application is assigned to potential funding partners who would be interested in the specific domain or stage of the Startup.</p>
                </div>
              </div>

              <div id='showDiv2'>
                <div className='htw-info mt-4'>
                  <img src="/images/landing/point.svg" className="" alt="" />
                  <h4>Evaluation Phase</h4>
                </div>
              </div>

              <div id="contentDiv2" style={{ display: 'none' }}>
                <span className='step'><h5>Step 3</h5></span>
                <div className='p-info'>
                  <h4>Evaluation Phase</h4>
                  <p>The designated investment partner reviews the application, may conduct preliminary meeting with the Startup or make a decision based on the data provided.</p>
                </div>
              </div>

              <div id='showDiv3'>
                <div className='htw-info mt-4'>
                  <img src="/images/landing/point.svg" className="" alt="" />
                  <h4>Due Diligence Round</h4>
                </div>
              </div>

              <div id="contentDiv3" style={{ display: 'none' }}>
                <span className='step'><h5>Step 4</h5></span>
                <div className='p-info'>
                  <h4>Due Diligence Round</h4>
                  <p>The successful Startup moves thorough due diligence phase, where the investment partner evaluates the financial records, product viability, market potential, team credentials and other pertinent details.</p>
                </div>
              </div>

              <div id='showDiv4'>
                <div className='htw-info mt-4'>
                  <img src="/images/landing/point.svg" className="" alt="" />
                  <h4>Finalization of Investment Sheet</h4>
                </div>
              </div>

              <div id="contentDiv4" style={{ display: 'none' }}>
                <span className='step'><h5>Step 5</h5></span>
                <div className='p-info'>
                  <h4>Finalization of Investment Sheet</h4>
                  <p>Once the due diligence is satisfactory, the Startup and the investment partner discuss and finalise the terms of investment - funding, equity share, milestones and other related agreements.</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-center mt-5 pt-5 fade-in-text">
            <button className="btn5">Learn More</button>
          </div> */}

        </div>
      </section>
      <section className='why_tanfund_section'>
        <div className='container'>
          <div className=''>
            <h3 className="what_is">Why <span>TANFUND ?</span></h3>
          </div>
          <div className="tab pb-5">
            <Box className="mt-5">
              <TabContext value={value} >
                <div className='row'>
                  <div className='col-lg-6'></div>
                  <div className='col-lg-6 col-12'>
                    <TabList onChange={handleChange}>
                      <Tab className='tab_lable_name' label="For Startup" value="0" />
                      <Tab className='tab_lable_name' label="For Investors" value="1" />
                    </TabList>
                  </div>
                </div>
                <TabPanel value="0">
                  <div className='tab1 container'>
                    <div className='row justify-content-center'>
                      <div className='col-lg-6'>
                        <div className='align_design'>
                          <img src="/images/landing/tab.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='why_tanfund_data'>
                          <div className='why_tanfund_details'>
                            <h5>Direct Access </h5>
                            <p>Engage directly with a spectrum of reputed global investors keen on investing in TN-based Startups.</p>
                          </div>
                          <div className='why_tanfund_details'>
                            <h5>Tailored Matches</h5>
                            <p>Benefit from funding avenues aligning with your Startup's specific requirements and growth phase.</p>
                          </div>
                          <div className='why_tanfund_details'>
                            <h5>Enhanced Visibility</h5>
                            <p>Boost your presence and earn due recognition among investor communities.</p>
                          </div>
                          <div className='mt-4'>
                            {/* <button className="btn3">Learn More</button> */}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="1">
                  <div className='tab1 container'>
                    <div className='row justify-content-center'>
                      <div className='col-lg-6'>
                        <div className='align_design'>
                          <img src="/images/landing/tab2.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='why_tanfund_data'>
                          <div className='why_tanfund_details'>
                            <h5>Curated Selection </h5>
                            <p>Gain access to a handpicked roaster of Startups primed for growth and aligning with your investment thesis.</p>
                          </div>
                          <div className='why_tanfund_details'>
                            <h5>Streamlined Process</h5>
                            <p>Experience a simplified, efficient and transparent investment journey, facilitated by StartupTN.</p>
                          </div>
                          <div className='why_tanfund_details'>
                            <h5>Collaborative Investment</h5>
                            <p>Co-invest with reputed peers, benefit from collective wisdom and shared insights.</p>
                          </div>
                          <div className='mt-4'>
                            {/* <button className="btn3">Learn More</button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </section>
      <section className='fund_program_section'>
        <div className='container-fluid'>
          <div className='text-center mb-5'>
            <h3 className="what_is">Funding <span>Partners</span></h3>
          </div>
          <div className='row justify-content-center mb-5 mx-4'>
            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className='funding_details' onClick={() => handleFundingPartners('Venture Capitalists')}>
                <img src='/images/landing/f1.svg' className='img-fluid mb-2' alt='' />
                <h5 className='mb-3'>Venture Capitalists</h5>
                <p>Professional entities managing pooled investor funds to invest in Startups, offering capital, expertise, and networks.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className='funding_details' onClick={() => handleFundingPartners('Private Equity')}>
                <img src='/images/landing/pv.png' className='img-fluid mb-2' alt='' />
                <h5 className='mb-3'>Private Equity</h5>
                <p>Invests in mature companies, often taking controlling interests. Focuses on scaling and optimizing businesses.</p>
              </div>
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className='funding_details' onClick={() => handleFundingPartners('Venture Debt')}>
                <img src='/images/landing/f3.svg' className='img-fluid mb-2' alt='' />
                <h5 className='mb-3'>Venture Debt</h5>
                <p>Debt financing for venture-backed startups lacking traditional collateral, complementing equity financing.
                </p>
              </div>
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className='funding_details' onClick={() => handleFundingPartners('Family Offices')}>
                <img src='/images/landing/f4.svg' className='img-fluid mb-2' alt='' />
                <h5 className='mb-3'>Family Offices</h5>
                <p>Manages wealth of high-net-worth families and making private equity investments with a long-term horizon.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className='funding_details' onClick={() => handleFundingPartners('Revenue-based Financing')}>
                <img src='/images/landing/f5.svg' className='img-fluid mb-2' alt='' />
                <h5 className='mb-3'>Revenue-based Financing</h5>
                <p>Startups receive capital in exchange for a percentage of ongoing gross revenues. Repayment is tied to revenue performance.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className='funding_details' onClick={() => handleFundingPartners('Angel Networks')}>
                <img src='/images/landing/f6.svg' className='img-fluid mb-2' alt='' />
                <h5 className='mb-3'>Angel Networks</h5>
                <p>Groups of HNIs pool resources to co-invest in Startups, sharing deal flow and due diligence.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className='funding_details' onClick={() => handleFundingPartners('Government')}>
                <img src='/images/landing/f7.svg' className='img-fluid mb-2' alt='' />
                <h5 className='mb-3'>Government
                  Investment Agencies</h5>
                <p>Support Startups with funding, mentorship and networks for economic growth, innovation, employment creation and social inclusion.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className='funding_details' onClick={() => handleFundingPartners('Accelerators')}>
                <img src='/images/landing/f8.svg' className='img-fluid mb-2' alt='' />
                <h5 className='mb-3'>Accelerator</h5>
                <p>Structured growth hacking programmes with mentorship and access to various resources, linked with seed investment.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='innovative_section'>
        <div className='container-fluid'>
          <div className='text-center mb-5'>
            <h3 className="what_is">Pride of <span> Startups</span></h3>
          </div>
          <swiper-container className="mySwiper" pagination={true} style={{
            "--swiper-pagination-color": "#FFBA08",
            "--swiper-pagination-bullet-inactive-color": "#999999",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "16px",
            "--swiper-pagination-bullet-horizontal-gap": "6px"
          }} pagination-clickable="true" space-between="30" slides-per-view={screenSize} autoplay="true">
            <swiper-slide>
              <div className='swiper_details'>
                <div className='o-con'>
                  <div className="card_data" >
                    <img src="/images/landing/l1.png" className="card-img-top img-fluid" alt="" />
                    <div className="card-body">
                      <h5 className="text-center">Ippo Pay</h5>
                    </div>
                  </div>
                </div>
                <div className='n-con'>
                  <div className="cards_hover" >
                    <div className="title">
                      <p className="set_big">Ippo Pay</p>
                      <p className="set_sml">Fintech</p>
                    </div>
                    <div className="card-body bg">
                      <p className="text-center">We are payment aggregators for businesses that collect and accept payments from their customers and vendors</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div className='swiper_details'>
                <div className='o-con'>
                  <div className="card_data" >
                    <img src="/images/landing/l2.png" className="card-img-top img-fluid" alt="" />
                    <div className="card-body">
                      <h5 className="text-center">Rizel</h5>
                    </div>
                  </div>
                </div>
                <div className='n-con'>
                  <div className="cards_hover" >
                    <div className="title">
                      <p className="set_big">Rizel</p>
                      <p className="set_sml" >Automobile</p>
                    </div>
                    <div className="card-body bg">
                      <p className="text-center">Rizel Automotive is helping the world in its energy efficiency endeavours by providing high-efficiency powertrain technologies</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div className='swiper_details'>
                <div className='o-con'>
                  <div className="card_data" >
                    <img src="/images/landing/l3.png" className="card-img-top img-fluid" alt="" />
                    <div className="card-body">
                      <h5 className="text-center">Greenpod Labs</h5>
                    </div>
                  </div>
                </div>
                <div className='n-con'>
                  <div className="cards_hover" >
                    <div className="title">
                      <p className="set_big">Greenpod Labs</p>
                      <p className="set_sml">Biotechnology</p>
                    </div>
                    <div className="card-body bg">
                      <p className="text-center">GreenPod is developing cutting-edge reliable solutions that extend the shelf-life of fruits & vegetables at room temperature.</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div className='swiper_details'>
                <div className='o-con'>
                  <div className="card_data" >
                    <img src="/images/landing/l4.png" className="card-img-top img-fluid" alt="" />
                    <div className="card-body">
                      <h5 className="text-center">Dextrowave</h5>
                    </div>
                  </div>
                </div>
                <div className='n-con'>
                  <div className="cards_hover" >
                    <div className="title">
                      <p className="set_big">Dextrowave</p>
                      <p className="set_sml">Computers and Electronics Manufacturing</p>
                    </div>
                    <div className="card-body bg">
                      <p className="text-center">Dextroware is developing assistive gadgets for differently abled persons to make use of consumer electronic products.</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div className='swiper_details'>
                <div className='o-con'>
                  <div className="card_data" >
                    <img src="/images/landing/l5.png" className="card-img-top img-fluid" alt="" />
                    <div className="card-body">
                      <h5 className="text-center">Beez Labs</h5>
                    </div>
                  </div>
                </div>
                <div className='n-con'>
                  <div className="cards_hover" >
                    <div className="title">
                      <p className="set_big">Beez Labs</p>
                      <p className="set_sml">IT Services and IT Consulting</p>
                    </div>
                    <div className="card-body bg">
                      <p className="text-center">Beez enables businesses with desktop-less environments & automation features by integrating end-to-end business processes.</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div className='swiper_details'>
                <div className='o-con'>
                  <div className="card_data" >
                    <img src="/images/landing/l6.png" className="card-img-top img-fluid" alt="" />
                    <div className="card-body">
                      <h5 className="text-center">Galaxeye</h5>
                    </div>
                  </div>
                </div>
                <div className='n-con'>
                  <div className="cards_hover" >
                    <div className="title">
                      <p className="set_big">Galaxeye</p>
                      <p className="set_sml">Defense and Space Manufacturing</p>
                    </div>
                    <div className="card-body bg">
                      <p className="text-center">Galaxeye is building a multi sensor imaging satellite for earth observation to gather and provide unique insights.</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div className='swiper_details'>
                <div className='o-con'>
                  <div className="card_data" >
                    <img src="/images/landing/l7.png" className="card-img-top img-fluid" alt="" />
                    <div className="card-body">
                      <h5 className="text-center">QUANTIC</h5>
                    </div>
                  </div>
                </div>
                <div className='n-con'>
                  <div className="cards_hover" >
                    <div className="title">
                      <p className="set_big">QUANTIC</p>
                      <p className="set_sml">IT Services and IT Consulting</p>
                    </div>
                    <div className="card-body bg">
                      <p className="text-center">Quantic provides AI-powered computer vision technology to address challenges in safety monitoring and process automation.</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper-container>
        </div>
      </section>
      <section >
        <div className='get_start_section'>
          <div className='container'>
            <div className='getstart_details'>
              <h2>Let's Get Started</h2>
              <p className='mb-0'>TANFUND provides access to a vibrant community of entrepreneurs,</p>
              <p>investors, mentors, and industry partners. </p>
              <button className='btn3' onClick={handleOpen}>Join us Now</button>
            </div>
          </div>
        </div>
      </section>
      <div id='contactus'>
        <Footer />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag_lg">
          <Signuproutemodal handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  )
}

export default Home
import React, { useContext } from 'react'
import './aboutus.css'
import Footer from '../footer-page/Footer'
// import YouTube from 'react-youtube';
import { Box, Modal } from '@mui/material';
import Signuproutemodal from '../signup-page/signupModal/Signuproutemodal';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Aboutus = () => {
    const { navigator, modalstyle, handleClose, open, handleOpen } = useContext(DataContext)
    useDidMountEffect(() => {
        document.getElementById('theme').setAttribute('href', '/css/landing1.css');
    }, [])

    const options = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1,
            controls: 1,
        },
    };
    // const _onReady = (event) => {
    //     event.target.pauseVideo();
    // }
    return (
        <div>
            <section>
                <div className='about'>
                    <img src='/images/about-bg1.png' className='ab-bg-img' alt='' />
                    <center><h1 className='ab-title font-weight-bold '>About Us <img src='/images/star.png' className='' alt='' /></h1> </center>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h3 className='heading font-weight-bold'>WHO  <span className='blue'>WE  ARE</span></h3>
                            <p className='heading-p'>TANFUND promoted by StartupTN and backed by the Tamil Nadu government, connects innovative
                                startups with diverse funding partners, fostering entrepreneurship and economic growth
                                in the region. It's a vital link for startups seeking financial support and opportunities
                                in Tamil Nadu's startup ecosystem</p>
                        </div>
                        <div className='col-lg-5'>
                            <img src='/images/tn-map.png' className='ab-bg-img' alt='' />
                        </div>
                    </div>
                </div>
                <img src='/images/circles.png' className='circle' alt='' />
            </section>

            <section>
                <section className='bg-banner'>
                    <div>
                        <center><img src='/images/banner.png' className='img-fluid' alt='' /></center>
                    </div>
                    <div className='bg-banner-img img-fluid' style={{
                        backgroundImage: `url("/images/C-line.png")`
                    }}></div>

                    <div className='bg-content'>
                        <h3 className='font-weight-bold'>WHAT <span className='blue'> WE DO</span></h3>
                    </div>
                    <div className='bg-content'>
                        <h5><img src='/images/s-q.png' className='mr-5 img-fluid' alt='' /><span>Empowering entrepreneurs by forging powerful</span></h5>
                    </div>
                    <div className='bg-content'>
                        <h4><span className='font-weight-bolder'>Founder <img src='/images/hand.png' className='' alt='' /> Funder</span></h4>
                    </div>
                    <div className='bg-content1'>
                        <h5> connections <img src='/images/e-q.png' className='ml-5 img-fluid' alt='' /></h5>
                    </div>

                    <div className='btn'>
                        <button onClick={handleOpen}>Join us Now</button>
                    </div>
                </section>
            </section>

            {/* <section>
                <div className='container mt-5'>
                    <h3 className='font-weight-bolder'>WHAT <span className='blue'> OUR CEO SAYS</span></h3>
                </div>
                <div className='container mt-5'>                  
                    <iframe width="100%" height="500" src="https://www.youtube.com/embed/DyRlWVT5N8E?si=8bfiLj-mTdMct-4R" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </section>

            <section className='container '>
                <div className='slider my-5'>
                    <h3 className='blue font-weight-bold'>EVENTS</h3>
                    <img src='/images/arrow.png' className='img-fluid' alt='' />
                </div>

                <swiper-container className="mySwiper" space-between="30" slides-per-view='3' autoplay="false">
                    <swiper-slide>
                        <div className="card" style={{}}>
                            <img src="/images/slide-1.png" className="card-img-top event-img" alt="..." />
                            <div className="card-body">
                                <p className="card-text">
                                    <div className='date'>
                                        <h6 className='blue font-weight-bold'>DEC</h6>
                                        <h3 className='font-weight-bold'>29</h3>
                                    </div>
                                    <div className='detail ml-3'>
                                        <p className='m-0'>A Strategic Guide for Crafting Winning Pitch Decks</p>
                                        <p className='grey'>Webinar</p>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className="card" style={{ opacity: `0.5` }}>
                            <img src="/images/slide-2.png" className="card-img-top event-img" alt="..." />
                            <div className="card-body">
                                <p className="card-text">
                                    <div className='date'>
                                        <h6 className='blue font-weight-bold'>DEC</h6>
                                        <h3 className='font-weight-bold'>21</h3>
                                    </div>
                                    <div className='detail ml-3'>
                                        <p className='m-0'>A Guide through the TANFUND Portal</p>
                                        <p className='grey'>Webinar</p>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className="card" style={{ opacity: `0.5` }}>
                            <img src="/images/slide-2.png" className="card-img-top event-img" alt="..." />
                            <div className="card-body">
                                <p className="card-text">
                                    <div className='date'>
                                        <h6 className='blue font-weight-bold'>DEC</h6>
                                        <h3 className='font-weight-bold'>21</h3>
                                    </div>
                                    <div className='detail ml-3'>
                                        <p className='m-0'>A Guide through the TANFUND Portal</p>
                                        <p className='grey'>Webinar</p>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className="card" style={{ opacity: `0.5` }}>
                            <img src="/images/slide-2.png" className="card-img-top event-img" alt="..." />
                            <div className="card-body">
                                <p className="card-text">
                                    <div className='date'>
                                        <h6 className='blue font-weight-bold'>DEC</h6>
                                        <h3 className='font-weight-bold'>21</h3>
                                    </div>
                                    <div className='detail ml-3'>
                                        <p className='m-0'>A Guide through the TANFUND Portal</p>
                                        <p className='grey'>Webinar</p>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                </ swiper-container>
            </section> */}

            <img src="/images/foot-c.png" className="foot-c" alt="..." />
            <Footer />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag_lg">
                    <Signuproutemodal handleClose={handleClose} />
                </Box>
            </Modal>
        </div>
    )
}

export default Aboutus
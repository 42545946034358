import React from 'react'
import ICAdminNavbar from './ICAdminNavbar'
import { Outlet } from 'react-router-dom'
import useDidMountEffect from '../../hooks/useDidMountEffect'

const ICAdminLayout = () => {
  localStorage.setItem('regRoleId', 2)
  useDidMountEffect(() => {
      document.getElementById('theme').setAttribute('href', '/css/admin.css');
  }, [])
  return (
    <div className='admin_main_div'>
            <ICAdminNavbar />
            <Outlet></Outlet>
        </div>
  )
}

export default ICAdminLayout
import { Container } from '@mui/material'
import React from 'react'

const Selfdeclarmsg = () => {
  return (
    <main>
      <Container>
        <div className='msg_page'>
          <img src='/images/Tanfundlogo-black-1.png' className='img-fluid msg_logo' alt='' />
          <h1>Thank you for applying on StartupTN TANFUND</h1>
          <h3>Our team will review your application form and will revert back soon</h3>
        </div>
      </Container>
    </main>
  )
}

export default Selfdeclarmsg
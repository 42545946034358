import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
// import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
// import PreliminaryTextPart1 from './PreliminaryTextPart1';
const fileTypes = ["pdf"];
// const fileTypesExcel = ["pdf", "XLSX"];

const FddSectionA = () => {
    const [formData, setFormData] = useState({});
    const { handleBackapp, setPageLoading,navigator } = useContext(DataContext)
    const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
        values: formData,
    });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    useDidMountEffect(() => {
        getApi()
    }, [])
    const [fileUploadId, setFileUploadId] = useState(0)
    const getApi = async () => {
        setPageLoading(true)
        await apiService(`startup/fdd/seca/get`, '', 'get').then((res) => {
            if (res && res.data) {
                var data = res.data
                if (res.data.id) {
                    setFileUploadId(res.data.id)
                  }
                  if(data.annaualBudgetPresent){
                    setPreviousYear(data.annaualBudgetPresent)
                  }
                  if(data.accountant){
                    setAccountingTeam(data.accountant)
                  }
                  if(data.accountsOutsourcingPresent){
                    setOutsourcing(data.accountsOutsourcingPresent)
                  }
                  if(data.auditorsPresent){
                    setauditors(data.auditorsPresent)
                  }
                // if (data.editForm === false) {
                //     setReadyOnlyValue(true)
                // }
                // if (res.data.id) {
                //     setFileUploadId(res.data.id)
                // }

                setFormData(res.data)
            }
            setPageLoading(false)
        })
    }
    const [accountingTeam, setAccountingTeam] = useState();
    const [outsourcing, setOutsourcing] = useState();
    const [auditors, setauditors] = useState();
    const [financialStatements, setFinancialStatements] = useState();
    const [previousYear, setPreviousYear] = useState();
    const handleAnnaualBudget = (e) => {
        handleFileUpload(e, "annaualBudget", "annaualBudgetName", "annaualBudgetURL", 'file');
    }
    const handleAuditReport = (e) => {
        handleFileUpload(e, "auditReport", "auditReportName", "auditReportURL", 'file');
    }
    const handleMonthlyMIS = (e) => {
        handleFileUpload(e, "monthlyMIS", "monthlyMISName", "monthlyMISURL", 'file');
    }
    const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
        let logoselecetdFile;
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            if (logoselecetdFile) {
                const fileSizeLimit = 5 * 1024 * 1024;
                if (logoselecetdFile.size > fileSizeLimit) {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        "Please upload a file smaller than 5 MB."
                    );
                    return;
                }
                var reader = new FileReader();
                var imagetype = logoselecetdFile.type
                var imagedatatype = imagetype.split("/")
                var img_crt_type = imagedatatype[1]
                if (
                    ((format === 'file') && (img_crt_type === "pdf")) ||
                    ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
                ) {
                    setValue(filekey, "")
                    setValue(fileName, "")

                    var fileValue = logoselecetdFile
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(',')
                        var ImageValue = spl[1]
                        var img_name = fileValue.name
                        setValue(filekey, ImageValue)
                        setValue(fileName, img_name)
                        setValue(fileURL, logourl1)
                        clearErrors(filekey);
                        clearErrors(fileName);
                        clearErrors(fileURL);
                        var req = {
                            [filekey]: ImageValue,
                            [fileName]: img_name,
                            id: fileUploadId
                        }
                        console.log(req);
                        // saveData(req)
                    }
                } else {
                    notifyService('danger', 'File Format Invalided', 'Please check your file format')
                }
            }
        }
    }


    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value) => {
        if (value) {
            setViewImage(value);
            setOpen(true);
        }
    };
    // const handleClickOpens = (url) => {
    //     if (url) {
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = 'filename.xlsx';
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //     }

    // };
    const handleClose = () => {
        setOpen(false);
    };
    // const onPdfClick = () => {
    //     const pdfUrl = "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Business_Plan_Template.xlsx";
    //     const link = document.createElement("a");
    //     link.href = pdfUrl;
    //     link.download = "Business_Plan_Template.xlsx";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    const onSubmit = (data) => {
        console.log(data);

        setPageLoading(true);

        apiService('startup/fdd/seca/save', data, 'post').then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    // handleBackapp()
                    navigator("/startup/scst/fdd-document/section-b");
                }
            }
        })
    }
    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className="d-flex align-items-center">
                        <span className="back_span" onClick={handleBackapp}>
                            <img src='/images/Fig-back.png' className='img-fluid' alt='' />
                        </span>
                        <h6 className="ml-2 mb-0">Accounts and Audit Startupscstaccounts </h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>

                        <div className="row doc-row2">
                        <div className='col-lg-4 col-12'>
                                <div className="form-group">
                                    <FormLabel>Is annual budgets are prepared by the Company? If so, share the budget for the Stub period, Current year and previous year</FormLabel>
                                    <Controller name="annaualBudgetPresent" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setPreviousYear(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setPreviousYear(e.target.value); setValue('annaualBudget', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.annaualBudgetPresent && errors.annaualBudgetPresent.message}</FormHelperText>
                                </div>
                            </div>
                            {
                                (previousYear === 'true' || previousYear === true) && (
                                    <>
                                        <div className='col-lg-4  col-12 mt-2'>
                                            {/* <FormLabel>FiLLip (Form for Incorporation of Company) *</FormLabel> */}
                                            <div className="d-flex align-items-center">
                                                <div className='form-group my-1'>
                                                    <Controller name="annaualBudget" control={control} defaultValue="" rules={{ required: false }}
                                                        render={({ field }) =>
                                                            <FileUploader
                                                                {...field}
                                                                handleChange={handleAnnaualBudget}
                                                                value={getValues('annaualBudget')}
                                                                name="file"
                                                                types={fileTypes}
                                                                children={
                                                                    <div className={getValues('annaualBudget') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                        <center>
                                                                            <p className={getValues('annaualBudget') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                                <span>
                                                                                    <AttachFileIcon className="file-icon mr-2" />
                                                                                    {getValues('annaualBudget') !== '' ? 'Uploaded (Click to Change)' : 'previous year Document'}
                                                                                </span>
                                                                            </p>
                                                                        </center>
                                                                    </div>
                                                                }
                                                                disabled={readyOnlyValue}
                                                            />
                                                        } />
                                                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.annaualBudget && errors.annaualBudget.message}</FormHelperText>
                                                </div>
                                                <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                                    onClick={() => handleClickOpen(getValues('annaualBudgetURL'))}
                                                    style={{ color: "#80809e" }}
                                                > Preview
                                                </Link>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                            <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Past Internal Audit Report, if any</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="auditReport" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleAuditReport}
                                                    value={getValues('auditReport')}
                                                    name="file"
                                                    types={fileTypes}
                                                    children={
                                                        <div className={getValues('auditReport') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('auditReport') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('auditReport') !== '' ? 'Uploaded (Click to Change)' : 'Past Internal Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.auditReport && errors.auditReport.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpen(getValues('auditReportURL'))}
                                        style={{ color: "#80809e" }}
                                    > Preview
                                    </Link>
                                    </span>
                                </div>
                            </div>

                            <div className='col-lg-4  col-12 mt-2'>
                                <FormLabel>Monthly MIS for the last 12 Months, (alongwith P & L, Balance sheet & Cash Flow)</FormLabel>
                                <div className="d-flex align-items-center">
                                    <div className='form-group my-1'>
                                        <Controller name="monthlyMIS" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <FileUploader
                                                    {...field}
                                                    handleChange={handleMonthlyMIS}
                                                    value={getValues('monthlyMIS')}
                                                    name="file"
                                                    types={fileTypes}
                                                    children={
                                                        <div className={getValues('monthlyMIS') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                            <center>
                                                                <p className={getValues('monthlyMIS') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                    <span>
                                                                        <AttachFileIcon className="file-icon mr-2" />
                                                                        {getValues('monthlyMIS') !== '' ? 'Uploaded (Click to Change)' : 'Monthly MIS Document'}
                                                                    </span>
                                                                </p>
                                                            </center>
                                                        </div>
                                                    }
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                        <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.monthlyMIS && errors.monthlyMIS.message}</FormHelperText>
                                    </div>
                                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                        onClick={() => handleClickOpen(getValues('monthlyMISURL'))}
                                        style={{ color: "#80809e" }}
                                    > Preview
                                    </Link>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>What Accounting Software is used by the Company?</FormLabel>
                                <div className="form-group mt-2">
                                    <Controller
                                        name="accountingSoftware"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Accounting Software is used by the Company?"
                                                placeholder="Enter Details"
                                                fullWidth
                                                multiline
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.accountingSoftware && errors.accountingSoftware.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            {/* <div className="d-flex align-items-center">
                                        <div className='form-group my-1'>
                                            <Controller name="accountingDataDoc" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>

                                                    <FileUploader
                                                        {...field}
                                                        handleChange={(e) => handleFileUpload(e,
                                                            "accountingDataDoc",
                                                            "accountingDataDocName",
                                                            "accountingDataDocURL",
                                                            'file')}
                                                        value={getValues('accountingDataDoc')}
                                                        name="file"
                                                        types={fileTypes}
                                                        children={
                                                            <div className={getValues('accountingDataDoc') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                                <center>
                                                                    <p className={getValues('accountingDataDoc') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                                        <span>
                                                                            <AttachFileIcon className="file-icon mr-2" />
                                                                            {getValues('accountingDataDoc') !== '' ? 'Uploaded (Click to Change)' : 'Accounting Data Document'}
                                                                        </span>
                                                                    </p>
                                                                </center>
                                                            </div>
                                                        }
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                            <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.accountingDataDoc && errors.accountingDataDoc.message}</FormHelperText>
                                        </div>
                                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                                            onClick={() => handleClickOpen(getValues('accountingDataDocURL'))}
                                            style={{ color: "#80809e" }}
                                        > Preview
                                        </Link>
                                        </span>
                                    </div> */}
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Any alternative software through which the operations are tracked? Say for instance software to place orders/ invoicing/ inventory tracking likewise</FormLabel>
                                <div className="form-group mt-2">
                                    <Controller
                                        name="softwareOperation"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Any alternative software through which the operations are tracked? Say for instance software to place orders/ invoicing/ inventory tracking likewise"
                                                placeholder="Enter Details"
                                                fullWidth
                                                multiline
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.softwareOperation && errors.softwareOperation.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>If so, provide us with the login credentials (This enables us to save considerable time in closing the DD)</FormLabel>
                                <div className="form-group mt-2">
                                    <Controller
                                        name="loginCredentials"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="If so, provide us with the login credentials (This enables us to save considerable time in closing the DD)"
                                                placeholder="Enter Details"
                                                fullWidth
                                                multiline
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.loginCredentials && errors.loginCredentials.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group">
                                    <FormLabel>Does the company have Internal Accountant and Accounting Team.</FormLabel>
                                    <Controller name="accountant" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setAccountingTeam(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setAccountingTeam(e.target.value); setValue('accountsHead', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.accountant && errors.accountant.message}</FormHelperText>
                                </div>
                            </div>
                            {
                                (accountingTeam === 'true' || accountingTeam === true) && (
                                    <>
                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group mt-2">
                                                <FormLabel>If yes, qualification and experience of the Internal Accounts Head</FormLabel>
                                                <Controller name="accountsHead" control={control} defaultValue="" rules={{ required: 'Incubation Center  Name is required' }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant='outlined'
                                                            label="If yes, qualification and experience of the Internal Accounts Head"
                                                            placeholder='Enter Details'
                                                            fullWidth
                                                            inputProps={
                                                                { readOnly: readyOnlyValue, }
                                                            }
                                                            {...field}
                                                        />} />
                                                <FormHelperText className='text-danger'>{errors.accountsHead && errors.accountsHead.message}</FormHelperText>
                                            </div>
                                        </div>

                                    </>
                                )
                            }


                            <div className='col-lg-4 col-12'>
                                <div className="form-group">
                                    <FormLabel>Are Company's accounts being outsourced. If yes, provide the details of the outsourcing firm</FormLabel>
                                    <Controller name="accountsOutsourcingPresent" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setOutsourcing(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setOutsourcing(e.target.value); setValue('accountsOutsourcing', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.accountsOutsourcingPresent && errors.accountsOutsourcingPresent.message}</FormHelperText>
                                </div>
                            </div>
                            {
                                (outsourcing === 'true' || outsourcing === true) && (
                                    <>
                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group mt-2">
                                                <FormLabel>If yes, qualification and experience of the Internal Accounts Head</FormLabel>
                                                <Controller name="accountsOutsourcing" control={control} defaultValue="" rules={{ required: 'accountsOutsourcing  is required' }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant='outlined'
                                                            label="Are Company's accounts being outsourced. If yes, provide the details of the outsourcing firm"
                                                            placeholder='Enter Details'
                                                            fullWidth
                                                            inputProps={
                                                                { readOnly: readyOnlyValue, }
                                                            }
                                                            {...field}
                                                        />} />
                                                <FormHelperText className='text-danger'>{errors.accountsOutsourcing && errors.accountsOutsourcing.message}</FormHelperText>
                                            </div>
                                        </div>

                                    </>
                                )
                            }
                            <div className='col-lg-4 col-12'>
                                <div className="form-group">
                                    <FormLabel>Is the company preparing monthly financial statements</FormLabel>
                                    <Controller name="monthlyFinacialStatement" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setFinancialStatements(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setFinancialStatements(e.target.value); setValue('monthlyFinacialStatement', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.monthlyFinacialStatement && errors.monthlyFinacialStatement.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Details w.r.t. Auditors of the Company</FormLabel>
                                <div className="form-group mt-2">
                                    <Controller
                                        name="companySecretary"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Details w.r.t. Auditors of the Company"
                                                placeholder="Enter Details"
                                                fullWidth
                                                multiline
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.companySecretary && errors.companySecretary.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group">
                                    <FormLabel>Are there any changes in the Auditors? If yes, reasons thereof</FormLabel>
                                    <Controller name="auditorsPresent" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setauditors(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setauditors(e.target.value); setValue('auditors', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.auditorsPresent && errors.auditorsPresent.message}</FormHelperText>
                                </div>
                            </div>
                            {
                                (auditors === 'true' || auditors === true) && (
                                    <>
                                        <div className='col-lg-4 col-12'>
                                            <div className="form-group mt-2">
                                                {/* <FormLabel>If yes, qualification and experience of the Internal Accounts Head</FormLabel> */}
                                                <Controller name="auditors" control={control} defaultValue="" rules={{ required: 'accountsOutsourcing  is required' }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            variant='outlined'
                                                            label="Are there any changes in the Auditors? If yes, reasons thereof"
                                                            placeholder='Enter Details'
                                                            fullWidth
                                                            inputProps={
                                                                { readOnly: readyOnlyValue, }
                                                            }
                                                            {...field}
                                                        />} />
                                                <FormHelperText className='text-danger'>{errors.auditors && errors.auditors.message}</FormHelperText>
                                            </div>
                                        </div>

                                    </>
                                )
                            }
                            <div className='col-lg-4 col-12 mt-2'>
                                <FormLabel>Name of the Company Secretary who takes care of all secretarial compliances</FormLabel>
                                <div className="form-group mt-2">
                                    <Controller
                                        name="companyAuditors"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="who takes care of all secretarial compliances"
                                                placeholder="Enter Details"
                                                fullWidth
                                                multiline
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.companyAuditors && errors.companyAuditors.message}
                                    </FormHelperText>
                                </div>
                            </div>

                          



                        </div>


                        <div className="form-group d-flex justify-content-end mt-3 mr-2">
                            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <Button
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </Button>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                </div>
                <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
            </section>
        </>
    )
}



export default FddSectionA
import React, { useContext, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import DataContext from '../../../context/DataContext';
import TablePagination from '@mui/material/TablePagination';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import apiService from '../../../api/apiService';
import downloadExcel from '../../../hooks/downloadExcel';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import notifyService from '../../../api/notifySerivce';
import { Box, Modal } from '@mui/material';
import SendEmail from '../startup-page/SendEmail';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const CurrentTanseed = () => {
    const { open, modalstyle, setPageLoading, tableIndexValue } = useContext(DataContext)
    const [partnerListData, setPartnerListData] = useState([])
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [activeStartup, setActiveStartup] = useState(1)
    const [acceptTanseed, setAcceptTanseed] = useState(true);
    const [searchInputValue, setSearchInputValue] = useState('');

    useDidMountEffect(() => {
        userListItemTanseed()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchInputValue, activeStartup, acceptTanseed])

    const handleSearch = (searchValue) => {
        setSearchInputValue(searchValue)
    }
    const userListItemTanseed = async () => {
        setPageLoading(true);
        setPartnerListData([])
       var reqTanseed = {
            "listSize": rowsPerPage,
            "pageNumber": page + 1,
            "searchString": searchInputValue,
            "acceptTanseedTerms": acceptTanseed
        }
        await apiService('user/tanseedstartupslist', reqTanseed, 'post').then((res) => { 
            setPageLoading(false);
            if (res) {
                setCount(res.data.count)
                if (res.data.responseModelList) {
                    setPartnerListData(res.data.responseModelList)

                } else {
                    setPartnerListData([])
                }
            }
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const { control, getValues, reset } = useForm({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [open2, setOpen2] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen2(!open2);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };
    const [loading, setLoading] = useState(false);
    const exportExcelStartup = (download, emails) => {
        // setDownloadValueStartup(download)
        setPageLoading(true);
        setLoading(true)
        var req
        if (download === 'All') {
            req = {
                "download": download,
                "roleId": 4,
                "searchString": searchInputValue,
                "acceptTanseedTerms": acceptTanseed,
                "email": emails
            }
        } else {
            req = {
                "download": download,
                "roleId": 4,
                "listSize": rowsPerPage,
                "pageNumber": page + 1,
                "searchString": searchInputValue,
                "acceptTanseedTerms": acceptTanseed
            }
        }
        // user/list
        apiService('user/tanseedstartupslist', req, 'downloadpost').then((res) => {
            setLoading(false)
            setPageLoading(false);
            if (res) {
                if (res.data) {
                    if (res.data.byteLength === 169) {
                        notifyService('danger', 'TANSEED list is empty', '')
                    } else {
                        if (download === 'All') {
                            notifyService('success', 'Success', 'An Excel file has been sent to your email address.')
                            handleExportAllClose()
                        } else {
                            downloadExcel(res.data, 'tanseed_')
                            // setDownloadValueStartup('')
                        }

                    }
                }
            }
        })
    }
    // const exportExcel = () => {
    //     setLoading(true);
    //     apiService('export/users?roleid=4', '', 'download').then((res) => {
    //         setLoading(false);
    //         downloadExcel(res.data, 'startup_')
    //     })
    // }

    const handleFilterStartup = (value) => {
        setAcceptTanseed(value)
    }
    // const handleActiveApi = (path, id) => {
    //     apiService(`user/${path}?id=${id}`, '', 'get').then((res) => {
    //         if (res) {
    //             if (res.data) {
    //                 userListItem(4)
    //             }
    //         }
    //     })
    // }

    const [openAllExport, setopenAllExport] = useState(false)
    const handleExportAllClick = () => {
        setopenAllExport(true)
    }
    const handleExportAllClose = () => {
        reset()
        setopenAllExport(false)
    }
    return (
        <div className='table_tag'>
            <div className='table_header_tag set-res-flex'>
                <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        value={searchInputValue}
                        type='search'
                        placeholder="Search with Name"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </SearchinputDiv>
                <div className='ml-auto'>
                    <div className='d-flex align-item-center'>
                        <div className='filiterinvestor mr-4'>
                            <span className={(acceptTanseed === true) ? ('common_span act_span') : ('common_span')} onClick={() => { handleFilterStartup(true) }}>Applied</span>
                            <span className={(acceptTanseed === false) ? ('common_span act_span') : ('common_span')} onClick={() => { handleFilterStartup(false) }}>Not Applied</span>
                        </div>
                        {/* <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CloudDownloadIcon />}
                            variant="outlined"
                            onClick={exportExcel}
                        >
                            <span>EXPORT STARTUP</span>
                        </LoadingButton> */}
                        <div className='exp-btn'>
                            <Button
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="outlined"
                                disableElevation
                                onClick={handleClick}
                                startIcon={<CloudDownloadIcon />}
                                endIcon={<KeyboardArrowDownIcon />}
                            >
                                Export Startups
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                anchorEl={anchorEl}
                                open={open2}
                                onClose={handleClose2}
                            >
                                <MenuItem disableRipple onClick={handleExportAllClick}>
                                    Export All
                                </MenuItem>
                                <MenuItem onClick={(event) => { handleClose2(); exportExcelStartup('This Page Only') }} disableRipple>
                                    This Page Only
                                </MenuItem>
                            </StyledMenu>
                        </div>
                    </div>
                </div>


            </div>
            <div>
                {
                    (partnerListData.length) ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email Id</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        {/* <TableCell align='center'>TANSEED Applied</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {partnerListData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {
                                                    tableIndexValue(rowsPerPage, page, index)
                                                }
                                            </TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>
                                                {row.email}
                                                {
                                                    (row.accountVerified === true) ? (
                                                        <span className='verify_span'>(<i className="fas fa-check-circle"></i> Verified)</span>
                                                    ) : (<span className='inprogress_span'>(Not verified)</span>)
                                                }
                                            </TableCell>
                                            <TableCell>{row.phone}</TableCell>
                                            {/* <TableCell align='center'>
                                        {
                                            (row.active === false) && (<span className='no_span'>No</span>)
                                        }
                                        {
                                            (row.active === true) && (<span className='yes_span'>Yes</span>)
                                        }
                                    </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <caption>
                                    <TablePagination
                                        component="div"
                                        count={count}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        style={{float:'left'}}
                                    /></caption>
                            </Table>

                        </TableContainer>
                    ) : (
                        <div className='nodata_tag'>
                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                            <h4>TANSEED list is empty</h4>
                        </div>
                    )
                }
            </div>
            <Modal
                open={openAllExport}
            >
                <Box sx={modalstyle} className="form_tag">
                    <SendEmail handleExportAllClose={handleExportAllClose} exportExcelStartup={exportExcelStartup} />
                    {/* <Bulkupload handleExportAllClose={handleExportAllClose} title={'Investor'} api={'investor'} roleid={5} /> */}
                </Box>
            </Modal>
        </div>
    )
}

export default CurrentTanseed
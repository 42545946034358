import React from 'react'
import { Outlet } from 'react-router-dom'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import Tanseednavbar from './Tanseednavbar'

const Tanseedlayout = () => {
    localStorage.setItem('regRoleId', 6)
    useDidMountEffect(() => {
        document.getElementById('theme').setAttribute('href', '/css/admin.css');
    }, [])
    return (
        <div className='admin_main_div'>
            <Tanseednavbar />
            <Outlet></Outlet>
        </div>
    )
}

export default Tanseedlayout
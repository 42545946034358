import React, { useContext, useState } from 'react';
import { Autocomplete, Checkbox, Container, FormControl, List, Paper } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
import apiService from '../../../api/apiService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import DataContext from '../../../context/DataContext';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import notifyService from '../../../api/notifySerivce';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Partnerprofile = () => {
    const { navigator, investmentTicketSize, VisuallyHiddenInput, minimumRevenue,icon, checkedIcon } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, clearErrors, formState: { errors } } = useForm(
        { values: formData }
    );
    useDidMountEffect(() => {
        (async () => await getApi())()
        fundprogramlistApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getApi = async () => {
        const userId = Number(localStorage.getItem('userId'))
        await apiService(`userprofile/get?userId=${userId}`, '', 'get').then((res) => {
            if (res) {
                let result = res.data
                if (result.investTicketSize !== undefined) {
                    setFormData(result)
                    // setOrganizationTypeValue(result.organizationType);
                    setPreferredSectors(result.preferredSectors)
                    setInvestTicketSize(result.investTicketSize)
                    if (result.revenueMaking === true) {
                        setMinRevenueValue(result.minRevenue)
                    }
                    setStartupStagesValue(result.startupStages)
                    setFundingStatusesValue(result.fundingStatuses)
                    if (result.organizationLogoName === undefined) {
                        setLogoDisplayUrl('/images/user.png')
                    } else {
                        setLogoDisplayUrl(result.organizationLogoName)
                        setLogoDisplayValue(result.organizationLogoName)
                    }
                    setAcceptTermsChecked(result.acceptTermsAndConditions)
                    if (result.revenueMaking) {
                        setRevenueMakingStatus(result.revenueMaking.toString())
                        setValue('revenueMaking', result.revenueMaking.toString())
                    }
                    
                } else {
                    setFormData(result)
                    if (result.revenueMaking) {
                        setRevenueMakingStatus(result.revenueMaking.toString())
                        setValue('revenueMaking', result.revenueMaking.toString())
                    }

                }
                setInvestedStartupsStatus(result.investedInTNbasedStartup)
                setTermsAndConditionsHTML(result.termsAndConditions)
                setPrivacyPolicyHTML(result.privacyPolicy)
            }

        })
    }
    const [termsAndConditionsHTML, setTermsAndConditionsHTML] = useState('')
    function termsAndConditionsMarkup() {
        return { __html: termsAndConditionsHTML };
    }
    const [privacyPolicyHTML, setPrivacyPolicyHTML] = useState('')
    function privacyPolicyMarkup() {
        return { __html: privacyPolicyHTML };
    }
    const [logoDisplayValue, setLogoDisplayValue] = useState(null)
    const [logoDisplayUrl, setLogoDisplayUrl] = useState('/images/user.png')
    let logoselecetdFile
    const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
        setValue(filekey, "")
        setValue(fileName, "")
        setdata('')
        setUrl('')
        if (event.target === undefined) {
            logoselecetdFile = event
        } else {
            logoselecetdFile = event.target.files[0]
        }
        if (logoselecetdFile) {
            var reader = new FileReader();
            var imagetype = logoselecetdFile.type
            var imagedatatype = imagetype.split("/")
            var img_crt_type = imagedatatype[1]
            if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png") {
                if (event.target === undefined) {
                    setdata(event)
                } else {
                    setdata(event.target.files[0])
                }
                var fileValue = logoselecetdFile
                reader.readAsDataURL(logoselecetdFile);
                reader.onload = () => {
                    var logourl1 = reader.result;
                    var spl = logourl1.split(',')
                    var ImageValue = spl[1]
                    var img_name = fileValue.name
                    setUrl(logourl1)
                    setValue(filekey, ImageValue)
                    setValue(fileName, img_name)
                    clearErrors(filekey);
                    clearErrors(fileName);
                    setLogoInvalid(false)
                }
            }
        }
    }
    const [fundingList, setFundingList] = useState([]);
    const fundprogramlistApi = async () => {
        await apiService(`partner/fundprograms`, '', 'get').then((res) => {
            if (res) {
                setFundingList(res.data.fundingPrograms)
            }
        })
    }

    // const [organizationTypeValue, setOrganizationTypeValue] = useState('');
    // const handleSelectOrganizationType = (value) => {
    //     // setOrganizationTypeValue(value);
    //     setValue('organizationType', value)
    //     clearErrors('organizationType');
    //     const name = fundingList.filter(item => ((item.partner)).includes(value)
    //         || ((item.partner)).includes(value)
    //     )
    //     setValue('fundingProgramVersionConfigId', name[0].versionId)
    // }
    const sectorData = [
        "Select All",
        'Aerospace, Defence & Space Tech',
        'Agriculture & Foodtech',
        'AI, ML & IoT',
        'Art, Culture & Architecture',
        'Automotive, EV & Smart Mobility',
        'Blue Economy',
        'Chemicals & Materials',
        'Circular Economy',
        'Climate Tech & Clean Energy',
        'Data Mining & Analytics',
        'Edutech',
        'Femtech',
        'FIntech & Insurtech',
        'Healthcare & Life Sciences',
        'HR Tech & Smart Workforce',
        'Industry 4.0 & Advanced Manufacturing',
        'Lifestyle, Personalcare & D2C',
        'MarketingTech & MICE',
        'Media & Entertainment',
        'PropTech, LegalTech & RegTech',
        'RetailTech',
        'SaaS, Software & IT/ITES',
        'Smart Cities & E-Governance',
        'Social Impact, Rural Livelihood & Sustainability',
        'SportsTech & Gaming',
        'Supply Chain & Logistics',
        'Telecom, Networking & Hardware',
        'TextileTech & Fashion',
        'Travel & Tourism',
        'Web 3.0, Blockchain, VR/AR',
        "Remove All",
    ];
    const [preferredSectors, setPreferredSectors] = useState([]);
    const handleSelectSector = (value) => {
        if (value[value.length - 1] === "Select All") {
            const name = sectorData.filter((item) => item !== 'Remove All' && item !== 'Select All')
            setPreferredSectors(name);
            setValue('preferredSectors', name)
            clearErrors('preferredSectors');
            return;
        } if (value[value.length - 1] === "Remove All") {
            setPreferredSectors([]);
            setValue('preferredSectors', [])
            return;
        } else {
            setPreferredSectors(value);
            clearErrors('preferredSectors');
        }        
      
    };

    const [investTicketSize, setInvestTicketSize] = useState([]);
    const handleSelectTicketSize = (value) => {
        setInvestTicketSize(value);
        setValue('investTicketSize', value)
        clearErrors('investTicketSize');
    };

    const [revenueMakingStatus, setRevenueMakingStatus] = useState(false);
    const [minRevenueValue, setMinRevenueValue] = useState([]);
    const handleSelectMinRevenue = (value) => {
        setMinRevenueValue(value);
        setValue('minRevenue', value)
        clearErrors('minRevenue');
    };

    const startupStagesList = [
        "Idea Stage",
        "MVP/Prototype Stage",
        "Early Revenue",
        "Growth Stage",
        "Scale Stage",
    ]
    const [startupStagesValue, setStartupStagesValue] = useState([]);
    const handleSelectStageStartup = (value) => {
        setStartupStagesValue(value)
        setValue('startupStages', value)
        clearErrors('startupStages');
    }

    const fundingStatusesList = [
        "Bootstrapped",
        "Friends & Family",
        "Seed/Angle Funded",
        "Pre-Series",
        "Series A or Beyond",
    ]
    const [fundingStatusesValue, setFundingStatusesValue] = useState([]);
    const handleSelectFundingStatuses = (value) => {
        setFundingStatusesValue(value)
        setValue('fundingStatuses', value)
        clearErrors('fundingStatuses');
    }

    const [acceptTermsChecked, setAcceptTermsChecked] = useState(null);
    const handleChangeChecked = (event) => {
        setAcceptTermsChecked(event.target.checked);
        if (event.target.checked) {
            setValue('acceptTermsAndConditions', event.target.checked)
            clearErrors('acceptTermsAndConditions')
        } else {
            setValue('acceptTermsAndConditions', null)
        }
    };

    const [investedStartupsStatus, setInvestedStartupsStatus] = useState(null);

    const [loading, setLoading] = useState(false);
    const [logoInvalid, setLogoInvalid] = useState(false);
    const onSubmit = (data) => {
        if (logoDisplayValue !== null) {
            // setLoading(true);
            var getform = data
            if (data.revenueMaking === "true") {
                getform.revenueMaking = true
            } else {
                getform.revenueMaking = false
            }
            if (data.investedInTNbasedStartup === "true") {
                getform.investedInTNbasedStartup = true
            } else {
                getform.investedInTNbasedStartup = false
            }
            getform.investmentCharacterstics=[]
            getform.id = Number(data.id)
            debugger
            apiService('userprofile/save', getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        handleCancel()
                    }
                }
            })
        } else {
            setLogoInvalid(true)
            notifyService('danger', 'Error', 'Please upload Profile Logo')
        }
    };
    const handleCancel = () => {
        navigator('/partner/round')
    }
    return (
        <section className='section_tag'>
            <Container>
                <div className='form_div'>
                    <div className='div_header'>
                        <h6>Partner Profile Details</h6>
                    </div>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="fundingProgramVersionConfigId" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                            <div className='col-lg-9 col-12'>
                                <div className='row'>
                                    <div className='col-lg-6 col-12'>
                                        <div className="form-group mt-2">
                                            <Controller name="name" control={control} defaultValue="" rules={{ required: 'Organization name is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Name of Organization *"
                                                        placeholder='Enter Organization Name'
                                                        autoFocus
                                                        fullWidth
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-12'>
                                        <div className="form-group mt-2">
                                            <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email Id is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Email Id *"
                                                        placeholder='Enter Email Id'
                                                        fullWidth
                                                        inputProps={
                                                            { readOnly: true, }
                                                        }
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-12'>
                                        <div className="form-group mt-2">
                                            <Controller name="phone" control={control} defaultValue="" rules={{ required: 'Contact is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Contact *"
                                                        placeholder='Enter Contact'
                                                        fullWidth
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.phone && errors.phone.message}</FormHelperText>
                                        </div>
                                    </div>
                                    {/* <div className='col-lg-6 col-12'>
                                        <div className="form-group mt-2">
                                            <FormControl sx={{ width: '100%' }}>
                                                <Controller name="organizationType" control={control} defaultValue="" rules={{ required: 'Organization Type is required' }}
                                                    render={({ field }) =>                                                        
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            {...field}
                                                            options={fundingList.map((option) => option.partner)}
                                                            onChange={(e, selectedOptions) => handleSelectOrganizationType(selectedOptions)}
                                                            renderInput={(params) => <TextField {...params} label="Organization Type *" />}
                                                        />
                                                    } />
                                                <FormHelperText className='text-danger'>{errors.organizationType && errors.organizationType.message}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                            <div className='col-lg-3 col-12 text-center mb-3'>
                                <div className='logo_div'>
                                    <img src={logoDisplayUrl} className='user_logo' alt='' />
                                    <div className='camera_icon'>
                                        <Button onChange={(e) => handleFileUpload(e, "organizationLogo", "organizationLogoName", setLogoDisplayValue, setLogoDisplayUrl)} component="label" startIcon={<CameraAltIcon />}>
                                            <VisuallyHiddenInput type="file" />
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <span>Organization Logo <span className='text-danger'>*</span></span>
                                </div>
                                <div>
                                    <span className='fn-12'>Recommented Size (128px * 128px)</span>
                                </div>
                                <div>
                                    <span className='fn-12'>Maximum 10 mb allowed doc (png, jpg, jpeg) <span className='text-danger'>*</span></span>
                                </div>
                                {
                                    (logoInvalid === true) && (<span className='text-danger'>Organization logo is required</span>)
                                }
                            </div>
                            <div className='col-lg-12 col-12'>
                                <div className='div_header'>
                                    <h6>POC Details</h6>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3 col-12'>
                                        <div className="form-group mt-2">
                                            <Controller name="pocName" control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Name *"
                                                        placeholder='Enter Name'
                                                        fullWidth
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.pocName && errors.pocName.message}</FormHelperText>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-12'>
                                        <div className="form-group mt-2">
                                            <Controller name="pocDesignation" control={control} defaultValue="" rules={{ required: 'Designation is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Designation *"
                                                        placeholder='Enter Designation'
                                                        fullWidth
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.pocDesignation && errors.pocDesignation.message}</FormHelperText>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-12'>
                                        <div className="form-group mt-2">
                                            <Controller name="pocEmail" control={control} defaultValue="" rules={{ required: 'Email Id is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Email Id *"
                                                        placeholder='Enter Email Id'
                                                        fullWidth
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.pocEmail && errors.pocEmail.message}</FormHelperText>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-12'>
                                        <div className="form-group mt-2">
                                            <Controller name="pocPhone" control={control} defaultValue="" rules={{ required: 'Phone Number is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Phone Number *"
                                                        placeholder='Enter Phone Number'
                                                        fullWidth
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.pocPhone && errors.pocPhone.message}</FormHelperText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-12'>
                                <div className='div_header'>
                                    <h6>Qualification of Startups</h6>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-4 col-12'>
                                        <div className="form-group">
                                            <FormLabel>Revenue making startups ? *</FormLabel>
                                            <Controller name="revenueMaking" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        name="position"
                                                        {...field}
                                                    >
                                                        <FormControlLabel onChange={(e) => { setRevenueMakingStatus(e.target.value); }} value="true" control={<Radio />} label="Yes" />
                                                        <FormControlLabel onChange={(e) => { setRevenueMakingStatus(e.target.value); setValue('minRevenue', []); }} value="false" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                } />
                                            <FormHelperText className='text-danger'>{errors.revenueMaking && errors.revenueMaking.message}</FormHelperText>
                                        </div>
                                    </div>

                                    {
                                        (revenueMakingStatus === 'true') && (
                                            <div className='col-lg-4 col-12'>
                                                <div className="form-group mt-2">
                                                    <FormControl sx={{ width: '100%' }}>
                                                        {/* <InputLabel htmlFor="grouped-native-select">Minimum revenue *</InputLabel> */}
                                                        <Controller name="minRevenue" control={control} defaultValue="" rules={{ required: 'Minimum revenue is required' }}
                                                            render={({ field }) =>
                                                                // <Select
                                                                //     {...field}
                                                                //     placeholder='Minimum revenue'
                                                                //     value={minRevenueValue}
                                                                //     multiple
                                                                //     onChange={(e) => handleSelectMinRevenue(e.target.value)}
                                                                //     input={<OutlinedInput label="Name" />}
                                                                //     MenuProps={MenuProps}
                                                                //     renderValue={(selected) => (
                                                                //         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                //             {selected.map((value) => (
                                                                //                 <Chip key={value} label={value} onDelete={() => console.log('')} />
                                                                //             ))}
                                                                //         </Box>
                                                                //     )}
                                                                // >
                                                                //     {minimumRevenue.map((item) => (
                                                                //         <MenuItem
                                                                //             key={item}
                                                                //             value={item}
                                                                //             style={getStyles(item, minRevenueValue, theme)}
                                                                //         >
                                                                //             {item}
                                                                //         </MenuItem>
                                                                //     ))}
                                                                // </Select>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    disablePortal
                                                                    {...field}
                                                                    multiple
                                                                    limitTags={2}
                                                                    value={minRevenueValue}
                                                                    options={minimumRevenue}
                                                                    onChange={(e, selectedOptions) => handleSelectMinRevenue(selectedOptions)}
                                                                    renderInput={(params) => <TextField {...params} label="Minimum revenue *" />}
                                                                    renderOption={(props, option, { selected }) => (
                                                                        <li {...props}>
                                                                            <Checkbox
                                                                                icon={icon}
                                                                                checkedIcon={checkedIcon}
                                                                                style={{ marginRight: 8 }}
                                                                                checked={selected}
                                                                            />
                                                                            {option}
                                                                        </li>
                                                                    )}
                                                                />
                                                            } />
                                                            <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                                        <FormHelperText className='text-danger'>{errors.minRevenue && errors.minRevenue.message}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className='col-lg-4 col-12'>
                                        <div className="form-group mt-2">
                                            <FormControl sx={{ width: '100%' }}>
                                                {/* <InputLabel htmlFor="grouped-native-select">Stage of Startup *</InputLabel> */}
                                                <Controller name="startupStages" control={control} defaultValue="" rules={{ required: 'Stage of Startup is required' }}
                                                    render={({ field }) =>
                                                        // <Select
                                                        //     {...field}
                                                        //     placeholder='Stage of Startup'
                                                        //     multiple
                                                        //     value={startupStagesValue}
                                                        //     onChange={(e) => handleSelectStageStartup(e.target.value)}
                                                        //     input={<OutlinedInput label="Name" />}
                                                        //     MenuProps={MenuProps}
                                                        //     renderValue={(selected) => (
                                                        //         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        //             {selected.map((value) => (
                                                        //                 <Chip key={value} label={value} onDelete={() => console.log('')} />
                                                        //             ))}
                                                        //         </Box>
                                                        //     )}

                                                        // >
                                                        //     {startupStagesList.map((item) => (
                                                        //         <MenuItem
                                                        //             key={item}
                                                        //             value={item}
                                                        //             style={getStyles(item, startupStagesValue, theme)}
                                                        //         >
                                                        //             {item}
                                                        //         </MenuItem>
                                                        //     ))}
                                                        // </Select>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            {...field}
                                                            multiple
                                                            limitTags={2}
                                                            value={startupStagesValue}
                                                            options={startupStagesList}
                                                            onChange={(e, selectedOptions) => handleSelectStageStartup(selectedOptions)}
                                                            renderInput={(params) => <TextField {...params} label="Stage of Startup *" />}
                                                            renderOption={(props, option, { selected }) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={icon}
                                                                        checkedIcon={checkedIcon}
                                                                        style={{ marginRight: 8 }}
                                                                        checked={selected}
                                                                    />
                                                                    {option}
                                                                </li>
                                                            )}
                                                        />
                                                    } />
                                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                                <FormHelperText className='text-danger'>{errors.startupStages && errors.startupStages.message}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="form-group mt-2">
                                            <FormControl sx={{ width: '100%' }}>
                                                {/* <InputLabel htmlFor="grouped-native-select">Funding Status *</InputLabel> */}
                                                <Controller name="fundingStatuses" control={control} defaultValue="" rules={{ required: 'Funding Status is required' }}
                                                    render={({ field }) =>
                                                        // <Select
                                                        //     {...field}
                                                        //     placeholder='Funding Status'
                                                        //     multiple
                                                        //     value={fundingStatusesValue}
                                                        //     onChange={(e) => handleSelectFundingStatuses(e.target.value)}
                                                        //     input={<OutlinedInput label="Name" />}
                                                        //     MenuProps={MenuProps}
                                                        //     renderValue={(selected) => (
                                                        //         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        //             {selected.map((value) => (
                                                        //                 <Chip
                                                        //                     key={value}
                                                        //                     label={value}
                                                        //                     onDelete={() => console.log('')}
                                                        //                 />
                                                        //             ))}
                                                        //         </Box>
                                                        //     )}
                                                        // >

                                                        //     {fundingStatusesList.map((item) => (
                                                        //         <MenuItem
                                                        //             key={item}
                                                        //             value={item}
                                                        //             style={getStyles(item, fundingStatusesValue, theme)}
                                                        //         >
                                                        //             {item}
                                                        //         </MenuItem>
                                                        //     ))}
                                                        // </Select>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            multiple
                                                            {...field}
                                                            limitTags={2}
                                                            value={fundingStatusesValue}
                                                            options={fundingStatusesList}
                                                            onChange={(e, selectedOptions) => handleSelectFundingStatuses(selectedOptions)}
                                                            renderInput={(params) =>
                                                                <TextField {...params} label="Funding Status *" />
                                                            }
                                                            renderOption={(props, option, { selected }) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={icon}
                                                                        checkedIcon={checkedIcon}
                                                                        style={{ marginRight: 8 }}
                                                                        checked={selected}
                                                                    />
                                                                    {option}
                                                                </li>
                                                            )}
                                                        />
                                                    } />
                                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                                <FormHelperText className='text-danger'>{errors.fundingStatuses && errors.fundingStatuses.message}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="form-group mt-2">
                                            <FormControl sx={{ width: '100%' }}>
                                                {/* <InputLabel htmlFor="grouped-native-select">Preferred Sectors *</InputLabel> */}
                                                <Controller name="preferredSectors" control={control} defaultValue="" rules={{ required: 'Sector is required' }}
                                                    render={({ field }) =>
                                                        // <Select
                                                        //     {...field}
                                                        //     placeholder='Preferred Sectors'
                                                        //     multiple
                                                        //     value={preferredSectors}
                                                        //     onChange={(e) => handleSelectSector(e.target.value)}
                                                        //     input={<OutlinedInput label="Name" />}
                                                        //     MenuProps={MenuProps}
                                                        //     renderValue={(selected) => (
                                                        //         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        //             {selected.map((value) => (
                                                        //                 <Chip key={value} label={value} onDelete={() => console.log('')} />
                                                        //             ))}
                                                        //         </Box>
                                                        //     )}
                                                        // >
                                                        //     <MenuItem
                                                        //         key={"allSelect"}
                                                        //         value={"allSelect"}
                                                        //         style={getStyles("allSelect", preferredSectors, theme)}
                                                        //     >
                                                        //         {"Select All"}
                                                        //     </MenuItem>

                                                        //     {sectorData.map((name) => (
                                                        //         <MenuItem
                                                        //             key={name}
                                                        //             value={name}
                                                        //             style={getStyles(name, preferredSectors, theme)}
                                                        //         >
                                                        //             {name}
                                                        //         </MenuItem>
                                                        //     ))}
                                                        //     <MenuItem
                                                        //         key={"allRemove"}
                                                        //         value={"allRemove"}
                                                        //         style={getStyles("allRemove", preferredSectors, theme)}
                                                        //     >
                                                        //         {"Remove All"}
                                                        //     </MenuItem>
                                                        // </Select>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            multiple
                                                            {...field}
                                                            limitTags={2}
                                                            value={preferredSectors}
                                                            options={sectorData}
                                                            onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                                                            renderInput={(params) =>
                                                                <TextField {...params} label="Preferred Sectors *" />
                                                            }
                                                            renderOption={(props, option, { selected }) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={icon}
                                                                        checkedIcon={checkedIcon}
                                                                        style={{ marginRight: 8 }}
                                                                        checked={selected}
                                                                    />
                                                                    {option}
                                                                </li>
                                                            )}
                                                        />
                                                    } />
                                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                                <FormHelperText className='text-danger'>{errors.preferredSectors && errors.preferredSectors.message}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="form-group mt-2">
                                            <FormControl sx={{ width: '100%' }}>
                                                {/* <InputLabel htmlFor="grouped-native-select">Investment Ticket Size *</InputLabel> */}
                                                <Controller name="investTicketSize" control={control} defaultValue="" rules={{ required: 'Investment Ticket Size is required' }}
                                                    render={({ field }) =>
                                                        // <Select
                                                        //     {...field}
                                                        //     placeholder='Enter Invest Ticket Size'
                                                        //     multiple
                                                        //     value={investTicketSize}
                                                        //     onChange={(e) => handleSelectTicketSize(e.target.value)}
                                                        //     input={<OutlinedInput label="Name" />}
                                                        //     renderValue={(selected) => (
                                                        //         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        //             {selected.map((value) => (
                                                        //                 <Chip key={value} label={value} onDelete={() => console.log('')} />
                                                        //             ))}
                                                        //         </Box>
                                                        //     )}
                                                        //     MenuProps={MenuProps}
                                                        // >
                                                        //     {investmentTicketSize.map((name) => (
                                                        //         <MenuItem
                                                        //             key={name}
                                                        //             value={name}
                                                        //             style={getStyles(name, investTicketSize, theme)}
                                                        //         >
                                                        //             {name}
                                                        //         </MenuItem>
                                                        //     ))}
                                                        // </Select>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            multiple
                                                            {...field}
                                                            limitTags={2}
                                                            value={investTicketSize}
                                                            options={investmentTicketSize}
                                                            onChange={(e, selectedOptions) => handleSelectTicketSize(selectedOptions)}
                                                            renderInput={(params) =>
                                                                <TextField {...params} label="Investment Ticket Size (₹)*" />
                                                            }
                                                            renderOption={(props, option, { selected }) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={icon}
                                                                        checkedIcon={checkedIcon}
                                                                        style={{ marginRight: 8 }}
                                                                        checked={selected}
                                                                    />
                                                                    {option}
                                                                </li>
                                                            )}
                                                        />
                                                    } />
                                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                                <FormHelperText className='text-danger'>{errors.investTicketSize && errors.investTicketSize.message}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="form-group mt-2">
                                            <Controller name="totalInvestmentCommitted" control={control} defaultValue="" rules={{ required: 'Total Investment committed is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Total Investment committed *"
                                                        placeholder='Enter Total Investment committed'
                                                        fullWidth
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.totalInvestmentCommitted && errors.totalInvestmentCommitted.message}</FormHelperText>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="form-group mt-2">
                                            <Controller name="aun" control={control} defaultValue="" rules={{ required: 'Aun is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Aun *"
                                                        placeholder='Enter Aun'
                                                        fullWidth
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.aun && errors.aun.message}</FormHelperText>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-12'>
                                        <div className="form-group">
                                            <FormLabel>Have you invested in TN-based Startups?  *</FormLabel>
                                            <Controller name="investedInTNbasedStartup" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        name="position"
                                                        {...field}
                                                    >
                                                        <FormControlLabel onChange={(e) => { setInvestedStartupsStatus(e.target.value); }} value="true" control={<Radio />} label="Yes" />
                                                        <FormControlLabel onChange={(e) => { setInvestedStartupsStatus(e.target.value); }} value="false" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                } />
                                            <FormHelperText className='text-danger'>{errors.investedInTNbasedStartup && errors.investedInTNbasedStartup.message}</FormHelperText>
                                        </div>
                                    </div>
                                    {
                                        (investedStartupsStatus === 'true' || investedStartupsStatus === true) ? (
                                            <div className='col-lg-4 col-12'>
                                                <div className="form-group mt-2">
                                                    <Controller name="noOfPortfolioStartups" control={control} defaultValue="" rules={{ required: 'Number of Portfolio Startups from Tamil Nadu is required' }}
                                                        render={({ field }) =>
                                                            <TextField
                                                                variant='outlined'
                                                                label="Number of Portfolio Startups from Tamil Nadu *"
                                                                placeholder='Enter Number of Portfolio Startups from Tamil Nadu'
                                                                fullWidth
                                                                {...field}
                                                            />} />
                                                    <FormHelperText className='text-danger'>{errors.noOfPortfolioStartups && errors.noOfPortfolioStartups.message}</FormHelperText>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='col-lg-4 col-12'>
                                                <div className="form-group mt-2">
                                                    <Controller name="noOfStartupsInPipeline" control={control} defaultValue="" rules={{ required: 'Number of Startups in PipleLine is required' }}
                                                        render={({ field }) =>
                                                            <TextField
                                                                variant='outlined'
                                                                label="Number of Startups in PipleLine *"
                                                                placeholder='Enter Number of Startups in PipleLine'
                                                                fullWidth
                                                                {...field}
                                                            />} />
                                                    <FormHelperText className='text-danger'>{errors.noOfStartupsInPipeline && errors.noOfStartupsInPipeline.message}</FormHelperText>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='div_header'>
                                    <h6>Terms and Condition</h6>
                                </div>
                                <Paper style={{ maxHeight: 130, overflow: 'auto' }}>
                                    <List>
                                        <div dangerouslySetInnerHTML={termsAndConditionsMarkup()} />
                                    </List>
                                </Paper>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='div_header'>
                                    <h6>Privacy Policy</h6>
                                </div>
                                <Paper style={{ maxHeight: 130, overflow: 'auto' }}>
                                    <List>
                                        <div dangerouslySetInnerHTML={privacyPolicyMarkup()} />
                                    </List>
                                </Paper>
                            </div>
                            <div className='col-12 mb-3'>
                                <Controller name="acceptTermsAndConditions" control={control} defaultValue="" rules={{ required: 'Required' }}
                                    render={({ field }) =>
                                        <FormControlLabel {...field} control={<Checkbox checked={acceptTermsChecked} onChange={handleChangeChecked} label="I accept the Terms and Conditions." />}
                                            label="I accept the Terms and Conditions. *" />
                                    } />
                                <FormHelperText className='text-danger'>{errors.acceptTermsAndConditions && errors.acceptTermsAndConditions.message}</FormHelperText>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3"
                            >
                                <span>Save</span>
                            </LoadingButton>
                            <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>
                        </div>
                    </form>
                </div>
            </Container>
        </section>
    )
}

export default Partnerprofile
import React, { useContext } from 'react'
import { Button, Container } from '@mui/material'
import DataContext from '../../../context/DataContext'
const Signupalert = () => {
    const { navigator, handleClose } = useContext(DataContext)
    const loginPage = () => {
        handleClose()
        navigator('sign-in')
    }
    return (
        <Container>
            <div className='sign_up_modal'>
                <img src='/images/Tanfundlogo-black-1.png' className='img-fluid sign_up_logo' alt=''/>
                <h2 className='mb-3'>Registration success</h2>
                <h3>Your credentials has been shared to your email id.</h3>
                <Button variant="outlined" onClick={loginPage}>Ok</Button>
            </div>
        </Container>
    )
}

export default Signupalert
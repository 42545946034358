import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom'
import { Box, FormHelperText, Modal, TextField } from '@mui/material';
import { useForm, useFormState } from "react-hook-form";
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import InputAdornment from '@mui/material/InputAdornment';
// import cryptoJS from '../../../hooks/cryptoJS';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import './signin.css';
// import DataContext from '../../../context/DataContext';
// import notifyService from '../../../api/notifySerivce';
// import Signuproutemodal from '../signup-page/signupModal/Signuproutemodal';
import ReCAPTCHA from 'react-google-recaptcha';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import cryptoJS from '../../../../hooks/cryptoJS';
import apiService from '../../../../api/apiService';
import Sendotp from './Sendotp';
import Newpassword from './Newpassword';
import Signuproutemodal from '../../signup-page/signupModal/Signuproutemodal';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import CustomLink from '../../../common-page/CustomLink';

const Forgotpassword = () => {


    const { modalstyle, handleClose, open, handleOpen, navigator } = useContext(DataContext)
    // const { register, handleSubmit, control } = useForm();
    // const { errors } = useFormState({ control });
    // const [showPassword, setShowPassword] = React.useState(false);
    // const handleClickShowPassword = () => setShowPassword((show) => !show);
    // const [capVal, setCapVal] = useState(null)
    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };
    // const onSubmit = async (data, event) => {
    //     var getform = data
    //     getform.password = cryptoJS(data.password)
    //     await apiService('login', data, 'unauthpost').then((res) => {
    //         if (res) {
    //             notifyService('success', 'Success', 'Welcome to TANFUND !!')
    //             localStorage.setItem('jwttoken', res.data.token)
    //             localStorage.setItem('userId', res.data.userId)
    //             localStorage.setItem('role', res.data.role)
    //             localStorage.setItem('privileges', JSON.stringify(res.data.privileges))
    //             localStorage.setItem('username', res.data.userName)
    //             localStorage.setItem('logo', res.data.organizationLogoName)
    //             localStorage.setItem('name', res.data.name)
    //             localStorage.setItem('forcePassword', res.data.forceChangePassword)
    //             event.target.reset();
    //             if (res.data.role === 'ADMIN') {
    //                 localStorage.setItem('routePath', '/admin/partner')
    //                 navigator('/admin/partner')
    //             } else if (res.data.role === 'STARTUP') {
    //                 localStorage.setItem('routePath', '/startup/dashboard')
    //                 navigator('/startup/dashboard')
    //             } else if (res.data.role === 'PARTNER') {
    //                 localStorage.setItem('routePath', '/partner/startup')
    //                 if (res.data.profileUpdated === true) {
    //                     navigator('/partner/startup')
    //                 } else {
    //                     navigator('/partner/profile')
    //                 }
    //             } else if (res.data.role === 'INVESTOR') {
    //                 localStorage.setItem('routePath', '/investor/startup')
    //                 if (res.data.profileUpdated === true) {
    //                     navigator('/investor/startup')
    //                 } else {
    //                     navigator('/investor/profile')
    //                 }
    //             } else if (res.data.role === 'JURY') {
    //                 localStorage.setItem('routePath', '/jury/startup')
    //                 navigator('/jury/startup')
    //             }
    //         }
    //     })

    // };
    useDidMountEffect(() => {
        document.getElementById('theme').setAttribute('href', '/css/admin.css');
    }, [])
    const [checkId, setCheckId] = useState(false)
    const handleCheck = () => {
        setCheckId(true)
        // let startupid = Number(localStorage.getItem('StartupId'))
    }
    return (
        <>
            <section className="log-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <div className="wrap d-md-flex">
                                <div className="text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last">
                                    <div className="text w-100">
                                        <img src='/images/Tanfundlogo-white-1.png' className='signin_logo' alt='' />
                                        <div>
                                            <CustomLink to="/sign-in" className="btn btn-white btn-outline-white">Sign In</CustomLink>
                                        </div>

                                    </div>
                                </div>
                                <div className="login-wrap p-4 p-lg-5 bg-white">
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <h3 className="mb-4">Forgot Password</h3>
                                        </div>
                                    </div>
                                    {
                                        (checkId === false) && (<Sendotp handleCheck={handleCheck} />)
                                    }
                                    {
                                        (checkId === true) && (<Newpassword />)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag_lg">
                    <Signuproutemodal handleClose={handleClose} />
                </Box>
            </Modal>
        </>
    )
}

export default Forgotpassword
import React, {useContext} from 'react'
import './tanseed.css'
import DataContext from '../../../context/DataContext'
import { Link } from 'react-router-dom'


const Tanseed = () => {
  const { navigator, modalstyle, handleClose, open, handleOpen } = useContext(DataContext)

  const handleSignUp = () => {
    navigator('/startup/sign-up')
  }
  
  const handleLogin = () => {
    navigator('/sign-in')
  }

  return (
    <div>

      <section className='tanseed-bg'>
        <div>
          {/* <div className='tanseed-bg-img'>
            <img src='/images/modal-bg.png' alt='' className='tanseed-img mt-4' />
          </div> */}
          <div className='tanseed-content'>
            <img src='/images/tanseed-logo.png' className='tanseed-logo' alt=''/>
            <h1 className='head font-weight-bolder'>TANSEED 6.0</h1>
            <p>The Next Biggest Opportunity to Kickstart Your Startup</p>
            {/* <p className='date'>Last date to Apply: <p className='yellow-clr font-weight-bold'>FEBRUARY 7, 2024</p></p> */}
            {/* <p className='thank '>Thank you for showing interest in <span className='yellow-color'>TANSEED 6.0</span></p> */}
            {/* <p className='reg'>Please Sign-up and Complete the application to apply</p> */}
            <p className='thank'>We appreciate your interest in applying for <span className='yellow-color'>TANSEED 6.0</span>.</p>
            <p className='reg m-0'>As the next step, please signup and submit your startup details.</p>
            <p className='m-0'>For any support feel free to reach us at <Link className='tanseedlink'>tanseed@startuptn.in</Link> or call us at <Link className='tanseedlink'>155343</Link></p>
            <button className='signup-btn' onClick={handleSignUp}>Sign Up <img src='/images/sigup-btn.png' className='pt-1 pl-1 arrow-img' alt=''/></button>
            <div className='r-dir'><p className='white-clr'>Already Registered with TANFUND?  <span className='grn-btn' onClick={handleLogin}>Login & Apply</span> </p></div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Tanseed
import React, { useContext, useState } from 'react';
import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DataContext from '../../../../context/DataContext';
import apiService from '../../../../api/apiService';
// import downloadExcel from '../../../../hooks/downloadExcel';
import { Link } from 'react-router-dom';
import Reviewupdate from './Reviewupdate';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import CustomLink from '../../../common-page/CustomLink';
const Startuplist = ({ name, path, endpoint }) => {
    const { modalstyle, searchInputValue, startupListData, page, rowsPerPage, startuplistItem } = useContext(DataContext)
    const [checkedList, setCheckedList] = useState([]);
    useDidMountEffect(() => {
        (async () => await startuplistItem(name, endpoint))()
        // eslint-disable-next-line
    }, [page, rowsPerPage, searchInputValue, checkedList, name, path, endpoint])
    // const [loading, setLoading] = useState(false);
    // const exportExcel = async () => {
    //     apiService('export/startups', '', 'download').then((res) => {
    //         setLoading(false);
    //         downloadExcel(res.data, 'startup_')
    //     })
    // }
    const [openView, setOpenView] = useState(false);
    const [getData, setSetGetData] = useState(0);
    const handleViewOpen = (id, selected, remarks) => {
        var req = {
            "id": id,
            "selected": selected,
            "remarks": remarks
        }
        setSetGetData(req)
        setOpenView(true)
    }
    const handleViewClose = () => {
        setOpenView(false);
        startuplistItem(name, endpoint)
    }
    const promoteRound = (id) => {
        const collection = [];
        collection.push(id);
        // setCheckedList(collection);
        var val = collection

        promoteroundsApi(val)
    }
    const selectPromoteRound = () => {
        var val = checkedList

        promoteroundsApi(val)
    }
    const promoteroundsApi = (val) => {

        apiService(`partner/startups/promoterounds`, val, 'post').then((res) => {
            if (res.data.responseStatus === "Success") {
                setCheckedList([]);
                setItemsChecked(false);
                startuplistItem(name, endpoint)
            }

        })
    }

    const [itemsChecked, setItemsChecked] = useState(false);
    const [indexValue, setIndexValue] = useState([])
    const handleCheckboxClick = (e, index) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckedList([...checkedList, value * 1]);
            setIndexValue([indexValue, index])

            sectFgs()
        } else {
            // indexValue
            setIndexValue(indexValue.filter((item) => item !== index))

            setCheckedList(checkedList.filter((item) => item !== value));
            if (checkedList.length === 1) {
                setItemsChecked(false);
            }
            // sectFgs()
        }
    };
    const sectFgs = () => {
        console.log(indexValue.length)
        if (startupListData.length === indexValue.length) {
            setItemsChecked(true);
        } else {
            setItemsChecked(false);
        }
    }
    const selectItem = (e) => {
        const { checked } = e.target;
        const collection = [];
        if (checked) {
            for (const category of startupListData) {
                collection.push(category.id);
            }
        }
        setCheckedList(collection);
        setItemsChecked(checked);
    };
    return (
        <section className=''>
            <div className='container-fluid'>
                <div className='form_div chart'>
                    <div className='header_tag'>
                        <h5>Associated Startups List</h5>
                    </div>
                    <div className='table_tag'>
                        <div className='table_header_tag d-flex'>
                            {
                                (checkedList.length > 0 && (
                                    <Button className='' variant="outlined" onClick={selectPromoteRound}>Promote Round</Button>
                                ))
                            }

                            {/* <LoadingButton className='ml-auto'
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CloudDownloadIcon />}
                            variant="outlined"
                            onClick={exportExcel}
                        >
                            <span>EXPORT STARTUP</span>
                        </LoadingButton> */}
                        </div>
                        {
                            (startupListData.length) ? (
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={itemsChecked} onClick={selectItem.bind(this)}
                                                    />
                                                </TableCell>
                                                <TableCell align='center'>No</TableCell>
                                                <TableCell>Startup Name</TableCell>
                                                <TableCell align='center'>Rank</TableCell>
                                                <TableCell align='center'>Score</TableCell>
                                                <TableCell align='center'>Selected</TableCell>
                                                <TableCell align='center'>Remarks </TableCell>
                                                <TableCell align='center'>Inprogress / Completed / Total Round</TableCell>
                                                {
                                                    (path === 'partner' || path === 'investor') ? (
                                                        <>
                                                            <TableCell align='center'>Action</TableCell>
                                                        </>
                                                    ) : (<></>)
                                                }

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {startupListData.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            item={list}
                                                            value={list.id}
                                                            checked={checkedList.includes(list.id)}
                                                            onChange={e => handleCheckboxClick(e, index)}
                                                        />
                                                    </TableCell>
                                                    <TableCell align='center'>{index + 1}</TableCell>
                                                    <TableCell>
                                                        <CustomLink to={`/${path}/associated-startups/details/${btoa(list.startupId)}/${btoa(list.partnerOrInvestorId)}`}>{list.startupName}</CustomLink>
                                                    </TableCell>
                                                    <TableCell align='center'>{list.rank}</TableCell>
                                                    <TableCell align='center'>{list.score}</TableCell>
                                                    <TableCell align='center' className='c-pointer' onClick={() => {
                                                        (path === 'partner' || path === 'investor') ? (handleViewOpen(list.id, list.selected, list.remarks)) : (<></>)
                                                    }}>

                                                        {
                                                            (list.selected === 1) ? (<span className='yes_span'>Yes</span>) : <></>
                                                        }
                                                        {
                                                            (list.selected === 2) ? (<span className='no_span'>No</span>) : <> </>
                                                        }
                                                        {
                                                            (list.selected === 0) ? (<span className='review_span'>Under Review</span>) : <></>
                                                        }
                                                    </TableCell>
                                                    <TableCell align='center' className='c-pointer' onClick={() => {
                                                        (path === 'partner' || path === 'investor') ? (handleViewOpen(list.id, list.selected, list.remarks)) : (<></>)
                                                    }}>
                                                        {(list.remarks) ? (list.remarks) : (<>-</>)}
                                                    </TableCell>
                                                    <TableCell align='center' >
                                                        {(list.inprogressRounds) ? (list.inprogressRounds) : (<>0</>)} / {(list.completedRounds) ? (list.completedRounds) : (<>0</>)} / {(list.totalRounds) ? (list.totalRounds) : (<>0</>)}
                                                    </TableCell>
                                                    {
                                                        (path === 'partner' || path === 'investor') ? (
                                                            <>

                                                                <TableCell align='center'>
                                                                    {
                                                                        (list.roundFlag === 1) ? (<span className='start_ron' onClick={() => promoteRound(list.startupId)}>Start Round</span>) :
                                                                            (<></>)
                                                                    }
                                                                    {
                                                                        (list.roundFlag === 2) ? (<span className='pro_ron' onClick={() => promoteRound(list.startupId)}>Promote Round</span>) :
                                                                            (<></>)
                                                                    }
                                                                    {
                                                                        (list.roundFlag === 0) ? (<span>-</span>) :
                                                                            (<></>)
                                                                    }
                                                                </TableCell>
                                                            </>
                                                        ) : (<></>)
                                                    }

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            ) : (
                                <div className='nodata_tag'>
                                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                    <h4>Startup Association Inprogress</h4>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
            <Modal
                open={openView}
                onClose={handleViewClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Reviewupdate handleViewClose={handleViewClose} getData={getData} />
                </Box>
            </Modal>
        </section>
    )
}

export default Startuplist
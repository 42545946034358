import React, { useContext } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './adminnavbar.css'
import { NavLink } from 'react-router-dom';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Adminnavbar = () => {
  const { setActiveStartup, setActive, setInvestorProfileType, setSearchInputValue, adminStartupRemoveFilter } = useContext(DataContext)

  const investorHandle = () => {
    setActive(1)
    setInvestorProfileType(2)
    setSearchInputValue('')
  }

  const handleReset = () => {
    setSearchInputValue('')
  }
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
  };
  return (
    <nav>
      <div className='submenu_div'>
        <div className='container-fluid'>
          <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/admin/dashboard" onClick={(e) => { handleClick(e); adminStartupRemoveFilter() }}>Dashboard</NavLink>
            </Typography>
            {/* <Typography sx={{ minWidth: 100 }}>
            <NavLink className="nav-link"  to="/admin/partner" onClick={handleClick}>Partners</NavLink>
          </Typography> */}
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/admin/investor" onClick={(e) => { investorHandle(); handleClick(e); adminStartupRemoveFilter() }}>Investors</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/admin/jury" onClick={(e) => { handleClick(e); adminStartupRemoveFilter() }}>Jury</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/admin/startup" onClick={(e) => { setActiveStartup(1); handleReset(); handleClick(e); }}>Startups</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/admin/associated-startups" onClick={(e) => { handleClick(e); adminStartupRemoveFilter() }}>Associated Startups</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/admin/tanseed" onClick={(e) => { handleClick(e); adminStartupRemoveFilter() }}>TANSEED</NavLink>
            </Typography>
          </Box>
        </div>
      </div>
    </nav>
  )
}

export default Adminnavbar
import React, { useContext, useState } from 'react'

import { useTheme } from '@mui/material/styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Modal, TextField } from '@mui/material';
import DataContext from '../../context/DataContext';
import apiService from '../../api/apiService';
import ReadMore from './ReadMore';
const SampleTemplates = ({ setFormData, template, mode }) => {
    const { fullScreen } = useContext(DataContext)
    const [openTemplateModal, setOpenTemplateModal] = useState(false);
    const [templateList, setTempleteList] = useState([])
    const handleTemplateOpen = (event) => {
        setOpenTemplateModal(!openTemplateModal);
        templateGetApi()
    };
    const handleTemplateClose = () => {
        setOpenTemplateModal(false);
        setSelectTemplate('')
        setTemplateValidation(false)
    };
    const templateGetApi = () => {
        apiService(`admin/notification/template/list?template=${template}&mode=${mode}`,'','get').then((result) => {
            if (result) {
                if (result.data) {
                    if (result.data.templates) {
                        setTempleteList(result.data.templates)
                    }
                }
            }
        }).catch((err) => {

        });
    }
    const [selectTemplate, setSelectTemplate] = useState('')
    const [templateValidation, setTemplateValidation] = useState(false)
    const handleSelectIndex = (index) => {
        setSelectTemplate(index)
        setTemplateValidation(false)
    }
    const handleSelectTemplete = () => {
        if (selectTemplate !== '') {
            setTemplateValidation(false)
            var data = templateList[selectTemplate]
            setFormData(data)
            handleTemplateClose()
        } else {
            setTemplateValidation(true)
        }
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleTemplateOpen}>
                Choose Template
            </Button>
            <Dialog
                open={openTemplateModal}
                fullScreen={fullScreen}
                maxWidth={'lg'}
            >
                <DialogTitle >
                    <div className='d-flex align-item-center'>
                        Choose Template
                        <HighlightOffIcon className='ml-auto c-red c-pointer' onClick={handleTemplateClose} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className=''>

                        <div className='p-3'>
                            {
                                templateList.length > 0 ? (
                                    <>
                                        <div className='row'>
                                            {
                                                templateList.map((list, index) => (
                                                    <div className='col-lg-6 col-md-6 col-12'>
                                                        <div className={selectTemplate === index ? ('template_structer activeTemplate') : ('template_structer')} onClick={() => handleSelectIndex(index)}>
                                                            <div className='templete header'>
                                                                <h4 className='mb-0'>{list.name}</h4>
                                                            </div>
                                                            <div className='templete_subject'>
                                                                <p>Subject : <b>{list.subject}</b></p>
                                                                {/* <p>{list.message}</p> */}
                                                                <ReadMore text={list.message} length={100} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))

                                            }
                                        </div>
                                        {
                                            templateValidation === true && (
                                                <FormHelperText className='text-danger'>
                                                    Select any one template
                                                </FormHelperText>
                                            )
                                        }

                                    </>


                                ) : (
                                    <>
                                        <div className='nodata_tag'>
                                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                            <h4>Template Not Available</h4>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className='p-3'>
                    <Button onClick={handleTemplateClose} variant="outlined" size="small">Close</Button>
                    <Button onClick={handleSelectTemplete} variant="contained" size="small" autoFocus>
                        Select
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SampleTemplates
import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { Dialog } from "@mui/material";

import Slide from "@mui/material/Slide";
import Tanseed6 from "../dashboard-page/form-page/Tanseed6";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Termsandconditioninvestor = () => {
  const { navigator } = useContext(DataContext);
  const [formDate, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formDate });
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getApi = async () => {
    await apiService(`startup/startupdeclaration/get`, "", "get").then(
      (res) => {
        if (res) {
          var response = res.data;
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
          // setTermsAndConditionsHTML(response.termsAndConditions)
          // setPrivacyPolicyHTML(response.privacyPolicy)
          setAcceptPrivacyPolicyValue(response.acceptPrivacyPolicy);
          setAcceptAgreementValue(response.acceptAgreement);
          setTermsAndConditionsValue(response.acceptTermsAndConditions);
          // setAcceptTanseedTermValue(response.acceptTanseedTerms)
          setFormData(response);
          // setValue('termsAndConditions', response.termsAndConditions)
          // setValue('accept', response.termsAndConditions)
          // setValue('terms', response.termsAndConditions)
        }
      }
    );
  };

  const [acceptPrivacyPolicyValue, setAcceptPrivacyPolicyValue] =
    useState(null);
  const handleChangeAcceptPrivacyPolicy = (event) => {
    setAcceptPrivacyPolicyValue(event.target.checked);
    if (event.target.checked) {
      setValue("acceptPrivacyPolicy", event.target.checked);
      clearErrors("acceptPrivacyPolicy");
    } else {
      setValue("acceptPrivacyPolicy", null);
    }
  };
  const [acceptAgreementValue, setAcceptAgreementValue] = useState(null);
  const handleChangeAcceptAgreement = (event) => {
    setAcceptAgreementValue(event.target.checked);
    if (event.target.checked) {
      setValue("acceptAgreement", event.target.checked);
      clearErrors("acceptAgreement");
    } else {
      setValue("acceptAgreement", null);
    }
  };
  const [termsAndConditionsValue, setTermsAndConditionsValue] = useState(null);
  const handleChangeTermsAndConditions = (event) => {
    setTermsAndConditionsValue(event.target.checked);
    if (event.target.checked) {
      setValue("acceptTermsAndConditions", event.target.checked);
      clearErrors("acceptTermsAndConditions");
    } else {
      setValue("acceptTermsAndConditions", null);
    }
  };
  // const [acceptTanseedTermsValue, setAcceptTanseedTermValue] = useState(null);
  // const handleChangeAcceptTanseedTerms = (event) => {
  //     setAcceptTanseedTermValue(event.target.checked);
  //     if (event.target.checked) {
  //         setValue('acceptTanseedTerms', event.target.checked)
  //         clearErrors('acceptTanseedTerms')
  //     } else {
  //         setValue('acceptTanseedTerms', null)
  //     }
  // }
  const [loading, setLoading] = useState(false);
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      getform.throughInvestorConnect = true;
      apiService("startup/startupdeclaration/save", getform, "post").then(
        (res) => {
          setLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              notifyService("success", "Success", "Saved successfully !!");
              // debugger
              // if (formDate.optedForTanseed === true) {
              //     navigator('/startup/form-submitted')
              // }
              navigator("/startup/form-submitted");
            }
          }
        }
      );
      // if (formDate.optedForTanseed === false) {
      //     handleClickOpenApply()
      // }
    }
  };

  const handlePrevious = () => {
    navigator("/startup/investor/tanfund");
  };
  const [openApply, setApplyOpen] = useState(false);

  // const handleClickOpenApply = () => {
  //     setApplyOpen(true);
  // };

  const handleApplyClose = () => {
    setApplyOpen(false);
    getApi();
    navigator("/startup/form-submitted");
  };
  return (
    <>
      <section className="">
        <div className="container">
          <div className="form_div chart">
            <div className="terms-head">
              <div className="chart-det">
                <div>
                  <h5 className="font-weight-bold mb-3">
                    Terms And Conditions
                  </h5>
                </div>
              </div>
            </div>
            <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <div className="col-lg-12 col-12">
                  <div className="d-flex">
                    <div>
                      <Controller
                        name="acceptAgreement"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <FormControlLabel
                            {...field}
                            control={
                              <Checkbox
                                disabled={readyOnlyValue}
                                checked={acceptAgreementValue}
                                onChange={handleChangeAcceptAgreement}
                              />
                            }
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.acceptAgreement &&
                          errors.acceptAgreement.message}
                      </FormHelperText>
                    </div>
                    <div className="mt-2">
                      <p>
                        I hereby understand the process of raising of funds for
                        my venture through various investment agencies at
                        TANFUND Portal of StartupTN and hereby irrevocably and
                        unconditionally agree to indemnify and hold startupTN
                        harmless, on demand, from and against any and all Losses
                        (including without limitation legal and other
                        professional fees and expenses) incurred towards the
                        investment funds raise for my venture through TANFUND
                        Portal of StartupTN.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="d-flex">
                    <div>
                      <Controller
                        name="acceptTermsAndConditions"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <FormControlLabel
                            {...field}
                            control={
                              <Checkbox
                                disabled={readyOnlyValue}
                                checked={termsAndConditionsValue}
                                onChange={handleChangeTermsAndConditions}
                              />
                            }
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.acceptTermsAndConditions &&
                          errors.acceptTermsAndConditions.message}
                      </FormHelperText>
                    </div>
                    <div className="mt-2">
                      <p>
                        I herewith agree with{" "}
                        <Link
                          className="c-blue"
                          to={
                            "https://startuptn-public-documents.s3.ap-south-1.amazonaws.com/TANFUND_Terms.pdf"
                          }
                          target="_blank"
                        >
                          Terms and Conditions
                        </Link>
                        .
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12 mb-3">
                  <div className="d-flex">
                    <div>
                      <Controller
                        name="acceptPrivacyPolicy"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <FormControlLabel
                            {...field}
                            control={
                              <Checkbox
                                disabled={readyOnlyValue}
                                checked={acceptPrivacyPolicyValue}
                                onChange={handleChangeAcceptPrivacyPolicy}
                              />
                            }
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.acceptPrivacyPolicy &&
                          errors.acceptPrivacyPolicy.message}
                      </FormHelperText>
                    </div>
                    <div className="mt-2">
                      <p>
                        I herewith agree with{" "}
                        <Link
                          className="c-blue"
                          to={
                            "https://startuptn-public-documents.s3.ap-south-1.amazonaws.com/TANFUND_PrivacyPolicy.pdf"
                          }
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </p>
                    </div>
                  </div>
                </div>

                {/* {
                                    formDate.optedForTanseed === true && (
                                        <div className='col-lg-12 col-12 mb-3'>
                                            <div className='d-flex'>
                                                <div>
                                                    <Controller name="acceptTanseedTerms" control={control} defaultValue="" rules={{ required: 'Required' }}
                                                        render={({ field }) =>
                                                            <FormControlLabel {...field} control={<Checkbox disabled={readyOnlyValue} checked={acceptTanseedTermsValue} onChange={handleChangeAcceptTanseedTerms} />}
                                                            />
                                                        } />
                                                    <FormHelperText className='text-danger'>{errors.acceptTanseedTerms && errors.acceptTanseedTerms.message}</FormHelperText>
                                                </div>
                                                <div className='mt-2'>
                                                    <ol className='pl-3'>
                                                        <li>
                                                            I/We, declare that all the statements made in this application are true, complete and correct to the best of my/our knowledge and belief. In the event of any information, found false or incorrect, my/our candidature will stand cancelled and all my claims will be forfeited.
                                                        </li>
                                                        <li>
                                                            I/We, hereby also certify that no loan or any other financial liabilities are pending with me/us on accord of financial support availed by me/us from other Government Institutions / Agencies for my/our any other innovations.
                                                        </li>
                                                        <li>
                                                            I/We, declare that the Startup is not formed by splitting up, reconstruction of a business already in existence or as a Subsidiary/JV/Associate of another company.
                                                        </li>
                                                        <li>
                                                            I/We, also, understand StartupTN reserves all the rights towards the selection of applications based on the defined criteria and that the decision of the committee and jury is final.
                                                        </li>
                                                        <li>
                                                            I/We, declare that the Startup is registered with StartupTN & Startup India and is located & registered in Tamil Nadu or will relocate the registered office to Tamil Nadu.
                                                        </li>
                                                        <li>
                                                            I/We acknowledge that TANSEED has undergone restructuring as the Support Equity Linked Grant Fund. Upon securing TANSEED, it is required to allocate a 3% support stake of the company to StartupTN.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } */}
                <div className="form-group text-center">
                  <Button
                    // variant="contained"
                    onClick={handlePrevious}
                    className="btn-primary-cancel px-3 mr-3"
                  >
                    {readyOnlyValue === false ? `Cancel` : `Close`}
                  </Button>
                  {readyOnlyValue === false ? (
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      loadingPosition="start"
                      // startIcon={<SaveIcon />}
                      // variant="contained"
                      className="btn btn-primary-submit px-3"
                    >
                      <span>I Accept</span>
                    </LoadingButton>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <Dialog
          open={openApply}
          TransitionComponent={Transition}
          keepMounted
          className="text-center p-5"
          fullWidth
          maxWidth="md"
        >
          <Tanseed6
            handleApplyClose={handleApplyClose}
            optedForTanseed={formDate.optedForTanseed}
            page={"terms"}
          />
        </Dialog>
      </section>
    </>
  );
};

export default Termsandconditioninvestor;

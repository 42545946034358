import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
// const top100Films = [
//   { title: 'The Shawshank Redemption', year: 1994 },
//   { title: 'The Godfather', year: 1972 },
//   { title: 'The Godfather: Part II', year: 1974 },
//   { title: 'The Dark Knight', year: 2008 },
//   { title: '12 Angry Men', year: 1957 },
//   { title: "Schindler's List", year: 1993 },
//   { title: 'Pulp Fiction', year: 1994 },
// ]

const FundingTanseed = () => {
  const [formData, setFormData] = useState({
    // fundHistory: [{}]
  });
  const {
    handleBacktanseed,
    navigator,
    // MenuProps,
    // Paper,
    // modalstyle,
    icon,
    checkedIcon,
  } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    // getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  // const {
  //   fields: fundFields,
  //   append: fundAppend,
  //   remove: fundRemove,
  // } = useFieldArray({
  //   control,
  //   name: "fundHistory",
  // });
  // const [fundingProgramDetails, setFundingProgramDetails] = useState([]);
  // let preferredInvestorTypeList = []
  // let investmentTicketSize = []

  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    dropdownValueApi();
    // eslint-disable-next-line
  }, []);
  // const [preferredInvestorTypeList, setPreferredInvestorTypeList] = useState(
  //   []
  // );
  // const [investmentTicketSize, setInvestmentTicketSize] = useState([]);
  // const [instrumentTypeData, setInstrumentTypeData] = useState([]);
  // const [revenuesList, setLevenuesList] = useState([]);
  // const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState(
  //   []
  // );
  // const [fundingProgramsList, setfundingProgramsList] = useState([]);
  const [roundFundingList, setRoundFundingList] = useState([]);
  const [roundFundingValue, setRoundFundingValueValue] = useState([]);
  const handleRoundFunding = (value) => {
    setRoundFundingValueValue(value);
    setValue("roundsfinancing", value);
    clearErrors("roundsfinancing");
  };
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        if (val.roundsFinancing) {
          setRoundFundingList(val.roundsFinancing);
        }
      }
    );
  };
  const getApi = async () => {
    await apiService(`startup/fundingandfinancials/get`, "", "get").then(
      (res) => {
        if (res) {
          setFormData({});
          let response = res.data;
          if (response.id !== 0) {
            if (response.editForm === false) {
              setReadyOnlyValue(true);
            }
            if (response.roundsfinancing) {
              setRoundFundingValueValue(response.roundsfinancing);
            }
            setFormData(response);
          } else {
            setFormData(response);
          }
        }
      }
    );
  };

  const onSubmit = (data) => {
    // checkFundingValidation()
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      // if (fundingProgramDetails.length > 0) {
      //   getform.startupFundingPrograms = fundingProgramDetails;
      // } else {
      //   getform.startupFundingPrograms = [];
      // }

      apiService("startup/fundingandfinancials/save", getform, "post").then(
        (res) => {
          setLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              notifyService("success", "Success", "Saved successfully !!");
              navigator("/startup/fundraisedhistory/tanseed");
            }
          }
        }
      );
    }
  };

  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBacktanseed}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Funding & Financials</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row align-items-center">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-12 mt-2">
                <div className="form-group mt-2">
                  <Controller
                    name="annualRevenue"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Annual Recurring Revenue is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Annual Recurring Revenue (₹)*"
                        placeholder="Enter Annual Recurring Revenue (₹)"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.annualRevenue && errors.annualRevenue.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12 mt-2">
                <div className="form-group mt-2">
                  <Controller
                    name="monthlyBurnRate"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Monthly Recurring Revenue is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Monthly Recurring Revenue (₹)*"
                        placeholder="Enter Monthly Recurring Revenue (₹)"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.monthlyBurnRate && errors.monthlyBurnRate.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <FormControl sx={{ width: "100%", marginTop: "25px" }}>
                  <Controller
                    name="roundsfinancing"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }} // 'Last funding round status is required'
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        multiple
                        {...field}
                        limitTags={2}
                        value={roundFundingValue}
                        options={roundFundingList}
                        onChange={(e, selectedOptions) =>
                          handleRoundFunding(selectedOptions)
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Round of financing *" />
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                      />
                    )}
                  />
                  <FormHelperText className="fnt-sm c-blue">
                    Select one or more
                  </FormHelperText>
                  <FormHelperText className="text-danger">
                    {errors.roundsfinancing && errors.roundsfinancing.message}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
          {/* <Modal
                        open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalstyle} className="form_tag_lg">
                            <Fundingprograms handleClose={handleClose} fundingProgramDetails={fundingProgramDetails} setFundingProgramDetails={setFundingProgramDetails} fundingProgramIndex={fundingProgramIndex} fundingProgramsList={fundingProgramsList} />
                        </Box>
                    </Modal> */}
        </div>
      </section>
    </>
  );
};

export default FundingTanseed;

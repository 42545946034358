import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DataContext from '../../../../context/DataContext';
// import DataContext from '../../../context/DataContext';

const Suggestionsview = ({ suggestions }) => {
    const { modalstyle } = useContext(DataContext)
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(0)
    const [assignStatus, setAssignStatus] = useState('')
    const assignStatusBtn = () => {

        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    return (
        <>
            {
                suggestions ? (
                    <Button onClick={() => setOpenModal(true)}>View</Button>
                ) : (`-`)
            }
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag p-5">
                    <div className='text-center'>
                        <ol>
                            {
                                suggestions && suggestions.map((res) => (
                                    <li>{res}</li>
                                ))
                            }
                        </ol>
                        <center>
                            <Button onClick={handleCloseModal} variant="outlined">Ok</Button>
                        </center>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default Suggestionsview
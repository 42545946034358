import React from 'react'
import { Outlet } from 'react-router-dom'
import Adminnavbar from './navbar-page/Adminnavbar'
import useDidMountEffect from '../../hooks/useDidMountEffect'
const Adminlayout = () => {
  localStorage.setItem('regRoleId',2) 
  useDidMountEffect(()=>{
    document.getElementById('theme').setAttribute('href', '/css/admin.css');
  },[])
  return (
    <>     
      <div className='admin_main_div'>
        <Adminnavbar />
        <Outlet></Outlet>
      </div>
    </>
  )
}

export default Adminlayout
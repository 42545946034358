import { Autocomplete, Box, Button, Divider, FormControl, Grid, Tab, Tabs, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Navigate } from "react-router-dom";
import DataContext from "../../context/DataContext";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import apiService from "../../api/apiService";
import { TabContext } from "@mui/lab";
import Toolbar from "@mui/material/Toolbar";
import ReactApexChart from 'react-apexcharts';
import Chart from "react-apexcharts";

const Scsthubdashboard = () => {
    const { navigator, setPageLoading, yearListApi, yearListValue, setSelectedYear } = useContext(DataContext)
    const [chartData, setChartData] = useState([]);
    const [investorsListValue, setInvestorListValue] = useState([]);
    const [selectedItem, setSelectedItem] = useState('All');
    const [yearName, setYearName] = useState('0');
    const [yearNameSelected, setYearNameSelected] = useState('All');
    const [monthName, setMonthName] = useState(0);
    const [districtName, setDistrictName] = useState("All");
    const [selectedFilteredYear, setSelectedFilteredYear] = useState(null);
    const [activeChip, setActiveChip] = useState("All");
    const nameval =  localStorage.getItem("name")
    useDidMountEffect(() => {
        yearListApi()
        districtGet()
        // handleSelectYear(yearName)
    }, []);
    useDidMountEffect(() => {

        if (selectedItem) {
            chartDatas();
        } else {
            setChartData([]);
        }

        districtHubGet()

    }, [selectedItem, districtName, yearName, yearNameSelected,selectedFilteredYear, activeChip]);


    useDidMountEffect(() => {
        yearApi(2021);
    }, []);
    useDidMountEffect(() => {
        if (!selectedItem && investorsListValue.length > 0) {
            setSelectedItem(investorsListValue[0].id);
        }
    }, [investorsListValue]);


    const districtGet = () => {
        apiService('admin/hub/get', "", "get").then(
            (res) => {
                setPageLoading(false)
                if (res) {
                    if (res && res.data.hubs) {
                        var hub = res.data.hubs

                        hub.unshift('All')
                        setInvestorListValue(hub)
                    }
                }
            }
        );
    }

    const [cityList, setCityList] = useState([])
    const hubList = localStorage.getItem('name')
    const districtHubGet = () => {
        setCityList('')
        apiService(`admin/hub/district/get?hub=${hubList}`, "", "get").then(
            (res) => {
                setPageLoading(false)
                if (res) {
                    if (res && res.data && res.data.districts) {
                        var dis = res.data.districts
                        dis.unshift('All')
                        setCityList(dis)
                    }
                }
            }
        );
    }



    // const options = {
    //     animationEnabled: true,
    //     // title: {
    //     //   text: "StartUps",
    //     // },
    //     subtitles: [
    //         {
    //             text: "Startup",
    //             verticalAlign: "center",
    //             fontSize: 34,
    //             dockInsidePlotArea: true,
    //         },
    //     ],
    //     data: [
    //         {
    //             type: "doughnut",
    //             showInLegend: false,
    //             indexLabel: "{name}: {y}",
    //             yValueFormatString: "#,###''",
    //             dataPoints: chartData,
    //             // click: (e) => {
    //             //     partnerId();
    //             // }
    //         },

    //     ],

    // };

    const handleChartClick = (item) => {
        setSelectedItem(item);
        setDistrictName('All')
    };

    // const partnerId = () => {
    //     navigator(`tanseed/partner/${btoa(selectedItem)}`)
    // }

    let month = [
        'All', 'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]
    const [monthList, setMonthList] = useState(month)

    const [selectedMonth, setSelectedMonth] = useState(monthList[0])
    const handleSelectMonth = (e, value) => {
        const index = monthList.indexOf(value);
        setSelectedMonth(value)
        setMonthName(index)

    }


    // const handleSelectYear = (value) => {
    //     let year = new Date().getFullYear()
    //     const currentMonthIndex = new Date().getMonth()
    //     setYearName(value)
    //     setSelectedYear(value)
    //     let yearValue
    //     if (typeof value === 'string') {
    //         setMonthName(0)
    //         setSelectedMonth(monthList[0])
    //         yearValue = Number(value)
    //     } else {
    //         yearValue = value
    //     }
    //     if (year === yearValue) {
    //         let valPush = []
    //         for (let i = 0; i <= currentMonthIndex + 1; i++) {
    //             var element = monthList[i]
    //             valPush.push(element)
    //         }
    //         setMonthList(valPush)
    //     } else {
    //         setMonthList(month)
    //     }
    // }

    const handleSelectCity = (value) => {
        setDistrictName(value);
    };

    // const allYears = ['All','2024 - 2025', '2023 - 2024', '2022 - 2023'];

    // const [filteredYears, setFilteredYears] = useState(allYears);


    const [filteredYears, setFilteredYears] = useState([]);

    // const [filterYear, setFilterYear] = useState()
    const yearApi = (startyear) => {
        apiService(`common/dashboard/listyears?startyear=2021`, "", "get").then(
            (res) => {
                setPageLoading(false);
                if (res && res.data && res.data.yearMap) {
                    var yearMap = res.data.yearMap;
                    var allYears = Object.keys(yearMap).map((year) => ({
                        label: year,
                        value: yearMap[year]
                    }));

                    allYears.unshift({ label: "All", value: 0 });

                    setFilteredYears(allYears);
                }
            }
        );
    };
    const handleSelectYear = (event, selectedOption) => {
        setYearName(selectedOption);
        setYearNameSelected(selectedOption)
        if (selectedOption) {
            var name=selectedOption.split(' - ')
            setYearName(name[0]);
        } else {
            setYearName('2024');

        }
    };
    const [tabIndex, setTabIndex] = useState(1);
    const handleTabDetails = (value, index) => {


        setTabIndex(index + 1)

    };
    const handleChange = (event, newValue) => {
        setActiveChip('All')
        const newTabDetails = investorsListValue[newValue];
        setSelectedItem(newTabDetails)
        if (newTabDetails) {
            //   setTabName(newTabDetails.name);
            //   setButtonText("Explore");
            //   setPageTitle(newTabDetails.name);
            //   setTabDetails(newTabDetails);
            //   setTabSerive(newTabDetails.serviceList);
        }

    };
    const handleChipClick = (chip, id, fundID) => {
        setActiveChip(chip);
    };


    const [screening, setScreening] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [evaluation, setEvaluation] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [bDD, setBDD] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [bDDDeepDive, setBDDDeepDive] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });


    const [ic, setIc] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [psc, setPsc] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });

    const [ldd, setLdd] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [fdd, setFdd] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [fs, setFs] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [fd, setFd] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [barVal, setBarVal] = useState({
        series: [],
        categories: []
    });
    const chartDatas = () => {
        setChartData([]);

        setPageLoading(true);
        let startYear = '0';   

        if (yearName && yearName !== 'All') { 
            startYear = yearName.split('-')[0].trim();
        }
        var req = {
            hub: hubList,
            district: activeChip,
            startYear: startYear
            // startYear: yearName === 'All' ? 0 : yearName,
            // endYear: selectedFilteredYear !== null ? selectedFilteredYear : '',
        };
        apiService('admin/scst/dashboard', req, "post")
            .then((result) => {
                setPageLoading(false);
                if (result) {
                    setChartData(result.data);
                }
                if (result.data.barChart) {
                    setBarVal(result.data.barChart);
                } else {
                    setBarVal({
                        series: [],
                        categories: []
                    });
                }
                // if (result.data.validation && result.data.validationLabels) {
                //     setScreening({
                //         ...screening,
                //         series: result.data.validation,
                //         labels: result.data.validationLabels,
                //     });
                // }
                if (result.data.validation && result.data.validationLabels) {
                    const isPscEmpty = result.data.validation.every((val) => val === 0);
                    setScreening({
                        ...screening,
                        series: result.data.validation,
                        labels: result.data.validationLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.evaluation && result.data.evaluationLabels) {
                    const isPscEmpty = result.data.evaluation.every((val) => val === 0);
                    setEvaluation({
                        ...evaluation,
                        series: result.data.evaluation,
                        labels: result.data.evaluationLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.bddFirstConnect && result.data.bddFirstConnectLabels) {
                    const isPscEmpty = result.data.bddFirstConnect.every((val) => val === 0);
                    setBDD({
                        ...bDD,
                        series: result.data.bddFirstConnect,
                        labels: result.data.bddFirstConnectLabels,
                        isEmpty: isPscEmpty,
                    });
                }

                if (result.data.bddDeepDive && result.data.bddDeepDiveLabels) {
                    const isPscEmpty = result.data.bddDeepDive.every((val) => val === 0);
                    setBDDDeepDive({
                        ...bDDDeepDive,
                        series: result.data.bddDeepDive,
                        labels: result.data.bddDeepDiveLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.ic && result.data.icLabels) {
                    const isPscEmpty = result.data.ic.every((val) => val === 0);
                    setIc({
                        ...ic,
                        series: result.data.ic,
                        labels: result.data.icLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.psc && result.data.pscLabels) {
                    const isPscEmpty = result.data.psc.every((val) => val === 0);
                    setPsc({
                        ...psc,
                        series: result.data.psc,
                        labels: result.data.pscLabels,
                        isEmpty: isPscEmpty,
                    });
                }

                if (result.data.ldd && result.data.lddLabels) {
                    const isPscEmpty = result.data.ldd.every((val) => val === 0);
                    setLdd({
                        ...ldd,
                        series: result.data.ldd,
                        labels: result.data.lddLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.fdd && result.data.fddLabels) {
                    const isPscEmpty = result.data.fdd.every((val) => val === 0);
                    setFdd({
                        ...fdd,
                        series: result.data.fdd,
                        labels: result.data.fddLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.fs && result.data.fsLabels) {
                    const isPscEmpty = result.data.fs.every((val) => val === 0);
                    setFs({
                        ...fs,
                        series: result.data.fs,
                        labels: result.data.fsLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.fd && result.data.fdLabels) {
                    const isPscEmpty = result.data.fd.every((val) => val === 0);
                    setFd({
                        ...fd,
                        series: result.data.fd,
                        labels: result.data.fdLabels,
                        isEmpty: isPscEmpty,
                    });
                }
            })
    };
    const options = {
        chart: {
            type: 'bar',
            height: 350,
            width: '100%',
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 7,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                },
                columnWidth: '40%'
            }
        },
        xaxis: {
            categories: barVal.categories
        },
        legend: {
            position: 'right',
            offsetY: 40
        },
        fill: {
            opacity: 1
        }
    };
return (
    <section className="">
        <div className="container-fluid">
            <div className="form_div chart">

                <div className="collapse show mt-3 mb-3" id="collapseWidthExample">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <FormControl fullWidth>
                                    {/* <Autocomplete
                                        disableClearable
                                        disablePortal
                                        value={yearNameSelected}
                                        options={allYears}
                                        onChange={handleSelectYear}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Financial Year" fullWidth />
                                        )}
                                    /> */}
                                    <Autocomplete
                                            disableClearable
                                            disablePortal
                                            value={yearNameSelected || ""}
                                            options={filteredYears.map((year) => year.label)}  
                                            onChange={handleSelectYear}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Financial Year" fullWidth />
                                            )}
                                        />
                                </FormControl>
                            </div>
                        </div>


                        {/* {

                            (
                                yearName !== 'All' && (
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    value={selectedFilteredYear}
                                                    options={filteredYears}
                                                    onChange={(event, newValue) => setSelectedFilteredYear(newValue)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="End Year" fullWidth />
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                )
                            )
                        } */}
                        <div className="col-lg-3">                            
                            <div className='d-flex align-items-center'>
                                <img src='/images/handshake.png' alt='' className='star_logo' />
                                <h6 className='font-weight-bold ml-lg-3 ml-md-3'>Total Startups</h6>
                                <div className='star_details ml-auto text-center'>
                                    <h5 className='font-weight-bold'>{chartData.totalStartups ? chartData.totalStartups : '0'}</h5>
                                </div>                               
                            </div>

                        </div>
                    </div>


                    {/* <TabContext className="tab-div">
                    <div className="name-tab">
                        <Box className="tabs-boxs">
                            <Tabs
                                // value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                {investorsListValue.map((menu, index) => (
                                    <Tab
                                        label={`${menu}`}
                                        onClick={(e) => handleTabDetails(menu, index)}
                                        className={tabIndex === index + 1 ? "Mui-selected hubselct" : ""}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                    </div>
                </TabContext> */}

                    {/* <div className="mt-4">
                    {(
                        selectedItem !== 'All' && ( */}
                    <div className="chips text-center">
                        {cityList && cityList.map((item, index) => (
                            <Button
                                key={index}
                                variant={activeChip === item ? "contained" : "outlined"}
                                className={activeChip === item ? "tan-chip1" : "tan-chip2"}
                                onClick={() => handleChipClick(item)}
                                sx={{ margin: '5px' }}
                            >
                                {item}
                            </Button>
                        ))}
                    </div>
                    {/* )
                    )}

                </div> */}
                    <div className="row">

                        <div className="col-lg-12">
                            <div>
                                <div id="chart">
                                    <ReactApexChart options={options} series={barVal.series} type="bar" height={350} />
                                </div>
                                <div id="html-dist"></div>
                            </div>
                        </div>
                        {screening && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>Screening</h5>
                                    <Divider />
                                    <div className="dashBox dashBox4">
                                        {screening.isEmpty ? (
                                            <p className="not-avail">No data available for Screening</p>
                                        ) : (
                                            <Chart
                                                options={screening}
                                                series={screening.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}


                        {evaluation && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>Evaluation</h5>
                                    <Divider />
                                    <div className="dashBox dashBox4">
                                        {evaluation.isEmpty ? (
                                            <p className="not-avail">No data available for Evaluation</p>
                                        ) : (
                                            <Chart
                                                options={evaluation}
                                                series={evaluation.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {bDD && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>BDD FirstConnect</h5>
                                    <Divider />
                                    <div className=" dashBox4">
                                        {bDD.isEmpty ? (
                                            <p className="not-avail">No data available for BDD</p>
                                        ) : (
                                            <Chart
                                                options={bDD}
                                                series={bDD.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {bDDDeepDive && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>BDD Deep Dive</h5>
                                    <Divider />
                                    {/* <div className=" dashBox4">
                                    <Chart
                                        options={bDDDeepDive}
                                        series={bDDDeepDive.series}
                                        type="pie"
                                    />
                                </div> */}
                                    <div className=" dashBox4">
                                        {bDDDeepDive.isEmpty ? (
                                            <p className="not-avail">No data available for BDD Deep Dive</p>
                                        ) : (
                                            <Chart
                                                options={bDDDeepDive}
                                                series={bDDDeepDive.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>

                                </div>
                            </div>
                        )}
                        {ic && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>IC</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {ic.isEmpty ? (
                                            <p className="not-avail">No data available for IC</p>
                                        ) : (
                                            <Chart
                                                options={ic}
                                                series={ic.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {psc && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>PSC</h5>
                                    <Divider />
                                    <div className="dashBox dashBox4">
                                        {psc.isEmpty ? (
                                            <p className="not-avail">No data available for PSC</p>
                                        ) : (
                                            <Chart
                                                options={psc}
                                                series={psc.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {ldd && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>LDD</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {ldd.isEmpty ? (
                                            <p className="not-avail">No data available for LDD</p>
                                        ) : (
                                            <Chart
                                                options={ldd}
                                                series={ldd.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {fdd && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>FDD</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {fdd.isEmpty ? (
                                            <p className="not-avail">No data available for FDD</p>
                                        ) : (
                                            <Chart
                                                options={fdd}
                                                series={fdd.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {fs && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>FS</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {fs.isEmpty ? (
                                            <p className="not-avail">No data available for FS</p>
                                        ) : (
                                            <Chart
                                                options={fs}
                                                series={fs.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {fd && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>FD</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {fd.isEmpty ? (
                                            <p className="not-avail">No data available for FD</p>
                                        ) : (
                                            <Chart
                                                options={fd}
                                                series={fd.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
            </div>
        </section>
    )
}

export default Scsthubdashboard

import React, { useContext, useState } from "react";
import { Checkbox, Dialog, TablePagination, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import AssigntoInvestor from "./AssigntoInvestor";
import notifyService from "../../../api/notifySerivce";
import IconButton from "@mui/material/IconButton";
import ViewInvestor from "./ViewInvestor";
import ICStartupRemarks from "./ICStartupRemarks";
import ICStartupFilter from "./ICStartupFilter";
import ViewPitchDeck from "./ViewPitchDeck";
import SendEmailTemplate from "../../admin-module/tanseed-page/SendEmailTemplate";

const ICStartupList = () => {
  const [preferredSectors, setPreferredSectors] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const { tableIndexValue, setPageLoading } = useContext(DataContext);
  const [investorCount, setInvestorCount] = useState(0)
  useDidMountEffect(() => {
    investerListApi()
  }, [])
  const investerListApi = () => {
    var req = {};
    apiService(`user/investor/list`, req, 'post').then((res) => {
      if (res) {
        setInvestorCount(res.data.count)
      }

    })
  }
  const [checkedList, setCheckedList] = useState([]);
  const [startupListData, setstartupListData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [associatedList, setAssociatedList] = useState(false);
  useDidMountEffect(() => {
    (async () => await listApi())();
    // eslint-disable-next-line
  }, [page, rowsPerPage, associatedList, preferredSectors, searchInputValue]);
  const [getData, setGetData] = useState({})
  const listApi = () => {
    setPageLoading(true)
    setstartupListData([])
    var req = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
      sectors: preferredSectors,
      // associatedList: false
    };

    apiService("admin/ic/startups/list", req, "post").then((res) => {
      setPageLoading(false)
      if (res) {
        setCount(res.data.count)
        setGetData(res.data)
        if (res.data.responseModelList) {
          setstartupListData(res.data.responseModelList);
        }
      }
    }).catch((err) => {

    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const [itemsChecked, setItemsChecked] = useState(false);
  const [indexValue, setIndexValue] = useState([])
  const handleCheckboxClick = (e, index) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedList(prev => [...prev, value * 1]);
      setIndexValue(prev => [...prev, index]);
    } else {
      setCheckedList(checkedList.filter(item => item !== value * 1));
      setIndexValue(indexValue.filter(item => item !== index));
    }
  };

  const selectItem = (e) => {
    const { checked } = e.target;
    const collection = checked ? startupListData.map(category => category.id) : [];
    setCheckedList(collection);
    setItemsChecked(checked);
    setIndexValue(checked ? startupListData.map((_, index) => index) : []);
  };

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    const updatedCheckedList = checked ? startupListData.map((startup) => startup.id) : [];
    setCheckedList(updatedCheckedList);
    setItemsChecked(checked);
  };

  const [openView, setOpenView] = useState(false);
  const handleViewOpen = () => {
    setOpenView(true);
  };
  const handleViewClose = () => {
    setOpenView(false);
    listApi();
    setItemsChecked(false);
    setCheckedList([]);
  };
  const resetCheckbox = () => {
    setItemsChecked(false);
    setCheckedList([])
}
  return (
    <section className="">
      <div className="container-fluid">
        <div className="total-set">
          <div className="row">
            <div className='col-lg-4 col-12'>
              <div className='total_star bg-white'>
                <div className='d-flex align-items-center'>
                  <img src='/images/star1.png' alt='' className='star_logo' />
                  <h6 className='font-weight-bold ml-3'>Total Startups</h6>
                  <div className='star_details ml-auto text-center'>
                    <h5 className='font-weight-bold c-orange'>{getData.totalStartups}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12' >
              <div className='total_star bg-white'>
                <div className='d-flex align-items-center'>
                  <img src='/images/star2.png' alt='' className='star_logo' />
                  <h6 className='font-weight-bold ml-3'>Total Investors</h6>
                  <div className='star_details ml-auto text-center'>
                    <h5 className='font-weight-bold c-orange'>{investorCount}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12' >
              <div className='total_star bg-white'>
                <div className='d-flex align-items-center'>
                  <img src='/images/handshake.png' alt='' className='star_logo' />
                  <h6 className='font-weight-bold ml-lg-3 ml-md-3'><span className="c-orange"><b>{getData.totalStartupsAssigned}</b></span> startups assigned with <span className="c-orange"><b>{getData.totalInvestorsAssigned}</b></span> investors</h6>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form_div chart">
          <div className="">
            <ICStartupFilter preferredSectors={preferredSectors} setPreferredSectors={setPreferredSectors} searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue} />
          </div>
          <div className="table_tag">
            <div className="table_header_tag d-flex">
              {checkedList.length > 0 && (
                <>
                  <Button
                    className="mr-3"
                    variant="outlined"
                    onClick={() => handleViewOpen()}
                  >
                    Assign to Investor
                  </Button>
                  <SendEmailTemplate startupId={checkedList} userListItemTanseed={resetCheckbox} />
                </>


              )}
            </div>
            {startupListData.length ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={itemsChecked}
                          onClick={selectItem.bind(this)}
                          onChange={handleSelectAllChange}
                        />
                      </TableCell>
                      <TableCell align="center">No</TableCell>
                      <TableCell>Startup Name</TableCell>
                      <TableCell>Sector</TableCell>
                      <TableCell align="center">Pitch Deck</TableCell>
                      <TableCell align="center">Remarks</TableCell>
                      <TableCell align="center">Assigned Investors</TableCell>
                      {/* <TableCell align="center">Action</TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>

                    {startupListData.map((list, index) => (
                      <TableRow key={index}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            item={list}
                            value={list.id}
                            checked={checkedList.includes(list.id)}
                            onChange={e => handleCheckboxClick(e, index)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {
                            tableIndexValue(rowsPerPage, page, index)
                          }
                        </TableCell>
                        <TableCell>
                          {list.name}
                        </TableCell>
                        <TableCell>{list.sector}</TableCell>
                        <TableCell align="center">
                          <ViewPitchDeck id={list.id} viewPitchValue={list.portfolio} />
                        </TableCell>
                        <TableCell align="center">
                          <ICStartupRemarks id={list.id} comments={list.comments} listApi={listApi} />
                        </TableCell>
                        <TableCell align="center">
                          <ViewInvestor id={list.id} investmentCharacterstics={list.investmentCharacterstics} listApi={listApi} />
                        </TableCell>
                        {/* <TableCell align="center">
                                            <Tooltip title="De-Assign">
                                                <IconButton>
                                                    <DeleteIcon
                                                        color="error"
                                                        onClick={() => handleDeAssociate(list, index)}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>


                  <caption>
                    <TablePagination
                      component="div"
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ float: 'left' }}
                    />
                  </caption>
                </Table>
              </TableContainer>
            ) : (
              <div className="nodata_tag">
                <img
                  src="/images/Tanfundlogo-black-1.png"
                  className="no_data_logo"
                  alt=""
                />
                <h4>
                  A Startups will be assigned as soon as the criteria
                  matches
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog open={openView} maxWidth="lg">
        <AssigntoInvestor
          handleViewClose={handleViewClose}
          checkedListName={checkedList}
        />
      </Dialog>
    </section>
  );
};

export default ICStartupList
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../../context/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DeletePop from "../../../common-page/DeletePop";

const Tanseed7Tractionandachievements = () => {
  const [formData, setFormData] = useState({
    tractions: [{}],
  });
  const {
    navigator,
    handleBacktanseed7,
    Paper,
    MenuProps,
    validateNumberonly,
    isWhitespace,
    setOpenPop,
  } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    fields: tractionsFields,
    append: tractionsAppend,
    remove: tractionsRemove,
  } = useFieldArray({
    control,
    name: "tractions",
  });
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    dropdownValueApi();
  }, []);
  const [startupTractionsList, setStartupTractionsList] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
      (res) => {
        if (res?.data?.dropDownValues) {
          var val = res.data.dropDownValues;
          setStartupTractionsList(val.tractionsTypes);
        }
      }
    );
  };
  const getApi = async () => {
    await apiService(
      `startup/tanseed/tractionandachievement/get`,
      "",
      "get"
    ).then((res) => {
      if (res?.data) {
        setFormData({});
        let response = res.data;
        if (response.id !== 0) {
          setFormData(response);
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
        } else {
          setFormData(response);
        }
      }
    });
  };
  const addTractionsDetailsBtn = () => {
    tractionsAppend({
      type: "",
      name: "",
    });
  };

  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      apiService(
        "startup/tanseed/tractionandachievement/save",
        getform,
        "post"
      ).then((res) => {
        setLoading(false);
        if (res?.data?.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          navigator("/startup/tanseed-7.0/funding-utilisation");
        }
      });
    }
  };
  // delete Tractions
  const [deleteIndex, setDaleteIndex] = useState();
  const handleDeleteTractions = (index) => {
    setOpenPop(true);
    setDaleteIndex(index);
  };
  const handleClosePop = () => {
    setOpenPop(false);
    setDaleteIndex();
  };
  const deleteApi = (index) => {
    deleteTractions(index);
  };
  const deleteTractions = (index) => {
    const id = getValues(`tractions.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/tanseed/tractions/remove?id=${id}`, "", "get").then(
        (res) => {
          if (res?.data?.responseStatus === "Success") {
            indexBasedremoveTractions(index);
          }
        }
      );
    } else {
      indexBasedremoveTractions(index);
    }
  };
  const indexBasedremoveTractions = (index) => {
    var tractions = getValues("tractions");
    if (tractions.length === 1 && index === 0) {
      var valu = getValues();
      valu.tractions = [
        {
          type: "",
          name: "",
        },
      ];
      setFormData(valu);
    } else {
      tractionsRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center mb-3">
            <span className="back_span" onClick={handleBacktanseed7}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Traction / Achievements</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="noOfCustomers"
                    control={control}
                    defaultValue=""
                    rules={{ required: "No. of customers is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="No. of customers *"
                        placeholder="Enter No. of customers"
                        fullWidth
                        type="text"
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                        onKeyDown={(e) => validateNumberonly(e)}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.noOfCustomers && errors.noOfCustomers.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-3 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="growthRate"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Growth Rate is required",
                      validate: {
                        noWhitespace: (value) =>
                          !isWhitespace(value) || "Whitespace not allowed",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Growth Rate *"
                        placeholder="Enter Growth Rate"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.growthRate && errors.growthRate.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-3 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="majorAchievements"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Major Achievements is required",
                      validate: {
                        noWhitespace: (value) =>
                          !isWhitespace(value) || "Whitespace not allowed",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Major Achievements *"
                        placeholder="Enter Major Achievements"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.majorAchievements &&
                      errors.majorAchievements.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-3 col-12">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="testimonial"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: false,
                        validate: {
                          noWhitespace: (value) =>
                            value === "" ||
                            !isWhitespace(value) ||
                            "Whitespace not allowed",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          id="outlined-multiline-static"
                          label="Testimonial"
                          placeholder="Enter Testimonial"
                          fullwidth
                          {...field}
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.testimonial && errors.testimonial.message}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="div_header">
                  <div className="d-flex">
                    <h6>Tractions Details</h6>
                    <Button
                      variant="outlined"
                      disabled={readyOnlyValue}
                      className="ml-auto"
                      onClick={() => addTractionsDetailsBtn("")}
                    >
                      Add Tractions Details
                    </Button>
                  </div>
                </div>
                <div>
                  {tractionsFields.length ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Type *</TableCell>
                            <TableCell>Value *</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tractionsFields.map((field, index, type) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <InputLabel htmlFor="grouped-native-select">
                                    Type *
                                  </InputLabel>
                                  <Controller
                                    name={`tractions.${index}.type`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Type is required" }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        readOnly={readyOnlyValue}
                                        defaultValue={""}
                                        input={<OutlinedInput label="Name" />}
                                        MenuProps={MenuProps}
                                      >
                                        {startupTractionsList.map((name) => (
                                          <MenuItem key={name} value={name}>
                                            {name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.tractions?.[index]?.type &&
                                      errors.tractions?.[index]?.type.message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`tractions.${index}.name`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Value is required",
                                      validate: {
                                        noWhitespace: (value) =>
                                          !isWhitespace(value) ||
                                          "Whitespace not allowed",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Value"
                                        placeholder="Enter Value"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.tractions?.[index]?.name &&
                                      errors.tractions?.[index]?.name.message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center">
                                <DeleteIcon
                                  style={{ fill: "#d32f2f", cursor: "pointer" }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      handleDeleteTractions(index)
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="nodata_tag">
                      <p>Tractions details is empty</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed7}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
      <DeletePop
        deleteApi={deleteApi}
        deleteIndex={deleteIndex}
        handleClosePop={handleClosePop}
      />
    </>
  );
};

export default Tanseed7Tractionandachievements;

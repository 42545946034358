import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import $ from 'jquery';
const Footer = () => {
  $(document).ready(function () {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('#backtotop').fadeIn();
      } else {
        $('#backtotop').fadeOut();
      }
    });
    $('#backtotop').click(function () {
      $("html, body").animate({
        scrollTop: 0
      }, 600);
      return false;
    });
  });
  return (
    <>
      <footer className="footer">

        <div className='footer_details text-white'>
          <div className="container">
            <section className="">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <Link to={'https://startuptn.in/'} target='_blank'>
                    <img src="images/startup-white.png" className="img-fluid logo_img p-0" alt="" />
                  </Link>

                  {/* <img src="/images/TNLogoWhiteLogo1.png" alt="blue horizontal.png" className='logo_tanfund_img' /> */}
                </div>
                <div className="col-lg-7 col-md-6">
                  <h3 className="my-3 font-weight-bolder c-gray">Contact</h3>
                  <ul className="list-unstyled mb-0">
                    <li className='set_flex'>
                      <div>
                        <img src='/images/landing/address_footer.png' className='foo_icon_img' alt="" />
                      </div>
                      {/* <i className="fas fa-map-marker-alt"></i> */}
                      <div>
                        <p className='setHighlight'>Tamil Nadu Startup and Innovation Mission,</p>
                        <p>Entrepreneurship Development And Innovation Institute Campus, <br /> Parthasarathy Koil Street, SIDCO Industrial Estate,<br /> Ekkattuthangal, Guindy, Chennai - 600 032</p>
                      </div>
                    </li>
                    <li className='set_flex'>
                      <div>
                        <img src='/images/landing/phone_footer.png' className='foo_icon_img' alt="" />
                      </div>
                      <p>Helpline Number : 155343</p>
                    </li>
                    <li className='set_flex'>
                      <div>
                        <img src='/images/landing/mail_footer.png' className='foo_icon_img' alt="" />
                      </div>
                      <p>tanfund@startuptn.in</p>
                    </li>
                  </ul>

                </div>
                {/* <div className="col-lg-1 col"></div> */}
                <div className="col-lg-5 col-md-6">
                  {/* <h3 className="mt-3 font-weight-bolder c-gray">Useful Links</h3> */}
                  <ul className="list-Links my-2 mb-5">
                    <li>
                      <a href="#!" className="text-white">Events</a>
                    </li>
                    <li>
                      <a href="#!" className="text-white">FAQ</a>
                    </li>
                    <li>
                      <a href="#!" className="text-white">Resources</a>
                    </li>
                  </ul>
                  <div className="stay_update">
                    <h3>Stay Updated</h3>
                    <p>Subscribe to get updates of the Tamil Nadu Startup Ecosystem.</p>
                    <div className='d-flex'>
                      <input type="text" placeholder="Email address" className="form-input" />
                      <button className="check_btn">Subscribe</button>
                    </div>
                  </div>
                  <div className='my-3'>
                    <div className="social_icon">
                      <img src='/images/landing/facebook.svg' className='img-fluid' alt="" />
                      <img src='/images/landing/youtube-round.svg' className='img-fluid' alt="" />
                      <img src='/images/landing/instagram.svg' className='img-fluid' alt="" />
                      <img src='/images/landing/twitter-round.svg' className='img-fluid' alt="" />
                      <img src='/images/landing/linkedin-round.svg' className='img-fluid' alt="" />
                      <img src='/images/landing/whatsapp.svg' className='img-fluid' alt="" />
                    </div>
                  </div>
                </div>

              </div>
              <div className='row flex-der'>
                <div className="col-lg-7 col-md-7 mb-4">
                  {/* | Powered by */}
                  <div className='poewe'><span className='clr-span mb-2'>2024 © StartupTN. All rights reserved.</span>
                    {/* <img src='/images/amizhth-logo.png' className='img_footer' alt='' /> */}
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 mb-4">
                  <div className='terms_p'>
                    <Link>Terms of Service</Link>
                    <Link>Privacy Policy</Link>
                    <Link>Cookie Policy</Link>
                  </div>
                </div>
              </div>
            </section>
            {/* <p className='hr' /> */}
            {/* <div className="text-center">
              

            </div> */}
          </div>
        </div>
        <span id="backtotop" title="Back to top" style={{ display: 'none' }}>&uarr;</span>
      </footer>


    </>
  )
}

export default Footer
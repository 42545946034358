import React from 'react';
import { Link } from 'react-router-dom';
import './signupmodal.css'
import CustomLink from '../../../common-page/CustomLink';

const Signuproutemodal = ({handleClose}) => {
    return (
        <>
            <section className="logs-section">
                <div className="wrap d-md-flex">
                    <div className="text-wrap p-4 py-5 text-center d-flex align-items-center order-md-last mx-1">
                        <div className="text w-100">
                            <img src='/images/startup-white.png' className='white_logo' alt=''/>
                            <p className='set-fnt mb-5'>Are you a startup ?</p>
                            <Link onClick={handleClose} to="/startup/sign-up" className="btn btn-white btn-outline-white">Register Here</Link>
                        </div>
                    </div>
                    <div className="text-wrap p-4 py-5 text-center d-flex align-items-center order-md-last  mx-1">
                        <div className="text w-100">
                            <img src='/images/startup-white.png' className='white_logo' alt=''/>
                            <p  className='set-fnt mb-5'>Are you a Investor ?</p>
                            <Link onClick={handleClose} to="/investor/sign-up" className="btn btn-white btn-outline-white">Register Here</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Signuproutemodal
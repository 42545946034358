import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { FaCheckCircle } from 'react-icons/fa';
import CommonViewPitchDeck from '../../common-page/CommonViewPitchDeck';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import DataContext from '../../../context/DataContext';

const Scstmentorshipdetails = () => {
    const { navigator } = useContext(DataContext)
    const { startupId } = useParams()
    const { id } = useParams()
    let idNumber = atob(startupId)
    let partnerOrInvestorId = atob(id)
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = useTheme();
    useDidMountEffect(() => {
        listApi()
        viewStartupDetails(idNumber, partnerOrInvestorId)
    }, [])
    const [listData, setListData] = useState([])
    const listApi = () => {
        var req = {
            "partnerOrInvestorId": partnerOrInvestorId,
            "startupId": idNumber
        }
        apiService('partner/scst/admin/startup/get', req, 'post').then((result) => {
            if (result) {
                if (result.data.startupRounds) {
                    setListData(result.data.startupRounds)
                }
            }
        }).catch((err) => {

        });
    }
    const [startupGetData, setStartupGetData] = useState({})
    const [startupValue, setStartupValue] = useState({})
    const viewStartupDetails = async (id, partnerOrInvestorId) => {
        // setNavTabValue('1')
        setStartupGetData({})
        setStartupValue({})
        apiService(`partner/scstadmin/startups/get?id=${id}&partnerOrInvestorId=${partnerOrInvestorId}`, '', 'get').then((res) => {
            if (res) {
                if (res.data) {
                    if (res.data.applicationForm) {
                        setStartupGetData(res.data.applicationForm)

                    }
                    setStartupValue(res.data)
                    // debugger
                    // if (res.data.showRounds === true) {
                    //     roundPartnerList()
                    // }
                } else {
                    setStartupGetData({})
                }
            }
        })
    }

    const isRegistered = startupGetData.dpiitRegistered;

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const handleClickOpen = (value) => {
        setViewImage(value);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <section>
            <div className="container-fluid">
                <div className="form_div chart">

                    <div className='dflex'>
                        <div className='top-banner'>
                            <img src={startupGetData.startupLogoName} className='img-fluid user_logos' alt='' />
                            <div className='content'>
                                <h3 className='startupname-sur'>{startupValue.startupName}</h3>
                                <span className='black-clr-sur'><img src='/images/phone.png' alt='' className='mr-2' /> {startupValue.startupPhone ? (startupValue.startupPhone) : ('-')}</span>
                                <span className='black-clr-sur ml-3'><img src='/images/email.png' alt='' className='mr-2' /> {startupValue.startupEmail ? (startupValue.startupEmail) : ('-')}</span>
                                <span className='black-clr-sur ml-3'>Member Since: {startupValue.date}</span>
                            </div>
                        </div>

                        <div className='ml-auto'>
                            <p className='dflex'>
                                {/* <b>{startupValue.startupName}</b> */}
                                <div
                                    className={`dpiit-button ${isRegistered ? 'registered' : 'not-registered'}`}
                                >
                                    {isRegistered && <FaCheckCircle className="icon" />}
                                    {isRegistered ? 'DPIIT REGISTERED' : 'Non DPIIT REGISTERED'}
                                </div>
                            </p>
                            {/* {details.financialProjectionsName && ( */}
                            <div className="dflex">
                                <p className="ml-auto">
                                    <span
                                        className="viewdetails-sur"
                                        onClick={() => {
                                            handleClickOpen(startupGetData.pitchDeck);
                                        }}
                                    >
                                        View PitchDeck
                                    </span>
                                </p>
                            </div>
                            {/* )} */}
                        </div>
                    </div>


                </div>
            </div>
            <CommonViewPitchDeck viewImage={viewImage} open={open} handleClose={handleClose} />
        </section>
    )
}

export default Scstmentorshipdetails
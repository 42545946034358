import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const Tractionachievements = ({ details }) => {
  return (
    <main>
      <section>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <div className='row'>
              <div className='col-lg-6 col-12'>
                <div className='d-flex'>
                  <label className='mr-3'>No. of customers :</label>
                  <p className='ml-auto'><b>{details.noOfCustomers}</b></p>
                </div>
              </div>
              <div className='col-lg-6 col-12'>
                <div className='d-flex'>
                  <label className='mr-3'>Growth Rate :</label>
                  <p className='ml-auto'><b>{details.growthRate}</b></p>
                </div>
              </div>
              <div className='col-lg-6 col-12'>
                <div className='d-flex'>
                  <label className='mr-3'>Major Achievements :</label>
                  <p className='ml-auto'><b>{details.majorAchievements}</b></p>
                </div>
              </div>
              <div className='col-lg-6 col-12'>
                <div className='d-flex'>
                  <label className='mr-3'>Testimonial :</label>
                  <p className='ml-auto'><b>{details.testimonial}</b></p>
                </div>
              </div>
            </div>
          </div>
          {
            (details.tractions) && (
              <div className='col-lg-12 col-12 my-3'>
                <div className='div_header'>
                  <h6>Tractions Details</h6>
                </div>
                <TableContainer component={Paper}>
                  <Table aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Type </TableCell>
                        <TableCell>Value </TableCell>                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {details.tractions.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {list.type}
                          </TableCell>                         
                          <TableCell>
                            {list.name}
                          </TableCell>                          
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                </TableContainer>
              </div>
            )
          }
        </div>
      </section>
    </main>
  )
}

export default Tractionachievements
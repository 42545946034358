import React, { useContext } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import DataContext from '../../context/DataContext';

const Tanseednavbar = () => {
  const { tanseedPartnerRemoveFilter,tanseedJuryRemoveFilter } = useContext(DataContext)
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
    tanseedPartnerRemoveFilter()
    tanseedJuryRemoveFilter()
  };

  return (
    <nav className='box_shadow'>
      <div className='submenu_div'>
        <div className='container-fluid'>
          <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/tanseed/partner" onClick={handleClick}>Partner</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/tanseed/juries" onClick={handleClick}>Juries</NavLink>
            </Typography>
          </Box>
        </div>
      </div>
    </nav>
  )
}

export default Tanseednavbar
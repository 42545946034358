import { Box, DialogTitle } from '@mui/material';
import React, { useContext } from 'react'
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import DataContext from '../../../../context/DataContext';

const style = {
    p: 4,
};
const Tanseed6 = ({ handleApplyClose, optedForTanseed, page }) => {
    const { navigator } = useContext(DataContext)
    const handleApplySubmit = () => {
        if (optedForTanseed === false) {
            apiService('startup/applyTANSEED', '', 'get').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        notifyService('success', 'Success', res.data.responseMessage)
                        handleApplyClose();
                        navigator('/startup/self-declaration')
                    } else {
                        notifyService('danger', 'Error', res.data.responseMessage)
                    }

                }
            })
        } else {
            handleApplyClose();
            navigator('/startup/self-declaration')
        }
    }
    return (
        <Box sx={style} className='modal1'>
            <img src='/images/close.png' onClick={handleApplyClose} className='close-btn' alt=''/>
            {/* <Button variant="contained" className="close-btn submit px-3 ml-3" onClick={handleApplyClose}><img src='/images/close.png' onClick={handleApplyClose} /></Button> */}
            <img src='/images/tanseed-logo.png' alt='img-fluid' />
            <h3 className='head font-weight-bold'>TANSEED 6.0</h3>
            {/* <h5>The Next Biggest Opportunity to Kickstart Your Startup</h5> */}
            {/* <span className='date'>Last date to Apply : <span className='yellow-clr font-weight-bold'>FEBRUARY 7, 2024</span></span> */}
            {
                page === 'dashboard' && (
                    <DialogTitle>You have chosen to apply for TANSEED 6.0</DialogTitle>
                )
            }

            {/* <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <span className='white-clr'>Your application will not be editable once submitted</span>
                        </DialogContentText>
                    </DialogContent> */}
            <div className='text-center'>
                <button className="yellow" onClick={handleApplySubmit}>{page === 'dashboard' ? ('Submit') : ('Apply Now')} <img src='/images/sigup-btn.png' className='pt-1 pl-1 arrow-img' alt=''/></button>
                {/* <Button variant="contained" className="white-btn submit px-3 ml-3" onClick={handleApplyClose}>Cancel</Button> */}
            </div>
        </Box>
    )
}

export default Tanseed6
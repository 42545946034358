import React, { useState } from 'react'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import apiService from '../../../api/apiService'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { useContext } from 'react'
import DataContext from '../../../context/DataContext'

const Tanseedroute = () => {
    const { count, setCount, setPageLoading,
        tableIndexValue } = useContext(DataContext)
    const [tanseedListData, setTanseedListData] = useState([])
    const [totalPage, setTotalPage] = React.useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useDidMountEffect(() => {
        tanseedListItem()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, page, rowsPerPage, setCount])


    const tanseedListItem = () => {
        setPageLoading(true)
        var req = {
            "listSize": rowsPerPage,
            "pageNumber": page + 1
        }
        apiService('admin/tanseed/investor/startups/list', req, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data.startups) {
                    setTanseedListData(res.data.startups)
                    setTotalPage(res.data.totalPages)
                    setCount(res.data.count)
                }
            }
        })
    }
    return (
        <section>
            <div className='container-fluid'>
                <div className='form_div chart'>
                    <div className='header_tag'>
                        <h5>TANSEED List</h5>
                    </div>
                    <div className='table_tag'>
                        {/* <div className='table_header_tag d-flex'>
                            {
                                (checkedList.length > 0 && (
                                    <Button className='' variant="outlined" onClick={() => handleViewOpen()}>Associate</Button>
                                ))
                            }
                        </div> */}
                        {
                            (tanseedListData.length) ? (
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={itemsChecked} onClick={selectItem.bind(this)}
                                                    />
                                                </TableCell> */}
                                                <TableCell align='center'>No</TableCell>
                                                <TableCell>Startup Name</TableCell>
                                                <TableCell align='center'>Rank</TableCell>
                                                <TableCell align='center'>Score</TableCell>
                                                <TableCell align='center'>Recommended</TableCell>
                                                <TableCell align='center'>Remarks </TableCell>
                                                <TableCell align='center'>Inprogress / Completed / Total Round</TableCell>
                                                {/* <TableCell align='center'>Associated with</TableCell> */}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tanseedListData.map((list, index) => (
                                                <TableRow key={index}>
                                                    {/* <TableCell padding="checkbox">
                                                        <Checkbox
                                                            item={list}
                                                            value={list.startupId}
                                                            checked={checkedList.includes(list.startupId)}
                                                            onChange={e => handleCheckboxClick(e, index)}
                                                        />
                                                    </TableCell> */}
                                                    <TableCell align='center'>
                                                        {
                                                            tableIndexValue(rowsPerPage, page, index)
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            list.startupName
                                                        }
                                                    </TableCell>
                                                    <TableCell align='center'>{list.rank !== 0 ? (list.rank) : ('NA')}</TableCell>
                                                    <TableCell align='center'>{list.score !== 0 ? (list.score) : ('NA')}</TableCell>
                                                    <TableCell align='center'>
                                                        {
                                                            (list.selected === 1) && (<span className='yes_span'>Yes</span>)
                                                        }
                                                        {
                                                            (list.selected === 2) && (<span className='no_span'>No</span>)
                                                        }
                                                        {
                                                            (list.selected === 0) && (<span className='review_span'>Under Review</span>)
                                                        }
                                                    </TableCell>
                                                    <TableCell align='center'>

                                                        {
                                                            (list.selected === 1) && ((list.remarks) ? (list.remarks) : (<>-</>))
                                                        }
                                                        {
                                                            (list.selected === 2) && ((list.remarks) ? (list.remarks) : (<>-</>))
                                                        }
                                                        {
                                                            (list.selected === 0) && ((list.remarks) ? (<span className='c-pointer'>{list.remarks}</span>) : (<span className='c-pointer' >-</span>))
                                                        }
                                                    </TableCell>
                                                    <TableCell align='center' >
                                                        {(list.inprogressRounds) ? (list.inprogressRounds) : (<>0</>)} / {(list.completedRounds) ? (list.completedRounds) : (<>0</>)} / {(list.totalRounds) ? (list.totalRounds) : (<>0</>)}
                                                    </TableCell>
                                                    {/* <TableCell align='center'>
                                                                    {
                                                                        list.investorName ? (list.investorName) : (`-`)
                                                                    }
                                                                </TableCell> */}

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <caption>
                                            <TablePagination
                                                component="div"
                                                count={count}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                style={{float:'left'}}
                                            /></caption>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <div className='nodata_tag'>
                                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                    <h4>A Startups will be assigned as soon as the criteria matches</h4>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Tanseedroute


import React from 'react'
import { Outlet } from 'react-router-dom'
const Layout = () => {
    
    return (
        <>
            <main className='home_main_div'>
                <Outlet></Outlet>
            </main>
        </>

    )
}

export default Layout
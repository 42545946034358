import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import { Autocomplete, Button, FormControl, FormHelperText, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../context/DataContext';
import DeleteIcon from '@mui/icons-material/Delete';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
const Fundingutilization = () => {
    const [formData, setFormData] = useState({
        fundPurposeAmount: [{}]
    });
    const {navigator, handleBack } = useContext(DataContext)
    const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        fields: fundFields,
        append: fundAppend,
        remove: fundRemove
    } = useFieldArray({
        control,
        name: "fundPurposeAmount"
    });
    useDidMountEffect(() => {
        // eslint-disable-next-line
        (async () => await getApi())()
        dropdownValueApi()
    }, [])
    const [previousRoundsList, setPreviousRoundsList] = useState([])
    const [purposeList, setPurposeList] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setPreviousRoundsList(val.previousFundingStatus)
            setPurposeList(val.purposes)
        })
    }
    const getApi = async () => {
        await apiService(`startup/fundingutilisation/get`, '', 'get').then((res) => {
            if (res) {
                setFormData({})
                let response = res.data
                if (response.id !== 0) {
                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                    if (response.previousFundingRounds) {
                        setPreviousFundingRoundsValue(response.previousFundingRounds);
                    }
                    // if (response.purpose) {
                    //     setPurposeValue(response.purpose)
                    // }

                } else {
                    setFormData(response)
                }
            }
        })
    }
    const addFundDetailsBtn = () => {
        fundAppend({
            purpose: "",
            fundAmount: ""
        })
        // setMultiplePurpose((name) => name.concat([]))
    }
  

    // const handleSelectfundingRequest = (value) => {
    //     setValue('fundingRequest', value)
    //     clearErrors('fundingRequest');
    // };


    const [previousFundingRoundsValue, setPreviousFundingRoundsValue] = useState('');
    const handleSelectpreviousRounds = (value) => {
        setPreviousFundingRoundsValue(value);
        setValue('previousFundingRounds', value)
        clearErrors('previousFundingRounds');
    }

    // const [purposeValue, setPurposeValue] = useState([]);
    // const [multiplePurpose, setMultiplePurpose] = useState([]);
    const handleSelectPurpose = (value, index) => {
        // setPurposeValue(value)
        setValue(`fundPurposeAmount.${index}.purpose`, value)
        clearErrors(`fundPurposeAmount.${index}.purpose`);
        // setMultiplePurpose((name) => name.concat(value))
        debugger
    }
    // 
    const onSubmit = (data) => {
        if (readyOnlyValue === false) {
            setLoading(true);
            var getform = data

            apiService('startup/fundingutilisation/save', getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        navigator('/startup/documents-upload')
                    }
                }
            })
        }
    };
    // delete Tractions
    const deleteFundingHistory = async (index) => {
        const id = getValues(`fundPurposeAmount.${index}.id`);
        if (id !== undefined) {
            apiService(`startup/funddetails/remove?id=${id}`, '', 'get').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        fundRemove(index)
                        getApi()
                    }
                }
            })
        } else {
            fundRemove(index)
        }
    }
    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBack}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Funding Utilisation</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-12'>
                        <div className="form-group mt-2">
                            <Controller name="fundingRequest" control={control} defaultValue="" rules={{ required: 'Funding Request is required' }}
                                render={({ field }) =>
                                    <Autocomplete
                                        disableClearable
                                        disablePortal
                                        {...field}
                                        limitTags={2}
                                        options={fundingRequestData}
                                        onChange={(e, selectedOptions) => handleSelectfundingRequest(selectedOptions)}
                                        renderInput={(params) => <TextField {...params} label="Funding Request *" />}
                                        disabled={readyOnlyValue}
                                    />
                                } />
                            <FormHelperText className='text-danger'>{errors.fundingRequest && errors.fundingRequest.message}</FormHelperText>
                        </div>
                    </div> */}
                            <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="previousFundingRounds" control={control} defaultValue="" rules={{ required: 'Previous Funding Rounds is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                // multiple
                                                {...field}
                                                // limitTags={2}
                                                value={previousFundingRoundsValue}
                                                options={previousRoundsList}
                                                onChange={(e, selectedOptions) => handleSelectpreviousRounds(selectedOptions)}
                                                renderInput={(params) =>
                                                    <TextField {...params} label="Previous Funding Rounds *" />
                                                }
                                                disabled={readyOnlyValue}
                                            />} />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.previousFundingRounds && errors.previousFundingRounds.message}</FormHelperText>
                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-12'>
                        <div className="form-group mt-2">
                            <Controller name="fundAmount" control={control} defaultValue="" rules={{ required: 'Fund Amount is required' }}
                                render={({ field }) =>
                                    <TextField
                                        variant='outlined'
                                        label="Approx. Fund Amount *"
                                        placeholder='Enter Fund Amount'
                                        fullWidth
                                        {...field}
                                        inputProps={
                                            { readOnly: readyOnlyValue, }
                                        }

                                    />} />
                            <FormHelperText className='text-danger'>{errors.fundAmount && errors.fundAmount.message}</FormHelperText>

                        </div>
                    </div>
                    <div className='col-lg-6 col-12'>
                        <div className="form-group mt-2">
                            <Controller name="purpose" control={control} defaultValue="" rules={{ required: 'Purpose  is required' }}
                                render={({ field }) =>
                                    <Autocomplete

                                        disableClearable
                                        disablePortal
                                        multiple
                                        {...field}
                                        limitTags={2}
                                        value={purposeValue}
                                        // id="combo-box-demo"
                                        options={purposeList}
                                        onChange={(e, selectedOptions) => handleSelectPurpose(selectedOptions)}
                                        // sx={{ width: 300 }}
                                        renderInput={(params) =>
                                            <TextField {...params} label="Purpose  *" />
                                        }
                                        disabled={readyOnlyValue}
                                    />}
                            />
                            <FormHelperText className='text-danger'>{errors.purpose && errors.purpose.message}</FormHelperText>
                        </div>
                    </div> */}
                            <div className='col-12 mb-3'>
                                <div className='div_header'>
                                    <div className='d-flex'>
                                        <h6>Funding Utilisation Details</h6>
                                        <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addFundDetailsBtn('')}>Add Fund Utilisation</Button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        (fundFields.length) ? (
                                            <div>
                                                {
                                                    fundFields.map((field, index, type) => (
                                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                                            <div className=''>
                                                                {index + 1}
                                                            </div>
                                                            <div className='col'>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <Controller name={`fundPurposeAmount.${index}.purpose`} control={control} defaultValue="" rules={{ required: 'Purpose is required' }}
                                                                        render={({ field }) =>
                                                                            <Autocomplete
                                                                                disableClearable
                                                                                disablePortal
                                                                                // multiple
                                                                                {...field}
                                                                                // limitTags={2}
                                                                                // value={multiplePurpose[index]}
                                                                                options={purposeList}
                                                                                onChange={(e, selectedOptions) => handleSelectPurpose(selectedOptions, index)}
                                                                                renderInput={(params) =>
                                                                                    <TextField {...params} label="Purpose  *" />
                                                                                }
                                                                                disabled={readyOnlyValue}
                                                                            />
                                                                        } />
                                                                    <FormHelperText className='text-danger'>
                                                                        {errors.fundPurposeAmount?.[index]?.purpose && errors.fundPurposeAmount?.[index]?.purpose.message}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                            <div className='col'>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <Controller name={`fundPurposeAmount.${index}.fundAmount`} control={control} defaultValue="" rules={{ required: 'Fund Amount is required' }}
                                                                        render={({ field }) =>
                                                                            <TextField
                                                                                id="outlined-multiline-static"
                                                                                label="Fund Amount (₹)*"
                                                                                placeholder='Enter Fund Amount (₹)'
                                                                                fullwidth
                                                                                {...field}
                                                                                inputProps={
                                                                                    { readOnly: readyOnlyValue, }
                                                                                }
                                                                            />} />
                                                                    <FormHelperText className='text-danger'>
                                                                        {errors.fundPurposeAmount?.[index]?.fundAmount && errors.fundPurposeAmount?.[index]?.fundAmount.message}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                            <div className=''>
                                                                <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteFundingHistory(index) : <></> }} />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            // <TableContainer component={Paper}>
                                            //     <Table aria-label="caption table">                                            
                                            //         <TableBody>
                                            //             {fundFields.map((field, index, type) => (
                                            //                 <TableRow key={index}>
                                            //                     <TableCell>{index + 1}</TableCell>
                                            //                     <TableCell>
                                            //                         <FormControl sx={{ width: '100%' }} >
                                            //                             <Controller name={`fundPurposeAmount.${index}.purpose`} control={control} defaultValue="" rules={{ required: 'Purpose is required' }}
                                            //                                 render={({ field }) =>                                                                        
                                            //                                     <Autocomplete
                                            //                                         disableClearable
                                            //                                         disablePortal
                                            //                                         multiple
                                            //                                         {...field}
                                            //                                         limitTags={2}
                                            //                                         value={multiplePurpose[index]}
                                            //                                         options={purposeList}
                                            //                                         onChange={(e, selectedOptions) => handleSelectPurpose(selectedOptions,index)}                                                                           
                                            //                                         renderInput={(params) =>
                                            //                                             <TextField {...params} label="Purpose  *" />
                                            //                                         }
                                            //                                         disabled={readyOnlyValue}
                                            //                                     />
                                            //                                 } />
                                            //                             <FormHelperText className='text-danger'>
                                            //                                 {errors.fundPurposeAmount?.[index]?.purpose && errors.fundPurposeAmount?.[index]?.purpose.message}
                                            //                             </FormHelperText>
                                            //                         </FormControl>
                                            //                     </TableCell>
                                            //                     <TableCell>
                                            //                         <FormControl sx={{ width: '100%' }} >
                                            //                             <Controller name={`fundPurposeAmount.${index}.fundAmount`} control={control} defaultValue="" rules={{ required: 'Fund Amount is required' }}
                                            //                                 render={({ field }) =>
                                            //                                     <TextField
                                            //                                         id="outlined-multiline-static"
                                            //                                         label="Fund Amount *"
                                            //                                         placeholder='Enter Fund Amount'
                                            //                                         fullwidth
                                            //                                         {...field}
                                            //                                         inputProps={
                                            //                                             { readOnly: readyOnlyValue, }
                                            //                                         }
                                            //                                     />} />
                                            //                             <FormHelperText className='text-danger'>
                                            //                                 {errors.fundPurposeAmount?.[index]?.fundAmount && errors.fundPurposeAmount?.[index]?.fundAmount.message}
                                            //                             </FormHelperText>
                                            //                         </FormControl>
                                            //                     </TableCell>                                                        
                                            //                     <TableCell align='center'>
                                            //                         <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteFundingHistory(index) : <></> }} />
                                            //                     </TableCell>
                                            //                 </TableRow>
                                            //             ))}
                                            //         </TableBody>
                                            //     </Table>

                                            // </TableContainer>
                                        ) : (
                                            <div className='nodata_tag'>
                                                <p>Funding Purpose details is empty</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <Button variant="contained" onClick={handleBack} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Fundingutilization
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Button, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import SendIcon from '@mui/icons-material/Send';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import ClarificationAll from '../../../scstBdd-page/scstBdd-details/ClarificationAll';
import Scsthubavpremarks from '../../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';
import Viewimage from '../../../../api/Viewimage';
import DataContext from '../../../../context/DataContext';

const PriorityDocuments = ({ idNumber,startupCheckList, path, startupValue, viewStartupDetails,showStartupValue }) => {
    const { setPageLoading, getBDDStatus } = useContext(DataContext)
    const [formData, setFormData] = useState();
    const { handleSubmit, control, setValue, getValues, setError, clearErrors, formState: { errors }, } = useForm({ values: formData });
    const {
      fields: priorityDocumentsFields,
    } = useFieldArray({
      control,
      name: "priorityDocuments",
    });
    
    useDidMountEffect(() => {
      getApi()
    }, [])
    const getApi = () => {
      setPageLoading(true)
      var req = {
        "startupId": idNumber,
        "round": "fdd",
        "category": 'prioritydocuments',
      }
      apiService(`partner/startup/clarification/list`, req, 'post').then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          result.data.priorityDocuments.forEach(element => {
            if (element.status === 3) {
              element.status = 2
            }
            if (element.fileUrl) {
              var val = element.fileUrl
              var data = val.split('?')
              if (data) {
                var formet = data[0].split('.')
                element.formet = formet[3]
              }
            }
  
          });
  
          setFormData(result.data)
          // console.log(result.data);
  
        }
      }).catch((err) => {
  
      });
    }
    const handelVerifiedStatus = (value, i) => {
      // console.log(value);
      setValue(`priorityDocuments.${i}.status`, Number(value))
      var getData = getValues(`priorityDocuments.${i}`)
      if (value === '1') {
        var req = {
          "bcId": getData.id,
          "startupId": idNumber,
          "status": 1,
          "round": "fdd"
        }
        apiService(`partner/startups/clarification/fdd/status/save`, req, 'post').then((result) => {
          if (result && result.data && result.data.responseStatus === 'Success') {
            notifyService("success", "Success", "Saved successfully !!");
            // viewStartupDetails()
            showStartupValue()
            getApi()
          }
        }).catch((err) => {
  
        });
      }
      setFormData(getValues())
  
    }
    const handleRequestValue = (value, i) => {
      setValue(`priorityDocuments.${i}.clarification`, value)
      clearErrors(`priorityDocuments.${i}.clarification`)
    }
    const onSubmit = (data) => { }
    const handleRequestSubmit = (i) => {
      var getData = getValues(`priorityDocuments.${i}`)
      if (getData.clarification !== '') {
        var req = {
          "bcId": getData.id,
          "startupId": idNumber,
          "clarification": getData.clarification,
          "status": 2,
          "round": "fdd"
        }
        apiService(`partner/startup/clarification/fdd/save`, req, 'post').then((result) => {
          if (result && result.data && result.data.responseStatus === 'Success') {
            notifyService("success", "Success", "Saved successfully !!");
            // viewStartupDetails()
            showStartupValue()
            getApi()
          }
        }).catch((err) => {
  
        });
      } else {
        setError(`priorityDocuments.${i}.clarification`, { message: "Request Clarification is required" })
      }
  
    }
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value, formet) => {
      setViewImage(value)
      if (formet === "xlsx") {
        const link = document.createElement('a');
        link.href = value;
        link.download = 'excelfile.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setOpen(true);
      }
  
    };
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <Box sx={{ p: 3 }}>
        <div>
          <div className="table_main_div">
            <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
              {(priorityDocumentsFields && priorityDocumentsFields.length) ? (
                <>
                  {
                    priorityDocumentsFields.map((data, index) => (
                      <div className='mb-3'>
                        <Grid container spacing={2}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p className='mb-0'>{index + 1}. {data.attribute}</p>
                          </Grid>
                          <Grid item lg={8} md={8} sm={12} xs={12}>
                            {data.value && <div className='ans-div'>{data.value}</div>}
                            {data.fileUrl && <div className='my-2'>View Document : <span className='view-doc' onClick={() => handleClickOpen(data.fileUrl, data.formet)}>{data.formet === 'xlsx' ? 'Download' : 'View'}</span></div>}
                            {data.fileUrl && data.formet &&
                            <>
                              {data.fileUrl && data.formet === 'xlsx' ?
                                (<>
                                  <span>(File Format : Excel)</span>
                                </>) : (<>
                                  <span>(File Format : PDF)</span></>)}
                            </>
                          }
                            {
                              data.vendors && (
                                <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                  <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>No</TableCell>
                                          <TableCell>Name</TableCell>
                                          <TableCell>Email</TableCell>
                                          <TableCell>Phone</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {data.vendors.map((row, index) => (
                                          <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{row.name ? row.name : `-`}</TableCell>
                                            <TableCell>{row.email ? row.email : `-`}</TableCell>
                                            <TableCell>{row.phone ? row.phone : `-`}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              )
                            }
                            {(!data.value && !data.fileUrl && !data.vendors ) && (
                          <>
                          <b>Not Provided</b>
                          </>
                        )}
                          </Grid>
                          {
                            (path === 'scstfdd' && startupCheckList.status === 17 && startupCheckList.showfddVerified === false) ? (
                              <>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                  <div>
                                    <FormControl>
                                      <Controller name={`priorityDocuments.${index}.status`} control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Innovation"
                                            name="radio-buttons-group"
                                            {...field}
                                            onChange={(e) => handelVerifiedStatus(e.target.value, index)}
                                          >
                                            <span className='d-flex'>
                                              {/* <FormControlLabel disabled={(getValues(`priorityDocuments.${index}.status`) === 2 && data.clarification) ? true : false} value={1} control={<Radio />} label="Verified" /> */}
                                              {/* {(
                                              (data.fileUrl || data.value ) && (
                                            <FormControlLabel disabled={(getValues(`priorityDocuments.${index}.status`) === 2 && data.clarification) ? true : false} value={1} control={<Radio />} label="Verified" />
                                              ))} */}
                                               <FormControlLabel disabled={(getValues(`priorityDocuments.${index}.status`) === 2 && data.clarification) ? true : false} value={1} control={<Radio />} label="Verified" />
                                              <FormControlLabel disabled={(getValues(`priorityDocuments.${index}.status`) === 2 && data.clarification) ? true : false} value={2} control={<Radio />} label="More clarification" />
                                            </span>
                                          </RadioGroup>} />
                                      <FormHelperText className="text-danger">
                                        {errors.priorityDocuments?.[index]?.status && errors.priorityDocuments?.[index]?.status.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  {
                                    getValues(`priorityDocuments.${index}.status`) === 2 && (
                                      <div className="form-group">
                                        <Controller
                                          name={`priorityDocuments.${index}.clarification`}
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: "Request Clarification is required" }}
                                          render={({ field }) => (
                                            <TextField
                                              variant="outlined"
                                              label="Request Clarification"
                                              placeholder="Enter Message"
                                              fullWidth
                                              multiline
                                              rows={3}
                                              onInput={(e) => handleRequestValue(e.target.value, index)}
                                              inputProps={{ readOnly: (getValues(`priorityDocuments.${index}.status`) === 2 && data.clarification) ? true : false }}
                                              {...field}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <Button disabled={(getValues(`priorityDocuments.${index}.status`) === 2 && data.clarification) ? true : false} onClick={() => handleRequestSubmit(index)}><SendIcon /></Button>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.priorityDocuments?.[index]?.clarification && errors.priorityDocuments?.[index]?.clarification.message}
                                        </FormHelperText>
                                      </div>
                                    )
                                  }
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                  <p className='mb-1'>Status : <b>{getBDDStatus(data.status)}</b></p>
                                  {data.clarification && <p className='mb-1'>Clarification :  {data.clarificationAll ? <ClarificationAll remarks={data.clarificationAll} lblName="Clarifications" btnName="View" /> : <Scsthubavpremarks remarks={data.clarification} lblName="View" />} </p>}
                                  {data.response && <p className='mb-1'>Startup Response : <Scsthubavpremarks remarks={data.response} lblName="View" /></p>}
                                  {data.documentName && <p className='mb-1'>Startup Attached Document :  <Button onClick={() => handleClickOpen(data.documentName)} className='text-cap'>View</Button></p>}
                                </Grid>
                              </>
                            )
                          }
                        </Grid>
                        <Divider />
                      </div>
                    ))
                  }
                </>
              ) : (
                <>
                  <div className='nodata_tag'>
                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                    <h4>FDD Document details is empty</h4>
                  </div>
                </>
              )
              }
            </form>
          </div>
          <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </div >
      </Box>
    )
  }
export default PriorityDocuments
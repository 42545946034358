import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../context/DataContext';
import DeleteIcon from '@mui/icons-material/Delete';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const Incubationaccprogram = () => {
  const [formData, setFormData] = useState({});
  const {navigator, handleBack, Paper } = useContext(DataContext)
  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    fields: organizationalSupportFields,
    append: organizationalSupportAppend,
    remove: organizationalSupportRemove
  } = useFieldArray({
    control,
    name: "organizationalSupport"
  })
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getApi = async () => {
    await apiService(`startup/incubationaccprogram/get`, '', 'get').then((res) => {
      if (res) {
        setFormData({})
        let response = res.data
        if (response.id !== 0) {
          setFormData(response)
          if (response.editForm === false) {
            setReadyOnlyValue(true)
          }
          setReceivingSupportValue(response.receivingSupport)
          setCurrentlyIncubatedValue(response.currentlyIncubated)
          // if(response.receivingSupport===true){
          //   organizationalSupportAppend({
          //     organisationName: "",
          //     natureOfSupport: "",
          //   })
          // }
        } else {
          setFormData(response)
        }
      }
    })
  }
  const addFounderDetailsBtn = () => {
    organizationalSupportAppend({
      organisationName: "",
      natureOfSupport: "",
    })
  }
  const [currentlyIncubatedValue, setCurrentlyIncubatedValue] = useState();
  const [receivingSupportValue, setReceivingSupportValue] = useState();

  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data
      debugger
      apiService('startup/incubationaccprogram/save', getform, 'post').then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService('success', 'Success', 'Saved successfully !!')
            navigator('/startup/product-market')
          }
        }
      })
    }
  };
  // delete Founders
  const deleteOrganizational = async (index) => {
    const id = getValues(`organizationalSupport.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/supportorganisation/remove?id=${id}`, '', 'get').then((res) => {
        if (res) {
          if (res.data.responseStatus === 'Success') {
            organizationalSupportRemove(index)
          }
        }
      })
    } else {
      organizationalSupportRemove(index)
    }
  }
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className='d-flex align-items-center'>
            <span className='back_span' onClick={handleBack}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Incubation / Acceleration details</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='d-none'>
                <div className="form-group">
                  <Controller name="id" control={control} defaultValue="0"
                    render={({ field }) =>
                      <TextField
                        {...field}
                      />} />
                </div>
              </div>
              <div className='col-lg-12 col-12'>
                <div className="form-group">
                  <FormLabel>Have you been or are you currently incubated at any Startup Incubation Center or part of an accelerator program? *</FormLabel>
                  <Controller name="currentlyIncubated" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setCurrentlyIncubatedValue(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setCurrentlyIncubatedValue(e.target.value); setValue('incubationCenterName', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />
                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.currentlyIncubated && errors.currentlyIncubated.message}</FormHelperText>
                </div>
              </div>
              {
                (currentlyIncubatedValue === 'true' || currentlyIncubatedValue === true) && (
                  <div className='col-lg-6 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="incubationCenterName" control={control} defaultValue="" rules={{ required: 'Incubation Center  Name is required' }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="Incubation Center Name *"
                            placeholder='Enter Incubation Center  Name'
                            fullWidth
                            inputProps={
                              { readOnly: readyOnlyValue, }
                            }
                            {...field}
                          />} />
                      <FormHelperText className='text-danger'>{errors.incubationCenterName && errors.incubationCenterName.message}</FormHelperText>
                    </div>
                  </div>
                )
              }
              <div className='col-lg-12 col-12'>
                <div className="form-group">
                  <FormLabel>Are you receiving support from any other organization? *</FormLabel>
                  <Controller name="receivingSupport" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setReceivingSupportValue(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setReceivingSupportValue(e.target.value) }} defaultValue='null' value='false' control={<Radio />} label="No" />
                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.receivingSupport && errors.receivingSupport.message}</FormHelperText>
                </div>
              </div>
              {
                (receivingSupportValue === 'true' || receivingSupportValue === true) && (
                  <div className='col-12 mb-3'>
                    <div className='div_header'>
                      <div className='d-flex'>
                        <h6>Organizational Support</h6>
                        <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addFounderDetailsBtn('')}>Add Organizational Support</Button>
                      </div>
                    </div>
                    <div>
                      {
                        (organizationalSupportFields.length) ? (
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>No</TableCell>
                                  <TableCell>Organization Name * </TableCell>
                                  <TableCell>Nature Of Support *</TableCell>
                                  <TableCell align='center'>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {organizationalSupportFields.map((field, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                      <FormControl sx={{ width: '100%' }} >
                                        <Controller name={`organizationalSupport.${index}.organisationName`} control={control} defaultValue="" rules={{ required: 'Organisation Name is required' }}
                                          render={({ field }) =>
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Organization Name"
                                              placeholder='Enter Organization Name'
                                              fullwidth
                                              {...field}
                                              inputProps={
                                                { readOnly: readyOnlyValue, }
                                              }
                                            />} />
                                        <FormHelperText className='text-danger'>
                                          {errors.organizationalSupport?.[index]?.organisationName && errors.organizationalSupport?.[index]?.organisationName.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </TableCell>
                                    <TableCell>
                                      <FormControl sx={{ width: '100%' }} >
                                        <Controller name={`organizationalSupport.${index}.natureOfSupport`} control={control} defaultValue="" rules={{ required: 'Nature Of Support is required' }}
                                          render={({ field }) =>
                                            <TextField
                                              id="outlined-multiline-static"
                                              label="Nature Of Support"
                                              placeholder='Enter Nature Of Support'
                                              fullwidth
                                              {...field}
                                              disabled={readyOnlyValue}
                                            />} />
                                        <FormHelperText className='text-danger'>
                                          {errors.organizationalSupport?.[index]?.natureOfSupport && errors.organizationalSupport?.[index]?.natureOfSupport.message}
                                        </FormHelperText>
                                      </FormControl>
                                    </TableCell>
                                    <TableCell align='center'>
                                      <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteOrganizational(index) : <></> }} />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>

                          </TableContainer>
                        ) : (
                          <div className='nodata_tag'>
                            <p>Organizational Support is empty</p>
                          </div>
                        )
                      }

                    </div>
                  </div>
                )
              }

            </div>
            <div className="form-group text-center">
              <Button variant="contained" onClick={handleBack} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <LoadingButton
                    type="submit"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </LoadingButton>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
      </section>

    </>
  )
}

export default Incubationaccprogram
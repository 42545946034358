import { Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TextField, Typography, FormControl, FormHelperText, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import React, { useContext, useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import apiService from '../../../../api/apiService';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DataContext from '../../../../context/DataContext';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import notifyService from '../../../../api/notifySerivce';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { FileUploader } from 'react-drag-drop-files';

const FddSectionP = () => {
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [formData, setFormData] = useState({});
  const { handleBackapp, validateNumberonly, setPageLoading,navigator } = useContext(DataContext)
  const [loading, setLoading] = useState(false);
  const [isAccordionOpen1, setIsAccordionOpen1] = useState(true);
  const [readyOnlyBDDValue, setReadyOnlyBDDValue] = useState(false);
  const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/secp/get`, '', 'get').then((res) => {
      if (res) {
        setPageLoading(false)
        setFormData({})
        let response = res.data
        setFormData(res.data)
        // if (response.id !== 0) {
        //   setFormData(response)
        // }

      }
    })
  }
  const {
    fields: rentalAdvanceFields,
    append: rentalAdvanceAppend,
    remove: rentalAdvanceRemove
  } = useFieldArray({
    control,
    name: "rentalAdvances"
  });
// if(rentalAdvanceFields.length === 0){
//   rentalAdvanceAppend()
// }
  const addpromotersFieldsBtn = () => {
    rentalAdvanceAppend({
      id: 0,
      location: "",
      advance: "",
      monthlyRent: "",
      purpose: "",
      rentalAgreementDoc: "",
      rentalAgreementDocName: "",
      promoterFullTimeWorking: ""
    })
  }

  const deletePromotersInterested = async (index) => {
    const id = getValues(`promoters.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`startup/vendor/remove?id=${id}`, '', 'delete').then((res) => {
        if (res) {
          if (res.data.responseStatus === 'Success') {
            indexBaseddeletePromotersInterested(index)
          }
        }
      })
    } else {
      indexBaseddeletePromotersInterested(index)
    }
  }


  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const indexBaseddeletePromotersInterested = (index) => {
    var startupTeams = getValues('rentalAdvances')
    if (startupTeams.length === 1 && index === 0) {
      var val = getValues()
      val.promoters = [{
        id: 0,
        location: "",
        advance: "",
        monthlyRent: "",
        purpose: "",
        rentalAgreementDoc: "",
        rentalAgreementDocName: "",
        promoterFullTimeWorking: ""
      }]
      setFormData(val)
    } else {
      rentalAdvanceRemove(index)
      var val = getValues()
      setFormData(val)
    }
  }
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const fileTypes = ["pdf"];
  const handleFileChangePatent = (index) => (e) => {
    handleFileUpload(
      e,
      `rentalAdvances.${index}.rentalAgreementDoc`,
      `rentalAdvances.${index}.rentalAgreementDocName`,
      `rentalAdvances.${index}.rentalAgreementDocURL`
    );
  }
  const handleFileUpload = (event, filekey, fileName, fileUrl) => {
    if (event !== null) {
      let logoselectedFile;
      if (event.target === undefined) {
        logoselectedFile = event;
      } else {
        logoselectedFile = event.target.files[0];
      }
      if (logoselectedFile) {
        const fileSizeLimit = 5 * 1024 * 1024; // 5 MB limit
        if (logoselectedFile.size > fileSizeLimit) {
          // File size exceeds limit, show error message
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return; // Exit function
        }
        var reader = new FileReader();
        var imagetype = logoselectedFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if ((img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf")) {
          setValue(filekey, "");
          setValue(fileName, "");
          setValue(fileUrl, '')
          // setName("");
          // setUrl("");
          // if (event.target === undefined) {
          //   setdata(event);
          // } else {
          //   setdata(event.target.files[0]);
          // }
          var fileValue = logoselectedFile;
          reader.readAsDataURL(logoselectedFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            // setUrl(logourl1);
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setValue(fileUrl, logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileUrl)
            // if (filekey === "logo") {
            //   setLogoInvalid(false);
            // }
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalid",
            "Please check your file format."
          );
        }
      }
    } else {
      // Handle case when event is null (optional)
    }
  };
  const onSubmit = (data) => {
    setPageLoading(true);
    apiService('startup/fdd/secp/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-q");
        }
      }
    })
  }
  return (
    <div>
      <div className='form_div2'>
        {/* <div className='doc-title mt-3'>
          <h6>Rental Advance :</h6>
        </div> */}
        <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Rental Advance :</h6>
          </div>
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>

          <div className='row'>
            <div className="col-lg-12 col-12 mb-3 pl-3">
              <Accordion className='Addfundacc-bor' expanded={isAccordionOpen1} onChange={() => setIsAccordionOpen1(!isAccordionOpen1)}>
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className='Addfundacc'
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 0
                  }}>
                    <AddCircleIcon className='add-icon mr-3' />List out each location and the advance paid and the monthly rent
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className='col-12 mb-3'>
                      <div>
                        {
                          (rentalAdvanceFields.length >= 0) && (
                            <TableContainer component={Paper} className='table-back'>
                              <Table aria-label="caption table">
                                <TableBody >
                                  {rentalAdvanceFields.map((field, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`rentalAdvances.${index}.location`} control={control} defaultValue="" rules={{ required: 'Location is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Location *"
                                                placeholder='Enter name'
                                                fullwidth
                                                {...field}
                                                inputProps={
                                                  { readOnly: readyOnlyValue, }
                                                }
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.rentalAdvances?.[index]?.location && errors.rentalAdvances?.[index]?.location.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`rentalAdvances.${index}.advance`} control={control} defaultValue="" rules={{ required: 'Advance is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Advance *"
                                                placeholder='Enter name'
                                                fullwidth
                                                {...field}
                                                inputProps={
                                                  { readOnly: readyOnlyValue, }
                                                }
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.rentalAdvances?.[index]?.advance && errors.rentalAdvances?.[index]?.advance.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`rentalAdvances.${index}.monthlyRent`} control={control} defaultValue="" rules={{ required: 'Monthly Rent is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Monthly Rent *"
                                                placeholder='Enter name'
                                                fullwidth
                                                {...field}
                                                inputProps={
                                                  { readOnly: readyOnlyValue, }
                                                }
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.rentalAdvances?.[index]?.monthlyRent && errors.rentalAdvances?.[index]?.monthlyRent.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`rentalAdvances.${index}.purpose`} control={control} defaultValue="" rules={{ required: 'Purpose is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Purpose *"
                                                placeholder='Enter purpose'
                                                fullwidth
                                                {...field}
                                                inputProps={
                                                  { readOnly: readyOnlyValue, }
                                                }
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.rentalAdvances?.[index]?.purpose && errors.rentalAdvances?.[index]?.purpose.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none' }} className='mt-2'>
                                        <FormControl >

                                          {/* <span>Document <span className='text-danger'>*</span></span> */}

                                          <div className="d-flex align-items-center">
                                            <div className='form-group mb-1'>
                                              <Controller name={`rentalAdvances.${index}.rentalAgreementDoc`} control={control} defaultValue="" rules={{ required: 'Documents is required' }}
                                                render={({ field }) =>
                                                  <FileUploader
                                                    {...field}
                                                    handleChange={handleFileChangePatent(index)}
                                                    value={getValues(`rentalAdvances.${index}.rentalAgreementDoc`)}
                                                    name="file"
                                                    types={fileTypes}
                                                    children={
                                                      <div className={getValues(`rentalAdvances.${index}.rentalAgreementDoc`) ? 'fileupload-patent-active' : 'fileupload-pdf-dpiit-bg-table'}>
                                                        <center>
                                                          <p className={getValues(`rentalAdvances.${index}.rentalAgreementDoc`) ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                            <span>
                                                              <AttachFileIcon className="file-icon mr-2" />
                                                              {getValues(`rentalAdvances.${index}.rentalAgreementDoc`) ? 'Uploaded (Click to Change) ' : 'Document *'}
                                                            </span>
                                                          </p>
                                                        </center>
                                                      </div>
                                                    }
                                                    disabled={readyOnlyBDDValue}
                                                  />
                                                } />
                                              <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                              <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.pitchDeck && errors.pitchDeck.message}</FormHelperText>
                                            </div>
                                            <span className="pre-btn-dpiit" onClick={() => handleClickOpen(getValues(`rentalAdvances.${index}.rentalAgreementDocURL`))}><VisibilityIcon className='icon-size mr-1' />
                                              <Link
                                                style={{ color: "#80809e" }}
                                              > Preview
                                              </Link>
                                            </span>
                                          </div>
                                          <FormHelperText className="text-danger">
                                            {errors.rentalAdvances?.[index]?.rentalAgreementDoc && errors.rentalAdvances?.[index]?.rentalAgreementDoc.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                        <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deletePromotersInterested(index) : <></> }} />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <center>
                                <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addpromotersFieldsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                              </center>
                            </TableContainer>
                          )
                        }
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>

          </div>
          <div className="form-group text-right mt-3">
            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
            {
              (readyOnlyBDDValue === false) ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (<></>)
            }

          </div>
        </form>
      </div>

    </div >
  )
}

export default FddSectionP
import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, Container, FormControl, List, Paper } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
// import apiService from '../../../api/apiService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
// import DataContext from '../../../context/DataContext';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import notifyService from '../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
const Investoreoi = () => {
    const { handleStartupDetails, handlepreference, handleeoi, setPageLoading, yearListApi, loading, setLoading, yearListValue, navigator, VisuallyHiddenInput, } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
        { values: formData }
    );

    useDidMountEffect(() => {
        (async () => await getApi())()
        dropdownValueApi()
        fundprogramlistApi()
        yearListApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [preferredInvestorTypeList, setPreferredInvestorTypeList] = useState([])
    const [fundingStatusesList, setFundingStatusesList] = useState([])
    const [investmentTicketSize, setInvestmentTicketSize] = useState([])
    const [instrumentTypeData, setInstrumentTypeData] = useState([])
    const [revenuesList, setLevenuesList] = useState([])
    const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState([])
    const [businessModelsData, setBusinessModelsData] = useState([])
    const [purposeList, setPurposeList] = useState([])
    const [technologiesList, setTechnologiesList] = useState([])
    const [sectorData, setSectorData] = useState([])
    const [startupStagesList, setStartupStagesList] = useState([])
    const [geoPreList, setGeoPreList] = useState([])
    const [revenueStageList, setRevenueStageList] = useState([])
    const [investmentData, setInvestmentData] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=investor`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            // setPreferredInvestorTypeList(val.prefInvestorTypes)
            setInvestmentTicketSize(val.desiredFundingAmount)
            setFundingStatusesList(val.previousFundingStatus)
            // setLevenuesList(val.revenueStages)
            setBusinessModelsData(val.businessModels)
            // setPurposeList(val.purposes)
            // setTechnologiesList(val.technologyStacks)
            setSectorData(val.sectors)
            setStartupStagesList(val.startupStages)
            setGeoPreList(val.geoPreferences)
            setRevenueStageList(val.revenueStages)
            setInstrumentTypeData(val.prefFundingInstruments)
            setInvestmentData(val.investCharacterstics)

        })
    }
    const [StartupDetailStatus, setStartupDetailStatus] = useState(false)
    const [preferenceStatus, setpreferenceStatus] = useState(false)
    const [eoiStatus, setEoiStatus] = useState(false)
    const getApi = async () => {
        setPageLoading(true)
        const userId = Number(localStorage.getItem('userId'))
        await apiService(`userprofile/get?userId=${userId}`, '', 'get').then((res) => {
            setPageLoading(false)
            if (res) {
                let result = res.data
                if (result.investTicketSize !== undefined) {
                    setFormData(result)
                    // setOrganizationTypeValue(result.organizationType);
                    setInstrumentTypeValueValue(result.investmentInstrumentType);
                    if (result.investmentCharacterstics) {
                        if (result.investmentCharacterstics.length === 1) {
                          if (result.investmentCharacterstics[0] === "") {
                            result.investmentCharacterstics = undefined
                          }
                        }
                        if (result.investmentCharacterstics !== undefined) {
                          setInvestmentCharactersticsValue(result.investmentCharacterstics)
                        }
                      }
                      if (result.geoPreferences) {
                        if (result.geoPreferences.length === 1) {
                          if (result.geoPreferences[0] === "") {
                            result.geoPreferences = undefined
                          }
                        }
                        if (result.geoPreferences !== undefined) {
                          setGeoPreferencesValue(result.geoPreferences)
                        }
                      }
                    setInvestTicketSizeValue(result.investTicketSize)
                    // setPreferToValue(result.preferTo)
                    setStartupStagesValue(result.startupStages)
                    setRevenueStageValue(result.revenueStage)
                    if (result.organizationLogoName === undefined) {
                        setLogoDisplayUrl('/images/user.png')
                    } else {
                        setLogoDisplayUrl(result.organizationLogoName)
                        setLogoDisplayValue(result.organizationLogoName)
                    }
                    setFundingStatusesValue(result.fundingStatuses)
                    if (result.preferredSectors) {
                        setPreferredSectors(result.preferredSectors)
                    }

                    var con = result.openToCoInvest.toString()
                    setValue('openToCoInvest', con)
                    setOpenToCoInvestValue(con)
                    // setGeoPreferencesValue(result.geoPreferences)
                    setBusinessModelsValue(result.businessModels)
                } else {
                    setValue('openToCoInvest', result.openToCoInvest.toString())
                    setOpenToCoInvestValue(result.openToCoInvest.toString())
                    setFormData(result)
                }

                setAcceptPrivacyPolicyChecked(result.acceptPrivacyPolicy)
                setAcceptTermsAndConditionsChecked(result.acceptTermsAndConditions)
                setValue('acceptPrivacyPolicy', result.acceptPrivacyPolicy)
                clearErrors('acceptPrivacyPolicy')
                setValue('acceptTermsAndConditions', result.acceptTermsAndConditions)
                clearErrors('acceptTermsAndConditions')
                if (result.noOfPortfolioStartups === 0) {
                    setValue('noOfPortfolioStartups', '')
                }
                if (result.estdYear !== undefined) {
                    setStartupDetailStatus(true)
                } if (result.fundingStatuses !== undefined) {
                    setpreferenceStatus(true)
                } if (result.acceptPrivacyPolicy !== false) {
                    setEoiStatus(true)
                }
            }
        })
    }
    const [termsAndConditionsHTML, setTermsAndConditionsHTML] = useState('')
    function termsAndConditionsMarkup() {
        return { __html: termsAndConditionsHTML };
    }
    const [privacyPolicyHTML, setPrivacyPolicyHTML] = useState('')
    function privacyPolicyMarkup() {
        return { __html: privacyPolicyHTML };
    }
    const [instrumentTypeValue, setInstrumentTypeValueValue] = useState([]);
    const handleSelectInstrument = (value) => {
        setInstrumentTypeValueValue(value);
        setValue('investmentInstrumentType', value)
        clearErrors('investmentInstrumentType');
    }


    const [investmentCharactersticsValue, setInvestmentCharactersticsValue] = useState([]);
    const handleSelectInvestment = (value) => {
        setInvestmentCharactersticsValue(value);
        setValue('investmentCharacterstics', value)
        clearErrors('investmentCharacterstics');
    };

    const [investTicketSizeValue, setInvestTicketSizeValue] = useState([]);
    const handleSelectTicketSize = (value) => {
        setInvestTicketSizeValue(value);
        setValue('investTicketSize', value)
        clearErrors('investTicketSize');
    };

    // const preferToData = [
    //   "Co-Invest",
    //   "Lead the round",
    //   "Solo Invest"
    // ]
    // const [preferToValue, setPreferToValue] = useState('');
    // const handleSelectPrefer = (value) => {
    //   setPreferToValue(value);
    //   setValue('preferTo', value)
    //   clearErrors('preferTo');
    // };

    const [businessModelsValue, setBusinessModelsValue] = useState([]);
    const handleSelectBusiness = (value) => {
        setBusinessModelsValue(value);
        setValue('businessModels', value)
        clearErrors('businessModels');
    }


    const [startupStagesValue, setStartupStagesValue] = useState([]);
    const handleSelectStageStartup = (value) => {
        setStartupStagesValue(value)
        setValue('startupStages', value)
        clearErrors('startupStages');
    }

    const [revenueStageValue, setRevenueStageValue] = useState([]);
    const handleSelectRevenueStageValue = (value) => {
        setRevenueStageValue(value)
        setValue('revenueStage', value)
        clearErrors('revenueStage');
    }

    const [geoPreferencesValue, setGeoPreferencesValue] = useState([]);
    const handleSelectGeoPreferencesValue = (value) => {
        // if (value[value.length - 1] === "allSelect") {
        //   setGeoPreferencesValue(geoPreList);
        //   return;
        // } if (value[value.length - 1] === "allRemove") {
        //   setGeoPreferencesValue([]);
        //   return;
        // } else {
        //   setGeoPreferencesValue(value);
        // }
        setGeoPreferencesValue(value);
        setValue('geoPreferences', value)
        clearErrors('geoPreferences');
    }
    const [fundingStatusesValue, setFundingStatusesValue] = useState([]);
    const handleSelectFundingStatuses = (value) => {
        setFundingStatusesValue(value)
        setValue('fundingStatuses', value)
        clearErrors('fundingStatuses');
    }

    const [preferredSectors, setPreferredSectors] = useState([]);
    const handleSelectSector = (value) => {
        setPreferredSectors([])
        if (value.length === 0) {
            return;
        }
        if (value[value.length - 1] === 'Select All') {


            var dataa = sectorData.filter((item) => item !== 'Remove All' && item !== 'Select All')

            setPreferredSectors(dataa);
            setValue('preferredSectors', dataa)
            clearErrors('preferredSectors');

        } else if (value[value.length - 1] === 'Remove All') {
            setPreferredSectors([]);
            setValue('preferredSectors', [])

        } else {
            setPreferredSectors(value);
            setValue('preferredSectors', value)
            clearErrors('preferredSectors');

        }
    };

    const [fundingList, setFundingList] = useState([]);
    const fundprogramlistApi = async () => {
        await apiService(`partner/fundprograms`, '', 'get').then((res) => {
            if (res) {
                setFundingList(res.data.fundingPrograms)
            }
        })
    }

    // const [organizationTypeValue, setOrganizationTypeValue] = useState('');
    const handleSelectOrganizationType = (value) => {
        // setOrganizationTypeValue(value);
        setValue('organizationType', value)
        clearErrors('organizationType');
        const name = fundingList.filter(item => ((item.partner)).includes(value)
            || ((item.partner)).includes(value)
        )
        setValue('fundingProgramVersionConfigId', name[0].versionId)
    }

    const [logoDisplayValue, setLogoDisplayValue] = useState(null)
    const [logoDisplayUrl, setLogoDisplayUrl] = useState('/images/user.png')
    let logoselecetdFile
    const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
        setValue(filekey, "")
        setValue(fileName, "")
        setdata('')
        setUrl('')
        if (event.target === undefined) {
            logoselecetdFile = event
        } else {
            logoselecetdFile = event.target.files[0]
        }
        if (logoselecetdFile) {
            var reader = new FileReader();
            var imagetype = logoselecetdFile.type
            var imagedatatype = imagetype.split("/")
            var img_crt_type = imagedatatype[1]
            if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png") {
                if (event.target === undefined) {
                    setdata(event)
                } else {
                    setdata(event.target.files[0])
                }
                var fileValue = logoselecetdFile
                reader.readAsDataURL(logoselecetdFile);
                reader.onload = () => {
                    var logourl1 = reader.result;
                    var spl = logourl1.split(',')
                    var ImageValue = spl[1]
                    var img_name = fileValue.name
                    setUrl(logourl1)
                    setValue(filekey, ImageValue)
                    setValue(fileName, img_name)
                    clearErrors(filekey);
                    clearErrors(fileName);
                    setLogoInvalid(false)
                }
            }
        }
    }


    const handleSelectYearData = (value) => {
        setValue('estdYear', value)
        clearErrors('estdYear')
    }
    const [acceptPrivacyPolicyChecked, setAcceptPrivacyPolicyChecked] = useState(null);
    const handleChangeAcceptPrivacyPolicy = (event) => {
        setAcceptPrivacyPolicyChecked(event.target.checked);
        if (event.target.checked) {
            setValue('acceptPrivacyPolicy', event.target.checked)
            clearErrors('acceptPrivacyPolicy')
        } else {
            setValue('acceptPrivacyPolicy', null)
        }
    };
    const [acceptTermsAndConditionsChecked, setAcceptTermsAndConditionsChecked] = useState(null);
    const handleChangeAcceptTermsAndConditions = (event) => {
        setAcceptTermsAndConditionsChecked(event.target.checked);
        if (event.target.checked) {
            setValue('acceptTermsAndConditions', event.target.checked)
            clearErrors('acceptTermsAndConditions')
        } else {
            setValue('acceptTermsAndConditions', null)
        }
    };
    const [openToCoInvestValue, setOpenToCoInvestValue] = useState(false)
    const [noOfPortfolioStartupsValue, setNoOfPortfolioStartupsValue] = useState(false)
    const [logoInvalid, setLogoInvalid] = useState(false);
    const onSubmit = (data) => {
        if (logoDisplayValue !== null) {

            var getform = data
            if (data.openToCoInvest === "true") {
                getform.openToCoInvest = true
            } else {
                getform.openToCoInvest = false
            }
            if (data.investmentCharacterstics === '') {
                getform.investmentCharacterstics = []
              }
              if (data.geoPreferences === '') {
                getform.geoPreferences = []
              }
            getform.id = Number(data.id)
            getform.noOfPortfolioStartups = Number(data.noOfPortfolioStartups)
            
            if (getform.noOfPortfolioStartups !== 0) {
                setLoading(true);
                setPageLoading(true)
                setNoOfPortfolioStartupsValue(false)
                apiService('userprofile/save', getform, 'post').then((res) => {
                    setLoading(false);
                    setPageLoading(false)
                    if (res) {
                        if (res.data.responseStatus === "Success") {
                            notifyService('success', 'Success', 'Saved successfully !!')
                            handleDashboard()
                        }
                    }
                })
            } else {
                setNoOfPortfolioStartupsValue(true)
            }
            // if (typeof (data.geoPreferences) !== "object") {
            //   getform.geoPreferences = data.geoPreferences.split(',');
            // }


        } else {
            setLogoInvalid(true)
            setPageLoading(false)
            notifyService('danger', 'Error', 'Please upload Profile Logo')
        }
    };
    const handleCancel = () => {
        navigator('/investor/profile/preference')
    }
    const handleDetails = () => {
        navigator('/investor/profile/details')
    }
    const handleDashboard = () => {
        navigator('/investor/associated-startups/list')
    }
    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${date}/${month}/${year}`;
    }
    return (
        <section className='container-fluid'>
            <div className='form_div'>
                <div className='d-flex align-items-center'>
                    <span className='back_span' onClick={handleCancel}>
                        <i className="fas fa-arrow-alt-circle-left"></i>
                    </span>
                    <div className='ml-2'>
                        <h6 className='mb-0'>Expression of Interest</h6>
                        <p className='header-p mb-0'>Your investment preference</p>
                    </div>
                    <div className='ml-auto'>
                        <div className='activeradio'>
                            {
                                (StartupDetailStatus === true) ? (
                                    <>
                                        <CheckCircleIcon className='c-green c-pointer mr-2' onClick={handleStartupDetails} />
                                        <div className='underlinecssgreen'></div>
                                    </>
                                ) : (
                                    <>
                                        <Radio checked={true} />
                                        <div className='underlinecss'></div>
                                    </>
                                )
                            }
                            {
                                (preferenceStatus === true) ? (
                                    <>
                                        <CheckCircleIcon className='c-green c-pointer mx-2' onClick={handlepreference} />
                                        <div className='underlinecssgreen'></div>
                                    </>
                                ) : (
                                    <>
                                        <Radio checked={true} />
                                        <div className='underlinecss'></div>
                                    </>
                                )
                            }
                            {
                                (eoiStatus === true) ? (
                                    <>
                                        <CheckCircleIcon className='c-green c-pointer ml-2' onClick={handleeoi} />
                                    </>
                                ) : (
                                    <>
                                        <Radio checked={true} />
                                    </>
                                )
                            }
                        </div>

                    </div>
                </div>
                <hr />
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="id" control={control} defaultValue="0"
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="organizationLogoName" control={control} defaultValue=""
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="organizationLogo" control={control} defaultValue=""
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="fundingProgramVersionConfigId" control={control} defaultValue="0"
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='col-12 mb-3'>
                            <div className='eoi_div'>
                                <p className='mb-0'>{getValues(`name`)}</p>
                                <p>{getValues(`city`)},{getValues(`state`)},{getValues(`country`)}</p>
                                <p>{getDate()}</p>
                                <p className='mb-0'>To,</p>
                                <p className='mb-0'>Mission Director & CEO,</p>
                                <p className='mb-0'>Tamil Nadu Startup and Innovation Mission,</p>
                                <p>EDII  Campus, Chennai - 600032</p>
                                <p>Subject: Expression of Interest for TANFUND Collaboration - Reg</p>
                                <p>Dear Sir/Madam,</p>
                                <p>We, {getValues(`name`)}, hereby express our keen interest in collaborating with TANFUND.</p>
                                <p>Hereby we agree to:
                                    <ol className='mt-3'>
                                        <li>Exploring diverse investment avenues in Tamil Nadu via the TANFUND Platform.</li>
                                        <li>Evaluating the prospects of establishing an operational base in Tamil Nadu.</li>
                                        <li>Offering our consent to showcase our organization's logo and name in alignment with the collaborative efforts on the TANFUND portal.</li>
                                    </ol>
                                </p>
                                <p className='mb-0'><i>Declaration:</i>
                                    <ol>
                                        <li>I am a duly incorporated legal entity, established in accordance with the applicable laws and regulations of the Union of India, duly authorized to engage in the business of making investments in startup enterprises, and I am in full compliance with all relevant statutory provisions and regulatory requirements.</li>
                                        <li>I hereby understand the level of risk involved in investing in a startup, and hereby irrevocably and unconditionally undertake that Startup TN or TANFUND shall not undertake any liability, from and against any and all Losses, disputes (including without limitation legal and other professional fees, and expenses) incurred towards the investments undertaken by means of TANFUND Portal.</li>
                                        <li>I hereby understand that StartupTN or TANFUND, in its absolute discretion, reserves the right to reject my application or discontinue access to TANFUND platform at any juncture, for reasons including, but not limited to, the provision of inaccurate or erroneous information.</li>
                                    </ol>
                                </p>
                                <p>Sincerely,</p>
                                <p className='mb-0'>{getValues(`pocName`)}</p>
                                <p className='mb-0'>{getValues(`pocDesignation`)}</p>
                                <p className='mb-0'>{getValues(`name`)}</p>
                            </div>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <div className='d-flex'>
                                <div>
                                    <Controller name="acceptTermsAndConditions" control={control} defaultValue="" rules={{ required: 'Required' }}
                                        render={({ field }) =>
                                            <FormControlLabel {...field} control={<Checkbox checked={acceptTermsAndConditionsChecked} onChange={handleChangeAcceptTermsAndConditions} />}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.acceptTermsAndConditions && errors.acceptTermsAndConditions.message}</FormHelperText>
                                </div>
                                <div className='mt-2'>
                                    <p>
                                        I herewith agree with <Link className='c-blue' to={'https://startuptn-public-documents.s3.ap-south-1.amazonaws.com/TANFUND_Terms.pdf'} target="_blank">Terms and Conditions</Link>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <div className='d-flex'>
                                <div>
                                    <Controller name="acceptPrivacyPolicy" control={control} defaultValue="" rules={{ required: 'Required' }}
                                        render={({ field }) =>
                                            <FormControlLabel {...field} control={<Checkbox checked={acceptPrivacyPolicyChecked} onChange={handleChangeAcceptPrivacyPolicy} />}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.acceptPrivacyPolicy && errors.acceptPrivacyPolicy.message}</FormHelperText>
                                </div>
                                <div className='mt-2'>
                                    <p>
                                        I herewith agree with <Link className='c-blue' to={'https://startuptn-public-documents.s3.ap-south-1.amazonaws.com/TANFUND_PrivacyPolicy.pdf'} target="_blank">Privacy Policy</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3">Cancel</Button>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            className="btn btn-primary submit px-3 ml-3"
                            // onClick={handleDashboard}
                        >
                            <span>Save</span>
                        </LoadingButton>

                    </div>
                </form>
            </div>
        </section>
    )
}

export default Investoreoi
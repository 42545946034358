import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './partnernavbar.css'
import { NavLink } from 'react-router-dom';

const Partnernavbar = () => {
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
  };
  return (
    <nav>
      <div className='submenu_div'>
        <div className='container'>
          <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center' }}>
            {/* <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/partner/round" onClick={handleClick}>Rounds</NavLink>
            </Typography> */}
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/partner/startup" onClick={handleClick}>Associated Startups</NavLink>
            </Typography>
            {/* <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/partner/jury" onClick={handleClick}>Jury</NavLink>
            </Typography> */}
          </Box>
        </div>
      </div>
    </nav>
  )
}

export default Partnernavbar
import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../context/DataContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Viewimage from '../../../../api/Viewimage';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import CustomLink from '../../../common-page/CustomLink';
const Productandmarket = () => {
    const [formData, setFormData] = useState({
        products: [{}]
    });
    const { navigator, handleBack, VisuallyHiddenInput, Paper,icon, checkedIcon  } = useContext(DataContext)
    const { handleSubmit, control, getValues, setValue, clearErrors, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        fields: productsFields,
        append: productsAppend,
        remove: productsRemove,
    } = useFieldArray({
        control,
        name: "products"
    });
    const [multipleImages, setMultipleImages] = useState([{
        productImageName: ''
    }]);
    useDidMountEffect(() => {
        // eslint-disable-next-line
        (async () => await getApi())()
        dropdownValueApi()
    }, [])
    const [technologiesList, setTechnologiesList] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setTechnologiesList(val.technologyStacks)
        })
    }
    const getApi = async () => {
        const updateName = multipleImages.filter((index) => index !== index)
        setMultipleImages(updateName)

        await apiService(`startup/productandmarket/get`, '', 'get').then((res) => {
            if (res) {
                setFormData({})
                let response = res.data
                if (response.id !== 0) {
                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                    //           
                    if (response.products !== undefined) {

                        // response.products.forEach((element, index) => {
                        //     const newInputValues = [...multipleImages];
                        //     newInputValues[index] = { ...newInputValues[index], imageName: "" };
                        //     setMultipleImages(newInputValues);
                        //     console.log(multipleImages)
                        // });
                        setMultipleImages(response.products);
                        // response.products.forEach((element, index) => {
                        //     // var req = {
                        //     //     imageName: element.productImageName
                        //     // }
                        //     // imageFile.push(req)
                        //     // setMultipleImages(imageFile)
                        //     // debugger
                        //     // const newInputValues = [...multipleImages];
                        //     // newInputValues[index] = { ...newInputValues[index], imageName: element.productImageName };
                        //     // setMultipleImages(newInputValues);
                        //     // console.log(multipleImages)
                        //     let newArr = multipleImages.map((item, i) => {
                        //         if (index == i) {
                        //           return { ...item, imageName: element.productImageName };
                        //         } else {
                        //           return item;
                        //         }
                        //       });
                        //       setMultipleImages(products);
                        //       debugger
                        // });

                    }
                    if (response.technologyStack) {
                        if (response.technologyStack.length === 1) {
                            if (response.technologyStack[0] === "") {
                                response.technologyStack = undefined
                            }
                        }
                        if (response.technologyStack !== undefined) {
                            setTechnologyStackValue(response.technologyStack)
                        }
                    }
                    console.log(multipleImages)

                } else {
                    setFormData(response)
                }
            }
        })
    }
    const addAdvisoriesDetailsBtn = () => {
        productsAppend({
            id: 0,
            name: "",
            link: "",
            productImage: "",
            productImageName: "",
            productRoadMap: "",
        })
        // const last = multipleImages.slice(-1)
        // debugger
        // setMultipleImages((prevImages) => prevImages.concat(null))
    }
    let logoselecetdFile = ''
    // let imageFile = []

    const handleProductUpload = (event, index) => {
        // const id = Number(getValues(`products.${index}.id`))
        // debugger
        if (event.target === undefined) {
            logoselecetdFile = event
        } else {
            logoselecetdFile = event.target.files[0]
        }
        if (logoselecetdFile) {
            var reader = new FileReader();
            var imagetype = logoselecetdFile.type
            var imagedatatype = imagetype.split("/")
            var img_crt_type = imagedatatype[1]
            if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf") {
                var fileValue = logoselecetdFile
                reader.readAsDataURL(logoselecetdFile);
                reader.onload = () => {
                    var logourl1 = reader.result;
                    var spl = logourl1.split(',')
                    var ImageValue = spl[1]
                    var img_name = fileValue.name
                    var getdata = getValues()
                    getdata.products[index].productImage = ImageValue
                    getdata.products[index].productImageName = img_name
                    setFormData(getdata)
                    // var req = {
                    //     imageName: img_name
                    // }
                    // imageFile.push(req)
                    // setMultipleImages(imageFile)                    
                    const newInputValues = [...multipleImages];
                    newInputValues[index] = { ...newInputValues[index], productImageName: logourl1 };
                    setMultipleImages(newInputValues);
                    // const imageArray = Array.from(event.target.files).map((file) =>
                    //     URL.createObjectURL(file)
                    // );
                    // if (id !== 0) {
                    //     setMultipleImages([...multipleImages, (prevImages) => prevImages.concat(imageArray)])
                    // } else {
                    //     setMultipleImages([...multipleImages, (prevImages) => prevImages.concat(imageArray)])
                    // }


                }
            }
        }
    }
    // delete products
    const deleteProducts = async (i) => {
        const id = Number(getValues(`products.${i}.id`))
        if (id !== 0) {
            apiService(`startup/products/remove?id=${id}`, '', 'get').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        productsRemove(i)
                        getApi()
                    }
                }
            })
        } else {
            productsRemove(i)
        }
        const updateName = multipleImages.filter((item, index) => index !== i)
        setMultipleImages(updateName)
        debugger
    }

    const [technologyStackValue, setTechnologyStackValue] = useState([])
    const handleSelectTechnologyStack = (value) => {
        setTechnologyStackValue(value)
        setValue('technologyStack', value)
        clearErrors('technologyStack');
    }
    // const [intellectualPropsValue,setIntellectualPropsValue]=useState('')
    const onSubmit = (data) => {
        if (readyOnlyValue === false) {
            setLoading(true);
            var getform = data
            if (getform.technologyStack === '') {
                getform.technologyStack = []
            }
            debugger
            apiService('startup/productandmarket/save', getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        navigator('/startup/business-strategy')
                    }
                }
            })
        }
    };
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
        debugger
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBack}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Product and Market Fit</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="about" control={control} defaultValue="" rules={{ required: 'About the Startup is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="About the Startup *"
                                                placeholder='Enter About the Startup'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.about && errors.about.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <FormLabel>Do you own patents (Intellectual Properties)</FormLabel>
                                    <Controller name="intellectualProps" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            // <TextField
                                            //     variant='outlined'
                                            //     label="Intellectual Properties *"
                                            //     placeholder='Enter Intellectual Properties'
                                            //     fullWidth
                                            //     inputProps={
                                            //         { readOnly: readyOnlyValue, }
                                            //     }
                                            //     {...field}
                                            // />
                                            <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                            >
                                                <FormControlLabel disabled={readyOnlyValue} value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} value='false' control={<Radio />} label="No" />
                                            </RadioGroup>
                                        } />
                                    <FormHelperText className='text-danger'>{errors.intellectualProps && errors.intellectualProps.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="technologyStack" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            // <TextField
                                            //     variant='outlined'
                                            //     label="Technology Stack *"
                                            //     placeholder='Enter Technology Stack'
                                            //     fullWidth
                                            //     inputProps={
                                            //         { readOnly: readyOnlyValue, }
                                            //     }
                                            //     {...field}
                                            // />
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                {...field}
                                                limitTags={4}
                                                value={technologyStackValue}
                                                options={technologiesList}
                                                onChange={(e, selectedOptions) => handleSelectTechnologyStack(selectedOptions)}
                                                renderInput={(params) =>
                                                    <TextField {...params} label="Technology Stack" />
                                                }
                                                disabled={readyOnlyValue}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        } />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.technologyStack && errors.technologyStack.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="marketAnalysis" control={control} defaultValue="" rules={{ required: 'Market Analysis is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Market Analysis *"
                                                placeholder='Enter Market Analysis'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.marketAnalysis && errors.marketAnalysis.message}</FormHelperText>
                                </div>
                            </div>

                            <div className='col-12 mb-3'>
                                <div className='div_header'>
                                    <div className='d-flex'>
                                        <h6>Products Details</h6>
                                        <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addAdvisoriesDetailsBtn()}>Add Products Details</Button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        (productsFields.length) ? (
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>No</TableCell>
                                                            <TableCell>Product Name </TableCell>
                                                            <TableCell>Link </TableCell>
                                                            <TableCell>Product Road Map </TableCell>
                                                            <TableCell className='text-center'>Upload Image </TableCell>
                                                            <TableCell align='center'>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {productsFields.map((field, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{index + 1}
                                                                    <FormControl sx={{ display: 'none' }} >
                                                                        <Controller name={`products.${index}.id`} control={control} defaultValue={0} rules={{ required: false }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    {...field}
                                                                                />}
                                                                        />
                                                                    </FormControl>
                                                                </TableCell>

                                                                <TableCell>
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <Controller name={`products.${index}.name`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Name *"
                                                                                    placeholder='Enter Name'
                                                                                    fullwidth
                                                                                    {...field}
                                                                                    inputProps={
                                                                                        { readOnly: readyOnlyValue, }
                                                                                    }
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.products?.[index]?.name && errors.products?.[index]?.name.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <Controller name={`products.${index}.link`} control={control} defaultValue="" rules={{ required: false }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Link"
                                                                                    placeholder='Enter Link'
                                                                                    fullwidth
                                                                                    {...field}
                                                                                    disabled={readyOnlyValue}
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.products?.[index]?.link && errors.products?.[index]?.link.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <Controller name={`products.${index}.productRoadMap`} control={control} defaultValue="" rules={{ required: false }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Product Road Map"
                                                                                    placeholder='Enter Product Road Map'
                                                                                    fullwidth
                                                                                    {...field}
                                                                                    disabled={readyOnlyValue}
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.products?.[index]?.productRoadMap && errors.products?.[index]?.productRoadMap.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell className='text-center'>
                                                                    <>
                                                                        {
                                                                            (multipleImages[index]) ? (
                                                                                <>
                                                                                    <Button
                                                                                        component="label"
                                                                                        variant="contained"
                                                                                        {...field}
                                                                                        onChange={(e) => handleProductUpload(e, index)}
                                                                                        href="#file-upload"
                                                                                        disabled={readyOnlyValue}
                                                                                    >
                                                                                        <i className="far fa-check-circle mr-2"></i> Uploaded
                                                                                        {
                                                                                            (readyOnlyValue === false) ? (
                                                                                                <VisuallyHiddenInput type="file" />
                                                                                            ) : (<></>)
                                                                                        }
                                                                                    </Button>
                                                                                    {/* {
                                                                                        multipleImages[index].id && (
                                                                                            <Link to={multipleImages[index].productImageName} target='_blank' className='view-img-btn'>View Image</Link>
                                                                                        )
                                                                                    } */}
                                                                                    <Button  onClick={() => handleClickOpen(multipleImages[index].productImageName)}>View Image</Button>
                                                                                </>

                                                                            ) : (
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <Controller name={`products.${index}.productImage`} control={control} defaultValue="" rules={{ required: false }}
                                                                                        render={({ field }) =>
                                                                                            <Button
                                                                                                component="label"
                                                                                                variant="contained"
                                                                                                {...field}
                                                                                                fullWidth
                                                                                                onChange={(e) => handleProductUpload(e, index)}
                                                                                                startIcon={<CloudUploadIcon />}
                                                                                                href="#file-upload"
                                                                                                disabled={readyOnlyValue}
                                                                                            >
                                                                                                Upload Image
                                                                                                {
                                                                                                    (readyOnlyValue === false) ? (
                                                                                                        <VisuallyHiddenInput type="file" />
                                                                                                    ) : (<></>)
                                                                                                }
                                                                                            </Button>
                                                                                        } />
                                                                                    <FormHelperText className='text-danger'>
                                                                                        {errors.products?.[index]?.productImage && errors.products?.[index]?.productImage.message}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            )
                                                                        }
                                                                    </>

                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    <DeleteIcon style={{ fill: '#D32F2F', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteProducts(index) : <></> }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <div className='nodata_tag'>
                                                <p>Products details is empty</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="form-group text-center">
                            <Button variant="contained" onClick={handleBack} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                </div >
            </section >
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </>
    )
}

export default Productandmarket
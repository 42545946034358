import React, { useContext, useEffect, useState } from "react";
import apiService from "../../../../api/apiService";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Container, Divider, Drawer, Tab } from "@mui/material";

// import Startupappication from './details-components/Startupappication';
// import Startupstartdetails from './details-components/Startupstartdetails';
// import Reviewrounds from './details-components/Reviewrounds';
// import Reviewquestion from './details-components/Reviewquestion';
// import Moreinfo from './details-components/Moreinfo';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import './startupdetails.css'
import DataContext from "../../../../context/DataContext";

import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Associatedtab from "./pages/Associatedtab";
import Associatedrounds from "./Associatedrounds";
import Associatedroundssave from "./Associatedroundssave";
import { Stack } from "@mui/material";
import Associatedscsttab from "./pages/Associatedscsttab";
import Scoremodal from "../../../scst-page/Scoremodal";
import Tanfundscoremodal from "../../../partner-module/startup-page/startup-details-page/Tanfundscoremodal";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Startuplistfilter from "./Startuplistfilter";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Tanseedscoremodal from "../../../partner-module/startup-page/startup-details-page/Tanseedscoremodal";
import { AiFillSafetyCertificate } from "react-icons/ai";
import Viewimage from "../../../../api/Viewimage";
import CommonViewPitchDeck from "../../../common-page/CommonViewPitchDeck";
import notifyService from "../../../../api/notifySerivce";

const Associateddetails = ({ name, path, endpoint }) => {
  console.log(path);

  const { getSCSTStartupStatus, navigator, setPageLoading, zoomToken } =
    useContext(DataContext);
  const { startupId } = useParams();
  const { id } = useParams();
  let idNumber = atob(startupId);
  let partnerOrInvestorId = atob(id);
  const [startupGetData, setStartupGetData] = useState({});
  useDidMountEffect(() => {
    // eslint-disable-next-line
    var zoomTokenlet = localStorage.getItem("zoomToken");
    if (zoomTokenlet !== null) {
      zoomToken();
    }
    viewStartupDetails(idNumber, partnerOrInvestorId);
    listApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [startupValue, setStartupValue] = useState({});
  const [roundNo, setRoundNo] = useState(1);
  const viewStartupDetails = async () => {
    setPageLoading(true);
    setStartupGetData({});
    setStartupValue({});
    if (name === "jury") {
      var req = {
        startupId: Number(idNumber),
        partnerOrInvestorId: Number(partnerOrInvestorId),
      };
      apiService(`${name}/${endpoint}/get`, req, "post").then((res) => {
        setPageLoading(false);
        if (res) {
          if (res.data) {
            if (res.data.applicationForm) {
              setStartupGetData(res.data.applicationForm);
            }
            setStartupValue(res.data);
            if (res.data.showRounds === true) {
              // roundJuryList()
            }
            if (res.data.showQuestions === true) {
              // questionList()
            }
            if (res.data.roundNo) {
              setRoundNo(res.data.roundNo);
              setNavTabValue(path === "jury/tanseed" ? "1" : "2");
            }
          }
        }
      });
    } else {
      apiService(`${name}/${endpoint}/get?id=${id}`, "", "get").then((res) => {
        setPageLoading(false);
        if (res) {
          if (res.data) {
            if (res.data.applicationForm) {
              setStartupGetData(res.data.applicationForm);
            }
            setStartupValue(res.data);
            if (res.data.roundNo) {
              setRoundNo(res.data.roundNo);
              setNavTabValue(path === "jury/tanseed" ? "1" : "2");
            }
          } else {
            setStartupGetData({});
          }
        }
      });
    }
    // infoGetInfo()
  };

  const [listData, setListData] = useState({});
  const listApi = () => {
    debugger;
    var req = {
      partnerOrInvestorId: partnerOrInvestorId,
      startupId: idNumber,
    };
    apiService("partner/scst/admin/startup/get", req, "post")
      .then((result) => {
        if (result) {
          if (result.data.startupRounds) {
            setListData(result.data.startupRounds[1]);
          }
        }
      })
      .catch((err) => {});
  };
  const [viewFile, setViewFile] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState({});
  const infoGetInfo = () => {
    var id = Number(idNumber);
    apiService(`startup/partner/additionalinfo/get?id=${id}`, "", "get").then(
      (res) => {
        if (res) {
          setAdditionalInfo(res.data);
          setViewFile(res.data.additionalDocName);
        }
      }
    );
  };
  // const [roundListData, setRoundListData] = useState([])
  // const roundJuryList = () => {
  //     var req = {
  //         "startupId": Number(idNumber),
  //         "partnerId": Number(partnerOrInvestorId),
  //     }
  //     apiService(`jury/panelround/review/list`, req, 'post').then((res) => {
  //         if (res) {
  //             if (res.data.rounds) {
  //                 setRoundListData(res.data.rounds)
  //             } else {
  //                 setRoundListData([])
  //             }

  //         }
  //     })
  // }
  const roundPartnerList = () => {
    var req = {
      startupId: Number(idNumber),
    };
    apiService(`partner/panelround/questions/review/list`, req, "post").then(
      (res) => {
        if (res) {
          if (res.data.rounds) {
            // setRoundListData(res.data.rounds)
          } else {
            // setRoundListData([])
          }
        }
      }
    );
  };
  // const [questionListData, setQuestionListData] = useState([])
  // const questionList = () => {
  //     var req = {
  //         "startupId": Number(idNumber),
  //         "partnerOrInvestorId": Number(partnerOrInvestorId),
  //     }
  //     apiService(`jury/panelround/questions/review/list`, req, 'post').then((res) => {
  //         if (res) {
  //             if (res.data.rounds) {
  //                 setQuestionListData(res.data.startupRounds)
  //             } else {
  //                 setQuestionListData([])
  //             }

  //         }
  //     })
  // }
  const handleBack = () => {
    navigator(`${path}/list`);
  };

  const [navTabValue, setNavTabValue] = useState(
    path === "jury/tanseed" ? "1" : "2"
  );
  const handleNavTabChange = (event, newValue) => {
    setNavTabValue(newValue);
  };
  const [openList, setOpenList] = React.useState(false);
  const handleStartupList = () => {
    setOpenList(true);
  };

  const handleClose = (value) => {
    setOpenList(false);
  };

  const isRegistered = startupGetData.dpiitRegistered;

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  let role = localStorage.getItem("role");
  let roundDet;
  const handledownloadpdf = () => {
    setPageLoading(true);
    if (role === "JURY") {
      roundDet = "evaluation";
    }
    var req = {
      id: idNumber,
      round: roundDet,
    };
    debugger;
    apiService("partner/export/scst/startup/application", req, "post")
      .then((res) => {
        setPageLoading(false);
        if (res.data && res.data.responseStatus === "Success") {
          notifyService("success", "Success", "PDF sent to the email");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="box-show">
      <section className="bg-white">
        <div className="row">
          {path !== "jury/scst" && (
            <div className="col-lg-2 p-0">
              <div className="box-line">
                <Startuplistfilter
                  path={path}
                  startupName={startupValue.startupName}
                  viewStartupDetails={viewStartupDetails}
                  handleClose={handleClose}
                />
              </div>
            </div>
          )}

          <div
            className={path === "jury/scst" ? "col-lg-12 p-0" : "col-lg-10 p-0"}
          >
            <div className="p-relative">
              <div className="p-3">
                <div className="dflex justify-content-between align-Items-center">
                  <div className="top-banner align-items-center">
                    <div>
                      <Button onClick={handleBack}>
                        <ArrowBackIcon />
                      </Button>
                    </div>
                    <img
                      src={startupGetData.startupLogoName}
                      className="img-fluid user_logos"
                      alt=""
                    />
                    <div className="content">
                      <div className="d-flex align-items-center mb-3">
                        <h3 className="mr-3 mb-0">
                          {startupValue.startupName}
                        </h3>
                        <button
                          className={`dpi-button ${
                            isRegistered ? "register-btn" : "not-register-btn"
                          }`}
                        >
                          {isRegistered && (
                            <AiFillSafetyCertificate className="icon" />
                          )}
                          {isRegistered
                            ? "DPIIT Recognised"
                            : "Non DPIIT Recognised"}
                        </button>
                        <button
                          className="viewdetails-surpitch"
                          onClick={() => {
                            handleClickOpen(startupGetData.pitchDeck);
                          }}
                        >
                          View PitchDeck
                        </button>
                      </div>
                      <span className="black-clr-sur">
                        <img src="/images/phone.png" alt="" className="mr-2" />{" "}
                        {startupValue.startupPhone
                          ? startupValue.startupPhone
                          : "-"}
                      </span>
                      <span className="black-clr-sur ml-3">
                        <img src="/images/email.png" alt="" className="mr-2" />{" "}
                        {startupValue.startupEmail
                          ? startupValue.startupEmail
                          : "-"}
                      </span>
                      <span className="black-clr-sur ml-3">
                        Member Since : {startupValue.date}
                      </span>
                      <div className="mt-3">
                        Status :{" "}
                        <span className="status_startup">
                          {getSCSTStartupStatus(startupValue.status)}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* {role === 'JURY' && ( <div className='mr-5'>
                                        <button className='down-pdf-btn' onClick={() => handledownloadpdf()}>Export Details to the Mail</button>
                                    </div>)} */}
                  {/* <div className='ml-auto mr-2'>
                                        <div className="d-flex">
                                            <div className="ml-auto text-right">
                                                <p className='mb-3'>
                                                    <span
                                                        className="viewdetails-sur"
                                                        onClick={() => {
                                                            handleClickOpen(startupGetData.pitchDeck);
                                                        }}
                                                    >
                                                        View PitchDeck
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
                <hr />
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={navTabValue}>
                    {path !== "jury/scst" && (
                      <>
                        {path === "jury/tanseed" && (
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={handleNavTabChange}
                              aria-label="lab API tabs example"
                            >
                              <Tab label="Round Status & Score" value="1" />
                              <Tab label="Startups" value="2" />
                            </TabList>
                          </Box>
                        )}
                      </>
                    )}
                    <TabPanel value="1" sx={{ padding: "10px 0px" }}>
                      <Associatedrounds
                        startupId={idNumber}
                        partnerOrInvestorId={partnerOrInvestorId}
                        name={name}
                      />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "10px 0px" }}>
                      {path === "jury/scst" ? (
                        <Associatedscsttab
                          startupGetData={startupGetData}
                          startupValue={startupValue}
                          idNumber={idNumber}
                          name={name}
                          viewStartupDetails={viewStartupDetails}
                          listData={listData}
                          path={path}
                          partnerOrInvestorId={partnerOrInvestorId}
                        />
                      ) : (
                        <Associatedtab
                          startupGetData={startupGetData}
                          startupValue={startupValue}
                          idNumber={idNumber}
                          name={name}
                        />
                      )}
                    </TabPanel>
                  </TabContext>
                </Box>
                {path === "jury/tanfund" && (
                  <Tanfundscoremodal
                    idNumber={idNumber}
                    path={path}
                    partnerOrInvestorId={partnerOrInvestorId}
                    viewStartupDetails={viewStartupDetails}
                  />
                )}

                {path === "jury/tanseed" && (
                  <Tanseedscoremodal
                    idNumber={idNumber}
                    path={path}
                    partnerOrInvestorId={partnerOrInvestorId}
                    roundNo={roundNo}
                    viewStartupDetails={viewStartupDetails}
                  />
                )}
                {/* <Startuplistfilter /> */}
                {/* <div className="tab_side_left">
                                    <button onClick={handleStartupList} className="fliter_btn">
                                        <FilterAltOffIcon />                                       
                                    </button>
                                </div> */}
                <Drawer
                  anchor="left"
                  open={openList}
                  onClose={handleClose}
                  className="scroe_module"
                >
                  <Box sx={{ width: 250 }} role="presentation">
                    <div className="box-line-sm">
                      <Startuplistfilter
                        path={path}
                        startupName={startupValue.startupName}
                        viewStartupDetails={viewStartupDetails}
                        handleClose={handleClose}
                      />
                    </div>
                  </Box>
                </Drawer>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CommonViewPitchDeck
        viewImage={viewImage}
        open={open}
        handleClose={handleClose1}
      />
    </div>
  );
};

export default Associateddetails;

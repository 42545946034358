import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../../../api/apiService';
import notifyService from '../../../../../api/notifySerivce';
import { FormHelperText, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
const Moreinfo = ({ idNumber, viewFile, additionalInfo }) => {
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, formState: { errors } } = useForm(
    { values: formData, }
  );
  useDidMountEffect(() => {
    setFormData(additionalInfo)
  }, [additionalInfo]);

  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true)
    var getform = data
    getform.startupId = Number(idNumber)
    apiService('partner/startups/getadditionalinfo', getform, 'post').then((res) => {
      setLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Updated successfully !!')
        }
      }
    })
  }
  return (
    <>
      <main>
        <section>
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className='row align-item-center'>
              <div className='col-lg-9 col-12'>
                <div className="">
                  <Controller name="comments" control={control} defaultValue="" rules={{ required: 'Comment is requried' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Comment"
                        placeholder='Enter Comment'
                        fullWidth
                        multiline
                        autoFocus
                        {...field}
                      />} />
                  <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-3 col-12 text-center'>
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                >
                  <span>Request</span>
                </LoadingButton>
              </div>
              <div className='col-lg-12 col-12 mt-3'>
                {
                  viewFile !== undefined && (
                    <p>Attached Documents -
                      <Link to={`${viewFile}`} target="_blank"><span className='viewdetails'>Download File</span></Link>
                    </p>
                  )
                }
              </div>
            </div>
          </form>
        </section>
      </main>
    </>
  )
}

export default Moreinfo
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
const fileTypes = ["pdf"];

const FddSectionK = () => {
  const [formData, setFormData] = useState({});
  const { handleBackapp, setPageLoading,navigator } = useContext(DataContext)
  const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [bankFacilities, setBankFacilities] = useState();
  const [roc, setROC] = useState();
  const [fileUploadId, setFileUploadId] = useState(0)
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/seck/get`, '', 'get').then((res) => {
      if (res && res.data) {
        var data = res.data
        if (res.data.bankFacilities) {
          setBankFacilities(res.data.bankFacilities)
        }
        if (res.data.workingCapitalLoans) {
          setLoans(res.data.workingCapitalLoans)
        }
        
        setFormData(res.data)
      }
      setPageLoading(false)
    })
  }
  const [commitments, setCommitments] = useState();
  const [loans, setLoans] = useState();
  const [bankLoans, setBankLoans] = useState();
  const handleClosingBalanceConfirmation = (e) => {
    handleFileUpload(e, "closingBalanceConfirmation", "closingBalanceConfirmationName", "closingBalanceConfirmationURL", 'file');
  }
  const handleSanctionLetters = (e) => {
    handleFileUpload(e, "sanctionLetters", "sanctionLettersName", "sanctionLettersURL", 'file');
  }
  const handleLoanRepaymentDetails = (e) => {
    handleFileUpload(e, "loanRepaymentDetails", "loanRepaymentDetailsName", "loanRepaymentDetailsURL", 'file');
  }
  const handleSubmittedInventory = (e) => {
    handleFileUpload(e, "submittedInventory", "submittedInventoryName", "submittedInventoryURL", 'file');
  }

  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type
        var imagedatatype = imagetype.split("/")
        var img_crt_type = imagedatatype[1]
        if (
          ((format === 'file') && (img_crt_type === "pdf")) ||
          ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
        ) {
          setValue(filekey, "")
          setValue(fileName, "")

          var fileValue = logoselecetdFile
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(',')
            var ImageValue = spl[1]
            var img_name = fileValue.name
            setValue(filekey, ImageValue)
            setValue(fileName, img_name)
            setValue(fileURL, logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId
            }
            console.log(req);
            // saveData(req)
          }
        } else {
          notifyService('danger', 'File Format Invalided', 'Please check your file format')
        }
      }
    }
  }

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    console.log(data);

    setPageLoading(true);

    apiService('startup/fdd/seck/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-l");
        }
      }
    })
  }
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Bank Loans</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>

            <div className="row doc-row2">
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Availed any facilities from the bank such as OD, CC or Term Loans?
                    If yes, provide with the (Closing balance confirmation for outstanding liability,Sanction Letters)
                    </FormLabel>
                  <Controller name="bankFacilities" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setBankFacilities(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setBankFacilities(e.target.value); setValue('closingBalanceConfirmation', ''); setValue('sanctionLetters', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.bankFacilities && errors.bankFacilities.message}</FormHelperText>
                </div>
              </div>
              {
                (bankFacilities === 'true' || bankFacilities === true) && (
                  <>
                    <div className='col-lg-4  col-12 mt-2'>
                      <div className="d-flex align-items-center">
                        <div className='form-group my-1'>
                          <Controller name="closingBalanceConfirmation" control={control} defaultValue="" rules={{ required: false }}
                            render={({ field }) =>
                              <FileUploader
                                {...field}
                                handleChange={handleClosingBalanceConfirmation}
                                value={getValues('closingBalanceConfirmation')}
                                name="file"
                                types={fileTypes}
                                children={
                                  <div className={getValues('closingBalanceConfirmation') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                    <center>
                                      <p className={getValues('closingBalanceConfirmation') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues('closingBalanceConfirmation') !== '' ? 'Uploaded (Click to Change)' : 'Closing balance Document'}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            } />
                          <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                          <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.closingBalanceConfirmation && errors.closingBalanceConfirmation.message}</FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                          onClick={() => handleClickOpen(getValues('closingBalanceConfirmationURL'))}
                          style={{ color: "#80809e" }}
                        > Preview
                        </Link>
                        </span>
                      </div>
                    </div>
                    <div className='col-lg-4  col-12 mt-2'>
                      <div className="d-flex align-items-center">
                        <div className='form-group my-1'>
                          <Controller name="sanctionLetters" control={control} defaultValue="" rules={{ required: false }}
                            render={({ field }) =>
                              <FileUploader
                                {...field}
                                handleChange={handleSanctionLetters}
                                value={getValues('sanctionLetters')}
                                name="file"
                                types={fileTypes}
                                children={
                                  <div className={getValues('sanctionLetters') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                    <center>
                                      <p className={getValues('sanctionLetters') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues('sanctionLetters') !== '' ? 'Uploaded (Click to Change)' : 'Sanction Letters Document'}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            } />
                          <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                          <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.sanctionLetters && errors.sanctionLetters.message}</FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                          onClick={() => handleClickOpen(getValues('sanctionLettersURL'))}
                          style={{ color: "#80809e" }}
                        > Preview
                        </Link>
                        </span>
                      </div>
                    </div>
                  </>
                )
              }
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Is the company regular in meeting monthly loan commitments.</FormLabel>
                  <Controller name="regularLoanCommitments" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setCommitments(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setCommitments(e.target.value); setValue('regularLoanCommitments', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.regularLoanCommitments && errors.regularLoanCommitments.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
              <FormLabel>Provide a table for each loan mentioning opening balance, repayments and closing balance</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="loanRepaymentDetails" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleLoanRepaymentDetails}
                          value={getValues('loanRepaymentDetails')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('loanRepaymentDetails') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('loanRepaymentDetails') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('loanRepaymentDetails') !== '' ? 'Uploaded (Click to Change)' : 'Closing Balance Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.loanRepaymentDetails && errors.loanRepaymentDetails.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('loanRepaymentDetailsURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Is any guarantee provided for the bank loans</FormLabel>
                  <Controller name="bankGuarantees" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setBankLoans(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setBankLoans(e.target.value); setValue('bankGuarantees', ''); setValue('sanctionLetters', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.bankGuarantees && errors.bankGuarantees.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Availed any working capital loans from the bank - if so, provide the Monthly Inventory or Receivables Statements submitted to the bank.</FormLabel>
                  <Controller name="workingCapitalLoans" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setLoans(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setLoans(e.target.value); setValue('closingBalanceConfirmation', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.workingCapitalLoans && errors.workingCapitalLoans.message}</FormHelperText>
                </div>
              </div>
              {
                (loans === 'true' || loans === true) && (
                  <>
                    <div className='col-lg-4  col-12 mt-2'>
                      <div className="d-flex align-items-center">
                        <div className='form-group my-1'>
                          <Controller name="submittedInventory" control={control} defaultValue="" rules={{ required: false }}
                            render={({ field }) =>
                              <FileUploader
                                {...field}
                                handleChange={handleSubmittedInventory}
                                value={getValues('submittedInventory')}
                                name="file"
                                types={fileTypes}
                                children={
                                  <div className={getValues('submittedInventory') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                    <center>
                                      <p className={getValues('submittedInventory') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues('submittedInventory') !== '' ? 'Uploaded (Click to Change)' : 'Loan Document'}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            } />
                          <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                          <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.submittedInventory && errors.submittedInventory.message}</FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                          onClick={() => handleClickOpen(getValues('submittedInventoryURL'))}
                          style={{ color: "#80809e" }}
                        > Preview
                        </Link>
                        </span>
                      </div>
                    </div>
                  </>
                )
              }
            
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Is any collateral security provided for the bank loan</FormLabel>
                  <Controller name="collateralSecurity" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setBankLoans(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setBankLoans(e.target.value); setValue('collateralSecurity', ''); }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.collateralSecurity && errors.collateralSecurity.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Is the charge created for the bank loans and is it filed with ROC</FormLabel>
                  <Controller name="loanChargeFiledWithROC" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setBankLoans(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setBankLoans(e.target.value); setValue('loanChargeFiledWithROC', ''); }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.loanChargeFiledWithROC && errors.loanChargeFiledWithROC.message}</FormHelperText>
                </div>
              </div>
              {/* <div className='col-lg-4 col-12 mt-2'>
                <FormLabel>The Valuation Basis. ( Basis on which the valuation for the current round has been arrived at)</FormLabel>
                <div className="form-group mt-2">
                  <Controller
                    name="valuationBasis"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="The Valuation Basis"
                        placeholder="Enter Details"
                        fullWidth
                        multiline
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.valuationBasis && errors.valuationBasis.message}
                  </FormHelperText>
                </div>
              </div> */}
            </div>


            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
        <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      </section>
    </>
  )
}

export default FddSectionK
import React, { useContext, useEffect, useState } from 'react'
import apiService from '../../../../../api/apiService'
import { Controller, useForm } from 'react-hook-form';
import notifyService from '../../../../../api/notifySerivce';
import { Autocomplete, Button, Divider, FormControl, FormHelperText, Tab, TablePagination, TextField } from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom';
import Viewimage from '../../../../../api/Viewimage';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import Additionalinfo from '../../../../startup-module/additional-info-page/Additionalinfo';
import DataContext from '../../../../../context/DataContext';
import Resubmit from './Resubmit';
import ClarificationAll from '../../../../scstBdd-page/scstBdd-details/ClarificationAll';

const Moreinfosave = ({ idNumber, detail, viewStartupDetails, path }) => {
    debugger
    // console.log(name,'name')
    const { setPageLoading } = useContext(DataContext)
    const [historyTabValue, setHistoryTabValue] = React.useState('1');
    const handleHistoryChangeInfo = (event, newValue) => {
        setHistoryTabValue(newValue);
        setAddInfoValue('1')
    };
    const [addInfoValue, setAddInfoValue] = React.useState('1');
    const handleChangeInfo = (event, newValue) => {
        setAddInfoValue(newValue);
    };
    const [formData, setFormData] = useState({});

    const { handleSubmit, getValues, setValue, clearErrors, reset, control, formState: { errors } } = useForm(
        { values: formData, }
    );
    const [statusValue, setStatusValue] = useState(0)
    const [page, setPage] = useState(0);
    useDidMountEffect(() => {
        // infoGetInfo()
        historyList()
        showStartupValue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusValue,page]);

    const [viewFile, setViewFile] = useState(undefined)
    const infoGetInfo = () => {
        var id = Number(idNumber)
        apiService(`partner/additionalinfo/get?id=${id}`, '', 'get').then((res) => {
            if (res) {
                setFormData(res.data)
                setViewFile(res.data.additionalDocName)
            }
        })
    }
    const reasonList = [
        'Application Details',
        'Funding & Financial Details',
        'Founder Informations',
        'Incubation/Acceleration Details'
    ]
    const [reasonName, setReasonName] = useState('')
    const handleReason = (value) => {
        setValue('reason', value);
        clearErrors('reason')
        setReasonName(value)
    };
    const handleStatus = (value) => {
        setStatusValue(value)
    }
    const [count, setCount] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [historyListValue, setHistoryListValue] = useState([])
    const historyList = () => {
        setHistoryListValue([])
        setPageLoading(true)
        var id = Number(idNumber)
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            startupId: id,
            userId: localStorage.getItem('userId'),
            status: statusValue
        }
        apiService('partner/startups/additionalinfo/history', req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                setCount(res.data.count)
                if (res.data.additionalInfo) {
                    setHistoryListValue(res.data.additionalInfo)
                }
            }
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [startupCheckList,setStartupCheckList]= useState([])
    const showStartupValue = async () =>{
        apiService(`startup/clarifications/status/get?id=${idNumber}`, '', 'get').then((res) => {

            if (res) {
                if (res.data) {
                    debugger
                    setStartupCheckList(res.data)
                }
            }
        })
    }


    const [loading, setLoading] = useState(false);
    const onSubmit = (data) => {
        debugger
        setLoading(true)
        var getform = data
        getform.startupId = Number(idNumber)
        getform.status = 0
        getform.userId = localStorage.getItem('userId')
        apiService('partner/additionalinfo/save', getform, 'post').then((res) => {
            setLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    debugger
                    notifyService('success', 'Success', 'Updated successfully !!')
                    reset()
                    setReasonName('')
                    showStartupValue()
                    viewStartupDetails()
                    setStatusValue(0)
                    historyList()
                    debugger
                }
            }
        })
    }
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handelMarkVerifed = (id) => {
        setLoading(true)
        var req = {
            id: id,
            status: 2
        }
        apiService(`partner/startups/additionalinfo/status/save`, req, 'post').then((res) => {
            setLoading(false)
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Updated successfully !!')
                    viewStartupDetails()
                    showStartupValue()
                    // setStatusValue(0)
                    historyList()
                }
            }
        })
    }
    const Round = localStorage.getItem("startupRoundStatus")
    // const roles = localStorage.getItem('role')
    return (
        <main>
            <section>
                {
                    (path !== 'scstbdd' && detail.status === 0 || Round === '0') && (
                        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className='row align-item-center'>
                                <div className='col-lg-4 col-md-6 col-12 my-3'>
                                    {/* <FormControl> */}
                                    <Controller name="reason" control={control} defaultValue="" rules={{ required: 'Reason is requried' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                fullWidth
                                                {...field}
                                                disableClearable
                                                disablePortal
                                                value={reasonName}
                                                autoFocus
                                                options={reasonList}
                                                onChange={(e, selectedOptions) => handleReason(selectedOptions)}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => <TextField {...params} label="Reason *" />}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.reason && errors.reason.message}</FormHelperText>
                                    {/* </FormControl> */}
                                </div>
                                <div className='col-lg-8 col-md-6 col-12'>
                                    {/* <FormControl > */}
                                    <Controller name="comments" control={control} defaultValue="" rules={{ required: 'Comment is requried' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Comment *"
                                                placeholder='Enter Comment'
                                                fullWidth
                                                multiline
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                                    {/* </FormControl> */}
                                </div>
                                <div className='col-lg-12 col-12 text-center'>
                                    <Button
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SendIcon />}
                                        variant="contained"
                                    >
                                        <span>Request</span>
                                    </Button>
                                </div>
                                {/* <div className='col-lg-12 col-12 mt-3'>
                            {
                                viewFile !== undefined && (
                                    <p>Attached Documents - <span className='viewdetails' onClick={() => { handleClickOpen(viewFile) }}>Download File</span>
                                    </p>
                                )
                            }
                        </div> */}
                                {
                                    getValues(`startupComments`) && (
                                        <div className='col-lg-12 col-12 mt-3'>
                                            <p>Comments from Startups : <b>{getValues(`startupComments`)}</b></p>
                                        </div>
                                    )
                                }
                            </div>
                        </form>
                    )
                }

                <div>
                    <TabContext value={historyTabValue}>
                        <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                            <TabList onChange={handleHistoryChangeInfo} aria-label="lab API tabs example">
                                <Tab label="Your History" value="1" className='amp' onClick={() => handleStatus(0)} />
                                <Tab label="Others History" value="2" onClick={() => handleStatus(3)} className='amp' />
                            </TabList>
                        </Box>
                        <Divider />
                        <TabPanel value="1" sx={{ padding: '10px 0px' }}>
                            <TabContext value={addInfoValue}>
                                <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                                    <TabList onChange={handleChangeInfo} aria-label="lab API tabs example">
                                        <Tab label="Pending" value="1" onClick={() => handleStatus(0)} />
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Submitted
                                                    {detail.additionalInfoRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }
                                            value="2" onClick={() => handleStatus(1)} />
                                        <Tab label="Verified" value="3" onClick={() => handleStatus(2)} />
                                    </TabList>
                                </Box>
                                <Divider />
                                <div className="mt-3">
                                    {historyListValue.length > 0 &&
                                        (historyListValue.length) ? (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>No</TableCell>
                                                        <TableCell>Reason</TableCell>
                                                        <TableCell align='center'>Comments</TableCell>
                                                        {
                                                            statusValue === 0 && <TableCell align='center'>Requested Time</TableCell>
                                                        }
                                                        {
                                                            statusValue === 1 && <TableCell align='center'>Submitted Time</TableCell>
                                                        }
                                                        {
                                                            statusValue === 2 && <TableCell align='center'>Time</TableCell>
                                                        }
                                                        {
                                                            statusValue === 1 && <TableCell align='center'>Attached Document</TableCell>
                                                        }
                                                        {
                                                            statusValue === 2 && <TableCell align='center'>View Document</TableCell>
                                                        }

                                                        {
                                                            statusValue === 1 && (
                                                                <TableCell align='center'>Action</TableCell>
                                                            )
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {historyListValue.map((list, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align='center'>{page * rowsPerPage + index + 1}</TableCell>
                                                            <TableCell>{list.reason} {list.reasonAll && (<ClarificationAll remarks={list.reasonAll} lblName="Reason" btnName="Show All Reason" />)}</TableCell>
                                                            <TableCell align='center'>
                                                                {list.remarks ? list.remarks : (`-`)}
                                                            </TableCell>
                                                            <TableCell align='center'>{list.time}</TableCell>
                                                            {
                                                                statusValue === 1 && <TableCell align='center'><button className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</button></TableCell>
                                                            }
                                                            {
                                                                statusValue === 2 && <TableCell align='center'><button className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</button></TableCell>
                                                            }
                                                            {
                                                                statusValue === 1 && (
                                                                    <TableCell align='center'>
                                                                        <Resubmit id={list.id} idNumber={idNumber} historyList={historyList} name="hub" showStartupValue={showStartupValue}/>
                                                                        <button className='verified_btn' onClick={() => handelMarkVerifed(list.id)}>Mark as Verified</button>
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <caption>
                                                    <TablePagination
                                                        component="div"
                                                        count={count}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        style={{ float: 'left' }}
                                                    />
                                                </caption>
                                            </Table>

                                        </TableContainer>
                                    ) : (
                                        <div className='nodata_tag'>
                                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                            <p>List is empty</p>
                                        </div>
                                    )
                                    }
                                </div>
                            </TabContext>
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: '10px 0px' }}>
                            <div className="mt-3">
                                {historyListValue.length > 0 &&
                                    (historyListValue.length) ? (
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='center'>No</TableCell>
                                                    <TableCell>Reason</TableCell>
                                                    <TableCell align='center'>Comments</TableCell>
                                                    <TableCell align='center'>Time</TableCell>
                                                    <TableCell align='center'>By</TableCell>
                                                    <TableCell align='center'>View Document</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {historyListValue.map((list, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell align='center'>{index + 1}</TableCell>
                                                        <TableCell>{list.reason}  {list.reasonAll && (<ClarificationAll remarks={list.reasonAll} lblName="Reason" btnName="Show All Reason" />)}</TableCell>
                                                        <TableCell align='center'>{list.remarks ? list.remarks : (`-`)}</TableCell>
                                                        <TableCell align='center'>{list.time}</TableCell>
                                                        <TableCell align='center'>{list.by}</TableCell>
                                                        <TableCell align='center'><span className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</span></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <caption>
                                                <TablePagination
                                                    component="div"
                                                    count={count}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    style={{ float: 'left' }}
                                                />
                                            </caption>
                                        </Table>

                                    </TableContainer>
                                ) : (
                                    <div className='nodata_tag'>
                                        <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                        <p>List is empty</p>
                                    </div>
                                )
                                }
                            </div>
                        </TabPanel>
                    </TabContext>
                    {/* <div className='div_header'>
                        <h6 className='mt-5' style={{ color: '#313131' }}>History</h6>
                    </div> */}

                </div>
            </section>
            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </main>
    )
}

export default Moreinfosave
import React, { useContext, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  Paper,
  FormHelperText,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Autocomplete,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import notifyService from "../../../api/notifySerivce";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUploader } from "react-drag-drop-files";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Viewimage from "../../../api/Viewimage";
import { useEffect } from "react";

const fileTypes = ["pdf"];

function numberToWordsIndian(num) {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Fourty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  function convertBelowThousand(n) {
    let word = "";
    if (n > 99) {
      word += units[Math.floor(n / 100)] + " Hundred ";
      n %= 100;
    }
    if (n > 19) {
      word += tens[Math.floor(n / 10)] + " ";
      word += units[n % 10];
    } else {
      word += units[n];
    }
    return word.trim();
  }

  function convertNumberToWords(n) {
    if (n === 0) return "Zero";
    let words = "";
    let crore = Math.floor(n / 10000000);
    n %= 10000000;
    let lakh = Math.floor(n / 100000);
    n %= 100000;
    let thousand = Math.floor(n / 1000);
    n %= 1000;
    let hundred = Math.floor(n / 100);

    if (crore > 0) {
      words += convertBelowThousand(crore) + " Crore ";
    }
    if (lakh > 0) {
      words += convertBelowThousand(lakh) + " Lakh ";
    }
    if (thousand > 0) {
      words += convertBelowThousand(thousand) + " Thousand ";
    }
    if (n > 0) {
      words += convertBelowThousand(n);
    }

    return words.trim();
  }

  return convertNumberToWords(num);
}

const Fundingscst = () => {
  const showAddInfo = localStorage.getItem("showInfo");
  const [formData, setFormData] = useState({
    fundHistory: [{}],
    startupfundutilization: [{}],
  });
  const {
    handleBackapp,
    navigator,
    modalstyle,
    setPageLoading,
    validateNumberonly,
  } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [readyOnlyBDDValue, setReadyOnlyBDDValue] = useState(false);
  const {
    fields: fundFields,
    append: fundAppend,
    remove: fundRemove,
  } = useFieldArray({
    control,
    name: "fundHistory",
  });

  const {
    fields: utilizationFields,
    append: utilizationAppend,
    remove: utilizationRemove,
  } = useFieldArray({
    control,
    name: "startupfundutilization",
  });

  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    dropdownValueApi();
    // eslint-disable-next-line
  }, []);
  const [externalFund, setExternalFund] = useState([]);
  const [utilizationDesc, setUtilizationDesc] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        setExternalFund(val.externalFunding);
        setUtilizationDesc(val.utilizationOfFunds);
      }
    );
  };
  const getApi = async () => {
    setFormData({});
    setPageLoading(true);
    await apiService(`startup/fundingandfinancials/get`, "", "get").then(
      (res) => {
        if (res) {
          setPageLoading(false);
          let response = res.data;
          if (response.id !== 0) {
            setFormData(response);
            // if (response.editForm === false) {
            //   setReadyOnlyValue(true);
            //   setReadyOnlyBDDValue(true);
            // } else {
            //   if (showAddInfo === "true") {
            //     setReadyOnlyValue(true);
            //   } else {
            //     setReadyOnlyValue(false);
            //   }
            //   setReadyOnlyBDDValue(false);
            // }
            if (response.editForm === false && response.superEdit === false  ) {
              debugger
              setReadyOnlyValue(true);
              setReadyOnlyBDDValue(true);
            } else {
              debugger
              if (showAddInfo === "true") {
                setReadyOnlyValue(true);
              }
              if(response.editForm === false && response.superEdit === true){
                setReadyOnlyValue(false);
              }
              if(response.editForm === true && response.superEdit === false){
                setReadyOnlyValue(false);
              }
              
              debugger
              setReadyOnlyBDDValue(false);
            }
            if (response.pitchDeck) {
              setIsUploaded(true);
              setPitchDeckValue(response.pitchDeck);
              setPitchDeckName(response.pitchDeck);
              setPitchDeckURL(response.pitchDeckName);
            }
          } else {
            setFormData(response);
          }
        }
      }
    );
  };
  const addFundDetailsBtn = () => {
    fundAppend({
      fundRaised: "",
      instrumentType: "",
      externalFunding: "",
      currencyValue: "",
    });
  };

  const addutilisationDetailsBtn = () => {
    utilizationAppend({
      expenses: "",
      amount: "",
      currencyValue: "",
    });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [preferredInvestorTypeValue, setPreferredInvestorTypeValue] = useState(
    []
  );
  const handleSelectPreferredInvestorType = (value) => {
    setPreferredInvestorTypeValue(value);
    setValue("preferredInvestorType", value);
    clearErrors("preferredInvestorType");
  };

  const handleSelectRevenues = (value) => {
    setValue("revenueStage", value);
    clearErrors("revenueStage");
  };

  // const [investTicketSizeValue, setInvestTicketSizeValue] = useState('');
  const handleSelectTicketSize = (value) => {
    // setInvestTicketSizeValue(value);
    setValue("investTicketSize", value);
    clearErrors("investTicketSize");
  };

  const [investmentInstrumentTypeValue, setInvestmentInstrumentTypeValue] =
    useState([]);
  const handleSelectInvestment = (value) => {
    setInvestmentInstrumentTypeValue(value);
    setValue("investmentInstrumentType", value);
    clearErrors("investmentInstrumentType");
  };

  // const [fundingProgramInvalid, setFundingProgramInvalid] = useState(false);

  const deleteUtilizationHistory = async (index) => {
    const id = getValues(`startupfundutilization.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`startup/fundutilization/remove?id=${id}`, "", "delete").then(
        (res) => {
          if (res) {
            if (res.data.responseStatus === "Success") {
              indexBasedremoveUtilization(index);
            }
          }
        }
      );
    } else {
      indexBasedremoveUtilization(index);
    }
  };
  const indexBasedremoveUtilization = (index) => {
    var startupfundutilization = getValues("startupfundutilization");
    if (startupfundutilization.length === 1 && index === 0) {
      var val = getValues();
      val.startupfundutilization = [
        {
          id: 0,
          expenses: "",
          amount: "",
          currencyValue: "",
        },
      ];
      setFormData(val);
    } else {
      utilizationRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  // delete Tractions
  const deleteFundingHistory = async (index) => {
    const id = getValues(`fundHistory.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`startup/fundhistory/remove?id=${id}`, "", "get").then(
        (res) => {
          if (res) {
            if (res.data.responseStatus === "Success") {
              indexBasedremoveFundHistory(index);
            }
          }
        }
      );
    } else {
      indexBasedremoveFundHistory(index);
    }
  };

  const indexBasedremoveFundHistory = (index) => {
    var fundHistory = getValues("fundHistory");
    if (fundHistory.length === 1 && index === 0) {
      var val = getValues();
      val.fundHistory = [
        {
          id: 0,
          fundRaised: "",
          instrumentType: "",
          externalFunding: "",
          currencyValue: "",
        },
      ];
      setFormData(val);
    } else {
      fundRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };

  const [pitchDeckValue, setPitchDeckValue] = useState(null);
  const [pitchDeckName, setPitchDeckName] = useState(null);
  const [pitchDeckURL, setPitchDeckURL] = useState(null);
  const [logoDisplayUrl, setLogoDisplayUrl] = useState("/images/user.png");
  const [logoDisplayValue, setLogoDisplayValue] = useState(null);
  const [logoDisplayName, setLogoDisplayName] = useState(null);
  // const [checkMethod, setCheckMethod] = useState('save')
  const [logoInvalid, setLogoInvalid] = useState(false);
  const inputRef = useRef(null);
  let logoselecetdFile = "";

  const handleFileUpload = (
    event,
    filekey,
    fileName,
    setdata,
    setName,
    setUrl,
    format
  ) => {
    debugger;
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (img_crt_type === "pdf") {
          setValue(filekey, "");
          setValue(fileName, "");
          setName("");
          setUrl("");
          if (event.target === undefined) {
            setdata(event);
          } else {
            setdata(event.target.files[0]);
          }
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            setUrl(logourl1);
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setName(img_name);
            clearErrors(filekey);
            clearErrors(fileName);
            if (filekey === "startupLogo") {
              setLogoInvalid(false);
            }
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    } else {
      // setValue(filekey, "")
      // setValue(fileName, "")
      // setName('')
      // setUrl('')
      // inputRef.current.value = '';
    }
  };

  const onSubmit = (data) => {
    // checkFundingValidation()
    if (readyOnlyBDDValue === false) {
      setPageLoading(true);
      var getform = data;
      debugger;
      apiService("startup/fundingandfinancials/save", getform, "post").then(
        (res) => {
          setPageLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              notifyService("success", "Success", "Saved successfully !!");
              navigator("/startup/founder/scst");
            }
          }
        }
      );
    }
  };
  const onPdfClick = () => {
    const pdfUrl =
      "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/SCST_Pitch_Deck_final.pptx";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "SCST_Pitch_Deck_Sample_Template.pptx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isWhitespace = (value) => {
    return /^\s*$/.test(value);
  };

  const isSpecialCharacter = (value) => {
    const regex = /[!@#$%^&*(),.?":{}|<>]/;
    return regex.test(value);
  };

  const handleFileClear = () => {
    setPitchDeckValue("");
    setPitchDeckName("");
    setPitchDeckURL("");
  };

  const [showExternalFunding, setShowExternalFunding] = useState(false);

  const fundtype = ["Internal Funding", "External Funding"];

  const handleSelectRole = (selectedOptions, value, index) => {
    setValue(`instrumentType`, value);
    clearErrors(`instrumentType`);

    // const selectedOption = selectedOptions[0];
    setShowExternalFunding(selectedOptions === "External Funding");
  };

  const handleSelectCurrency = (selectedOption, value, index) => {
    setValue("currencyValue", selectedOption);
    clearErrors("currencyValue");
  };

  const handleSelectCurrencyFund = (selectedOptions, value, index) => {
    setValue(`fundHistory.${index}.currencyValue`, value);
    clearErrors(`fundHistory.${index}.currencyValue`);
  };

  const handleSelectCurrencyUtilization = (selectedOptions, value, index) => {
    setValue(`currencyValue`, value);
    clearErrors(`currencyValue`);
  };

  const handleSelectExternalFunding = (value, index) => {
    setValue(`externalFunding`, value);
    clearErrors(`externalFunding`);
  };

  const handleSelectUtilization = (value, index) => {
    setValue(`utilizationDesc`, value);
    clearErrors(`utilizationDesc`);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const handleFileChange = (e) => {
    handleFileUpload(
      e,
      "pitchDeck",
      "pitchDeckName",
      setPitchDeckValue,
      setPitchDeckName,
      setPitchDeckURL,
      "file"
    );
    setIsUploaded(true);
  };

  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };

  const [revenueText, setRevenueText] = useState("");
  const [revenueText1, setRevenueText1] = useState("");
  const [revenueText2, setRevenueText2] = useState("");

  const watchedRevenue = watch("annualRevenue", "");
  useEffect(() => {
    const numberValue = parseFloat(watchedRevenue.replace(/,/g, ""));
    if (!isNaN(numberValue)) {
      const words = numberToWordsIndian(numberValue);
      setRevenueText(
        `${words.charAt(0).toUpperCase() + words.slice(1)} Rupees`
      );
    } else {
      setRevenueText("");
    }
  }, [watchedRevenue]);

  const watchedRevenue1 = watch("revenueTurnover", "");
  useEffect(() => {
    const numberValue = parseFloat(watchedRevenue1.replace(/,/g, ""));
    if (!isNaN(numberValue)) {
      const words = numberToWordsIndian(numberValue);
      setRevenueText1(
        `${words.charAt(0).toUpperCase() + words.slice(1)} Rupees`
      );
    } else {
      setRevenueText1("");
    }
  }, [watchedRevenue1]);

  const watchedRevenue2 = watch("expectedFunding", "");
  useEffect(() => {
    const numberValue = parseFloat(watchedRevenue2.replace(/,/g, ""));
    if (!isNaN(numberValue)) {
      const words = numberToWordsIndian(numberValue);
      setRevenueText2(
        `${words.charAt(0).toUpperCase() + words.slice(1)} Rupees`
      );
    } else {
      setRevenueText2("");
    }
  }, [watchedRevenue2]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      e.target.value = value;
    } else {
      e.target.value = value.replace(/[^\d]/g, "");
    }
  };
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              {" "}
              <img
                src="/images/Fig-back.png"
                className="img-fluid"
                alt=""
              />{" "}
            </span>{" "}
            <h6 className="ml-2 mb-0">Funding & Financials</h6>
          </div>
          <hr />
          <form
            className="signin-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              {/* <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">Product/Service Uniqueness</FormLabel>

                                        <Controller name="uniqueness" control={control} defaultValue="" rules={{ required: 'Revenue Generated  is required' }}
                                            render={({ field }) =>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="Innovation"
                                                    name="radio-buttons-group"
                                                    inputProps={
                                                        { readOnly: readyOnlyValue, }
                                                    }
                                                    {...field}
                                                >
                                                    <span className='d-flex'>
                                                        <FormControlLabel value="Innovation" control={<Radio />} label="Innovation" />
                                                        <FormControlLabel value="Invention" control={<Radio />} label="Invention" />
                                                        <FormControlLabel value="Upgradation" control={<Radio />} label="Upgradation" />
                                                    </span>
                                                </RadioGroup>} />
                                        <FormHelperText className='text-danger'>{errors.uniqueness && errors.uniqueness.message}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div> */}
              {/* <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="fundProjection" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label='Funding Projection'
                                                placeholder='Enter Funding Projection'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.fundProjection && errors.fundProjection.message}</FormHelperText>
                                </div>
                            </div> */}
              <div className="col-lg-6 col-12 mt-2">
                <div className="form-group mt-2">
                  <Controller
                    name="annualRevenue"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Revenue Generated is required",
                      validate: {
                        noWhitespace: (value) =>
                          !isWhitespace(value) ||
                          "Please enter a valid revenue",
                      },
                      pattern: {
                        // value: /^[a-zA-Z0-9\s]*$/,
                        value: /^[0-9]*$/,
                        message: "Only numbers are allowed",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label={
                          <>
                            <span>Revenue Generated (Last Year)</span>{" "}
                            <span>&#8377; *</span>
                          </>
                        }
                        placeholder="Enter Revenue Generated "
                        fullWidth
                        type="text"
                        // inputProps={
                        //     { readOnly: readyOnlyValue, }
                        // }
                        {...field}
                        error={!!errors.annualRevenue}
                        helperText={
                          errors.annualRevenue
                            ? errors.annualRevenue.message
                            : ""
                        }
                        inputProps={{
                          readOnly: readyOnlyValue,
                          onInput: handleInputChange,
                          maxLength: 10,
                        }}
                      />
                    )}
                  />
                  <div className="revenue-display">
                    {revenueText && <p className="text-right">{revenueText}</p>}
                  </div>
                  {/* <FormHelperText className='text-danger'>{errors.annualRevenue && errors.annualRevenue.message}</FormHelperText> */}
                </div>
              </div>
              {/* <div className="col-lg-4 mt-3">
                                <div className="form-group">
                                    <FormControl fullWidth>
                                        <Controller name="currencyValue" control={control} defaultValue="" rules={{ required: 'Fund Value is required' }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    options={currencyValue}
                                                    value={field.value}
                                                    onChange={(e, selectedOption) => {
                                                        field.onChange(selectedOption);
                                                        handleSelectCurrency(selectedOption)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Fund Value *" />
                                                    )}
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className='text-danger'>{errors.currencyValue && errors.currencyValue.message}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div> */}
              {showAddInfo === "true" && (
                <>
                  <div className="col-lg-6 col-12 mt-2">
                    <div className="form-group mt-2">
                      <FormControl fullWidth>
                        <Controller
                          name="revenueTurnover"
                          control={control}
                          defaultValue=""
                          rules={{
                            required:
                              "Total Funds Raised till Date is required",
                            validate: (value) =>
                              !isWhitespace(value) ||
                              "Please enter a valid revenue",
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Revenue/Turnover for all completed financial years *"
                              placeholder="Enter Revenue/Turnover"
                              fullWidth
                              {...field}
                              type="text"
                              inputProps={{
                                readOnly: readyOnlyBDDValue,
                                onInput: handleInputChange,
                                maxLength: 10,
                              }}
                            />
                          )}
                        />
                        <div className="revenue-display">
                          {revenueText1 && (
                            <p className="text-right">{revenueText1}</p>
                          )}
                        </div>
                        <FormHelperText className="text-danger">
                          {errors.revenueTurnover &&
                            errors.revenueTurnover.message}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                </>
              )}
              <div className="col-lg-6 col-12 ">
                <div className="form-group ">
                  <Controller
                    name="expectedFunding"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Funding Expected  is required",
                      validate: {
                        // noSpecialChars: value => !isSpecialCharacter(value) || 'Special characters are not allowed',
                        noWhitespace: (value) =>
                          !isWhitespace(value) ||
                          "Please enter a valid revenue",
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9\s]*$/,
                        message: "Only alphabets and numbers are allowed",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label={
                          <>
                            <span>Funding Expected</span> <span>&#8377; *</span>
                          </>
                        }
                        placeholder="Enter Funding Expected "
                        sx={{ marginTop: "15px" }}
                        fullWidth
                        type="text"
                        inputProps={{
                          readOnly: readyOnlyValue,
                          onInput: handleInputChange,
                          maxLength: 10,
                        }}
                        error={!!errors.expectedFunding}
                        helperText={
                          errors.expectedFunding
                            ? errors.expectedFunding.message
                            : ""
                        }
                        {...field}
                      />
                    )}
                  />
                  <div className="revenue-display">
                    {revenueText2 && (
                      <p className="text-right">{revenueText2}</p>
                    )}
                  </div>
                  {/* <FormHelperText className='text-danger'>{errors.expectedFunding && errors.expectedFunding.message}</FormHelperText> */}
                </div>
              </div>

              <div className="col-lg-6 col-12 mt-2">
                {getValues("pitchDeck") !== "" && (
                  <span>
                    Pitch Deck <span className="text-danger">*</span>
                  </span>
                )}
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="pitchDeck"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Pitch Deck is required" }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={handleFileChange}
                          value={pitchDeckValue}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("pitchDeck") !== ""
                                  ? "fileupload-pdf-dpiit-bg-active"
                                  : "fileupload-pdf-dpiit-bg"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("pitchDeck") !== ""
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("pitchDeck") !== ""
                                      ? "Uploaded (Click to Change)"
                                      : "Pitch Deck *"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .pdf
                    </FormHelperText>
                    {/* <FormHelperText>Maximum file size: 5 MB</FormHelperText> */}
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.pitchDeck && errors.pitchDeck.message}
                    </FormHelperText>
                  </div>
                  <span
                    className="pre-btn-dpiit"
                    onClick={() => handleClickOpen(pitchDeckURL)}
                  >
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link
                      // to={`${pitchDeckURL}`}
                      // target="_blank"

                      style={{ color: "#80809e" }}
                    >
                      {" "}
                      Preview
                    </Link>
                  </span>
                  {/* <span className="pre-btn-dpiit"> <DeleteIcon className='icon-size' /></span> */}
                </div>
                <div>
                  {/* <span className='c-blue c-pointer ml-2' onClick={onPdfClick} >Sample Pitch Deck Template <PictureAsPdfIcon className="c-red" /></span> */}
                  <span className="c-blue c-pointer ml-2" onClick={onPdfClick}>
                    Sample Pitch Deck Template{" "}
                    <i
                      className="fas fa-file-powerpoint"
                      style={{ fontSize: "20px", color: "#D04423" }}
                    ></i>
                  </span>
                </div>
              </div>
              {/* <div className='col-lg-6 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="utilizationDesc" control={control} defaultValue="" rules={{ required: 'Total Funds Raised till Date is required', validate: value => !isWhitespace(value) || 'Please enter a valid revenue' }}
                                        render={({ field }) =>

                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                options={utilizationDesc}
                                                onChange={(e, selectedOptions) => {
                                                    field.onChange(selectedOptions);
                                                    handleSelectUtilization(selectedOptions)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="External Funding*" />
                                                )}
                                                disabled={readyOnlyValue}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.utilizationDesc && errors.utilizationDesc.message}</FormHelperText>
                                </div>
                            </div> */}
              {showAddInfo === "true" && (
                <>
                  <Accordion className="Addfundacc-bor" expanded={true}>
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="Addfundacc"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: 0,
                        }}
                      >
                        <AddCircleIcon className="add-icon mr-3" /> Previous
                        Fundraising History
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="col-12 mb-3">
                          <div>
                            {fundFields.length >= 0 && (
                              <TableContainer
                                component={Paper}
                                className="table-back"
                              >
                                <Table aria-label="caption table">
                                  <TableBody>
                                    {fundFields.map((field, index, type) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "25%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`fundHistory.${index}.fundRaised`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Fund Raised is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Fund Raised (₹)*"
                                                  placeholder="Enter Fund Raised (₹)"
                                                  fullwidth
                                                  {...field}
                                                  type="text"
                                                  onKeyDown={(e) =>
                                                    validateNumberonly(e)
                                                  }
                                                  inputProps={{
                                                    readOnly: readyOnlyBDDValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.fundHistory?.[index]
                                                ?.fundRaised &&
                                                errors.fundHistory?.[index]
                                                  ?.fundRaised.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        {/* <TableCell sx={{ width: '25%', borderBottom: 'none', padding: '5px' }}>
                                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                                <div className="form-group mt-3">
                                                                                                    <FormControl fullWidth>
                                                                                                        <Controller name={`fundHistory.${index}.currencyValue`} control={control} defaultValue="" rules={{ required: 'Fund Value is required' }}
                                                                                                            render={({ field }) =>
                                                                                                                <Autocomplete
                                                                                                                    disableClearable
                                                                                                                    disablePortal
                                                                                                                    {...field}
                                                                                                                    options={currencyValue}
                                                                                                                    onChange={(e, selectedOptions) => {
                                                                                                                        field.onChange(selectedOptions);
                                                                                                                        handleSelectCurrencyFund(selectedOptions)
                                                                                                                    }}
                                                                                                                    renderInput={(params) => (
                                                                                                                        <TextField {...params} label="Fund Value *" />
                                                                                                                    )}
                                                                                                                    disabled={readyOnlyValue}
                                                                                                                />
                                                                                                            } />
                                                                                                        <FormHelperText className='text-danger'>
                                                                                                            {errors.fundHistory?.[index]?.currencyValue && errors.fundHistory?.[index]?.currencyValue.message}
                                                                                                        </FormHelperText>
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </FormControl>
                                                                                        </TableCell> */}
                                        <TableCell
                                          sx={{
                                            width: "25%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`fundHistory.${index}.instrumentType`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Instrument Type is required",
                                              }}
                                              render={({ field }) => (
                                                <Autocomplete
                                                  disableClearable
                                                  disablePortal
                                                  {...field}
                                                  options={fundtype}
                                                  onChange={(
                                                    e,
                                                    selectedOptions
                                                  ) => {
                                                    field.onChange(
                                                      selectedOptions
                                                    );
                                                    handleSelectRole(
                                                      selectedOptions
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label="Fund Type *"
                                                    />
                                                  )}
                                                  disabled={readyOnlyBDDValue}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.fundHistory?.[index]
                                                ?.instrumentType &&
                                                errors.fundHistory?.[index]
                                                  ?.instrumentType.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "25%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          {getValues(
                                            `fundHistory.${index}.instrumentType`
                                          ) === "External Funding" && (
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`fundHistory.${index}.externalFunding`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required:
                                                    "External Funding is required",
                                                }}
                                                render={({ field }) => (
                                                  <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    options={externalFund}
                                                    onChange={(
                                                      e,
                                                      selectedOptions
                                                    ) => {
                                                      field.onChange(
                                                        selectedOptions
                                                      );
                                                      handleSelectExternalFunding(
                                                        selectedOptions
                                                      );
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label="External Funding*"
                                                      />
                                                    )}
                                                    disabled={readyOnlyBDDValue}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.fundHistory?.[index]
                                                  ?.externalFunding &&
                                                  errors.fundHistory?.[index]
                                                    ?.externalFunding.message}
                                              </FormHelperText>
                                            </FormControl>
                                          )}
                                          {getValues(
                                            `fundHistory.${index}.instrumentType`
                                          ) === "Internal Funding" && (
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`fundHistory.${index}.remarks`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required:
                                                    "Remarks is required",
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Remarks*"
                                                    placeholder="Enter Remarks"
                                                    fullwidth
                                                    {...field}
                                                    inputProps={{
                                                      readOnly:
                                                        readyOnlyBDDValue,
                                                    }}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors.fundHistory?.[index]
                                                  ?.remarks &&
                                                  errors.fundHistory?.[index]
                                                    ?.remarks.message}
                                              </FormHelperText>
                                            </FormControl>
                                          )}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyBDDValue === false ? (
                                                deleteFundingHistory(index)
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <center>
                                  <Button
                                    className="add-more-btn"
                                    disabled={readyOnlyBDDValue}
                                    onClick={() => addFundDetailsBtn("")}
                                  >
                                    <AddCircleIcon className="mr-2" />
                                    Add More
                                  </Button>
                                </center>
                              </TableContainer>
                              //     ) : (
                              // <div className='nodata_tag'>
                              //     <p>Fundraising history details is empty</p>
                              // </div>
                            )}
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className="Addfundacc-bor" expanded={true}>
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="Addfundacc"
                    >
                      <Typography>
                        <p className="m-0">
                          {" "}
                          <AddCircleIcon className="add-icon mr-3" />{" "}
                          Utilization of Funds
                        </p>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="col-12 my-3">
                          {utilizationFields.length >= 0 && (
                            <TableContainer
                              component={Paper}
                              className="table-back"
                            >
                              <Table aria-label="caption table">
                                <TableBody>
                                  {utilizationFields.map(
                                    (field, index, type) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "35%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`startupfundutilization.${index}.expenses`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Utilization of Funds is required",
                                              }}
                                              render={({ field }) => (
                                                <Autocomplete
                                                  disableClearable
                                                  disablePortal
                                                  {...field}
                                                  options={utilizationDesc}
                                                  onChange={(
                                                    e,
                                                    selectedOptions
                                                  ) => {
                                                    field.onChange(
                                                      selectedOptions
                                                    );
                                                    handleSelectUtilization(
                                                      selectedOptions
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label="Utilization of Funds *"
                                                    />
                                                  )}
                                                  disabled={readyOnlyBDDValue}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.startupfundutilization?.[
                                                index
                                              ]?.expenses &&
                                                errors.startupfundutilization?.[
                                                  index
                                                ]?.expenses.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            width: "35%",
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`startupfundutilization.${index}.amount`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Amount is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Amount*"
                                                  placeholder="Enter Amount"
                                                  fullwidth
                                                  {...field}
                                                  type="text"
                                                  onKeyDown={(e) =>
                                                    validateNumberonly(e)
                                                  }
                                                  inputProps={{
                                                    readOnly: readyOnlyBDDValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.startupfundutilization?.[
                                                index
                                              ]?.amount &&
                                                errors.startupfundutilization?.[
                                                  index
                                                ]?.amount.message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                            padding: "5px",
                                          }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            sx={{
                                              backgroudColor: "#F4F4F4",
                                              fontSize: "24px",
                                              fill: "#808080",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyBDDValue === false ? (
                                                deleteUtilizationHistory(index)
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <center>
                                <Button
                                  className="add-more-btn"
                                  disabled={readyOnlyBDDValue}
                                  onClick={() => addutilisationDetailsBtn("")}
                                >
                                  <AddCircleIcon className="mr-2" />
                                  Add More
                                </Button>
                              </center>
                            </TableContainer>
                          )}
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </div>
            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button
                variant="contained"
                onClick={handleBackapp}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyBDDValue === false ? (
                <Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </Button>
              ) : (
                <></>
              )}
            </div>
          </form>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalstyle} className="form_tag_lg">
              {/* <Fundingprograms handleClose={handleClose} fundingProgramDetails={fundingProgramDetails} setFundingProgramDetails={setFundingProgramDetails} fundingProgramIndex={fundingProgramIndex} fundingProgramsList={fundingProgramsList} /> */}
            </Box>
          </Modal>
        </div>
        <Viewimage
          viewImage={viewImage}
          open={open}
          handleClose={handleClose}
        />
      </section>
    </>
  );
};

export default Fundingscst;

import React, { useState } from 'react';
import { Container, FormHelperText } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../api/apiService';
import notifyService from '../../api/notifySerivce';
import cryptoJS from '../../hooks/cryptoJS';
const Changepassword = ({ handlePasswordClose, passLable }) => {
    const { handleSubmit, control, reset, formState: { errors } } = useForm({});
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showRePassword, setShowRePassword] = React.useState(false);
    const handleClickShowRePassword = () => setShowRePassword((show) => !show);
    const [loading, setLoading] = useState(false);
    const [checkSamePassword, setCheckSamePassword] = useState(false)
    const onSubmit = (data) => {
        if (data.newPassword === data.reenterPassword) {
            setLoading(true);
            setCheckSamePassword(false)
            var getform = data
            if (passLable === 'OTP') {
                getform.oldPassword = localStorage.getItem('crp')
            } else {
                getform.oldPassword = cryptoJS(data.oldPassword)
            }
            getform.newPassword = cryptoJS(data.newPassword)
            apiService(`userprofile/changepassword`, getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        // localStorage.setItem('forcePassword', 'false')
                        if (passLable !== 'OTP') {
                            notifyService('success', 'Success', 'Password changed successfully !!')
                        } else {
                            notifyService('success', 'Success', '')
                        }

                        handleCancel()
                    }
                }
            })
        } else {
            setCheckSamePassword(true)
        }

    }
    const handleCancel = () => {
        reset({})
        handlePasswordClose()
    }
    return (
        <section className=''>
            <Container>
                <div className='header_tag text-center'>
                    <h5 className='c-black'>Change Password</h5>
                </div>
                <div className=''>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            {
                                (passLable === 'Old Password') && (
                                    <div className='col-lg-12 col-12 mb-3'>
                                        <div className="form-group mb-3">
                                            <Controller name="oldPassword" control={control} defaultValue="" rules={{ required: 'Old Password is required' }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant='outlined'
                                                        label="Old Password"
                                                        placeholder="Enter Old Password"
                                                        autoFocus
                                                        fullWidth
                                                        type='password'
                                                        {...field}
                                                    />} />
                                            <FormHelperText className='text-danger'>{errors.oldPassword && errors.oldPassword.message}</FormHelperText>
                                        </div>
                                    </div>
                                )
                            }

                            <div className='col-lg-6 col-12 mb-3'>
                                <div className="form-group mb-3">
                                    <Controller name="newPassword" control={control} defaultValue="" rules={{ required: 'New Password is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="New Password *"
                                                placeholder='Enter New Password'
                                                fullWidth
                                                autoComplete='password'
                                                {...field}
                                                error={!!errors?.password}
                                                helperText={errors?.password ? errors.password.message : null}
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment:
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                        >{showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                }}
                                            />
                                        }
                                    />
                                    <FormHelperText className='text-danger'>{errors.newPassword && errors.newPassword.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 mb-3'>
                                <div className="form-group mb-3">
                                    <Controller name="reenterPassword" control={control} defaultValue="" rules={{ required: 'Re-Enter Password is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Re-Enter Password *"
                                                placeholder='Enter Re-Enter Password'
                                                fullWidth
                                                autoComplete='password'
                                                {...field}
                                                error={!!errors?.password}
                                                helperText={errors?.password ? errors.password.message : null}
                                                type={showRePassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment:
                                                        <IconButton
                                                            onClick={handleClickShowRePassword}
                                                        >{showRePassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                }}
                                            />
                                        }
                                    />
                                    <FormHelperText className='text-danger'>{errors.reenterPassword && errors.reenterPassword.message}</FormHelperText>
                                    <FormHelperText className='text-danger'>{(checkSamePassword === true ? ('Password Mismatch') : (<></>))}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3"
                            >
                                <span>Save</span>
                            </LoadingButton>
                            {
                                (passLable === 'Old Password') && (<Button variant="contained" onClick={handlePasswordClose} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>)
                            }

                        </div>
                    </form>
                </div>
            </Container>
        </section>
    )
}

export default Changepassword
import React, { useContext } from "react";
import { useState } from "react";
import DataContext from "../../context/DataContext";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import useDidMountEffect from "../../hooks/useDidMountEffect";

const DeletePop = ({ deleteApi, deleteIndex, deleteId, handleClosePop }) => {
  const { openPop, setOpenPop } = useContext(DataContext);
  useDidMountEffect(() => {}, [openPop]);

  const handleStatusSave = () => {
    deleteApi(deleteIndex, deleteId);
    setOpenPop(false);
  };
  return (
    <Dialog open={openPop} maxWidth="sm" fullWidth={true}>
      <Box>
        <form className="text-center">
          <DialogContent>
            <img src="/images/04-06-10-848_512.webp" className="are_you" />
            <h3>Are yor sure</h3>
          </DialogContent>
          <DialogActions>
            <div className="text-center mb-3 w-100">
              <Button onClick={handleClosePop} className="discard-btn">
                No
              </Button>
              <Button onClick={handleStatusSave} className="publish-button">
                {" "}
                Yes
              </Button>
            </div>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default DeletePop;

import React from 'react'
import apiService from '../../../../api/apiService';
import { useForm, useFormState } from 'react-hook-form';
import { Box, Button, FormHelperText, InputAdornment, Modal, TextField } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import notifyService from '../../../../api/notifySerivce';
const Sendotp = ({handleCheck}) => {
    // const { modalstyle, handleClose, open, handleOpen, navigator } = useContext(DataContext)
    const { register, handleSubmit, control } = useForm();
    const { errors } = useFormState({ control });
    const onSubmit = async (data) => {
        var getform = data        
        await apiService('user/forgotpassword', getform, 'unauthpost').then((res) => {
            if (res) {
                localStorage.setItem('StartupId', res.data.id)            
                notifyService('success', 'Success', 'OTP send your mail id')
                handleCheck()
            }
        })

    };
    return (
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-3">
                <TextField
                    variant='outlined'
                    label="Username / Email"
                    fullWidth
                    autoComplete='userName'
                    placeholder='Enter Username / Email'
                    autoFocus
                    {...register("userName", {
                        required: "Username / Email required",
                    })}
                    error={!!errors?.userName}
                    helperText={errors?.userName ? errors.userName.message : null}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className="form-group">
                <Button type="submit" variant="contained" className="form-control btn btn-primary submit px-3">Send OTP</Button>
            </div>
        </form>
    )
}

export default Sendotp
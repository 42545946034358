import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import apiService from '../../../../../../api/apiService';
import notifyService from '../../../../../../api/notifySerivce';
import Paper from '@mui/material/Paper';
import useDidMountEffect from '../../../../../../hooks/useDidMountEffect';
const Reviewroundsave = ({ data,idNumber }) => {
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, formState: { errors } } = useForm(
        { values: formData, }
    );
    useDidMountEffect(() => {
        setFormData(data)
    }, [data]);
    const onSubmit = (data) => {        
        var req = {
            "id": data.id,
            "startupId": idNumber,
            "partnerId": data.partnerId,
            "mentorTheStartup": data.mentorTheStartup,
            "score": data.score,
            "remarks": data.remarks,
            "recommend": data.recommend,
            "finalScore": data.finalScore,
        }        
        apiService('jury/panelround/review/save', req, 'post').then((res) => {            
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                }
            }
        })
    }
    return (
        <div>
            <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-lg-6 col-12'>
                        <FormLabel>Would you like to mentor this startup ? *</FormLabel>
                        <Controller name='mentorTheStartup' control={control} defaultValue="" rules={{ required: 'Mentor the startup is required' }}
                            render={({ field }) =>
                                <RadioGroup
                                    row
                                    {...field}
                                >
                                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="false" control={<Radio />} label="No" />
                                </RadioGroup>
                            } />
                        <FormHelperText className='text-danger'>{errors.mentorTheStartup && errors.mentorTheStartup.message}</FormHelperText>
                    </div>
                    <div className='col-lg-6 col-12'>
                        <FormControl sx={{ width: '100%' }} >
                            <Controller name='score' control={control} defaultValue="" rules={{ required: 'Score is required' }}
                                render={({ field }) =>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Score *"
                                        placeholder='Enter Score'
                                        fullwidth
                                        type="number"
                                        {...field}
                                    />} />
                            <FormHelperText className='text-danger'>
                                {errors.score && errors.score.message}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <div className='col-lg-6 col-12'>
                        <FormLabel>Do you recommend this startup ? *</FormLabel>
                        <Controller name='recommend' control={control} defaultValue="" rules={{ required: 'Recommend is required' }}
                            render={({ field }) =>
                                <RadioGroup
                                    row
                                    {...field}
                                >
                                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="false" control={<Radio />} label="No" />
                                </RadioGroup>
                            } />
                        <FormHelperText className='text-danger'>{errors.recommend && errors.recommend.message}</FormHelperText>
                    </div>
                    <div className='col-lg-6 col-12'>
                        <FormControl sx={{ width: '100%' }} >
                            <Controller name='remarks' control={control} defaultValue="" rules={{ required: 'Score is required' }}
                                render={({ field }) =>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Remarks *"
                                        placeholder='Enter remarks'
                                        fullwidth
                                        {...field}
                                    />} />
                            <FormHelperText className='text-danger'>
                                {errors.remarks && errors.remarks.message}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <div className='col-lg-12 col-12'>
                        {
                            (data.startupJury.length) ? (
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>No</TableCell>
                                                <TableCell>Jury Name</TableCell>
                                                <TableCell align='center'>Mentoring the startup</TableCell>
                                                <TableCell align='center'>Score</TableCell>
                                                <TableCell align='center'>Remarks </TableCell>
                                                <TableCell align='center'>Recommend</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.startupJury.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align='center'>{index + 1}</TableCell>
                                                    <TableCell>{list.juryName}</TableCell>
                                                    <TableCell align='center'>{(list.mentorTheStartup === true) ? (<>Yes</>) : (<>No</>)}</TableCell>
                                                    <TableCell align='center'>{list.score}</TableCell>
                                                    <TableCell align='center'>{list.remarks}</TableCell>
                                                    <TableCell align='center'>{(list.recommend === true) ? (<>Yes</>) : (<>No</>)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            ) : (
                                <div className='nodata_tag'>
                                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                    <h4>Startup list is empty</h4>
                                </div>
                            )
                        }
                    </div>
                    <div className='col-lg-12 col-12 text-right mt-3'>
                        <Button type="submit" variant="contained">
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Reviewroundsave
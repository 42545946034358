import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText,  TextField } from '@mui/material';
import notifyService from '../../../api/notifySerivce';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
const ICStartupRemarks = ({ id, comments, listApi }) => {
    const { setPageLoading } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, reset, formState: { errors } } = useForm({ values: formData });
    const [openModal, setOpenModal] = useState(false);
    const assignStatusBtn = () => {
        setOpenModal(true);
        if (comments) {
            setFormData({
                remarks: comments
            })
        }
    }
    const onSubmit = (data) => {
        setPageLoading(true)        
        var req = {
            id: id,
            remarks: data.remarks,
        };        
        apiService('startup/remarks/save', req, "post").then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    listApi()
                    handleCloseModal()
                    notifyService("success", "Success", "");
                }
            }
        });
    }
    const onError = (data) => {
        notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
    }
    const handleCloseModal = () => {
        reset()
        setOpenModal(false);
    };
    return (
        <>
            <Button onClick={() => assignStatusBtn()}>View / Add</Button>
            <Dialog open={openModal} maxWidth="lg">
                <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className='d-flex justify-content-between'>
                        <DialogTitle>Remarks</DialogTitle>
                        <CloseIcon onClick={handleCloseModal} className='m-3 c-pointer' />
                    </div>
                    <DialogContent>
                        <div className='row' >
                            <div className='col-12'>
                                <Controller name="remarks" control={control} defaultValue="" rules={{ required: `Remarks is required` }}
                                    render={({ field }) =>
                                        <TextField
                                            placeholder='Enter Remarks'
                                            fullWidth
                                            type='text'
                                            {...field}
                                            multiline
                                            rows={4}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.remarks && errors.remarks.message}</FormHelperText>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions >
                        <div className='text-center mb-3 w-100'>
                            <Button onClick={handleCloseModal} variant="outlined" className='form-btn'>Close</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className='form-btn b-green-btn ml-3'
                            >
                                <span>Save</span>
                            </Button>
                        </div>

                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}


export default ICStartupRemarks
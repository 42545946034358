import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Box, Accordion, AccordionDetails, style, Switch, AccordionSummary, Button, styled, FormControl, FormControlLabel, Paper, MenuItem, FormHelperText, FormLabel, IconButton, InputAdornment, InputLabel, Typography, Modal, OutlinedInput, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Autocomplete, Container } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
// import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
// import PreliminaryTextPart1 from './PreliminaryTextPart1';
const fileTypes = ["pdf"];
// const fileTypesExcel = ["pdf", "XLSX"];


const FddSectionI = () => {
  const [formData, setFormData] = useState({});
  const [isAccordionOpen1, setIsAccordionOpen1] = useState(true);
  const { handleBackapp, setPageLoading, AntSwitch, validateNumberonly,navigator } = useContext(DataContext)
  const { handleSubmit, setValue, watch, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const {
    fields: startupCashPaymentsFields,
    append: startupCashPaymentsAppend,
    remove: startupCashPaymentsRemove
  } = useFieldArray({
    control,
    name: "startupCashPayment"
  });
  const addstartupCashPaymentsBtn = () => {
    startupCashPaymentsAppend({
      id: 0,
      name: "",
      receipt: "",
      receiptName: "",
      receiptUrl: "",
      custody: ""
    })
  }
  const deletestartupCashPaymentsBtn = async (index) => {
    const id = getValues(`startupCashPayment.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`startup/fdd/seci/startupCashPayment/remove?id=${id}`, '', 'delete').then((res) => {
        if (res) {
          if (res.data.responseStatus === 'Success') {
            indexBaseDeletestartupCashPayments(index)
          }
        }
      })
    } else {
      indexBaseDeletestartupCashPayments(index)
    }
  }
  const indexBaseDeletestartupCashPayments = (index) => {
    var startupTeams = getValues('startupCashPayment')
    if (startupTeams.length === 1 && index === 0) {
      // var val = getValues()
      // val.startupCashPayment = [{
      // id: 0,
      // name: "",
      // receipt: "",
      // receiptName: "",
      // receiptUrl: "",
      // custody: ""
      // }]
      // setFormData(val)
      startupCashPaymentsRemove(index)
      var val = getValues()
      setFormData(val)
    } else {
      startupCashPaymentsRemove(index)
      var val = getValues()
      setFormData(val)
    }
  }
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [fileUploadId, setFileUploadId] = useState(0)
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/seci/get`, '', 'get').then((res) => {
      if (res && res.data) {
        var data = res.data
        if (data.editForm === false) {
          setReadyOnlyValue(true)
        }
        if (res.data.id) {
          setFileUploadId(res.data.id)
        }

        setFormData(res.data)
      }
      setPageLoading(false)
    })
  }
  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type
        var imagedatatype = imagetype.split("/")
        var img_crt_type = imagedatatype[1]
        if (
          ((format === 'file') && (img_crt_type === "pdf")) ||
          ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
        ) {
          setValue(filekey, "")
          setValue(fileName, "")

          var fileValue = logoselecetdFile
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(',')
            var ImageValue = spl[1]
            var img_name = fileValue.name
            setValue(filekey, ImageValue)
            setValue(fileName, img_name)
            setValue(fileURL, logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId
            }
            console.log(req);
            // saveData(req)
          }
        } else {
          notifyService('danger', 'File Format Invalided', 'Please check your file format')
        }
      }
    }
  }
  const cashPaymentsReceipts = watch("cashPaymentsReceipts", false);
  const cashBalanceTallied = watch("cashBalanceTallied", false)
  const cashBalanceAndTransitInsured = watch("cashBalanceAndTransitInsured", false)
  const hasCashPaymentsToEmployees = watch("hasCashPaymentsToEmployees", false)
  const hasMultipleOperationPlaces = watch("hasMultipleOperationPlaces", false)
  const hasCashReceiptsAtEachLocation = watch("hasCashReceiptsAtEachLocation", false)
  const cashBalanceInsuredAtAllLocations = watch("cashBalanceInsuredAtAllLocations", false)
  // const relatedPartyDebtors = watch("relatedPartyDebtors", false)
  const handleFilterCheck = (value, key, file, name, url) => {
    setValue(key, value);
    if (!value) {
      setValue(key, "");
      setValue(file, "");
      setValue(name, "");
      setValue(url, "");
    }
  };
  const handleFilter = (value, key) => {
    setValue(key, value);
    if (!value) {
      setValue(key, "");
    }
  };

  const saveData = (req) => {
    console.log(req);
    apiService('startup/ldd/sectionone/save', req, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          getApi()
        }
      }
    })
  }

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    setPageLoading(true);
    // if (data.shareHoldingPattern) {
    //   data.shareHoldingPattern.forEach(element => {
    //     if (element.date) {
    //       element.date = element.date.format('YYYY-MM-DD');
    //     }
    //   });
    // }
    // var req = {
    //   id: fileUploadId,
    //   directorsInterestedCompanies: data.directorsInterestedCompanies,
    //   shareHoldingPattern: data.shareHoldingPattern,
    //   sharesSubjectedToPledge: data.sharesSubjectedToPledge
    // }
    console.log(data);
    apiService('startup/fdd/seci/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-j");
        }
      }
    })
  }
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Cash Balance</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className=''>
              <div className="row doc-row">
                <div className='col-12'>
                  <div className='doc-title mt-3'>
                    <h6>Cash Balance :</h6>
                  </div>
                </div>
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Do you have cash payments and receipts</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={cashPaymentsReceipts}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilter(!cashPaymentsReceipts, `cashPaymentsReceipts`)}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>What are the general cash payments and receipts transactions</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="cashTransactions"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Cash Transactions"
                          placeholder="Enter Cash Transactions"
                          fullWidth
                          {...field}
                          type='text'
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.cashTransactions && errors.cashTransactions.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>Who is incharge of handling cash receipts, payments and safe custody</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="cashHandlerIncharge"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Cash Handler Incharge"
                          placeholder="Enter Cash Handler Incharge"
                          fullWidth
                          {...field}
                          type='text'
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.cashHandlerIncharge && errors.cashHandlerIncharge.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Is the closing cash balance tallied with the books of accounts every day , if not at what frequency</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={cashBalanceTallied}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilterCheck(!cashBalanceTallied, `cashBalanceTallied`, 'cashBalanceTallyFrequency', '', '')}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {
                  cashBalanceTallied === true && (
                    <div className="col-lg-4 col-12">
                      <FormLabel>What frequency</FormLabel>
                      <div className="form-group mt-2">
                        <Controller
                          name="cashBalanceTallyFrequency"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Cash Balance Tally Frequency"
                              placeholder="Enter Cash Balance Tally Frequency"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.cashBalanceTallyFrequency && errors.cashBalanceTallyFrequency.message}
                        </FormHelperText>
                      </div>
                    </div>
                  )
                }
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Is the cash balance at office and cash in transit insured?</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={cashBalanceAndTransitInsured}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilterCheck(!cashBalanceAndTransitInsured, `cashBalanceAndTransitInsured`, 'cashBalanceOnDDCutOff', '', '')}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {
                  cashBalanceAndTransitInsured === false && (
                    <div className="col-lg-4 col-12">
                      <FormLabel>What is the cash balance as on DD cut off date?</FormLabel>
                      <div className="form-group mt-2">
                        <Controller
                          name="cashBalanceOnDDCutOff"
                          control={control}
                          defaultValue=""
                          rules={{ required: 'What is the cash balance as on DD cut off date is required' }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Cash Balance On DD Cut Off"
                              placeholder="Enter Cash Balance On DD Cut Off"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.cashBalanceOnDDCutOff && errors.cashBalanceOnDDCutOff.message}
                        </FormHelperText>
                      </div>
                    </div>
                  )
                }
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Do you have a system of making cash payments to employees towards advance for expenses</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={hasCashPaymentsToEmployees}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilterCheck(!hasCashPaymentsToEmployees, `hasCashPaymentsToEmployees`, 'expenseSettlementTimeLimit', '', '')}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {
                  hasCashPaymentsToEmployees === false && (
                    <div className="col-lg-4 col-12">
                      <FormLabel>If so, What is the time limit for getting the settlement statements from employees as per Company Policy</FormLabel>
                      <div className="form-group mt-2">
                        <Controller
                          name="expenseSettlementTimeLimit"
                          control={control}
                          defaultValue=""
                          rules={{ required: 'Expense Settlement Time Limit is required' }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Expense Settlement Time Limit"
                              placeholder="Enter Expense Settlement Time Limit"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.expenseSettlementTimeLimit && errors.expenseSettlementTimeLimit.message}
                        </FormHelperText>
                      </div>
                    </div>
                  )
                }
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Do you have multiple places of operations?</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={hasMultipleOperationPlaces}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilterCheck(!hasMultipleOperationPlaces, `hasMultipleOperationPlaces`, 'pettyCashOrganization', '', '')}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {
                  hasMultipleOperationPlaces === false && (
                    <div className="col-lg-4 col-12">
                      <FormLabel>If so, how do you organise for petty cash expenses/cash payments at each location</FormLabel>
                      <div className="form-group mt-2">
                        <Controller
                          name="pettyCashOrganization"
                          control={control}
                          defaultValue=""
                          rules={{ required: 'Petty Cash Organization is required' }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Petty Cash Organization"
                              placeholder="Enter Petty Cash Organization"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pettyCashOrganization && errors.pettyCashOrganization.message}
                        </FormHelperText>
                      </div>
                    </div>
                  )
                }
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>In case of multiple places of operations, is there cash receipts at each place</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={hasCashReceiptsAtEachLocation}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilterCheck(!hasCashReceiptsAtEachLocation, `hasCashReceiptsAtEachLocation`, 'cashDepositFrequency', '', '')}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {
                  hasCashReceiptsAtEachLocation === false && (
                    <div className="col-lg-4 col-12">
                      <FormLabel>If so, at what frequency the cash is deposited in to bank</FormLabel>
                      <div className="form-group mt-2">
                        <Controller
                          name="cashDepositFrequency"
                          control={control}
                          defaultValue=""
                          rules={{ required: 'Cash Deposit Frequency is required' }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Cash Deposit Frequency"
                              placeholder="Enter Cash Deposit Frequency"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.cashDepositFrequency && errors.cashDepositFrequency.message}
                        </FormHelperText>
                      </div>
                    </div>
                  )
                }
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Is the cash balance and cash in transit is insured at all the locations</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={cashBalanceInsuredAtAllLocations}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilter(!cashBalanceInsuredAtAllLocations, `cashBalanceInsuredAtAllLocations`)}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 mt-2'>
                  <FormLabel>Provide Physical Verfication of Cash Statement as on DD Cut off Date</FormLabel>
                  <div className="d-flex align-items-center">
                    <div className='form-group my-1'>
                      <Controller name="cashStatementVerfication" control={control} defaultValue="" rules={{ required: false }}
                        render={({ field }) =>

                          <FileUploader
                            {...field}
                            handleChange={(e) => handleFileUpload(e,
                              "cashStatementVerfication",
                              "cashStatementVerficationName",
                              "cashStatementVerficationURL",
                              'file')}
                            value={getValues('cashStatementVerfication')}
                            name="file"
                            types={fileTypes}
                            children={
                              <div className={getValues('cashStatementVerfication') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                <center>
                                  <p className={getValues('cashStatementVerfication') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                    <span>
                                      <AttachFileIcon className="file-icon mr-2" />
                                      {getValues('cashStatementVerfication') !== '' ? 'Uploaded (Click to Change)' : 'Cash Statement Verfication Document'}
                                    </span>
                                  </p>
                                </center>
                              </div>
                            }
                            disabled={readyOnlyValue}
                          />
                        } />
                      <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                      <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.cashStatementVerfication && errors.cashStatementVerfication.message}</FormHelperText>
                    </div>
                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                      onClick={() => handleClickOpen(getValues('cashStatementVerficationURL'))}
                      style={{ color: "#80809e" }}
                    > Preview
                    </Link>
                    </span>
                  </div>
                </div>



                <div className="col-lg-12 col-12 mb-3 pl-3">
                  <Accordion className='Addfundacc-bor' expanded={isAccordionOpen1} onChange={() => setIsAccordionOpen1(!isAccordionOpen1)}>
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className='Addfundacc'
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0
                      }}>
                        <AddCircleIcon className='add-icon mr-3' /> Provide the list of locations and person in charge of handling cash payments and receipts and safe custoday of cash
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className='col-12 mb-3'>
                          <div>
                            {
                              (startupCashPaymentsFields.length >= 0) && (
                                <TableContainer component={Paper} className='table-back'>
                                  <Table aria-label="caption table">
                                    <TableBody >
                                      {startupCashPaymentsFields.map((field, index) => (
                                        <TableRow key={index}>
                                          <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                          <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                            <FormControl sx={{ width: '100%' }} >
                                              <Controller name={`startupCashPayment.${index}.name`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                                render={({ field }) =>
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Name *"
                                                    placeholder='Enter Name'
                                                    fullwidth
                                                    {...field}
                                                    type='text'
                                                    inputProps={{ readOnly: readyOnlyValue }}
                                                  />
                                                } />
                                              <FormHelperText className='text-danger'>
                                                {errors.startupCashPayment?.[index]?.name && errors.startupCashPayment?.[index]?.name.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                            <FormControl sx={{ width: '100%' }} >
                                              <Controller name={`startupCashPayment.${index}.custody`} control={control} defaultValue="" rules={{ required: 'Safe Custody is required' }}
                                                render={({ field }) =>
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Safe Custody *"
                                                    placeholder='Enter Safe Custody'
                                                    fullwidth
                                                    {...field}
                                                    type='text'
                                                    inputProps={{ readOnly: readyOnlyValue }}
                                                  // onKeyDown={(e) => validateNumberonly(e)}
                                                  />} />
                                              <FormHelperText className='text-danger'>
                                                {errors.startupCashPayment?.[index]?.custody && errors.startupCashPayment?.[index]?.custody.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell sx={{ width: '25%', borderBottom: 'none', padding: '5px' }}>
                                            {
                                              getValues(`startupCashPayment.${index}.receipt`) !== '' && <span>Upload Receipt <span className='text-danger'>*</span></span>
                                            }
                                            {/* <FormLabel>Provide Physical Verfication of Cash Statement as on DD Cut off Date</FormLabel> */}
                                            <div className="d-flex align-items-center">
                                              <div className='form-group my-1'>
                                                <Controller name={`startupCashPayment.${index}.receipt`} control={control} defaultValue="" rules={{ required: false }}
                                                  render={({ field }) =>

                                                    <FileUploader
                                                      {...field}
                                                      handleChange={(e) => handleFileUpload(e,
                                                        `startupCashPayment.${index}.receipt`,
                                                        `startupCashPayment.${index}.receiptName`,
                                                        `startupCashPayment.${index}.receiptUrl`,
                                                        'file')}
                                                      value={getValues(`startupCashPayment.${index}.receipt`)}
                                                      name="file"
                                                      types={fileTypes}
                                                      children={
                                                        <div className={getValues(`startupCashPayment.${index}.receipt`) !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                                          <center>
                                                            <p className={getValues(`startupCashPayment.${index}.receipt`) !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                                              <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues(`startupCashPayment.${index}.receipt`) !== '' ? 'Uploaded (Click to Change)' : 'Receipt Document'}
                                                              </span>
                                                            </p>
                                                          </center>
                                                        </div>
                                                      }
                                                      disabled={readyOnlyValue}
                                                    />
                                                  } />
                                                <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.startupCashPayment?.[index]?.receipt && errors.startupCashPayment?.[index]?.receipt.message}</FormHelperText>

                                              </div>
                                              <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                                                onClick={() => handleClickOpen(getValues(`startupCashPayment.${index}.receiptUrl`))}
                                                style={{ color: "#80809e" }}
                                              > Preview
                                              </Link>
                                              </span>
                                            </div>
                                          </TableCell>
                                          <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                            <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deletestartupCashPaymentsBtn(index) : <></> }} />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <center>
                                    <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addstartupCashPaymentsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                                  </center>
                                </TableContainer>
                                //     ) : (
                                // <div className='nodata_tag'>
                                //     <p>Fundraising history details is empty</p>
                                // </div>
                              )
                            }
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
        <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      </section>
    </>
  )
}



export default FddSectionI
import React, { useContext, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
import DataContext from '../../../../../context/DataContext';

const Startupstartdetails = ({ details }) => {
  const { modalstyle } = useContext(DataContext)
  const [openView, setOpenView] = useState(false);
  const [getData, setSetGetData] = useState([]);
  const handleViewOpen = (data) => {
    debugger
    setSetGetData(data)
    setOpenView(true)
  }
  const handleViewClose = () => {
    setOpenView(false);
  }
  return (
    <>
      <main>
        <section>
          <div className='row'>            
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>PitchDeck (Startup Presentation) :</label>
                <div className='ml-auto'>
                  <Link to={`${details.pitchDeckName}`} target="_blank"><span className='viewdetails'>View File</span></Link>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Elevator Pitch of Your Startup :</label>
                <p className='ml-auto'><b>{details.elevatorPitch}</b></p>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Product details :</label>
                <p className='ml-auto'><b>{details.productDetails}</b></p>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Stage of Startup :</label>
                <p className='ml-auto'><b>{details.startupStage}</b></p>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Intellectual property? :</label>
                <p className='ml-auto'><b>{(details.intellectPropertyPresent === true) ? (<>Yes</>) : (<>No</>)}</b></p>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Technology :</label>
                <p className='ml-auto'>
                  <span className='viewdetails' onClick={() => handleViewOpen(details.technologies)}>View Details</span>
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>SC / ST led startup :</label>
                <p className='ml-auto'><b>{(details.scstFounderStartup === true) ? (<>Yes</>) : (<>No</>)}</b></p>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Funding Stage :</label>
                <p className='ml-auto'><b>{(details.fundingStage) ? (<>{details.fundingStage}</>) : (<>-</>)}</b></p>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Sector :</label>
                <p className='ml-auto'><b>{details.sector}</b></p>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Investment Ticket Size :</label>
                <p className='ml-auto'><b>{(details.investTicketSize) ? (<>{details.investTicketSize}</>) : (<>-</>)}</b></p>
              </div>
            </div>

            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Funding Investment Instrument Type :</label>
                <p className='ml-auto'>
                  <span className='viewdetails' onClick={() => handleViewOpen(details.investmentInstrumentType)}>View Details</span>
                </p>

              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Current Stage of Revenue :</label>
                <p className='ml-auto'><b>{details.revenueStage}</b></p>
              </div>
            </div>

            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Total Addressable Market Globally :</label>
                <p className='ml-auto'><b>{details.totalAddrMarket}</b></p>
              </div>
            </div>

            <div className='col-lg-6 col-12'>
              <div className='d-flex'>
                <label className='mr-3'>Tentative Valuation ? :</label>
                <p className='ml-auto'><b>{details.tentativeValuation}</b></p>
              </div>
            </div>
            {
              (details.editAdditionalDocNameStatus === true) && (
                <div className='col-lg-6 col-12'>
                  <div className='d-flex'>
                    <label className='mr-3'>Additional Documents :</label>
                    <p className='ml-auto'>
                      <Link to={`${details.additionalDocName}`} target="_blank"><span className='viewdetails'>View File</span></Link>
                    </p>
                  </div>
                </div>
              ) 
            }
            {
              (details.startupTractions) && (
                <div className='col-lg-12 col-12 my-3'>
                  <div className='div_header'>
                    <h6>Startup Tractions</h6>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {details.startupTractions.map((list, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {list.name}
                            </TableCell>
                            <TableCell>
                              {list.type}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                  </TableContainer>
                </div>
              ) 
            }
            {
              (details.startupFundingPrograms) && (
                <div className='col-lg-12 col-12 my-3'>
                  <div className='div_header'>
                    <h6>Funding Programs Details</h6>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Partner</TableCell>
                          <TableCell>Program</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {details.startupFundingPrograms.map((list, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {list.type}
                            </TableCell>
                            <TableCell>
                              {list.partner}
                            </TableCell>
                            <TableCell>
                              {list.program}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                  </TableContainer>
                </div>
              ) 
            }
            {
              (details.startupProducts) && (
                <div className='col-lg-12 col-12 my-3'>
                  <div className='div_header'>
                    <h6>Products Details</h6>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell>Product Name</TableCell>
                          <TableCell>Link</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {details.startupProducts.map((list, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {list.name}
                            </TableCell>
                            <TableCell>
                              {list.link}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                  </TableContainer>
                </div>
              )
            }
          </div>
        </section>
      </main>
      <Modal
        open={openView}
        onClose={handleViewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag">
          <div className='ques_row'>           
            <div>
              <ol>
                {
                  getData.map((list) => (
                    <li>{list}</li>
                  ))
                }
              </ol>
            </div>
          </div>
        </Box>

      </Modal>
    </>
  )
}

export default Startupstartdetails
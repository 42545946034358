import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, FormHelperText, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const Tractionandachievements = () => {
  // const [acceleratorProgram, setAcceleratorProgram] = useState();
  const [formData, setFormData] = useState({
    // customerTestimonials: [{}],
    tractions: [{}],
  });
  const { navigator, handleBacktanseed } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    // getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  // const {
  //     fields: customerTestimonialsFields,
  //     append: customerTestimonialsAppend,
  //     remove: customerTestimonialsRemove
  // } = useFieldArray({
  //     control,
  //     name: "customerTestimonials"
  // })
  // const {
  //   fields: tractionsFields,
  //   append: tractionsAppend,
  //   remove: tractionsRemove,
  // } = useFieldArray({
  //   control,
  //   name: "tractions",
  // });
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    // dropdownValueApi();
  }, []);
  // const [startupTractionsList, setStartupTractionsList] = useState([]);
  // const dropdownValueApi = async () => {
  //   await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
  //     (res) => {
  //       var val = res.data.dropDownValues;
  //       setStartupTractionsList(val.tractionsTypes);
  //     }
  //   );
  // };
  const getApi = async () => {
    await apiService(`startup/tractionandachievement/get`, "", "get").then(
      (res) => {
        if (res) {
          setFormData({});
          let response = res.data;
          if (response.id !== 0) {
            setFormData(response);
            if (response.editForm === false) {
              setReadyOnlyValue(true);
            }
            // if(response.currentlyAccelator){
            //   setAcceleratorProgram(response.currentlyAccelator)
            // }
          } else {
            setFormData(response);
          }
        }
      }
    );
  };
  // const addTestimonialsDetailsBtn = () => {
  //     customerTestimonialsAppend({
  //         customerName: "",
  //         testimonial: ""
  //     })
  // }
  // const addTractionsDetailsBtn = () => {
  //   tractionsAppend({
  //     type: "",
  //     name: "",
  //   });
  // };

  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      apiService("startup/tractionandachievement/save", getform, "post").then(
        (res) => {
          setLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              notifyService("success", "Success", "Saved successfully !!");
              navigator("/startup/documents-upload/tanseed");
            }
          }
        }
      );
    }
  };
  // delete customer Testimonials
  // const deleteCustomerTestimonials = (index) => {
  //     const id = getValues(`customerTestimonials.${index}.id`);
  //     if (id !== undefined) {
  //         apiService(`startup/customertestimonial/remove?id=${id}`, '', 'get').then((res) => {
  //             if (res) {
  //                 if (res.data.responseStatus === 'Success') {
  //                     // eslint-disable-next-line
  //                     const del = customerTestimonialsFields.filter((index) => index !== index)
  //                     customerTestimonialsRemove(del)
  //                     getApi()
  //                 }
  //             }
  //         })
  //     } else {
  //         // eslint-disable-next-line
  //         const del = customerTestimonialsFields.filter((index) => index !== index)
  //         customerTestimonialsRemove(del)
  //     }
  // }
  // delete Tractions
  // const deleteTractions = async (index) => {
  //   const id = getValues(`tractions.${index}.id`);
  //   if (id !== undefined) {
  //     apiService(`startup/tractions/remove?id=${id}`, "", "get").then((res) => {
  //       if (res) {
  //         if (res.data.responseStatus === "Success") {
  //           tractionsRemove(index);
  //           getApi();
  //         }
  //       }
  //     });
  //   } else {
  //     tractionsRemove(index);
  //   }
  // };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center mb-3">
            <span className="back_span" onClick={handleBacktanseed}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Traction / Achievements</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="noOfCustomers"
                    control={control}
                    defaultValue=""
                    rules={{ required: "No. of customers is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="No. of customers *"
                        placeholder="Enter No. of customers"
                        fullWidth
                        type="text"
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.noOfCustomers && errors.noOfCustomers.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="fullTimeEmployee"
                    control={control}
                    defaultValue=""
                    rules={{ required: "No. of Full Time Employee required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="No. of Full Time Employee *"
                        placeholder="No. of Full Time Employee "
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.fullTimeEmployee && errors.fullTimeEmployee.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="partTimeEmployee"
                    control={control}
                    defaultValue=""
                    rules={{ required: "No. of Part Time Employee required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="No. of Part Time Employee *"
                        placeholder="No. of Part Time Employee "
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.partTimeEmployee && errors.partTimeEmployee.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="linkToMedia"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Link to Media"
                        placeholder="Enter Link to Media"
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.linkToMedia && errors.linkToMedia.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="linkToLinkedIn"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Link to LinkedIn Post"
                        placeholder="Link to LinkedIn Post"
                        fullWidth
                        {...field}
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.linkToLinkedIn && errors.linkToLinkedIn.message}
                  </FormHelperText>
                </div>
              </div>
              {/* <div className="col-lg-12 col-12">
              <div className="form-group">
                  <FormLabel>Are you part of any accelerator program or incubation program? *</FormLabel>
                  <Controller name="currentlyAccelator" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setAcceleratorProgram(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setAcceleratorProgram(e.target.value); setValue('incubationCenterName', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />
                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.currentlyAccelator && errors.currentlyAccelator.message}</FormHelperText>
                </div>
              </div>
              {
                (acceleratorProgram === 'true' || acceleratorProgram === true) && (
                  <div className='col-lg-4 col-12'>
                    <div className="form-group mt-2">
                      <Controller name="accelatorProgram" control={control} defaultValue="" rules={{ required: 'Incubation Center  Name is required' }}
                        render={({ field }) =>
                          <TextField
                            variant='outlined'
                            label="Accelator Program  Name *"
                            placeholder='Enter Accelator Program  Name'
                            fullWidth
                            inputProps={
                              { readOnly: readyOnlyValue, }
                            }
                            {...field}
                          />} />
                      <FormHelperText className='text-danger'>{errors.accelatorProgram && errors.accelatorProgram.message}</FormHelperText>
                    </div>
                  </div>
                )
              } */}
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Tractionandachievements;

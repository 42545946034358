import React, { useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import SaveIcon from "@mui/icons-material/Save";
import useDidMountEffect from '../../hooks/useDidMountEffect';
const Remarksmodal = ({ index, remarkValue, handleCloseRemark, status }) => {
    const [formData, setformData] = useState({})
    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({ values: formData });
    useDidMountEffect(() => {
        if (remarkValue) {
            setComment(remarkValue);
            var req = {
                "remarks": remarkValue
            }
            setformData(req)
        }
    }, [])
    const [comment, setComment] = useState("");
    const handleComment = (value) => {
        setValue("remarks", value);
        clearErrors("remarks");
        setComment(value);
    };
    const onSubmit = (data) => {  
        handleCloseRemark(index, data.remarks)    
    }
    return (
        <div>
            <h4 className="mb-4">
                <b>Add your Remark</b>
            </h4>
            <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Controller
                        name={`remarks`}
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                id="filled-textarea"
                                label="Comment"
                                placeholder="Add a comment"
                                multiline
                                variant="filled"
                                {...field}
                                fullWidth
                                rows={4}
                                value={comment}
                                onInput={(e) => handleComment(e.target.value)}
                                inputProps={{ readOnly: status !== 0 && true }}
                            />
                        )}
                    />
                    <FormHelperText className="text-danger">
                        {errors.remarks &&
                            "Remark is required"}
                    </FormHelperText>
                </div>
                <div className='p-3 text-center'>
                    {
                        status === 0 && (
                            <Button
                                type="submit"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                sx={{
                                    margin: '3px', borderRadius: "25px", "&:hover": {
                                        backgroundColor: "green",
                                    }
                                }}
                            >
                                <span>Save</span>
                            </Button>
                        )
                    }

                    <Button variant="contained" color="error" onClick={handleCloseRemark} sx={{
                        margin: '3px', borderRadius: "25px", "&:hover": {
                            backgroundColor: "#007aff",
                        }
                    }}>Cancel</Button>
                </div>
            </form>
        </div>
    )
}

export default Remarksmodal
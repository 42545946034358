import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

const ICAdminNavbar = () => {
    return (
        <nav>
          <div className='submenu_div'>
            <div className='container-fluid'>
              <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
                <Typography sx={{ minWidth: 100 }}>
                  <NavLink className="nav-link" to={`/ic-admin/startup`}>Startups</NavLink>
                </Typography>
                {/* <Typography sx={{ minWidth: 100 }}>
                  <NavLink className="nav-link" to={`/io-admin/evaluator`} onClick={handleClick}>Evaluator</NavLink>
                </Typography>
                <Typography sx={{ minWidth: 100 }}>
                  <NavLink className="nav-link" to={`/io-admin/assign-evaluator`} onClick={handleClick}>Assign Evaluator</NavLink>
                </Typography> */}
              </Box>
            </div>
          </div>
        </nav>
      )
    }

export default ICAdminNavbar
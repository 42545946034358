import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import { Autocomplete, Button, FormHelperText, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../context/DataContext';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const Businessstrategy = () => {
  const [formData, setFormData] = useState({});
  const { navigator,handleBack } = useContext(DataContext)
  const { handleSubmit, setValue, clearErrors, control, formState: { errors } } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())()
    dropdownValueApi()
  }, [])
  const [businessModelsData, setBusinessModelsData] = useState([])
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, '', 'get').then((res) => {
      var val = res.data.dropDownValues
      setBusinessModelsData(val.businessModels)
    })
  }
  const getApi = async () => {
    await apiService(`startup/businessstrategy/get`, '', 'get').then((res) => {
      if (res) {
        setFormData({})
        let response = res.data
        if (response.id !== 0) {

          setFormData(response)
          if (response.editForm === false) {
            setReadyOnlyValue(true)
          }
        } else {
          setFormData(response)
        }
      }
    })
  }
  // const [businessModelsValue, setBusinessModelsValue] = useState('');
  const handleSelectBusiness = (value) => {
    // setBusinessModelsValue(value);
    setValue('businessModel', value)
    clearErrors('businessModel');
  }
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data
      debugger
      apiService('startup/businessstrategy/save', getform, 'post').then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService('success', 'Success', 'Saved successfully !!')
            navigator('/startup/traction-achievements')
          }
        }
      })
    }
  };
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className='d-flex align-items-center'>
            <span className='back_span' onClick={handleBack}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Business Strategy</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='d-none'>
                <div className="form-group">
                  <Controller name="id" control={control} defaultValue="0"
                    render={({ field }) =>
                      <TextField
                        {...field}
                      />} />
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group mt-2">
                  <Controller name="businessModel" control={control} defaultValue="" rules={{ required: 'Business Model is required' }}
                    render={({ field }) =>
                      // <TextField
                      //   variant='outlined'
                      //   label="Business Model *"
                      //   placeholder='Enter Business Model'
                      //   fullWidth
                      //   inputProps={
                      //     { readOnly: readyOnlyValue, }
                      //   }
                      //   {...field}
                      // />
                      <Autocomplete

                        disableClearable
                        disablePortal
                        {...field}
                        limitTags={2}
                        options={businessModelsData}
                        onChange={(e, selectedOptions) => handleSelectBusiness(selectedOptions)}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Business Model *" />}
                        disabled={readyOnlyValue}
                      />
                    } />
                  <FormHelperText className='text-danger'>{errors.businessModel && errors.businessModel.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group mt-2">
                  <Controller name="revenueGenerationModel" control={control} defaultValue="" rules={{ required: 'Revenue Generation Model is required' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Revenue Generation Model *"
                        placeholder='Enter Revenue Generation Model'
                        fullWidth
                        inputProps={
                          { readOnly: readyOnlyValue, }
                        }
                        {...field}
                      />} />
                  <FormHelperText className='text-danger'>{errors.revenueGenerationModel && errors.revenueGenerationModel.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group mt-2">
                  <Controller name="marketingStrategy" control={control} defaultValue="" rules={{ required: 'Go-to-market Strategy is required' }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        label="Go-to-market Strategy *"
                        placeholder='Enter Go-to-market Strategy'
                        fullWidth
                        inputProps={
                          { readOnly: readyOnlyValue, }
                        }
                        {...field}
                      />} />
                  <FormHelperText>How do you plan to reach and acquire your customers?</FormHelperText>
                  <FormHelperText className='text-danger'>{errors.marketingStrategy && errors.marketingStrategy.message}</FormHelperText>

                </div>
              </div>
              {/* <div className='col-lg-6 col-12'>
            <div className="form-group mt-2">
              <Controller name="customerAcquisitionCost" control={control} defaultValue="" rules={{ required: 'Customer Acquisition Cost is required' }}
                render={({ field }) =>
                  <TextField
                    variant='outlined'
                    label="Customer Acquisition Cost (CAC) *"
                    placeholder='Enter Customer Acquisition Cost'
                    fullWidth
                    inputProps={
                      { readOnly: readyOnlyValue, }
                    }
                    {...field}
                  />} />
              <FormHelperText className='text-danger'>{errors.customerAcquisitionCost && errors.customerAcquisitionCost.message}</FormHelperText>
            </div>
          </div>
          <div className='col-lg-6 col-12'>
            <div className="form-group mt-2">
              <Controller name="lifeTimeValue" control={control} defaultValue="" rules={{ required: 'Lifetime Value is required' }}
                render={({ field }) =>
                  <TextField
                    variant='outlined'
                    label="Lifetime Value (LTV) *"
                    placeholder='Enter Lifetime Value'
                    fullWidth
                    inputProps={
                      { readOnly: readyOnlyValue, }
                    }
                    {...field}
                  />} />
              <FormHelperText className='text-danger'>{errors.lifeTimeValue && errors.lifeTimeValue.message}</FormHelperText>
            </div>
          </div> */}
            </div>
            <div className="form-group text-center">
              <Button variant="contained" onClick={handleBack} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <LoadingButton
                    type="submit"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </LoadingButton>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default Businessstrategy
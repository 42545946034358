import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
const Fundingutilisation = ({ details }) => {
  return (
    <Box sx={{ minWidth: 100 }}>
      <section>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <div className='d-flex'>
              <label className='mr-3'>Previous Funding Rounds :</label>
              <p className='ml-auto'><b>{details.previousFundingRounds}</b></p>
            </div>
          </div>
          <div className='col-lg-12 col-12'>
            {
              (details.fundPurposeAmount) && (
                <>
                  <div className='div_header'>
                    <h6>Funding Purpose Details</h6>
                  </div>
                  <TableContainer component={Paper} className='table-width'>
                    <Table aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell>Purpose</TableCell>
                          <TableCell>Fund Amount (₹)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {details.fundPurposeAmount.map((list, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {list.purpose}
                            </TableCell>
                            <TableCell>
                              {list.fundAmount}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )
            }
          </div>
        </div>
      </section>
    </Box >
  )
}

export default Fundingutilisation
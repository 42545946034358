import React, { useEffect, useState } from 'react'

const Guidlines = () => {
  const [url, setUrl] = useState()
  useEffect(() => {
    setUrl('https://startuptn-public-files.s3.ap-south-1.amazonaws.com/SCST_Guidelines.pdf')
    
  }, [])

  return (
    <div style={{overflow:'hidden'}}>
    <iframe className='text-center' src={url} width="100%" height="100%" style={{
      border: "none",
      height: "100vh",
    }} />
    </div>
  )
}

export default Guidlines
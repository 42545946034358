import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, Container, FormControl, List, Paper } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
// import apiService from '../../../api/apiService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
// import DataContext from '../../../context/DataContext';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import notifyService from '../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
const Investorpreference = () => {
    const { handleStartupDetails, handlepreference, setUserLogo, handleeoi, 
        yearListApi, loading, setLoading, yearListValue, navigator, setPageLoading, icon, checkedIcon } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors } } = useForm(
        { values: formData }
    );

    useDidMountEffect(() => {
        (async () => await getApi())()
        dropdownValueApi()
        fundprogramlistApi()
        yearListApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [preferredInvestorTypeList, setPreferredInvestorTypeList] = useState([])
    const [fundingStatusesList, setFundingStatusesList] = useState([])
    const [investmentTicketSize, setInvestmentTicketSize] = useState([])
    const [instrumentTypeData, setInstrumentTypeData] = useState([])
    const [revenuesList, setLevenuesList] = useState([])
    const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState([])
    const [businessModelsData, setBusinessModelsData] = useState([])
    const [purposeList, setPurposeList] = useState([])
    const [technologiesList, setTechnologiesList] = useState([])
    const [sectorData, setSectorData] = useState([])
    const [startupStagesList, setStartupStagesList] = useState([])
    const [geoPreList, setGeoPreList] = useState([])
    const [revenueStageList, setRevenueStageList] = useState([])
    const [investmentData, setInvestmentData] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=investor`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            // setPreferredInvestorTypeList(val.prefInvestorTypes)
            setInvestmentTicketSize(val.desiredFundingAmount)
            setFundingStatusesList(val.previousFundingStatus)
            // setLevenuesList(val.revenueStages)
            setBusinessModelsData(val.businessModels)
            // setPurposeList(val.purposes)
            // setTechnologiesList(val.technologyStacks)
            setSectorData(val.sectors)
            setStartupStagesList(val.startupStages)
            setGeoPreList(val.geoPreferences)
            setRevenueStageList(val.revenueStages)
            setInstrumentTypeData(val.prefFundingInstruments)
            setInvestmentData(val.investCharacterstics)

        })
    }
    const [StartupDetailStatus, setStartupDetailStatus] = useState(false)
    const [preferenceStatus, setpreferenceStatus] = useState(false)
    const [eoiStatus, setEoiStatus] = useState(false)
    const getApi = async () => {
        const userId = Number(localStorage.getItem('userId'))
        setPageLoading(true)
        await apiService(`userprofile/get?userId=${userId}`, '', 'get').then((res) => {
            setPageLoading(false)
            if (res) {
                let result = res.data
                if (result.fundingStatuses !== undefined) {
                    setFormData(result)
                    // setOrganizationTypeValue(result.organizationType);
                    setInstrumentTypeValueValue(result.investmentInstrumentType);
                    if (result.investmentCharacterstics) {
                        if (result.investmentCharacterstics.length === 1) {
                            if (result.investmentCharacterstics[0] === "") {
                                result.investmentCharacterstics = undefined
                            }
                        }
                        if (result.investmentCharacterstics !== undefined) {
                            setInvestmentCharactersticsValue(result.investmentCharacterstics)
                        }
                    }
                    if (result.geoPreferences) {
                        if (result.geoPreferences.length === 1) {
                            if (result.geoPreferences[0] === "") {
                                result.geoPreferences = undefined
                            }
                        }
                        if (result.geoPreferences !== undefined) {
                            setGeoPreferencesValue(result.geoPreferences)
                        }
                    }
                    setInvestTicketSizeValue(result.investTicketSize)
                    // setPreferToValue(result.preferTo)
                    setStartupStagesValue(result.startupStages)
                    setRevenueStageValue(result.revenueStage)
                    if (result.organizationLogoName === undefined) {
                        setLogoDisplayUrl('/images/user.png')
                    } else {
                        setLogoDisplayUrl(result.organizationLogoName)
                        setLogoDisplayValue(result.organizationLogoName)
                        localStorage.setItem("logo", result.organizationLogoName);
                        setUserLogo(result.organizationLogoName)
                    }
                    setFundingStatusesValue(result.fundingStatuses)
                    if (result.preferredSectors) {
                        setPreferredSectors(result.preferredSectors)
                    }

                    var con = result.openToCoInvest.toString()
                    setValue('openToCoInvest', con)
                    setOpenToCoInvestValue(con)
                    // setGeoPreferencesValue(result.geoPreferences)
                    setBusinessModelsValue(result.businessModels)
                } else {
                    setValue('openToCoInvest', result.openToCoInvest.toString())
                    setOpenToCoInvestValue(result.openToCoInvest.toString())
                    setFormData(result)
                }
                setAcceptPrivacyPolicyChecked(result.acceptPrivacyPolicy)
                setAcceptTermsAndConditionsChecked(result.acceptTermsAndConditions)
                setValue('acceptPrivacyPolicy', result.acceptPrivacyPolicy)
                clearErrors('acceptPrivacyPolicy')
                setValue('acceptTermsAndConditions', result.acceptTermsAndConditions)
                clearErrors('acceptTermsAndConditions')
                if (result.noOfPortfolioStartups === 0) {
                    setValue('noOfPortfolioStartups', '')
                }
                if (result.estdYear !== undefined) {
                    setStartupDetailStatus(true)
                } if (result.fundingStatuses !== undefined) {
                    setpreferenceStatus(true)
                } if (result.acceptPrivacyPolicy !== false) {
                    setEoiStatus(true)
                }
            }
        })
    }
    const [termsAndConditionsHTML, setTermsAndConditionsHTML] = useState('')
    function termsAndConditionsMarkup() {
        return { __html: termsAndConditionsHTML };
    }
    const [privacyPolicyHTML, setPrivacyPolicyHTML] = useState('')
    function privacyPolicyMarkup() {
        return { __html: privacyPolicyHTML };
    }
    const [instrumentTypeValue, setInstrumentTypeValueValue] = useState([]);
    const handleSelectInstrument = (value) => {
        setInstrumentTypeValueValue(value);
        setValue('investmentInstrumentType', value)
        clearErrors('investmentInstrumentType');
    }


    const [investmentCharactersticsValue, setInvestmentCharactersticsValue] = useState([]);
    const handleSelectInvestment = (value) => {
        setInvestmentCharactersticsValue(value);
        setValue('investmentCharacterstics', value)
        clearErrors('investmentCharacterstics');
    };

    const [investTicketSizeValue, setInvestTicketSizeValue] = useState([]);
    const handleSelectTicketSize = (value) => {
        setInvestTicketSizeValue(value);
        setValue('investTicketSize', value)
        clearErrors('investTicketSize');
    };

    // const preferToData = [
    //   "Co-Invest",
    //   "Lead the round",
    //   "Solo Invest"
    // ]
    // const [preferToValue, setPreferToValue] = useState('');
    // const handleSelectPrefer = (value) => {
    //   setPreferToValue(value);
    //   setValue('preferTo', value)
    //   clearErrors('preferTo');
    // };

    const [businessModelsValue, setBusinessModelsValue] = useState([]);
    const handleSelectBusiness = (value) => {
        setBusinessModelsValue(value);
        setValue('businessModels', value)
        clearErrors('businessModels');
    }


    const [startupStagesValue, setStartupStagesValue] = useState([]);
    const handleSelectStageStartup = (value) => {
        setStartupStagesValue(value)
        setValue('startupStages', value)
        clearErrors('startupStages');
    }

    const [revenueStageValue, setRevenueStageValue] = useState([]);
    const handleSelectRevenueStageValue = (value) => {
        setRevenueStageValue(value)
        setValue('revenueStage', value)
        clearErrors('revenueStage');
    }

    const [geoPreferencesValue, setGeoPreferencesValue] = useState([]);
    const handleSelectGeoPreferencesValue = (value) => {
        // if (value[value.length - 1] === "allSelect") {
        //   setGeoPreferencesValue(geoPreList);
        //   return;
        // } if (value[value.length - 1] === "allRemove") {
        //   setGeoPreferencesValue([]);
        //   return;
        // } else {
        //   setGeoPreferencesValue(value);
        // }
        setGeoPreferencesValue(value);
        setValue('geoPreferences', value)
        clearErrors('geoPreferences');
    }
    const [fundingStatusesValue, setFundingStatusesValue] = useState([]);
    const handleSelectFundingStatuses = (value) => {
        setFundingStatusesValue(value)
        setValue('fundingStatuses', value)
        clearErrors('fundingStatuses');
    }

    const [preferredSectors, setPreferredSectors] = useState([]);
    const handleSelectSector = (value) => {
        setPreferredSectors([])
        if (value.length === 0) {
            return;
        }
        if (value[value.length - 1] === 'Select All') {


            var dataa = sectorData.filter((item) => item !== 'Remove All' && item !== 'Select All')

            setPreferredSectors(dataa);
            setValue('preferredSectors', dataa)
            clearErrors('preferredSectors');

        } else if (value[value.length - 1] === 'Remove All') {
            setPreferredSectors([]);
            setValue('preferredSectors', [])

        } else {
            setPreferredSectors(value);
            setValue('preferredSectors', value)
            clearErrors('preferredSectors');

        }
    };

    const [fundingList, setFundingList] = useState([]);
    const fundprogramlistApi = async () => {
        await apiService(`partner/fundprograms`, '', 'get').then((res) => {
            if (res) {
                setFundingList(res.data.fundingPrograms)
            }
        })
    }

    // const [organizationTypeValue, setOrganizationTypeValue] = useState('');
    const handleSelectOrganizationType = (value) => {
        // setOrganizationTypeValue(value);
        setValue('organizationType', value)
        clearErrors('organizationType');
        const name = fundingList.filter(item => ((item.partner)).includes(value)
            || ((item.partner)).includes(value)
        )
        setValue('fundingProgramVersionConfigId', name[0].versionId)
    }

    const [logoDisplayValue, setLogoDisplayValue] = useState(null)
    const [logoDisplayUrl, setLogoDisplayUrl] = useState('/images/user.png')
    let logoselecetdFile
    const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
        setValue(filekey, "")
        setValue(fileName, "")
        setdata('')
        setUrl('')
        if (event.target === undefined) {
            logoselecetdFile = event
        } else {
            logoselecetdFile = event.target.files[0]
        }
        if (logoselecetdFile) {
            var reader = new FileReader();
            var imagetype = logoselecetdFile.type
            var imagedatatype = imagetype.split("/")
            var img_crt_type = imagedatatype[1]
            if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png") {
                if (event.target === undefined) {
                    setdata(event)
                } else {
                    setdata(event.target.files[0])
                }
                var fileValue = logoselecetdFile
                reader.readAsDataURL(logoselecetdFile);
                reader.onload = () => {
                    var logourl1 = reader.result;
                    var spl = logourl1.split(',')
                    var ImageValue = spl[1]
                    var img_name = fileValue.name
                    setUrl(logourl1)
                    setValue(filekey, ImageValue)
                    setValue(fileName, img_name)
                    clearErrors(filekey);
                    clearErrors(fileName);
                    setLogoInvalid(false)
                }
            }
        }
    }


    const handleSelectYearData = (value) => {
        setValue('estdYear', value)
        clearErrors('estdYear')
    }
    const [acceptPrivacyPolicyChecked, setAcceptPrivacyPolicyChecked] = useState(null);
    const handleChangeAcceptPrivacyPolicy = (event) => {
        setAcceptPrivacyPolicyChecked(event.target.checked);
        if (event.target.checked) {
            setValue('acceptPrivacyPolicy', event.target.checked)
            clearErrors('acceptPrivacyPolicy')
        } else {
            setValue('acceptPrivacyPolicy', null)
        }
    };
    const [acceptTermsAndConditionsChecked, setAcceptTermsAndConditionsChecked] = useState(null);
    const handleChangeAcceptTermsAndConditions = (event) => {
        setAcceptTermsAndConditionsChecked(event.target.checked);
        if (event.target.checked) {
            setValue('acceptTermsAndConditions', event.target.checked)
            clearErrors('acceptTermsAndConditions')
        } else {
            setValue('acceptTermsAndConditions', null)
        }
    };
    const [openToCoInvestValue, setOpenToCoInvestValue] = useState(false)
    const [noOfPortfolioStartupsValue, setNoOfPortfolioStartupsValue] = useState(false)
    const [logoInvalid, setLogoInvalid] = useState(false);
    const onSubmit = (data) => {
        var getform = data
        if (data.openToCoInvest === "true") {
            getform.openToCoInvest = true
        } else {
            getform.openToCoInvest = false
        }
        if (data.investmentCharacterstics === '') {
            getform.investmentCharacterstics = []
        }
        if (data.geoPreferences === '') {
            getform.geoPreferences = []
        }
        getform.id = Number(data.id)
        getform.noOfPortfolioStartups = Number(data.noOfPortfolioStartups)

        if (getform.noOfPortfolioStartups !== 0) {
            setLoading(true);
            setNoOfPortfolioStartupsValue(false)
            setPageLoading(true)
            apiService('userprofile/save', getform, 'post').then((res) => {
                setPageLoading(false)
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        navigator('/investor/profile/eoi')
                    }
                }
            })
        } else {
            setNoOfPortfolioStartupsValue(true)
        }
    };
    const handleCancel = () => {
        navigator('/investor/profile/details')
    }
    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${date}/${month}/${year}`;
    }
    return (
        <section className='container-fluid'>
            <div className='form_div'>
                <div className='d-flex align-items-center'>
                    <span className='back_span' onClick={handleCancel}>
                        <i className="fas fa-arrow-alt-circle-left"></i>
                    </span>
                    <div className='ml-2'>
                        <h6 className='mb-0'>Startup Preference</h6>
                        <p className='header-p mb-0'>Your investment preference</p>
                    </div>
                    <div className='ml-auto'>
                        <div className='activeradio'>
                            {
                                (StartupDetailStatus === true) ? (
                                    <>
                                        <CheckCircleIcon className='c-green c-pointer mr-2' onClick={handleStartupDetails} />
                                        <div className='underlinecssgreen'></div>
                                    </>
                                ) : (
                                    <>
                                        <Radio checked={true} />
                                        <div className='underlinecss'></div>
                                    </>
                                )
                            }
                            {
                                (preferenceStatus === true) ? (
                                    <>
                                        <CheckCircleIcon className='c-green c-pointer mx-2' onClick={handlepreference} />
                                        <div className='underlinecssgreen'></div>
                                    </>
                                ) : (
                                    <>
                                        <Radio checked={true} />
                                        <div className='underlinecss'></div>
                                    </>
                                )
                            }
                            {
                                (eoiStatus === true) ? (
                                    <>
                                        <CheckCircleIcon className='c-green c-pointer ml-2' onClick={handleeoi} />
                                    </>
                                ) : (
                                    <>
                                        <Radio checked={false} />
                                    </>
                                )
                            }
                        </div>

                    </div>
                </div>
                <hr />
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="id" control={control} defaultValue="0"
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="organizationLogoName" control={control} defaultValue=""
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="organizationLogo" control={control} defaultValue=""
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="fundingProgramVersionConfigId" control={control} defaultValue="0"
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='col-lg-6 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="fundingStatuses" control={control} defaultValue="" rules={{ required: 'Last funding round status is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                {...field}
                                                limitTags={2}
                                                value={fundingStatusesValue}
                                                options={fundingStatusesList}
                                                onChange={(e, selectedOptions) => handleSelectFundingStatuses(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Last funding round status *" />}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        } />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.fundingStatuses && errors.fundingStatuses.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="preferredSectors" control={control} defaultValue="" rules={{ required: 'Sector is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                {...field}
                                                limitTags={2}
                                                value={preferredSectors}
                                                options={sectorData}
                                                onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Sectors *" />}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        } />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.preferredSectors && errors.preferredSectors.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="organizationType" control={control} defaultValue="" rules={{ required: 'Organization Type is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                options={fundingList.map((option) => option.partner)}
                                                onChange={(e, selectedOptions) => handleSelectOrganizationType(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Organization Type *" />}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.organizationType && errors.organizationType.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="investTicketSize" control={control} defaultValue="" rules={{ required: 'Investment Ticket Size is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                {...field}
                                                limitTags={2}
                                                value={investTicketSizeValue}
                                                options={investmentTicketSize}
                                                onChange={(e, selectedOptions) => handleSelectTicketSize(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Investment Ticket Size (₹)*" />}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        }
                                    />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.investTicketSize && errors.investTicketSize.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="startupStages" control={control} defaultValue="" rules={{ required: 'Stage of Startup is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                {...field}
                                                limitTags={2}
                                                value={startupStagesValue}
                                                options={startupStagesList}
                                                onChange={(e, selectedOptions) => handleSelectStageStartup(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Stage of Startup *" />}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        } />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.startupStages && errors.startupStages.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="geoPreferences" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                limitTags={2}
                                                {...field}
                                                value={geoPreferencesValue}
                                                options={geoPreList}
                                                onChange={(e, selectedOptions) => handleSelectGeoPreferencesValue(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Geographical Preferences" />}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        } />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.geoPreferences && errors.geoPreferences.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-8 col-12'>
                            <div className="form-group">
                                <FormLabel>Are you open to co-investing with other Investment agencies on the TANFUND platform? *</FormLabel>
                                <Controller name="openToCoInvest" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>
                                        <RadioGroup
                                            row
                                            value={openToCoInvestValue}
                                            aria-labelledby="demo-form-control-label-placement"
                                            name="position"
                                            {...field}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    } />
                                <FormHelperText className='text-danger'>{errors.openToCoInvest && errors.openToCoInvest.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="revenueStage" control={control} defaultValue="" rules={{ required: 'Funding Status is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                limitTags={2}
                                                {...field}
                                                value={revenueStageValue}
                                                options={revenueStageList}
                                                onChange={(e, selectedOptions) => handleSelectRevenueStageValue(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Revenue Stage *" />}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        } />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.revenueStage && errors.revenueStage.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="investmentInstrumentType" control={control} defaultValue="" rules={{ required: 'Investment Characteristic is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                limitTags={2}
                                                {...field}
                                                value={instrumentTypeValue}
                                                options={instrumentTypeData}
                                                onChange={(e, selectedOptions) => handleSelectInstrument(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Investment Instrument Type *" />}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        } />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.investmentInstrumentType && errors.investmentInstrumentType.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="businessModels" control={control} defaultValue="" rules={{ required: 'Business Models is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                limitTags={2}
                                                {...field}
                                                value={businessModelsValue}
                                                options={businessModelsData}
                                                onChange={(e, selectedOptions) => handleSelectBusiness(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Business Models *" />}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        } />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.businessModels && errors.businessModels.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="startupEvaluation" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="What do you value the most when evaluating a startup?"
                                            placeholder='E.g., Team, Product, Market Size, etc.'
                                            fullWidth
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.startupEvaluation && errors.startupEvaluation.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="investmentCharacterstics" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                limitTags={2}
                                                {...field}
                                                value={investmentCharactersticsValue}
                                                options={investmentData}
                                                onChange={(e, selectedOptions) => handleSelectInvestment(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Investment Characteristic you look into startups" />}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        } />
                                    <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                    <FormHelperText className='text-danger'>{errors.investmentCharacterstics && errors.investmentCharacterstics.message}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="comments" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            multiline
                                            label="Any additional comments or information you would like to share"
                                            placeholder='Enter comments'
                                            fullWidth
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3">Cancel</Button>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            className="btn btn-primary submit px-3 ml-3"
                        >
                            <span>Save & Proceed Next</span>
                        </LoadingButton>

                    </div>
                </form>
            </div>
        </section>
    )
}

export default Investorpreference
import { AppBar, Button, Dialog, DialogTitle, IconButton, Toolbar, Typography } from '@mui/material';
import React, { useContext, useState } from 'react'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { Worker, Viewer, SpecialZoomLevel, Icon, MinimalButton, Position, Tooltip } from '@react-pdf-viewer/core';
import CloseIcon from '@mui/icons-material/Close';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';
const ViewPitchDeck = ({ id, viewPitchValue }) => {
    const { setPageLoading } = useContext(DataContext)
    const [openModal, setOpenModal] = useState(false);
    const [pitchdeckValue, setPitchdeckValue] = useState('')
    const viewBtn = () => {
        setOpenModal(true);
        getApi()
    }
    const getApi = () => {
        setPageLoading(true)
        apiService(`startup/pitchdeck/get?startupid=${id}`, '', 'get').then((result) => {
            setPageLoading(false)
            if (result && result.data && result.data.pitchDeck) {
                setPitchdeckValue(result.data.pitchDeck)
            }
        }).catch((err) => {

        });
    }
    const pdfUrl = `data:image/pdf;base64,${pitchdeckValue}`
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const workerSrc = ` https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
    // https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js
    const disableScrollPlugin = () => {
        const renderViewer = ({ slot }) => {
            if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
                slot.subSlot.attrs.style = {
                    ...slot.subSlot.attrs.style,
                    overflow: 'hidden',
                };
            }

            return slot;
        };

        return {
            renderViewer,
        };
    };

    const disableScrollPluginInstance = disableScrollPlugin();
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { GoToPreviousPage, GoToNextPage } = pageNavigationPluginInstance;
    return (
        <div>
            {
                viewPitchValue ? (
                    <Button onClick={() => viewBtn()}>View Pitch Deck</Button>
                ) : (`-`)
            }
            <Dialog open={openModal} fullScreen>
                <AppBar sx={{ position: 'relative', backgroundColor: '#253B80', boxShadow: '0px 0px 5px gainsboro' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Pitch Deck File
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseModal}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <section className='p-5'>                   
                    {
                        viewPitchValue && (
                            <>
                                <div className='full-pdf' >
                                    <Worker workerUrl={workerSrc}>
                                        <Viewer
                                            fileUrl={pdfUrl}
                                            defaultScale={SpecialZoomLevel.PageFit}
                                            plugins={[
                                                disableScrollPluginInstance,
                                                pageNavigationPluginInstance,
                                            ]}
                                        />
                                    </Worker>
                                    {
                                        pitchdeckValue && (
                                            <>
                                                <div className='pdf-left-arrow'>
                                                    <GoToPreviousPage>
                                                        {(props) => (
                                                            <Tooltip
                                                                position={Position.BottomCenter}
                                                                target={
                                                                    <MinimalButton onClick={props.onClick}>
                                                                        <Icon size={16}>
                                                                            <path d="M18.4.5,5.825,11.626a.5.5,0,0,0,0,.748L18.4,23.5" />
                                                                        </Icon>
                                                                    </MinimalButton>
                                                                }
                                                                content={() => 'Previous page'}
                                                                offset={{ left: 0, top: 8 }}
                                                            />
                                                        )}
                                                    </GoToPreviousPage>
                                                </div>
                                                <div className='pdf-right-arrow' >
                                                    <GoToNextPage>
                                                        {(props) => (
                                                            <Tooltip
                                                                position={Position.BottomCenter}
                                                                target={
                                                                    <MinimalButton onClick={props.onClick}>
                                                                        <Icon size={16}>
                                                                            <path d="M5.6.5,18.175,11.626a.5.5,0,0,1,0,.748L5.6,23.5" />
                                                                        </Icon>
                                                                    </MinimalButton>
                                                                }
                                                                content={() => 'Next page'}
                                                                offset={{ left: 0, top: 8 }}
                                                            />
                                                        )}
                                                    </GoToNextPage>
                                                </div>
                                            </>
                                        )
                                    }

                                </div>
                            </>
                        )
                    }
                </section>
            </Dialog>

        </div>
    )
}

export default ViewPitchDeck
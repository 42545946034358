import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import './scst-page/scst.css'
import { NavLink } from 'react-router-dom';
import DataContext from '../../context/DataContext';

const Scsthubavpnavbar = ({ role }) => {
  const { scstRemoveFiters } = useContext(DataContext)
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
    scstRemoveFiters()
  };
  return (
    <nav>
      <div className='submenu_div'>
        <div className='container-fluid'>
          <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to={`/${role}/startups`} onClick={handleClick}>Startups</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to={`/${role}/evaluator`} onClick={handleClick}>Evaluators</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to={`/${role}/assign-evaluator`} onClick={handleClick}>Assign Evaluators</NavLink>
            </Typography>
            {/* <Typography sx={{ minWidth: 100 }}>

              <NavLink className="nav-link" to={`/${role}/report`} onClick={handleClick}>Report</NavLink>

            </Typography> */}
          </Box>
        </div>
      </div>
    </nav>
  )
}

export default Scsthubavpnavbar
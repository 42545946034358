import React, { useEffect, useContext, useState } from 'react';
import { Checkbox, Container, Tab } from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import apiService from '../../../api/apiService';
import TablePagination from "@mui/material/TablePagination";
import { Autocomplete } from '@mui/material'
import { TextField } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// import downloadExcel from '../../../../hooks/downloadExcel';
import { Link } from 'react-router-dom';
import DataContext from '../../../context/DataContext';
// import Reviewupdate from '../../../partner-module/startup-page/startup-list-page/Reviewupdate';
// import Reviewupdate from './Reviewupdate';
import AddCommentIcon from '@mui/icons-material/AddComment';
// import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useDidMountEffect from '../../../hooks/useDidMountEffect';// import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import Reviewupdate from '../../partner-module/startup-page/startup-list-page/Reviewupdate';
import SearchIcon from '@mui/icons-material/Search';

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import CustomLink from '../../common-page/CustomLink';
import Scsthubavpremarks from '../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';
import OnBoradFilter from '../../scsthubavp-module/onboraded-details/OnBoradFilter';
const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
const Juryassociatedlist = ({ name, path, endpoint, value }) => {

    const { modalstyle, startuplistItem,
        tableIndexValue, setPageLoading } = useContext(DataContext)
    const [checkedList, setCheckedList] = useState([]);
    const [itemsChecked, setItemsChecked] = useState(false);
    const [indexValue, setIndexValue] = useState([])
    // const [districtName, setDistrictName] = useState('')
    useDidMountEffect(() => {
        getCityApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [cityList, setCityList] = useState([])
    const getCityApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=district`, '', 'get').then((res) => {
            if (res) {
                if (res.data) {
                    var val = res.data.dropDownValues;
                    setCityList(val.districts)
                }
            }

        })
    }

    const [count, setCount] = useState(0);
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [searchInputValue, setSearchInputValue] = useState('');
    // const [scsthub, setScsthub] = useState("");
    // const [yearName, setYearName] = useState(2022);
    // const [monthName, setMonthName] = useState(0);
    // const [manualName, setManualName] = useState(0)
    // const [statusValue, setStatusValue] = useState(1)
    var pageNumberPartners = Number(localStorage.getItem('pageScst'))
    const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

    var listSizePartners = Number(localStorage.getItem('rowsPerPageScst'))
    const [rowsPerPage, setRowsPerPage] = useState(listSizePartners ? listSizePartners : 10);
    const juryID = localStorage.getItem('userId')
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        localStorage.setItem('pageScst', newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        localStorage.setItem('rowsPerPageScst', parseInt(event.target.value, 10))
        localStorage.setItem('pageScst', 0)
        setPage(0);
    };

    var districtNameScst = localStorage.getItem('districtNameScst')
    const [districtName, setDistrictName] = useState(districtNameScst ? districtNameScst : '')

    var hubScst = localStorage.getItem('hubScst')
    const [scsthub, setScsthub] = useState(hubScst ? hubScst : "");
    const d = new Date();
    let year = d.getFullYear();
    var yearNameScst = Number(localStorage.getItem('yearNameScst'))
    const [yearName, setYearName] = useState(yearNameScst ? yearNameScst : 'All');

    var toYearNameScst = Number(localStorage.getItem('toYearNameScst'))
    const [toYearName, setToYearName] = useState(toYearNameScst ? toYearNameScst : 'All');

    var monthNameScst = Number(localStorage.getItem('monthNameScst'))
    const [monthName, setMonthName] = useState(monthNameScst ? monthNameScst : 0);

    var toMonthNameScst = Number(localStorage.getItem('toMonthNameScst'))
    const [toMonthName, setToMonthName] = useState(toMonthNameScst ? toMonthNameScst : 0);

    var manualNameScst = Number(localStorage.getItem('manualNameScst'))
    const [manualName, setManualName] = useState(manualNameScst ? manualNameScst : 0)

    var searchInputValueScst = localStorage.getItem('searchInputValueScst')
    const [searchInputValue, setSearchInputValue] = useState(searchInputValueScst ? searchInputValueScst : '');

    var phaseValueScst = Number(localStorage.getItem('phaseValueScst'))
    const [phaseValue, setPhaseValue] = useState(phaseValueScst ? phaseValueScst : 'Evaluation')

    var statusValueScst = Number(localStorage.getItem('statusValueScst'))
    const [statusValue, setStatusValue] = useState(statusValueScst ? statusValueScst : 100)
    useDidMountEffect(() =>{
        setSearchInputValue('')
    },[])
    useDidMountEffect(() => {
        listApi()
        // eslint-disable-next-line
    }, [checkedList, indexValue, itemsChecked, page, rowsPerPage, districtName, searchInputValue, yearName, toYearName,
        monthName, toMonthName, manualName, phaseValue, statusValue])
 
    const handleSearch = (searchValue) => {
        setSearchInputValue(searchValue)
    }
    const [loading, setLoading] = useState(false);
    const [startupListData, setStartupListData] = useState([])
    const listApi = async () => {

        setStartupListData([])
        setPageLoading(true);
        var req
        let url
        if (value === 'scst') {
            url = 'partner/scst/admin/startups/list'
            req = {
                juryID:juryID,
                listSize: rowsPerPage,
                pageNumber: page + 1,
                fund: value,
                district: districtName,
                searchString: searchInputValue,
                onboardType: manualName,
                status: statusValue
            }
        } else {
            url = 'jury/startup/list'
            req = {
                listSize: rowsPerPage,
                pageNumber: page + 1,
                fund: value,
                district: districtName,
                searchString: searchInputValue,
                fromYear: yearName === 'All' ? 0 : yearName,
                toYear: toYearName === 'All' ? 0 : toYearName,
                fromMonth: monthName,
                toMonthName: toMonthName,
                onboardType: manualName,
                status: statusValue
            }
        }
        
        apiService(url, req, 'post').then((res) => {
            setPageLoading(false);
           
            if (res) {
                setCount(res.data.count);
                if (res.data.startups) {
                    setStartupListData(res.data.startups)
                }
            }
        })
    }

    const handleClear = () => {
        setDistrictName('')
    }

    const [openView, setOpenView] = useState(false);
    const [getData, setSetGetData] = useState(0);
    const handleViewOpen = (id, selected, remarks) => {
        var req = {
            "id": id,
            "selected": selected,
            "remarks": remarks
        }
        setSetGetData(req)
        setOpenView(true)
    }
    const handleViewClose = () => {
        setOpenView(false);
        startuplistItem(name, endpoint)
    }
    // const promoteRound = (id) => {
    //     const collection = [];
    //     collection.push(id);
    //     var val = collection
    //     startRoundsApi(val)
    // }
    const selectStartRound = () => {
        var val = checkedList
        startRoundsApi(val)
    }
    const startRoundsApi = (val) => {

        // var req={
        //     "startupId":val
        // }
        // 
        apiService(`partner/startups/startround`, val, 'post').then((res) => {
            if (res.data.responseStatus === "Success") {
                setCheckedList([]);
                setItemsChecked(false);
                startuplistItem(name, endpoint)
            }

        })
    }


    const handleCheckboxClick = (e, index) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckedList([...checkedList, value * 1]);
            setIndexValue([indexValue, index])
            sectFgs()
        } else {
            // indexValue
            setIndexValue(indexValue.filter((item) => item !== index))
            setCheckedList(checkedList.filter((item) => item !== value));

            if (checkedList.length === 1) {
                setItemsChecked(false);
                setCheckedList([])

            } else {

            }
            // sectFgs()
        }
    };
    const sectFgs = () => {
        console.log(indexValue.length)
        if (startupListData.length === indexValue.length) {
            setItemsChecked(true);
        } else {
            setItemsChecked(false);
        }
    }
    const selectItem = (e) => {
        const { checked } = e.target;
        const collection = [];
        if (checked) {
            for (const category of startupListData) {
                collection.push(category.startupId);
            }
        }
        setCheckedList(collection);
        setItemsChecked(checked);

    };
    const getStatus = (num) => {
        var value = Number(num)
        if (value === 0) {
            return 'Pending';
        } else if (value === 1) {
            return 'No';
        } else if (value === 2) {
            return 'Maybe';
        } else if (value === 3) {
            return 'Yes';
        } else if (value === 4) {
            return `Can't Review`;
        }
    };
    return (
        <section className=''>
            <div className='container-fluid'>
                <div className='form_div chart'>
                    <div>
                        {
                            value === "scst" ? (
                                // <>
                                //     <Autocomplete
                                //         sx={{ width: '200px' }}
                                //         disableClearable
                                //         disablePortal
                                //         value={districtName}
                                //         options={cityList}
                                //         onChange={(e, selectedOptions) => handleSelectCity(selectedOptions)}
                                //         getOptionLabel={(option) => option}
                                //         renderInput={(params) => <TextField {...params} label="District" />}
                                //     />
                                //     {
                                //         districtName && (
                                //             <div>
                                //                 <Button
                                //                     variant="contained"
                                //                     sx={{ marginLeft: "10px" }}
                                //                     onClick={() => handleClear()}
                                //                 >
                                //                     Clear
                                //                 </Button>
                                //             </div>
                                //         )
                                //     }
                                // </>
                                <>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        {/* <h5>Associated Startups</h5> */}
                                        {/* <div className="ml-auto d-flex align-items-center">
                                            <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                                                <SearchIconWrapper>
                                                    <SearchIcon />
                                                </SearchIconWrapper>
                                                <StyledInputBase
                                                    placeholder="Search by Name"
                                                    type="search"
                                                    inputProps={{ 'aria-label': 'Search by Name' }}
                                                />
                                            </SearchinputDiv>
                                        </div> */}
                                    </div>
                                    <div className='ml-auto mt-3'>
                                        <OnBoradFilter
                                            setPage={setPage}
                                            districtName={districtName} setDistrictName={setDistrictName}
                                            scsthub={scsthub} setScsthub={setScsthub}
                                            yearName={yearName} setYearName={setYearName}
                                            toYearName={toYearName} setToYearName={setToYearName}
                                            monthName={monthName} setMonthName={setMonthName}
                                            toMonthName={toMonthName} setToMonthName={setToMonthName}
                                            manualName={manualName} setManualName={setManualName}
                                            path={path}
                                            searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue}
                                            phaseValue={phaseValue} setPhaseValue={setPhaseValue}
                                            statusValue={statusValue} setStatusValue={setStatusValue}
                                            lblName='Assigned Startups'
                                        />
                                    </div>
                                </>
                            ) : (<>
                                <div className='header_tag'>
                                    <h5>Associated Startups</h5>
                                    <div className="ml-auto d-flex align-items-center">
                                        <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search by Name"
                                                type="search"
                                                inputProps={{ 'aria-label': 'Search by Name' }}
                                            />
                                        </SearchinputDiv>
                                    </div>
                                </div>
                            </>)
                        }

                    </div>
                    <div className='table_tag'>
                        <div className='table_header_tag d-flex'>
                            {
                                (checkedList.length > 0 && (
                                    <Button className='' variant="outlined" onClick={selectStartRound}>Start Round</Button>
                                ))
                            }
                        </div>
                        {
                            (startupListData.length) ? (
                                <TableContainer component={Paper}>
                                    <TablePagination
                                        component="div"
                                        count={count}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        style={{ float: 'left' }}
                                    />
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>No</TableCell>
                                                <TableCell>Startup Name</TableCell>
                                                <TableCell>
                                                    {
                                                        value === 'tanseed' ? 'Partner Name' : 'Hub Name'
                                                    }
                                                </TableCell>
                                                {
                                                    value === 'tanseed' ? (
                                                        <>
                                                            {/* <TableCell align='center'>Average Score</TableCell>
                                                            <TableCell align='center'>Recommended</TableCell>
                                                            <TableCell>Remarks</TableCell> */}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <TableCell>District</TableCell>
                                                            <TableCell>Entity</TableCell>
                                                            <TableCell>Onboarded Type</TableCell>
                                                            <TableCell>Date</TableCell>
                                                            <TableCell align='center'>Status</TableCell>
                                                        </>

                                                    )
                                                }


                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {startupListData.map((list, index) => (
                                                <TableRow key={index}>

                                                    <TableCell align='center'>{
                                                        tableIndexValue(rowsPerPage, page, index)
                                                    }</TableCell>

                                                    <TableCell>
                                                        <CustomLink to={`/${path}/details/${btoa(list.startupId)}/${btoa(list.partnerOrInvestorId)}`}>{list.startupName}</CustomLink>
                                                    </TableCell>
                                                    <TableCell>{list.partnerName ? (list.partnerName) : ('-')}</TableCell>
                                                    {
                                                        value === 'tanseed' ? (
                                                            <>
                                                                {/* <TableCell align='center'>{list.score}</TableCell>
                                                                <TableCell align='center'>{getStatus(list.status)}</TableCell>
                                                                <TableCell>{list.remarks ? (<Scsthubavpremarks remarks={list.remarks} />) : '-'}</TableCell> */}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TableCell>{list.district}</TableCell>
                                                                <TableCell>{list.entityType}</TableCell>
                                                                <TableCell>
                                                                    {
                                                                        list.onboardType === 1 ? 'Manual' : 'Portal'
                                                                    }
                                                                </TableCell>
                                                                <TableCell>{list.date}</TableCell>
                                                                <TableCell align='center'>{list.status}
                                                                    {/* {
                                                                        (list.selected === 1) ? (<span className='yes_span'>Yes</span>) : <></>
                                                                    }
                                                                    {
                                                                        (list.selected === 2) ? (<span className='no_span'>No</span>) : <> </>
                                                                    }
                                                                    {
                                                                        (list.selected === 0) ? (<span className='review_span'>NA</span>) : <></>
                                                                    } */}
                                                                </TableCell>
                                                            </>

                                                        )
                                                    }


                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <caption>
                                            <TablePagination
                                                component="div"
                                                count={count}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                style={{ float: 'left' }}
                                            /></caption>
                                    </Table>

                                </TableContainer>
                            ) : (
                                <div className='nodata_tag'>
                                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                    <h4>A Startups will be assigned by Investor</h4>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <Modal
                open={openView}
                onClose={handleViewClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Reviewupdate handleViewClose={handleViewClose} getData={getData} />
                </Box>
            </Modal>
        </section>
    )
}

export default Juryassociatedlist
import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './investornavbar.css'
import { NavLink } from 'react-router-dom';

const Investornavbar = () => {
  let tanseedlist = localStorage.getItem('tanseed')
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
    }
  };
  return (
    <nav>
      <div className='submenu_div'>
        <div className='container-fluid'>
          <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/investor/associated-startups" onClick={handleClick}>Associated Startups</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/investor/jury" onClick={handleClick}>Jury</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/investor/round" onClick={handleClick}>Rounds</NavLink>
            </Typography>
            <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link"  to="/investor/profile" onClick={handleClick}>Profile</NavLink>
            </Typography>
            {/* <Typography sx={{ minWidth: 100 }}>
              <NavLink className="nav-link" to="/investor/tanseed" onClick={handleClick}>Tanseed</NavLink>
            </Typography> */}
            {/* {tanseedlist === 'true' ? (
              <Typography sx={{ minWidth: 100 }}>
                <NavLink className="nav-link" to="/investor/tanseed" onClick={handleClick}>
                  Tanseed
                </NavLink>
              </Typography> 
              ) : (<></>)
            } */}
          </Box>
        </div>
      </div>
    </nav>
  )
}

export default Investornavbar
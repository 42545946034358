import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Modal, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../context/DataContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Fundingprograms from './modal-form/Fundingprograms';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
// import Fundingprograms from '../../form-page/modal-form/Fundingprograms';
const Fundingfinancials = () => {
    const [formData, setFormData] = useState({
        // fundHistory: [{}]
    });
    const { handleBack, navigator, MenuProps, Paper, modalstyle, icon, checkedIcon } = useContext(DataContext)
    const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        fields: fundFields,
        append: fundAppend,
        remove: fundRemove
    } = useFieldArray({
        control,
        name: "fundHistory"
    });
    const [fundingProgramDetails, setFundingProgramDetails] = useState([])
    // let preferredInvestorTypeList = []
    // let investmentTicketSize = []

    useDidMountEffect(() => {
        // eslint-disable-next-line                
        (async () => await getApi())()
        dropdownValueApi()
        // eslint-disable-next-line
    }, [])
    const [preferredInvestorTypeList, setPreferredInvestorTypeList] = useState([])
    const [investmentTicketSize, setInvestmentTicketSize] = useState([])
    const [instrumentTypeData, setInstrumentTypeData] = useState([])
    const [revenuesList, setLevenuesList] = useState([])
    const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState([])
    const [fundingProgramsList, setfundingProgramsList] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setPreferredInvestorTypeList(val.prefInvestorTypes)
            setInvestmentTicketSize(val.desiredFundingAmount)
            setInstrumentTypeData(val.prefFundingInstruments)
            setLevenuesList(val.revenueStages)
            setStartupinstrumentTypeList(val.previousFundHistoryInstruments)
            setfundingProgramsList(val.fundingPrograms)
            debugger
        })
    }
    const getApi = async () => {
        await apiService(`startup/fundingandfinancials/get`, '', 'get').then((res) => {
            if (res) {
                setFormData({})
                let response = res.data
                if (response.id !== 0) {

                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                    if (response.startupFundingPrograms) {
                        setFundingProgramDetails(response.startupFundingPrograms)
                    }
                    if (response.preferredInvestorType) {
                        setPreferredInvestorTypeValue(response.preferredInvestorType);
                    }
                    // if (response.fundingStage) {
                    //     setFundingStageValue(response.fundingStage)
                    // }
                    // if (response.investTicketSize) {
                    //     setInvestTicketSizeValue(response.investTicketSize)
                    // }
                    if (response.investmentInstrumentType) {
                        setInvestmentInstrumentTypeValue(response.investmentInstrumentType)
                    }

                } else {
                    setFormData(response)
                }
            }
        })
    }
    // const checkFundingValidation = () => {
    //     if (fundingProgramDetails.length !== 0) {
    //         setFundingProgramInvalid(false)
    //     } else {
    //         setFundingProgramInvalid(true)
    //     }
    // }
    const addFundDetailsBtn = () => {
        fundAppend({
            fundRaised: "",
            organisationName: "",
            instrumentType: ""
        })
    }
    const [fundingProgramIndex, setFundingProgramIndex] = useState('')
    const addFundingProgramBtn = (index) => {
        setFundingProgramIndex(index)
        handleOpen()
    }
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const fundingStageList = [
    //     "Bootstrapped",
    //     "Friends & Family",
    //     "Seed/Angle Funded",
    //     "Pre-Series",
    //     "Series A or Beyond",
    // ];
    // const [fundingStageValue, setFundingStageValue] = useState(undefined);
    // const handleSelectfundingStage = (value) => {
    //     // setFundingStageValue(value);
    //     setValue('fundingStage', value)
    //     clearErrors('fundingStage');
    // };

    const [preferredInvestorTypeValue, setPreferredInvestorTypeValue] = useState([]);
    const handleSelectPreferredInvestorType = (value) => {
        setPreferredInvestorTypeValue(value);
        setValue('preferredInvestorType', value)
        clearErrors('preferredInvestorType');
    };

    const handleSelectRevenues = (value) => {
        setValue('revenueStage', value)
        clearErrors('revenueStage');
    };

    // const [investTicketSizeValue, setInvestTicketSizeValue] = useState('');
    const handleSelectTicketSize = (value) => {
        // setInvestTicketSizeValue(value);
        setValue('investTicketSize', value)
        clearErrors('investTicketSize');
    };

    const [investmentInstrumentTypeValue, setInvestmentInstrumentTypeValue] = useState([]);
    const handleSelectInvestment = (value) => {
        setInvestmentInstrumentTypeValue(value);
        setValue('investmentInstrumentType', value)
        clearErrors('investmentInstrumentType');
    };

    // const [fundingProgramInvalid, setFundingProgramInvalid] = useState(false);
    // delete funding
    const deleteFundingProgram = (id, indexToDelete) => {
        if (id !== '0') {
            apiService(`startup/fundingprograms/remove?id=${id}`, '', 'get').then((res) => {
                if (res && res.data.responseStatus === 'Success') {
                    const updatedFundingPrograms = fundingProgramDetails.filter((_, index) => index !== indexToDelete);
                    setFundingProgramDetails(updatedFundingPrograms);
                    getApi();
                }
            });
        } else {
            const updatedFundingPrograms = fundingProgramDetails.filter((_, index) => index !== indexToDelete);
            setFundingProgramDetails(updatedFundingPrograms);
        }
    }
    // delete Tractions
    const deleteFundingHistory = async (index) => {
        const id = getValues(`fundHistory.${index}.id`);
        if (id !== undefined) {
            apiService(`startup/fundhistory/remove?id=${id}`, '', 'get').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        fundRemove(index)
                        getApi()
                    }
                }
            })
        } else {
            fundRemove(index)
        }
    }
    const onSubmit = (data) => {
        // checkFundingValidation()
        if (readyOnlyValue === false) {
            setLoading(true);
            var getform = data
            if (fundingProgramDetails.length > 0) {
                getform.startupFundingPrograms = fundingProgramDetails
            } else {
                getform.startupFundingPrograms = []
            }

            apiService('startup/fundingandfinancials/save', getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        navigator('/startup/team')
                    }
                }
            })
        }
    };
    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBack}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Funding & Financials</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                            {/* <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="tentativeValuation" control={control} defaultValue="" rules={{ required: 'Expected Valuation is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Expected Valuation *"
                                                placeholder='Enter Expected Valuation'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.tentativeValuation && errors.tentativeValuation.message}</FormHelperText>
                                </div>
                            </div> */}
                            <div className='col-lg-4 col-12 '>
                                <div className="form-group mt-2">
                                    <FormControl sx={{ width: '100%' }}>
                                        <Controller name="preferredInvestorType" control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    multiple={true}
                                                    value={preferredInvestorTypeValue}
                                                    limitTags={2}
                                                    options={preferredInvestorTypeList}
                                                    onChange={(e, selectedOptions) => handleSelectPreferredInvestorType(selectedOptions)}
                                                    renderInput={(params) => <TextField {...params} label="Preferred Investor Type" />}
                                                    disabled={readyOnlyValue}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option}
                                                        </li>
                                                    )}
                                                />
                                            } />
                                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                        <FormHelperText className='text-danger'>{errors.preferredInvestorType && errors.preferredInvestorType.message}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                            {/* <div className='col-lg-4 col-12 '>
                        <div className="form-group mt-2">
                            <FormControl sx={{ width: '100%' }}>
                                <Controller name="fundingStage" control={control} defaultValue="" rules={{ required: 'Funding Stage is required' }}
                                    render={({ field }) =>
                                        <Autocomplete
                                            disableClearable
                                            disablePortal
                                            {...field}

                                            limitTags={2}
                                            options={fundingStageList}
                                            onChange={(e, selectedOptions) => handleSelectfundingStage(selectedOptions)}
                                            renderInput={(params) => <TextField {...params} label="Funding Stage *" />}
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className='text-danger'>{errors.fundingStage && errors.fundingStage.message}</FormHelperText>
                            </FormControl>
                        </div>
                    </div> */}
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <FormControl sx={{ width: '100%' }}>
                                        <Controller name="investTicketSize" control={control} defaultValue="" rules={{ required: 'Desired Funding Amount is required' }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    {...field}
                                                    options={investmentTicketSize}
                                                    onChange={(e, selectedOptions) => handleSelectTicketSize(selectedOptions)}
                                                    renderInput={(params) => <TextField {...params} label="Desired Funding Amount (₹)*" />}
                                                    disabled={readyOnlyValue}
                                                />
                                            } />
                                        <FormHelperText className='text-danger'>{errors.investTicketSize && errors.investTicketSize.message}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <FormControl sx={{ width: '100%' }}>
                                        <Controller name="investmentInstrumentType" control={control} defaultValue="" rules={{ required: 'Preferred Funding Instrument is required' }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    multiple
                                                    {...field}
                                                    limitTags={2}
                                                    value={investmentInstrumentTypeValue}
                                                    options={instrumentTypeData}
                                                    onChange={(e, selectedOptions) => handleSelectInvestment(selectedOptions)}
                                                    renderInput={(params) =>
                                                        <TextField {...params} label="Preferred Funding Instrument *" />
                                                    }
                                                    disabled={readyOnlyValue}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option}
                                                        </li>
                                                    )}
                                                />
                                            } />
                                        <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                        <FormHelperText className='text-danger'>{errors.investmentInstrumentType && errors.investmentInstrumentType.message}</FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                            {/* <div className='col-lg-4 col-12'>
                        <div className="form-group mt-2">
                            <FormControl sx={{ width: '100%' }}>
                                <Controller name="stakeHoldersEquity" control={control} defaultValue="" rules={{ required: 'Stakeholders Equity is required' }}
                                    render={({ field }) =>
                                        <Autocomplete
                                            
                                            disableClearable
                                            disablePortal
                                            {...field}
                                            limitTags={2}
                                            options={stakeHoldersEquityList}
                                            onChange={(e, selectedOptions) => handleSelectStakeHoldersEquity(selectedOptions)}
                                            renderInput={(params) =>
                                                <TextField {...params} label="Stakeholders Equity *" />
                                            }
                                            disabled={readyOnlyValue}
                                        />
                                    } />
                                <FormHelperText className='text-danger'>{errors.stakeHoldersEquity && errors.stakeHoldersEquity.message}</FormHelperText>
                            </FormControl>
                        </div>
                    </div> */}
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="revenueStage" control={control} defaultValue="" rules={{ required: 'Revenue Stage is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                options={revenuesList}
                                                onChange={(e, selectedOptions) => handleSelectRevenues(selectedOptions)}
                                                renderInput={(params) =>
                                                    <TextField {...params} label="Revenue Stage *" />
                                                }
                                                disabled={readyOnlyValue}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.revenueStage && errors.revenueStage.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="annualRevenue" control={control} defaultValue="" rules={{ required: 'Annual Revenue is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Annual Revenue (₹)*"
                                                placeholder='Enter Annual Revenue (₹)'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.annualRevenue && errors.annualRevenue.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="monthlyBurnRate" control={control} defaultValue="" rules={{ required: 'Monthly Burn Rate is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Monthly Burn Rate  (₹)*"
                                                placeholder='Enter Monthly Burn Rate (₹)'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.monthlyBurnRate && errors.monthlyBurnRate.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="totalFundsRaised" control={control} defaultValue="" rules={{ required: 'Total Funds Raised till Date is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Total Funds Raised till Date (₹) *"
                                                placeholder='Enter Total Funds Raised till Date (₹)'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.totalFundsRaised && errors.totalFundsRaised.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='div_header'>
                                    <div className='d-flex'>
                                        <h6>Previous Fundraising History</h6>
                                        <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addFundDetailsBtn('')}>Add Fundraising History</Button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        (fundFields.length > 0) ? (
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                    {/* <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Fund Raised *</TableCell>
                                                    <TableCell>Organization Name *</TableCell>
                                                    <TableCell>Instrument Type *</TableCell>
                                                    <TableCell align='center'>Action</TableCell>
                                                </TableRow>
                                            </TableHead> */}
                                                    <TableBody>
                                                        {fundFields.map((field, index, type) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <Controller name={`fundHistory.${index}.fundRaised`} control={control} defaultValue="" rules={{ required: 'Fund Raised is required' }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Fund Raised (₹)*"
                                                                                    placeholder='Enter Fund Raised (₹)'
                                                                                    fullwidth
                                                                                    {...field}
                                                                                    inputProps={
                                                                                        { readOnly: readyOnlyValue, }
                                                                                    }
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.fundHistory?.[index]?.fundRaised && errors.fundHistory?.[index]?.fundRaised.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <Controller name={`fundHistory.${index}.organisationName`} control={control} defaultValue="" rules={{ required: 'Organization Name is required' }}
                                                                            render={({ field }) =>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Organization Name *"
                                                                                    placeholder='Enter Organization Name'
                                                                                    fullwidth
                                                                                    {...field}
                                                                                    inputProps={
                                                                                        { readOnly: readyOnlyValue, }
                                                                                    }
                                                                                />} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.fundHistory?.[index]?.organisationName && errors.fundHistory?.[index]?.organisationName.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell sx={{ width: '25%' }}>
                                                                    <FormControl sx={{ width: '100%' }} >
                                                                        <InputLabel htmlFor="grouped-native-select">Instrument Type *</InputLabel>
                                                                        <Controller name={`fundHistory.${index}.instrumentType`} control={control} defaultValue="" rules={{ required: 'Instrument Type is required' }}
                                                                            render={({ field }) =>
                                                                                <Select
                                                                                    {...field}
                                                                                    readOnly={readyOnlyValue}
                                                                                    defaultValue={type}
                                                                                    input={<OutlinedInput label="Name" />}
                                                                                    MenuProps={MenuProps}
                                                                                >
                                                                                    {startupinstrumentTypeList && startupinstrumentTypeList.map((name) => (
                                                                                        <MenuItem
                                                                                            key={name}
                                                                                            value={name}
                                                                                        >
                                                                                            {name}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>} />
                                                                        <FormHelperText className='text-danger'>
                                                                            {errors.fundHistory?.[index]?.instrumentType && errors.fundHistory?.[index]?.instrumentType.message}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteFundingHistory(index) : <></> }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>

                                            </TableContainer>
                                        ) : (
                                            <div className='nodata_tag'>
                                                <p>Fundraising history details is empty</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='div_header'>
                                    <div className='d-flex'>
                                        <h6>Funding Programs</h6>
                                        <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addFundingProgramBtn('')}>Add Funding Program</Button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        (fundingProgramDetails.length) ? (
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>No</TableCell>
                                                            <TableCell>Type</TableCell>
                                                            <TableCell>Partner</TableCell>
                                                            <TableCell>Program</TableCell>
                                                            <TableCell align='center'>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {fundingProgramDetails.map((tableData, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{tableData.type}</TableCell>
                                                                <TableCell>{tableData.partner}</TableCell>
                                                                <TableCell>{tableData.program}</TableCell>
                                                                <TableCell align='center'>
                                                                    <EditIcon style={{ fill: '#66bb6a', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? addFundingProgramBtn(index) : <></> }} />
                                                                    <DeleteIcon style={{ fill: '#dc3545', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteFundingProgram(tableData.id, index) : <></> }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>

                                            </TableContainer>
                                        ) : (<div className='nodata_tag'>
                                            <p>Funding details is empty</p>
                                        </div>)
                                    }
                                    {/* {(fundingProgramInvalid === true) ? (<><span className='text-danger'>Funding Programs is required</span></>) : (<></>)} */}

                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <Button variant="contained" onClick={handleBack} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Proceed Next</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                    <Modal
                        open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalstyle} className="form_tag_lg">
                            <Fundingprograms handleClose={handleClose} fundingProgramDetails={fundingProgramDetails} setFundingProgramDetails={setFundingProgramDetails} fundingProgramIndex={fundingProgramIndex} fundingProgramsList={fundingProgramsList} />
                        </Box>
                    </Modal>
                </div>
            </section>
        </>
    )
}

export default Fundingfinancials
import React, { useContext, useState } from 'react'
import apiService from '../../../../api/apiService'
import { useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Startupappication from './details-components/Startupappication';
import Startupstartdetails from './details-components/Startupstartdetails';
import Reviewrounds from './details-components/Reviewrounds';
import Reviewquestion from './details-components/Reviewquestion';
import Moreinfo from './details-components/Moreinfo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './startupdetails.css'
import DataContext from '../../../../context/DataContext';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Startupdetails = ({ name, path, endpoint }) => {
    const { navigator } = useContext(DataContext)
    const { startupId } = useParams()
    const { id } = useParams()
    let idNumber = atob(startupId)
    let partnerOrInvestorId = atob(id)
    const [startupGetData, setStartupGetData] = useState({})
    useDidMountEffect(() => {
         // eslint-disable-next-line
        viewStartupDetails(idNumber)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idNumber])

    const viewStartupDetails = async (id) => {
        if (name === 'jury') {
            var req = {
                "startupId": Number(id),
                "partnerOrInvestorId": Number(partnerOrInvestorId)
            }
            apiService(`${name}/${endpoint}/get`, req, 'post').then((res) => {
                if (res) {
                    if (res.data) {
                        setStartupGetData(res.data)
                        if (res.data.showRounds === true) {
                            roundJuryList()
                        }
                        if (res.data.showQuestions === true) {
                            questionList()
                        }
                    }
                }
            })
        } else {
            apiService(`${name}/${endpoint}/get?id=${id}`, '', 'get').then((res) => {
                if (res) {
                    if (res.data) {
                        setStartupGetData(res.data)
                        if (res.data.showRounds === true) {
                            roundPartnerList()
                        }
                    }
                }
            })
        }
        infoGetInfo()
    }
    const [viewFile, setViewFile] = useState('')
    const [additionalInfo, setAdditionalInfo] = useState({})
    const infoGetInfo = () => {
        var id = Number(idNumber)
        apiService(`startup/partner/additionalinfo/get?id=${id}`, '', 'get').then((res) => {
            if (res) {
                setAdditionalInfo(res.data)
                setViewFile(res.data.additionalDocName)
            }
        })
    }
    const [roundListData, setRoundListData] = useState([])
    const roundJuryList = () => {
        var req = {
            "startupId": Number(idNumber),
            "partnerId": Number(partnerOrInvestorId),
        }
        apiService(`jury/panelround/review/list`, req, 'post').then((res) => {
            if (res) {
                if (res.data.rounds) {
                    setRoundListData(res.data.rounds)
                } else {
                    setRoundListData([])
                }

            }
        })
    }
    const roundPartnerList = () => {
        var req = {
            "startupId": Number(idNumber)
        }
        apiService(`partner/panelround/questions/review/list`, req, 'post').then((res) => {
            if (res) {
                if (res.data.rounds) {
                    setRoundListData(res.data.rounds)
                } else {
                    setRoundListData([])
                }
            }
        })
    }
    const [questionListData, setQuestionListData] = useState([])
    const questionList = () => {
        var req = {
            "startupId": Number(idNumber),
            "partnerOrInvestorId": Number(partnerOrInvestorId),
        }
        apiService(`jury/panelround/questions/review/list`, req, 'post').then((res) => {
            if (res) {
                if (res.data.rounds) {
                    setQuestionListData(res.data.startupRounds)
                } else {
                    setQuestionListData([])
                }

            }
        })
    }
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleBack = () => {
        navigator(`${path}/startup/list`)
    }
    return (
        <section className='mt-5'>
            <div className='container-fluid'>
                <div className='header_tag'>
                    <h5>Startup Details</h5>
                </div>
                <div className='form_div'>
                    <div className='div_headers mb-3'>
                        <div className='d-flex start_aligin_center'>
                            <label className='mr-3 mb-0'>Startup Name :</label>
                            <p className='mb-0'><b>{startupGetData.startupName}</b></p>
                            <Button className='ml-auto' onClick={handleBack}><ArrowBackIcon /></Button>

                        </div>
                    </div>
                    <div className='overallTab'>
                        <Box
                            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', }}
                        >
                            <Tabs
                                className='verticalTabCSS'
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Application Details" {...a11yProps(0)} />
                                <Tab label="Startup Details" {...a11yProps(1)} />
                                {
                                    (path === 'partner' || path === 'investor' || path === 'jury') && startupGetData.showRounds === true && (
                                        <Tab label="Review Rounds" {...a11yProps(2)} />
                                    )
                                }
                                {
                                    path === 'jury' && startupGetData.showQuestions === true && (
                                        <Tab label="Review Questions" {...a11yProps(3)} />
                                    )
                                }
                                {
                                    path !== 'admin' && (
                                        <Tab label="Request More Info" {...a11yProps(4)} />
                                    )
                                }
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                {
                                    (startupGetData.application ? (<Startupappication application={startupGetData.application} />) : (
                                        <div className='nodata_tag'>
                                            <h6>Startup application details is empty</h6>
                                        </div>
                                    ))
                                }

                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {
                                    (startupGetData.details ? (<Startupstartdetails details={startupGetData.details} />) : (
                                        <div className='nodata_tag'>
                                            <h6>Startup details is empty</h6>
                                        </div>
                                    ))
                                }
                            </TabPanel>
                            {
                                (path === 'partner' || path === 'investor') && (
                                    <>
                                        <TabPanel value={value} index={2}>
                                            <Reviewrounds roundListData={roundListData} idNumber={idNumber} />
                                        </TabPanel>
                                        <TabPanel value={value} index={4}>
                                            <Moreinfo idNumber={idNumber} viewFile={viewFile} additionalInfo={additionalInfo} />
                                        </TabPanel>
                                    </>
                                )
                            }
                            {
                                (path === 'jury') && (
                                    <>
                                        <TabPanel value={value} index={2}>
                                            <Reviewrounds roundListData={roundListData} path={path} idNumber={idNumber} />
                                        </TabPanel>
                                        <TabPanel value={value} index={3}>
                                            <Reviewquestion questionListData={questionListData} />
                                        </TabPanel>
                                        <TabPanel value={value} index={4}>
                                            <Moreinfo idNumber={idNumber} viewFile={viewFile} additionalInfo={additionalInfo} />
                                        </TabPanel>
                                    </>
                                )
                            }
                        </Box>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Startupdetails
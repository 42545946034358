import React, { useContext, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DataContext from '../../context/DataContext';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import apiService from '../../api/apiService';
import { useParams } from 'react-router-dom'
import Scsthubavpremarks from '../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';
import Scsthubavpevaluation from '../scsthubavp-module/scsthubavp-details/Scsthubavpevaluation';
const Scstevaluationdetails = ({ path }) => {
    const { navigator, Accordion, AccordionSummary, AccordionDetails } = useContext(DataContext)
    const { startupId } = useParams()
    const { id } = useParams()
    let idNumber = atob(startupId)
    let partnerOrInvestorId = atob(id)
    useDidMountEffect(() => {
        listApi()
    }, [])
    const [expanded, setExpanded] = useState('');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [listData, setListData] = useState({})
    const [statusData, setStatusData] = useState({})
    // const [juryData, setJuryData] = useState([])
    const listApi = () => {
        var req = {
            "partnerOrInvestorId": partnerOrInvestorId,
            "startupId": idNumber
        }
        apiService('partner/scst/admin/startup/get', req, 'post').then((result) => {
            if (result) {
                if (result.data.startupRounds) {
                    setListData(result.data.startupRounds[1])
                }
            }
        }).catch((err) => {

        });
    }
    return (
        <section className='container-fluid'>
            <div className='form_div chart'>
                <Scsthubavpevaluation listData={listData} />
            </div>
        </section>
    )
}

export default Scstevaluationdetails
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import {
  Button,
  FormControl,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../../context/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DeletePop from "../../../common-page/DeletePop";

const Tanseed7Team = () => {
  const [formData, setFormData] = useState({
    startupFounders: [{}],
    startupAdvisories: [{}],
  });
  const { navigator, handleBacktanseed7, Paper, isWhitespace, setOpenPop } =
    useContext(DataContext);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    fields: foundersFields,
    append: founderAppend,
    remove: foundersRemove,
  } = useFieldArray({
    control,
    name: "startupFounders",
  });
  const {
    fields: advisoriesFields,
    append: advisoriesAppend,
    remove: advisoriesRemove,
  } = useFieldArray({
    control,
    name: "startupAdvisories",
  });
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
  }, []);
  const getApi = async () => {
    await apiService(`startup/tanseed/team/get`, "", "get").then((res) => {
      if (res?.data) {
        setFormData({});
        let response = res.data;
        if (response.id !== 0) {
          setFormData(response);
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
        } else {
          setFormData(response);
        }
      }
    });
  };
  const addFounderDetailsBtn = () => {
    founderAppend({
      name: "",
      brief: "",
      linkedIn: "",
    });
  };
  const addAdvisoriesDetailsBtn = () => {
    advisoriesAppend({
      name: "",
      linkedIn: "",
    });
  };
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      apiService("startup/tanseed/team/save", getform, "post").then((res) => {
        setLoading(false);
        if (res?.data?.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          navigator("/startup/tanseed-7.0/incubation-accelaration");
        }
      });
    }
  };
  const [deleteFunction, setDeleteFunction] = useState();
  const [deleteIndex, setDaleteIndex] = useState();
  // const [deleteId, setDaleteId] = useState();
  const handleDeleteFounders = (index) => {
    setOpenPop(true);
    setDeleteFunction("Founders");
    setDaleteIndex(index);
  };
  const handleDeleteAdvisories = (index) => {
    setOpenPop(true);
    setDeleteFunction("Advisories");
    setDaleteIndex(index);
  };
  const handleClosePop = () => {
    setOpenPop(false);
    setDaleteIndex();
    setDeleteFunction();
  };
  const deleteApi = (index) => {
    if (deleteFunction === "Founders") {
      deleteFounders(index);
    }
    if (deleteFunction === "Advisories") {
      deleteAdvisories(index);
    }
  };
  // delete Founders
  const deleteFounders = (index) => {
    const id = getValues(`startupFounders.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/tanseed/founders/remove?id=${id}`, "", "get").then(
        (res) => {
          if (res?.data?.responseStatus === "Success") {
            indexBasedremovestartupFounders(index);
            // getApi();
          }
        }
      );
    } else {
      indexBasedremovestartupFounders(index);
    }
  };
  const indexBasedremovestartupFounders = (index) => {
    var startupFounders = getValues("startupFounders");
    if (startupFounders.length === 1 && index === 0) {
      var valu = getValues();
      valu.startupFounders = [
        {
          name: "",
          brief: "",
          linkedIn: "",
        },
      ];
      setFormData(valu);
    } else {
      foundersRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  // delete Advisories
  const deleteAdvisories = (index) => {
    const id = getValues(`startupAdvisories.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/tanseed/advisories/remove?id=${id}`, "", "get").then(
        (res) => {
          if (res?.data?.responseStatus === "Success") {
            indexBasedremovestartupAdvisories(index);
            // getApi();
          }
        }
      );
    } else {
      indexBasedremovestartupAdvisories(index);
    }
  };
  const indexBasedremovestartupAdvisories = (index) => {
    var startupAdvisories = getValues("startupAdvisories");
    if (startupAdvisories.length === 1 && index === 0) {
      var valu = getValues();
      valu.startupAdvisories = [
        {
          name: "",
          linkedIn: "",
        },
      ];
      setFormData(valu);
    } else {
      advisoriesRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBacktanseed7}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Team Info</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="div_header">
                  <div className="d-flex">
                    <h6>Founder Details</h6>
                    <Button
                      variant="outlined"
                      disabled={readyOnlyValue}
                      className="ml-auto"
                      onClick={() => addFounderDetailsBtn("")}
                    >
                      Add Founder Details
                    </Button>
                  </div>
                </div>
                <div>
                  {foundersFields.length ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Founder Name * </TableCell>
                            <TableCell>LinkedIn Profile Link *</TableCell>
                            <TableCell>Brief *</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {foundersFields.map((field, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupFounders.${index}.name`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Name is required",
                                      validate: {
                                        noWhitespace: (value) =>
                                          !isWhitespace(value) ||
                                          "Whitespace not allowed",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Name"
                                        placeholder="Enter Name"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupFounders?.[index]?.name &&
                                      errors.startupFounders?.[index]?.name
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupFounders.${index}.linkedIn`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "LinkedIn is required",
                                      validate: {
                                        noWhitespace: (value) =>
                                          !isWhitespace(value) ||
                                          "Whitespace not allowed",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="LinkedIn"
                                        placeholder="Enter LinkedIn"
                                        fullwidth
                                        {...field}
                                        disabled={readyOnlyValue}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupFounders?.[index]
                                      ?.linkedIn &&
                                      errors.startupFounders?.[index]?.linkedIn
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupFounders.${index}.brief`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Brief is required",
                                      validate: {
                                        noWhitespace: (value) =>
                                          !isWhitespace(value) ||
                                          "Whitespace not allowed",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Brief"
                                        placeholder="Enter Brief"
                                        fullwidth
                                        {...field}
                                        disabled={readyOnlyValue}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupFounders?.[index]?.brief &&
                                      errors.startupFounders?.[index]?.brief
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center">
                                <DeleteIcon
                                  style={{ fill: "#d32f2f", cursor: "pointer" }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      handleDeleteFounders(index)
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="nodata_tag">
                      <p>Founder details is empty</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="div_header">
                  <div className="d-flex">
                    <h6>Advisory / Mentors Details</h6>
                    <Button
                      variant="outlined"
                      disabled={readyOnlyValue}
                      className="ml-auto"
                      onClick={() => addAdvisoriesDetailsBtn("")}
                    >
                      Add Advisories Details
                    </Button>
                  </div>
                </div>
                <div>
                  {advisoriesFields.length ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Advisory / Mentors Name *</TableCell>
                            <TableCell>LinkedIn Profile Link *</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {advisoriesFields.map((field, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupAdvisories.${index}.name`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Name is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Name"
                                        placeholder="Enter Name"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupAdvisories?.[index]?.name &&
                                      errors.startupAdvisories?.[index]?.name
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupAdvisories.${index}.linkedIn`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "LinkedIn is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="LinkedIn"
                                        placeholder="Enter LinkedIn"
                                        fullwidth
                                        {...field}
                                        disabled={readyOnlyValue}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupAdvisories?.[index]
                                      ?.linkedIn &&
                                      errors.startupAdvisories?.[index]
                                        ?.linkedIn.message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center">
                                <DeleteIcon
                                  style={{ fill: "#d32f2f", cursor: "pointer" }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      handleDeleteAdvisories(index)
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="nodata_tag">
                      <p>Advisories details is empty</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed7}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
      <DeletePop
        deleteApi={deleteApi}
        deleteIndex={deleteIndex}
        handleClosePop={handleClosePop}
      />
    </>
  );
};

export default Tanseed7Team;

import { LoadingButton } from '@mui/lab';
import { Button, Container, FormHelperText } from '@mui/material'
import React, { useContext, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import notifyService from '../../api/notifySerivce';
import apiService from '../../api/apiService';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DataContext from '../../context/DataContext';
const Bulkupload = ({ handleBulkClose, title, api, roleid }) => {
    const { VisuallyHiddenInput } = useContext(DataContext)   
    const [exportLoading, setExportLoading] = useState(false);
    const exportExcel = () => {
        setExportLoading(true);
        apiService(`export/template/download?roleid=${roleid}`, '', 'download').then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${api}_template.xlsx`);
            document.body.appendChild(link);
            link.click();
            setExportLoading(false);
        })
    }
    const [fileData, setFileData] = useState(null)
    const [fileName, setFileName] = useState('Upload File')
    const handleFileUpload = (event) => {
        if (event.target.files[0]) {
            // var reader = new FileReader();
            var imagetype = event.target.files[0].type
            var imagedatatype = imagetype.split("/")
            var img_crt_type = imagedatatype[1]
            if (img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                setFileData(event.target.files)
                setValidate(false)
                setFileName(event.target.files[0].name)
                // debugger
            } else {
                notifyService('danger', 'File Format Invalided', 'Please check your file format')
            }
        }

    }
    const [validate, setValidate] = useState(false)
    const [loading, setLoading] = useState(false);
    const onSubmit = () => {
        if (fileData !== null) {
            setLoading(true)
            setValidate(false)
            const formData = new FormData()
            formData.append("file", fileData[0]);
            apiService(`import/${api}`, formData, 'fileupload').then((res) => {
                setLoading(false)
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Upload successfully !!')
                        handleCancel()
                    }
                }

            })
        } else {
            setValidate(true)
        }

    }
    const handleCancel = () => {
        handleBulkClose()
    }

    return (
        <section className=''>
            <Container>
                <div className='header_tag text-center'>
                    <h6 className='c-black'>Upload {title}</h6>
                </div>
                <div className=''>
                    <div className='templete_dev'>
                        <div className='d-flex'>
                            <div>
                                <span>Download Template</span>
                            </div>
                            <div className="ml-auto form-groups">
                                <LoadingButton className='ml-3'
                                    loading={exportLoading}
                                    loadingPosition="start"
                                    startIcon={<CloudDownloadIcon />}
                                    variant="outlined"
                                    fullWidth
                                    onClick={exportExcel}
                                >
                                    <span>DOWNLOAD</span>
                                </LoadingButton>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={onSubmit}>
                        <div className='templete_dev'>
                            <div className='d-flex'>
                                <div><span>Upload</span></div>
                                <div className="ml-auto form-groups">
                                    <Button
                                        className='ml-3'
                                        component="label"
                                        loadingPosition="start"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleFileUpload}
                                        startIcon={<CloudUploadIcon />}
                                        href="#file-upload"
                                    >
                                        {fileName}
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                    <FormHelperText className='text-danger'>{(validate === true && ('CSV Upload is required'))}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3"
                            >
                                <span>Save</span>
                            </LoadingButton>
                            <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>
                        </div>
                    </form>



                </div>
            </Container>
        </section>
    )
}

export default Bulkupload
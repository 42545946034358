import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
const Teaminfotanseed = () => {
  const [formData, setFormData] = useState({
    startupFounders: [{}],
  });
  // const [profilePicUrl, setProfilePicUrl] = useState("/images/user.png");

  // const [ProfilePicValue, setProfilePicValue] = useState(null);
  // const [profilePicName, setProfilePicName] = useState(null);
  // const [logoInvalid, setLogoInvalid] = useState(false);
  const {
    navigator,
    // handleBack,
    // Paper,
    validateEmail,
    handleBacktanseed,
    validatePhoneNumber,
    VisuallyHiddenInput,
    // validatePANNumber,
    // checkAlphanumeric,
    // validateAadharNumber,
    Accordion,
    AccordionSummary,
    AccordionDetails,
  } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    fields: foundersFields,
    append: founderAppend,
    remove: foundersRemove,
  } = useFieldArray({
    control,
    name: "startupFounders",
  });
  useDidMountEffect(() => {
    getApi();
  }, []);
  const getApi = async () => {
    await apiService(`startup/team/get`, "", "get").then((res) => {
      if (res) {
        setFormData({});
        let response = res.data;
        if (response.id !== 0) {
          if (response.startupFounders) {
            response.startupFounders.forEach((element) => {
              if (element.communityCertificateName) {
                element.communityStatus = "update";
              }
              if (element.aadharCertificateName) {
                element.aadharCertificateStatus = "update";
              }
              if (element.panDocName) {
                element.panStatus = "update";
              }
              if (element.profilePictureName) {
                element.profilePictureURL = element.profilePictureName;
              }
            });
          }

          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
          setFormData(response);
          debugger;
        } else {
          setFormData(response);
        }
      }
    });
  };
  const addFounderDetailsBtn = () => {
    founderAppend({
      name: "",
      email: "",
      phone: "",
      community: "",
      caste: "",
      communityCertificate: "",
      aadharCertificate: "",
      holdingSharegt50: "",
    });
  };
  const onSubmit = (data) => {
    console.log(data);
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      debugger;
      apiService("startup/team/save", getform, "post").then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            navigator("/startup/traction-achievements/tanseed");
          }
        }
      });
    }
  };
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };

  // const [communityCertificateValue, setCommunityCertificateValue] =
  //   useState("");
  // const [communityCertificateName, setCommunityCertificateName] = useState("");
  // const [communityCertificateURL, setCommunityCertificateURL] = useState("");

  // const [aadharCertificateValue, setAadharCertificateValue] = useState("");
  // const [aadharCertificateName, setAadharCertificateName] = useState("");
  // const [aadharCertificateURL, setAadharCertificateURL] = useState("");

  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    fileURL,
    arrayName,
    index
  ) => {
    // debugger
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          img_crt_type === "jpeg" ||
          img_crt_type === "jpg" ||
          img_crt_type === "png" ||
          img_crt_type === "pdf"
        ) {
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            // setValue(filekey, ImageValue)
            // setValue(fileName, img_name)
            // setValue(fileURL, logourl1)
            if (arrayName !== "") {
              setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
              setValue(`${arrayName}.${index}.${fileName}`, img_name);
              setValue(`${arrayName}.${index}.${fileURL}`, logourl1);
            } else {
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileURL, logourl1);
            }
            // setPartnerLogoURL(logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalid",
            "Please check your file format"
          );
        }
      }
    }
  };

  // const handleDeleteCommunity = (index) => {
  //   setValue(`startupFounders.${index}.communityCertificate`, null);
  //   setValue(`startupFounders.${index}.status`, null);
  //   debugger;
  // };

  // const handleCheck = (e, index) => {
  //   setValue(`startupFounders.${index}.aadharConcern`, e.target.checked);

  //   clearErrors(`startupFounders.${index}.aadharConcern`);
  // };
  // const handlePanCheck = (e, index) => {
  //   setValue(`startupFounders.${index}.panConcern`, e.target.checked);

  //   clearErrors(`startupFounders.${index}.panConcern`);
  // };
  // let communityList = ["SC", "ST"];
  // const handleSelectCommunity = (value, index) => {
  //   setValue(`startupFounders.${index}.community`, value);
  //   clearErrors(`startupFounders.${index}.community`);
  //   if (value === "SC") {
  //     setValue(`startupFounders.${index}.caste`, "");
  //   }
  // };
  const [expanded, setExpanded] = useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <section className="container-fluid">
      <div className="form_div">
        <div className="d-flex align-items-center">
          <span className="back_span" onClick={handleBacktanseed}>
            <i className="fas fa-arrow-alt-circle-left"></i>
          </span>
          <h6 className="ml-2 mb-0">Team Info</h6>
        </div>
        <hr />
        <form
          className="signin-form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="row">
            <div className="d-none">
              <div className="form-group">
                <Controller
                  name="id"
                  control={control}
                  defaultValue="0"
                  render={({ field }) => <TextField {...field} />}
                />
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="div_header">
                <div className="d-flex">
                  <h6>Founder Details</h6>
                  <Button
                    variant="outlined"
                    disabled={readyOnlyValue}
                    className="ml-auto"
                    onClick={() => addFounderDetailsBtn("")}
                  >
                    Add Founder Details
                  </Button>
                </div>
              </div>
              <div>
                {foundersFields && foundersFields.length ? (
                  <>
                    {foundersFields.map((field, index) => (
                      // <div className="mb-3 found-div">

                      //   <Divider />

                      // </div>
                      <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id={`panel${index}d-header`}
                        >
                          <div className="d-flex align-items-center w-100">
                            <h5>Founder {index + 1}</h5>
                            <div className="ml-auto">
                              <Button
                                variant="outlined"
                                disabled={readyOnlyValue}
                                color="error"
                                onClick={() => {
                                  readyOnlyValue === false ? (
                                    foundersRemove(index)
                                  ) : (
                                    <></>
                                  );
                                }}
                              >
                                Remove Founder Details
                              </Button>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            className="mt-2"
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            key={index}
                          >
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <FormControl sx={{ width: "100%" }}>
                                <Controller
                                  name={`startupFounders.${index}.name`}
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: "Name is required" }}
                                  render={({ field }) => (
                                    <TextField
                                      id={`name-${index}`}
                                      label="Name *"
                                      placeholder="Enter Name"
                                      fullWidth
                                      {...field}
                                      inputProps={{ readOnly: readyOnlyValue }}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.name &&
                                    errors.startupFounders?.[index]?.name
                                      .message}
                                </FormHelperText>
                              </FormControl>
                              <FormControl
                                sx={{ width: "100%", marginTop: "25px" }}
                              >
                                <Controller
                                  name={`startupFounders.${index}.email`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Email is required",
                                    validate: validateEmail,
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      // id={`email-${index}`}
                                      variant="outlined"
                                      label="Email *"
                                      placeholder="Enter Email"
                                      fullWidth
                                      {...field}
                                      inputProps={{ readOnly: readyOnlyValue }}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.email &&
                                    errors.startupFounders?.[index]?.email
                                      .message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <FormControl sx={{ width: "100%" }}>
                                <Controller
                                  name={`startupFounders.${index}.phone`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Phone number is required",
                                    validate: validatePhoneNumber,
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      id={`phone-${index}`}
                                      variant="outlined"
                                      label="Phone Number *"
                                      placeholder="Enter Phone Number"
                                      fullWidth
                                      {...field}
                                      inputProps={{
                                        readOnly: readyOnlyValue,
                                        maxLength: 10,
                                      }}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.phone &&
                                    errors.startupFounders?.[index]?.phone
                                      .message}
                                </FormHelperText>
                              </FormControl>
                              <FormControl
                                sx={{ width: "100%", marginTop: "25px" }}
                              >
                                <Controller
                                  name={`startupFounders.${index}.linkedIn`}
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: "LinkedIn is required" }}
                                  render={({ field }) => (
                                    <TextField
                                      id="outlined-multiline-static"
                                      label="LinkedIn"
                                      placeholder="Enter LinkedIn"
                                      fullwidth
                                      {...field}
                                      disabled={readyOnlyValue}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.startupFounders?.[index]?.linkedIn &&
                                    errors.startupFounders?.[index]?.linkedIn
                                      .message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              sx={{ marginTop: "8px" }}
                            >
                              <Controller
                                name={`startupFounders.${index}.profilePicture`}
                                control={control}
                                defaultValue=""
                                rules={{ required: "Image is required" }}
                                render={({ field }) => (
                                  <Button
                                    className="imgupload_div"
                                    component="label"
                                    onChange={(e) =>
                                      handleFileUpload(
                                        e,
                                        "profilePicture",
                                        "profilePictureName",
                                        "profilePictureURL",
                                        "startupFounders",
                                        index
                                      )
                                    }
                                  >
                                    {getValues(
                                      `startupFounders.${index}.profilePictureName`
                                    ) ? (
                                      <div className="disPhotos ">
                                        <VisuallyHiddenInput type="file" />
                                        <img
                                          src={getValues(
                                            `startupFounders.${index}.profilePictureURL`
                                          )}
                                          style={{ width: "73px" }}
                                          className="logo"
                                          alt={`startupFounders.${index}.profilePictureName`}
                                        />
                                        {/* <div className='hoverDelete'>
                                                      <div className='center_display'>
                                                          <DeleteForeverIcon onClick={() => deletestartupFoundersPhotos(index)} />
                                                      </div>
                                                  </div> */}
                                      </div>
                                    ) : (
                                      <div className="camera_icons disPhotos text-center">
                                        <VisuallyHiddenInput type="file" />
                                        <img
                                          src="/images/product-image.png"
                                          style={{ width: "73px" }}
                                          className="no_logo"
                                          alt={`startupFounders.${index}.profilePictureName`}
                                        />
                                        <div className=" mt-3">
                                          Upload Photo *
                                        </div>
                                      </div>
                                    )}
                                  </Button>
                                )}
                              />
                              {/* <div className='text-center'>
                              <DeleteForeverIcon className='c-red c-pointer' onClick={() => deleteProblemPhotos(index)} />
                          </div> */}
                              <FormHelperText>
                                File format should be in .jpg, .jpeg, .png
                              </FormHelperText>
                              <FormHelperText>
                                Maximum file size: 5 MB
                              </FormHelperText>
                              <FormHelperText className="text-danger">
                                {errors.startupFounders?.[index]?.image &&
                                  errors.startupFounders?.[index]?.image
                                    .message}
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                ) : (
                  <div className="nodata_tag">
                    <p>Founder details is empty</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group text-center">
            <Button
              variant="contained"
              onClick={handleBacktanseed}
              className="btn btn-cancel submit px-3"
            >
              Cancel
            </Button>
            {readyOnlyValue === false ? (
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="btn btn-primary submit px-3 ml-3"
              >
                <span>Save & Preview</span>
              </LoadingButton>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default Teaminfotanseed;

import { Autocomplete, Button, Checkbox, FormControl, FormHelperText, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';
import SearchIcon from '@mui/icons-material/Search';

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import downloadExcel from '../../../hooks/downloadExcel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
const ICStartupFilter = ({ preferredSectors, setPreferredSectors, setSearchInputValue, searchInputValue }) => {
    const { icon, checkedIcon, setPageLoading } = useContext(DataContext);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [searchInputValue, setSearchInputValue] = useState('');
    const [sectorData, setSectorData] = useState([]);
    useDidMountEffect(() => {
        dropdownValueApi()
    }, [page, rowsPerPage, searchInputValue])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup_min`, "", "get")
            .then((res) => {
                var val = res.data.dropDownValues;
                setSectorData(val.sectors);
            })
            .catch((error) => {
                console.error("Error fetching dropdown values:", error);
            });
    };
    const handleSearch = (searchValue) => {
        setSearchInputValue(searchValue)
    }
    const handleSelectSector = (value) => {
        setPreferredSectors(value);
    };
    const handleClear = () => {
        setPreferredSectors([]);
    };

    const exportExcel = () => {
        setPageLoading(true)
        var req = {
            download: 'Yes',
            listSize: rowsPerPage,
            pageNumber: page + 1,
            searchString: searchInputValue,
            // sectors: preferredSectors,
        }
        apiService('admin/ic/startups/list', req, "downloadpost").then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    downloadExcel(res.data, 'Tanfund_Admin_')
                }
            }
        }).catch((err) => {

        });
    }

    return (
        <div>
            <div className="header_tag ">
                <h5>Investor Connect Startups</h5>
                <div className='ml-3 d-flex'>
                    <div className='mr-3'>
                        <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search by Name"
                                type="search"
                                inputProps={{ 'aria-label': 'Search by Name' }}
                            />
                        </SearchinputDiv>
                    </div>
                    <Button
                        variant="outlined"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseWidthExample"
                    >
                        {" "}
                        <FilterAltIcon />
                        Filters
                    </Button>
                    <Button
                        startIcon={<CloudDownloadIcon />}
                        variant="outlined"
                        onClick={exportExcel}
                        className="ml-3"
                    >
                        <span>EXPORT EXCEL</span>
                    </Button>
                </div>

            </div>
            <div className="collapse" id="collapseWidthExample">
                <div className="row d-flex justify-content-center">
                    {/* <div className="col-lg-3">
                        <div className="form-group mt-2">
                            <FormControl sx={{ width: "100%" }}>
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    options={startupCategoryData}
                                    onChange={(e, selectedOptions) =>
                                        handleSelectStartupCategory(selectedOptions)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Category *" />
                                    )}
                                />
                            </FormControl>
                        </div>
                    </div> */}
                    <div className="col-lg-3">
                        <div className="form-group mt-2">
                            <FormControl sx={{ width: "100%" }}>
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    multiple
                                    // {...field}
                                    limitTags={2}
                                    value={preferredSectors}
                                    options={sectorData}
                                    onChange={(e, selectedOptions) =>
                                        handleSelectSector(selectedOptions)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Sectors *" />
                                    )}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option}
                                        </li>
                                    )}
                                />
                                <FormHelperText className="fnt-sm c-blue">
                                    Select one or more
                                </FormHelperText>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <center>
                    <Button
                        variant="contained"
                        sx={{ marginRight: "10px" }}
                        onClick={() => handleClear()}
                        data-toggle="collapse"
                        data-target="#collapseWidthExample"
                    >
                        Clear
                    </Button>
                    {/* <Button variant="contained" onClick={handleFilter}>
                        Filter
                    </Button> */}
                </center>
            </div>
        </div>
    )
}

export default ICStartupFilter
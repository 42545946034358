import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import ClarificationAll from "../../../scstBdd-page/scstBdd-details/ClarificationAll";
import Scsthubavpremarks from "../../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks";
import Viewimage from "../../../../api/Viewimage";
import DataContext from "../../../../context/DataContext";

const LDDPriorDocuments1 = ({
  idNumber,
  path,
  startupValue,
  startupCheckList,
  viewStartupDetails,
  showStartupValue,
}) => {
  const { setPageLoading, getBDDStatus } = useContext(DataContext);
  const [formData, setFormData] = useState();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const { fields: priorDocumentsFields } = useFieldArray({
    control,
    name: "priorDocuments",
  });

  useDidMountEffect(() => {
    getApi();
  }, []);
  const getApi = () => {
    setPageLoading(true);
    var req = {
      startupId: idNumber,
      round: "ldd",
      category: "prior1",
    };
    apiService(`partner/startup/clarification/list`, req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          result.data.priorDocuments.forEach((element) => {
            if (element.status === 3) {
              element.status = 2;
            }
            if (element.fileUrl) {
              var val = element.fileUrl;
              var data = val.split("?");
              if (data) {
                var formet = data[0].split(".");
                element.formet = formet[3];
              }
            }
          });

          setFormData(result.data);
          // console.log(result.data);
        }
      })
      .catch((err) => { });
  };
  const handelVerifiedStatus = (value, i) => {
    // console.log(value);
    setValue(`priorDocuments.${i}.status`, Number(value));
    var getData = getValues(`priorDocuments.${i}`);
    if (value === "1") {
      var req = {
        bcId: getData.id,
        startupId: idNumber,
        status: 1,
        round: "ldd",
      };
      apiService(`partner/startups/clarification/ldd/status/save`, req, "post")
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.responseStatus === "Success"
          ) {
            notifyService("success", "Success", "Saved successfully !!");
            // viewStartupDetails()
            showStartupValue();
            getApi();
          }
        })
        .catch((err) => { });
    }
    setFormData(getValues());
  };
  const handleRequestValue = (value, i) => {
    setValue(`priorDocuments.${i}.clarification`, value);
    clearErrors(`priorDocuments.${i}.clarification`);
  };
  const onSubmit = (data) => { };
  const handleRequestSubmit = (i) => {
    var getData = getValues(`priorDocuments.${i}`);
    if (getData.clarification !== "") {
      var req = {
        bcId: getData.id,
        startupId: idNumber,
        clarification: getData.clarification,
        status: 2,
        round: "ldd",
      };
      apiService(`partner/startup/clarification/ldd/save`, req, "post")
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.responseStatus === "Success"
          ) {
            notifyService("success", "Success", "Saved successfully !!");
            // viewStartupDetails()
            showStartupValue();
            getApi();
          }
        })
        .catch((err) => { });
    } else {
      setError(`priorDocuments.${i}.clarification`, {
        message: "Request Clarification is required",
      });
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value, formet) => {
    setViewImage(value);
    if (formet === "xlsx") {
      const link = document.createElement("a");
      link.href = value;
      link.download = "excelfile.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ p: 3 }}>
      <div>
        <div className="table_main_div">
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            {priorDocumentsFields && priorDocumentsFields.length ? (
              <>
                {priorDocumentsFields.map((data, index) => (
                  <div className="mb-3">
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="mb-0">
                          {index + 1}. {data.attribute}
                        </p>
                      </Grid>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        {data.value && (
                          <div className="ans-div">{data.value}</div>
                        )}
                        {data.fileUrl && (
                          <div className="my-2">
                            View Document :{" "}
                            <span
                              className="view-doc"
                              onClick={() =>
                                handleClickOpen(data.fileUrl, data.formet)
                              }
                            >
                              {data.formet === "xlsx" ? "Download" : "View"}
                            </span>
                          </div>
                        )}
                        {data.fileUrl && data.formet &&
                          <>
                            {data.fileUrl && data.formet === 'xlsx' ?
                              (<>
                                <span>(File Format : Excel)</span>
                              </>) : (<>
                                <span>(File Format : PDF)</span></>)}
                          </>
                        }
                        {(!data.value && !data.fileUrl && !data.documentName && !data.patents && !data.employee) && (
                          <>
                            <b>Not Provided</b>
                          </>
                        )}
                        {
                          data.employee && (
                            <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                              <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>No</TableCell>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Role</TableCell>
                                      <TableCell>Responsibility</TableCell>
                                      <TableCell>Education</TableCell>
                                      <TableCell>Professional Experience</TableCell>
                                      <TableCell>Relevant Experience</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {data.employee.map((row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.name ? row.name : `-`}</TableCell>
                                        <TableCell>{row.role ? row.role : `-`}</TableCell>
                                        <TableCell>{row.responsibility ? row.responsibility : `-`}</TableCell>
                                        <TableCell>{row.education ? row.education : `-`}</TableCell>
                                        <TableCell>{row.professionalExperience ? row.professionalExperience : `-`}</TableCell>
                                        <TableCell>{row.relevantExperience ? row.relevantExperience : `-`}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )
                        }
                        {data.materialAgreements && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Party Name</TableCell>
                                    <TableCell>Type of contract</TableCell>
                                    <TableCell>Value per Annum</TableCell>
                                    <TableCell>Validity period</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.materialAgreements.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.partyName ? row.partyName : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.contractType
                                          ? row.contractType
                                          : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.valuePerAnnum
                                          ? row.valuePerAnnum
                                          : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.validityPeriod
                                          ? row.validityPeriod
                                          : `-`}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.intellectualProperty && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Property</TableCell>
                                    <TableCell>Details</TableCell>
                                    <TableCell>Status</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.intellectualProperty.map(
                                    (row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {row.property ? row.property : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.details ? row.details : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.status ? row.status : `-`}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.immovableProperty && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Lessor</TableCell>
                                    <TableCell>Lease rent</TableCell>
                                    <TableCell>Term / duration</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.immovableProperty.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.Address ? row.Address : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.lessor ? row.lessor : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.lease ? row.lease : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.leaseTerm ? row.leaseTerm : `-`}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.complaintsAndLitigation && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>
                                      Litigation & Venue/ Forum
                                    </TableCell>
                                    <TableCell>Party</TableCell>
                                    <TableCell>Indicative Amount</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.complaintsAndLitigation.map(
                                    (row, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {row.litigationNature
                                            ? row.litigationNature
                                            : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.party ? row.party : `-`}
                                        </TableCell>
                                        <TableCell>
                                          {row.indicativeAmount
                                            ? row.indicativeAmount
                                            : `-`}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.startupLabourLaw && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Act</TableCell>
                                    <TableCell>
                                      Whether obtained / Not
                                    </TableCell>
                                    <TableCell>Validity period</TableCell>
                                    <TableCell>Status of compliance</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.startupLabourLaw.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.act ? row.act : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.obtained !== undefined
                                          ? (row.obtained ? "Yes" : "No")
                                          : "-"}
                                      </TableCell>
                                      <TableCell>
                                        {row.validity ? row.validity : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.status ? row.status : `-`}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.startupPolicy && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Status</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.startupPolicy.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.name ? row.name : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.status ? row.status : `-`}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.startupTeams && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Total Employees</TableCell>
                                    <TableCell>Covered under PF/ESI</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.startupTeams.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.type ? row.type : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.totalEmployees
                                          ? row.totalEmployees
                                          : `-`}
                                      </TableCell>
                                      <TableCell>

                                        {row.coveredUnderPF
                                          ? row.coveredUnderPF === true
                                            ? "Yes"
                                            : `No`
                                          : "-"}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.patents && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Choose</TableCell>
                                    <TableCell>Stage</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>File</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.patents.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.name ? row.name : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.stage ? row.stage : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.number ? row.number : `-`}
                                      </TableCell>
                                      <TableCell sx={{ padding: "0px" }}>
                                        {row.docname ? (
                                          <span
                                            className="view-doc"
                                            onClick={() =>
                                              window.open(row.docname, "_blank")
                                            }
                                          >
                                            View
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                        {data.branches && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="caption table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>State</TableCell>
                                    <TableCell>District</TableCell>
                                    <TableCell>Pincode</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.branches.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>
                                        {row.address ? row.address : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.state ? row.state : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.district ? row.district : `-`}
                                      </TableCell>
                                      <TableCell>
                                        {row.pincode ? row.pincode : `-`}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                      </Grid>
                      {path === "scstldd" &&
                        startupCheckList.status === 17 &&
                        startupCheckList.showlddVerified === false ? (
                        <>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <div>
                              <FormControl>
                                <Controller
                                  name={`priorDocuments.${index}.status`}
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: false }}
                                  render={({ field }) => (
                                    <RadioGroup
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      defaultValue="Innovation"
                                      name="radio-buttons-group"
                                      {...field}
                                      onChange={(e) =>
                                        handelVerifiedStatus(
                                          e.target.value,
                                          index
                                        )
                                      }
                                    >
                                      <span className="d-flex">
                                        {/* {(
                                                (data.fileUrl || data.value || data.materialAgreements || data.intellectualProperty || data.immovableProperty || data.complaintsAndLitigation || data.startupTeams || data.startupLabourLaw || data.patents || data.branches || data.startupPolicy) && (
                                                  <FormControlLabel disabled={(getValues(`priorDocuments.${index}.status`) === 2 && data.clarification) ? true : false} value={1} control={<Radio />} label="Verified" />
                                                )
                                              )} */}
                                        {/* <FormControlLabel
                                          disabled={
                                            getValues(
                                              `priorDocuments.${index}.status`
                                            ) === 2 && data.clarification
                                              ? true
                                              : false
                                          }
                                          value={1}
                                          control={<Radio />}
                                          label="Verified"
                                        /> */}
                                        {/* <FormControlLabel
                                          disabled={
                                            getValues(
                                              `priorDocuments.${index}.status`
                                            ) === 2 && data.clarification
                                              ? true
                                              : false
                                          }
                                          value={2}
                                          control={<Radio />}
                                          label="More clarification"
                                        /> */}
                                        {getValues(`priorDocuments.${index}.status`) === 1 && (
                                          <b>Clarified </b>
                                        )}
                                        {/* <FormControlLabel disabled={(getValues(`priorDocuments.${index}.status`) === 2 && data.clarification) ? true : false} value={1} control={<Radio />} label="Verified" /> */}
                                        {getValues(`priorDocuments.${index}.status`) !== 1 && (
                                          <FormControlLabel
                                            disabled={
                                              getValues(
                                                `priorDocuments.${index}.status`
                                              ) === 2 && data.clarification
                                                ? true
                                                : false
                                            }
                                            value={2}
                                            control={<Radio />}
                                            label="More clarification"
                                          />
                                        )}
                                      </span>
                                    </RadioGroup>
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.priorDocuments?.[index]?.status &&
                                    errors.priorDocuments?.[index]?.status
                                      .message}
                                </FormHelperText>
                              </FormControl>
                            </div>
                            {getValues(`priorDocuments.${index}.status`) ===
                              2 && (
                                <div className="form-group">
                                  <Controller
                                    name={`priorDocuments.${index}.clarification`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required:
                                        "Request Clarification is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Request Clarification"
                                        placeholder="Enter Message"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        onInput={(e) =>
                                          handleRequestValue(
                                            e.target.value,
                                            index
                                          )
                                        }
                                        inputProps={{
                                          readOnly:
                                            getValues(
                                              `priorDocuments.${index}.status`
                                            ) === 2 && data.clarification
                                              ? true
                                              : false,
                                        }}
                                        {...field}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Button
                                                disabled={
                                                  getValues(
                                                    `priorDocuments.${index}.status`
                                                  ) === 2 && data.clarification
                                                    ? true
                                                    : false
                                                }
                                                onClick={() =>
                                                  handleRequestSubmit(index)
                                                }
                                              >
                                                <SendIcon />
                                              </Button>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.priorDocuments?.[index]
                                      ?.clarification &&
                                      errors.priorDocuments?.[index]
                                        ?.clarification.message}
                                  </FormHelperText>
                                </div>
                              )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <p className="mb-1">
                              Status : <b>{getBDDStatus(data.status)}</b>
                            </p>
                            {data.clarification && (
                              <p className="mb-1">
                                Clarification :{" "}
                                {data.clarificationAll ? (
                                  <ClarificationAll
                                    remarks={data.clarificationAll}
                                    lblName="Clarifications"
                                    btnName="View"
                                  />
                                ) : (
                                  <Scsthubavpremarks
                                    remarks={data.clarification}
                                    lblName="View"
                                  />
                                )}{" "}
                              </p>
                            )}
                            {data.response && (
                              <p className="mb-1">
                                Startup Response :{" "}
                                <Scsthubavpremarks
                                  remarks={data.response}
                                  lblName="View"
                                />
                              </p>
                            )}
                            {data.fileUrl && (
                              <p className="mb-1">
                                Startup Attached Document :{" "}
                                <Button
                                  onClick={() =>
                                    handleClickOpen(data.fileUrl, data.formet)
                                  }
                                  className="text-cap"
                                >
                                  View
                                </Button>
                              </p>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Divider />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />
                  <h4>LDD Document details is empty</h4>
                </div>
              </>
            )}
          </form>
        </div>
        <Viewimage
          viewImage={viewImage}
          open={open}
          handleClose={handleClose}
        />
      </div>
    </Box>
  );
};
export default LDDPriorDocuments1;

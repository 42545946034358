import React from 'react'
import './Pagenotfound.css'
import { Link } from 'react-router-dom'
import CustomLink from '../../common-page/CustomLink'

const Pagenotfound = () => {
  return (
    <>
      {/* <section>
        <div className=''>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='under-main'>
                  <center><img src="/images/pagenotfound.png" alt="" className='w-50' /></center>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='under-main-1'>
                  <center>
                    <img src="/images/Tanfundlogo-black-1.png" alt="" className='w-50' />
                    <h3>WEBSITE IN UNDER</h3> 
                    <h4>MAINTENANCE</h4>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <main className='sesstion_404'>
        <div className="container pageCss">
          <div className="row">
            <div className="col-md-6 align-self-center">
              <img src='/images/404-1.gif' className='img-fluid' />
            </div>
            <div className="col-md-6 align-self-center page404">
              <h1>404</h1>
              <h2>Page Not Found.</h2>
              <p className='mb-0'>The page you are looking for does not exists.
              </p>
              <p>But you can click the button below to go back to the homepage.</p>
              <CustomLink className="btn404 green" to="/">HOME</CustomLink>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Pagenotfound
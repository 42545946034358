import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Applicationscst from "./Applicationscst";
import Fundingscstview from "./Fundingscstview";
import Founderscstview from "./Founderscstview";
import Moreinfosave from "../investor-module/associated-startups-page/associated-details-page/pages/Moreinfosave";
import IncubatorAccelerationScst from "./IncubatorAccelerationScst";
import { Grid } from "@mui/material";
import ScstScoreValidation from "./ScstScoreValidation";
import Scsthubavpevaluation from "../scsthubavp-module/scsthubavp-details/Scsthubavpevaluation";
import ScstBddVerificationLayout from "../scstBdd-page/scst-verification-details/ScstBddVerificationLayout";
import BDDDeepDive from "./bdd-dee-dive/BDDDeepDive";
import BDDDeepDiveLayout from "./bdd-dee-dive/BDDDeepDiveLayout";
import LDDLayout from "./ldd-stage/LDDLayout";
import FDDLayout from "./fdd-stage/FDDLayout";
import Zoomlist from "../jury-module/zoom-page/Zoomlist";
import Scsthubavpvalidation from "../scsthubavp-module/scsthubavp-details/Scsthubavpvalidation";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import apiService from "../../api/apiService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Scsttab = ({
  meetingIndex,
  startupGetData,
  listApi,
  startupValue,
  idNumber,
  viewStartupDetails,
  listData,
  path,
  partnerOrInvestorId,
  showStartupValue,
  startupCheckList,
}) => {
  const roles = localStorage.getItem("role");
  // console.log(`role : ${roles}`, `path : ${path}`);
  // console.log(startupCheckList);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    window.scrollTo(0, 0);
  };
  useDidMountEffect(() => {
    listApis();
  }, []);
  const [listDatas, setListDatas] = useState([]);
  const listApis = () => {
    //   setPageLoading(true)
    var req = {
      partnerOrInvestorId: partnerOrInvestorId,
      startupId: idNumber,
    };
    apiService("partner/scst/admin/startup/get", req, "post")
      .then((result) => {
        // setPageLoading(false)
        if (result && result.data) {
          if (result.data.startupRounds) {
            setListDatas(result.data.startupRounds);
          }
        }
      })
      .catch((err) => {});
  };
  // const [bddMeetIndex, setBddMeetIndex] = useState(startupValue.status >= 8 ? 2 : 1)


  return (
    <div className="setHeg">
      {/* {

                path === 'scstbdd' && (

                    <Grid container>
                        <Grid item xs={2}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={valueTab}
                                onChange={handleChangeTab}
                                aria-label="Vertical tabs example"
                            >
                                <Tab
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Profile
                                            {startupCheckList.additionalInfoRequested === true ? (
                                                <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                            ) : (<></>)}
                                        </div>
                                    }
                                    {...a11yProps(0)} className='side-tab' />
                                {
                                    (startupCheckList.status >= 3) && (
                                        <Tab label={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                BDD First Connect
                                                {startupCheckList.bddClarificationRequested === true ? (
                                                    <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                ) : (<></>)}
                                            </div>
                                        }
                                            {...a11yProps(1)} className='side-tab' />
                                    )
                                }
                                {
                                    (startupCheckList.status >= 8) && (
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    BDD deep dive
                                                    {startupCheckList.bddDDClarificationRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            } {...a11yProps(2)} className='side-tab' />
                                    )
                                }
                                <Tab label="Zoom" {...a11yProps(startupCheckList.status >= 8 ? 3 : 2)} className='side-tab' />

                            </Tabs>
                        </Grid>
                        <Grid item xs={10}>
                            <TabPanel value={valueTab} index={0}>
                                <div className='overallTab'>
                                    <Tabs
                                        className='verticalTabCSS'
                                        orientation="horizontal"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                                    >
                                        <Tab className='verticalLable' label="Application Overview" {...a11yProps(0)} />
                                        <Tab className='verticalLable' label="Funding & Financials" {...a11yProps(1)} />
                                        <Tab className='verticalLable' label="Founder Info" {...a11yProps(2)} />
                                        <Tab className='verticalLable' label="Incubation & Acceleration" {...a11yProps(3)} />
                                        <Tab className='verticalLable'
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Additional Info
                                                    {startupCheckList.additionalInfoRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }
                                            {...a11yProps(4)}
                                        />
                                    </Tabs>
                                    <Box sx={{ p: 3 }}>
                                        <TabPanel value={value} index={0}><Applicationscst details={startupGetData} detail={startupValue} /></TabPanel>
                                        <TabPanel value={value} index={1}><Fundingscstview details={startupGetData} detail={startupValue} /></TabPanel>
                                        <TabPanel value={value} index={2}><Founderscstview details={startupGetData} /></TabPanel>
                                        <TabPanel value={value} index={3}><IncubatorAccelerationScst details={startupGetData} detail={startupValue} idNumber={idNumber} /></TabPanel>
                                        <TabPanel value={value} index={4}><Moreinfosave idNumber={idNumber} detail={startupValue} viewStartupDetails={viewStartupDetails} path={path} /></TabPanel>
                                    </Box>
                                </div>
                            </TabPanel>
                            <TabPanel value={valueTab} index={1}>
                                <ScstBddVerificationLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                            </TabPanel>
                            {
                                (startupCheckList.status >= 8) && (
                                    <TabPanel value={valueTab} index={2}>
                                        <BDDDeepDiveLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} />
                                    </TabPanel>
                                )
                            }
                          
                        </Grid>
                    </Grid>
                )
            }           */}

      {roles === "SCSTHUB" && (
        <Grid container>
          <Grid item xs={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="Vertical tabs example"
            >
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Profile
                    {startupCheckList.additionalInfoRequested === true ? (
                      <span className="ml-2">
                        <img
                          src="/images/giphy.gif"
                          alt="gif"
                          className="gif-bell img-fluid"
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                }
                {...a11yProps(0)}
                className="side-tab"
              />
              {startupCheckList.status >= 0 && (
                <Tab
                  label="Validation Phase"
                  {...a11yProps(1)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 1 && (
                <Tab
                  label="Evaluation Phase"
                  {...a11yProps(2)}
                  className="side-tab"
                />
              )}
              <Tab label="Zoom" {...a11yProps(3)} className="side-tab" />
            </Tabs>
          </Grid>
          <Grid item xs={10}>
            <TabPanel value={valueTab} index={0}>
              <div className="overallTab">
                <Tabs
                  className="verticalTabCSS"
                  orientation="horizontal"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tab
                    className="verticalLable"
                    label="Application Overview"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Funding & Financials"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Founder Info"
                    {...a11yProps(2)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Incubation & Acceleration"
                    {...a11yProps(3)}
                  />
                  <Tab
                    className="verticalLable"
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Additional Info
                        {startupCheckList.additionalInfoRequested === true ? (
                          <span className="ml-2">
                            <img
                              src="/images/giphy.gif"
                              alt="gif"
                              className="gif-bell img-fluid"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    }
                    {...a11yProps(4)}
                  />
                </Tabs>
                <Box sx={{ p: 3 }}>
                  <TabPanel value={value} index={0}>
                    <Applicationscst
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Fundingscstview
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Founderscstview details={startupGetData} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <IncubatorAccelerationScst
                      details={startupGetData}
                      detail={startupValue}
                      idNumber={idNumber}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Moreinfosave
                      idNumber={idNumber}
                      detail={startupValue}
                      viewStartupDetails={viewStartupDetails}
                      showStartupValue={showStartupValue}
                      path={path}
                    />
                  </TabPanel>
                </Box>
              </div>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <ScstScoreValidation
                listApi={listApi}
                idNumber={idNumber}
                path={path}
                partnerOrInvestorId={partnerOrInvestorId}
                viewStartupDetails={viewStartupDetails}
                showStartupValue={showStartupValue}
                detail={startupValue}
                startupCheckList={startupCheckList}
              />
            </TabPanel>
            <TabPanel
              value={valueTab}
              index={startupCheckList.status >= 1 && 2}
            >
              <Box sx={{ p: 3 }} role="presentation" className="overallTab">
                <Scsthubavpevaluation listData={listData} listApi={listApi} />
              </Box>
            </TabPanel>
            <TabPanel
              value={valueTab}
              index={startupCheckList.status <= 0 ? 2 : 3}
            >
              <div className="table_tag">
                <Box sx={{ p: 3 }}>
                  <Zoomlist startupId={idNumber} />
                </Box>
              </div>
            </TabPanel>
          </Grid>
        </Grid>
      )}



      {roles === "BDD" && (
        <Grid container>
          <Grid item xs={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="Vertical tabs example"
            >
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Profile
                    {startupCheckList.additionalInfoRequested === true ? (
                      <span className="ml-2">
                        <img
                          src="/images/giphy.gif"
                          alt="gif"
                          className="gif-bell img-fluid"
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                }
                {...a11yProps(0)}
                className="side-tab"
              />
              {startupCheckList.status >= 0 && (
                <Tab
                  label="Validation Phase"
                  {...a11yProps(1)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 1 && (
                <Tab
                  label="Evaluation Phase"
                  {...a11yProps(2)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 5 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD First Connect
                      {startupCheckList.bddClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(3)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 8 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD deep dive
                      {startupCheckList.bddDDClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(4)}
                  className="side-tab"
                />
              )}
              <Tab
                label="Zoom"
                {...a11yProps(meetingIndex)}
                className="side-tab"
              />
            </Tabs>
          </Grid>
          <Grid item xs={10}>
            <TabPanel value={valueTab} index={0}>
              <div className="overallTab">
                <Tabs
                  className="verticalTabCSS"
                  orientation="horizontal"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tab
                    className="verticalLable"
                    label="Application Overview"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Funding & Financials"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Founder Info"
                    {...a11yProps(2)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Incubation & Acceleration"
                    {...a11yProps(3)}
                  />
                  <Tab
                    className="verticalLable"
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Additional Info
                        {startupCheckList.additionalInfoRequested === true ? (
                          <span className="ml-2">
                            <img
                              src="/images/giphy.gif"
                              alt="gif"
                              className="gif-bell img-fluid"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    }
                    {...a11yProps(5)}
                  />
                </Tabs>
                <Box sx={{ p: 3 }}>
                  <TabPanel value={value} index={0}>
                    <Applicationscst
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Fundingscstview
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Founderscstview details={startupGetData} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <IncubatorAccelerationScst
                      details={startupGetData}
                      detail={startupValue}
                      idNumber={idNumber}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Moreinfosave
                      idNumber={idNumber}
                      detail={startupValue}
                      viewStartupDetails={viewStartupDetails}
                      showStartupValue={showStartupValue}
                      path={path}
                    />
                  </TabPanel>
                </Box>
              </div>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <Scsthubavpvalidation listData={listDatas[0]} />
            </TabPanel>
            <TabPanel value={valueTab} index={2}>
              <Box sx={{ p: 3 }} role="presentation" className="overallTab">
                <Scsthubavpevaluation listData={listData} />
              </Box>
            </TabPanel>
            {startupCheckList.status >= 5 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={3}>
                <ScstBddVerificationLayout
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupCheckList={startupCheckList}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            {startupCheckList.status >= 8 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={4}>
                <BDDDeepDiveLayout
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupCheckList={startupCheckList}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            <TabPanel value={valueTab} index={meetingIndex}>
              <div className="table_tag">
                <Box sx={{ p: 3 }}>
                  <Zoomlist startupId={idNumber} />
                </Box>
              </div>
            </TabPanel>
          </Grid>
        </Grid>
      )}

      {roles === "IC" && (
        <Grid container>
          <Grid item xs={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="Vertical tabs example"
            >
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Profile
                    {startupCheckList.additionalInfoRequested === true ? (
                      <span className="ml-2">
                        <img
                          src="/images/giphy.gif"
                          alt="gif"
                          className="gif-bell img-fluid"
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                }
                {...a11yProps(0)}
                className="side-tab"
              />
              {startupCheckList.status >= 0 && (
                <Tab
                  label="Validation Phase"
                  {...a11yProps(1)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 1 && (
                <Tab
                  label="Evaluation Phase"
                  {...a11yProps(2)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 3 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD First Connect
                      {startupCheckList.bddClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(3)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 8 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD deep dive
                      {startupCheckList.bddDDClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(4)}
                  className="side-tab"
                />
              )}
              <Tab label="Zoom" {...a11yProps(5)} className="side-tab" />
            </Tabs>
          </Grid>
          <Grid item xs={10}>
            <TabPanel value={valueTab} index={0}>
              <div className="overallTab">
                <Tabs
                  className="verticalTabCSS"
                  orientation="horizontal"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tab
                    className="verticalLable"
                    label="Application Overview"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Funding & Financials"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Founder Info"
                    {...a11yProps(2)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Incubation & Acceleration"
                    {...a11yProps(3)}
                  />
                  <Tab
                    className="verticalLable"
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Additional Info
                        {startupCheckList.additionalInfoRequested === true ? (
                          <span className="ml-2">
                            <img
                              src="/images/giphy.gif"
                              alt="gif"
                              className="gif-bell img-fluid"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    }
                    {...a11yProps(4)}
                  />
                </Tabs>
                <Box sx={{ p: 3 }}>
                  <TabPanel value={value} index={0}>
                    <Applicationscst
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Fundingscstview
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Founderscstview details={startupGetData} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <IncubatorAccelerationScst
                      details={startupGetData}
                      detail={startupValue}
                      idNumber={idNumber}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Moreinfosave
                      idNumber={idNumber}
                      detail={startupValue}
                      viewStartupDetails={viewStartupDetails}
                      showStartupValue={showStartupValue}
                      path={path}
                    />
                  </TabPanel>
                </Box>
              </div>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <Scsthubavpvalidation listData={listDatas[0]} />
            </TabPanel>
            <TabPanel value={valueTab} index={2}>
              <Box sx={{ p: 3 }} role="presentation" className="overallTab">
                <Scsthubavpevaluation listData={listData} />
              </Box>
            </TabPanel>
            <TabPanel value={valueTab} index={3}>
              <ScstBddVerificationLayout
                idNumber={idNumber}
                path={path}
                viewStartupDetails={viewStartupDetails}
                showStartupValue={showStartupValue}
                startupValue={startupValue}
              />
            </TabPanel>
            {startupCheckList.status >= 8 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={4}>
                <BDDDeepDiveLayout
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            <TabPanel value={valueTab} index={meetingIndex}>
              <div className="table_tag">
                <Box sx={{ p: 3 }}>
                  <Zoomlist startupId={idNumber} />
                </Box>
              </div>
            </TabPanel>
          </Grid>
        </Grid>
      )}

      {roles === "PSC" && (
        <Grid container>
          <Grid item xs={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="Vertical tabs example"
            >
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Profile
                    {startupCheckList.additionalInfoRequested === true ? (
                      <span className="ml-2">
                        <img
                          src="/images/giphy.gif"
                          alt="gif"
                          className="gif-bell img-fluid"
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                }
                {...a11yProps(0)}
                className="side-tab"
              />
              {startupCheckList.status >= 0 && (
                <Tab
                  label="Validation Phase"
                  {...a11yProps(1)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 1 && (
                <Tab
                  label="Evaluation Phase"
                  {...a11yProps(2)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 3 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD First Connect
                      {startupCheckList.bddClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(3)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 8 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD deep dive
                      {startupCheckList.bddDDClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(4)}
                  className="side-tab"
                />
              )}
              <Tab label="Zoom" {...a11yProps(5)} className="side-tab" />
            </Tabs>
          </Grid>
          <Grid item xs={10}>
            <TabPanel value={valueTab} index={0}>
              <div className="overallTab">
                <Tabs
                  className="verticalTabCSS"
                  orientation="horizontal"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tab
                    className="verticalLable"
                    label="Application Overview"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Funding & Financials"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Founder Info"
                    {...a11yProps(2)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Incubation & Acceleration"
                    {...a11yProps(3)}
                  />
                  <Tab
                    className="verticalLable"
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Additional Info
                        {startupCheckList.additionalInfoRequested === true ? (
                          <span className="ml-2">
                            <img
                              src="/images/giphy.gif"
                              alt="gif"
                              className="gif-bell img-fluid"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    }
                    {...a11yProps(4)}
                  />
                </Tabs>
                <Box sx={{ p: 3 }}>
                  <TabPanel value={value} index={0}>
                    <Applicationscst
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Fundingscstview
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Founderscstview details={startupGetData} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <IncubatorAccelerationScst
                      details={startupGetData}
                      detail={startupValue}
                      idNumber={idNumber}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Moreinfosave
                      idNumber={idNumber}
                      detail={startupValue}
                      viewStartupDetails={viewStartupDetails}
                      showStartupValue={showStartupValue}
                      path={path}
                    />
                  </TabPanel>
                </Box>
              </div>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              {/* <ScstScoreValidation listApi={listApi} idNumber={idNumber} path={path} partnerOrInvestorId={partnerOrInvestorId} viewStartupDetails={viewStartupDetails} detail={startupValue} startupCheckList={startupCheckList} showStartupValue={showStartupValue} /> */}
              <Scsthubavpvalidation listData={listDatas[0]} />
            </TabPanel>
            <TabPanel value={valueTab} index={2}>
              <Box sx={{ p: 3 }} role="presentation" className="overallTab">
                <Scsthubavpevaluation listData={listData} />
              </Box>
            </TabPanel>
            <TabPanel value={valueTab} index={3}>
              <ScstBddVerificationLayout
                idNumber={idNumber}
                path={path}
                viewStartupDetails={viewStartupDetails}
                showStartupValue={showStartupValue}
                startupValue={startupValue}
              />
            </TabPanel>
            {startupCheckList.status >= 8 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={4}>
                <BDDDeepDiveLayout
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            <TabPanel value={valueTab} index={meetingIndex}>
              <div className="table_tag">
                <Box sx={{ p: 3 }}>
                  <Zoomlist startupId={idNumber} />
                </Box>
              </div>
            </TabPanel>
          </Grid>
        </Grid>
      )}

      {roles === "LDD" && (
        <Grid container>
          <Grid item xs={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="Vertical tabs example"
            >
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Profile
                    {startupCheckList.additionalInfoRequested === true ? (
                      <span className="ml-2">
                        <img
                          src="/images/giphy.gif"
                          alt="gif"
                          className="gif-bell img-fluid"
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                }
                {...a11yProps(0)}
                className="side-tab"
              />
              {startupCheckList.status >= 5 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD First Connect
                      {startupCheckList.bddClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(1)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 8 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD deep dive
                      {startupCheckList.bddDDClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(2)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 17 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      LDD
                      {roles === "LDD" &&
                      startupCheckList.lddClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(3)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 17 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      FDD
                      {roles !== "LDD" &&
                      startupCheckList.fddClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(4)}
                  className="side-tab"
                />
              )}
              <Tab label="Zoom" {...a11yProps(5)} className="side-tab" />
            </Tabs>
          </Grid>
          <Grid item xs={10}>
            <TabPanel value={valueTab} index={0}>
              <div className="overallTab">
                <Tabs
                  className="verticalTabCSS"
                  orientation="horizontal"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tab
                    className="verticalLable"
                    label="Application Overview"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Funding & Financials"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Founder Info"
                    {...a11yProps(2)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Incubation & Acceleration"
                    {...a11yProps(3)}
                  />
                  <Tab
                    className="verticalLable"
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Additional Info
                        {startupCheckList.additionalInfoRequested === true ? (
                          <span className="ml-2">
                            <img
                              src="/images/giphy.gif"
                              alt="gif"
                              className="gif-bell img-fluid"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    }
                    {...a11yProps(4)}
                  />
                </Tabs>
                <Box sx={{ p: 3 }}>
                  <TabPanel value={value} index={0}>
                    <Applicationscst
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Fundingscstview
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Founderscstview details={startupGetData} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <IncubatorAccelerationScst
                      details={startupGetData}
                      detail={startupValue}
                      idNumber={idNumber}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Moreinfosave
                      idNumber={idNumber}
                      detail={startupValue}
                      viewStartupDetails={viewStartupDetails}
                      showStartupValue={showStartupValue}
                      path={path}
                    />
                  </TabPanel>
                </Box>
              </div>
            </TabPanel>
            {startupCheckList.status >= 5 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={1}>
                <ScstBddVerificationLayout
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            {startupCheckList.status >= 8 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={2}>
                <BDDDeepDiveLayout
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            {startupCheckList.status >= 17 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={3}>
                <LDDLayout
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupValue={startupValue}
                  startupCheckList={startupCheckList}
                />
              </TabPanel>
            )}
            {startupCheckList.status >= 17 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={4}>
                <FDDLayout
                  startupCheckList={startupCheckList}
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            <TabPanel value={valueTab} index={meetingIndex}>
              <div className="table_tag">
                <Box sx={{ p: 3 }}>
                  <Zoomlist startupId={idNumber} />
                </Box>
              </div>
            </TabPanel>
          </Grid>
        </Grid>
      )}

      {roles === "FDD" && (
        <Grid container>
          <Grid item xs={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="Vertical tabs example"
            >
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Profile
                    {startupCheckList.additionalInfoRequested === true ? (
                      <span className="ml-2">
                        <img
                          src="/images/giphy.gif"
                          alt="gif"
                          className="gif-bell img-fluid"
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                }
                {...a11yProps(0)}
                className="side-tab"
              />
              {startupCheckList.status >= 5 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD First Connect
                      {startupCheckList.bddClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(1)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 8 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      BDD deep dive
                      {startupCheckList.bddDDClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(2)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 17 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      LDD
                      {roles !== "FDD" &&
                      startupCheckList.lddClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(3)}
                  className="side-tab"
                />
              )}
              {startupCheckList.status >= 17 && startupValue.status !== 28 && (
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      FDD
                      {roles === "FDD" &&
                      startupCheckList.fddClarificationRequested === true ? (
                        <span className="ml-2">
                          <img
                            src="/images/giphy.gif"
                            alt="gif"
                            className="gif-bell img-fluid"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                  {...a11yProps(4)}
                  className="side-tab"
                />
              )}
              <Tab label="Zoom" {...a11yProps(5)} className="side-tab" />
            </Tabs>
          </Grid>
          <Grid item xs={10}>
            <TabPanel value={valueTab} index={0}>
              <div className="overallTab">
                <Tabs
                  className="verticalTabCSS"
                  orientation="horizontal"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tab
                    className="verticalLable"
                    label="Application Overview"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Funding & Financials"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Founder Info"
                    {...a11yProps(2)}
                  />
                  <Tab
                    className="verticalLable"
                    label="Incubation & Acceleration"
                    {...a11yProps(3)}
                  />
                  <Tab
                    className="verticalLable"
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Additional Info
                        {startupCheckList.additionalInfoRequested === true ? (
                          <span className="ml-2">
                            <img
                              src="/images/giphy.gif"
                              alt="gif"
                              className="gif-bell img-fluid"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    }
                    {...a11yProps(4)}
                  />
                </Tabs>
                <Box sx={{ p: 3 }}>
                  <TabPanel value={value} index={0}>
                    <Applicationscst
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Fundingscstview
                      details={startupGetData}
                      detail={startupValue}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Founderscstview details={startupGetData} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <IncubatorAccelerationScst
                      details={startupGetData}
                      detail={startupValue}
                      idNumber={idNumber}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Moreinfosave
                      idNumber={idNumber}
                      detail={startupValue}
                      viewStartupDetails={viewStartupDetails}
                      showStartupValue={showStartupValue}
                      path={path}
                    />
                  </TabPanel>
                </Box>
              </div>
            </TabPanel>
            {/* <TabPanel value={valueTab} index={1}>
                                <ScstBddVerificationLayout idNumber={idNumber} path={path} viewStartupDetails={viewStartupDetails} startupValue={startupValue} showStartupValue={showStartupValue} />
                            </TabPanel> */}
            {startupCheckList.status >= 5 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={1}>
                <ScstBddVerificationLayout
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            {startupCheckList.status >= 8 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={2}>
                <BDDDeepDiveLayout
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            {startupCheckList.status >= 17 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={3}>
                <LDDLayout
                  startupCheckList={startupCheckList}
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            {startupCheckList.status >= 17 && startupValue.status !== 28 && (
              <TabPanel value={valueTab} index={4}>
                <FDDLayout
                  startupCheckList={startupCheckList}
                  idNumber={idNumber}
                  path={path}
                  viewStartupDetails={viewStartupDetails}
                  showStartupValue={showStartupValue}
                  startupValue={startupValue}
                />
              </TabPanel>
            )}
            <TabPanel value={valueTab} index={meetingIndex}>
              <div className="table_tag">
                <Box sx={{ p: 3 }}>
                  <Zoomlist startupId={idNumber} />
                </Box>
              </div>
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Scsttab;

import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
const fileTypes = ["pdf"];

const FddSectionV = () => {
  const [formData, setFormData] = useState({});
  const { handleBackapp, setPageLoading,navigator } = useContext(DataContext)
  const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [venturePayments, setVenturePayments] = useState();
  const [deposit, setDeposit] = useState();
  const [fileUploadId, setFileUploadId] = useState(0)
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/secv/get`, '', 'get').then((res) => {
      if (res && res.data) {
        var data = res.data
        if (res.data.id) {
          setFileUploadId(res.data.id)
        }
        setFormData(res.data)
      }
      setPageLoading(false)
    })
  }



  const handleInception = (e) => {
    handleFileUpload(e, "inception", "inceptionName", "inceptionURL", 'file');
  }
  const handleEsop = (e) => {
    handleFileUpload(e, "esop", "esopName", "esopURL", 'file');
  }



  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type
        var imagedatatype = imagetype.split("/")
        var img_crt_type = imagedatatype[1]
        if (
          ((format === 'file') && (img_crt_type === "pdf")) ||
          ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
        ) {
          setValue(filekey, "")
          setValue(fileName, "")

          var fileValue = logoselecetdFile
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(',')
            var ImageValue = spl[1]
            var img_name = fileValue.name
            setValue(filekey, ImageValue)
            setValue(fileName, img_name)
            setValue(fileURL, logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId
            }
            console.log(req);
            // saveData(req)
          }
        } else {
          notifyService('danger', 'File Format Invalided', 'Please check your file format')
        }
      }
    }
  }

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    console.log(data);
    setPageLoading(true);
    apiService('startup/fdd/secv/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-w");
        }
      }
    })
  }
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Cap Table</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>

            <div className="row doc-row2">
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Cap Table break up from Inception till DD Cut off Date</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="inception" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleInception}
                          value={getValues('inception')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('inception') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('inception') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('inception') !== '' ? 'Uploaded (Click to Change)' : 'DD Date Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.inception && errors.inception.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                    onClick={() => handleClickOpen(getValues('inceptionURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Details of ESOP creation/implementation status</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="esop" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleEsop}
                          value={getValues('esop')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('esop') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('esop') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('esop') !== '' ? 'Uploaded (Click to Change)' : 'ESOP Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.esop && errors.esop.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                    onClick={() => handleClickOpen(getValues('esopURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4 col-12 mt-2'>
                <div className="form-group mt-2">
                  <Controller
                    name="commitments"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Any commitments to any others for allotment of shares in future?"
                        placeholder="Enter Details"
                        fullWidth
                        multiline
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.commitments && errors.commitments.message}
                  </FormHelperText>
                </div>
              </div>

              <div className='col-lg-4 col-12 mt-2'>
                <div className="form-group mt-2">
                  <Controller
                    name="allotedConsideration"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Any shares have been allotted for consideration other than cash?"
                        placeholder="Enter Details"
                        fullWidth
                        multiline
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.allotedConsideration && errors.allotedConsideration.message}
                  </FormHelperText>
                </div>
              </div>




            </div>


            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
        <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      </section>
    </>
  )
}

export default FddSectionV
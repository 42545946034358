import { Button, Dialog, DialogContent, DialogTitle, FormHelperText, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import apiService from '../../api/apiService';
import notifyService from '../../api/notifySerivce';
import DataContext from '../../context/DataContext';
import Viewimage from '../../api/Viewimage';
import { Link } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import { Controller, useForm } from 'react-hook-form';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { MuiFileInput } from 'mui-file-input';
import { FaRegFilePdf } from "react-icons/fa";
import { BsFiletypeXls } from "react-icons/bs";
const fileTypesPDF = ["pdf"];
const BDDSubmitReport = ({ idNumber, viewStartupDetails, showStartupValue, lbl, status }) => {
    const [openReport, setOpenReport] = useState(false);
    const handleClickReportOpen = () => {
        setOpenReport(true);
        setAdditionalDocValue(null)
        clearErrors('additionalDoc')
    };
    const handleReportClose = () => {
        setOpenReport(false);
        setAdditionalDocValue('');
        setValue('additionalDoc', '');
    };
    // console.log(statusValue)
    // const { id } = data
    const { VisuallyHiddenInput, setPageLoading } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, getValues, control, clearErrors, setValue, formState: { errors } } = useForm(
        { values: formData }
    );
    const [additionalDocURL, setAdditionalDocURL] = useState(null)
    const [additionalDocValue, setAdditionalDocValue] = useState(null)
    const [open, setOpen] = useState(false);
    const [checkMethod, setCheckMethod] = useState(false)
    let logoselecetdFile
    const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
        debugger
        if (event) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 10 * 1024 * 1024;
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type
                    var imagedatatype = imagetype.split("/")
                    var img_crt_type = imagedatatype[1]
                    const isExcel = img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    const isPdf = img_crt_type === "pdf";
                    if (
                        (status === 21 || status === 27) ? isExcel : isPdf
                    ) {
                        setValue(filekey, "")
                        setValue(fileName, "")
                        setdata('')
                        setUrl('')
                        if (event.target === undefined) {
                            setdata(event)
                        } else {
                            setdata(event.target.files[0])
                        }
                        var fileValue = logoselecetdFile
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(',')
                            var ImageValue = spl[1]
                            var img_name = fileValue.name
                            setUrl(logourl1)
                            setValue(filekey, ImageValue)
                            setValue(fileName, img_name)
                            clearErrors(filekey);
                            clearErrors(fileName);
                            debugger
                        }

                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File size exceeds 10 MB",
                        "Please check your file size"
                    );
                }
            }
        }
    }
    const onSubmit = (data) => {
        setPageLoading(true)
        if (lbl === 'LDD') {
            var getform = data
            getform.status = status
            getform.startupId = idNumber
            getform.type = "LDD"
        }
        else if (lbl === 'FDD') {
            var getform = data
            getform.status = status
            getform.startupId = idNumber
            getform.type = "FDD"
        }
        else if (lbl === 'CP LDD') {
            var getform = data
            getform.status = status
            getform.startupId = idNumber
            getform.type = "LDD"
        }
        else if (lbl === 'CP FDD') {
            var getform = data
            getform.status = status
            getform.startupId = idNumber
            getform.type = "FDD"
        }
        else if (lbl === 'CS LDD') {
            var getform = data
            getform.status = status
            getform.startupId = idNumber
            getform.type = "LDD"
        }
        else if (lbl === 'CS FDD') {
            var getform = data
            getform.status = status
            getform.startupId = idNumber
            getform.type = "FDD"
        }
        else {
            var getform = data
            getform.status = status
            getform.startupId = idNumber
        }

        debugger
        apiService('partner/scst/status/save', getform, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    showStartupValue()
                    handleReportClose()
                }
            }
        })
    };

    const onPdfClick4 = () => {
        const pdfUrl =
            "https://startuptn-public-files.s3.ap-south-1.amazonaws.com/CP_CS_Tracker.xlsx";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Business_Plan_Template.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const roles = localStorage.getItem('role')
    return (
        <>
            <button
                className="submitdetails-sur"
                onClick={() => {
                    handleClickReportOpen();
                }}
            >
                Submit Report
            </button>
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={openReport}
                onClose={handleReportClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {lbl} Report
                </DialogTitle>
                <DialogContent>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-12 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="additionalDoc" control={control} defaultValue="" rules={{ required: `${lbl} Report File is required` }}
                                        render={({ field: { value, onChange, ...field } }) =>
                                            <MuiFileInput
                                                {...field}
                                                // onChange={(e) => handleFileUpload(e, "additionalDoc", "additionalDocName", setAdditionalDocValue, setAdditionalDocURL)}
                                                onChange={(file) => {
                                                    if (file) {
                                                        handleFileUpload(file, "additionalDoc", "additionalDocName", setAdditionalDocValue, setAdditionalDocURL);
                                                    } else {
                                                        setAdditionalDocValue('');
                                                        setValue('additionalDoc', '');
                                                    }
                                                    // onChange(file);
                                                }}
                                                value={additionalDocValue}
                                                variant='outlined'
                                                fullWidth={true}
                                                types={fileTypesPDF}
                                                label={`Upload ${lbl} Report *`}
                                                placeholder='Upload Report' />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.additionalDoc && errors.additionalDoc.message}</FormHelperText>
                                    {status === 21 || status === 27 ? (<>
                                        <span className='font-sm'>Maximum 10 mb allowed doc (Excel) <span className='text-danger'>*</span></span>
                                    </>):(<>
                                    <span className='font-sm'>Maximum 10 mb allowed doc (pdf) <span className='text-danger'>*</span></span>
                                    </>)}
                                    
                                    {/* <span className='font-sm'>Maximum 10 mb allowed doc (pdf or Excel) <span className='text-danger'>*</span></span> */}
                                    {/* {(roles === 'LDD' || roles === 'FDD') && (status === 20 || status === 26) && (
                                        <div>
                                            <span
                                                className="c-blue c-pointer ml-2"
                                                onClick={() => onPdfClick4()}
                                            >
                                                File Upload Format <BsFiletypeXls className="c-green" />
                                            </span>
                                        </div>
                                    )} */}
                                    {(roles === 'LDD' || roles === 'FDD') && (status === 21 || status === 27) && (
                                        <div>
                                            <span
                                                className="c-blue c-pointer ml-2"
                                                onClick={() => onPdfClick4()}
                                            >
                                                {status === 21 ? (
                                                    <>
                                                        CP Format <BsFiletypeXls className="c-green" />
                                                    </>
                                                ) : (
                                                    <>
                                                        CS Format <BsFiletypeXls className="c-green" />
                                                    </>
                                                )}
                                            </span>
                                        </div>
                                    )}

                                </div>
                                <div className="form-group mt-2">
                                    <Controller name="comments" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                multiline
                                                label="Enter comments"
                                                placeholder='Enter comments'
                                                fullWidth
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className='text-center p-3'>
                            <Button className='mr-3' onClick={handleReportClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>

        </>
    )
}

export default BDDSubmitReport
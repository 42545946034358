import React from 'react'
import { useState } from 'react';
import BddStartupsOnboarded from './BddOnboarded-details/BddStartupsOnboarded';

const ScstBddlist = ({ role,name }) => {
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue)
    };
    return ( 
        <>
        <div className="scsthub-div form_div chart">
            <BddStartupsOnboarded role={role} name={name}/>
        </div>
        </>
    )
}

export default ScstBddlist
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, useFormState, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import { TextField, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import apiService from "../../../../api/apiService";
import Button from "@mui/material/Button";
import cryptoJS from "../../../../hooks/cryptoJS";
import "../signup.css";
import DataContext from "../../../../context/DataContext";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Signupalert from "../Signupalert";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadingButton } from "@mui/lab";
import notifyService from "../../../../api/notifySerivce";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import CustomLink from "../../../common-page/CustomLink";
const Investorsignup = () => {
  const reCAPTCHA = process.env.REACT_APP_CAPTCHA;
  const {
    validateEmail,
    validatePhoneNumber,
    navigator,
    modalstyle,
    handleClose,
    open,
    handleOpen,
    loading,
    setLoading,
  } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const [capVal, setCapVal] = useState(null);

  const cities = [
    "Ariyalur",
    "Chengalpattu",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kallakurichi",
    "Kanchipuram",
    "Kanniyakumari",
    "Kanniyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Mayiladuthurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Ranipet",
    "Salem",
    "Sivagangai",
    "Tenkasi",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tirupathur",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ];
  const [cityValue, setCityValue] = useState([]);
  const handleSelectCity = (value) => {
    setCityValue(value);
    setValue("city", value);
    clearErrors("city");
  };
  useDidMountEffect(() => {
    document.getElementById("theme").setAttribute("href", "/css/admin.css");
  }, []);

  const [emailError, setEmailError] = useState(false);
  const handleInputChange = (e) => {
    var name = e.target.value;
    var spl = name.split("@");
    if (spl[1]) {
      var val = spl[1].split(".");
      if (val[0] === "gmail") {
        setEmailError(true);
      } else if (val[0] === "yahoo") {
        setEmailError(true);
      } else if (val[0] === "outlook") {
        setEmailError(true);
      } else if (val[0] === "hotmail") {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
  };

  useDidMountEffect(() => {
    homeApi();
  }, []);

  const [homeData, setHomeData] = useState({});
  const homeApi = () => {
    setHomeData({});
    apiService(`home`, "", "get").then((res) => {
      if (res.data) {
        setHomeData(res.data);
      }
    });
  };

  const onSubmit = (data) => {
    if (emailError === false) {
      var getform = data;
      getform.roleId = 5;
      setLoading(true);
      apiService("register", getform, "unauthpost").then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.response.responseStatus === "Success") {
            localStorage.setItem("InvestorId", res.data.id);
            navigator("/investor/verify");
          }
        }
      });
    } else {
      notifyService("danger", "Email", "Email id is invalid");
    }
  };

  return (
    <>
      <marquee>
        <h3 className="text-danger mt-5">{homeData.maintenanceMessage}</h3>
      </marquee>
      <section className="signup-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="wrap d-md-flex">
                {/* <div className="text-wrap-signup p-4 p-lg-5 text-center d-flex align-items-center order-md-last">
                                    <div className="text w-100">
                                        <h2>Investor Registration</h2>
                                        <p>Already have a account ?</p>
                                        <Link to="/sign-in" className="btn btn-white btn-outline-white">Sign In</Link>
                                    </div>
                                </div> */}
                <div>
                  <img
                    src="/images/investor.png"
                    className="startup-bar img-fluid"
                    alt=""
                  />
                </div>
                <div className="signup-wrap p-4 p-lg-5 bg-white">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4 font-weight-bold">
                        Investor Registration
                      </h3>
                      {/* <p className="mb-4">Any startup based in Tamil Nadu, whether in the ideation, prototype or in traction stage, and actively seeking funding, is eligible to register.</p> */}
                    </div>
                  </div>
                  <form
                    className="signin-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row">
                      <div className="col-lg-12 col-12 mb-3">
                        <div className="form-group mb-3">
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Organization Name is required",
                            }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Organization Name"
                                placeholder="Organization Name"
                                autoFocus
                                fullWidth
                                {...field}
                                type="text"
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.name && errors.name.message}
                          </FormHelperText>
                        </div>
                      </div>
                      <div className="col-lg-12 col-12 mb-3">
                        <div className="form-group mb-3">
                          <FormControl sx={{ width: "100%" }}>
                            <Controller
                              name="email"
                              control={control}
                              defaultValue=""
                              rules={{ validate: validateEmail }}
                              render={({ field }) => (
                                <div>
                                  <TextField
                                    variant="outlined"
                                    label="Email Id"
                                    // id={`email-${i}`}
                                    type="text"
                                    // value={inputValues[i].email}
                                    onInput={(e) => handleInputChange(e)}
                                    placeholder="Enter email id"
                                    {...field}
                                    fullWidth
                                  />
                                </div>
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.email && errors.email.message}
                            </FormHelperText>
                            <FormHelperText className="text-danger">
                              {emailError === true && "Email id is invalid"}
                            </FormHelperText>
                            {/* email */}
                          </FormControl>
                        </div>
                      </div>
                      <div className="col-lg-12 col-12 mb-3">
                        <div className="form-group mb-3">
                          <FormControl sx={{ width: "100%" }}>
                            <Controller
                              name="phone"
                              control={control}
                              defaultValue=""
                              rules={{ validate: validatePhoneNumber }}
                              render={({ field }) => (
                                <div>
                                  <TextField
                                    variant="outlined"
                                    label="Phone Number "
                                    placeholder="Enter Phone Number"
                                    {...field}
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                  />
                                </div>
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.phone && errors.phone.message}
                            </FormHelperText>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-center mb-3">
                        <ReCAPTCHA
                          sitekey={reCAPTCHA}
                          onChange={(val) => setCapVal(val)}
                        />
                      </div>
                      <LoadingButton
                        loading={loading}
                        disabled={!capVal}
                        loadingPosition="start"
                        type="submit"
                        variant="contained"
                        className="form-control btn btn-primary submit px-3"
                      >
                        Register
                      </LoadingButton>
                    </div>
                    <div className="text-center">
                      <p className="mb-0 font-lg">
                        Already Registered & Verification Pending ?{" "}
                        <CustomLink to="/investor/verify" className="c-blue">
                          Click Here
                        </CustomLink>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag">
          <Signupalert handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
};

export default Investorsignup;

import React, { useEffect, useContext, useState } from 'react';
import { Checkbox, Container, Dialog, Tab, TablePagination } from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import apiService from '../../../../api/apiService';
import { LoadingButton } from '@mui/lab';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import downloadExcel from '../../../../hooks/downloadExcel';
import { Link } from 'react-router-dom';
import DataContext from '../../../../context/DataContext';
import Reviewupdate from '../../../partner-module/startup-page/startup-list-page/Reviewupdate';
// import Reviewupdate from './Reviewupdate';
import AddCommentIcon from '@mui/icons-material/AddComment';
// import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import Startround from './Startround';
import Avatar from '@mui/material/Avatar';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CustomLink from '../../../common-page/CustomLink';
const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
const Associatedlist = ({ name, path, endpoint, lable, url, }) => {
    const { modalstyle, startuplistItem, setPageLoading, tableIndexValue } = useContext(DataContext)
    const [checkedList, setCheckedList] = useState([]);
    const [itemsChecked, setItemsChecked] = useState(false);
    const [indexValue, setIndexValue] = useState([])
    const [count, setCount] = useState(0);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    useDidMountEffect(() => {
        // console.log(atob('MA=='))
        localStorage.setItem('point', lable)
        listApi()
        // eslint-disable-next-line
    }, [page, rowsPerPage, searchInputValue])
    const handleSearch = (searchValue) => {
        setSearchInputValue(searchValue)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [startupListData, setStartupListData] = useState([])
    const listApi = async () => {
        setStartupListData([])
        setPageLoading(true)
        var req = {
            "listSize": rowsPerPage,
            "pageNumber": page + 1,
            "searchString": searchInputValue,
        }
        apiService(`${url}`, req, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data.startups) {
                    setStartupListData(res.data.startups)
                    setCount(res.data.count)
                }


            }
        })
    }
    const [openView, setOpenView] = useState(false);
    const [getData, setSetGetData] = useState(0);
    const handleViewOpen = (id, selected, remarks) => {
        var req = {
            "id": id,
            "selected": selected,
            "remarks": remarks
        }
        setSetGetData(req)
        setOpenView(true)
    }
    const handleViewClose = () => {
        setOpenView(false);
        startuplistItem(name, endpoint)
    }
    // const promoteRound = (id) => {
    //     const collection = [];
    //     collection.push(id);
    //     var val = collection
    //     startRoundsApi(val)
    // }
    const [openStartRound, setOpenStartRound] = useState(false)
    const selectStartRound = () => {
        var val = checkedList
        let urlName
        if (lable === 'start') {
            setOpenStartRound(true)
            // urlName = 'partner/startups/startround'
        } else {
            urlName = 'partner/startups/promoteround'
            startRoundsApi(val, urlName)
        }

    }
    const handleStartRoundClose = () => {
        setOpenStartRound(false)
        listApi()
    }
    const startRoundsApi = (val, urlName) => {
        setPageLoading(true)
        apiService(urlName, val, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    if (res.data.responseStatus === "Success") {
                        setCheckedList([]);
                        setItemsChecked(false);
                        startuplistItem(name, endpoint)
                        listApi()
                    }
                }
            }

        })
    }


    const handleCheckboxClick = (e, index) => {
        const { value, checked } = e.target;
        var num = Number(value)
        // debugger
        if (checked) {
            setCheckedList([...checkedList, value * 1]);
            setIndexValue([indexValue, index])
            sectFgs()
        } else {
            // indexValue
            setIndexValue(indexValue => indexValue.filter((item) => item !== index))
            setCheckedList(checkedList => checkedList.filter((item) => item !== num));
            if (checkedList.length === 1) {
                setItemsChecked(false);
                setCheckedList([])
            }
            // sectFgs()
        }
    };
    const sectFgs = () => {
        console.log(indexValue.length)
        if (startupListData.length === indexValue.length) {
            setItemsChecked(true);
        } else {
            setItemsChecked(false);
        }
    }
    const selectItem = (e) => {
        const { checked } = e.target;
        const collection = [];
        if (checked) {
            for (const category of startupListData) {
                collection.push(category.startupId);
            }
        }
        setCheckedList(collection);
        setItemsChecked(checked);
        debugger
    };
    return (
        <section className=''>
            <div className='table_header_tag d-flex'>
                <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        value={searchInputValue}
                        placeholder="Search…"
                        type="search"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </SearchinputDiv>
                {
                    (checkedList.length > 0 && (
                        <Button className='ml-auto' variant="outlined" onClick={selectStartRound}>
                            {
                                lable === 'start' ? ('Start round and Assign to Jury') : ('Promote Round')
                            }


                        </Button>
                    ))
                }
            </div>
            {
                (startupListData.length) ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    {
                                        (lable === 'start' || lable === 'inprogress') && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={itemsChecked} onClick={(e) => selectItem(e)}
                                                />
                                            </TableCell>
                                        )
                                    }

                                    <TableCell align='center'>No</TableCell>
                                    {lable === "start" && (
                                        <TableCell align='center'></TableCell>
                                    )}
                                    <TableCell>Startup Name</TableCell>
                                    <TableCell align='center'>Sector</TableCell>
                                    <TableCell align='center'>Stage</TableCell>
                                    {/* <TableCell align='center'>Recommended</TableCell> */}
                                    {/* <TableCell align='center'>Remarks </TableCell> */}
                                    {lable === "inprogress" && (
                                        <TableCell align='center'>Inprogress / Completed / Total Round</TableCell>
                                    )}
                                    {/* {lable === "start" && (
                                        <TableCell align='center'>Total Round</TableCell>
                                    )} */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {startupListData.map((list, index) => (
                                    <TableRow key={index}>
                                        {
                                            (lable === 'start' || lable === 'inprogress') && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        item={list}
                                                        value={list.startupId}
                                                        checked={checkedList.includes(list.startupId)}
                                                        onChange={e => handleCheckboxClick(e, index)}
                                                    />
                                                </TableCell>
                                            )
                                        }

                                        <TableCell align='center'>
                                            {
                                                tableIndexValue(rowsPerPage, page, index)
                                            }
                                        </TableCell>
                                        {lable === "start" && (
                                            <TableCell align='center'>
                                                <Avatar alt={list.startupName} src={list.logo} />
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <CustomLink to={`/${path}/associated-startups/details/${btoa(list.startupId)}/${btoa(list.partnerOrInvestorId)}`}>{list.startupName}</CustomLink>
                                        </TableCell>
                                        <TableCell align='center'>{list.sector}</TableCell>
                                        <TableCell align='center'>{list.stage}</TableCell>
                                        {/* <TableCell align='center'>
                                            {
                                                (list.selected === 1) ? (<span className='yes_span'>Yes</span>) : <></>
                                            }
                                            {
                                                (list.selected === 2) ? (<span className='no_span'>No</span>) : <> </>
                                            }
                                            {
                                                (list.selected === 0) ? (<span className='review_span c-pointer' onClick={() => {
                                                    (path === 'partner' || (path === 'investor' && (lable === 'inprogress' || lable === 'start')) || path === 'jury') ? (handleViewOpen(list.id, list.selected, list.remarks)) : (<></>)
                                                }}>
                                                    {
                                                        path === 'jury' ? ('NA') : ('Under Review')
                                                    }
                                                </span>) : <></>
                                            }
                                        </TableCell> */}
                                        {/* <TableCell align='center'>

                                            {
                                                (list.selected === 1) && ((list.remarks) ? (list.remarks) : (<>-</>))
                                            }
                                            {
                                                (list.selected === 2) && ((list.remarks) ? (list.remarks) : (<>-</>))
                                            }
                                            {
                                                (list.selected === 0) && ((list.remarks) ? (<span className='c-pointer' onClick={() => handleViewOpen(list.id, list.selected, list.remarks)}>{list.remarks}</span>) : (
                                                    <>
                                                        {
                                                            (lable === 'inprogress' || lable === 'start') ? (<span className='c-pointer' onClick={() => handleViewOpen(list.id, list.selected, list.remarks)}><AddCommentIcon /></span>) : ('-')
                                                        }

                                                    </>
                                                ))
                                            }
                                        </TableCell> */}
                                        {lable === "inprogress" && (
                                            <TableCell align='center'>
                                                {(list.inprogressRounds ? list.inprogressRounds : 0)} / {(list.completedRounds ? list.completedRounds : 0)} / {(list.totalRounds ? list.totalRounds : 0)}
                                            </TableCell>
                                        )}
                                        {/* {lable === "start" && (
                                            <TableCell align='center'>
                                                {list.totalRounds}
                                            </TableCell>
                                        )} */}
                                        {/* <TableCell align='center' >
                                            {(list.inprogressRounds) ? (list.inprogressRounds) : (<>0</>)} / {(list.completedRounds) ? (list.completedRounds) : (<>0</>)} / {(list.totalRounds) ? (list.totalRounds) : (<>0</>)}
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                            <caption>
                                <TablePagination
                                    component="div"
                                    count={count}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    style={{float:'left'}}
                                /></caption>
                        </Table>

                    </TableContainer>
                ) : (
                    <div className='nodata_tag'>
                        <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                        {/* <h4>A Startups will be assigned as soon as the criteria matches</h4> */}
                        <h4>No Data Found</h4>
                    </div>
                )
            }
            <Modal
                open={openView}
                onClose={handleViewClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Reviewupdate handleViewClose={handleViewClose} getData={getData} />
                </Box>
            </Modal>
            <Dialog
                open={openStartRound}
                // onClose={handleStartRoundClose}
                minWidth={'lg'}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ width: '500px' }} className="form_tag p-3">
                    <Startround handleStartRoundClose={handleStartRoundClose} checkedList={checkedList} />
                </Box>
            </Dialog>
        </section>
    )
}

export default Associatedlist
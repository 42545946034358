import React, { createContext, useState } from "react";
// import cryptoJS from '../hooks/cryptoJS';
import apiService from "../api/apiService";
import { useForm } from "react-hook-form";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import notifyService from "../api/notifySerivce";
import { useTheme } from "@mui/material/styles";
// import { styled } from '@mui/material/styles';
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useDidMountEffect from "../hooks/useDidMountEffect";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Switch } from "@mui/material";
const tomorrow = dayjs().add(1, "day");
const DataContext = createContext({});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const DataProvider = ({ children }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigator = useNavigate();
  // password icon code
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // register code
  const [userId, setUserId] = useState("");
  const [registerData, setRegisterData] = useState({});
  // const { register, handleSubmit, control } = useForm({ values: registerData });
  // const { errors } = useFormState({ control });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({ values: registerData });
  // list api
  const [partnerListData, setPartnerListData] = useState([]);
  const [count, setCount] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState("");
  const handleSearch = (searchValue) => {
    setSearchInputValue(searchValue);
  };
  const [roleId, setRoleId] = useState(null);
  const [investorProfileType, setInvestorProfileType] = useState(2);
  const [active, setActive] = useState(1);
  const [activeStartup, setActiveStartup] = useState(1);
  let reqData;
  const userListItem = async (roleId) => {
    setPartnerListData([]);
    setRoleId(roleId);
    //
    if (roleId === 5) {
      reqData = {
        roleId: roleId,
        listSize: rowsPerPage,
        pageNumber: page + 1,
        searchString: searchInputValue,
        investorProfileType: investorProfileType,
        active: active,
      };
    } else {
      reqData = {
        roleId: roleId,
        listSize: rowsPerPage,
        pageNumber: page + 1,
        searchString: searchInputValue,
        active: activeStartup,
      };
    }
    // await apiService('user/list', reqData, 'post').then((res) => {
    //     if (res) {
    //         setCount(res.data.count)
    //         if (res.data.responseModelList) {
    //             setPartnerListData(res.data.responseModelList)

    //         } else {
    //             setPartnerListData([])
    //         }
    //     }
    // })
    await apiService("user/list", reqData, "post").then((res) => {
      if (res) {
        setCount(res.data.count);
        if (res.data.responseModelList) {
          setPartnerListData(res.data.responseModelList);
        } else {
          setPartnerListData([]);
        }
      }
    });
  };

  //tanseed list api
  const [acceptTanseed, setAcceptTanseed] = useState(true);
  let reqTanseed;
  const userListItemTanseed = async () => {
    setPartnerListData([]);
    reqTanseed = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
      acceptTanseedTerms: acceptTanseed,
    };
    await apiService("user/tanseedstartupslist", reqTanseed, "post").then(
      (res) => {
        if (res) {
          setCount(res.data.count);
          if (res.data.responseModelList) {
            setPartnerListData(res.data.responseModelList);
          } else {
            setPartnerListData([]);
          }
        }
      }
    );
  };

  // startup list api
  const [startupListData, setStartupListData] = useState([]);
  const startuplistItem = async (url, endpoint) => {
    var req = {
      // "roleId": localStorage.getItem('regRoleId'),
      // "listSize": rowsPerPage,
      // "pageNumber": page + 1,
      // "searchString": searchInputValue,
    };
    let method = "";
    if (url === "jury") {
      method = "post";
    } else {
      method = "get";
      await apiService(`${url}/${endpoint}/list`, req, method).then((res) => {
        if (res) {
          if (res.data.startups) {
            // setCount(res.data.count)
            setStartupListData(res.data.startups);
          } else {
            setStartupListData([]);
          }
        }
      });
    }
    await apiService(`${url}/${endpoint}/list`, req, method).then((res) => {
      if (res) {
        if (res.data.startups) {
          // setCount(res.data.count)
          setStartupListData(res.data.startups);
        } else {
          setStartupListData([]);
        }
      }
    });
  };
  // round list api
  const [roundListData, setRoundListData] = useState([]);
  const roundlistItem = async () => {
    setPageLoading(true);
    // var req = {
    // "roleId": localStorage.getItem('regRoleId'),
    // "listSize": rowsPerPage,
    // "pageNumber": page + 1,
    // "searchString": searchInputValue,
    // }
    await apiService("partner/panelround/list", "", "get").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.panelRounds) {
          // setCount(res.data)
          setRoundListData(res.data.panelRounds);
        } else {
          setRoundListData([]);
        }
      }
    });
  };
  const [viewQusData, setViewQusData] = useState([]);
  const viewQues = (value) => {
    setViewQusData(value);
    handleOpen();
  };

  const addRegister = () => {
    setUserId("");
    handleOpen();
  };
  const editValue = (id) => {
    setUserId(id);
    handleOpen();
  };
  // delete user
  const deleteUser = async (id) => {
    apiService(`user/remove?id=${id}`, "", "get").then((res) => {
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Deleted successfully !!");
          userListItem(roleId);
        }
      }
    });
  };
  const [readyOnlyEmail, setReadyOnlyEmail] = useState(false);
  // get user api
  const getRegisterItem = async () => {
    apiService(`user/get?id=${userId}`, "", "get").then((res) => {
      if (res) {
        setReadyOnlyEmail(true);
        setRegisterData(res.data);
      }
    });
  };

  // save user api
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data, event) => {
    setLoading(true);
    const result = Number(data.roleId);
    var getform = data;
    getform.roleId = result;
    // getform.password = cryptoJS(data.password)
    //
    if (getform.id === "0") {
      apiService("register", getform, "unauthpost").then((res) => {
        setLoading(false);
        if (res) {
          notifyService(
            "success",
            "Success",
            "Your registration details added successfully !!"
          );
          event.target.reset();
          handleCancelModal();
          handleSuccessOpen();
        }
      });
    } else {
      apiService("user/update", getform, "post").then((res) => {
        setLoading(false);
        if (res) {
          notifyService(
            "success",
            "Success",
            "Your registration details added successfully !!"
          );
          event.target.reset();
          handleCancelModal();
          handleSuccessOpen();
        }
      });
    }
  };
  const handleCancelModal = () => {
    setReadyOnlyEmail(false);
    setUserId("");
    reset({});
    setRegisterData({});
    // userListItem(roleId)
    //
    handleClose(false);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleSuccessOpen = () => setOpenSuccess(true);
  const handleSuccessClose = () => setOpenSuccess(false);

  // table search code
  const SearchinputDiv = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));
  const modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };
  // pagination code
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    userListItem(roleId);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    userListItem(roleId);
  };

  // form page code
  function getStyles(name, field, theme) {
    return {
      fontWeight:
        field.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));
  const startupCategoryList = [
    "Rural Impact Startups",
    "Green Tech Startup",
    "Women-Led Startup (Minimum equity of 75% should be owned by Woman or Co-owned by a group of Women)",
    "Other Sector Startups",
  ];
  const sectorData = [
    "Aerospace, Defence & Space Tech",
    "Agriculture & Foodtech",
    "AI, ML & IoT",
    "Art, Culture & Architecture",
    "Automotive, EV & Smart Mobility",
    "Blue Economy",
    "Chemicals & Materials",
    "Circular Economy",
    "Climate Tech & Clean Energy",
    "Data Mining & Analytics",
    "Edutech",
    "Femtech",
    "FIntech & Insurtech",
    "Healthcare & Life Sciences",
    "HR Tech & Smart Workforce",
    "Industry 4.0 & Advanced Manufacturing",
    "Lifestyle, Personalcare & D2C",
    "MarketingTech & MICE",
    "Media & Entertainment",
    "PropTech, LegalTech & RegTech",
    "RetailTech",
    "SaaS, Software & IT/ITES",
    "Smart Cities & E-Governance",
    "Social Impact, Rural Livelihood & Sustainability",
    "SportsTech & Gaming",
    "Supply Chain & Logistics",
    "Telecom, Networking & Hardware",
    "TextileTech & Fashion",
    "Travel & Tourism",
    "Web 3.0, Blockchain, VR/AR",
  ];
  const districtList = [
    "Ariyalur",
    "Chengalpattu",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dindigul",
    "Erode",
    "Kallakurichi",
    "Kanchipuram",
    "Kanniyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Mayiladuthurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Ranipet",
    "Salem",
    "Sivagangai",
    "Tenkasi",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tirupathur",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ];
  const fundingProgram = [
    {
      type: "sdadGovernment Schemes",
      partnerList: [
        {
          partner: "StartupTN",
          program: [
            "TANSTEP",
            "TANSEED",
            "TN SC/ST Startup Fund",
            "AngelsTN",
            "TANFUND",
            "Digital Accelerator by ATEA",
          ],
        },
        {
          partner: "EDII",
          program: ["IVP (A&B)"],
        },
        {
          partner: "TNIFMC",
          program: ["TN Emerging Sector Seed Fund"],
        },
        {
          partner: "MSME India",
          program: ["MSME Incubation Scheme"],
        },
      ],
    },
    {
      type: "Incubators",
      partnerList: [
        {
          partner: "TN Based Incubators",
          program: [
            "NIDHI Prayas",
            "BIRAC",
            "Startup India Seed Fund",
            "Atal Incubation Centre",
            "Technical Development Board",
            "NABARD",
            "STPI Finblue",
          ],
        },
      ],
    },
    {
      type: "Private Funding Organisations",
      partnerList: [
        {
          partner: "Angel Networks",
          program: [],
        },
        {
          partner: "Venture Capitalists",
          program: [],
        },
        {
          partner: "Accelerator Program (YC/AWS/Google)",
          program: [],
        },
        {
          partner:
            "Corporate Accelerator Program (CISCO, ShelterTech, BOSCH,TechnoServe))",
          program: [],
        },
        {
          partner: "Co-working Space",
          program: [],
        },
      ],
    },
  ];
  const FounderTitleList = ["Mr", "Mrs", "Ms"];
  const investmentTicketSize = [
    "Up to 5 Lakhs",
    "5 Lakhs to 15 Lakhs",
    "15 Lakhs to 1 Crore",
    "1 Crore to 5 Crore",
    "More than 5 Crore",
  ];
  const minimumRevenue = [
    "Less than INR 1 Lakh",
    "INR 1 Lakh - 10 Lakhs",
    "INR 10 Lakh - 25 Lakhs",
    "INR 25 Lakhs - INR 1 Cr",
    "More than INR 1 Cr",
  ];
  const instrumentTypeData = [
    "Convertible Notes",
    "Debt",
    "Equity",
    "Open to all",
  ];

  // accordion code
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      marginBottom: 10,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#cff6ffab" : "#cff6ffab",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  const modalSuccessPopup = () => {
    handleOpen();
  };
  const [activeStepValue, setActiveStepValue] = useState(0);
  const handlePath = () => {
    // console.log(window.location.hash)
    if (
      window.location.hash === "/startup/form/application" ||
      window.location.hash === "/startup/form"
    ) {
      setActiveStepValue(0);
    }
    // if (window.location.hash === '/startup/form/founders') {
    //     setActiveStepValue(1);
    // }
    if (window.location.hash === "/startup/form/startup-details") {
      setActiveStepValue(1);
    }
    if (window.location.hash === "/startup/form/self-declaration") {
      setActiveStepValue(2);
    }
  };
  const [startupProfileValue, setStartupProfileValue] = useState(false);
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);

  const [yearListValue, setYearListValue] = useState([]);
  const [endYearListValue, setEndYearListValue] = useState([]);
  const yearList = ["All"];
  const endYearList = [];
  const yearListApi = () => {
    let year = new Date().getFullYear();
    for (let i = year; 2021 < i; i--) {
      var val = i.toString();
      yearList.push(val);
      endYearList.push(val);
    }
    setYearListValue(yearList);
    setEndYearListValue(endYearList);
  };
  const [durationListValue, setDurationListValue] = useState([]);
  const durationList = [];
  const durationListApi = () => {
    for (let i = 15; i <= 180; i++) {
      var val = i.toString();
      durationList.push(val);
    }
    setDurationListValue(durationList);
  };
  const [expanded, setExpanded] = useState("panel1");
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded("");
    setExpanded(panel);
  };
  const [dropdownValue, setDropDownValue] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
      (res) => {
        setDropDownValue(res.data.dropDownValues);
      }
    );
  };
  const validateDecimalNumberonly = (e) => {
    const validKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      ".",
    ];
    const input = e.target;
    const cursorPosition = input.selectionStart;
    const inputValue = input.value;
    if (validKeys.includes(e.key)) {
      return;
    }
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
      return;
    }
    const hasDecimalPoint = inputValue.includes(".");
    if (e.key === "." && hasDecimalPoint) {
      e.preventDefault();
      return;
    }
    if (e.key === "0" && cursorPosition === 0 && inputValue === "") {
      return;
    }
  };
  const handleBack = () => {
    navigator("/startup/dashboard");
  };
  const handleBackapp = () => {
    navigator("/startup/dashboard/scst");
  };
  const handleBacktanseed = () => {
    navigator("/startup/dashboard/tanseed");
  };
  const handleBackinvestor = () => {
    navigator("/startup/investor/tanfund");
  };
  const handleBacktanseed7 = () => {
    navigator("/startup/tanseed-7.0/dashboard");
  };
  const [pageLoading, setPageLoading] = useState(false);
  const validateEmail = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailRegex.test(value);
    return isValid || "Invalid email address";
  };

  const validatePhoneNumber = (value) => {
    // const isValid = value.length === 10;
    if (value.length !== 10) {
      return "Phone number must be 10 digits";
    }
    const matches = value.match(
      /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
    );
    if (matches === null) {
      return "Only numbers allowed";
    }
  };
  const validateNumberonly = (e) => {
    // const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    // if (!/[0-9]/.test(e.key) && !validKeys.includes(e.key)) {
    //     e.preventDefault();
    // }
    const validKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"];
    if (!/[0-9]/.test(e.key) && !validKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  const validateAadharNumber = (value) => {
    if (value.length) {
      if (value.length !== 12) {
        return "Aadhar number must be 12 digits";
      }
      // const matches = value.match(
      //     /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/
      // );
      // if (matches === null) {
      //     return "Please enter a valid Aadhar number";
      // }
    }
    // const aadharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    // const isValid = aadharRegex.test(value);
    // return isValid || 'Please enter a valid Aadhar number';
  };
  const validatePANNumber = (value) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    const isValid = panRegex.test(value);
    return isValid || "Please enter a valid PAN number";
  };
  const isWhitespace = (value) => {
    return /^\s*$/.test(value);
  };
  const validateCin = (value) => {
    const cinRegex =
      /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
    const isValid = cinRegex.test(value);
    return isValid || "Please enter a valid CIN number";
    // if (value.length !== 21) {
    //     return "Input must be exactly 21 characters long.";
    // }
    // return true;
  };
  const checkAlphanumeric = (e) => {
    if (!/[0-9A-Za-z]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const validateDPIINumber = (value) => {
    const number = /^dipp\d{4,6}$/i;
    const isValid = number.test(value);
    return (
      isValid ||
      'DPIIT Registration No must be in the format "DIPP1234" where d is a digit (4 to 6 digits)'
    );
  };
  const validatePercentage = (value) => {
    if (value > 100) {
      return "Percentage is invalid";
    }
  };
  const validateIfscNumber = (value) => {
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const isValid = ifscRegex.test(value);
    return isValid || "Please enter a valid IFSC number";
  };
  const checkSpecialChar = (e) => {
    if (!/[0-9A-Z]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleStartupDetails = () => {
    navigator("/investor/profile/details");
  };
  const handlepreference = () => {
    navigator("/investor/profile/preference");
  };
  const handleeoi = () => {
    navigator("/investor/profile/eoi");
  };
  const CurrentDate = () => {
    var today = new Date();
    var month = today.getMonth() + 1;
    var date = today.getDate();
    if (month < 9) {
      month = `0` + month;
    }
    if (date < 9) {
      date = `0` + date;
    }
    return today.getFullYear() + "-" + month + "-" + date;
  };
  const [userLogo, setUserLogo] = useState("");

  useDidMountEffect(() => {
    setUserLogo(localStorage.getItem("logo"));
  }, []);

  const zoomToken = () => {
    apiService("create/token", "", "post")
      .then((result) => {
        if (result && result.data && result.data.access_token) {
          localStorage.setItem("zoomToken", result.data.access_token);
        }
      })
      .catch((err) => {});
  };
  const tableIndexValue = (listSize, pageNumber, index) => {
    return listSize * pageNumber + index + 1;
  };
  // const d = new Date();
  // let year = d.getFullYear();
  const [selectedYear, setSelectedYear] = useState("All");

  const [hasNotification, setHasNotification] = useState(false);
  // const listApi = () => {
  //     var req = {
  //         "startupId": userId
  //     }
  //     apiService('partner/clarification/list', req, 'post').then((result) => {
  //         if (result) {
  //             if (result.data) {
  //                 if (result.data.clarificationDetails) {
  //                     const notifi = result.data.clarificationDetails.map(notifi => notifi.notification)
  //                     setHasNotification(notifi)
  //
  //                 }
  //             }
  //         }
  //     }).catch((err) => {

  //     });
  // }
  const getBDDStatus = (value) => {
    if (value === 0) {
      return "Verification Pending";
    } else if (value === 1) {
      return "Verified";
    } else if (value === 2) {
      return "Under clarification";
    } else if (value === 3) {
      return "Under clarification";
    }
  };
  const getSCSTStartupStatus = (value) => {
    if (value === 0) {
      return "Validation Pending";
    } else if (value === 1) {
      return "Validation Approved";
    } else if (value === 2) {
      return "Validation Rejected";
    } else if (value === 28) {
      return "BDD Approval Pending";
    } else if (value === 3) {
      return "Recommended to BDD First Connect";
    } else if (value === 4) {
      return "Recommended to Mentorship / Other Schemes";
    } else if (value === 5) {
      return "BDD First Connect Details Submitted by Startup";
    } else if (value === 6) {
      return "BDD First Connect Report under Review";
    } else if (value === 7) {
      return "Approved for BDD Deep Dive";
    } else if (value === 8) {
      return "BDD Deep Dive Details Submitted by Startup";
    } else if (value === 9) {
      return "BDD Recommended to Other Schemes";
    } else if (value === 10) {
      return "BDD Deep Dive Report under Review";
    } else if (value === 11) {
      return "Approved for IC";
    } else if (value === 12) {
      return "BDD Deepdive Recommended to Other Schemes";
    } else if (value === 13) {
      return "Approved for PSC";
    } else if (value === 14) {
      return "IC Recommended to Other Schemes";
    } else if (value === 15) {
      return "PSC Rejected";
    } else if (value === 16) {
      return "Approved for DD";
    } else if (value === 17) {
      return "DD Details Submitted by Startup";
    } else if (value === 18) {
      return "DD Report under Review";
    } else if (value === 19) {
      return "DD Recommended to Other Schemes";
    } else if (value === 20) {
      return "Approved for CP";
    } else if (value === 21) {
      return "CP Report under Review";
    } else if (value === 22) {
      return "Approved for Fund Sanction";
    } else if (value === 23) {
      return "CP Recommended to Other Schemes";
    } else if (value === 24) {
      return "Approved for Fund Disbursement";
    } else if (value === 25) {
      return "FS Recommended to Other Schemes";
    } else if (value === 26) {
      return "Approved for CS Stage";
    } else if (value === 27) {
      return "CS Stage";
    }
  };
  const tanseedPartnerRemoveFilter = () => {
    localStorage.removeItem("roundPartners");
    localStorage.removeItem("sectorsPartners");
    localStorage.removeItem("startupCategoryPartners");
    localStorage.removeItem("listSizePartners");
    localStorage.removeItem("pageNumberPartners");
    localStorage.removeItem("searchInputValuePartners");
    localStorage.removeItem("sortRankPartners");
  };
  const tanseedJuryRemoveFilter = () => {
    localStorage.removeItem("roundJury");
    localStorage.removeItem("listSizeJury");
    localStorage.removeItem("pageNumberJury");
    localStorage.removeItem("startupCategoryJury");
    localStorage.removeItem("sectorsJury");
    localStorage.removeItem("partnerValueIdJury");
    localStorage.removeItem("searchInputValueJury");
  };
  const adminStartupRemoveFilter = () => {
    localStorage.removeItem("listSizeAdminStartup");
    localStorage.removeItem("pageNumberAdminStartup");
    localStorage.removeItem("searchStringAdminStartup");
    localStorage.removeItem("activeAdminStartup");
    localStorage.removeItem("applicationStatusAdminStartup");
    localStorage.removeItem("startupStageAdminStartup");
    localStorage.removeItem("cityAdminStartup");
    localStorage.removeItem("revenuStageAdminStartup");
    localStorage.removeItem("sectorsAdminStartup");
  };
  const scstRemoveFiters = () => {
    localStorage.removeItem("rowsPerPageScst");
    localStorage.removeItem("pageScst");
    localStorage.removeItem("districtNameScst");
    localStorage.removeItem("yearNameScst");
    localStorage.removeItem("toYearNameScst");
    localStorage.removeItem("monthNameScst");
    localStorage.removeItem("toMonthNameScst");
    localStorage.removeItem("manualNameScst");
    localStorage.removeItem("searchInputValueScst");
    localStorage.removeItem("phaseValueScst");
    localStorage.removeItem("statusValueScst");
  };

  const scstBDDRemoveFiters = () => {
    localStorage.removeItem("rowsPerPageBDDStartup");
    localStorage.removeItem("pageBDDStartup");
    localStorage.removeItem("districtNameBDDStartup");
    localStorage.removeItem("yearNameBDDStartup");
    localStorage.removeItem("toYearNameBDDStartup");
    localStorage.removeItem("monthNameBDDStartup");
    localStorage.removeItem("toMonthNameBDDStartup");
    localStorage.removeItem("manualNameBDDStartup");
    localStorage.removeItem("searchInputValueBDDStartup");
    localStorage.removeItem("phaseValueBDDStartup");
    localStorage.removeItem("statusValueBDDStartup");
    localStorage.removeItem("scsthubBDDStartup");
  };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const [openPop, setOpenPop] = useState(false);
  return (
    <>
      <DataContext.Provider
        value={{
          handleStartupDetails,
          handlepreference,
          handleeoi,
          tableIndexValue,
          validateEmail,
          validatePhoneNumber,
          validateNumberonly,
          validatePercentage,
          validateIfscNumber,
          checkSpecialChar,
          validateDecimalNumberonly,
          // dropdown Value
          dropdownValueApi,
          dropdownValue,
          pageLoading,
          setPageLoading,
          handleBack,
          handleBackapp,
          expanded,
          handlePanelChange,
          setExpanded,
          setUserId,
          startupProfileValue,
          setStartupProfileValue,
          readyOnlyValue,
          setReadyOnlyValue,
          yearListValue,
          endYearListValue,
          yearListApi,
          openSuccess,
          handleSuccessOpen,
          handleSuccessClose,
          deleteUser,
          readyOnlyEmail,
          theme,
          fullScreen,
          SearchinputDiv,
          SearchIconWrapper,
          StyledInputBase,
          modalstyle,
          userId,
          searchInputValue,
          setSearchInputValue,
          page,
          rowsPerPage,
          handleChangePage,
          handleChangeRowsPerPage,
          partnerListData,
          count,
          setCount,
          handleSearch,
          editValue,
          userListItem,
          investorProfileType,
          setInvestorProfileType,
          active,
          setActive,
          activeStartup,
          setActiveStartup,
          userListItemTanseed,
          acceptTanseed,
          setAcceptTanseed,
          handleSubmit,
          control,
          setValue,
          clearErrors,
          errors,
          showPassword,
          handleClickShowPassword,
          handleMouseDownPassword,
          loading,
          setLoading,
          onSubmit,
          handleCancelModal,
          open,
          handleOpen,
          handleClose,
          getRegisterItem,
          addRegister,
          VisuallyHiddenInput,
          CurrentDate,
          //Bdd Bell gif
          setHasNotification,
          hasNotification,
          // form 1 code
          tomorrow,
          MenuProps,
          BootstrapInput,
          startupCategoryList,
          sectorData,
          districtList,
          getStyles,
          fundingProgram,
          // startuplist is admin
          startuplistItem,
          startupListData,
          // roundlist in partner
          roundlistItem,
          roundListData,
          viewQues,
          viewQusData,
          FounderTitleList,
          modalSuccessPopup,
          navigator,
          // navbar function
          handlePath,
          activeStepValue,
          setActiveStepValue,
          // partner profile save
          investmentTicketSize,
          minimumRevenue,
          instrumentTypeData,
          zoomToken,
          durationListApi,
          durationListValue,
          userLogo,
          setUserLogo,
          Accordion,
          AccordionSummary,
          AccordionDetails,
          icon,
          checkedIcon,
          validateAadharNumber,
          validatePANNumber,
          isWhitespace,
          validateDPIINumber,
          checkAlphanumeric,
          handleBacktanseed,
          handleBackinvestor,
          handleBacktanseed7,
          selectedYear,
          setSelectedYear,
          tanseedPartnerRemoveFilter,
          tanseedJuryRemoveFilter,
          adminStartupRemoveFilter,
          scstRemoveFiters,
          scstBDDRemoveFiters,
          getBDDStatus,
          getSCSTStartupStatus,
          validateCin,
          AntSwitch,
          openPop,
          setOpenPop,
        }}
      >
        {children}
      </DataContext.Provider>
    </>
  );
};
export default DataContext;

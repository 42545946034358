import React, { useState } from 'react'
import Viewimage from '../../../../../api/Viewimage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Tab } from '@mui/material';
const Fundingfinancialsview = ({ details, detail }) => {
    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <main>
            {/* <section>
                <div className='row'>
                    <div className='col-lg-12 col-12'>
                        <div className='row'>
                            <div className='col-lg-6 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Expected Valuation :</label>
                                    <p className='ml-auto'><b>{details.tentativeValuation}</b></p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Preferred Investor Type :</label>
                                    <p className='ml-auto'><b>{details.preferredInvestorType}</b></p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Desired Funding Amount (₹) :</label>
                                    <p className='ml-auto'><b>{details.investTicketSize}</b></p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Preferred Funding Instrument :</label>
                                    <p className='ml-auto'><b>{details.investmentInstrumentType}</b></p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Revenue Stage :</label>
                                    <p className='ml-auto'><b>{details.revenueStage}</b></p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Annual Revenue (₹) :</label>
                                    <p className='ml-auto'><b>{details.annualRevenue}</b></p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Monthly Burn Rate  (₹) :</label>
                                    <p className='ml-auto'><b>{details.monthlyBurnRate}</b></p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='d-flex'>
                                    <label className='mr-3'>Total Funds Raised till Date (₹) :</label>
                                    <p className='ml-auto'><b>{details.totalFundsRaised}</b></p>
                                </div>
                            </div>
                            {
                                (details.fundHistory) && (
                                    <div className='col-lg-12 col-12 my-3'>
                                        <div className='div_header'>
                                            <h6>Previous Fundraising History</h6>
                                        </div>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Fund Raised (₹)</TableCell>
                                                        <TableCell>Organization Name</TableCell>
                                                        <TableCell>Instrument Type</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {details.fundHistory.map((list, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>
                                                                {list.fundRaised}
                                                            </TableCell>
                                                            <TableCell>
                                                                {list.organisationName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {list.instrumentType}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>

                                        </TableContainer>
                                    </div>
                                )
                            }
                            {
                                (details.startupFundingPrograms) && (
                                    <div className='col-lg-12 col-12 my-3'>
                                        <div className='div_header'>
                                            <h6>Funding Programs</h6>
                                        </div>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Partner</TableCell>
                                                        <TableCell>Program</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {details.startupFundingPrograms.map((list, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>
                                                                {list.type}
                                                            </TableCell>
                                                            <TableCell>
                                                                {list.partner}
                                                            </TableCell>
                                                            <TableCell>
                                                                {list.program}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>

                                        </TableContainer>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section> */}
            <section>
                {/* <div className='header_tag'>
                    <span className='d-flex'><img src={details.startupLogoName} className='img-fluid user_logos' alt='' /><h3 className='startupname'>{detail.startupName}</h3></span>
                    <span className='black-clr'><img src='/images/phone.png' alt='' className='mr-2' /> {detail.startupPhone ? (detail.startupPhone) : ('-')}</span>
                    <span className='black-clr'><img src='/images/email.png' alt='' className='mr-2' /> {detail.startupEmail ? (detail.startupEmail) : ('-')}</span>
                    <span className='social-datas'>
                        <Stack className='social-Stack' direction="row" spacing={1}>
                            {(details.linkedIn) && (
                                <Link to={`${details.linkedIn}`} target="_blank">
                                    <img src='/images/linkedin.png' alt='LinkedIn' className='linkedinicon' />
                                </Link>
                            )}
                            {(details.website) && (
                                <Link to={`${details.website}`} target="_blank">
                                    <img src='/images/globe.png' alt='Website' className='websiteicon' />
                                </Link>
                            )}
                        </Stack>
                    </span>
                </div> */}
                {/* <div className='rec-info1 mt-4'>
                    <span className='d-flex'><img src='/images/purple-icon.png' alt='' className='purple-star mr-3' /><b><span className='grey-clr'>Incubated from </span><span className='purple-clr'>TAMILNADU AGRICULTURE COLLEGE</span></b></span>
                </div>
                <div className='rec-info2 mt-3'>
                    <span className='d-flex'><img src='/images/blue-icon.png' alt='' className='purple-star mr-3' /><b><span className='blue-clr'>IAF award, Recognized by TNAU</span></b></span>
                </div> */}
                <div className=''>
                    {/* <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Funding & Financials" value="1" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" className='application '>
                                
                            </TabPanel>
                        </TabContext>
                    </Box> */}
                    <div className=''>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <p><b>Expected Valuation :</b></p>
                                <p>{details.tentativeValuation}</p>
                            </div>
                            <div className='col-lg-4'>
                                <p><b>Preferred Investor Type :</b></p>
                                <p>{details.preferredInvestorType}</p>
                            </div>
                            <div className='col-lg-4'>
                                <p className='mt-4'><b>Preferred Funding Instrument :</b></p>
                                <p>{details.investmentInstrumentType}</p>
                            </div>
                            <div className='col-lg-4'>
                                <p className='mt-4'><b>Revenue Stage :</b></p>
                                <p>{details.revenueStage}</p>
                            </div>
                            <div className='col-lg-4'>
                                <p className='mt-4'><b>Desired Funding Amount (₹) :</b></p>
                                <p>{details.investTicketSize}</p>
                            </div>
                            <div className='col-lg-4'>
                                <p className='mt-4'><b>Monthly Burn Rate (₹) :</b></p>
                                <p>{details.monthlyBurnRate}</p>
                            </div>
                            <div className='col-lg-4'>
                                <p className='mt-4'><b>Annual Revenue (₹) :</b></p>
                                <p>{details.annualRevenue}</p>
                            </div>
                            <div className='col-lg-4'>
                                <p className='mt-4'><b>Total Funds Raised till Date (₹) :</b></p>
                                <p>{details.totalFundsRaised}</p>
                            </div>
                        </div>
                    </div>
                    {
                        (details.fundHistory) && (
                            <div className='col-lg-12 col-12 my-3'>
                                <div className='div_header'>
                                    <h6>Previous Fundraising History</h6>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead className='tablehead'>
                                            <TableRow>
                                                <TableCell>No</TableCell>
                                                <TableCell>Fund Raised (₹)</TableCell>
                                                <TableCell>Organization Name</TableCell>
                                                <TableCell>Instrument Type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {details.fundHistory.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        {list.fundRaised}
                                                    </TableCell>
                                                    <TableCell>
                                                        {list.organisationName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {list.instrumentType}
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </div>
                        )
                    }
                    {
                        (details.startupFundingPrograms) && (
                            <div className='col-lg-12 col-12 my-5'>
                                <div className='div_header'>
                                    <h6>Funding Programs</h6>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead className='tablehead'>
                                            <TableRow>
                                                <TableCell>No</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Partner</TableCell>
                                                <TableCell>Program</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {details.startupFundingPrograms.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        {list.type}
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='green-clr'><b>{list.partner}</b></span>
                                                    </TableCell>
                                                    <TableCell>
                                                        {list.program}
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )
                    }
                </div>
            </section>

            <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
        </main>
    )
}

export default Fundingfinancialsview
import React, { useState } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Additionalinfo from "../additional-info-page/Additionalinfo";

const Tanseedpage = () => {
  useDidMountEffect(() => {
    (async () => await tanseedpage())();
  }, []);

  const [tanseedData, setTanseedData] = useState([]);
  const tanseedpage = () => {
    apiService(`startup/partners/tanseed/get`, "", "get").then((res) => {
      if (res) {
        if (res.data.startupPartner) {
          setTanseedData(res.data.startupPartner);
        } else {
          setTanseedData([]);
        }
      }
    });
  };

  return (
    <div>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            {/* <h6 className='ml-2 mb-0'>Progress</h6> */}
            <h5 className="font-weight-bold mb-3">Tanseed</h5>
          </div>
          <hr />
          {/* <div className='header_tag'>
          <h5>Progress</h5>
        </div> */}
          <div className="table_tag">
            {tanseedData.length ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>Partner Name</TableCell>
                      <TableCell align="center">Partner Comment</TableCell>
                      <TableCell align="center">Selected</TableCell>
                      <TableCell align="center">Additional Info</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tanseedData.map((list, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{list.partnerName}</TableCell>
                        <TableCell align="center">
                          {list.partnerComments ? list.partnerComments : `-`}
                        </TableCell>
                        <TableCell align="center">
                          {list.selected === 1 ? (
                            <span className="yes_span">Selected</span>
                          ) : (
                            <></>
                          )}
                          {list.selected === 2 ? (
                            <span className="no_span">Not Selected</span>
                          ) : (
                            <> </>
                          )}
                          {list.selected === 0 ? (
                            <span className="review_span">Under Review</span>
                          ) : (
                            <></>
                          )}
                        </TableCell>

                        <TableCell align="center">
                          <p className="nav-link mb-0">
                            <Additionalinfo
                              status={list.additionalInfo}
                              id={list.partnerorInvestorId}
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="nodata_tag">
                <img
                  src="/images/Tanfundlogo-black-1.png"
                  className="no_data_logo"
                  alt=""
                />
                <p>A Partner will be assigned to you soon</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Tanseedpage;

import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
const fileTypes = ["pdf"];

const FddSectionL = () => {
  const [formData, setFormData] = useState({});
  const { handleBackapp, setPageLoading,navigator } = useContext(DataContext)
  const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [operating, setOperating] = useState();
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/secl/get`, '', 'get').then((res) => {
      if (res && res.data) {
        var data = res.data


        setFormData(res.data)
      }
      setPageLoading(false)
    })
  }




  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    console.log(data);

    setPageLoading(true);

    apiService('startup/fdd/secl/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-m");
        }
      }
    })
  }
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Non Operational Account</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>

            <div className="row doc-row2">

              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Do you have any non operating accounts</FormLabel>
                  <Controller name="nonOperatingAccounts" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setOperating(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setOperating(e.target.value); setValue('nonOperatingAccounts', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.nonOperatingAccounts && errors.nonOperatingAccounts.message}</FormHelperText>
                </div>
              </div>

            </div>


            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
        <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      </section>
    </>
  )
}

export default FddSectionL
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const Startupappication = ({ application }) => {
  return (
    <>
      <main>
        <section>
          <div className='row'>
            <div className='col-lg-9 col-12'>
              <div className='row'>
                <div className='col-lg-6 col-12'>
                  <div className='d-flex'>
                    <label className='mr-3'>Incorporation Date :</label>
                    <p className='ml-auto'><b>{application.incorporatedDate}</b></p>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className='d-flex'>
                    <label className='mr-3'>Type of Entity :</label>
                    <p className='ml-auto'><b>{application.entityType}</b></p>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className='d-flex'>
                    <label className='mr-3'>Business Models :</label>
                    <p className='ml-auto'><b>{application.businessModel}</b></p>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className='d-flex'>
                    <label className='mr-3'>Team Size :</label>
                    <p className='ml-auto'><b>{application.teamSize}</b></p>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className='d-flex'>
                    <label className='mr-3'>Incorporation Certificate :</label>
                    <div className='ml-auto'>
                      <Link to={`${application.inCorpCertificateName}`} target="_blank"><span className='viewdetails'>View File</span></Link>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className='d-flex'>
                    <label className='mr-3'>City :</label>
                    <p className='ml-auto'><b>{application.city}</b></p>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className='d-flex'>
                    <label className='mr-3'>State :</label>
                    <p className='ml-auto'><b>{application.state}</b></p>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className='d-flex'>
                    <label className='mr-3'>Country :</label>
                    <p className='ml-auto'><b>{application.country}</b></p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-12 mb-3'>
              <img src={application.startupLogoName} className='img-fluid' alt='' />
              <div className='social-datas'>
                <Stack className='social-Stack' direction="row" spacing={1}>
                  {(application.website) && (<><Link to={`${application.website}`} target="_blank"><LanguageIcon className='websiteicon' /></Link></>)}
                  {(application.linkedIn) && (<><Link to={`${application.linkedIn}`} target="_blank"><LinkedInIcon className='linkedinicon' /></Link></>)}
                  {(application.twitter) && (<><Link to={`${application.twitter}`} target="_blank"><span className='twittericon'>&#120143;</span></Link></>)}    
                </Stack>
              </div>
            </div>
            {
              (application.startupFounders) && (
                <div className='col-lg-12 col-12 my-3'>
                  <div className='div_header'>
                    <h6>Founder Details</h6>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell>Founder Name</TableCell>
                          <TableCell>Contact Number</TableCell>
                          <TableCell>Email ID</TableCell>
                          <TableCell>Designation</TableCell>
                          <TableCell>LinkedIn Profile Link</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {application.startupFounders.map((list, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {list.name}
                            </TableCell>
                            <TableCell>
                              {list.phone}
                            </TableCell>
                            <TableCell>
                              {list.email}
                            </TableCell>
                            <TableCell>
                              {list.designation}
                            </TableCell>
                            <TableCell>
                              {list.linkedIn}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                  </TableContainer>
                </div>
              ) 
            }
            {
              (application.startupAdvisories) && (
                <div className='col-lg-12 col-12 my-3'>
                  <div className='div_header'>
                    <h6>Advisory / Mentors Details</h6>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell>Advisory / Mentors Name</TableCell>
                          <TableCell>LinkedIn Profile Link</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {application.startupAdvisories.map((list, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {list.name}
                            </TableCell>
                            <TableCell>
                              {list.linkedIn}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                  </TableContainer>
                </div>
              )
            }
          </div>
        </section>
      </main>
    </>
  )
}

export default Startupappication
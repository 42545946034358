import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import {
  Button,
  FormControl,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../../context/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

const InvestorTeaminfo = () => {
  const [formData, setFormData] = useState({
    startupFounders: [{}],
   
  });
  const {
    navigator,
    handleBackinvestor,
    Paper,
    validateEmail,
    validatePhoneNumber,
  } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    fields: foundersFields,
    append: founderAppend,
    remove: foundersRemove,
  } = useFieldArray({
    control,
    name: "startupFounders",
  });
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
  }, []);
  const getApi = async () => {
    await apiService(`startup/team/get`, "", "get").then((res) => {
      if (res) {
        setFormData({});
        let response = res.data;
        if (response.id !== 0) {
          setFormData(response);
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
        } else {
          setFormData(response);
        }
      }
    });
  };
  const addFounderDetailsBtn = () => {
    founderAppend({
      name: "",
      email: "",
      phone: "",
      designation:""
    });
  };
 
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      apiService("startup/team/save", getform, "post").then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            navigator('/startup/investor/Documents');
          }
        }
      });
    }
  };
  // delete Founders
  const deleteFounders = async (index) => {
    const id = getValues(`startupFounders.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/founders/remove?id=${id}`, "", "get").then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            foundersRemove(index);
            getApi();
          }
        }
      });
    } else {
      foundersRemove(index);
    }
  };
  // delete Advisories

  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackinvestor}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Team Info</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="div_header">
                  <div className="d-flex">
                    <h6>Founder Details</h6>
                    <Button
                      variant="outlined"
                      disabled={readyOnlyValue}
                      className="ml-auto"
                      onClick={() => addFounderDetailsBtn("")}
                    >
                      Add Founder Details
                    </Button>
                  </div>
                </div>
                <div>
                  {foundersFields.length ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Founder Name * </TableCell>
                            <TableCell>Email *</TableCell>
                            <TableCell>Designation *</TableCell>
                            <TableCell>Contact Number *</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {foundersFields.map((field, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupFounders.${index}.name`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Name is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Name"
                                        placeholder="Enter Name"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupFounders?.[index]?.name &&
                                      errors.startupFounders?.[index]?.name
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupFounders.${index}.email`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Email is required",
                                      validate: validateEmail,
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        // id={`email-${index}`}
                                        variant="outlined"
                                        label="Email *"
                                        placeholder="Enter Email"
                                        fullWidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupFounders?.[index]?.email &&
                                      errors.startupFounders?.[index]?.email
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupFounders.${index}.designation`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Designation is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Designation"
                                        placeholder="Enter Designation"
                                        fullwidth
                                        {...field}
                                        disabled={readyOnlyValue}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupFounders?.[index]?.designation &&
                                      errors.startupFounders?.[index]?.designation
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`startupFounders.${index}.phone`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Contact number is required",
                                      validate: validatePhoneNumber,
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id={`phone-${index}`}
                                        variant="outlined"
                                        label="Contact Number *"
                                        placeholder="Enter Contact Number"
                                        fullWidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                          maxLength: 10,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupFounders?.[index]?.phone &&
                                      errors.startupFounders?.[index]?.phone
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center">
                                <DeleteIcon
                                  style={{ fill: "#d32f2f", cursor: "pointer" }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      deleteFounders(index)
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="nodata_tag">
                      <p>Founder details is empty</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBackinvestor}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default InvestorTeaminfo;

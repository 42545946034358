import React, { useContext, useState } from 'react'
import DataContext from '../../../../../context/DataContext';
import Reviewroundsave from './review-round-tab/Reviewroundsave';
import { Typography } from '@mui/material';

const Reviewrounds = ({ roundListData, path, idNumber }) => {
  const { Accordion, AccordionSummary, AccordionDetails } = useContext(DataContext)
  const [expanded, setExpanded] = useState('panel0');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <main>
        <section>
          {roundListData.map((data, index) => (
            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <AccordionSummary aria-controls="panel1d-content" id={`panel${index}d-header`}>
                <Typography>
                  {
                    (data.roundName) ? (data.roundName) : (<>Round</>)
                  }
                </Typography>
                <span className='ml-auto'>Avarage Score : <b>{data.avgScore}</b></span>
              </AccordionSummary>
              {
                (path === 'partner') ?
                  (
                    <AccordionDetails>
                      <Typography>
                        <Reviewroundsave data={data} idNumber={idNumber} />
                      </Typography>
                    </AccordionDetails>
                  ) : (
                    <AccordionDetails>
                      <Typography>
                        <Reviewroundsave data={data} idNumber={idNumber} />
                      </Typography>
                    </AccordionDetails>

                  )
              }

            </Accordion>
          ))
          }
        </section>
      </main>
    </>
  )
}

export default Reviewrounds
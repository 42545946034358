import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import {
  Autocomplete,
  Checkbox,
  Container,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select
} from "@mui/material";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import apiService from "../../../../api/apiService";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import DataContext from "../../../../context/DataContext";
import notifyService from "../../../../api/notifySerivce";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import CloseIcon from "@mui/icons-material/Close";
import Remarksmodal from "../../../scst-page/Remarksmodal";

const Tanseedscoremodal = ({
  idNumber,
  path,
  partnerOrInvestorId,
  roundNo,
  viewStartupDetails,
}) => {
  const [open, setOpen] = React.useState(false);

  const { modalstyle, icon, checkedIcon } = useContext(DataContext);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    // setOpenList(newOpen);
  };


  const [dropdownRemarks, setDropdownRemarks] = useState('');

  const handleChange = (event) => {
    setDropdownRemarks(event.target.value);
    
  };
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm({ values: formData });
  const { fields: questionsFields, remove: questionRemove } = useFieldArray({
    control,
    name: "startupQuestions",
  });
  const [lblData, setLblData] = useState({});
  const [dropShow, setDropShow] = useState(false)
  useDidMountEffect(() => {
    dropdownValueApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [suggestionData, setSuggestionData] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=suggestions`,
      "",
      "get"
    ).then((res) => {
      if (res) {
        if (res.data) {
          var val = res.data.dropDownValues;
          if (val.suggestions) {
            setSuggestionData(val.suggestions);
          }
        }
      }
    });
  };
  // const [round, setRound] = useState(1)
  const getApi = () => {
    setOpen(true);
    setSuggestionValue([]);
    setFormData({});
    setApproveStatus(true);
    apiService(
      `jury/startup/rounds/getcurrentround?startupid=${idNumber}`,
      "",
      "get"
    )
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.startupQuestions) {
              setFormData(result.data);
              result.data.startupQuestions.forEach((element) => {
                if (element.answer === "Yes") {
                  setApproveStatus(false);
                  return;
                }
              });
              // if (result.data.round) {
              //     setRound(result.data.round)
              // }
            }
          }
        }
      })
      .catch((err) => {});
  };

  const [approveStatus, setApproveStatus] = useState(true);
  const handleCheck = (index, value) => {
    setValue(`startupQuestions.${index}.answer`, value);
    clearErrors(`startupQuestions.${index}.answer`);
    if (value === "Yes") {
      setApproveStatus(false);
    }
  };
  const handleCheckScore = (index, value) => {
    setValue(`startupQuestions.${index}.score`, value);
    clearErrors(`startupQuestions.${index}.score`);
  };
  const [openRemark, setOpenRemark] = useState(false);
  const [remarkIndex, setRemarkIndex] = useState();
  const [remarkValue, setRemarkValue] = useState("");
  const handleOpenRemark = (index) => {
    setOpenRemark(true);
    setRemarkIndex(index);
    var data = getValues(`startupQuestions.${index}.remarks`);
    setRemarkValue(data);
  };
  const handleCloseRemark = (index, value) => {
    setOpenRemark(false);
    setValue(`startupQuestions.${index}.remarks`, value);
    clearErrors(`startupQuestions.${index}.remarks`);
  };
 
  const handleRecommendBtn = (value) => {
    debugger
    setOpenRemark(false);
    setDropShow(value === 4);
    
    setValue(`status`, value);
    clearErrors(`status`);
    debugger
  };
  const [comment, setComment] = useState("");
  const handleComment = (value) => {
    setValue("remarks", value);
    clearErrors("remarks");
    setComment(value);
  };
  const [suggestionValue, setSuggestionValue] = useState([]);
  const handleSelectSuggestion = (value) => {
    setSuggestionValue(value);
    setValue("suggestions", value);
    clearErrors("suggestions");
  };
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    debugger
    let remarkStatus;
    if (data.status === 2) {
      remarkStatus = true;
    } else {
      remarkStatus = false;
      clearErrors("remarks");
    }
    if (remarkStatus === true && data.remarks !== "") {
      saveApi(data);
    } else if (remarkStatus === false) {
      saveApi(data);
    } else if (remarkStatus === true) {
      setError("remarks", { message: "Remark is required" });
    }
  };
  const saveApi = (data) => {
    debugger;
    setLoading(true);
    apiService("jury/startup/round/answers/savecurrentround", data, "post")
      .then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            toggleClose();
          }
        }
      })
      .catch((err) => {});
  };
  const onError = (event) => {
    console.log(event);
  };
  const toggleClose = () => {
    reset();
    questionRemove();
    setFormData([]);
    setOpen(false);
    viewStartupDetails(idNumber, partnerOrInvestorId);
  };

  const number = 4;
  const DrawerList = (
    <Box sx={{ width: 350 }} role="presentation">
      <div>
        <div className="full-div">
          {/* <div></div> */}
          {/* <h4 className="mb-4">
              <b>Rate your Score here</b>
            </h4> */}
          <form
            className="signin-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            {questionsFields.length > 0 ? (
              <>
                <div className="qus_div">
                  {questionsFields.map((value, index) => (
                    <>
                      <div className="form-group mb-5">
                        <p>
                          <b>
                            {index + 1}. {value.question}
                          </b>
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                          {value.questionType === "yesno" ? (
                            <div className="w-100">
                              <div className="d-flex align-items-center justify-content-center w-100">
                                <Controller
                                  name={`startupQuestions.${index}.answer`}
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: "Answer is required" }}
                                  render={({ field }) => (
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-form-control-label-placement"
                                      name="position"
                                      {...field}
                                    >
                                      <div className="d-flex align-items-center justify-content-center w-100 main_toggle">
                                        <div
                                          className={
                                            getValues(
                                              `startupQuestions.${index}.answer`
                                            ) === "Yes"
                                              ? "customs_toggle toggleActive"
                                              : "customs_toggle"
                                          }
                                          onClick={() => {
                                            formData.status === 0 &&
                                              handleCheck(index, "Yes");
                                          }}
                                        >
                                          Yes
                                        </div>
                                        <div
                                          className={
                                            getValues(
                                              `startupQuestions.${index}.answer`
                                            ) === "No"
                                              ? "customs_toggle toggleActive"
                                              : "customs_toggle"
                                          }
                                          onClick={() => {
                                            formData.status === 0 &&
                                              handleCheck(index, "No");
                                          }}
                                        >
                                          No
                                        </div>
                                      </div>
                                    </RadioGroup>
                                  )}
                                />
                              </div>
                              <FormHelperText className="text-danger text-center">
                                {errors.startupQuestions?.[index]?.answer &&
                                  errors.startupQuestions?.[index]?.answer
                                    .message}
                              </FormHelperText>
                            </div>
                          ) : (
                            <>
                              {roundNo > 1 ? (
                                <>
                                  <div>
                                    <div>
                                      Score{" "}
                                      <span className="text-danger">*</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      {[...Array(5)].map((_, i) => (
                                        <>
                                          <div
                                            className={
                                              getValues(
                                                `startupQuestions.${index}.score`
                                              ) ===
                                              i + 1
                                                ? "point_css pointActive"
                                                : "point_css"
                                            }
                                            onClick={() => {
                                              formData.status === 0 &&
                                                handleCheckScore(index, i + 1);
                                            }}
                                          >
                                            {i + 1}
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                    <div className="d-none">
                                      <div className="form-group mb-3">
                                        <Controller
                                          name={`startupQuestions.${index}.score`}
                                          control={control}
                                          defaultValue=""
                                          // rules={{
                                          //   required: "Score is required",
                                          //   validate: {
                                          //     positiveOrZero: (value) => {
                                          //       const parsedValue = parseInt(
                                          //         value,
                                          //         10
                                          //       );
                                          //       return (
                                          //         parsedValue > 0 ||
                                          //         "Score is required"
                                          //       );
                                          //     },
                                          //   },
                                          // }}
                                          rules={{
                                            required: {
                                              value: getValues("status") !== 4,
                                              message: "Score is required"
                                            },
                                            validate: {
                                              positiveOrZero: (value) => {
                                                if (getValues("status") === 4) return true; // Skip validation
                                                const parsedValue = parseInt(value, 10);
                                                return parsedValue > 0 || "Score is required";
                                              }
                                            }
                                          }}
                                          render={({ field }) => (
                                            <TextField {...field} />
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <FormHelperText className="text-danger text-center">
                                      {errors.startupQuestions?.[index]
                                        ?.score &&
                                        errors.startupQuestions?.[index]?.score
                                          .message}
                                    </FormHelperText>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Controller
                                    name={`startupQuestions.${index}.score`}
                                    control={control}
                                    defaultValue=""
                                    // rules={{ required: "Score is required" }}
                                    rules={{ required: {
                                      value: getValues("status") !== 4,
                                      message: "Score is required"
                                    } }}
                                    
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Score *"
                                        placeholder="Enter Score"
                                        fullWidth
                                        {...field}
                                        type="number"
                                        onInput={(e) => {
                                          if (e.target.value !== 0) {
                                            setApproveStatus(false);
                                          } else {
                                            setApproveStatus(true);
                                          }
                                        }}
                                        inputProps={{
                                          readOnly:
                                            formData.status !== 0 && true,
                                        }}
                                        onWheel={(event) =>
                                          event.currentTarget
                                            .querySelector("input")
                                            ?.blur()
                                        }
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.startupQuestions?.[index]?.score &&
                                      errors.startupQuestions?.[index]?.score
                                        .message}
                                  </FormHelperText>
                                </>
                              )}
                            </>
                          )}
                          <div className="ml-3">
                            <Controller
                              name={`startupQuestions.${index}.remarks`}
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <Button
                                  variant="outlined"
                                  onClick={() => handleOpenRemark(index)}
                                >
                                  Remarks
                                </Button>
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.startupQuestions?.[index]?.remarks &&
                                errors.startupQuestions?.[index]?.remarks
                                  .message}
                            </FormHelperText>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                {formData.status === 0 ? (
                  <div className="mb-4">
                    <Controller
                      name="suggestions"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          multiple
                          {...field}
                          limitTags={2}
                          value={suggestionValue}
                          options={suggestionData}
                          onChange={(e, selectedOptions) =>
                            handleSelectSuggestion(selectedOptions)
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Suggestion" />
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="fnt-sm c-blue">
                      Select one or more
                    </FormHelperText>
                    <FormHelperText className="text-danger">
                      {errors.suggestions && errors.suggestions.message}
                    </FormHelperText>
                  </div>
                ) : (
                  <div>
                    <h6 className="my-4">
                      Suggestions : <b></b>
                    </h6>
                    <ul>
                      {formData.suggestions &&
                        formData.suggestions.map((res) => <li>{res}</li>)}
                    </ul>
                  </div>
                )}
                {formData.status === 0 ? (
                <>
                 {dropShow ?(<>
                    <FormControl fullWidth>
                    <Controller
                      name={`remarks`}
                      control={control}
                      defaultValue=""
                      rules={{ required: "Reason is Required" }}
                      render={({ field }) => (
                        <>
                        <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={dropdownRemarks}
                          label="Reason"
                          onChange={(e) => {
                            handleChange(e);
                            field.onChange(e.target.value); 
                          }}
                        >
                          <MenuItem value={"Absent"}>Absent </MenuItem>
                          <MenuItem value={"conflict of interest"}>Conflict Of Interest</MenuItem>
                          <MenuItem value={"I don’t understand this "}>I don’t Understand this </MenuItem>
                         
                        </Select>
                        </>
                      )}
                    />
                    </FormControl>
                    
                    <FormHelperText className="text-danger">
                      {errors.remarks && errors.remarks.message}
                    </FormHelperText>
                    </>)
                 :
                 (<>
                    <div>
                    <Controller
                      name={`remarks`}
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          id="filled-textarea"
                          label="Remarks / Feedback"
                          placeholder=""
                          multiline
                          variant="filled"
                          {...field}
                          fullWidth
                          rows={4}
                          value={comment}
                          onInput={(e) => handleComment(e.target.value)}
                          inputProps={{
                            readOnly: formData.status !== 0 && true,
                          }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.remarks && "Remark is required"}
                    </FormHelperText>
                  </div>
                    </>) 
               }
                </>
                ) : (
                  <div>
                    <h6 className="my-4">
                      Remarks : <b>{formData.remarks}</b>
                    </h6>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="text-center py-4">
                  <h5>Rounds Completed</h5>
                </div>
              </>
            )}
            {roundNo === 1 ? (
              <>
                {formData.status === 0 ? (
                  <div className="form-group text-center my-3">
                    {questionsFields.length > 0 && (
                      <>
                        {approveStatus === false && (
                          <LoadingButton
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            onClick={() => setValue("status", 1)}
                            sx={{
                              padding: "10px 20px !important",
                              margin: "3px",
                              borderRadius: "25px",
                              "&:hover": {
                                backgroundColor: "green",
                              },
                            }}
                          >
                            <span>Recommend to Round 2</span>
                          </LoadingButton>
                        )}
                        <LoadingButton
                          type="submit"
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<CloseIcon />}
                          variant="contained"
                          sx={{
                            padding: "10px 20px !important",
                            margin: "3px",
                            borderRadius: "25px",
                            "&:hover": {
                              backgroundColor: "red",
                            },
                          }}
                          onClick={() => setValue("status", 2)}
                        >
                          <span>Reject</span>
                        </LoadingButton>
                      </>
                    )}
                    <Button
                      variant="contained"
                      onClick={toggleClose}
                      sx={{
                        padding: "10px 23px !important",
                        margin: "3px",
                        borderRadius: "25px",
                        "&:hover": {
                          backgroundColor: "#253b80 ",
                        },
                      }}
                    >
                      {questionsFields.length > 0 ? "Cancel" : "OK"}
                    </Button>
                  </div>
                ) : (
                  <>
                    {formData.status === 1 && (
                      <h3 className="text-center my-4">
                        <span className="approved_span c-green">
                          <b>Recommended to Round 2</b>
                        </span>
                      </h3>
                    )}
                    {formData.status === 2 && (
                      <h3 className="text-center my-4">
                        <span className="rejected_span c-red">
                          <b>Rejected</b>
                        </span>
                      </h3>
                    )}
                    <div className="text-center mt-3">
                      <Button
                        variant="contained"
                        onClick={toggleClose}
                        sx={{
                          padding: "10px 23px !important",
                          marginRight: "3px",
                          borderRadius: "25px",
                          "&:hover": {
                            backgroundColor: "#253b80 ",
                          },
                        }}
                      >
                        {questionsFields.length > 0 ? "Cancel" : "OK"}
                      </Button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {formData.status === 0 && questionsFields.length > 0 ? (
                  <>
                    <div className="d-flex align-items-center my-3">
                      <p className="mb-0">Do you recommend :</p>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <p
                            className={
                              getValues("status") === 3
                                ? "recomment_css recommentActive"
                                : "recomment_css"
                            }
                            onClick={() => {
                              handleRecommendBtn(3);
                            }}
                          >
                            Yes
                          </p>
                          <p
                            className={
                              getValues("status") === 1
                                ? "recomment_css recommentActive"
                                : "recomment_css"
                            }
                            onClick={() => {
                              handleRecommendBtn(1);
                            }}
                          >
                            No
                          </p>
                        </div>
                        <div>
                          <p
                            className={
                              getValues("status") === 2
                                ? "recomment_css recommentActive"
                                : "recomment_css"
                            }
                            onClick={() => {
                              handleRecommendBtn(2);
                            }}
                          >
                            Maybe
                          </p>
                          <p
                            className={
                              getValues("status") === 4
                                ? "recomment_css1 recommentActive"
                                : "recomment_css1"
                            }
                            
                            onClick={() => {
                              handleRecommendBtn(4);
                            }}
                          >
                            Can't Review
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-none">
                      <div className="form-group mb-3">
                        <Controller
                          name={`status`}
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Status is required",
                            validate: {
                              positiveOrZero: (value) => {
                                const parsedValue = parseInt(value, 10);
                                return parsedValue > 0 || "Status is required";
                              },
                            },
                          }}
                          render={({ field }) => <TextField {...field} />}
                        />
                      </div>
                    </div>
                    <FormHelperText className="text-danger text-center">
                      {errors.status && errors.status.message}
                    </FormHelperText>
                    <div className="form-group text-center my-3">
                      <LoadingButton
                        type="submit"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        sx={{
                          padding: "10px 20px !important",
                          margin: "3px",
                          borderRadius: "25px",
                          "&:hover": {
                            backgroundColor: "green",
                          },
                        }}
                      >
                        <span>Submit</span>
                      </LoadingButton>
                      <Button
                        variant="contained"
                        onClick={toggleClose}
                        sx={{
                          padding: "10px 23px !important",
                          margin: "3px",
                          borderRadius: "25px",
                          "&:hover": {
                            backgroundColor: "#253b80 ",
                          },
                        }}
                      >
                        {questionsFields.length > 0 ? "Cancel" : "OK"}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="form-group text-center my-3">
                    <Button
                      variant="contained"
                      onClick={toggleClose}
                      sx={{
                        padding: "10px 23px !important",
                        margin: "3px",
                        borderRadius: "25px",
                        "&:hover": {
                          backgroundColor: "#253b80 ",
                        },
                      }}
                    >
                      {questionsFields.length > 0 ? "Cancel" : "OK"}
                    </Button>
                  </div>
                )}
              </>
            )}
          </form>
        </div>
      </div>
      <Modal
        open={openRemark}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag">
          <Remarksmodal
            index={remarkIndex}
            remarkValue={remarkValue}
            handleCloseRemark={handleCloseRemark}
            status={formData.status}
          />
        </Box>
      </Modal>
    </Box>
  );

  return (
    <>
      {/* onClick={() => getApi()} */}

      <div className="tab_side">
        <button onClick={() => getApi()} className="ani_btn">
          <img src="/images/sml_arrow.png" alt="" className="sml-right" /> Rate
          your Score
          <img src="/images/sml_arrow.png" alt="" className="sml-right" />
        </button>
      </div>

      <Drawer anchor="right" open={open} className="scroe_module">
        {DrawerList}
      </Drawer>
    </>
  );
};

export default Tanseedscoremodal;

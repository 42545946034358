import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, DialogContent, DialogTitle } from '@mui/material';
const Viewquestion = ({ viewQusData, handleQuesClose }) => {
    return (
        <>
            <DialogTitle id="responsive-dialog-title">
                Round Question Details
            </DialogTitle>
            <DialogContent>
                {
                    (viewQusData.length) ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Question</TableCell>
                                        <TableCell className='text-center'>Question Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {viewQusData.map((list, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{list.question}</TableCell>
                                            <TableCell className='text-center'>{list.questionType === 'yesno' ? ('Yes / No') : (`Scoring`)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                    ) : (
                        <div className='nodata_tag'>
                            <p>Question list is empty</p>
                        </div>
                    )
                }
                <div className='text-center p-3'>
                    <Button className='mr-3' onClick={handleQuesClose} variant="outlined">
                        OK
                    </Button>
                </div>
            </DialogContent>
        </>
    )
}

export default Viewquestion
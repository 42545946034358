import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import './scst-page/scst.css'
import { NavLink } from 'react-router-dom';

const ScstadminNavbar = ({ role }) => {
    const handleClick = (event) => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
        }
    };
    return (
        <nav>
            <div className='submenu_div'>
                <div className='container-fluid'>
                    <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/dashboard`} onClick={handleClick}>Dashboard</NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/startups`} onClick={handleClick}>Startups</NavLink>
                        </Typography>
                        {/* <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/report`} onClick={handleClick}>Report</NavLink>
                        </Typography> */}
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/import-page`} onClick={handleClick}>Import</NavLink>
                        </Typography>
                    </Box>
                </div>
            </div>
        </nav>
    )
}


export default ScstadminNavbar
import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { Container, Dialog, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import notifyService from '../../../api/notifySerivce';
import CloseIcon from '@mui/icons-material/Close';
const ViewInvestor = ({ id, investmentCharacterstics, listApi }) => {
    const { setPageLoading } = useContext(DataContext)
    const [openModal, setOpenModal] = useState(false);
    const [investorName, setinvestorName] = useState([])
    const assignStatusBtn = () => {
        setOpenModal(true);
        getApi()
    }
    const getApi = () => {
        setPageLoading(true)
        setinvestorName([])
        apiService(`admin/investor/list?startupid=${id}`, '', 'get').then((result) => {
            setPageLoading(false)
            if (result && result.data && result.data.startups) {
                setinvestorName(result.data.startups)
            }
        }).catch((err) => {

        });
    }
    const handleDeAssociate = (list) => {
        setPageLoading(true)
        let startupId = [];
        let partnerOrInvestorId = []
        startupId.push(list.startupId)
        partnerOrInvestorId.push(list.partnerOrInvestorId)
        var req = {
            associate: false,
            partnerOrInvestorIds: partnerOrInvestorId,
            startupIds: startupId
        };
        apiService('admin/ic/startups/associate', req, "post").then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    getApi()
                    notifyService("success", "Success", "");
                }
            }
        });
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        listApi()
    };
    return (
        <>
            {
                investmentCharacterstics ? (
                    <Button onClick={() => assignStatusBtn()}>View / Remove</Button>
                ) : (`-`)
            }
            <Dialog open={openModal} maxWidth="lg">
                <section className='p-3'>
                    <Container>                       
                        <div className='d-flex justify-content-between'>
                            <DialogTitle className='px-0'>Assigned Investors</DialogTitle>
                            <CloseIcon onClick={handleCloseModal} className='m-3 c-pointer' />
                        </div>
                        <div className=''>
                            {investorName.length ? (
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">No</TableCell>
                                                <TableCell>Investor Name</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {investorName.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">
                                                        {
                                                            index + 1
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {list.partnerName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip title="De-Assign">
                                                            <IconButton>
                                                                <DeleteIcon
                                                                    color="error"
                                                                    onClick={() => handleDeAssociate(list)}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            ) : (
                                <div className="nodata_tag">
                                    <img
                                        src="/images/Tanfundlogo-black-1.png"
                                        className="no_data_logo"
                                        alt=""
                                    />
                                    <h4>
                                        Investor Not Assigned
                                    </h4>
                                </div>
                            )}
                        </div>
                        <div className="form-group text-center my-3">
                            <Button variant="contained" onClick={handleCloseModal} className="btn btn-cancel submit px-3 ml-3">Ok</Button>
                        </div>
                    </Container>
                </section>

            </Dialog>
        </>
    )
}

export default ViewInvestor
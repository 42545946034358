import React, { useContext, useState } from 'react';
import { Autocomplete, Checkbox, Container, DialogContent, DialogTitle, FormHelperText, TextField } from '@mui/material'
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
// import SaveIcon from '@mui/icons-material/Save';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DataContext from '../../../../context/DataContext';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddJuryList = ({ panalRoundId, handleAddJuryClose }) => {
    const { setPageLoading } = useContext(DataContext)
    const { handleSubmit, control, setValue, getValues, clearErrors, setError, reset, formState: { errors } } = useForm({});
    const [juryList, setJuryList] = useState([])
    useDidMountEffect(() => {
        getJuryList()
    }, [])
    const getJuryList = () => {
        var req = {
            id: panalRoundId
        }
        apiService('partner/panelround/jury/add/list', req, 'post').then((result) => {
            if (result && result.data && result.data.panelRoundJury) {
                setJuryList(result.data.panelRoundJury)
            }
        }).catch((err) => {

        });
    }
    const [juryIdValue, setJuryIdValue] = useState([])
    const handleSelectJury = (value) => {
        setJuryIdValue(value)
        setValue('juryId', value)
        clearErrors('juryId');
    }
    const onSubmit = (data) => {
        setPageLoading(true)        
        const juryname = juryList.filter((item) => item.juryName === data.juryId)
        // debugger
        var req = {            
            panelRoundsId: panalRoundId,
            juryId: juryname[0].juryId,
            juryName: juryname[0].juryName
        }
        debugger
        apiService(`partner/panelround/jury/add`, req, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', '')
                    handleCancel()
                }
            }
        })
    }
    const handleCancel = () => {
        reset({})
        handleAddJuryClose()
    }
    return (
        <>           
            <DialogTitle id="responsive-dialog-title">
                Add Jury
            </DialogTitle>
            <DialogContent>
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-lg-12 col-12 mb-3'>
                                    <div className="form-group mb-3">
                                        <Controller name="juryId" control={control} defaultValue="" rules={{ required: 'Jury Name is required' }}
                                            render={({ field }) =>
                                                <Autocomplete
                                                    disableClearable
                                                    disablePortal
                                                    // multiple
                                                    {...field}
                                                    // limitTags={2}
                                                    value={juryIdValue}
                                                    // options={juryList}
                                                    options={juryList.map(
                                                        (list) => list.juryName
                                                    )}
                                                    onChange={(e, selectedOptions) => handleSelectJury(selectedOptions)}
                                                    // renderOption={(props, option, { selected }) => (
                                                    //     <li {...props}>
                                                    //         <Checkbox
                                                    //             icon={icon}
                                                    //             checkedIcon={checkedIcon}
                                                    //             style={{ marginRight: 8 }}
                                                    //             checked={selected}
                                                    //         />
                                                    //         {option}
                                                    //     </li>
                                                    // )}
                                                    renderInput={(params) => <TextField {...params} label="Jury *" />}
                                                />
                                            }
                                        />
                                        <FormHelperText className='text-danger'>{errors.juryId && errors.juryId.message}</FormHelperText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <Button
                            type="submit"
                            variant="contained"
                            className="btn btn-primary submit px-3"
                        >
                            <span>Save</span>
                        </Button>
                        <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>

                    </div>
                </form>
            </DialogContent>
        </>
    )
}

export default AddJuryList
import { Button, FormHelperText, TextField } from '@mui/material';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import notifyService from '../../../api/notifySerivce';

const SendEmail = ({ handleExportAllClose, exportExcelStartup }) => {
    const { handleSubmit, reset, control, clearErrors, setValue, formState: { errors } } = useForm();
    // emails
    const onSubmit = (data) => {
        let emails = data.email.split(',');
        // setEmailValues(emails)
        exportExcelStartup('All',emails)
    };
    const onError = (error) => {
        console.log(error);
        notifyService("danger", "Error", "Please check mandatory fields");
    };
    return (
        <div><div className='header_tag text-center'>
            <h5 className='c-black'>Send Email Ids</h5>
        </div>
            <form
                className="signin-form"
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="form-group mt-2">
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Email Id's is required" }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Email Id's *"
                                        placeholder="Enter Email Id's"
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                            <FormHelperText>
                                For multiple emails use , (comma) inbetween Ex. test@startuptn.in,support@startuptn.in
                            </FormHelperText>
                            <FormHelperText className="text-danger">
                                {errors.email && errors.email.message}
                            </FormHelperText>
                        </div>
                    </div>
                </div>
                <div className="form-group text-center mt-4 ">
                    <Button
                        variant="contained"
                        onClick={handleExportAllClose}
                        className="btn btn-cancel submit px-3"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        className="btn btn-primary submit px-3 ml-3"
                    >
                        <span>Send</span>
                    </Button>
                </div>
            </form></div>
    )
}

export default SendEmail
import React, { useContext, useState } from 'react'
import DataContext from '../../context/DataContext'
import apiService from '../../api/apiService'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import notifyService from '../../api/notifySerivce';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#253b80' : '#308fe8',
    },
}));
const Admindashboard = () => {
    const { navigator } = useContext(DataContext)
    useDidMountEffect(() => {
        (async () => await getApi())()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [formData, setFormData] = useState({});
    const getApi = () => {
        apiService('admin/dashboard', '', 'get').then((res) => {
            if (res) {
                setFormData(res.data)
            }

        })
    }
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleEmail = () => {
        setLoading(true)
        apiService('startup/progress/notification', '', 'get').then((res) => {
            setLoading(false)
            if (res) {
                if (res.data.responseStatus === 'Success') {
                    notifyService('success', 'Success', 'Startups Notified.');
                    handleClose()
                }
            }
        })
    }
    const handleStartup = () => {
        navigator('/admin/startup')
    }
    const handleInvestor = () => {
        navigator('/admin/investor')
    }
    const handleAssociated = () => {
        navigator('/admin/associated-startups')
    }
    return (
        <section>
            <div className='container-fluid'>
                <div className='form_div chart'>
                    <div className='chart-heads'>
                        <div className='chart-det'>
                            <div className=''>
                                <h5 className='font-weight-bold mb-3'>Admin Dashboard</h5>

                                {/* <p className='p-size'>Start with any section and return to complete others as you wish. Your progress is saved automatically</p> */}
                            </div>
                            <Button startIcon={<RefreshIcon />} variant="contained"
                                className="ml-auto btn btn-primary submit px-3" onClick={() => { getApi() }}>Reload</Button>
                        </div>
                    </div>
                    <hr />
                    <div className='main-rec-box'>
                        <div className='row'>
                            <div className='col-lg-4 col-12'>
                                <div className='total_star c-pointer' onClick={handleStartup}>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/star1.png' alt='' className='star_logo' />
                                        <h6 className='font-weight-bold ml-3'>Total Startups</h6>
                                        <div className='star_details ml-auto text-center'>
                                            <h5 className='font-weight-bold'>{formData.totalStartups}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12' >
                                <div className='total_star c-pointer' onClick={handleInvestor}>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/star2.png' alt='' className='star_logo' />
                                        <h6 className='font-weight-bold ml-3'>Total Investors</h6>
                                        <div className='star_details ml-auto text-center'>
                                            <h5 className='font-weight-bold'>{formData.totalInvestors}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12' >
                                <div className='total_star c-pointer' onClick={handleAssociated}>
                                    <div className='d-flex align-items-center'>
                                        <img src='/images/handshake.png' alt='' className='star_logo' />
                                        <h6 className='font-weight-bold ml-lg-3 ml-md-3'>Startup Associated with Investors</h6>
                                        <div className='star_details ml-auto text-center'>
                                            <h5 className='font-weight-bold'>{formData.associateStartups}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='dashboard_dev'>
                                    <div className='div_header'>
                                        <h5 className='font-weight-bold'>Startups</h5>
                                    </div>
                                    {/* <h6 className='font-weight-bold mb-3'>Startups</h6> */}
                                    <div className='d-lg-flex d-md-flex d-sm-block '>
                                        <div>
                                            <p>Application Submitted ({`${formData.profileCompletedStartups} / ${formData.totalStartups}`})</p>
                                        </div>
                                        <div className='ml-auto rec-box1'>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <BorderLinearProgress variant="determinate" value={Math.round((formData.profileCompletedStartups / formData.totalStartups) * 100)} />
                                            </Box>
                                            <p className='mt-3 right' >{Math.round((formData.profileCompletedStartups / formData.totalStartups) * 100)}%</p>
                                        </div>
                                    </div>
                                    <div className='d-lg-flex d-md-flex d-sm-block '>
                                        <div>
                                            <p>Application Partially Completed
                                                ({`${formData.profilePartiallyCompletedStartups} / ${formData.totalStartups}`})</p>
                                        </div>
                                        <div className='ml-auto rec-box1'>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <BorderLinearProgress variant="determinate" value={Math.round((formData.profilePartiallyCompletedStartups / formData.totalStartups) * 100)} />
                                            </Box>
                                            <p className='mt-3 right' >{Math.round((formData.profilePartiallyCompletedStartups / formData.totalStartups) * 100)}%</p>
                                        </div>
                                    </div>
                                    <div className='d-lg-flex d-md-flex d-sm-block '>
                                        <div>
                                            <p>Application Pending
                                                ({`${formData.pendingStartups} / ${formData.totalStartups}`})</p>
                                        </div>
                                        <div className='ml-auto rec-box1'>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <BorderLinearProgress variant="determinate" value={Math.round((formData.pendingStartups / formData.totalStartups) * 100)} />
                                            </Box>
                                            <p className='mt-3 right' >{Math.round((formData.pendingStartups / formData.totalStartups) * 100)}%</p>
                                        </div>
                                    </div>
                                    {/* <div className='d-lg-flex d-md-flex d-sm-block'>
                                        <p className='mr-4'>Notify Startups on Application Progress <Button variant="contained" className='btn btn-primary submit ml-5' onClick={handleOpen}>Send Email</Button></p>
                                    </div> */}
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='dashboard_dev'>
                                    <div className='div_header'>
                                        <h5 className='font-weight-bold'>Investors</h5>
                                    </div>
                                    {/* <h6 className='font-weight-bold mb-3'>Investors</h6> */}
                                    <div className='d-lg-flex d-md-flex d-sm-block '>
                                        <div>
                                            <p>Application Submitted ({`${formData.profileCompletedInvestors} / ${formData.totalInvestors}`})</p>
                                        </div>
                                        <div className='ml-auto rec-box1'>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <BorderLinearProgress variant="determinate" value={Math.round((formData.profileCompletedInvestors / formData.totalInvestors) * 100)} />
                                            </Box>
                                            <p className='mt-3 right' >{Math.round((formData.profileCompletedInvestors / formData.totalInvestors) * 100)}%</p>
                                        </div>
                                    </div>
                                    <div className='d-lg-flex d-md-flex d-sm-block '>
                                        <div>
                                            <p>Application Partially Completed
                                                ({`${formData.profilePartiallyCompletedInvestors} / ${formData.totalInvestors}`})</p>
                                        </div>
                                        <div className='ml-auto rec-box1'>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <BorderLinearProgress variant="determinate" value={Math.round((formData.profilePartiallyCompletedInvestors / formData.totalInvestors) * 100)} />
                                            </Box>
                                            <p className='mt-3 right' >{Math.round((formData.profilePartiallyCompletedInvestors / formData.totalInvestors) * 100)}%</p>
                                        </div>
                                    </div>
                                    {/* <div className='d-flex '>
                                        <div>
                                            <p>Application Pending
                                                ({`${formData.pendingInvestors} / ${formData.totalInvestors}`})</p>
                                        </div>
                                        <div className='ml-auto rec-box1'>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <BorderLinearProgress variant="determinate" value={Math.round((formData.pendingInvestors / formData.totalInvestors) * 100)} />
                                            </Box>
                                            <p className='mt-3 right' >{Math.round((formData.pendingInvestors / formData.totalInvestors) * 100)}%</p>
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="text-center">
                    <img src='/images/Tanfundlogo-black-1.png' alt='' className='img-fluid logo_img mb-3' />
                    <Typography id="modal-modal-title" variant="h5" component="h5">
                        Are you sure want to send email now ?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            onClick={() => handleEmail()}
                            startIcon={<CheckIcon />}
                            variant="contained"
                            className="btn btn-primary submit px-3"
                        >
                            <span>Confirm</span>
                        </LoadingButton>
                        <Button variant="contained" onClick={handleClose} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>
                    </Typography>
                </Box>
            </Modal>
        </section>
    )
}

export default Admindashboard
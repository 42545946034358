import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import DataContext from '../../context/DataContext';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import apiService from '../../api/apiService';
import { Autocomplete, InputBase } from '@mui/material'
import { TextField } from '@mui/material';
import CustomLink from '../common-page/CustomLink';
import OnBoradFilter from '../scsthubavp-module/onboraded-details/OnBoradFilter';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Scstjurylist from './Scstjurylist';
const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const Scstlist = ({ round, path }) => {

    const { setPageLoading, tableIndexValue, scstRemoveFiters } = useContext(DataContext)

    const [scstList, setScstList] = useState([])
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const hubval = localStorage.getItem('name')
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

// const hubval = localStorage.getItem('name')

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    let districtNameScst = localStorage.getItem('districtNameScst')
    const [districtName, setDistrictName] = useState(districtNameScst ? districtNameScst : '')

    let hubScst = localStorage.getItem('hubScst')
    const [scsthub, setScsthub] = useState(hubScst ? hubScst : "");
    const d = new Date();
    let year = d.getFullYear();
    let yearNameScst = Number(localStorage.getItem('yearNameScst'))
    const [yearName, setYearName] = useState(yearNameScst ? yearNameScst : '2022');

    let toYearNameScst = Number(localStorage.getItem('toYearNameScst'))
    const [toYearName, setToYearName] = useState(toYearNameScst ? toYearNameScst : '2022');

    // console.log(yearNameScst)
    let monthNameScst = Number(localStorage.getItem('monthNameScst'))
    const [monthName, setMonthName] = useState(monthNameScst ? monthNameScst : 0);

    let toMonthNameScst = Number(localStorage.getItem('toMonthNameScst'))
    const [toMonthName, setToMonthName] = useState(toMonthNameScst ? toMonthNameScst : '');

    let manualNameScst = Number(localStorage.getItem('manualNameScst'))
    const [manualName, setManualName] = useState(manualNameScst ? manualNameScst : 0)

    let searchInputValueScst = localStorage.getItem('searchInputValueScst')
    const [searchInputValue, setSearchInputValue] = useState(searchInputValueScst ? searchInputValueScst : '');
    const roles = localStorage.getItem('role')
    let phaseValueScst = localStorage.getItem('phaseValueScst')
    const [phaseValue, setPhaseValue] = useState(roles === 'JURY' ? 'Evaluation' : (phaseValueScst ? phaseValueScst : 'All'))

    let statusValueScst = Number(localStorage.getItem('statusValueScst'))
    const [statusValue, setStatusValue] = useState(statusValueScst ? statusValueScst : 100)
    console.log(statusValueScst);


    // useDidMountEffect(() => {
    //     if (roles === 'JURY') {
    //         setPhaseValue(phaseValueScst ? phaseValueScst : 'Evaluation')            
    //     } else {
    //         setPhaseValue(phaseValueScst ? phaseValueScst : 'All')            
    //     }
    // }, [])
    useDidMountEffect(() => {

        scstListItem()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, districtName, round, yearName, monthName, toMonthName, manualName, searchInputValue, phaseValue, statusValue])


    // useDidMountEffect(() => {
    //     if (path === 'evaluation') {
    //         let statusValueScst = Number(localStorage.getItem('statusValueScst'))
    //         setStatusValue(statusValueScst ? statusValueScst : 100);
    //     }
    // }, [path]);

    const handleSelectCity = (value) => {
        setDistrictName(value)
        // clearErrors('city')
    }
    const scstListItem = () => {
        setPageLoading(true)
        setScstList([])
        let req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            district: districtName,
            round: round,
            hub:hubval,
            fromYear: yearName === 'All' ? 0 : yearName,
            toYear: toYearName === 'All' ? 0 : toYearName,
            fromMonth: monthName,
            toMonthName: toMonthName,
            onboardType: manualName,
            searchString: searchInputValue,
            status: statusValue
        };
        apiService('partner/scst/admin/startups/list', req, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data) {
                    setCount(res.data.count)
                    if (res.data.startups) {
                        setScstList(res.data.startups)
                    } else {
                        setScstList([])
                    }
                }

            }
        })
    }
    // const handleClear = () => {
    //     setDistrictName('')
    // }

    // const handleSearch = (searchValue) => {
    //     setSearchInputValue(searchValue)
    // }
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useDidMountEffect(() =>{
        setSearchInputValue('')
    },[])
    return (
        <section>
            <div className='container-fluid'>
                <div className='form_div chart'>
                    {/* <div className='header_tag d-flex'>
                    <h5>Startup List</h5>
                </div> */}
                    <OnBoradFilter
                        setPage={setPage}
                        districtName={districtName} setDistrictName={setDistrictName}
                        scsthub={scsthub} setScsthub={setScsthub}
                        yearName={yearName} setYearName={setYearName}
                        toYearName={toYearName} setToYearName={setToYearName}
                        monthName={monthName} setMonthName={setMonthName}
                        toMonthName={toMonthName} setToMonthName={setToMonthName}
                        manualName={manualName} setManualName={setManualName} path={path}
                        searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue}
                        phaseValue={phaseValue} setPhaseValue={setPhaseValue}
                        statusValue={statusValue} setStatusValue={setStatusValue}
                        lblName='Startup List'
                    />
                    <div className='table_tag'>
                        {
                            (scstList.length) ? (
                                <TableContainer component={Paper}>
                                    <TablePagination
                                        component="div"
                                        count={count}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        style={{ float: 'left' }}
                                    />
                                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>No</TableCell>
                                                <TableCell>Startup Name</TableCell>
                                                <TableCell>District</TableCell>
                                                {/* <TableCell>Assigned to hub</TableCell> */}
                                                <TableCell>Entity</TableCell>
                                                <TableCell>Onboarded Type</TableCell>
                                                <TableCell>Date</TableCell>
                                                {/* <TableCell align='center'>Status</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {scstList.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {
                                                            tableIndexValue(rowsPerPage, page, index)
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <CustomLink to={`/scst/startups/${path}/details/${btoa(row.startupId)}/${btoa(row.partnerOrInvestorId)}`}>{row.startupName}</CustomLink>
                                                        {
                                                            row.additionalInfoRequested === true && (
                                                                <span className='ml-2'>
                                                                    <img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' />
                                                                </span>
                                                            )
                                                        }
                                                    </TableCell>
                                                    <TableCell>{row.district}</TableCell>
                                                    {/* <TableCell>{row.entityType}</TableCell> */}
                                                    <TableCell>{row.entityType}</TableCell>
                                                    <TableCell>
                                                        {
                                                            row.onboardType === 1 ? 'Manual' : 'Portal'
                                                        }
                                                    </TableCell>
                                                    <TableCell>{row.date}</TableCell>
                                                    {/* <TableCell align='center'>{row.status}</TableCell> */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <caption>
                                            <TablePagination
                                                component="div"
                                                count={count}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                style={{ float: 'left' }}
                                            /></caption>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <div className='nodata_tag'>
                                    <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                    <h4>Startup list is empty</h4>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Scstlist

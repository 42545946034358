import React, { useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DataContext from '../../../../context/DataContext';
import DeleteIcon from '@mui/icons-material/Delete';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const Tractionandachievements = () => {
    const [formData, setFormData] = useState({
        // customerTestimonials: [{}],
        tractions: [{}],
    });
    const { navigator,handleBack, Paper, MenuProps } = useContext(DataContext)
    const { handleSubmit, control, getValues, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    // const {
    //     fields: customerTestimonialsFields,
    //     append: customerTestimonialsAppend,
    //     remove: customerTestimonialsRemove
    // } = useFieldArray({
    //     control,
    //     name: "customerTestimonials"
    // })
    const {
        fields: tractionsFields,
        append: tractionsAppend,
        remove: tractionsRemove
    } = useFieldArray({
        control,
        name: "tractions"
    });
    useDidMountEffect(() => {
        // eslint-disable-next-line
        (async () => await getApi())()
        dropdownValueApi()
    }, [])
    const [startupTractionsList, setStartupTractionsList] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setStartupTractionsList(val.tractionsTypes)
        })
    }
    const getApi = async () => {
        await apiService(`startup/tractionandachievement/get`, '', 'get').then((res) => {
            if (res) {
                setFormData({})
                let response = res.data
                if (response.id !== 0) {
                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                } else {
                    setFormData(response)
                }
            }
        })
    }
    // const addTestimonialsDetailsBtn = () => {
    //     customerTestimonialsAppend({
    //         customerName: "",
    //         testimonial: ""
    //     })
    // }
    const addTractionsDetailsBtn = () => {
        tractionsAppend({
            type: "",
            name: ""
        })
    }

    const onSubmit = (data) => {
        if (readyOnlyValue === false) {
            setLoading(true);
            var getform = data
            apiService('startup/tractionandachievement/save', getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        navigator('/startup/funding-utilisation')
                    }
                }
            })
        }
    };
    // delete customer Testimonials
    // const deleteCustomerTestimonials = (index) => {
    //     const id = getValues(`customerTestimonials.${index}.id`);
    //     if (id !== undefined) {
    //         apiService(`startup/customertestimonial/remove?id=${id}`, '', 'get').then((res) => {
    //             if (res) {
    //                 if (res.data.responseStatus === 'Success') {
    //                     // eslint-disable-next-line
    //                     const del = customerTestimonialsFields.filter((index) => index !== index)
    //                     customerTestimonialsRemove(del)
    //                     getApi()
    //                 }
    //             }
    //         })
    //     } else {
    //         // eslint-disable-next-line
    //         const del = customerTestimonialsFields.filter((index) => index !== index)
    //         customerTestimonialsRemove(del)
    //     }
    // }
    // delete Tractions
    const deleteTractions = async (index) => {
        const id = getValues(`tractions.${index}.id`);
        if (id !== undefined) {
            apiService(`startup/tractions/remove?id=${id}`, '', 'get').then((res) => {
                if (res) {
                    if (res.data.responseStatus === 'Success') {
                        tractionsRemove(index)
                        getApi()
                    }
                }
            })
        } else {
            tractionsRemove(index)
        }
    }
    return (
        <><section className='container-fluid'>
            <div className='form_div'>
                <div className='d-flex align-items-center mb-3'>
                    <span className='back_span' onClick={handleBack}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Traction / Achievements</h6>
                </div>
                <hr />
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="id" control={control} defaultValue="0"
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='col-lg-3 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="noOfCustomers" control={control} defaultValue="" rules={{ required: 'No. of customers is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="No. of customers *"
                                            placeholder='Enter No. of customers'
                                            fullWidth
                                            type='text'
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.noOfCustomers && errors.noOfCustomers.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-3 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="growthRate" control={control} defaultValue="" rules={{ required: 'Growth Rate is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Growth Rate *"
                                            placeholder='Enter Growth Rate'
                                            fullWidth
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.growthRate && errors.growthRate.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-3 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="majorAchievements" control={control} defaultValue="" rules={{ required: 'Major Achievements is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Major Achievements *"
                                            placeholder='Enter Major Achievements'
                                            fullWidth
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.majorAchievements && errors.majorAchievements.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-3 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }} >
                                    <Controller name='testimonial' control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Testimonial"
                                                placeholder='Enter Testimonial'
                                                fullwidth
                                                {...field}
                                                disabled={readyOnlyValue}
                                            />} />
                                    <FormHelperText className='text-danger'>
                                        {errors.testimonial && errors.testimonial.message}
                                    </FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        {/* <div className='col-12 mb-3'>
                        <div className='div_header'>
                            <div className='d-flex'>
                                <h6>Customer Testimonials</h6>
                                <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addTestimonialsDetailsBtn('')}>Add Testimonials Details</Button>
                            </div>
                        </div>
                        <div>
                            {
                                (customerTestimonialsFields.length) ? (
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Customer Name  </TableCell>
                                                    <TableCell>Testimonial </TableCell>
                                                    <TableCell align='center'>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {customerTestimonialsFields.map((field, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>
                                                            <FormControl sx={{ width: '100%' }} >
                                                                <Controller name={`customerTestimonials.${index}.customerName`} control={control} defaultValue="" rules={{ required: false }}
                                                                    render={({ field }) =>
                                                                        <TextField
                                                                            id="outlined-multiline-static"
                                                                            label="Customer Name"
                                                                            placeholder='Enter Customer Name'
                                                                            fullwidth
                                                                            {...field}
                                                                            inputProps={
                                                                                { readOnly: readyOnlyValue, }
                                                                            }
                                                                        />} />
                                                                <FormHelperText className='text-danger'>
                                                                    {errors.customerTestimonials?.[index]?.customerName && errors.customerTestimonials?.[index]?.customerName.message}
                                                                </FormHelperText>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl sx={{ width: '100%' }} >
                                                                <Controller name={`customerTestimonials.${index}.testimonial`} control={control} defaultValue="" rules={{ required: false }}
                                                                    render={({ field }) =>
                                                                        <TextField
                                                                            id="outlined-multiline-static"
                                                                            label="Testimonial"
                                                                            placeholder='Enter Testimonial'
                                                                            fullwidth
                                                                            {...field}
                                                                            disabled={readyOnlyValue}
                                                                        />} />
                                                                <FormHelperText className='text-danger'>
                                                                    {errors.customerTestimonials?.[index]?.testimonial && errors.customerTestimonials?.[index]?.testimonial.message}
                                                                </FormHelperText>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteCustomerTestimonials(index) : <></> }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                    </TableContainer>
                                ) : (
                                    <div className='nodata_tag'>
                                        <p>Testimonials details is empty</p>
                                    </div>
                                )
                            }

                        </div>
                    </div> */}
                        <div className='col-12 mb-3'>
                            <div className='div_header'>
                                <div className='d-flex'>
                                    <h6>Tractions Details</h6>
                                    <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addTractionsDetailsBtn('')}>Add Tractions Details</Button>
                                </div>
                            </div>
                            <div>
                                {
                                    (tractionsFields.length) ? (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Type *</TableCell>
                                                        <TableCell>Value *</TableCell>
                                                        <TableCell align='center'>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {tractionsFields.map((field, index, type) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>
                                                                <FormControl sx={{ width: '100%' }}>
                                                                    <InputLabel htmlFor="grouped-native-select">Type *</InputLabel>
                                                                    <Controller name={`tractions.${index}.type`} control={control} defaultValue="" rules={{ required: 'Type is required' }}
                                                                        render={({ field }) =>
                                                                            <Select
                                                                                {...field}
                                                                                readOnly={readyOnlyValue}
                                                                                defaultValue={type}
                                                                                input={<OutlinedInput label="Name" />}
                                                                                MenuProps={MenuProps}
                                                                            >
                                                                                {startupTractionsList.map((name) => (
                                                                                    <MenuItem
                                                                                        key={name}
                                                                                        value={name}
                                                                                    >
                                                                                        {name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>} />
                                                                    <FormHelperText className='text-danger'>{errors.tractions?.[index]?.type && errors.tractions?.[index]?.type.message}</FormHelperText>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <Controller name={`tractions.${index}.name`} control={control} defaultValue="" rules={{ required: 'Value is required' }}
                                                                        render={({ field }) =>
                                                                            <TextField
                                                                                id="outlined-multiline-static"
                                                                                label="Value"
                                                                                placeholder='Enter Value'
                                                                                fullwidth                                                                                
                                                                                {...field}
                                                                                inputProps={
                                                                                    { readOnly: readyOnlyValue, }
                                                                                }
                                                                            />} />
                                                                    <FormHelperText className='text-danger'>
                                                                        {errors.tractions?.[index]?.name && errors.tractions?.[index]?.name.message}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteTractions(index) : <></> }} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>

                                        </TableContainer>
                                    ) : (
                                        <div className='nodata_tag'>
                                            <p>Tractions details is empty</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <Button variant="contained" onClick={handleBack} className="btn btn-cancel submit px-3">Cancel</Button>
                        {
                            (readyOnlyValue === false) ? (
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    className="btn btn-primary submit px-3 ml-3"
                                >
                                    <span>Save & Proceed Next</span>
                                </LoadingButton>
                            ) : (<></>)
                        }

                    </div>
                </form>
            </div>
        </section>
        </>
    )
}

export default Tractionandachievements
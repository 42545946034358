import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import Viewquestion from '../modal-form/Viewquestion';
import DataContext from '../../../context/DataContext';
// import Viewjury from '../modal-form/Viewjury';
import DeleteIcon from '@mui/icons-material/Delete';
import apiService from '../../../api/apiService';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Zoomscheduler from './Zoomscheduler';
import cryptoJS from '../../../hooks/cryptoJS';
import axios from 'axios';
import { Modal } from '@mui/material';
import notifyService from '../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const Zoomlist = ({ startupId }) => {
    const { setPageLoading, zoomToken,fullScreen } = useContext(DataContext)
    let zoomTokenlet = localStorage.getItem('zoomToken')
    let encryToken
    if (zoomTokenlet != null) {
        encryToken = cryptoJS(zoomTokenlet)
    }
    useDidMountEffect(() => {
        // eslint-disable-next-line
        if (zoomTokenlet !== null) {
            listApi()
        } else {
            zoomToken()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // const addRound = () => {
    //     navigator(`/jury/zoom/scheduler`)
    // }
    const [zoomListData, setZoomListData] = useState([])
    const tenant = localStorage.getItem("jwttoken")
    const listApi = () => {
        setPageLoading(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'token': tenant,
                'key': encryToken,
                "startupid": Number(startupId)
            },
        };
        const URL = `${process.env.REACT_APP_API_URL}${'list/meeting'}`;
        debugger
        axios.post(URL, {}, config)
            // apiService(`list/meeting`, '', 'zoom_get')
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    setZoomListData(result.data.meetings)
                }
            }).catch((err) => {
                setPageLoading(false)
                setZoomListData([])
            });
    }
    const [zoomId, setZoomId] = useState(0)
    const editZoomBtn = (id) => {
        setOpenModal(true);
        setZoomId(id)
    }
    const removeZoom = (id) => {
        setPageLoading(true)
        apiService(`delete/meeting?meetingId=${id}`, '', 'zoom_delete').then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    notifyService('success', 'Success', res.data)
                    listApi()
                }
            }
        })
    }

    const [openModal, setOpenModal] = useState(false);
    // const handleClickOpen = () => {
    //     setOpenModal(true);
    // }
    // const handleCloseModal = () => {
    //     setOpenModal(false);
    // };

    const [token, setToken] = useState('')

    const handleOpenModal = () => {
        setPageLoading(true)
        apiService('create/token', '', 'post').then((result) => {
            setPageLoading(false)
            if (result && result.data && result.data.access_token) {
                result.data.access_token = cryptoJS(result.data.access_token)
                setZoomId(0)
                setToken(result.data.access_token)
                setOpenModal(true);
            }
        }).catch((err) => {

        });
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        listApi()
    };
    const [tooltipOpen, setTooltipOpen] = useState();

    const handleCopyClick = async (url, index) => {
        try {
            await navigator.clipboard.writeText(url);
            setTooltipOpen(index);
            setTimeout(() => setTooltipOpen(), 2000); // Hide tooltip after 2 seconds
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
    return (
        <section>
            <div className='d-flex'>
                <div className='div_header'>
                    <h6>Meeting Schedule List</h6>
                </div>
                <div className='ml-auto mr-4'>
                    <Button onClick={() => handleOpenModal()} variant="outlined">+ Schedule Meeting</Button>
                </div>
            </div>
            <div className='table_tag'>
                {
                    (zoomListData && zoomListData.length) ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Agenda</TableCell>
                                        <TableCell>Topic</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Duration</TableCell>
                                        <TableCell>Link</TableCell>
                                        <TableCell align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {zoomListData.map((list, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{list.agenda}</TableCell>
                                            <TableCell>{list.topic}</TableCell>
                                            <TableCell>{list.start_time}</TableCell>
                                            <TableCell>{list.duration}</TableCell>
                                            <TableCell>
                                                <Link to={list.join_url} target='_blank'>
                                                    <img src='/images/zoom-icon.png' className='zoom-img' /></Link>
                                                <Tooltip
                                                    title="Copied!"
                                                    open={tooltipOpen === index ? true : false}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: '13px', cursor: 'pointer' }} onClick={() => handleCopyClick(list.join_url, index)} />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <EditIcon style={{ fill: '#66bb6a', cursor: 'pointer' }} onClick={() => { editZoomBtn(list.id) }} />
                                                <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => removeZoom(list.id)} />

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                    ) : (
                        <div className='nodata_tag'>
                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                            <p>Schedule list is empty</p>
                        </div>
                    )
                }

            </div>
            <Dialog
                open={openModal}
                fullScreen={fullScreen}
                maxWidth={'md'}
            >
                <Zoomscheduler zoomId={zoomId} token={token} handleCloseModal={handleCloseModal} startupId={startupId} />
            </Dialog>
        </section>
    )
}

export default Zoomlist
import React from 'react'
import { Outlet } from 'react-router-dom';
import useDidMountEffect from '../../hooks/useDidMountEffect';

const ScstMentorshipLayout = () => {

    localStorage.setItem('regRoleId', 11)
    useDidMountEffect(() => {
        document.getElementById('theme').setAttribute('href', '/css/admin.css');
    }, [])

    return (
        <div className='admin_main_div'>
            <Outlet></Outlet>
        </div>
    )
}

export default ScstMentorshipLayout
import React from "react";
import { Outlet } from "react-router-dom";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import ScstBddNavbar from "./ScstBddNavbar";

const ScstBddLayout = ({ role }) => {
  debugger;
  localStorage.setItem("regRoleId", 10);
  useDidMountEffect(() => {
    document.getElementById("theme").setAttribute("href", "/css/admin.css");
  }, []);
  let roles = localStorage.getItem("role");
  return (
    <div className="admin_main_div">
      {/* {(roles === 'BDD' || roles === 'LDD' || roles === 'FDD') && (<ScstBddNavbar role={role} />)} */}
      <Outlet></Outlet>
    </div>
  );
};

export default ScstBddLayout;

import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import Fundingprograms from './modal-form/Fundingprograms';
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
import DataContext from "../../../context/DataContext";

const Fundraisedhistory = () => {
  const [formData, setFormData] = useState({
    // fundHistory: [{}]
  });
  const {
    handleBacktanseed,
    navigator,
    MenuProps,
    Paper,
    // modalstyle,
    // icon,
    // checkedIcon,
  } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    setValue,
    // clearErrors,
    getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  useDidMountEffect(() => {
    dropdownValueApi();
    (async () => await getApi())();
    // eslint-disable-next-line
  }, []);
  const {
    fields: fundFields,
    append: fundAppend,
    remove: fundRemove,
  } = useFieldArray({
    control,
    name: "fundHistory",
  });
  const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState(
    []
  );
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        if (val && val.prefFundingInstruments) {
          setStartupinstrumentTypeList(val.prefFundingInstruments);
        }
      }
    );
  };
  const getApi = async () => {
    await apiService(`startup/fundingandfinancials/get`, "", "get").then(
      (res) => {
        if (res) {
          setFormData({});
          let response = res.data;
          if (response.id !== 0) {
            setFormData(response);
            if (response.editForm === false) {
              setReadyOnlyValue(true);
            }

            if (response.partofAccProg) {
              setPartofAccProg(response.partofAccProg);
            }
          } else {
            setFormData(response);
          }
        }
      }
    );
  };
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      apiService("startup/fundingandfinancials/save", getform, "post").then(
        (res) => {
          setLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              notifyService("success", "Success", "Saved successfully !!");
              navigator("/startup/Teaminfo/tanseed");
            }
          }
        }
      );
    }
  };
  const addFundDetailsBtn = () => {
    fundAppend({
      fundRaised: "",
      organisationName: "",
      instrumentType: "",
    });
  };
  const deleteFundingHistory = async (index) => {
    const id = getValues(`fundHistory.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/fundhistory/remove?id=${id}`, "", "get").then(
        (res) => {
          if (res) {
            if (res.data.responseStatus === "Success") {
              fundRemove(index);
              getApi();
            }
          }
        }
      );
    } else {
      fundRemove(index);
    }
  };
  const [partofAccProg, setPartofAccProg] = useState("");
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBacktanseed}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Previous Fundraising History</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6 col-12 mb-3">
                <div className="form-group">
                  <FormLabel>
                    Are you part of any accelerator program or incubation
                    program?
                  </FormLabel>
                  <Controller
                    name="partofAccProg"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            setPartofAccProg(e.target.value);
                          }}
                          defaultValue="null"
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            setPartofAccProg(e.target.value);
                            setValue("accProgName", "");
                          }}
                          defaultValue="null"
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.partofAccProg && errors.partofAccProg.message}
                  </FormHelperText>
                </div>
              </div>
              {(partofAccProg === "true" || partofAccProg === true) && (
                <div className="col-lg-6 col-12 mb-3">
                  {/* <FormLabel>Incubation Program Name</FormLabel> */}
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="accProgName"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          id="outlined-multiline-static"
                          label="Incubation Program Name"
                          placeholder="Enter Incubation Program Name"
                          // sx={{ width: 400, marginTop: "10px" }}
                          {...field}
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.accProgName && errors.accProgName.message}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
              <div className="col-12 mb-3">
                {/* <div className="div_header">
                  <div className="d-flex">
                    <h6>Previous Fundraising History</h6>
                   
                  </div>
                </div> */}
                <div>
                  <div>
                    <div className="div_header">
                      <div className="d-flex">
                        <h6>Previous Fundraising History</h6>
                        <Button
                          variant="outlined"
                          disabled={readyOnlyValue}
                          className="ml-auto"
                          onClick={() => addFundDetailsBtn("")}
                        >
                          Add Fundraising History
                        </Button>
                      </div>
                    </div>
                    <div>
                      {fundFields.length ? (
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="caption table"
                          >
                            <TableBody>
                              {fundFields.map((field, index, type) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`fundHistory.${index}.fundRaised`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Fund Raised is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Fund Raised (₹)*"
                                            placeholder="Enter Fund Raised (₹)"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.fundHistory?.[index]
                                          ?.fundRaised &&
                                          errors.fundHistory?.[index]
                                            ?.fundRaised.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell>
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`fundHistory.${index}.organisationName`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Organization Name is required",
                                        }}
                                        render={({ field }) => (
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Organization Name *"
                                            placeholder="Enter Organization Name"
                                            fullwidth
                                            {...field}
                                            inputProps={{
                                              readOnly: readyOnlyValue,
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.fundHistory?.[index]
                                          ?.organisationName &&
                                          errors.fundHistory?.[index]
                                            ?.organisationName.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell sx={{ width: "25%" }}>
                                    <FormControl sx={{ width: "100%" }}>
                                      <InputLabel htmlFor="grouped-native-select">
                                        Instrument Type *
                                      </InputLabel>
                                      <Controller
                                        name={`fundHistory.${index}.instrumentType`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required:
                                            "Instrument Type is required",
                                        }}
                                        render={({ field }) => (
                                          <Select
                                            {...field}
                                            readOnly={readyOnlyValue}
                                            defaultValue={type}
                                            input={
                                              <OutlinedInput label="Name" />
                                            }
                                            MenuProps={MenuProps}
                                          >
                                            {startupinstrumentTypeList.map(
                                              (name) => (
                                                <MenuItem
                                                  key={name}
                                                  value={name}
                                                >
                                                  {name}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.fundHistory?.[index]
                                          ?.instrumentType &&
                                          errors.fundHistory?.[index]
                                            ?.instrumentType.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <DeleteIcon
                                      style={{
                                        fill: "#d32f2f",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        readyOnlyValue === false ? (
                                          deleteFundingHistory(index)
                                        ) : (
                                          <></>
                                        );
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <div className="nodata_tag">
                          <p>Fundraising history details is empty</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Fundraisedhistory;

import React from 'react'
import ScsthubavpEvaNavbar from './ScsthubavpEvaNavbar'
import StartupOnboraded from './onboraded-details/StartupOnboraded'
import Jurylist from '../partner-module/jury-details/Jurylist'

const ScsthubavpEvalList = ({ role }) => {
    return (
        <div>
            <Jurylist />
        </div>
    )
}

export default ScsthubavpEvalList
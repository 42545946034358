import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import { useTheme } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../api/apiService';
import { MuiFileInput } from 'mui-file-input';
import { FormHelperText, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DataContext from '../../../context/DataContext';
import { Link } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import notifyService from '../../../api/notifySerivce';
const Additionalinfo = ({ status, id ,listApi}) => {
    const { VisuallyHiddenInput } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, getValues, control, clearErrors, setValue, formState: { errors } } = useForm(
        { values: formData }
    );
    // const [editAdditionalDocNameStatus, setAditAdditionalDocNameStatus] = useState(true)
    const [additionalDocURL, setAdditionalDocURL] = useState(null)
    const [additionalDocValue, setAdditionalDocValue] = useState(null)
    // const [additionalDocName, setAdditionalDocName] = useState(null)
    const [open, setOpen] = useState(false);
    // const theme = useTheme();
    const [checkMethod, setCheckMethod] = useState(false)
    const handleClickOpen = () => {
        setOpen(true);
        (async () => await getApi())()
    };
    const getApi = async () => {
        await apiService(`startup/additionalinfo/get?id=${id}`, '', 'get').then((res) => {
            if (res) {
                let response = res.data
                if (response.id !== 0) {
                    setFormData({})
                    if (response.additionalDoc) {
                        setCheckMethod(response.additionalDoc)
                        setCheckMethod(true)
                    }
                    setAdditionalDocURL(response.additionalDocName)
                    setFormData(response)
                } else {
                    setFormData(response)
                }
                // setAditAdditionalDocNameStatus(true)
            }
        })
    }
    let logoselecetdFile
    const handleFileUpload = (event, filekey, fileName, setdata, setUrl) => {
        setValue(filekey, "")
        setValue(fileName, "")
        setdata('')
        setUrl('')
        if (event.target === undefined) {
            logoselecetdFile = event
        } else {
            logoselecetdFile = event.target.files[0]
        }
        if (logoselecetdFile) {
            var reader = new FileReader();
            var imagetype = logoselecetdFile.type
            var imagedatatype = imagetype.split("/")
            var img_crt_type = imagedatatype[1]
            if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf") {
                if (event.target === undefined) {
                    setdata(event)
                } else {
                    setdata(event.target.files[0])
                }
                var fileValue = logoselecetdFile
                reader.readAsDataURL(logoselecetdFile);
                reader.onload = () => {
                    var logourl1 = reader.result;
                    var spl = logourl1.split(',')
                    var ImageValue = spl[1]
                    var img_name = fileValue.name
                    setUrl(logourl1)
                    setValue(filekey, ImageValue)
                    setValue(fileName, img_name)
                    clearErrors(filekey);
                    clearErrors(fileName);
                    debugger
                }
            }
        }
    }
    const onSubmit = (data) => {
        apiService('startup/additionalinfo/save', data, 'post').then((res) => {
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Saved successfully !!')
                    listApi()
                    handleClose()
                }
            }
        })
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {
                status === true ? (
                    <>
                    <img src='/images/giphy.gif' className='click-css' />
                        <span onClick={handleClickOpen} className='yes_span1'>Click to Attach / Add Additional Info</span>
                        
                    </>
                ) : (<span className='notApp'>NA</span>)
            }
            {/* <span onClick={handleClickOpen}>Additional Info</span>            */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Additional Info
                </DialogTitle>
                <DialogContent>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-12 col-12 mb-3'>
                                Comment : <b>{getValues(`comments`)}</b>
                            </div>
                            <div className='col-lg-12 col-12'>
                                {
                                    (checkMethod === false) ? (
                                        <div className="form-group mt-2">
                                            <Controller name="additionalDoc" control={control} defaultValue="" rules={{ required: 'Additional Documents File is required' }}
                                                render={({ field }) =>
                                                    <MuiFileInput
                                                        {...field}
                                                        onChange={(e) => handleFileUpload(e, "additionalDoc", "additionalDocName", setAdditionalDocValue, setAdditionalDocURL)}
                                                        value={additionalDocValue}
                                                        variant='outlined'
                                                        fullWidth={true}
                                                        label="Upload Additional Documents"
                                                        placeholder='Choose File' />
                                                } />
                                            <FormHelperText className='text-danger'>{errors.additionalDoc && errors.additionalDoc.message}</FormHelperText>
                                            <span className='font-sm'>Maximum 10 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                        </div>
                                    ) : (
                                        <div>
                                            <p className='mb-0'>Upload Additional Documents *</p>
                                            <div className='row'>
                                                <div className='col-lg-8 col-12 mb-3'>
                                                    <Controller name="additionalDoc" control={control} defaultValue="" rules={{ required: 'Additional Documents File is required' }}
                                                        render={({ field }) =>
                                                            <Button
                                                                component="label"
                                                                variant="contained"
                                                                {...field}
                                                                fullWidth={true}
                                                                onChange={(e) => handleFileUpload(e, "additionalDoc", "additionalDocName", setAdditionalDocValue, setAdditionalDocURL)}
                                                                startIcon={<CloudUploadIcon />}
                                                                href="#file-upload"
                                                            >
                                                                Uploaded
                                                                <VisuallyHiddenInput type="file" />
                                                            </Button>

                                                        } />
                                                    <span className='font-sm'>Maximum 10 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                                </div>
                                                <div className='col-lg-4 col-12'>
                                                    <Button variant="contained" className='viewbtn-div'>
                                                        <Link to={`${additionalDocURL}`} target="_blank" color='white'>
                                                            <PreviewIcon sx={{ mr: 1 }} />
                                                            View
                                                        </Link>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='col-lg-12 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="startupComments" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                                variant='outlined'
                                                label="Your Comment"
                                                placeholder='Enter Your Comment'
                                                fullWidth
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.startupComments && errors.startupComments.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className='text-center p-3'>
                            <Button className='mr-3' onClick={handleClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Save
                            </Button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>
        </>
    )
}

export default Additionalinfo
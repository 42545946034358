import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormHelperText, FormLabel, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
// import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
// import PreliminaryTextPart1 from './PreliminaryTextPart1';
const fileTypes = ["pdf"];
// const fileTypesExcel = ["pdf", "XLSX"];

const FddSectionG = () => {
  const [formData, setFormData] = useState({});
  const { handleBackapp, setPageLoading, AntSwitch, validateNumberonly,navigator } = useContext(DataContext)
  const { handleSubmit, setValue, watch, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [fileUploadId, setFileUploadId] = useState(0)
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/secg/get`, '', 'get').then((res) => {
      if (res && res.data) {
        var data = res.data
        if (data.editForm === false) {
          setReadyOnlyValue(true)
        }
        if (res.data.id) {
          setFileUploadId(res.data.id)
        }

        setFormData(res.data)
      }
      setPageLoading(false)
    })
  }
  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type
        var imagedatatype = imagetype.split("/")
        var img_crt_type = imagedatatype[1]
        if (
          ((format === 'file') && (img_crt_type === "pdf")) ||
          ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
        ) {
          setValue(filekey, "")
          setValue(fileName, "")

          var fileValue = logoselecetdFile
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(',')
            var ImageValue = spl[1]
            var img_name = fileValue.name
            setValue(filekey, ImageValue)
            setValue(fileName, img_name)
            setValue(fileURL, logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId
            }
            console.log(req);
            // saveData(req)
          }
        } else {
          notifyService('danger', 'File Format Invalided', 'Please check your file format')
        }
      }
    }
  }
  const inventoryVerification = watch("inventoryVerification", false);
  const importPurchase = watch("importPurchase", false)
  const handleFilterCheck = (value, key, file, name, url) => {
    setValue(key, value);
    if (!value) {
      setValue(key, "");
      setValue(file, "");
      setValue(name, "");
      setValue(url, "");
    }
  };
  const valuationMethod = watch("valuationMethod", false)
  const handleFilter = (value, key) => {
    setValue(key, value);
    if (!value) {
      setValue(key, "");
    }
  };
  const saveData = (req) => {
    console.log(req);
    apiService('startup/ldd/sectionone/save', req, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          getApi()
        }
      }
    })
  }

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    // data.id = fileUploadId
    // setPageLoading(true);
    // if (data.shareHoldingPattern) {
    //   data.shareHoldingPattern.forEach(element => {
    //     if (element.date) {
    //       element.date = element.date.format('YYYY-MM-DD');
    //     }
    //   });
    // }
    // var req = {
    //   id: fileUploadId,
    //   directorsInterestedCompanies: data.directorsInterestedCompanies,
    //   shareHoldingPattern: data.shareHoldingPattern,
    //   sharesSubjectedToPledge: data.sharesSubjectedToPledge
    // }
    console.log(data);
    setPageLoading(true);
    apiService('startup/fdd/secg/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-h");
        }
      }
    })
  }
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Inventory</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className=''>
              <div className="row doc-row">
                <div className='col-12'>
                  <div className='doc-title mt-3'>
                    <h6>Inventory :</h6>
                  </div>
                </div>
                <div className='col-lg-4 col-12 mt-2'>
                  <FormLabel>Physical Verification list and Quantity &Value of Inventory as on any latest date for the current year</FormLabel>
                  <div className="d-flex align-items-center">
                    <div className='form-group my-1'>
                      <Controller name="verificationCurrentYear" control={control} defaultValue="" rules={{ required: false }}
                        render={({ field }) =>

                          <FileUploader
                            {...field}
                            handleChange={(e) => handleFileUpload(e,
                              "verificationCurrentYear",
                              "verificationCurrentYearName",
                              "verificationCurrentYearURL",
                              'file')}
                            value={getValues('verificationCurrentYear')}
                            name="file"
                            types={fileTypes}
                            children={
                              <div className={getValues('verificationCurrentYear') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                <center>
                                  <p className={getValues('verificationCurrentYear') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                    <span>
                                      <AttachFileIcon className="file-icon mr-2" />
                                      {getValues('verificationCurrentYear') !== '' ? 'Uploaded (Click to Change)' : 'Verification Current Year Document'}
                                    </span>
                                  </p>
                                </center>
                              </div>
                            }
                            disabled={readyOnlyValue}
                          />
                        } />
                      <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                      <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.verificationCurrentYear && errors.verificationCurrentYear.message}</FormHelperText>
                    </div>
                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                      onClick={() => handleClickOpen(getValues('verificationCurrentYearURL'))}
                      style={{ color: "#80809e" }}
                    > Preview
                    </Link>
                    </span>
                  </div>
                </div>
                <div className='col-lg-4 col-12 mt-2'>
                  <FormLabel>Physical Verification list and Quantity &Value of Inventory as on closing date of the previous year</FormLabel>
                  <div className="d-flex align-items-center">
                    <div className='form-group my-1'>
                      <Controller name="verificationPreviousYear" control={control} defaultValue="" rules={{ required: false }}
                        render={({ field }) =>

                          <FileUploader
                            {...field}
                            handleChange={(e) => handleFileUpload(e,
                              "verificationPreviousYear",
                              "verificationPreviousYearName",
                              "verificationPreviousYearURL",
                              'file')}
                            value={getValues('verificationPreviousYear')}
                            name="file"
                            types={fileTypes}
                            children={
                              <div className={getValues('verificationPreviousYear') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                <center>
                                  <p className={getValues('verificationPreviousYear') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                    <span>
                                      <AttachFileIcon className="file-icon mr-2" />
                                      {getValues('verificationPreviousYear') !== '' ? 'Uploaded (Click to Change)' : 'Verification Current Year Document'}
                                    </span>
                                  </p>
                                </center>
                              </div>
                            }
                            disabled={readyOnlyValue}
                          />
                        } />
                      <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                      <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.verificationPreviousYear && errors.verificationPreviousYear.message}</FormHelperText>
                    </div>
                    <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                      onClick={() => handleClickOpen(getValues('verificationPreviousYearURL'))}
                      style={{ color: "#80809e" }}
                    > Preview
                    </Link>
                    </span>
                  </div>
                </div>
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Do you have system of Physical Verification of Inventory Periodically</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={inventoryVerification}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilterCheck(!inventoryVerification, `inventoryVerification`, 'pvReport', 'pvReportName', 'pvReportURL')}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {inventoryVerification === true && (
                  <>
                    <div className='col-lg-4 col-12 mt-2'>
                      <FormLabel>Share the PV reports if any available</FormLabel>
                      <div className="d-flex align-items-center">
                        <div className='form-group my-1'>
                          <Controller name="pvReport" control={control} defaultValue="" rules={{ required: false }}
                            render={({ field }) =>

                              <FileUploader
                                {...field}
                                handleChange={(e) => handleFileUpload(e,
                                  "pvReport",
                                  "pvReportName",
                                  "pvReportURL",
                                  'file')}
                                value={getValues('pvReport')}
                                name="file"
                                types={fileTypes}
                                children={
                                  <div className={getValues('pvReport') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                    <center>
                                      <p className={getValues('pvReport') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues('pvReport') !== '' ? 'Uploaded (Click to Change)' : 'PV Report Document'}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            } />
                          <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                          <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.pvReport && errors.pvReport.message}</FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                          onClick={() => handleClickOpen(getValues('pvReportURL'))}
                          style={{ color: "#80809e" }}
                        > Preview
                        </Link>
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-lg-4 col-12">
                  <FormLabel>What is the control system on purchase, receipt, storage, issue and physical custoday for the Inventory</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="controlSystem"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Control System"
                          placeholder="Enter Control System"
                          fullWidth
                          {...field}
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.controlSystem && errors.controlSystem.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>Is there an accounting system for the Inventory</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="accountingSystem"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Accounting System"
                          placeholder="Enter accountingSystem"
                          fullWidth
                          {...field}
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.accountingSystem && errors.accountingSystem.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>What is the valuation method followed for the Inventory</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={valuationMethod}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilter(!valuationMethod, `valuationMethod`)}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>What portion of Inventory is non moving</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="nonMovingPortion"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Non Moving Portion"
                          placeholder="Enter Non Moving Portion"
                          fullWidth
                          {...field}
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.nonMovingPortion && errors.nonMovingPortion.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>What portion of Inventory in slow moving</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="slowMovingPortion"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Slow Moving Portion"
                          placeholder="Enter Slow Moving Portion"
                          fullWidth
                          {...field}
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.slowMovingPortion && errors.slowMovingPortion.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>Should any part of inventory be written off in near future due to non moving or slow moving</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="writtenOff"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Written Off"
                          placeholder="Enter Written Off"
                          fullWidth
                          {...field}
                          inputProps={{ readOnly: readyOnlyValue }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.writtenOff && errors.writtenOff.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>How many months consumption should be maitained as inventory for your business</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="monthsOfInventoryMaintenance"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Months Of Inventory Maintenance"
                          placeholder="Enter Months Of Inventory Maintenance"
                          fullWidth
                          {...field}
                          type='text'
                          inputProps={{ readOnly: readyOnlyValue }}
                          onKeyDown={(e) => validateNumberonly(e)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.monthsOfInventoryMaintenance && errors.monthsOfInventoryMaintenance.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <FormLabel>How many months consumtpion are you maintaining as inventory</FormLabel>
                  <div className="form-group mt-2">
                    <Controller
                      name="monthsOfInventoryMaintaining"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Months Of Inventory Maintaining"
                          placeholder="Enter Months Of Inventory Maintaining"
                          fullWidth
                          {...field}
                          type='text'
                          inputProps={{ readOnly: readyOnlyValue }}
                          onKeyDown={(e) => validateNumberonly(e)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.monthsOfInventoryMaintaining && errors.monthsOfInventoryMaintaining.message}
                    </FormHelperText>
                  </div>
                </div>
                <div className="col-lg-4 col-4 mb-3 pl-3">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <FormLabel>Do you have any import purchases?</FormLabel>
                      <span className="ml-4 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch className="mx-2"
                          checked={importPurchase}
                          disabled={readyOnlyValue}
                          onChange={() => handleFilterCheck(!importPurchase, `importPurchase`, 'purchaseDetails', '', '')}
                          inputProps={{ 'aria-label': 'ant design' }} />
                        {" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
                {
                  importPurchase === true && (
                    <div className="col-lg-4 col-12">
                      <FormLabel>Proivde the details of import purchases and corresponding documentations done for this.</FormLabel>
                      <div className="form-group mt-2">
                        <Controller
                          name="purchaseDetails"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Purchase Details"
                              placeholder="Enter Purchase Details"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.purchaseDetails && errors.purchaseDetails.message}
                        </FormHelperText>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
        <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      </section>
    </>
  )
}



export default FddSectionG
import React from 'react';
import { DataProvider } from './context/DataContext';
import { ReactNotifications } from 'react-notifications-component';
import './App.css';
import 'react-notifications-component/dist/theme.css';
import Baseroute from './components/Baseroute';
import ScrollToTop from './components/common-page/ScrollToTop';

function App() { 
  return (
    <div className="App">
      <ReactNotifications />
      <DataProvider>
        <ScrollToTop />
        <Baseroute />
      </DataProvider>
    </div>
  );
}

export default App;

// "start": "NODE_ENV=development node scripts/start.js",
//   "start-prod": "NODE_ENV=production node scripts/start.js",
//     "build-dev": "NODE_ENV=development node scripts/build.js",
//       "build-prod": "NODE_ENV=production node scripts/build.js",
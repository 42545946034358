import React, { useContext, useState } from 'react'
import DataContext from '../../../context/DataContext'
import {
    Autocomplete,
    Checkbox,
    // Container,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    TablePagination,
    TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import { useForm, Controller } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
const Juryassign = ({ id, handleCloseModal, name }) => {
    const { icon, checkedIcon, setPageLoading } = useContext(DataContext)
    const { handleSubmit, control, setValue, reset, clearErrors, formState: { errors } } = useForm();
    useDidMountEffect(() => {
        userListItem()
    }, [])
    const [juryList, setJuryList] = useState([])
    const userListItem = () => {
        setPageLoading(true)
        var req = {
            "searchString": ''
        }
        apiService('investor/jury/list', req, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data.responseModelList) {
                    setJuryList(res.data.responseModelList)
                } else {
                    setJuryList([])
                }
            }
        })
    }
    const [juryIds, setJuryIds] = useState([])
    const handleSelectJury = (value) => {
        setJuryIds(value)
        setValue('jury', value)
        clearErrors('jury')
        // debugger
    }
    const onSubmit = (data) => {
        setPageLoading(true)
        let jury = []
        juryList.forEach(element => {
            data.jury.forEach(value => {
                if (element.name === value) {
                    jury.push(element.id)
                }
            })
        });
        var req = {
            "partnerOrInvestorId": id,
            "assign": true,
            "juryId": jury
        }
        // debugger
        let url
        if (name === 'scsthubavp') {
            url = 'partner/scst/jury/assign'
        } else {
            url = 'partner/jury/assign'
        }
        apiService(url, req, 'post').then((result) => {
            setPageLoading(false)
            if (result) {
                if (result.data.responseStatus === 'Success') {
                    setJuryIds([])
                    reset()
                    handleCloseModal()
                }
            }
        }).catch((err) => {

        });
    }
    return (
        <>
            <DialogTitle>
                <div className='d-flex align-item-center'> Assign Evaluator </div>
            </DialogTitle>
            <DialogContent>
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: "100%" }}>
                                    <Controller
                                        name="jury"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Evaluator is required" }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                multiple
                                                {...field}
                                                limitTags={2}
                                                value={juryIds}
                                                // options={juryList}
                                                // onChange={(e, selectedOptions) =>
                                                //     handleSelectJury(selectedOptions)
                                                // }
                                                options={juryList.map((option) => option.name)}
                                                // options={juryList}
                                                // getOptionLabel={(option) => option.name}
                                                // isOptionEqualToValue={(option, value) => option.name === value.name}
                                                onChange={(e, selectedOptions) => handleSelectJury(selectedOptions)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Evaluator Name *" />
                                                )}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="fnt-sm c-blue">
                                        Select one or more
                                    </FormHelperText>
                                    <FormHelperText className="text-danger">
                                        {errors.jury &&
                                            errors.jury.message}
                                    </FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        <div className='col-12 text-center'>
                            <Button variant="contained" onClick={handleCloseModal} className="btn btn-cancel submit px-3">Cancel</Button>
                            <Button
                                type="submit"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                className="btn btn-primary submit px-3 ml-3"
                            >
                                <span>Assign</span>
                            </Button>
                        </div>
                    </div>
                </form>
            </DialogContent>
        </>
    )
}

export default Juryassign
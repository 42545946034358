import React, { useState } from 'react';
import { Autocomplete, Container, FormHelperText, TextField } from '@mui/material'
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
// import SaveIcon from '@mui/icons-material/Save';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Associatedwith = ({ handleViewClose, checkedList,handleAssociate }) => {
    const { handleSubmit, control, setValue, clearErrors, reset, formState: { errors } } = useForm({});
    const [loading, setLoading] = useState(false);
    // const [associate, setAssociate] = useState(true)
    useDidMountEffect(() => {
        investerListApi()
    }, [])
    const [investorsListValue, setInvestorListValue] = useState([])
  
    const investerListApi = () => {
        apiService(`admin/tanseed/associated/investorname/list`, '', 'get').then((res) => {
            if (res) {
                if (res.data.tanseedAppliedDeatils) {
                    setInvestorListValue(res.data.tanseedAppliedDeatils)
                }
            }

        })
    }
    const [partnerOrInvestorID, setPartnerOrInvestorID] = useState()
    const handleSelectpartnerOrInvestor = (value) => {
        setPartnerOrInvestorID(value.name)
        // debugger
        setValue('partnerOrInvestorId', value.id)
        clearErrors('partnerOrInvestorId');
    }
    const onSubmit = (data) => {
        // setLoading(true);   
        debugger     
        var getform = data
        getform.startupIds = checkedList
        getform.associate = true; 
        debugger
        
        apiService(`admin/tanseed/startups/associate`, getform, 'post').then((res) => {
            setLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    // handleAssociate()
                    notifyService('success', 'Success', '')
                    handleCancel()
                }
            }
        })

    }
    const handleCancel = () => {
        reset({})
        handleViewClose()
    }
    return (
        <section className='p-3'>
            <Container>
                <div className='header_tag text-center'>
                    <h5 className='c-black'>Associate</h5>
                </div>
                <div className=''>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-12 col-12 mb-3'>
                                <div className="form-group mb-3">
                                    <Controller name="partnerOrInvestorId" control={control} defaultValue="" rules={{ required: 'Partner / Investor Name is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                fullWidth
                                                {...field}
                                                value={partnerOrInvestorID}
                                                options={investorsListValue}
                                                getOptionLabel={(option) => option.name}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                                // options={ investorsListValue.map((option) => option.id)}
                                                onChange={(e, selectedOptions) => handleSelectpartnerOrInvestor(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Partner / Investor Name *" />}
                                            />
                                        }
                                    />
                                    <FormHelperText className='text-danger'>{errors.partnerOrInvestorId && errors.partnerOrInvestorId.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                variant="contained"
                                className="btn btn-primary submit px-3"
                            >
                                <span>Submit</span>
                            </LoadingButton>
                            <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>

                        </div>
                    </form>
                </div>
            </Container>
        </section>
    )
}

export default Associatedwith
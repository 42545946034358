import React, { useContext, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import apiService from '../../../../api/apiService';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import DataContext from '../../../../context/DataContext';
const Viewjury = ({ panalRoundId, handleJuryClose }) => {
    const { setPageLoading } = useContext(DataContext)
    useDidMountEffect(() => {
        listApi()
    }, [])
    const [viewJuryData, setviewJuryData] = useState([])
    const listApi = () => {
        setPageLoading(true)
        setviewJuryData([])
        var req = {
            id: panalRoundId
        }
        apiService(`partner/panelround/jury/list`, req, 'post').then((result) => {
            setPageLoading(false)
            if (result && result.data && result.data.panelRoundJury) {
                setviewJuryData(result.data.panelRoundJury)
            }
        }).catch((err) => {

        });
    }
    const handleClickDelete = (id) => {
        setPageLoading(true)
        var req = {
            panelRoundsId: panalRoundId,
            juryId: id
        }
        apiService(`partner/panelround/jury/remove`, req, 'post').then((result) => {
            setPageLoading(false)
            if (result && result.data && result.data.responseStatus === 'Success') {
                listApi()
            }
        }).catch((err) => {

        });
    }
    return (
        <>
            <DialogTitle id="responsive-dialog-title">
                Round Jury Details
            </DialogTitle>
            <DialogContent>
                {
                    (viewJuryData.length) ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell align='center'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {viewJuryData.map((list, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{list.juryName}</TableCell>
                                            <TableCell align='center'>
                                                <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => handleClickDelete(list.juryId)} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <div className='nodata_tag'>
                            <p>Jury list is empty</p>
                        </div>
                    )
                }
                <div className='text-center p-3'>
                    <Button className='mr-3' onClick={handleJuryClose} variant="outlined">
                        OK
                    </Button>
                </div>
            </DialogContent>
        </>
    )
}

export default Viewjury
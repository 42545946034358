import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
const fileTypes = ["pdf"];

const FddSectionE = () => {
  const [formData, setFormData] = useState({});
  const { handleBackapp, setPageLoading,navigator } = useContext(DataContext)
  const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [Investors, setInvestors] = useState();
  const [roc, setROC] = useState();
  const [fileUploadId, setFileUploadId] = useState(0)
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/sece/get`, '', 'get').then((res) => {
      if (res && res.data) {
        var data = res.data

        if (res.data.id) {
          setFileUploadId(res.data.id)
        }
        if (res.data.certificateIssued) {
          setInvestors(res.data.certificateIssued)
        }
        setFormData(res.data)
      }
      setPageLoading(false)
    })
  }

  const handleTermSheet = (e) => {
    handleFileUpload(e, "termSheet", "termSheetName", "termSheetURL", 'file');
  }
  const handleFddReport = (e) => {
    handleFileUpload(e, "fddReport", "fddReportName", "fddReportURL", 'file');
  }
  const handleSha = (e) => {
    handleFileUpload(e, "sha", "shaName", "shaURL", 'file');
  }
  const handleCpCertificate = (e) => {
    handleFileUpload(e, "cpCertificate", "cpCertificateName", "cpCertificateURL", 'file');
  }
  const handleCsCertificate = (e) => {
    handleFileUpload(e, "csCertificate", "csCertificateName", "csCertificateURL", 'file');
  }
  const handlePreviousValuationCertificate = (e) => {
    handleFileUpload(e, "previousValuationCertificate", "previousvaluationCertificateName", "previousValuationCertificateURL", 'file');
  }
  const handleCapTable = (e) => {
    handleFileUpload(e, "capTable", "capTableName", "capTableURL", 'file');
  }
  const handleDetailsCertificate = (e) => {
    handleFileUpload(e, "detailsCertificate", "detailsCertificateName", "detailsCertificateURL", 'file');
  }

  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type
        var imagedatatype = imagetype.split("/")
        var img_crt_type = imagedatatype[1]
        if (
          ((format === 'file') && (img_crt_type === "pdf")) ||
          ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
        ) {
          setValue(filekey, "")
          setValue(fileName, "")

          var fileValue = logoselecetdFile
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(',')
            var ImageValue = spl[1]
            var img_name = fileValue.name
            setValue(filekey, ImageValue)
            setValue(fileName, img_name)
            setValue(fileURL, logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId
            }
            console.log(req);
            // saveData(req)
          }
        } else {
          notifyService('danger', 'File Format Invalided', 'Please check your file format')
        }
      }
    }
  }

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    console.log(data);

    setPageLoading(true);

    apiService('startup/fdd/sece/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-f");
        }
      }
    })
  }
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Details w.r.t. Previous Round of Investment Startupscst Investments</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>

            <div className="row doc-row2">
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Term Sheet</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="termSheet" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleTermSheet}
                          value={getValues('termSheet')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('termSheet') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('termSheet') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('termSheet') !== '' ? 'Uploaded (Click to Change)' : 'Term Sheet Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.termSheet && errors.termSheet.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('termSheetURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Financial Due Diligence (FDD) report, if issued earlier.</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="fddReport" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleFddReport}
                          value={getValues('fddReport')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('fddReport') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('fddReport') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('fddReport') !== '' ? 'Uploaded (Click to Change)' : 'Financial Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.fddReport && errors.fddReport.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('fddReportURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>SHA</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="sha" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleSha}
                          value={getValues('sha')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('sha') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('sha') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('sha') !== '' ? 'Uploaded (Click to Change)' : 'SHA Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.sha && errors.sha.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('shaURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>

              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>CP Completion Certificate</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="cpCertificate" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleCpCertificate}
                          value={getValues('cpCertificate')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('cpCertificate') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('cpCertificate') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('cpCertificate') !== '' ? 'Uploaded (Click to Change)' : 'CP Certificate Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.cpCertificate && errors.cpCertificate.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('cpCertificateURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>

             
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>CS Completion Certificate</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="csCertificate" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleCsCertificate}
                          value={getValues('csCertificate')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('csCertificate') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('csCertificate') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('csCertificate') !== '' ? 'Uploaded (Click to Change)' : 'CS Completion Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.csCertificate && errors.csCertificate.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('csCertificateURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Valuation Certificate</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="previousValuationCertificate" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handlePreviousValuationCertificate}
                          value={getValues('previousValuationCertificate')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('previousValuationCertificate') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('previousValuationCertificate') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('previousValuationCertificate') !== '' ? 'Uploaded (Click to Change)' : 'Valuation Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.previousValuationCertificate && errors.previousValuationCertificate.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('cpCertificateURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Cap Table before and after each round</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="capTable" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleCapTable}
                          value={getValues('capTable')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('capTable') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('capTable') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('capTable') !== '' ? 'Uploaded (Click to Change)' : 'Cap Table Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.capTable && errors.capTable.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                    onClick={() => handleClickOpen(getValues('capTableURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>ROC Filings are complete with respect to shares allotment?</FormLabel>
                  <Controller name="rocFilling" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setROC(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setROC(e.target.value); setValue('rocFilling', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.rocFilling && errors.rocFilling.message}</FormHelperText>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Any share certificates issued to the Investors - details</FormLabel>
                  <Controller name="certificateIssued" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setInvestors(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setInvestors(e.target.value); setValue('detailsCertificate', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.certificateIssued && errors.certificateIssued.message}</FormHelperText>
                </div>
              </div>
              {
                (Investors === 'true' || Investors === true) && (
                  <>
                    <div className='col-lg-4  col-12 mt-2'>
                      <div className="d-flex align-items-center">
                        <div className='form-group my-1'>
                          <Controller name="detailsCertificate" control={control} defaultValue="" rules={{ required: false }}
                            render={({ field }) =>
                              <FileUploader
                                {...field}
                                handleChange={handleDetailsCertificate}
                                value={getValues('detailsCertificate')}
                                name="file"
                                types={fileTypes}
                                children={
                                  <div className={getValues('detailsCertificate') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                    <center>
                                      <p className={getValues('detailsCertificate') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues('detailsCertificate') !== '' ? 'Uploaded (Click to Change)' : 'Investors Document'}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            } />
                          <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                          <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.detailsCertificate && errors.detailsCertificate.message}</FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                          onClick={() => handleClickOpen(getValues('detailsCertificateURL'))}
                          style={{ color: "#80809e" }}
                        > Preview
                        </Link>
                        </span>
                      </div>
                    </div>

                  </>
                )
              }
              {/* <div className='col-lg-4 col-12 mt-2'>
                <FormLabel>The Valuation Basis. ( Basis on which the valuation for the current round has been arrived at)</FormLabel>
                <div className="form-group mt-2">
                  <Controller
                    name="valuationBasis"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="The Valuation Basis"
                        placeholder="Enter Details"
                        fullWidth
                        multiline
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.valuationBasis && errors.valuationBasis.message}
                  </FormHelperText>
                </div>
              </div> */}
            </div>


            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
        <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      </section>
    </>
  )
}

export default FddSectionE
import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import {
    Autocomplete,
    Checkbox,
    Container,
    FormControl,
    List,
    Paper,
} from "@mui/material";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import apiService from "../../../../api/apiService";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import DataContext from "../../../../context/DataContext";
import notifyService from "../../../../api/notifySerivce";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import CloseIcon from "@mui/icons-material/Close";
import Remarksmodal from "../../../scst-page/Remarksmodal";


const Tanfundscoremodal = ({ idNumber, path, partnerOrInvestorId, viewStartupDetails }) => {
    const [open, setOpen] = React.useState(false);

    const { modalstyle, icon, checkedIcon } = useContext(DataContext)

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        // setOpenList(newOpen);
    };
    const [formData, setFormData] = useState({});
    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        clearErrors,
        setError,
        reset,
        formState: { errors },
    } = useForm({ values: formData });
    const {
        fields: questionsFields,
        remove: questionRemove,
    } = useFieldArray({
        control,
        name: "startupQuestions",
    });
    const [lblData, setLblData] = useState({});
    useDidMountEffect(() => {
        dropdownValueApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [suggestionData, setSuggestionData] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=suggestions`, '', 'get').then((res) => {
            if (res) {
                if (res.data) {
                    var val = res.data.dropDownValues
                    if (val.suggestions) {
                        setSuggestionData(val.suggestions)
                    }
                }
            }

        })
    }
    const getApi = () => {
        setOpen(true);
        setSuggestionValue([])
        setFormData({})
        setApproveStatus(true)
        apiService(`jury/startup/rounds/getcurrentround?startupid=${idNumber}`, "", "get")
            .then((result) => {
                if (result) {
                    if (result.data) {
                        if (result.data.startupQuestions) {
                            setFormData(result.data)
                        }
                    }
                }
            })
            .catch((err) => { });

    };
    const [approveStatus, setApproveStatus] = useState(true);
    const handleCheck = (index, value) => {
        setValue(`startupQuestions.${index}.answer`, value);
        clearErrors(`startupQuestions.${index}.answer`);
        if (value === "Yes") {
            setApproveStatus(false);
        }
    };
    const [openRemark, setOpenRemark] = useState(false)
    const [remarkIndex, setRemarkIndex] = useState()
    const [remarkValue, setRemarkValue] = useState('')
    const handleOpenRemark = (index) => {
        setOpenRemark(true)
        setRemarkIndex(index)
        var data = getValues(`startupQuestions.${index}.remarks`)
        setRemarkValue(data)
    }
    const handleCloseRemark = (index, value) => {
        setOpenRemark(false)
        setValue(`startupQuestions.${index}.remarks`, value)
        clearErrors(`startupQuestions.${index}.remarks`)

    }
    const [comment, setComment] = useState("");
    const handleComment = (value) => {
        setValue("remarks", value);
        clearErrors("remarks");
        setComment(value);
    };
    const [suggestionValue, setSuggestionValue] = useState([]);
    const handleSelectSuggestion = (value) => {
        setSuggestionValue(value)
        setValue('suggestions', value)
        clearErrors('suggestions')
    }
    const [loading, setLoading] = useState(false);
    const onSubmit = (data) => {
        let remarkStatus
        if (data.status === 2) {
            remarkStatus = true
        } else {
            remarkStatus = false
            clearErrors('remarks')
        }
        if (remarkStatus === true && data.remarks !== '') {
            saveApi(data)
        } else if (remarkStatus === false) {
            saveApi(data)
        } else if (remarkStatus === true) {
            setError('remarks', { message: 'Remark is required' })
        }
    };
    const saveApi = (data) => {
        setLoading(true);
        apiService('jury/startup/round/answers/savecurrentround', data, "post")
            .then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService("success", "Success", "Saved successfully !!");
                        toggleClose();
                    }
                }
            })
            .catch((err) => { });
    }
    const toggleClose = () => {
        reset();
        questionRemove()
        setFormData([])
        setOpen(false);
        viewStartupDetails(idNumber, partnerOrInvestorId)
    };




    const DrawerList = (
        <Box sx={{ width: 350 }} role="presentation">
            <div>
                <div className="full-div">
                    {/* <div></div> */}
                    {/* <h4 className="mb-4">
              <b>Rate your Score here</b>
            </h4> */}
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        {questionsFields.length > 0 ?
                            (
                                <>
                                    <div className="qus_div">
                                        {
                                            questionsFields.map((value, index) => (
                                                <>
                                                    <div className="form-group mb-5">
                                                        <p>
                                                            <b>
                                                                {index + 1}. {value.question}
                                                            </b>
                                                        </p>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            {value.questionType === "yesno" ? (
                                                                <div className="w-100">
                                                                    <div className="d-flex align-items-center justify-content-center w-100">
                                                                        <Controller
                                                                            name={`startupQuestions.${index}.answer`}
                                                                            control={control}
                                                                            defaultValue=""
                                                                            rules={{ required: "Answer is required" }}
                                                                            render={({ field }) => (
                                                                                <RadioGroup
                                                                                    row
                                                                                    aria-labelledby="demo-form-control-label-placement"
                                                                                    name="position"
                                                                                    {...field}
                                                                                >
                                                                                    <div className="d-flex align-items-center justify-content-center w-100 main_toggle">

                                                                                        <div
                                                                                            className={
                                                                                                getValues(
                                                                                                    `startupQuestions.${index}.answer`
                                                                                                ) === "Yes"
                                                                                                    ? "customs_toggle toggleActive"
                                                                                                    : "customs_toggle"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                formData.status === 0 &&
                                                                                                    handleCheck(index, "Yes");
                                                                                            }}
                                                                                        >
                                                                                            Yes
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                getValues(
                                                                                                    `startupQuestions.${index}.answer`
                                                                                                ) === "No"
                                                                                                    ? "customs_toggle toggleActive"
                                                                                                    : "customs_toggle"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                formData.status === 0 &&
                                                                                                    handleCheck(index, "No");
                                                                                            }}
                                                                                        >
                                                                                            No
                                                                                        </div>
                                                                                    </div>
                                                                                </RadioGroup>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <FormHelperText className="text-danger text-center">
                                                                        {errors.startupQuestions?.[index]?.answer &&
                                                                            errors.startupQuestions?.[index]?.answer.message}
                                                                    </FormHelperText>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <Controller
                                                                        name={`startupQuestions.${index}.score`}
                                                                        control={control}
                                                                        defaultValue=""
                                                                        rules={{ required: "Score is required" }}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                variant="outlined"
                                                                                label="Score *"
                                                                                placeholder="Enter Score"
                                                                                fullWidth
                                                                                {...field}
                                                                                type="number"
                                                                                onInput={(e) => {
                                                                                    if (e.target.value != 0) {
                                                                                        setApproveStatus(false)
                                                                                    } else {
                                                                                        setApproveStatus(true)
                                                                                    }
                                                                                }
                                                                                }
                                                                                inputProps={{
                                                                                    readOnly: formData.status !== 0 && true,
                                                                                }}
                                                                                onWheel={(event) =>
                                                                                    event.currentTarget
                                                                                        .querySelector("input")
                                                                                        ?.blur()
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                    <FormHelperText className="text-danger">
                                                                        {errors.startupQuestions?.[index]?.score &&
                                                                            errors.startupQuestions?.[index]?.score.message}
                                                                    </FormHelperText>
                                                                </>
                                                            )}
                                                            <div className="ml-3">
                                                                <Controller
                                                                    name={`startupQuestions.${index}.remarks`}
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{ required: false }}
                                                                    render={({ field }) => (
                                                                        <Button variant="outlined" onClick={() => handleOpenRemark(index)}>Remarks</Button>
                                                                    )}
                                                                />
                                                                <FormHelperText className="text-danger">
                                                                    {errors.startupQuestions?.[index]?.remarks &&
                                                                        errors.startupQuestions?.[index]?.remarks.message}
                                                                </FormHelperText>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                    {
                                        formData.status === 0 ? (
                                            <div className="mb-4">
                                                <Controller name="suggestions" control={control} defaultValue="" rules={{ required: false }}
                                                    render={({ field }) =>
                                                        <Autocomplete
                                                            disableClearable
                                                            disablePortal
                                                            multiple
                                                            {...field}
                                                            limitTags={2}
                                                            value={suggestionValue}
                                                            options={suggestionData}
                                                            onChange={(e, selectedOptions) => handleSelectSuggestion(selectedOptions)}
                                                            renderOption={(props, option, { selected }) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={icon}
                                                                        checkedIcon={checkedIcon}
                                                                        style={{ marginRight: 8 }}
                                                                        checked={selected}
                                                                    />
                                                                    {option}
                                                                </li>
                                                            )}
                                                            renderInput={(params) => <TextField {...params} label="Suggestion" />}
                                                        />
                                                    } />
                                                <FormHelperText className='fnt-sm c-blue'>Select one or more</FormHelperText>
                                                <FormHelperText className='text-danger'>{errors.suggestions && errors.suggestions.message}</FormHelperText>

                                            </div>
                                        ) : (
                                            <div>
                                                <h6 className="my-4">
                                                    Suggestions : <b></b>
                                                </h6>
                                                <ul>
                                                    {
                                                        formData.suggestions && formData.suggestions.map((res) => (
                                                            <li>{res}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {
                                        formData.status === 0 ? (
                                            <div>
                                                <Controller
                                                    name={`remarks`}
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: false }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            id="filled-textarea"
                                                            label="Remarks / Feedback"
                                                            placeholder=""
                                                            multiline
                                                            variant="filled"
                                                            {...field}
                                                            fullWidth
                                                            rows={4}
                                                            value={comment}
                                                            onInput={(e) => handleComment(e.target.value)}
                                                            inputProps={{ readOnly: formData.status !== 0 && true }}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.remarks &&
                                                        "Remark is required"}
                                                </FormHelperText>
                                            </div>
                                        ) : (
                                            <div>
                                                <h6 className="my-4">
                                                    Remarks : <b>{formData.remarks}</b>
                                                </h6>
                                            </div>
                                        )
                                    }
                                </>) : (
                                <>
                                    <div className="text-center py-4">
                                        <h5>Rounds Completed</h5>
                                    </div>

                                </>
                            )}
                        {formData.status === 0 ? (
                            <>
                                <div className="form-group text-center my-3">
                                    {
                                        questionsFields.length > 0 && (
                                            <>
                                                {approveStatus === false && (
                                                    <LoadingButton
                                                        type="submit"
                                                        loading={loading}
                                                        loadingPosition="start"
                                                        startIcon={<SaveIcon />}
                                                        variant="contained"
                                                        onClick={() => setValue("status", 1)}
                                                        sx={{
                                                            padding: "10px 20px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                                                                backgroundColor: "green",
                                                            }
                                                        }}
                                                    >
                                                        <span>Recommend</span>
                                                    </LoadingButton>
                                                )}
                                                <LoadingButton
                                                    type="submit"
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<CloseIcon />}
                                                    variant="contained"
                                                    sx={{
                                                        padding: "10px 20px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                                                            backgroundColor: "red",
                                                        }
                                                    }}

                                                    onClick={() => setValue("status", 2)}
                                                >
                                                    <span>Reject</span>
                                                </LoadingButton>
                                            </>
                                        )
                                    }
                                    <Button
                                        variant="contained"
                                        onClick={toggleClose}
                                        sx={{
                                            padding: "10px 23px !important", margin: '3px', borderRadius: "25px", "&:hover": {
                                                backgroundColor: "#253b80 ",
                                            }
                                        }}
                                    >
                                        {
                                            questionsFields.length > 0 ? ('Cancel') : ('OK')
                                        }
                                    </Button>
                                </div>


                            </>
                        ) : (
                            <>
                                {formData.status === 1 && (
                                    <h3 className="text-center my-4">
                                        <span className="approved_span c-green">
                                            <b>Recommended</b>
                                        </span>
                                    </h3>
                                )}
                                {formData.status === 2 && (
                                    <h3 className="text-center my-4">
                                        <span className="rejected_span c-red">
                                            <b>Rejected</b>
                                        </span>
                                    </h3>
                                )}

                                <div className="text-center mt-3">
                                    <Button
                                        variant="contained"
                                        onClick={toggleClose}
                                        sx={{
                                            padding: "10px 23px !important", marginRight: '3px', borderRadius: "25px", "&:hover": {
                                                backgroundColor: "#253b80 ",
                                            }
                                        }}
                                    >
                                        {
                                            questionsFields.length > 0 ? ('Cancel') : ('OK')
                                        }
                                    </Button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
            <Modal
                open={openRemark}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Remarksmodal index={remarkIndex} remarkValue={remarkValue} handleCloseRemark={handleCloseRemark} status={formData.status} />
                </Box>
            </Modal>
        </Box >
    );

    return (
        <>

            {/* onClick={() => getApi()} */}

            <div className="tab_side">
                <button onClick={() => getApi()} className="ani_btn">
                    <img src="/images/sml_arrow.png" alt="" className="sml-right" /> Rate
                    your Score
                    <img src="/images/sml_arrow.png" alt="" className="sml-right" />
                </button>
            </div>

            <Drawer anchor="right" open={open} className="scroe_module">
                {DrawerList}
            </Drawer>
        </>
    );
};

export default Tanfundscoremodal
import React, { useContext, useState } from 'react';
import { Autocomplete, Checkbox, Container, FormHelperText, TablePagination, TextField } from '@mui/material'
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
// import SaveIcon from '@mui/icons-material/Save';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataContext from '../../../context/DataContext';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
const AssigntoInvestor = ({ handleViewClose, checkedListName }) => {
    // const { handleSubmit, control, setValue, clearErrors, reset, formState: { errors } } = useForm({});
    const { tableIndexValue, setPageLoading } = useContext(DataContext);
    const [checkedList, setCheckedList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchInputValue, setSearchInputValue] = useState('');
    useDidMountEffect(() => {
        investerListApi()
    }, [page, rowsPerPage, searchInputValue])
    const [investorsListValue, setInvestorListValue] = useState([])

    const investerListApi = () => {
        setInvestorListValue([])
        setPageLoading(true)
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            searchString: searchInputValue
        };
        apiService(`user/investor/list`, req, 'post').then((res) => {
            setPageLoading(false)
            if (res) {
                setCount(res.data.count)
                if (res.data.responseModelList) {
                    setInvestorListValue(res.data.responseModelList)
                }
            }

        })
    }
    const handleSearch = (searchValue) => {
        setSearchInputValue(searchValue)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [itemsChecked, setItemsChecked] = useState(false);
    const [indexValue, setIndexValue] = useState([])
    const handleCheckboxClick = (e, index) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckedList(prev => [...prev, value * 1]);
            setIndexValue(prev => [...prev, index]);
        } else {
            setCheckedList(checkedList.filter(item => item !== value * 1));
            setIndexValue(indexValue.filter(item => item !== index));
        }
    };

    const selectItem = (e) => {
        const { checked } = e.target;
        const collection = checked ? investorsListValue.map(category => category.id) : [];
        setCheckedList(collection);
        setItemsChecked(checked);
        setIndexValue(checked ? investorsListValue.map((_, index) => index) : []);
    };

    const handleSelectAllChange = (event) => {
        const { checked } = event.target;
        const updatedCheckedList = checked ? investorsListValue.map((startup) => startup.id) : [];
        setCheckedList(updatedCheckedList);
        setItemsChecked(checked);
    };
    const onSubmit = () => {
        // setLoading(true);  
        var getform = {
            startupIds: checkedListName,
            associate: true,
            partnerOrInvestorIds : checkedList,
        }        

        apiService(`admin/ic/startups/associate`, getform, 'post').then((res) => {
            // setLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', '')
                    handleCancel()
                }
            }
        })

    }
    const handleCancel = () => {
        handleViewClose()
    }
    return (
        <section className='p-3'>
            <Container>
                <div className='header_tag text-center'>
                    <h5 className='c-black'>Assign to Investor</h5>
                    <div className='ml-auto'>
                        <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search by Name"
                                type="search"
                                inputProps={{ 'aria-label': 'Search by Name' }}
                            />
                        </SearchinputDiv>
                    </div>
                </div>
                <div className=''>
                    {investorsListValue.length ? (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={itemsChecked}
                                                onClick={selectItem.bind(this)}
                                                onChange={handleSelectAllChange}
                                            />
                                        </TableCell>
                                        <TableCell align="center">No</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>Investor Name</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {investorsListValue.map((list, index) => (
                                        <TableRow key={index}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    item={list}
                                                    value={list.id}
                                                    checked={checkedList.includes(list.id)}
                                                    onChange={e => handleCheckboxClick(e, index)}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    tableIndexValue(rowsPerPage, page, index)
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <img src={list.organizationLogoName} className='logo_list' alt='' />
                                            </TableCell>
                                            <TableCell>
                                                {list.name}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <caption>
                                    <TablePagination
                                        component="div"
                                        count={count}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        style={{float:'left'}}
                                    />
                                </caption>
                            </Table>

                        </TableContainer>
                    ) : (
                        <div className="nodata_tag">
                            <img
                                src="/images/Tanfundlogo-black-1.png"
                                className="no_data_logo"
                                alt=""
                            />
                            <h4>
                                Investor Not Found
                            </h4>
                        </div>
                    )}
                </div>
                <div className="form-group text-center my-3">
                    {checkedList.length > 0 && (
                        <Button
                            variant="contained"
                            className="btn btn-primary submit px-3"
                            onClick={onSubmit}
                        >
                            <span>Submit</span>
                        </Button>
                    )}
                    <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>

                </div>
            </Container>
        </section>
    )
}

export default AssigntoInvestor
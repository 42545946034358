import { Accordion, AccordionDetails, AccordionSummary, Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TextField, Typography, FormControl, FormHelperText, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import React, { useContext, useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import apiService from '../../../../api/apiService';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DataContext from '../../../../context/DataContext';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import notifyService from '../../../../api/notifySerivce';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
const FddSectionC = () => {
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [formData, setFormData] = useState({});
  const { handleBackapp, validateNumberonly, setPageLoading,navigator } = useContext(DataContext)
  const [loading, setLoading] = useState(false);
  const [isAccordionOpen1, setIsAccordionOpen1] = useState(true);
  const [isAccordionOpen2, setIsAccordionOpen2] = useState(true);
  const [isAccordionOpen3, setIsAccordionOpen3] = useState(true);
  const [readyOnlyBDDValue, setReadyOnlyBDDValue] = useState(false);
  const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/secc/get`, '', 'get').then((res) => {
      if (res) {
        setPageLoading(false)
        setFormData({})
        let response = res.data
        if (res.data.directors) {
          res.data.directors.forEach(element => {
              if (element.appointmentDate) {
                  element.appointmentDate = dayjs(element.appointmentDate, "YYYY-MM-DD");
              }
          });
      }
        if (response.id !== 0) {
          setFormData(response)
        }

      }
    })
  }

  const {
    fields: promotersFields,
    append: promotersAppend,
    remove: promotersRemove
  } = useFieldArray({
    control,
    name: "promoters"
  });
  // if(promotersFields.length === 0){
  //   promotersAppend()
  // }
  const {
    fields: directorsFields,
    append: directorsAppend,
    remove: directorsRemove
  } = useFieldArray({
    control,
    name: "directors"
  });
  // if(directorsFields.length === 0){
  //   directorsAppend()
  // }
  const {
    fields: resigneddirectorsFields,
    append: resigneddirectorsAppend,
    remove: resigneddirectorsRemove
  } = useFieldArray({
    control,
    name: "resigneddirectors"
  });
  // if(resigneddirectorsFields.length === 0){
  //   resigneddirectorsAppend()
  // }
  const addpromotersFieldsBtn = () => {
    promotersAppend({
      id: 0,
      name: "",
      qualification: "",
      experience: "",
      role: "",
      promoterFullTimeWorking: ""
    })
  }
  const adddirectorsFieldsBtn = () => {
    directorsAppend({
      id: 0,
      name: "",
      appointmentDate: "",
      involvement: "",
      
    })
  }
  const addresigneddirectorsBtn = () => {
    resigneddirectorsAppend({
      id: 0,
      name: "",
      reason:'',
    })
  }
  const deletePromotersInterested = async (index) => {
    const id = getValues(`promoters.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`startup/vendor/remove?id=${id}`, '', 'delete').then((res) => {
        if (res) {
          if (res.data.responseStatus === 'Success') {
            indexBaseddeletePromotersInterested(index)
          }
        }
      })
    } else {
      indexBaseddeletePromotersInterested(index)
    }
  }
  const deleteDirectorsInterested = async (index) => {
    const id = getValues(`directors.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`startup/vendor/remove?id=${id}`, '', 'delete').then((res) => {
        if (res) {
          if (res.data.responseStatus === 'Success') {
            indexBaseddeleteDirectorsInterested(index)
          }
        }
      })
    } else {
      indexBaseddeleteDirectorsInterested(index)
    }
  }
  const deleteResignedDirectors = async (index) => {
    const id = getValues(`resigneddirectors.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(`startup/vendor/remove?id=${id}`, '', 'delete').then((res) => {
        if (res) {
          if (res.data.responseStatus === 'Success') {
            indexBaseddeleteResignedDirectors(index)
          }
        }
      })
    } else {
      indexBaseddeleteResignedDirectors(index)
    }
  }

  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const indexBaseddeletePromotersInterested = (index) => {
    var startupTeams = getValues('promoters')
    if (startupTeams.length === 1 && index === 0) {
      var val = getValues()
      val.promoters = [{
        id: 0,
        name: "",
        qualification: "",
        experience: "",
        role: "",
      }]
      setFormData(val)
    } else {
      promotersRemove(index)
      var val = getValues()
      setFormData(val)
    }
  }

  const indexBaseddeleteDirectorsInterested = (index) => {
    var startupTeams = getValues('directors')
    if (startupTeams.length === 1 && index === 0) {
      var val = getValues()
      val.directors = [{
        id: 0,
        name: "",
        appointmentDate: "",
        involvement: "",
      }]
      setFormData(val)
    } else {
      directorsRemove(index)
      var val = getValues()
      setFormData(val)
    }
  }
  const indexBaseddeleteResignedDirectors = (index) => {
    var startupTeams = getValues('directors')
    if (startupTeams.length === 1 && index === 0) {
      var val = getValues()
      val.directors = [{
        id: 0,
        name: "",
        reason: "",
      }]
      setFormData(val)
    } else {
      resigneddirectorsRemove(index)
      var val = getValues()
      setFormData(val)
    }
  }
  const handleDateFormat = (newValue, index) => {
    setValue(`directors.${index}.appointmentDate`, newValue)
    clearErrors(`directors.${index}.appointmentDate`);
  }
  const onSubmit = (data) => {
    console.log(data);
    if (data.directors) {
      data.directors.forEach(element => {
        if (element.appointmentDate) {
          element.appointmentDate = element.appointmentDate.format('YYYY-MM-DD');
        }

      });
    }
    setPageLoading(true);

    apiService('startup/fdd/secc/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-d");
        }
      }
    })
  }
  return (
    <div>
      <div className='form_div2'>
        <div className='doc-title mt-3'>
          <h6>Details of Promoters & Directors :</h6>
        </div>
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>

          <div className='row'>
            <div className="col-lg-12 col-12 mb-3 pl-3">
              <Accordion className='Addfundacc-bor' expanded={isAccordionOpen1} onChange={() => setIsAccordionOpen1(!isAccordionOpen1)}>
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className='Addfundacc'
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 0
                  }}>
                    <AddCircleIcon className='add-icon mr-3' />Name of the promoters, their qualifcation/experience and the roll in the current company
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className='col-12 mb-3'>
                      <div>
                        {
                          (promotersFields.length >= 0) && (
                            <TableContainer component={Paper} className='table-back'>
                              <Table aria-label="caption table">
                                <TableBody >
                                  {promotersFields.map((field, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`promoters.${index}.name`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Name *"
                                                placeholder='Enter name'
                                                fullwidth
                                                {...field}
                                                inputProps={
                                                  { readOnly: readyOnlyValue, }
                                                }
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.promoters?.[index]?.name && errors.promoters?.[index]?.name.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`promoters.${index}.qualification`} control={control} defaultValue="" rules={{ required: 'qualification is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Qualification*"
                                                placeholder='Enter qualification'
                                                fullwidth
                                                {...field}
                                                type='text'
                                                inputProps={{ readOnly: readyOnlyValue }}
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.promoters?.[index]?.qualification && errors.promoters?.[index]?.qualification.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`promoters.${index}.experience`} control={control} defaultValue="" rules={{ required: 'experience is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Experience*"
                                                placeholder='Enter Experience'
                                                fullwidth
                                                {...field}
                                                type='text'
                                                onKeyDown={(e) => validateNumberonly(e)}
                                                inputProps={{ readOnly: readyOnlyValue }}
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.promoters?.[index]?.experience && errors.promoters?.[index]?.experience.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`promoters.${index}.role`} control={control} defaultValue="" rules={{ required: 'role is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Role*"
                                                placeholder='Enter Role'
                                                fullwidth
                                                {...field}
                                                type='text'
                                                inputProps={{ readOnly: readyOnlyValue }}
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.promoters?.[index]?.role && errors.promoters?.[index]?.role.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <FormLabel>Promoter FullTime Working</FormLabel>
                                          <Controller name={`promoters.${index}.promoterFullTimeWorking`} control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                              <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                              >
                                                <FormControlLabel disabled={readyOnlyValue} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} defaultValue='null' value='false' control={<Radio />} label="No" />

                                              </RadioGroup>
                                            } />
                                          <FormHelperText className='text-danger'>{errors.promoters?.[index]?.promoterFullTimeWorking && errors.promoters?.[index]?.promoterFullTimeWorking.message}</FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                        <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deletePromotersInterested(index) : <></> }} />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <center>
                                <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addpromotersFieldsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                              </center>
                            </TableContainer>
                          )
                        }
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="col-lg-12 col-12 mb-3 pl-3">
              <Accordion className='Addfundacc-bor' expanded={isAccordionOpen2} onChange={() => setIsAccordionOpen2(!isAccordionOpen2)}>
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className='Addfundacc'
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 0
                  }}>
                    <AddCircleIcon className='add-icon mr-3' />List of Directors with the date of appointment and whether they are involved in the operations of the company
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className='col-12 mb-3'>
                      <div>
                        {
                          (directorsFields.length >= 0) && (
                            <TableContainer component={Paper} className='table-back'>
                              <Table aria-label="caption table">
                                <TableBody >
                                  {directorsFields.map((field, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                      <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`directors.${index}.name`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Name *"
                                                placeholder='Enter name'
                                                fullwidth
                                                {...field}
                                                inputProps={
                                                  { readOnly: readyOnlyValue, }
                                                }
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.directors?.[index]?.name && errors.directors?.[index]?.name.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`directors.${index}.appointmentDate`} control={control} defaultValue="" rules={{ required: 'Appointment Date is required' }}
                                            render={({ field }) =>
                                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']} fullWidth>
                                                  <DatePicker label="Appointment Date" format="DD/MM/YYYY"
                                                    {...field}
                                                    sx={{ width: "100%" }}
                                                    inputFormat="DD/MM/YYYY"
                                                    onChange={(newValue) => handleDateFormat(newValue, index)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    disabled={readyOnlyValue}
                                                  />
                                                </DemoContainer>
                                              </LocalizationProvider>
                                            } />
                                          <FormHelperText className='text-danger'>
                                            {errors.directors?.[index]?.appointmentDate && errors.directors?.[index]?.appointmentDate.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>


                                      <TableCell sx={{ width: '20%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <FormLabel>involvement</FormLabel>
                                          <Controller name={`directors.${index}.involvement`} control={control} defaultValue="" rules={{ required: false }}
                                            render={({ field }) =>
                                              <RadioGroup
                                                row
                                                name="position"
                                                {...field}
                                              >
                                                <FormControlLabel disabled={readyOnlyValue} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel disabled={readyOnlyValue} defaultValue='null' value='false' control={<Radio />} label="No" />

                                              </RadioGroup>
                                            } />
                                          <FormHelperText className='text-danger'>{errors.directors?.[index]?.involvement && errors.directors?.[index]?.involvement.message}</FormHelperText>
                                        </FormControl>
                                      </TableCell>


                                      <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                        <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteDirectorsInterested(index) : <></> }} />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <center>
                                <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => adddirectorsFieldsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )
                        }
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="col-lg-12 col-12 mb-3 pl-3">
              <Accordion className='Addfundacc-bor' expanded={isAccordionOpen3} onChange={() => setIsAccordionOpen3(!isAccordionOpen3)}>
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className='Addfundacc'
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 0
                  }}>
                    <AddCircleIcon className='add-icon mr-3' />Details of Directors resigned from the inception to till date & the reasons thereof
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className='col-12 mb-3'>
                      <div>
                        {
                          (resigneddirectorsFields.length >= 0) && (
                            <TableContainer component={Paper} className='table-back'>
                              <Table aria-label="caption table">
                                <TableBody >
                                  {resigneddirectorsFields.map((field, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>{index + 1}</TableCell>
                                      <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`resigneddirectors.${index}.name`} control={control} defaultValue="" rules={{ required: 'Name is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Name *"
                                                placeholder='Enter name'
                                                fullwidth
                                                {...field}
                                                inputProps={
                                                  { readOnly: readyOnlyValue, }
                                                }
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.resigneddirectors?.[index]?.name && errors.resigneddirectors?.[index]?.name.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell sx={{ width: '30%', borderBottom: 'none', padding: '5px' }}>
                                        <FormControl sx={{ width: '100%' }} >
                                          <Controller name={`resigneddirectors.${index}.reason`} control={control} defaultValue="" rules={{ required: 'Reason is required' }}
                                            render={({ field }) =>
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Reason *"
                                                placeholder='Enter Reason'
                                                fullwidth
                                                {...field}
                                                inputProps={
                                                  { readOnly: readyOnlyValue, }
                                                }
                                              />} />
                                          <FormHelperText className='text-danger'>
                                            {errors.resigneddirectors?.[index]?.reason && errors.resigneddirectors?.[index]?.reason.message}
                                          </FormHelperText>
                                        </FormControl>
                                      </TableCell>





                                      <TableCell sx={{ borderBottom: 'none', padding: '5px' }} align='center'>
                                        <DeleteIcon sx={{ backgroudColor: '#F4F4F4', fontSize: '24px', fill: '#808080', cursor: 'pointer' }} onClick={() => { (readyOnlyValue === false) ? deleteResignedDirectors(index) : <></> }} />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <center>
                                <Button className='add-more-btn' disabled={readyOnlyValue} onClick={() => addresigneddirectorsBtn('')}><AddCircleIcon className='mr-2' />Add More</Button>
                              </center>
                            </TableContainer>
                            //     ) : (
                            // <div className='nodata_tag'>
                            //     <p>Fundraising history details is empty</p>
                            // </div>
                          )
                        }
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="form-group text-right mt-3">
            <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
            {
              (readyOnlyBDDValue === false) ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (<></>)
            }

          </div>
        </form>
      </div>

    </div >
  )
}

export default FddSectionC
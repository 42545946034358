import React from 'react'
import { Outlet } from 'react-router-dom'
import Jurynavbar from './navbar-page/Jurynavbar';
import useDidMountEffect from '../../hooks/useDidMountEffect';
const Jurylayout = () => {
    localStorage.setItem('regRoleId',5)
    useDidMountEffect(() => {
        document.getElementById('theme').setAttribute('href', '/css/admin.css');
    }, [])
    return (
        <div className='admin_main_div'>
            <Jurynavbar />
            <Outlet></Outlet>
        </div>
    )
}

export default Jurylayout
import React, { useState } from 'react'
import apiService from '../../../../../api/apiService'
import { Button, Container, Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const Questiontemplate = ({ nestIndex, handleTemplateClose, getValues }) => {
    const [templateData, setTemplateData] = useState([])
    useDidMountEffect(() => {
        templeteList()
    }, [])
    const templeteList = () => {
        apiService('partner/questions/template/list', {}, 'get').then((res) => {
            if (res) {
                // debugger
                if (res.data.templates) {
                    setTemplateData(res.data.templates)
                    // debugger
                } else {
                    setTemplateData([])
                }
            }
        })
    }
    const [expanded, setExpanded] = useState('panel0');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const selectTemplate = (panelRoundid) => {
        var getform = getValues()
        var req = {
            "panelRoundQuestionTemplateId": panelRoundid,
            "id": getform.panelRounds[nestIndex].id
        }        
        apiService(`partner/questions/template/save`, req, 'post').then((res) => {            
            if (res) {
                if (res.data.responseStatus === "Success") {
                    handleTemplateClose()
                }
            }
        })
    }
    return (
        <section className='set_modal_with'>
            <Container>
                <div className='header_tag'>
                    <h5 className='c-black'>Question Template Details</h5>
                </div>
                <div className=''>
                    {templateData.length && templateData.map((field, index) => (
                        <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                            <AccordionSummary aria-controls="panel1d-content" id={`panel${index}d-header`}>
                                <Typography>{field.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='tem_qus_div'>
                                    <ol>
                                        {field.questionNames && field.questionNames.map((list, index) => (
                                            <li>{list}</li>
                                        ))
                                        }
                                    </ol>
                                </div>
                                <div className='text-right'>
                                    <Button variant="contained" size="small" onClick={() => selectTemplate(field.id)}>Select</Button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))
                    }
                </div>
            </Container>
        </section>
    )
}

export default Questiontemplate
import React, { useContext, useEffect, useState } from 'react'
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Autocomplete, FormControl, Button, TextField, Select, MenuItem, InputLabel, NativeSelect, InputBase } from "@mui/material";
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import { styled, alpha } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import DataContext from '../../../context/DataContext';
import downloadPDF from "../../../hooks/downloadPDF";
import SearchIcon from '@mui/icons-material/Search';
const formatDateForApi = (date) => date ? dayjs(date).format('DD-MM-YYYY') : null;
const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    '& .MuiCalendarPicker-root': {
        '& .MuiPickersCalendarHeader-label': {
        },
    },
}));

const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '17ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const ReportsFilter = ({ setPage, rowsPerPage, districtName, setEndDate, setStartDate, setPreferredSectors, startDate, preferredSectors, setDistrictName, scsthub, setScsthub, yearName, setYearName, page, monthName, setMonthName, setGender, gender, manualName, setManualName, endDate, path, setSearchInputValue, searchInputValue, statusValue, setStatusValue, lblName }) => {
    const { yearListApi, yearListValue, setSelectedYear, scstRemoveFiters, setPageLoading } = useContext(DataContext);
    const [cityList, setCityList] = useState([]);
    const [hubList, setHubList] = useState([]);

    useDidMountEffect(() => {
        yearListApi()
        // getCityApi();
        getScstHub();
        dropdownValueApi()
    }, [searchInputValue, ]);
    useDidMountEffect(() => {
        getCityApi();
    }, [scsthub])
    const [sectorData, setSectorData] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup_min`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setSectorData(val.sectors)
        })
    }
    const getCityApi = async () => {
        var role = localStorage.getItem('role')
        let url
        if (role === 'SCSTHUB') {
            url = 'admin/district/get'
        } else {
            // url = `admin/dropdownvalues/get?key=district`
            url=`admin/hub/district/get?hub=${scsthub}`
        }
        await apiService(url, "", "get").then(
            (res) => {
                if (res && res.data)
                    if (role === 'SCSTHUB') {
                        setCityList(res.data);
                    } else {
                        // var val = res.data.dropDownValues;
                        // setCityList(val.districts);
                        if (res && res.data && res.data.districts) {
                            var dis = res.data.districts
                            dis.unshift('All')
                            setCityList(dis)
                        }
                    }

            }
        );
    };
    const getScstHub = async () => {
        await apiService(`partner/hub/filter`, "", "get")
            .then((res) => { 
                if (res.data) {
                    var hub = res.data
                    hub.unshift('All')
                    setHubList(hub);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSelectCity = (value) => {
        setDistrictName(value);
        localStorage.setItem('districtNameScst', value)
        localStorage.setItem('pageScst', 0)
        setPage(0)
    };

    const handleScstHub = (value) => {
        setScsthub(value);
        localStorage.setItem('hubScst', value)
        localStorage.setItem('pageScst', 0)
        setDistrictName('All');
        localStorage.setItem('districtNameScst', '')
    };

    const handleMenualFiter = (value) => {
        setManualName(value)
        localStorage.setItem('manualNameScst', value)
        localStorage.setItem('pageScst', 0)
        setPage(0)
    }

    const d = new Date();
    const handleClear = () => {
        setDistrictName("");
        setScsthub("");
        setMonthName(0)
        // setGender("All")
        setYearName('All')
        setEndDate(null)
        setStartDate(null);
        setPreferredSectors("")
        setManualName(0)
        scstRemoveFiters()


    };

    const handleSearch = (searchValue) => {
        setSearchInputValue(searchValue)
        localStorage.setItem('searchInputValueScst', searchValue)
        localStorage.setItem('pageScst', 0)
        setPage(0)
    }
    const handleSelectSector = (value) => {
        setPreferredSectors(value);
    };

    // const handleStartDate = (newValue) => {
    //     if (newValue) {
    //         const formattedDate = dayjs(newValue).format('DD-MM-YYYY'); 
    //         setStartDate(formattedDate);  
    //     } 
    // }
    // const handleEndDate = (newValue) => {
    //     if (newValue) {
    //         const formattedDate = dayjs(newValue).format('DD-MM-YYYY');
    //         setEndDate(formattedDate);  
    //     } 
    // }

    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
        if (endDate && newValue && newValue.isAfter(endDate)) {
            setEndDate(null);
            setPage(0)
        }
    };
    const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
        setPage(0)
    };
    const Gender = ["All", "Male", "Female", "Transgender"]
    const handlegender = (value) => {
        setGender(value)
    }
    const handlePdf = (name) => {
        setPageLoading(true)
        var startedDate = formatDateForApi(startDate)
        var endedDate = formatDateForApi(endDate)
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            district: districtName,
            hub: scsthub,
            searchString: searchInputValue,
            // status: statusValue,
            sector: preferredSectors,
            startDate: startedDate ? startedDate : "",
            endDate: endedDate ? endedDate : "",
            download: 'yes'
        }
        apiService("admin/hub/scst/report/list", req, "downloadpost").then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data) {
                    debugger
                    downloadPDF(res.data, 'Report');
                }

            }
        });
    }
    return (
        <div>

            <div className="header_tag">
                <h5 className='mb-0'>{lblName}</h5>
                <div className="ml-auto d-flex align-items-center">
                    <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search by Name"
                            type="search"
                            inputProps={{ 'aria-label': 'Search by Name' }}
                        />
                    </SearchinputDiv>
                    <Button
                        className='ml-3'
                        variant="outlined"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseWidthExample"
                    >
                        <FilterAltIcon />
                        Filters
                    </Button>
                    <div className='hub-pdf-icon'>
                        <img src="/images/pdf.png" alt="" onClick={() => handlePdf('name')} />


                    </div>
                </div>
            </div>

            {/* filter code */}
            <div className="collapse mt-3 mb-3" id="collapseWidthExample">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-2">
                        <div className="form-group">
                            <FormControl fullWidth>
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    value={scsthub}
                                    options={hubList}
                                    onChange={(e, selectedOptions) =>
                                        handleScstHub(selectedOptions)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Hub" fullWidth />
                                    )}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="form-group">
                            <FormControl fullWidth>
                                <Autocomplete
                                    disableClearable
                                    disablePortal
                                    value={districtName}
                                    options={cityList}
                                    onChange={(e, selectedOptions) =>
                                        handleSelectCity(selectedOptions)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Startup District" fullWidth />
                                    )}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className='col-lg-2'>

                        <Autocomplete
                            disableClearable
                            disablePortal
                            options={sectorData}
                            value={preferredSectors}
                            onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                            renderInput={(params) => <TextField {...params} label="Sector *" />}

                        />

                    </div>




                    {/* <div className="col-lg-2">
                    <div className="form-group">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                value={gender}
                                                options={Gender}
                                                onChange={(e, selectedOptions) =>
                                                    handlegender(selectedOptions)
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Gender" fullWidth />
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                    </div> */}
                    <div className="col-lg-3 mt-1 datepic-margin">
                        <div className="form-group">
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']} fullWidth>
                                    <DatePicker label="Start Date" format="DD/MM/YYYY"
                                        sx={{ width: "100% " }}
                                        inputFormat="DD/MM/YYYY"
                                        onChange={(newValue) => handleStartDate(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    format="DD/MM/YYYY"
                                    inputFormat="DD/MM/YYYY"
                                    onChange={handleStartDateChange}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                        />}
                                />
                            </LocalizationProvider>
                        </div>


                    </div>
                    <div className="col-lg-3 mt-1 datepic-margin">
                        <div className="form-group">
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']} fullWidth>
                                    <DatePicker label="End Date" format="DD/MM/YYYY"
                                        sx={{ width: "100% " }}
                                        inputFormat="DD/MM/YYYY"
                                        onChange={(newValue) => handleEndDate(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider> */}
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    format="DD/MM/YYYY"
                                    inputFormat="DD/MM/YYYY"
                                    minDate={startDate} // Ensures endDate is on or after startDate
                                    disabled={!startDate}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!endDate && startDate && endDate.isBefore(startDate)} // Error only if endDate is invalid
                                            helperText={
                                                !!endDate && startDate && endDate.isBefore(startDate)
                                                    ? "End date cannot be before start date"
                                                    : "" // No helper text if endDate is not set or valid
                                            }
                                        />
                                    )}
                                />
                            </LocalizationProvider> */}



                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    format="DD/MM/YYYY"
                                    inputFormat="DD/MM/YYYY"
                                    onChange={handleEndDateChange}
                                    minDate={startDate}
                                    disabled={!startDate}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                        />}
                                />
                            </LocalizationProvider>
                        </div>


                    </div>



                </div>
                <div className="mt-3 mb-3">
                    <center>
                        <Button
                            variant="contained"
                            sx={{ marginRight: "10px" }}
                            onClick={() => handleClear()}
                            data-toggle="collapse"
                            data-target="#collapseWidthExample"
                        >
                            Clear
                        </Button>

                    </center>
                </div>
            </div>
        </div >
    )
}


export default ReportsFilter

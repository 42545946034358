import React from 'react';
import { Link } from 'react-router-dom';

const CustomLink = ({ to, children }) => {
  // const handleClick = (event) => {
  //   if (event.ctrlKey || event.metaKey) {
  //     event.preventDefault();
  //   }
  // };

  return (
    <Link className="c-blue" to={to}>
      {children}
    </Link>
  );
};

export default CustomLink;

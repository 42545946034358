import React from 'react'
import { Link } from "react-router-dom";
import "../signupModal/signupmodal.css";

const Signinscstmodal = ({ handleClose }) => {
  return (
    <>
      <section className="logs-section">
        <div className="wrap d-md-flex">
          <div className="text-wrap p-3  text-center d-flex align-items-center order-md-last mx-1">
            <div className="text w-100">
              <img
                src="/images/tanseed-logo.png"
                className="white_logo"
                alt="tanseed-logo"
              />
              <p className="set-fnt mb-3 mt-3">TANFUND </p>
              {/* <Link onClick={handleClose} to="/startup/dashboard"><img src='/images/applygiffy.gif' className='white_logo' alt='tanseed-logo'/></Link> */}
              <Link
                onClick={handleClose}
                to="/startup/dashboard"
                className="btn btn-white btn-outline-white"
              >
                View / Apply
              </Link>
            </div>
          </div>
          <div className="text-wrap p-3  text-center d-flex align-items-center order-md-last  mx-1">
            <div className="text w-100">
              <img
                src="/images/tanseed-logo.png"
                className="white_logo"
                alt="tanseed-logo"
              />
              <p className="set-fnt mb-3 mt-3">TANSEED </p>
              {/* <Link onClick={handleClose} to="/admin/dashboard/SCST">
                                <img src='/images/applygiffy.gif' className='white_logo' alt='tanseed-logo'/>
                                </Link> */}
              {/* <Link
                onClick={handleClose}
                // to="/investor/sign-up"
                className="btn btn-white btn-outline-white"
              >
                View / Apply
              </Link> */}
            </div>
          </div>
          <div className="text-wrap p-3  text-center d-flex align-items-center order-md-last  mx-1">
            <div className="text w-100">
              <img
                src="/images/tanseed-logo.png"
                className="white_logo"
                alt="tanseed-logo"
              />
              <p className="set-fnt mt-3 mb-3">SC/ST FUND</p>
              {/* <Link onClick={handleClose} to="/admin/dashboard/scst"><img src='/images/applygiffy.gif' className='white_logo' alt='tanseed-logo'/></Link> */}
              <Link
                onClick={handleClose}
                to="/startup/dashboard/scst"
                className="btn btn-white btn-outline-white"
              >
                View / Apply
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Signinscstmodal

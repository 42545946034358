import React from 'react'
import { Outlet } from 'react-router-dom'
import Partnernavbar from './navbar-page/Partnernavbar'
import useDidMountEffect from '../../hooks/useDidMountEffect'

const Partnerlayout = () => {
    localStorage.setItem('regRoleId', 3)
    useDidMountEffect(() => {
        document.getElementById('theme').setAttribute('href', '/css/admin.css');
    }, [])
    return (
        <>
            <div className='admin_main_div'>
                <Partnernavbar />
                <Outlet></Outlet>
            </div>
        </>
    )
}

export default Partnerlayout
import React, { useContext, useRef, useState } from 'react';
import { Autocomplete, FormControl } from '@mui/material'
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
// import apiService from '../../../api/apiService';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import { MuiFileInput } from 'mui-file-input';
import dayjs from 'dayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PreviewIcon from '@mui/icons-material/Preview';
import Fab from '@mui/material/Fab';
import { Link } from 'react-router-dom';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));


const Application = () => {
    const { validateEmail, validatePhoneNumber, handleBack, readyOnlyValue, setReadyOnlyValue, navigator, VisuallyHiddenInput, setStartupProfileValue, } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, watch, clearErrors, formState: { errors } } = useForm(
        { values: formData, }
    );
    const dpiitRegistered = watch('dpiitRegistered', false);
    const [checkMethod, setCheckMethod] = useState('save')
    useDidMountEffect(() => {
        // eslint-disable-next-line

        (async () => await getApi())()
        dropdownValueApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readyOnlyValue, setReadyOnlyValue])
    const [sectorData, setSectorData] = useState([])
    const [entityTypeList, setEntityTypeList] = useState([])
    const [startupStageData, setStartupStageData] = useState([])
    const [startupCategoryData, setStartupCategoryData] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=startup_min`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setSectorData(val.sectors)
            setEntityTypeList(val.legalEntities)
            setStartupStageData(val.startupStages)
            setStartupCategoryData(val.startupCategory)
        })
    }
    const getApi = async () => {
        await apiService(`startup/startupdetails/get`, '', 'get').then((res) => {
            if (res) {
                let response = res.data

                if (response.id !== 0) {

                    setFormData({})
                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                    if (response.inCorpCertificate) {
                        setCheckMethod('update')
                    }
                    if (response.incorporatedDate) {
                        const tomorrow = dayjs(response.incorporatedDate, 'DD/MM/YYYY')
                        response.incorporatedDate = tomorrow
                        setDate(tomorrow)
                    }
                    if (response.entityType) {
                        setEntityTypeValue(response.entityType)
                    }
                    if (response.inCorpCertificate) {
                        setInCorporationValue(response.inCorpCertificate)
                        setInCorporationName(response.inCorpCertificate)
                        setInCorporationURL(response.inCorpCertificateName)
                    }
                    // if (response.sector) {
                    //     setSectorName(response.sector)
                    // } 
                    // if (response.startupStage) {
                    //     setStartupStageValue(response.startupStage)
                    // } 
                    if (response.entityType) {
                        setEntityTypeValue(response.entityType)
                    }
                    if (response.startupLogoName === undefined) {

                        setLogoDisplayUrl('/images/user.png')
                        setStartupProfileValue(false)
                    } else {
                        setLogoDisplayUrl(response.startupLogoName)
                        setLogoDisplayValue(response.startupLogo)
                        setLogoDisplayName(response.startupLogo)
                        setStartupProfileValue(true)

                    }
                    setValue("startupLogo", response.startupLogo)
                    setValue("startupLogoName", response.startupLogoName)
                    setValue("inCorpCertificate", response.inCorpCertificate)
                    setValue("inCorpCertificateName", response.inCorpCertificateName)
                    // console.log(response)
                } else {
                    setFormData(response)
                }
            }

        })
    }
    // const [entityValue, setEntityValue] = useState();

    const [inCorporationValue, setInCorporationValue] = useState(null)
    const [inCorporationName, setInCorporationName] = useState(null)
    const [inCorporationURL, setInCorporationURL] = useState(null)
    const [logoDisplayUrl, setLogoDisplayUrl] = useState('/images/user.png')
    const [logoDisplayValue, setLogoDisplayValue] = useState(null)
    const [logoDisplayName, setLogoDisplayName] = useState(null)


    const inputRef = useRef(null);
    let logoselecetdFile = ''
    const handleFileUpload = (event, filekey, fileName, setdata, setName, setUrl, format) => {

        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            if (logoselecetdFile) {
                var reader = new FileReader();
                var imagetype = logoselecetdFile.type
                var imagedatatype = imagetype.split("/")
                var img_crt_type = imagedatatype[1]
                if (
                    ((format === 'file') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf")) ||
                    ((format === 'image') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png"))
                ) {
                    setValue(filekey, "")
                    setValue(fileName, "")
                    setName('')
                    setUrl('')
                    if (event.target === undefined) {
                        setdata(event)
                    } else {
                        setdata(event.target.files[0])
                    }
                    var fileValue = logoselecetdFile
                    reader.readAsDataURL(logoselecetdFile);
                    reader.onload = () => {
                        var logourl1 = reader.result;
                        var spl = logourl1.split(',')
                        var ImageValue = spl[1]
                        var img_name = fileValue.name
                        setUrl(logourl1)
                        setValue(filekey, ImageValue)
                        setValue(fileName, img_name)
                        setName(img_name)
                        clearErrors(filekey);
                        clearErrors(fileName);
                        if (filekey === "startupLogo") {
                            setLogoInvalid(false)
                        }
                    }
                } else {

                    notifyService('danger', 'File Format Invalided', 'Please check your file format')
                }
            }
        } else {

            // setValue(filekey, "")
            // setValue(fileName, "")
            // setName('')
            // setUrl('')
            // inputRef.current.value = '';
        }

    }

    const [date, setDate] = useState(null)
    const handleDateFormat = (newValue) => {
        setDate(newValue)
        setValue('incorporatedDate', newValue)
        clearErrors('incorporatedDate');
    }

    const [entityTypeValue, setEntityTypeValue] = useState('');
    const handleSelectEntityType = (value) => {
        setEntityTypeValue(value)
        setValue('entityType', value)
        clearErrors('entityType');
    }

    const handleSelectSector = (value) => {
        // setSectorName(value);
        setValue('sector', value)
        clearErrors('sector');
    };

    // const [startupStageValue, setStartupStageValue] = useState('');
    const handleSelectStartupStage = (value) => {
        // setStartupStageValue(value);
        setValue('startupStage', value)
        clearErrors('startupStage');
    }
    const handleSelectStartupCategory = (value) => {
        // setSectorName(value);
        setValue('startupCategory', value)
        clearErrors('startupCategory');
    };

    const [loading, setLoading] = useState(false);
    const [logoInvalid, setLogoInvalid] = useState(false);
    const onSubmit = (data) => {
        if (readyOnlyValue === false) {
            if (logoDisplayValue !== null) {
                setLoading(true);
                var getform = data
                getform.incorporatedDate = data.incorporatedDate.format("DD/MM/YYYY")

                apiService('startup/startupdetails/save', getform, 'post').then((res) => {
                    setLoading(false);
                    if (res) {
                        if (res.data.responseStatus === "Success") {
                            notifyService('success', 'Success', 'Saved successfully !!')
                            navigator('/startup/funding-financials')
                        }
                    }
                })
            } else {
                setLogoInvalid(true)
                notifyService('danger', 'Error', 'Please upload Startup Logo')
            }
        }
    };

    const handleFilter = (isDpiitRegistered) => {
        setValue('dpiitRegistered', isDpiitRegistered);
        
        if (!isDpiitRegistered) {
            setValue('dpiitRegNumber', '');
            }
    };

    return (
        <section className='container-fluid'>
            <div className='form_div'>
                <div className='d-flex align-items-center'>
                    <span className='back_span' onClick={handleBack}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Startup Details</h6>
                </div>
                <hr />
                <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        <div className='d-none'>
                            <div className="form-group">
                                <Controller name="id" control={control} defaultValue="0"
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                        />} />
                            </div>
                        </div>
                        <div className='col-lg-8 col-12'>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="startupName" control={control} defaultValue="" rules={{ required: 'Startup Name is required' }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Startup Name *"
                                                    placeholder='Enter Startup Name'
                                                    fullWidth
                                                    inputProps={
                                                        { readOnly: readyOnlyValue, }
                                                    }
                                                    {...field}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.startupName && errors.startupName.message}</FormHelperText>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group">
                                        <Controller name="incorporatedDate" control={control} defaultValue={date} rules={{ required: 'Date of Company Incorporation is required' }}
                                            render={({ field }) =>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']} fullWidth>
                                                        <DatePicker label="Company Incorporation Date *" format="DD/MM/YYYY"
                                                            {...field}
                                                            readOnly={readyOnlyValue}
                                                            sx={{ width: "100% " }}
                                                            inputFormat="DD/MM/YYYY"
                                                            onChange={(newValue) => handleDateFormat(newValue)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            } />
                                        <FormHelperText className='text-danger'>{errors.incorporatedDate && errors.incorporatedDate.message}</FormHelperText>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group mt-2">
                                        <FormControl sx={{ width: '100%' }}>
                                            {/* <InputLabel htmlFor="grouped-native-select">Legal Entity *</InputLabel> */}
                                            <Controller name="entityType" control={control} defaultValue="" rules={{ required: 'Legal Entity is required' }}
                                                render={({ field }) =>
                                                    // <Select
                                                    //     {...field}
                                                    //     // placeholder='Enter Team Size'
                                                    //     value={entityTypeValue}
                                                    //     readOnly={readyOnlyValue}
                                                    //     onChange={(e) => handleSelectEntityType(e.target.value)}
                                                    //     input={<OutlinedInput label="Name" />}
                                                    //     MenuProps={MenuProps}
                                                    // >
                                                    //     {entityTypeList.map((name) => (
                                                    //         <MenuItem
                                                    //             key={name}
                                                    //             value={name}
                                                    //             style={getStyles(name, entityTypeValue, theme)}
                                                    //         >
                                                    //             {name}
                                                    //         </MenuItem>
                                                    //     ))}
                                                    // </Select>
                                                    <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        {...field}
                                                        limitTags={2}
                                                        options={entityTypeList}
                                                        onChange={(e, selectedOptions) => handleSelectEntityType(selectedOptions)}
                                                        renderInput={(params) => <TextField {...params} label="Legal Entity *" />}
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />

                                            <FormHelperText className='text-danger'>{errors.entityType && errors.entityType.message}</FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>
                                {
                                    (entityTypeValue === 'Other') && (
                                        <div className='col-lg-6 col-12'>
                                            <div className="form-group mt-2">
                                                <Controller name="entityTypeOther" control={control} defaultValue="" rules={{ required: 'Type of Entity is required' }}
                                                    render={({ field }) =>
                                                        <TextField
                                                            {...field}
                                                            variant='outlined'
                                                            label="Type of Entity *"
                                                            placeholder='Enter Type of Entity'
                                                            fullWidth
                                                            inputProps={
                                                                { readOnly: readyOnlyValue, }
                                                            }
                                                        />} />
                                                <FormHelperText className='text-danger'>{errors.entityTypeOther && errors.entityTypeOther.message}</FormHelperText>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group mt-2">
                                        <FormControl sx={{ width: '100%' }}>
                                            {/* <InputLabel htmlFor="grouped-native-select">Current Growth Stage of Your Startup *</InputLabel> */}
                                            <Controller name="startupStage" control={control} defaultValue="" rules={{ required: 'Startup Stage is required' }}
                                                render={({ field }) =>
                                                    // <Select
                                                    //     {...field}
                                                    //     placeholder='Business Models'
                                                    //     readOnly={readyOnlyValue}
                                                    //     value={startupStageValue}
                                                    //     onChange={(e) => handleSelectStartupStage(e.target.value)}
                                                    //     input={<OutlinedInput label="Name" />}
                                                    //     MenuProps={MenuProps}
                                                    // >
                                                    //     {startupStageData.map((name) => (
                                                    //         <MenuItem
                                                    //             key={name}
                                                    //             value={name}
                                                    //             style={getStyles(name, startupStageValue, theme)}
                                                    //         >
                                                    //             {name}
                                                    //         </MenuItem>
                                                    //     ))}
                                                    // </Select>
                                                    <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        {...field}
                                                        limitTags={2}
                                                        options={startupStageData}
                                                        onChange={(e, selectedOptions) => handleSelectStartupStage(selectedOptions)}
                                                        renderInput={(params) => <TextField {...params} label="Current Growth Stage of Your Startup *" />}
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className='text-danger'>{errors.startupStage && errors.startupStage.message}</FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group mt-2">
                                        <FormControl sx={{ width: '100%' }}>
                                            <Controller name="sector" control={control} defaultValue="" rules={{ required: 'Sector is required' }}
                                                render={({ field }) =>
                                                    <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        {...field}
                                                        limitTags={2}
                                                        options={sectorData}
                                                        onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                                                        renderInput={(params) => <TextField {...params} label="Sector *" />}
                                                        disabled={readyOnlyValue}
                                                    />
                                                } />
                                            <FormHelperText className='text-danger'>{errors.sector && errors.sector.message}</FormHelperText>
                                        </FormControl>
                                        {/* <span className='font-sm'>Please check here https://bit.ly/spstn , for eligibility under category Rural Impact, Green Tech & Women-Led Startups</span> */}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="form-group mt-2">
                                        <Controller name="briefStartupDescription" control={control} defaultValue="" rules={{ required: 'Brief Description is required' }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant='outlined'
                                                    label="Brief Description of your Startup *"
                                                    placeholder='Enter Brief Description of your Startup'
                                                    fullWidth
                                                    multiline
                                                    inputProps={
                                                        { readOnly: readyOnlyValue, }
                                                    }
                                                    {...field}
                                                />} />
                                        <FormHelperText className='text-danger'>{errors.briefStartupDescription && errors.briefStartupDescription.message}</FormHelperText>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-4 col-12 text-center mb-3'>
                            <div className='logo_div'>
                                <img src={logoDisplayUrl} className='user_logo' alt={logoDisplayName} />
                                {
                                    (readyOnlyValue === false) ? (
                                        <div className='camera_icon'>
                                            <Button onChange={(e) => handleFileUpload(e, "startupLogo", "startupLogoName", setLogoDisplayValue, setLogoDisplayName, setLogoDisplayUrl, 'image')} component="label" startIcon={<CameraAltIcon />}>
                                                <VisuallyHiddenInput type="file" />
                                            </Button>
                                        </div>
                                    ) : (<></>)
                                }
                            </div>
                            <div>
                                <span>Startup Logo <span className='text-danger'>*</span></span>
                            </div>
                            <div>
                                <span className='fn-12'>Recommend Size (128px * 128px)</span>
                            </div>
                            <div>
                                <span className='fn-12'>Maximum 10 mb allowed doc (png, jpg, jpeg) <span className='text-danger'>*</span></span>
                            </div>
                            {
                                (logoInvalid === true) ? (<span className='text-danger'>Startup Logo is required</span>) : (<></>)
                            }
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="briefProblemStatement" control={control} defaultValue="" rules={{ required: 'Brief problem statement is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Brief about your problem statement *"
                                            placeholder='Enter Brief about your problem statement'
                                            fullWidth
                                            multiline
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.briefProblemStatement && errors.briefProblemStatement.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="briefProposedSolution" control={control} defaultValue="" rules={{ required: 'Brief proposed solution is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Brief about your proposed solution *"
                                            placeholder='Enter Brief about your proposed solution'
                                            fullWidth
                                            multiline
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.briefProposedSolution && errors.briefProposedSolution.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller name="startupCategory" control={control} defaultValue="" rules={{ required: 'Category is required' }}
                                        render={({ field }) =>
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                options={startupCategoryData}
                                                onChange={(e, selectedOptions) => handleSelectStartupCategory(selectedOptions)}
                                                renderInput={(params) => <TextField {...params} label="Choose relevant category of your Startup *" />}
                                                disabled={readyOnlyValue}
                                            />
                                        } />
                                    <FormHelperText className='text-danger'>{errors.startupCategory && errors.startupCategory.message}</FormHelperText>
                                </FormControl>
                                <span className='font-sm'>Please check <Link className='c-blue' to='https://bit.ly/spstn' target='_blank'>https://bit.ly/spstn</Link> , for eligibility under category Rural Impact, Green Tech & Women-Led Startups</span>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12 mb-3'>
                            <div className='form-group mt-2'>
                                <div className='d-flex'>
                                    <span className='mt-1'>Are you DPIIT Registered?</span>
                                    {/* <div className='filiterinvestor ml-4'>
                                        <span className={dpiitRegistered === true ? 'common_span act_span' : 'common_span'} onClick={() => handleFilter(true)}>Yes</span> 
                                        <span className={dpiitRegistered === false ? 'common_span act_span' : 'common_span'} onClick={() => handleFilter(false)}>No</span>
                                    </div> */}
                                    <span className='ml-5'>No <Switch checked={dpiitRegistered} disabled={readyOnlyValue} onChange={() => handleFilter(!dpiitRegistered)} color="primary" /> Yes </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            {
                                dpiitRegistered === true && (
                                    <div className='form-group mt-2'>
                                        <Controller name='dpiitRegNumber' control={control}  defaultValue="" rules={{ required: false }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant='outlined'
                                                    label="DPIIT Registration Number:"
                                                    placeholder='Enter DPIIT Registration Number:'
                                                    {...field}
                                                    type='text'
                                                    className='form-control'
                                                    disabled={readyOnlyValue}
                                                />
                                        )}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        
                        <div className='col-lg-8 col-12'>
                            {
                                (checkMethod === 'save') ? (
                                    <div className="form-group mt-2">
                                        <Controller name="inCorpCertificate" control={control} defaultValue="" rules={{ required: 'In Corporation Certificate File is required' }}
                                            render={({ field }) =>
                                                <MuiFileInput
                                                    {...field}
                                                    onChange={(e) => handleFileUpload(e, "inCorpCertificate", "inCorpCertificateName", setInCorporationValue, setInCorporationName, setInCorporationURL, 'file')}
                                                    value={inCorporationValue}
                                                    variant='outlined'
                                                    // inputProps={}
                                                    // onReset={(e) => handleremove(e)}
                                                    inputRef={inputRef}
                                                    // inputProps= {
                                                    //   ref=
                                                    // }

                                                    fullWidth
                                                    label="Incorporation Certificate *"
                                                    placeholder='Choose File' />
                                            } />
                                        <FormHelperText className='text-danger'>{errors.inCorpCertificate && errors.inCorpCertificate.message}</FormHelperText>
                                        <div>
                                            <span className='fn-12'>Maximum 10 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p className='mb-0'>In Corporation Certificate File</p>
                                        <div className='row'>
                                            <div className='col-lg-8 col-12'>
                                                <Controller name="inCorpCertificate" control={control} defaultValue="" rules={{ required: 'In Corporation Certificate File is required' }}
                                                    render={({ field }) =>
                                                        <Button
                                                            component="label"
                                                            variant="contained"
                                                            {...field}
                                                            fullWidth
                                                            onChange={(e) => handleFileUpload(e, "inCorpCertificate", "inCorpCertificateName", setInCorporationValue, setInCorporationName, setInCorporationURL, 'file')}
                                                            startIcon={<CloudUploadIcon />}
                                                            href="#file-upload"
                                                            disabled={readyOnlyValue}
                                                        >
                                                            Change
                                                            {
                                                                (readyOnlyValue === false) ? (
                                                                    <VisuallyHiddenInput type="file" />
                                                                ) : (<></>)
                                                            }

                                                        </Button>

                                                    } />
                                                <div>
                                                    <span className='fn-12'>Maximum 10 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-12'>
                                                <Fab variant="extended">
                                                    <Link to={`${inCorporationURL}`} target="_blank" color='black'>
                                                        <PreviewIcon sx={{ mr: 1 }} />
                                                        View Document
                                                    </Link>
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="website" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Website"
                                            placeholder='Enter Website'
                                            fullWidth
                                            {...field}
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                        />} />
                                <FormHelperText className='text-danger'>{errors.website && errors.website.message}</FormHelperText>
                            </div>
                        </div>
                        
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="startupEmail" control={control} defaultValue="" rules={{ required: 'Email is required', validate: validateEmail }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Email *"
                                            placeholder='Enter Email'
                                            fullWidth
                                            {...field}
                                            inputProps={
                                                { readOnly: true, }
                                            }
                                        />} />
                                <FormHelperText className='text-danger'>{errors.startupEmail && errors.startupEmail.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="startupPhone" control={control} defaultValue="" rules={{ required: 'Phone number is required', validate: validatePhoneNumber }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Phone Number *"
                                            placeholder='Enter Phone Number'
                                            fullWidth
                                            {...field}
                                            inputProps={
                                                { readOnly: readyOnlyValue,maxLength: 10 }
                                            }
                                        />} />
                                <FormHelperText className='text-danger'>{errors.startupPhone && errors.startupPhone.message}</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="linkedIn" control={control} defaultValue="" rules={{ required: false }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Link to LinkedIn"
                                            placeholder='Enter Link to LinkedIn'
                                            fullWidth
                                            {...field}
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                        />} />
                                <FormHelperText className='text-danger'>{errors.linkedIn && errors.linkedIn.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="city" control={control} defaultValue="" rules={{ required: 'City is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="City *"
                                            placeholder='Enter City'
                                            fullWidth
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                            {...field}
                                        />} />
                                <FormHelperText className='text-danger'>{errors.city && errors.city.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="state" control={control} defaultValue="Tamil Nadu" rules={{ required: 'State is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="State *"
                                            placeholder='Enter State'
                                            fullWidth
                                            {...field}
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                        />} />
                                <FormHelperText className='text-danger'>{errors.state && errors.state.message}</FormHelperText>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mt-2">
                                <Controller name="country" control={control} defaultValue="India" rules={{ required: 'Country is required' }}
                                    render={({ field }) =>
                                        <TextField
                                            variant='outlined'
                                            label="Country *"
                                            placeholder='Enter Country'
                                            fullWidth
                                            {...field}
                                            inputProps={
                                                { readOnly: readyOnlyValue, }
                                            }
                                        />} />
                                <FormHelperText className='text-danger'>{errors.country && errors.country.message}</FormHelperText>
                            </div>
                        </div>

                    </div>
                    <div className="form-group text-center">
                        <Button variant="contained" onClick={handleBack} className="btn btn-cancel submit px-3">Cancel</Button>
                        {
                            (readyOnlyValue === false) ? (
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    className="btn btn-primary submit px-3 ml-3"
                                >
                                    <span>Save & Proceed Next</span>
                                </LoadingButton>
                            ) : (<></>)
                        }

                    </div>
                </form>
            </div>
        </section>
    )
}

export default Application
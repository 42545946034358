import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Divider, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper';
import React, { useContext, useState } from 'react'
import apiService from '../../../api/apiService'
import Viewimage from '../../../api/Viewimage'
import DataContext from '../../../context/DataContext'
import notifyService from '../../../api/notifySerivce'
import Resubmit from '../../investor-module/associated-startups-page/associated-details-page/pages/Resubmit'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import Scsthubavpremarks from '../../scsthubavp-module/scsthubavp-details/Scsthubavpremarks'
import ClarificationAll from '../../scstBdd-page/scstBdd-details/ClarificationAll';

const BDDDeepDiveClarification = ({ idNumber, path,startupCheckList, viewStartupDetails,showStartupValue, startupValue }) => {
    const { setPageLoading } = useContext(DataContext)
    const [addInfoValue, setAddInfoValue] = React.useState('1');
    const handleChangeInfo = (event, newValue) => {
        setAddInfoValue(newValue);
    };
    const [historyTabValue, setHistoryTabValue] = useState('1');

    const handleHistoryChangeInfo = (event, newValue) => {
        setHistoryTabValue(newValue);
        setAddInfoValue('1')
    };
    const [statusValue, setStatusValue] = useState(2)
    const handleStatus = (value) => {
        setStatusValue(value)
    }
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    useDidMountEffect(() => {
        historyList()
    }, [statusValue,page,rowsPerPage])
    const [historyListValue, setHistoryListValue] = useState([])
    const historyList = () => {
        setHistoryListValue([])
        setPageLoading(true)
        var id = Number(idNumber)
        var req = {
            listSize: rowsPerPage,
            pageNumber: page + 1,
            startupId: id,
            // userId: localStorage.getItem('userId'),
            status: statusValue,
            round: "deepdive"

        }
        apiService('partner/startup/clarification/history', req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                setCount(res.data.count)
                if (res.data.clarifications) {
                    setHistoryListValue(res.data.clarifications)
                }
            }
        })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState('')
    const handleClickOpen = (value) => {
        setViewImage(value)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handelMarkVerifed = (id) => {
        setPageLoading(true)
        var req = {
            bcId: id,
            startupId: idNumber,
            status: 1
        }
        apiService(`partner/startups/clarification/status/save`, req, 'post').then((res) => {
            setPageLoading(false)
            if (res && res.data) {
                if (res.data.responseStatus === "Success" || res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Updated successfully !!')
                    setStatusValue(3)
                    historyList()
                    viewStartupDetails()
                    showStartupValue()

                }
            }
        })
    }
    return (
        <main>
            <section>
                <div className="table_main_div">
                    <div className='row'>
                        <div className='col-lg-12 col-12 my-3'>
                            <TabContext value={addInfoValue}>
                                <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                                    <TabList onChange={handleChangeInfo} aria-label="lab API tabs example">
                                        <Tab label="Requested" value="1" onClick={() => handleStatus(2)} />
                                        <Tab
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Submitted by startup
                                                    {startupCheckList.bddDDClarificationRequested === true ? (
                                                        <span className='ml-2'><img src='/images/giphy.gif' alt='gif' className='gif-bell img-fluid' /></span>
                                                    ) : (<></>)}
                                                </div>
                                            }
                                            value="2" onClick={() => handleStatus(3)} />
                                        <Tab label="Verified" value="3" onClick={() => handleStatus(1)} />
                                    </TabList>
                                </Box>
                                <Divider />
                                <div className="mt-3">
                                    {historyListValue.length > 0 &&
                                        (historyListValue.length) ? (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>No</TableCell>
                                                        <TableCell>Question</TableCell>
                                                        <TableCell>Requested Clarifications</TableCell>
                                                        {/* <TableCell align='center'>Comments</TableCell> */}
                                                        {
                                                            statusValue === 2 && <TableCell align='center'>Requested Time</TableCell>
                                                        }
                                                        {
                                                            statusValue === 3 && <TableCell align='center'>Submitted Time</TableCell>
                                                        }
                                                        {
                                                            statusValue === 1 && <TableCell align='center'>Time</TableCell>
                                                        }
                                                        {
                                                            statusValue === 3 && <TableCell align='center'>Attached Document</TableCell>
                                                        }
                                                        {
                                                            statusValue === 1 && <TableCell align='center'>Document Details</TableCell>
                                                        }

                                                        {
                                                            statusValue === 3 && (
                                                                <TableCell align='center'>Action</TableCell>
                                                            )
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {historyListValue.map((list, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align='center'>{page * rowsPerPage + index + 1}</TableCell>
                                                            <TableCell>{list.attribute}</TableCell>
                                                            <TableCell >{list.clarification ? list.clarification : '-'}  {list.clarificationAll && (<ClarificationAll remarks={list.clarificationAll} lblName="Clarifications" btnName="Show All Clarifications" />)}</TableCell>
                                                            <TableCell align='center'>{list.time}</TableCell>
                                                            {
                                                                statusValue === 3 && <TableCell align='center'>
                                                                    {list.documentName && <button className='viewdetails' onClick={() => { handleClickOpen(list.documentName) }}>View Document</button>}
                                                                    {list.response && <Scsthubavpremarks remarks={list.response} lblName="View Comments" />}
                                                                </TableCell>
                                                            }
                                                            {
                                                                statusValue === 1 && <TableCell align='center'>
                                                                    {list.documentName && <button className='viewdetails' onClick={() => { handleClickOpen(list.documentName) }}>View Document</button>}
                                                                    {list.response && <Scsthubavpremarks remarks={list.response} lblName="View Comments" />}
                                                                </TableCell>
                                                            }
                                                            {
                                                                statusValue === 3 && (
                                                                    <TableCell align='center'>
                                                                        <Resubmit id={list.id} idNumber={idNumber} historyList={historyList} name="bdd" viewStartupDetails={viewStartupDetails} showStartupValue={showStartupValue}/>
                                                                        <button className='verified_btn' onClick={() => handelMarkVerifed(list.id)}>Mark as Verified</button>
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <caption>
                                                    <TablePagination
                                                        component="div"
                                                        count={count}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        style={{ float: 'left' }}
                                                    />
                                                </caption>
                                            </Table>

                                        </TableContainer>
                                    ) : (
                                        <div className='nodata_tag'>
                                            <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                            <p>List is empty</p>
                                        </div>
                                    )
                                    }
                                </div>
                            </TabContext>
                        </div>
                    </div>
                    <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
                </div>
            </section>
        </main>
    )
}

export default BDDDeepDiveClarification
import React from 'react'
import { Outlet } from 'react-router-dom'

const Investorroute = () => {
  return (
    <>
            <Outlet></Outlet>
        </>
  )
}

export default Investorroute
import React from 'react'

const Businessstrategy = ({ details }) => {
  return (
    <main>
      <section>
        <div className='row'>
          <div className='col-lg-8 col-12'>
            <div className='d-flex'>
              <label className='mr-3'>Business Model :</label>
              <p className='ml-auto'><b>{details.businessModel}</b></p>
            </div>
          </div>
          <div className='col-lg-8 col-12'>
            <div className='d-flex'>
              <label className='mr-3'>Revenue Generation Model :</label>
              <p className='ml-auto'><b>{details.revenueGenerationModel}</b></p>
            </div>
          </div>
          <div className='col-lg-8 col-12'>
            <div className='d-flex'>
              <label className='mr-3'>Go-to-market Strategy :</label>
              <p className='ml-auto'><b>{details.marketingStrategy}</b></p>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Businessstrategy
import React, { useContext, useState } from 'react'
import apiService from '../../../../api/apiService';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Box, Button, FormHelperText, InputAdornment, Modal, TextField } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DataContext from '../../../../context/DataContext';
import cryptoJS from '../../../../hooks/cryptoJS';
import notifyService from '../../../../api/notifySerivce';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';
import CustomLink from '../../../common-page/CustomLink';
const Newpassword = ({ startupid }) => {
    const { modalstyle, handleClose, open, handleOpen, navigator } = useContext(DataContext)
    const { register, handleSubmit, control } = useForm();
    const { errors } = useFormState({ control });
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showRePassword, setShowRePassword] = React.useState(false);
    const handleClickShowRePassword = () => setShowRePassword((show) => !show);
    const [loading, setLoading] = useState(false);
    const [checkSamePassword, setCheckSamePassword] = useState(false)
    const onSubmit = async (data, event) => {
        if (data.newPassword === data.reenterPassword) {
            var getform = data
            getform.id = Number(localStorage.getItem('StartupId'))
            getform.newPassword = cryptoJS(data.newPassword)
            getform.reenterPassword = cryptoJS(data.reenterPassword)
            getform.otp = cryptoJS(data.otp)            
            await apiService('user/forgotpasswordupdate', getform, 'unauthpost').then((res) => {
                if (res) {                    
                    notifyService('success', 'Success', 'Password updated successfully !!')
                    navigator('/sign-in')                    
                }
            })
        } else {
            setCheckSamePassword(true)
        }
    };
    const handleResendOTP = async () => {
        var getform = {
            id: Number(localStorage.getItem('StartupId'))
        }
        await apiService('user/resendforgotpwotp', getform, 'unauthpost').then((res) => {
            if (res) {
                notifyService('success', 'Success', 'Mail sent successfully !!')
            }
        })
    }
    return (
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-3">
                <Controller name="otp" control={control} defaultValue="" rules={{ required: 'OTP is required' }}
                    render={({ field }) =>
                        <TextField
                            variant='outlined'
                            label="OTP *"
                            fullWidth
                            autoComplete='OTP'
                            placeholder='Enter OTP'
                            autoFocus
                            {...field}
                            type='number'
                        />
                    }
                />
                <FormHelperText className='text-danger'>{errors.otp && errors.otp.message}</FormHelperText>
            </div>
            <div className="form-group mb-3">
                <Controller name="reenterPassword" control={control} defaultValue="" rules={{ required: 'New Password is required' }}
                    render={({ field }) =>
                        <TextField
                            variant='outlined'
                            label="New Password *"
                            placeholder='Enter New Password'
                            fullWidth
                            autoComplete='password'
                            {...field}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                    >{showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                            }}
                        />
                    }
                />
                <FormHelperText className='text-danger'>{errors.reenterPassword && errors.reenterPassword.message}</FormHelperText>
            </div>
            <div className="form-group mb-3">
                <Controller name="newPassword" control={control} defaultValue="" rules={{ required: 'Re-Enter Password is required' }}
                    render={({ field }) =>
                        <TextField
                            variant='outlined'
                            label="Re-Enter Password *"
                            placeholder='Enter Re-Enter Password'
                            fullWidth
                            autoComplete='password'
                            {...field}
                            type={showRePassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <IconButton
                                        onClick={handleClickShowRePassword}
                                    >{showRePassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                            }}
                        />
                    }
                />
                <FormHelperText className='text-danger'>{errors.newPassword && errors.newPassword.message}</FormHelperText>
                <FormHelperText className='text-danger'>{(checkSamePassword === true ? ('Password Mismatch') : (<></>))}</FormHelperText>
            </div>

            <div className="form-group">
                <Button type="submit" variant="contained" className="form-control btn btn-primary submit px-3">Update Password</Button>
            </div>
            <div className='text-center'>
                <p className='mb-0 font-lg'>Resend OTP ? <CustomLink onClick={handleResendOTP} className='c-blue'>Click Here</CustomLink></p>
            </div>
        </form>
    )
}

export default Newpassword
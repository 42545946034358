import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import apiService from "../../../api/apiService";
import CheckIcon from "@mui/icons-material/Check";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { Dialog, DialogContent } from "@mui/material";
import Slide from "@mui/material/Slide";
import PaymentsIcon from "@mui/icons-material/Payments";
import "../dashboard-page/dashboard.css";
import Tanseed6 from "../dashboard-page/form-page/Tanseed6";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#28a745" : "#308fe8",
  },
}));
const style = {
  p: 4,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const InvestorConnectDashboard = () => {
  // document.getElementById('theme').setAttribute('href', '/css/admin.css');
  const { navigator, setPageLoading } = useContext(DataContext);

  useDidMountEffect(() => {
    // eslint-disable-next-line
    if (!localStorage.getItem("jwttoken")) {
      navigator("/sign-in");
    } else {
      (async () => await getApi())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [formData, setFormData] = useState({});
  const getApi = async () => {
    setPageLoading(true);
    localStorage.removeItem("tanseed");
    await apiService(`startup/alldetails/ic/get`, "", "get").then((res) => {
      setPageLoading(false);
      if (res) {
        let response = res.data;
        setFormData(response);
        localStorage.setItem("tanseed", response.enableTanseedButton);
        // if (response.id !== 0) {
        //     setFormData({})
        //     setFormData(response)
        // }
      }
    });
  };

  const handleValidation = () => {
    // if (formData.optedForTanseed === true) {
    //     debugger
    //     handleClickOpenApply()
    // } else {
    //     navigator('/startup/self-declaration')
    // }
    navigator("/startup/self-declaration/investor");
  };
  const handleApplicaion = () => {
    navigator("/startup/investor/application");
  };
  const handleFundingFinancials = () => {
    navigator("/startup/investor/funding");
  };
  const handleTeam = () => {
    navigator("/startup/investor/Team");
  };

  const handleDocumentsUpload = () => {
    navigator("/startup/investor/Documents");
  };
  const handletanseed = () => {
    navigator("/startup/tanseed");
  };
  // /startup/documents-upload
  const [openApply, setApplyOpen] = useState(false);

  // const handleClickOpenApply = () => {
  //     setApplyOpen(true);
  // };

  const handleApplyClose = () => {
    setApplyOpen(false);
  };

  const [openReview, setReviewOpen] = useState(false);

  // const handleClickOpenReview = () => {
  //     setReviewOpen(true);
  // };

  const handleReviewClose = () => {
    setReviewOpen(false);
  };
  const handleBackapp = () => {
    navigator("/startup/info");
    // localStorage.removeItem("routePath");
    localStorage.setItem("routePath", "/startup/info");
  };
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="form_div chart">
            <div className="chart-head">
              {/* <div className='row'>
                            <div className='col-lg-6'>
                                <h5 className='font-weight-bold mb-3'>Begin Your Journey: Select a Section to Start</h5>
                                <p className='p-size'>Start with any section and return to complete others as you wish. Your progress is saved automatically</p>
                            </div>
                            <div className='col-lg-6'>
                                <center>
                                    <div className='top-btn'>
                                        <button className='button'><UploadFileRoundedIcon/>  Download Checklist</button>
                                        <button className='button'><QuestionAnswerIcon/>  Need Help,Chat with us</button>
                                    </div>
                                </center>
                            </div>
                        </div> */}
              <div className="d-flex">
                <span className="back_span" onClick={handleBackapp}>
                  <i className="fas fa-arrow-alt-circle-left"></i>
                </span>
                <div className="ml-3">
                  <h5 className="font-weight-bold mb-3">
                    Begin Your Journey: Select a Section to Start
                  </h5>
                  <p className="p-size">
                    Start with any section and return to complete others as you
                    wish. Your progress is saved automatically
                  </p>
                  <div className="d-flex align-item-center">
                    {/* <h5 className='font-weight-bold mb-0 c-yellow'>TANSEED 6.0 is Live </h5> */}

                    {/* <button className='apply_span ml-3' onClick={handleClickOpenReview}>View More</button> */}
                  </div>
                </div>
                <div className="top-btn">
                  {/* <button className='button '><UploadFileRoundedIcon />  Download Checklist</button>
                                    <button className='button ml-3'><QuestionAnswerIcon />  Need Help,Chat with us</button> */}
                  {/* {
                                        formData.enableTanseedButton === 0 && (<button className='apply_span ml-3' onClick={handleClickOpenApply}> Apply for TANSEED 6.0 <img src='/images/sigup-btn.png' className='arrow-img-sml' alt='' /></button>)
                                    }
                                    {
                                        formData.enableTanseedButton === 1 && (<button className='apply_span ml-3' onClick={handleClickOpenReview}> Apply for TANSEED 6.0 <img src='/images/sigup-btn.png' className='arrow-img-sml' alt='' /></button>)
                                    } */}
                  {formData.enableTanseedButton === 2 && (
                    <div>
                      <p className="ml-2 font-weight-bold">
                        TANSEED 6.0 Applied !
                      </p>
                      <button
                        className="apply_span ml-2"
                        onClick={handletanseed}
                      >
                        <PaymentsIcon /> Check your Application Status
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div className="main-rec-box">
              <div className="row">
                <div className="col-lg-6">
                  <div className="rectangle m-3" onClick={handleApplicaion}>
                    {formData.detailsPercent === 100 ? (
                      <div className="circle1 font-weight-bold bg-green">
                        <CheckIcon />
                      </div>
                    ) : (
                      <div className="circle font-weight-bold">1</div>
                    )}

                    <div className="rec-box">
                      <div>
                        <h6 className="font-weight-bold">
                          Startup Details <ErrorRoundedIcon />
                        </h6>
                        <p>Brand logo, Incorporation Certificate</p>
                      </div>
                      <div className="rec-box1">
                        <Box sx={{ flexGrow: 1 }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={formData.detailsPercent}
                          />
                        </Box>
                        <p className="mt-3 right">
                          {formData.detailsPercent}% Completed
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rectangle m-3" onClick={handleTeam}>
                    {formData.teamInfoPercent === 100 ? (
                      <div className="circle1 font-weight-bold bg-green">
                        <CheckIcon />
                      </div>
                    ) : (
                      <div className="circle font-weight-bold">3</div>
                    )}
                    <div className="rec-box">
                      <div>
                        <h6 className="font-weight-bold">
                          Team Info <ErrorRoundedIcon />
                        </h6>
                        <p>Your team information</p>
                      </div>
                      <div className="rec-box1">
                        <Box sx={{ flexGrow: 1 }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={formData.teamInfoPercent}
                          />
                        </Box>
                        <p className="mt-3 right">
                          {formData.teamInfoPercent}% Completed
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="rectangle m-3"
                    onClick={handleFundingFinancials}
                  >
                    {formData.fundingFinancialsPercent === 100 ? (
                      <div className="circle1 font-weight-bold bg-green">
                        <CheckIcon />
                      </div>
                    ) : (
                      <div className="circle font-weight-bold">2</div>
                    )}
                    <div className="rec-box">
                      <div>
                        <h6 className="font-weight-bold">
                          Funding & Financials <ErrorRoundedIcon />
                        </h6>
                        <p>Your funding and financial infomation</p>
                      </div>
                      <div className="rec-box1">
                        <Box sx={{ flexGrow: 1 }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={formData.fundingFinancialsPercent}
                          />
                        </Box>
                        <p className="mt-3 right">
                          {formData.fundingFinancialsPercent}% Completed
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="rectangle m-3"
                    onClick={handleDocumentsUpload}
                  >
                    {formData.documentsPercent === 100 ? (
                      <div className="circle1 font-weight-bold bg-green">
                        <CheckIcon />
                      </div>
                    ) : (
                      <div className="circle font-weight-bold">4</div>
                    )}
                    <div className="rec-box">
                      <div>
                        <h6 className="font-weight-bold">
                          Documents Upload <ErrorRoundedIcon />
                        </h6>
                        <p>Upload required document</p>
                      </div>
                      <div className="rec-box1">
                        <Box sx={{ flexGrow: 1 }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={formData.documentsPercent}
                          />
                        </Box>
                        <p className="mt-3 right">
                          {formData.documentsPercent}% Completed
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <button type='button' disabled={!formData.enableSubmitButton} className='button-2 m-3' onClick={handleValidation}> SUBMIT FOR VALIDATION </button> */}
                  <div>
                    {formData.enableSubmitButton === 0 && (
                      <button
                        type="button"
                        disabled={!formData.enableSubmitButton}
                        className="button-2 m-3"
                      >
                        {" "}
                        Submit for validation{" "}
                      </button>
                    )}
                    {formData.enableSubmitButton === 1 && (
                      <button
                        type="button"
                        className="button-2 m-3"
                        onClick={handleValidation}
                      >
                        {" "}
                        Submit for validation{" "}
                      </button>
                    )}
                    {formData.enableSubmitButton === 2 && (
                      <button
                        type="button"
                        className="button-2 m-3"
                        onClick={handleValidation}
                      >
                        Resubmit for validation
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dialog
        open={openApply}
        TransitionComponent={Transition}
        keepMounted
        className="text-center p-5"
        fullWidth
        maxWidth="md"
      >
        <Tanseed6
          handleApplyClose={handleApplyClose}
          optedForTanseed={formData.optedForTanseed}
          page={"dashboard"}
        />
      </Dialog>
      <Dialog
        open={openReview}
        TransitionComponent={Transition}
        keepMounted
        className="text-center p-5"
        fullWidth
        maxWidth="md"
      >
        <Box sx={style} className="modal1 ">
          {/* <h5>Apply for TANSEED 6.0</h5>
                    <h5>Please complete the application details to apply</h5> */}
          <img src="/images/tanseed-logo.png" alt="img-fluid" />
          <h3 className="head font-weight-bold">TANFUND 6.0</h3>
          <h5>The Next Biggest Opportunity to Kickstart Your Startup</h5>
          <span className="date">
            Last date to Apply :{" "}
            <span className="yellow-clr font-weight-bold">
              FEBRUARY 7, 2024
            </span>
          </span>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-slide-description">
                            <span className='white-clr'>It is available under Funding & Financials</span>
                        </DialogContentText> */}
            {/* <div className='mb-2'>
                            <span className='white-clr'>It is available under Funding & Financials</span>
                        </div> */}
            <div className="my-2">
              <h5>Please complete the application and apply for TANSEED</h5>
            </div>
          </DialogContent>
          <div className="text-center">
            {/* <Button variant="contained" className="btn btn-primary submit px-3" onClick={handleReviewClose}>Ok</Button> */}
            <button className="yellow" onClick={handleReviewClose}>
              OK
            </button>
          </div>
        </Box>
      </Dialog>
    </>
  );
};

export default InvestorConnectDashboard;

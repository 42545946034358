import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import LDDPart1 from "./ldd-verification-details/LDDPart1";
import LDDPart2 from "./ldd-verification-details/LDDPart2";
import LDDClarification from "./LDDClarification";
import LDDPriorDocuments1 from "./ldd-verification-details/LDDPriorDocuments1";
import LDDPriorDocuments2 from "./ldd-verification-details/LDDPriorDocuments2";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const LDDLayout = ({
  idNumber,
  path,
  viewStartupDetails,
  showStartupValue,
  startupCheckList,
  startupValue,
}) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="overallTab">
      <Tabs
        className="verticalTabCSS"
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab
          className="verticalLable"
          label="Prior Documents 1"
          {...a11yProps(0)}
        />
        <Tab
          className="verticalLable"
          label="Prior Documents 2"
          {...a11yProps(1)}
        />
        <Tab className="verticalLable" label="Documents 1" {...a11yProps(2)} />
        <Tab className="verticalLable" label="Documents 2" {...a11yProps(3)} />
        {path === "scstldd" && (
          <Tab
            className="verticalLable"
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                Clarification
                {startupCheckList.lddClarificationRequested === true ? (
                  <span className="ml-2">
                    <img
                      src="/images/giphy.gif"
                      alt="gif"
                      className="gif-bell img-fluid"
                    />
                  </span>
                ) : (
                  <></>
                )}
              </div>
            }
            {...a11yProps(4)}
          />
        )}
      </Tabs>

      <Box sx={{ p: 3 }}>
        <TabPanel value={value} index={0}>
          <LDDPriorDocuments1
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LDDPriorDocuments2
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LDDPart1
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <LDDPart2
            idNumber={idNumber}
            path={path}
            startupValue={startupValue}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <LDDClarification
            idNumber={idNumber}
            path={path}
            viewStartupDetails={viewStartupDetails}
            startupCheckList={startupCheckList}
            showStartupValue={showStartupValue}
            startupValue={startupValue}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default LDDLayout;

import React, { useEffect, useContext, useState } from 'react';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Partneradd from './Partneradd';
import DataContext from '../../../context/DataContext';
import TablePagination from '@mui/material/TablePagination';
import Signupalert from '../Signupalert';
import { LoadingButton } from '@mui/lab';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import apiService from '../../../api/apiService';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Bulkupload from '../Bulkupload';
import downloadExcel from '../../../hooks/downloadExcel';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
const SearchinputDiv = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    color: "black",
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
const Partnerlist = () => {
    const { openSuccess, handleSuccessClose, open, addRegister, handleClose, modalstyle, searchInputValue, deleteUser,
        handleChangePage, handleChangeRowsPerPage, partnerListData, count, page, rowsPerPage, handleSearch, editValue, userListItem,
    tableIndexValue
    } = useContext(DataContext)
    useDidMountEffect(() => {
        (async () => await userListItem(2))()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchInputValue])
    const [loading, setLoading] = useState(false);
    const exportExcel = () => {
        setLoading(true);
        apiService('export/users?roleid=2', '', 'download').then((res) => {
            setLoading(false);
            downloadExcel(res.data, 'partner_')
        })
    }
    const [openBulk, setOpenBulk] = useState(false);
    const handleBulkOpen = () => setOpenBulk(true);
    const handleBulkClose = () => setOpenBulk(false);
    return (
        <section className='section_tag'>
            <Container>
                <div className='header_tag'>
                    <h5>Partner List</h5>
                </div>
                <div className='table_tag'>
                    <div className='table_header_tag d-flex'>

                        <SearchinputDiv sx={{ display: { xs: 'none', sm: 'block' } }} onChange={(e) => handleSearch(e.target.value)}>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                value={searchInputValue}
                                placeholder="Search…"
                                type="search"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </SearchinputDiv>
                        <Button className='ml-auto' variant="outlined" onClick={addRegister}>+ Add Partner</Button>
                        <LoadingButton className='ml-3'
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CloudDownloadIcon />}
                            variant="outlined"
                            onClick={exportExcel}
                        >
                            <span>EXPORT PARTNERS</span>
                        </LoadingButton>
                        <Button className='ml-3' startIcon={<CloudUploadIcon />} variant="outlined" onClick={handleBulkOpen}>IMPORT PARTNERS</Button>

                    </div>
                    {
                        (partnerListData.length) ? (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>Organization Name</TableCell>
                                            <TableCell>Email Id</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell align='center'>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {partnerListData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {
                                                        tableIndexValue(rowsPerPage, page, index)
                                                    }
                                                </TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell>{row.phone}</TableCell>
                                                <TableCell align='center'>
                                                    <EditIcon style={{ fill: '#66bb6a' }} onClick={() => editValue(row.id)} />
                                                    <DeleteIcon style={{ fill: '#d32f2f', cursor: 'pointer' }} onClick={() => deleteUser(row.id)} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <caption>
                                        <TablePagination
                                            component="div"
                                            count={count}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            style={{float:'left'}}
                                        /></caption>
                                </Table>

                            </TableContainer>
                        ) : (
                            <div className='nodata_tag'>
                                <img src='/images/Tanfundlogo-black-1.png' className='no_data_logo' alt='' />
                                <h4>Partner list is empty</h4>
                            </div>
                        )
                    }

                </div>
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Partneradd handleClose={handleClose} />
                </Box>

            </Modal>
            <Modal
                open={openSuccess}
                onClose={handleSuccessClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Signupalert handleSuccessClose={handleSuccessClose} />
                </Box>

            </Modal>
            <Modal
                open={openBulk}
                onClose={handleBulkClose}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle} className="form_tag">
                    <Bulkupload handleBulkClose={handleBulkClose} title={'Partner'} api={'partner'} roleid={2} />
                </Box>
            </Modal>
        </section>
    )
}

export default Partnerlist
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import { Button, FormHelperText, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PreviewIcon from '@mui/icons-material/Preview';
import Fab from '@mui/material/Fab';
import { Link } from 'react-router-dom';
import { MuiFileInput } from 'mui-file-input';
import DataContext from '../../../../context/DataContext';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
const InvestorDocuments = () => {
    const { handleBackinvestor, VisuallyHiddenInput } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, clearErrors, formState: { errors } } = useForm({ values: formData });
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const [loading, setLoading] = useState(false);

    useDidMountEffect(() => {
        // eslint-disable-next-line
        (async () => await getApi())()
    }, [])
    const getApi = async () => {
        await apiService(`startup/supportingdocs/get`, '', 'get').then((res) => {
            if (res) {
                let response = res.data
                if (response.id !== 0) {
                    setFormData({})
                    setFormData(response)
                    if (response.editForm === false) {
                        setReadyOnlyValue(true)
                    }
                    if (response.pitchDeckName) {
                        setPitchDeskValue(response.pitchDeckName)
                        setPitchDeskName(response.pitchDeck)
                        setPitchDeskURL(response.pitchDeckName)

                    }
                } else {
                    setFormData(response)
                }
            }
        })
    }

    const [pitchDeskValue, setPitchDeskValue] = useState(null)
    const [pitchDeskName, setPitchDeskName] = useState(null)
    const [pitchDeskURL, setPitchDeskURL] = useState(null)

    let logoselecetdFile = ''
    const handleFileUpload = (event, filekey, fileName, setdata, setName, setUrl) => {
        setValue(filekey, "")
        setValue(fileName, "")
        setdata('')
        setName('')
        setUrl('')
        debugger
        if (event.target === undefined) {
            logoselecetdFile = event
        } else {
            logoselecetdFile = event.target.files[0]
        }
        if (logoselecetdFile) {
            var reader = new FileReader();
            var imagetype = logoselecetdFile.type
            var imagedatatype = imagetype.split("/")
            var img_crt_type = imagedatatype[1]
            if (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf") {
                if (event.target === undefined) {
                    setdata(event)
                } else {
                    setdata(event.target.files[0])
                }
                var fileValue = logoselecetdFile
                reader.readAsDataURL(logoselecetdFile);
                reader.onload = () => {
                    var logourl1 = reader.result;
                    var spl = logourl1.split(',')
                    var ImageValue = spl[1]
                    var img_name = fileValue.name
                    setUrl(logourl1)
                    setValue(filekey, ImageValue)
                    setValue(fileName, img_name)
                    setName(img_name)
                    clearErrors(filekey);
                    clearErrors(fileName);
                    //   if (filekey === "product") {
                    //     setProductInvalid(false)
                    //   }
                    debugger
                }
            }
        }
    }
    const onSubmit = (data) => {
        if (readyOnlyValue === false) {
            setLoading(true);
            var getform = data
            debugger
            apiService('startup/supportingdocs/save', getform, 'post').then((res) => {
                setLoading(false);
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        notifyService('success', 'Success', 'Saved successfully !!')
                        handleBackinvestor()
                    }
                }
            })
        }
    };
    return (
        <>
            <section className='container-fluid'>
                <div className='form_div'>
                    <div className='d-flex align-items-center'>
                        <span className='back_span' onClick={handleBackinvestor}><i className="fas fa-arrow-alt-circle-left"></i></span> <h6 className='ml-2 mb-0'>Documents Upload</h6>
                    </div>
                    <hr />
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='d-none'>
                                <div className="form-group">
                                    <Controller name="id" control={control} defaultValue="0"
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                            />} />
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                {
                                    (pitchDeskName !== 'c29tZXZhbHVl') ? (
                                        <div className="form-group mt-2">
                                            <Controller name="pitchDeck" control={control} defaultValue="" rules={{ required: 'PitchDeck (Startup Presentation) File is required' }}
                                                render={({ field }) =>
                                                    <MuiFileInput
                                                        {...field}
                                                        onChange={(e) => handleFileUpload(e, "pitchDeck", "pitchDeckName", setPitchDeskValue, setPitchDeskName, setPitchDeskURL)}
                                                        value={pitchDeskValue}
                                                        variant='outlined'
                                                        fullWidth
                                                        label="Upload PitchDeck (Startup Presentation) *"
                                                        placeholder='Choose File' />
                                                } />
                                            <FormHelperText className='text-danger'>{errors.pitchDeck && errors.pitchDeck.message}</FormHelperText>
                                            <span className='font-sm'>Maximum 10 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                        </div>
                                    ) : (
                                        <div>
                                            <p className='mb-0'>Upload PitchDeck (Startup Presentation) <span className='text-danger'>*</span></p>
                                            <div className='row'>
                                                <div className='col-lg-8 col-12'>
                                                    <Controller name="pitchDeck" control={control} defaultValue="" rules={{ required: 'In Corporation Certificate File is required' }}
                                                        render={({ field }) =>
                                                            <Button
                                                                component="label"
                                                                variant="contained"
                                                                {...field}
                                                                fullWidth
                                                                onChange={(e) => handleFileUpload(e, "pitchDeck", "pitchDeckName", setPitchDeskValue, setPitchDeskName, setPitchDeskURL)}
                                                                startIcon={<CloudUploadIcon />}
                                                                href="#file-upload"
                                                                disabled={readyOnlyValue}
                                                            >
                                                                Change
                                                                {
                                                                    (readyOnlyValue === false) ? (
                                                                        <VisuallyHiddenInput type="file" />
                                                                    ) : (<></>)
                                                                }
                                                            </Button>

                                                        } />
                                                    <span className='font-sm'>Maximum 10 mb allowed doc (png, jpg, jpeg, pdf) <span className='text-danger'>*</span></span>
                                                </div>
                                                <div className='col-lg-4 col-12'>
                                                    <Fab variant="extended">
                                                        <Link to={`${pitchDeskURL}`} target="_blank" color='black'>
                                                            <PreviewIcon sx={{ mr: 1 }} />
                                                            View Document
                                                        </Link>
                                                    </Fab>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            {/* <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="elevatorPitch" control={control} defaultValue="" rules={{ required: false }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Elevator Pitch of Your Startup"
                                                placeholder='Enter Elevator Pitch of Your Startup'
                                                fullWidth
                                                {...field}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.elevatorPitch && errors.elevatorPitch.message}</FormHelperText>
                                    
                                </div>
                            </div> */}
                           
                        </div>
                        <div className="form-group text-center">
                            <Button variant="contained" onClick={handleBackinvestor} className="btn btn-cancel submit px-3">Cancel</Button>
                            {
                                (readyOnlyValue === false) ? (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        className="btn btn-primary submit px-3 ml-3"
                                    >
                                        <span>Save & Preview</span>
                                    </LoadingButton>
                                ) : (<></>)
                            }

                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default InvestorDocuments
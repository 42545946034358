import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const Incubationaccelarationview = ({ details }) => {
  return (
    <main>
      <section>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <div className='row'>
              <div className='col-lg-12 col-12'>
                <div className='d-flex'>
                  <label className='mr-3'>Have you been or are you currently incubated at any Startup Incubation Center or part of an accelerator program? :</label>
                  <p className=''><b>{(details.currentlyIncubated) ? (<>Yes</>) : (<>No</>)}</b></p>
                </div>
              </div>
              {
                details.currentlyIncubated === true && (
                  <div className='col-lg-4 col-12'>
                    <div className='d-flex'>
                      <label className='mr-3'>Incubation Center Name :</label>
                      <p className=''><b>{details.incubationCenterName}</b></p>
                    </div>
                  </div>
                )
              }
              <div className='col-lg-12 col-12'>
                <div className='d-flex'>
                  <label className='mr-3'>Are you receiving support from any other organization? :</label>
                  <p className='ml-auto'><b>{(details.receivingSupport) ? (<>Yes</>) : (<>No</>)}</b></p>
                </div>
              </div>
            </div>
          </div>
          {
            (details.receivingSupport === true && details.organizationalSupport) && (
              <div className='col-lg-12 col-12 my-3'>
                <div className='div_header'>
                  <h6>Organizational Support</h6>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Organization Name</TableCell>
                        <TableCell>Nature Of Support</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {details.organizationalSupport.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {list.organisationName}
                          </TableCell>
                          <TableCell>
                            {list.natureOfSupport}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                </TableContainer>
              </div>
            )
          }
        </div>
      </section>
    </main>
  )
}

export default Incubationaccelarationview
import React, { useContext, useState } from "react";
import { Button, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TanseedAssociateddetails from "../../tanseed-module/tanseed-startups-page/TanseedAssociateddetails";
import TanseedProgressList from "./TanseedProgressList";
import { useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataContext from "../../../context/DataContext";

const AdminTanseedDetails = ({ name, path }) => {
    const { startupId } = useParams()
    const { navigator} = useContext(DataContext)
    // debugger
    let idNumber = atob(startupId)
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleBack = () => {
        navigator(`admin/tanseed`)

    }
    return (
        <div className="scsthub-div form_div chart">
            {/* <div className='header_tag'>
        <h5>TANSEED Details</h5>
    </div> */}
            <div className=''>
                <h5><Button onClick={handleBack}><ArrowBackIcon /></Button> TANSEED Details </h5>
            </div>
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab label="Startup" value="1" />
                            <Tab
                                label="Progress"
                                value="2"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <TanseedAssociateddetails name={name} path={path} />
                    </TabPanel>
                    <TabPanel value="2" >
                        <TanseedProgressList startupId={idNumber} />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

export default AdminTanseedDetails
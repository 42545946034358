import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { Link } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Viewimage from '../../../../api/Viewimage';
const fileTypes = ["pdf"];

const FddSectionY = () => {
  const [formData, setFormData] = useState({});
  const { handleBackapp, setPageLoading,navigator } = useContext(DataContext)
  const { handleSubmit, setValue, getValues, clearErrors, control, formState: { errors } } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [exceptionalIncomePresent, setExceptionalIncomePresent] = useState();
  const [gstInvoicesPresent, setGstInvoicesPresent] = useState();
  const [creditnotesPresent, setCreditnotesPresent] = useState();
  const [contractSignedPresent, setContractSignedPresent] = useState();
  const [totalCashCollectedPresent, setTotalCashCollectedPresent] = useState();
  const [depositOrExpenses, setDepositOrExpenses] = useState();
  const [operrationalRevenue, setOperrationalRevenue] = useState();
  const [fileUploadId, setFileUploadId] = useState(0)
  const getApi = async () => {
    setPageLoading(true)
    await apiService(`startup/fdd/secy/get`, '', 'get').then((res) => {
      if (res && res.data) {
        var data = res.data
        if (res.data.id) {
          setFileUploadId(res.data.id)
        }
        if(res.data.exceptionalIncomePresent){
          setExceptionalIncomePresent(res.data.exceptionalIncomePresent)
        }
        if(res.data.gstInvoicesPresent){
          setGstInvoicesPresent(res.data.gstInvoicesPresent)
        }
        if(res.data.creditnotesPresent){
          setCreditnotesPresent(res.data.creditnotesPresent)
        }
        if(res.data.contractSignedPresent){
          setContractSignedPresent(res.data.contractSignedPresent)
        }
        if(res.data.totalCashCollectedPresent){
          setTotalCashCollectedPresent(res.data.totalCashCollectedPresent)
        }
        
        setFormData(res.data)
      }
      setPageLoading(false)
    })
  }



  const handleAccountingSoftware = (e) => {
    handleFileUpload(e, "accountingSoftware", "accountingSoftwareName", "accountingSoftwareURL", 'file');
  }
  const handleStreamWiseRevenue = (e) => {
    handleFileUpload(e, "streamWiseRevenue", "streamWiseRevenueName", "streamWiseRevenueURL", 'file');
  }
  const handleKeyCustomerRevenue = (e) => {
    handleFileUpload(e, "keyCustomerRevenue", "keyCustomerRevenueName", "keyCustomerRevenueURL", 'file');
  }
  const handleExceptionalIncome = (e) => {
    handleFileUpload(e, "exceptionalIncome", "exceptionalIncomeName", "exceptionalIncomeURL", 'file');
  }
  const handleClientWiseRevenue = (e) => {
    handleFileUpload(e, "clientWiseRevenue", "clientWiseRevenueName", "clientWiseRevenueURL", 'file');
  }



  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type
        var imagedatatype = imagetype.split("/")
        var img_crt_type = imagedatatype[1]
        if (
          ((format === 'file') && (img_crt_type === "pdf")) ||
          ((format === 'excel') && (img_crt_type === "vnd.ms-excel" || img_crt_type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
        ) {
          setValue(filekey, "")
          setValue(fileName, "")

          var fileValue = logoselecetdFile
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(',')
            var ImageValue = spl[1]
            var img_name = fileValue.name
            setValue(filekey, ImageValue)
            setValue(fileName, img_name)
            setValue(fileURL, logourl1)
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            var req = {
              [filekey]: ImageValue,
              [fileName]: img_name,
              id: fileUploadId
            }
            console.log(req);
            // saveData(req)
          }
        } else {
          notifyService('danger', 'File Format Invalided', 'Please check your file format')
        }
      }
    }
  }

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    if (value) {
      setViewImage(value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    console.log(data);
    setPageLoading(true);
    apiService('startup/fdd/secy/save', data, 'post').then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          navigator("/startup/scst/fdd-document/section-z");
        }
      }
    })
  }
  return (
    <>
      <section className='container-fluid'>
        <div className='form_div'>
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src='/images/Fig-back.png' className='img-fluid' alt='' />
            </span>
            <h6 className="ml-2 mb-0">Revenue</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>

            <div className="row doc-row2">
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Monthwise, Streamwise Revenue for the last 24 Months
                </FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="streamWiseRevenue" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleStreamWiseRevenue}
                          value={getValues('streamWiseRevenue')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('streamWiseRevenue') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('streamWiseRevenue') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('streamWiseRevenue') !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.streamWiseRevenue && errors.streamWiseRevenue.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                    onClick={() => handleClickOpen(getValues('streamWiseRevenueURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>List of Key Customers and the Revenue from them for the last 24 Months </FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="keyCustomerRevenue" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleKeyCustomerRevenue}
                          value={getValues('keyCustomerRevenue')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('keyCustomerRevenue') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('keyCustomerRevenue') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('keyCustomerRevenue') !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.keyCustomerRevenue && errors.keyCustomerRevenue.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                    onClick={() => handleClickOpen(getValues('keyCustomerRevenueURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Present a table on Revenue as per back end data and revenue as per the accounting software on a monthly basis </FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="accountingSoftware" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleAccountingSoftware}
                          value={getValues('accountingSoftware')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('accountingSoftware') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('accountingSoftware') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('accountingSoftware') !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.accountingSoftware && errors.accountingSoftware.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                    onClick={() => handleClickOpen(getValues('accountingSoftwareURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4  col-12 mt-2'>
                <FormLabel>Client wise projected revenue for the next 12 months</FormLabel>
                <div className="d-flex align-items-center">
                  <div className='form-group my-1'>
                    <Controller name="clientWiseRevenue" control={control} defaultValue="" rules={{ required: false }}
                      render={({ field }) =>
                        <FileUploader
                          {...field}
                          handleChange={handleClientWiseRevenue}
                          value={getValues('clientWiseRevenue')}
                          name="file"
                          types={fileTypes}
                          children={
                            <div className={getValues('clientWiseRevenue') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                              <center>
                                <p className={getValues('clientWiseRevenue') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues('clientWiseRevenue') !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      } />
                    <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                    <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.clientWiseRevenue && errors.clientWiseRevenue.message}</FormHelperText>
                  </div>
                  <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link
                    onClick={() => handleClickOpen(getValues('clientWiseRevenueURL'))}
                    style={{ color: "#80809e" }}
                  > Preview
                  </Link>
                  </span>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Do you have any Exceptional Income. IF so, please provide yearwise income booked under exceptional items.
                  </FormLabel>
                  <Controller name="exceptionalIncomePresent" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setExceptionalIncomePresent(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setExceptionalIncomePresent(e.target.value); setValue('closingBalanceConfirmation', ''); setValue('sanctionLetters', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.exceptionalIncomePresent && errors.exceptionalIncomePresent.message}</FormHelperText>
                </div>
              </div>
              {
                (exceptionalIncomePresent === 'true' || exceptionalIncomePresent === true) && (
                  <>
                    <div className='col-lg-4  col-12 mt-2'>
                      <div className="d-flex align-items-center">
                        <div className='form-group my-1'>
                          <Controller name="exceptionalIncome" control={control} defaultValue="" rules={{ required: false }}
                            render={({ field }) =>
                              <FileUploader
                                {...field}
                                handleChange={handleExceptionalIncome}
                                value={getValues('exceptionalIncome')}
                                name="file"
                                types={fileTypes}
                                children={
                                  <div className={getValues('exceptionalIncome') !== '' ? 'fileupload-pdf-dpiit-bg1-active' : 'fileupload-pdf-dpiit-bg1'}>
                                    <center>
                                      <p className={getValues('exceptionalIncome') !== '' ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                        <span>
                                          <AttachFileIcon className="file-icon mr-2" />
                                          {getValues('exceptionalIncome') !== '' ? 'Uploaded (Click to Change)' : 'Document'}
                                        </span>
                                      </p>
                                    </center>
                                  </div>
                                }
                                disabled={readyOnlyValue}
                              />
                            } />
                          <FormHelperText className="ml-2">Maximum size: 5 MB. File format .pdf</FormHelperText>
                          <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.exceptionalIncome && errors.exceptionalIncome.message}</FormHelperText>
                        </div>
                        <span className="pre-btn-dpiit"><VisibilityIcon className='icon-size mr-1' /><Link

                          onClick={() => handleClickOpen(getValues('exceptionalIncomeURL'))}
                          style={{ color: "#80809e" }}
                        > Preview
                        </Link>
                        </span>
                      </div>
                    </div>

                  </>
                )
              }
              <div className="col-lg-4 col-12">
                <FormLabel>What is the back end software you have for tracking the revenue, right from receipt of orders and tracking invoices.
                </FormLabel>
                <div className="form-group mt-2">
                  <Controller
                    name="trackingInvoices"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Back end software"
                        placeholder="Enter Back end software"
                        fullWidth
                        {...field}
                        type='text'
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.trackingInvoices && errors.trackingInvoices.message}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="revenueModel"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Revenue Model of the Company"
                        placeholder="Enter Revenue Model"
                        fullWidth
                        {...field}
                        type='text'
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.revenueModel && errors.revenueModel.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="revenueStreams"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Different Revenue Streams"
                        placeholder="Enter Different Revenue"
                        fullWidth
                        {...field}
                        type='text'
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.revenueStreams && errors.revenueStreams.message}
                  </FormHelperText>
                </div>
              </div>
             
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="revenucerecognitionPractices"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Note on Revenue Recognition Practices"
                        placeholder="Enter Note on Revenue"
                        fullWidth
                        {...field}
                        type='text'
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.revenucerecognitionPractices && errors.revenucerecognitionPractices.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="pricingPolicy"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Note on Pricing Policy"
                        placeholder="Enter Note on Pricing"
                        fullWidth
                        {...field}
                        type='text'
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.pricingPolicy && errors.pricingPolicy.message}
                  </FormHelperText>
                </div>
              </div>

              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Has the company got any income apart from operational revenue, Example- Sale of Assets, Divident from Investments, Scrap Sales, Insurance Claim or any other Miscellaneous Income</FormLabel>
                  <Controller name="operrationalRevenue" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setOperrationalRevenue(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setOperrationalRevenue(e.target.value); setValue('operrationalRevenue', ''); setValue('sanctionLetters', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.operrationalRevenue && errors.operrationalRevenue.message}</FormHelperText>
                </div>
              </div>
              

              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Are you collecting GST on all the Invoices. What is the GST % you are charging</FormLabel>
                  <Controller name="gstInvoicesPresent" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setGstInvoicesPresent(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setGstInvoicesPresent(e.target.value); setValue('gstInvoices', ''); setValue('sanctionLetters', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.gstInvoicesPresent && errors.gstInvoicesPresent.message}</FormHelperText>
                </div>
              </div>
              {
                (gstInvoicesPresent === 'true' || gstInvoicesPresent === true) && (
                  <>

                    <div className="col-lg-4 col-12">
                      <div className="form-group mt-2">
                        <Controller
                          name="gstInvoices"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="GST Charging"
                              placeholder="Enter GST Charging"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.gstInvoices && errors.gstInvoices.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </>
                )
              }
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Do we issue credit notes. If so what purpose. Do we allow sales return. If so policy on sales returns.
                  </FormLabel>
                  <Controller name="creditnotesPresent" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setCreditnotesPresent(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setCreditnotesPresent(e.target.value); setValue('gstInvoices', ''); setValue('sanctionLetters', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.creditnotesPresent && errors.creditnotesPresent.message}</FormHelperText>
                </div>
              </div>
              {
                (creditnotesPresent === 'true' || creditnotesPresent === true) && (
                  <>

                    <div className="col-lg-4 col-12">
                      <div className="form-group mt-2">
                        <Controller
                          name="creditnotes"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Sales Returns"
                              placeholder="Enter Sales Returns"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.creditnotes && errors.creditnotes.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </>
                )
              }

              
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Any contract signed for future revenue? If so please provide the details
                  </FormLabel>
                  <Controller name="contractSignedPresent" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setContractSignedPresent(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setContractSignedPresent(e.target.value); setValue('contractSigned', ''); setValue('sanctionLetters', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.contractSignedPresent && errors.contractSignedPresent.message}</FormHelperText>
                </div>
              </div>
              {
                (contractSignedPresent === 'true' || contractSignedPresent === true) && (
                  <>

                    <div className="col-lg-4 col-12">
                      <div className="form-group mt-2">
                        <Controller
                          name="contractSigned"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Future Revenue"
                              placeholder="Enter Future Revenue"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.contractSigned && errors.contractSigned.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </>
                )
              }
              <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>Do you collect cash for the sales made, if so provide the details of total cash collected for sales.</FormLabel>
                  <Controller name="totalCashCollectedPresent" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setTotalCashCollectedPresent(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setTotalCashCollectedPresent(e.target.value); setValue('totalCashCollected', ''); setValue('sanctionLetters', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.totalCashCollectedPresent && errors.totalCashCollectedPresent.message}</FormHelperText>
                </div>
              </div>
              {
                (totalCashCollectedPresent === 'true' || totalCashCollectedPresent === true) && (
                  <>

                    <div className="col-lg-4 col-12">
                      <div className="form-group mt-2">
                        <Controller
                          name="totalCashCollected"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Total Cash Collected"
                              placeholder="Enter Total Cash Collected"
                              fullWidth
                              {...field}
                              type='text'
                              inputProps={{ readOnly: readyOnlyValue }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.totalCashCollected && errors.totalCashCollected.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </>
                )
              }
                <div className='col-lg-4 col-12'>
                <div className="form-group">
                  <FormLabel>If cash collected , then do you deposit the same in the bank or it is used for expenses directly</FormLabel>
                  <Controller name="depositOrExpenses" control={control} defaultValue="" rules={{ required: false }}
                    render={({ field }) =>
                      <RadioGroup
                        row
                        name="position"
                        {...field}
                      >
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setDepositOrExpenses(e.target.value) }} defaultValue='null' value='true' control={<Radio />} label="Yes" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setDepositOrExpenses(e.target.value); setValue('depositOrExpenses', ''); setValue('sanctionLetters', '') }} defaultValue='null' value='false' control={<Radio />} label="No" />

                      </RadioGroup>
                    } />
                  <FormHelperText className='text-danger'>{errors.depositOrExpenses && errors.depositOrExpenses.message}</FormHelperText>
                </div>
              </div>
            </div>


            <div className="form-group d-flex justify-content-end mt-3 mr-2">
              <Button variant="contained" onClick={handleBackapp} className="btn btn-cancel submit px-3">Cancel</Button>
              {
                (readyOnlyValue === false) ? (
                  <Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    className="btn btn-primary submit px-3 ml-3"
                  >
                    <span>Save & Proceed Next</span>
                  </Button>
                ) : (<></>)
              }

            </div>
          </form>
        </div>
        <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      </section>
    </>
  )
}

export default FddSectionY
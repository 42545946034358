import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, DialogContent, DialogTitle } from "@mui/material";

const PanalRoundJuryList = ({ juryList, handleViewClose }) => {
  const getStatus = (value) => {
    if (value === 0) {
      return "Pending";
    } else if (value === 1) {
      return "No";
    } else if (value === 2) {
      return "Maybe";
    } else if (value === 3) {
      return "Yes";
    } else if (value === 4) {
      return `Can't Review`;
    }
  };
  return (
    <>
      <DialogTitle id="responsive-dialog-title">Juries</DialogTitle>
      <DialogContent>
        {juryList.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Weighted Score</TableCell>
                  <TableCell>Remarks</TableCell>
                  <TableCell align="center">Recommended</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {juryList.map((list, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{list.name}</TableCell>
                    <TableCell align="center">{list.score}</TableCell>
                    <TableCell>{list.remarks}</TableCell>
                    <TableCell align="center">
                      {getStatus(list.status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="nodata_tag">
            <img
              src="/images/Tanfundlogo-black-1.png"
              className="no_data_logo"
              alt=""
            />
            <p>Juries not found</p>
          </div>
        )}
        <div className="text-center p-3">
          <Button className="mr-3" onClick={handleViewClose} variant="outlined">
            OK
          </Button>
        </div>
      </DialogContent>
    </>
  );
};

export default PanalRoundJuryList;

import React, { useContext, useState } from "react";
import { Autocomplete, FormControl } from "@mui/material";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import useDidMountEffect from "../../../../../hooks/useDidMountEffect";
import DataContext from "../../../../../context/DataContext";

const Tanseed7Fundingprograms = ({
  fundingProgramDetails,
  setFundingProgramDetails,
  fundingProgramIndex,
  handleClose,
  fundingProgramsList,
}) => {
  const { isWhitespace } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const [partnerList, setPartnerList] = useState([]);
  const [programList, setProgramList] = useState([]);
  useDidMountEffect(() => {
    if (fundingProgramIndex !== "") {
      let datas = fundingProgramDetails[fundingProgramIndex];

      handleType(datas.type);
      handlePartner(datas.partner);
      handleProgram(datas.program);
      setFormData(datas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // programList,
    // partnerList,
    // fundingProgramsList,
    fundingProgramDetails,
    // setFundingProgramDetails,
    // fundingProgramIndex,
    // handleClose,
  ]);
  useDidMountEffect(() => {
    if (partnerName) {
      handlePartner(formData.partner);
    }
  }, [partnerList]);
  const handleType = (value) => {
    setValue(`type`, value);
    clearErrors("type");
    if (value === "Others") {
      setPartnerList([]);
      setProgramList([]);
    } else {
      const name = fundingProgramsList.filter(
        (item) => item.type.includes(value) || item.type.includes(value)
      );
      if (name.length > 0) {
        setPartnerList(name[0].partners);
      }
    }
    setValue("partner", "");
    setValue("program", "");
    setPartnerName();
    setProgramList([]);
  };
  const [partnerName, setPartnerName] = useState();
  const handlePartner = (value) => {
    const name = partnerList.filter(
      (item) => item.partner.includes(value) || item.partner.includes(value)
    );
    setValue(`partner`, value);
    clearErrors("partner");
    setPartnerName(value);
    if (name.length > 0) {
      if (name[0].programs !== undefined) {
        setProgramList(name[0].programs);
      }
    }
  };
  const handleProgram = async (value) => {
    setValue(`program`, value);
    clearErrors("program");
  };
  const onSubmit = (data) => {
    if (fundingProgramIndex !== "") {
      var indData = fundingProgramDetails;
      indData[fundingProgramIndex] = data;
      setFundingProgramDetails(indData);
    } else {
      const newListAdd = [...fundingProgramDetails, data];
      setFundingProgramDetails(newListAdd);
    }
    handleCancel();
  };
  const handleCancel = () => {
    handleClose(false);
  };
  return (
    <section>
      <div className="div_header">
        <h6>
          {fundingProgramIndex !== "" ? `Update` : `Add`} Funding Programs
          Details
        </h6>
      </div>
      <div className="form_divs">
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="d-none">
              <div className="form-group mb-3">
                <Controller
                  name="id"
                  control={control}
                  defaultValue="0"
                  render={({ field }) => <TextField {...field} />}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <FormControl sx={{ width: "100%" }}>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Name is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        options={fundingProgramsList.map(
                          (option) => option.type
                        )}
                        onChange={(e, selectedOptions) => {
                          handleType(selectedOptions);
                          if (selectedOptions === "Others") {
                            setValue("type", "Others");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Type *" />
                        )}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.type && errors.type.message}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            {getValues(`type`) === "Others" ? (
              <>
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="program"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: false,
                          validate: {
                            noWhitespace: (value) =>
                              value === "" ||
                              !isWhitespace(value) ||
                              "Whitespace not allowed",
                          },
                        }}
                        render={({ field }) => (
                          <TextField label="Program" {...field} />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.program && errors.program.message}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="partner"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            disableClearable
                            {...field}
                            options={partnerList.map(
                              (option) => option.partner
                            )}
                            onChange={(e, selectedOptions) =>
                              handlePartner(selectedOptions)
                            }
                            value={field.value || ""}
                            renderInput={(params) => (
                              <TextField {...params} label="Partner *" />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.partner && errors.partner.message}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                {programList.length > 0 && (
                  <div className="col-lg-12 col-12">
                    <div className="form-group">
                      <FormControl sx={{ width: "100%" }}>
                        <Controller
                          name="program"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Autocomplete
                              disablePortal
                              disableClearable
                              {...field}
                              options={programList}
                              onChange={(e, selectedOptions) =>
                                handleProgram(selectedOptions)
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Program" />
                              )}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.program && errors.program.message}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="form-group text-center">
            <LoadingButton
              type="submit"
              startIcon={<SaveIcon />}
              variant="contained"
              className="btn btn-primary submit px-3"
            >
              {fundingProgramIndex !== "" ? `Update` : `Save`}
            </LoadingButton>
            <Button
              variant="contained"
              onClick={handleCancel}
              className="btn btn-cancel submit px-3 ml-3"
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Tanseed7Fundingprograms;

import React, { useContext, useState } from 'react';
import { Box, Modal, TextField } from '@mui/material';
import { useForm, useFormState } from "react-hook-form";
import apiService from '../../../api/apiService';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import InputAdornment from '@mui/material/InputAdornment';
import cryptoJS from '../../../hooks/cryptoJS';
import './firstlogin.css';
import DataContext from '../../../context/DataContext';
import notifyService from '../../../api/notifySerivce';
import Signuproutemodal from '../signup-page/signupModal/Signuproutemodal';
import ReCAPTCHA from 'react-google-recaptcha';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const Firstlogin = () => {
  
  useDidMountEffect(() => {
    document.getElementById('theme').setAttribute('href', '/css/admin.css');
}, [])
  const { modalstyle, handleClose, open, navigator } = useContext(DataContext)
  const { register, handleSubmit, control } = useForm();
  const { errors } = useFormState({ control });
  const [capVal, setCapVal] = useState(null)
  const onSubmit = async (data, event) => {
    var getform = data
    getform.password = cryptoJS(data.password)

    await apiService('login', data, 'unauthpost').then((res) => {
      if (res) {
        notifyService('success', 'Success', 'Welcome to TANFUND !!')
        localStorage.setItem('crp', getform.password)
        localStorage.setItem('jwttoken', res.data.token)
        localStorage.setItem('userId', res.data.userId)
        localStorage.setItem('role', res.data.role)
        localStorage.setItem('privileges', JSON.stringify(res.data.privileges))
        localStorage.setItem('username', res.data.userName)
        localStorage.setItem('logo', res.data.organizationLogoName)
        localStorage.setItem('name', res.data.name)
        localStorage.setItem('forcePassword', res.data.forceChangePassword)
        event.target.reset();
        if (res.data.role === 'ADMIN') {
          localStorage.setItem('routePath', '/admin/partner')
          navigator('/admin/partner')
        } else if (res.data.role === 'STARTUP') {
          localStorage.setItem('routePath', '/startup/form/application')
          navigator('/startup/form/application')
        } else if (res.data.role === 'PARTNER') {
          localStorage.setItem('routePath', '/partner/round')
          if (res.data.profileUpdated === true) {
            navigator('/partner/round')
          } else {
            navigator('/partner/profile')
          }
        } else if (res.data.role === 'INVESTOR') {
          localStorage.setItem('routePath', '/investor/round')
          if (res.data.profileUpdated === true) {
            navigator('/investor/round')
          } else {
            navigator('/investor/profile')
          }
        } else if (res.data.role === 'JURY') {
          localStorage.setItem('routePath', '/jury/startup')
          navigator('/jury/startup')
        }
      }
    })

  };
  return (
    <>
      <section className="log-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="d-md-flex justify-content-center">
                <div className="login-wrap p-4 p-lg-5 bg-white box-show">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Sign In</h3>
                    </div>
                    {/* <div className="w-100">
                    <p className="social-media d-flex justify-content-end">
                      <Link className="social-icon d-flex align-items-center justify-content-center" target="_blank" to={'https://www.facebook.com/TheStartupTN/'}><span
                        className="fab fa-facebook-f"></span></Link>
                      <Link className="social-icon d-flex align-items-center justify-content-center" target="_blank" to={'https://twitter.com/TheStartupTN'}><span
                        className="fab fa-twitter"></span></Link>
                    </p>
                  </div> */}
                  </div>
                  <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-3">
                      <TextField
                        variant='outlined'
                        label="Username / Email"
                        fullWidth
                        autoComplete='userName'
                        placeholder='Enter Username / Email'
                        autoFocus
                        {...register("userName", {
                          required: "Username / Email required",
                        })}
                        error={!!errors?.userName}
                        helperText={errors?.userName ? errors.userName.message : null}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <TextField
                        variant='outlined'
                        label="OTP"
                        placeholder='Enter OTP'
                        fullWidth
                        type='number'
                        //   autoComplete='password'
                        {...register("password", {
                          required: "OTP id required",
                        })}
                        error={!!errors?.password}
                        helperText={errors?.password ? errors.password.message : null}
                        //   type={showPassword ? 'number' : 'password'}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <KeyIcon />
                            </InputAdornment>
                          ),
                          // endAdornment:
                          //   <IconButton
                          //     onClick={handleClickShowPassword}
                          //     onMouseDown={handleMouseDownPassword}
                          //   >{showPassword ? <Visibility /> : <VisibilityOff />}
                          //   </IconButton>
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <div className='d-flex justify-content-center mb-3'>
                        {/* dev -> 6LdEc4YoAAAAAD7exCN9xw41WW20_Aa3wpxsgTvj */}
                        {/* local -> 6LcaFYIoAAAAAP93phV9qkG0EcGDMyw_SJJAB_O9 */}
                        {/* live -> 6Lee24YoAAAAAATfkCzwSRTv7Zu7ASyb0ogFlprH */}
                        {/* qa -> 6LdTX-QoAAAAADwSrpOUT4XwUZo9TB0lZptVyG28 */}
                        {/* <ReCAPTCHA sitekey='6LcaFYIoAAAAAP93phV9qkG0EcGDMyw_SJJAB_O9' onChange={val => setCapVal(val)} /> */}
                      </div>
                      {/* disabled={!capVal} */}
                      <Button  type="submit" 
                      // disabled={!capVal} 
                      variant="contained" className="form-control btn btn-primary submit px-3">Sign In</Button>
                    </div>
                    <div className='text-center'>
                      {/* <p className='mb-0 font-sm'>Your are already login ? <Link to='/sign-in'>Click Here</Link></p> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle} className="form_tag_lg">
          <Signuproutemodal handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  )
}

export default Firstlogin